import { axiosCall, URL, Method } from "./config";
import {
  SavedConfigurationProps,
  UpdatedDeviceTabDetailsProps,
} from "features/devices-page/device-settings/types";

export const systemSettingsAPI = {
  //#region
  createSystemSetting: async (
    modifiedSavedConfiguration: SavedConfigurationProps
  ) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/device_system_settings`,
      modifiedSavedConfiguration
    );
    return response;
  },
  deleteConfiguration: async (system_settings_id: string) => {
    const response = await axiosCall(
      Method.DELETE,
      `${URL()}/device_system_settings`,
      { system_settings_id }
    );

    return response;
  },
  updateDevicesSystemSettings: async (
    updatedDeviceObject: UpdatedDeviceTabDetailsProps
  ) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/device/system_settings`,
      updatedDeviceObject
    );
    return response.data.Attributes;
  },
  retrieveDeviceConfigurationsList: async (tenant_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/device_system_settings/tenant_id`,
      { tenant_id }
    );
    return response.data.Items;
  },
  //#endregion
};
