import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";

export const tableStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowY: "auto",
      margin: "1rem 0",
    },
    faIcon: {
      color: theme.palette.primary.light,
      backgroundColor: "transparent",
    },
    table: {
      minWidth: "100%",
      // marginTop: "1rem",
    },
    header: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      // padding: 0,
      borderTop: "2px solid #DDD",
      borderBottom: "2px solid #DDD",
    },
    tableRow: {
      textDecoration: "none",
      "&:focus &:hover &:link &:visited &:active": {
        textDecoration: "none",
      },
    },
    tableCell: {
      borderBottom: "2px solid #DDD",
      fontSize: 14,
    },
    flex: {
      display: "flex",
      alignItems: "center",
      padding: "0 0 0 1rem",
    },
  })
);
