import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import DeleteDeviceModal from "features/devices-page/device-components/device-modal/DeleteDeviceModal";

type DevicePopOverProps = {
  id: string | undefined;
  open: any;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  deviceId: string;
};

export const DevicePopOver: React.FC<DevicePopOverProps> = ({
  id,
  open,
  anchorEl,
  handleClose,
  deviceId,
}) => {
  const classes = useStyles();
  const [showDeleteDeviceModal, setShowDeleteDevicemodal] = useState(false);

  const closeDeleteDeviceModal = () => {
    setShowDeleteDevicemodal(false);
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        classes={{
          paper: classes.paper,
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      // transformOrigin={{
      //   vertical: "top",
      //   horizontal: "left",
      // }}
      >
        <div className={classes.selectionContainer}>
          <p
            className={classes.selection}
            onClick={() => console.log("VIEW ACTIVATION CODE")}
          >
            View activation code
          </p>
          <p
            className={classes.selection}
            onClick={() => {
              handleClose();
              setShowDeleteDevicemodal(true);
            }}
          >
            Delete device
          </p>
        </div>
      </Popover>
      <DeleteDeviceModal
        deviceId={deviceId}
        fromPopOver={true}
        showDeleteDeviceModal={showDeleteDeviceModal}
        closeModals={closeDeleteDeviceModal}
      />
    </>
  );
};

export default Popover;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectionContainer: {
      padding: ".5rem 1rem",
    },
    paper: {
      border: "none",
      borderRadius: 0,
    },
    selection: {
      fontSize: "14px",
      color: theme.palette.primary.light,
      cursor: "pointer",
      margin: ".5rem 0",
      backgroundColor: "#ed254e"
    },
  })
);
