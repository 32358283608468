/* Added this file for activating/deactivating token modal for github issue #143 */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useDispatch } from "react-redux";
import {
  setSuccessModalOpen,
  setSuccessModalDescription
} from "components/success-modal/successModalSlice";
import { useStyles } from "components/asset-modals/assetStyles";
import AccountAPI from "services/accountsAPI";
import { setCallTokens } from "../accountSlice";

type ActivateDeactivateTokenModalProps = {
  open: boolean;
  handleClose: Function;
  tenantId: string;
  tokenActivity: boolean;
  tokenId: string;
  // setCallToken: Function;
};

export const ActivateDeactivateTokenModal: React.FC<ActivateDeactivateTokenModalProps> = ({
  open, //to open modal for issue #143
  handleClose, // to handle closing of modal for issue #143
  tenantId, //to send with api call for issue #143
  tokenActivity, //to add condition to activate/deactivate token for issue #143
  tokenId, // to send with api call for issue #143
  // setCallToken //to call the token list after token operation for issue #143
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // function to handle token activation/deactivation for issue #143
  const handleActivateDeactivateToken = async () => {
    // var added to send with api call if activate or deactivate button is clicked for issue #143
    const tokenStatus = tokenActivity ? "deactivate" : "activate";
    try {
      //api call for activating/deactivating token for issue #143
      const response = await AccountAPI.activateDeactivateToken(
        tenantId,
        tokenId,
        tokenStatus
      );
      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription(response.data.body));
      // setCallToken(true);
      dispatch(setCallTokens(true));
    } catch (error) {
      console.log(error);
    }

    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalOverlay}>
        <div className={classes.modalBody} style={{ minHeight: 0 }}>
          <div className={classes.header}>
            <p className={classes.headerText}>
              {/* condition to show Deactivate Token/Activate Token on UI for issue #143 */}
              {tokenActivity ? "Deactivate Token" : "Activate Token"}
            </p>
            <CloseIcon className={classes.icon} onClick={() => handleClose()} />
          </div>
          <p style={{ margin: "0 0 0 2rem" }}>
            {tokenActivity
              ? "Are you sure you want to deactivate this token?"
              : "Are you sure you want to activate this token?"}
          </p>
          <div className={classes.actionItems} style={{ border: "none" }}>
            <button
              className={classes.cancelButton}
              type="button"
              onClick={() => handleClose()}
            >
              Cancel
            </button>
            {
              <button
                className={classes.saveButton}
                type="button"
                onClick={handleActivateDeactivateToken}
              >
                {tokenActivity ? "Deactivate token" : "Activate token"}
              </button>
            }
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ActivateDeactivateTokenModal;
