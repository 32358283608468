import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PathwayProps } from "./types";

const initialState: {
  pathway: PathwayProps[];
} = {
  pathway: [],
};

const realTimeStatusPageSlice = createSlice({
  name: "RealTimeStatusPageSlice",
  initialState,
  reducers: {
    setPathway: (state, action: PayloadAction<PathwayProps[]>) => {
      // console.log(action.payload);
      state.pathway = action.payload;
    },
    addPath: (state, action: PayloadAction<PathwayProps>) => {
      state.pathway.push(action.payload);
    },
  },
});

export const { setPathway, addPath } = realTimeStatusPageSlice.actions;
export default realTimeStatusPageSlice.reducer;
