import React, { useState, useEffect } from "react";
import { PasswordValidationProps } from "./types";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Colors } from "common/Colors";

const PasswordValidation = ({
  password,
  setIsPasswordValid,
}: PasswordValidationProps) => {
  const classes = useStyles();
  const [validUpper, setValidUpper] = useState(false);
  // const [validLower, setValidLower] = useState(false);
  const [validLength, setValidLength] = useState(false);
  const [validNumber, setValidNumber] = useState(false);
  const [validSpecial, setValidSpecial] = useState(false);

  useEffect(() => {
    const evaluatePassword = (password: any) => {
      const regexUpper = /[A-Z]/g;
      const regexNumber = /[0-9]/g;
      // const regexLower = /[a-z]/g;
      const regexSpecial = /[!@#$%^&*(),.?":{}|<>]/g;

      let validUpper = regexUpper.test(password);
      let validNumber = regexNumber.test(password);
      let validLength = password.length >= 12;
      // let validLower = regexLower.test(password);
      let validSpecial = regexSpecial.test(password);

      setValidUpper(validUpper);
      setValidNumber(validNumber);
      setValidLength(validLength);
      setValidSpecial(validSpecial);
      // setValidLower(validLower);

      // if (validLower && validUpper & validNumber & validSpecial && validLength) {
      //   props.readPasswordValidity(true);
      // }
      if (validLength && validNumber && validUpper && validSpecial) {
        setIsPasswordValid(true);
      } else {
        setIsPasswordValid(false);
      }
    };

    evaluatePassword(password);
  }, [password, setIsPasswordValid]);

  return (
    <div className={classes.rulesContainer}>
      <div className={classes.ruleCheckContainer}>
        <div
          className={clsx({
            [classes.rulePass]: validLength,
            [classes.ruleFail]: !validLength,
          })}
        >
          {validUpper ? (
            <div style={{ margin: "0 .5rem 0 0" }}>
              <svg
                width="18"
                height="13"
                viewBox="0 0 18 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 1L6 12L1 7"
                  stroke={Colors.BasinRed}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          ) : (
              <div style={{ margin: "0 .5rem 0 0" }}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                    stroke={Colors.BasinRed}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 6V9"
                    stroke={Colors.BasinRed}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 12H9.0075"
                    stroke={Colors.BasinRed}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            )}
          At least 12 alphanumeric characters
        </div>
        <div
          className={clsx({
            [classes.rulePass]: validUpper,
            [classes.ruleFail]: !validUpper,
          })}
        >
          {validUpper ? (
            <div style={{ margin: "0 .5rem 0 0" }}>
              <svg
                width="18"
                height="13"
                viewBox="0 0 18 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 1L6 12L1 7"
                  stroke={Colors.BasinRed}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          ) : (
              <div style={{ margin: "0 .5rem 0 0" }}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                    stroke={Colors.BasinRed}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 6V9"
                    stroke={Colors.BasinRed}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 12H9.0075"
                    stroke={Colors.BasinRed}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            )}
          At least 1 capital letter
        </div>
        <div
          className={clsx({
            [classes.rulePass]: validNumber,
            [classes.ruleFail]: !validNumber,
          })}
        >
          {validUpper ? (
            <div style={{ margin: "0 .5rem 0 0" }}>
              <svg
                width="18"
                height="13"
                viewBox="0 0 18 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 1L6 12L1 7"
                  stroke={Colors.BasinRed}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          ) : (
              <div style={{ margin: "0 .5rem 0 0" }}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                    stroke={Colors.BasinRed}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 6V9"
                    stroke={Colors.BasinRed}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 12H9.0075"
                    stroke={Colors.BasinRed}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            )}
          At least 1 number
        </div>
        <div
          className={clsx({
            [classes.rulePass]: validSpecial,
            [classes.ruleFail]: !validSpecial,
          })}
        >
          {validUpper ? (
            <div style={{ margin: "0 .5rem 0 0" }}>
              <svg
                width="18"
                height="13"
                viewBox="0 0 18 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 1L6 12L1 7"
                  stroke={Colors.BasinRed}
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          ) : (
              <div style={{ margin: "0 .5rem 0 0" }}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                    stroke={Colors.BasinRed}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 6V9"
                    stroke={Colors.BasinRed}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9 12H9.0075"
                    stroke={Colors.BasinRed}
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            )}
          At least 1 special character
        </div>
      </div>
    </div>
  );
};

export default PasswordValidation;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rulesContainer: {
      marginTop: "1rem 0 0 0",  //changed marginbottom for issue #66
    },
    ruleCheckContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    rulePass: {
      fontSize: 12,
      color: "#008000",
    },
    ruleFail: {
      fontSize: 12,
      color: "#ED254E",
    },
  })
);
