/* Issue fixed for github issue #93 */
export enum LocalDeviceType {
  DISABLED = 0,
  MODBUS_RTU = 1,
  MODBUS_TCP = 2,
  CAN_J1939 = 3,
  EdgeTrak_4N = 7 //Added device type or Github issue #93
}

export const LOGOUT_RESET_REDUX = "LOGOUT_RESET_REDUX";
