/* Issue fixed for github issue #54, issue #82,issue #140 */
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ParameterProps } from "features/real-time-status-page/types";
import { displayColors } from "utils/displayDashChartColors";
import { Colors } from "common/Colors";
import { binaryFromJsonList } from "utils/binaryToText";
import { numberToTextKey } from "utils/constants";
import { binaryToTextKey } from "utils/constants";
import { getDecodedVal } from "utils/conversions";
interface IndicatorChartProps {
  params: ParameterProps;
}

const IndicatorChart: React.FC<IndicatorChartProps> = ({
  params
}: IndicatorChartProps) => {
  const valParam =
    params.parameter_value > params.max_value
      ? params.max_value
      : params.parameter_value < params.min_value
      ? params.min_value
      : params.parameter_value;

  const {
    low_low_to_low_color,
    min_to_low_low_color,
    low_to_high_color,
    high_high_to_max_color,
    high_to_high_high_color
  } = params;

  const COLORS = [
    min_to_low_low_color,
    low_low_to_low_color,
    low_to_high_color,
    high_to_high_high_color,
    high_high_to_max_color
  ];
  const percent = (100 * valParam) / params.max_value;

  const getColor = () => {
    let color = "black";
    if (params.parameter_value <= params.low_low_value) {
      color = COLORS[0];
    }
    if (
      params.parameter_value >= params.low_low_value &&
      params.parameter_value <= params.low_value
    ) {
      color = COLORS[1];
    }
    if (
      params.parameter_value >= params.low_value &&
      params.parameter_value <= params.high_value
    ) {
      color = COLORS[2];
    }
    if (
      params.parameter_value >= params.high_value &&
      params.parameter_value <= params.high_high_value
    ) {
      color = COLORS[3];
    }
    if (
      params.parameter_value >= params.high_high_value &&
      params.parameter_value <= params.max_value
    ) {
      color = COLORS[4];
    }
    return displayColors(color);
  };
  return (
    <div
      style={{
        //Changed height and width for chart so that it size itself according to screen resolution for issue #140
        height: "max(125px,20vh)",
        // height: 125, //added height parameter as the chart was touching the box borders. Github issue #82
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div
        style={{
          // height: 75,
          // removed width and added height and aspect ratio for issue #140
          height: "75%",
          aspectRatio: "1/1",
          border: `1px solid ${Colors.Black}`,
          borderRadius: "50%",
          backgroundColor: getColor(),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Colors.Black
        }}
      >
        {/* Added a function call to show the value of the parameter and its unit inside the chart. Github issue #54 */}
        {getDecodedVal(
          params,
          Number(params.parameter_value),
          numberToTextKey,
          binaryToTextKey,
          binaryFromJsonList
        )}
        <br />
        {params.unit}
        {/* {`${percent || 0}%`} */}
      </div>
    </div>
  );
};

export default IndicatorChart;
