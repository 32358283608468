/* Issue fixed for github issue #33, issue #26,issue #218 */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import AccountsTable from "./components/AccountsTable";
import search from "assets/bt-assets/header_search_w.svg";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "app/store";
import {
  resetSelectedAccountData,
  setSubPageActive
} from "features/accounts-page/accountSlice";
import { RootState } from "app/rootReducer";
import AddNewAccount from "./components/AddNewAccount";
import Button from "components/common-buttons/Button";
import AccountsOverview from "./components/AccountsOverview";
import {
  setSelectedAccountInformation,
  setSelectedAccount,
  setAccountDashboard //added setAccountDashboard for returning to accounts page after deletion for issue #33
} from "./accountSlice";
import { useAccessLevelAndRoles } from "utils/reactHooks";
import LoadSpinner from "common/LoadSpinner";
import AccountAPI from "services/accountsAPI";
import { ReactComponent as ExportIcon } from "assets/bt-assets/Group 55.svg";
import { Colors } from "common/Colors";

const Accounts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const requestedRef = useRef<boolean>(false);

  const [addNewAccount, setAddNewAccount] = useState(false);
  const [accounts, setAccounts] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const userInformation = useSelector(
    (state: RootState) => state.AppSlice.userInformation
  );
  const { selectedAccount } = useAppSelector(state => state.AccountSlice);
  const { superAdminAccountId } = useAppSelector(state => state.AppSlice);
  const selectedAnAccount = useMemo(() => Boolean(selectedAccount.accountId), [
    selectedAccount
  ]);
  //added reCallAcclist to recall list api for displaying updated ccounts list after deletion for issue #33
  const reCallAccList = useSelector(
    (state: RootState) => state.AccountSlice.isAccountDashboard
  );
  const { isBasintrakSuperAdmin, isTenantAdmin } = useAccessLevelAndRoles();

  const handleAddNewAccount = () => {
    // setHeaderName("Add new account") //! we will dispatch this so that it can be used in header flow
    setAddNewAccount(true);
  };

  // Added the function to send with the AddNewAccount Modal. Github issue #26
  const getaddNewAccount = () => {
    setAddNewAccount(false);
  };

  const handleCancel = () => {
    // setHeaderName("Add new account") //! we will dispatch this so that it can be used in header flow
    dispatch(setSubPageActive(false));
    setAddNewAccount(false);
    dispatch(resetSelectedAccountData());
  };

  const handleAccountPageRole = (tenantId: string) => {
    if (userInformation.accounts.length > 0) {
      // const isAdmin = userInformation.access_level === "admin";
      const visibleAccounts = isBasintrakSuperAdmin
        ? accounts
        : userInformation.accounts;
      const selectedAccount = visibleAccounts.filter(
        (account: any) => account.tenant_id === tenantId
      );
      if (selectedAccount.length < 1) {
        return alert(
          "No account found in user information that matches selected account."
        );
      }
      const { tenant_name, tenant_id, roles, active } = selectedAccount[0];
      dispatch(
        setSelectedAccount({
          accountName: tenant_name,
          accountId: tenant_id,
          role: isBasintrakSuperAdmin ? "admin" : roles[0],
          active: active
        })
      );
    }
  };

  const handleTenant = async (tenantId: string) => {
    // ! pass through tenant ID and store information into redux
    handleAccountPageRole(tenantId);

    try {
      setLoading(true);
      const accountInformation = await AccountAPI?.getTenantProfile(tenantId);
      dispatch(setSelectedAccountInformation(accountInformation));
      dispatch(setSubPageActive(true));
      setLoading(false);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  useEffect(() => {
    const retrieveAllAccounts = async () => {
      const response = await AccountAPI.getAllTenants();
      let accountsArray: any[] = [...response];
      const superAdminAccountIndex = response.findIndex((account: any) => {
        return account.tenant_id === superAdminAccountId;
      });
      accountsArray.push(...accountsArray.splice(0, superAdminAccountIndex));
      //Added function to sort the account list for issue #218
      accountsArray.sort((a: any, b: any) => {
        if (a.tenant_name < b.tenant_name) return -1;
        return a.tenant_name > b.tenant_name ? 1 : 0;
      });
      setAccounts(accountsArray);
      setLoading(false);
      return response;
    };

    if (
      !requestedRef.current &&
      userInformation !== undefined &&
      userInformation.access_level
    ) {
      requestedRef.current = true; // prevents duplicate retrieveAllAccounts calls from userInformation dependency
      switch (userInformation.access_level) {
        case "admin":
          retrieveAllAccounts();
          break;
        case "general":
          //Added function to sort the account list for issue #218
          const accArr = [...userInformation.accounts];
          accArr.sort((a: any, b: any) => {
            if (a.tenant_name < b.tenant_name) return -1;
            return a.tenant_name > b.tenant_name ? 1 : 0;
          });
          setAccounts(accArr);
          setLoading(false);
          break;
        default:
          break;
      }
    }
    //Added the condition for recalling retrieve all accounts function after deletion for issue #33
    if (reCallAccList) {
      retrieveAllAccounts();
      dispatch(setAccountDashboard(false));
    }
  }, [userInformation, reCallAccList, dispatch]); //added reCallAccList and dispatch to render useeffect on thesse variable change for issue #33

  useEffect(() => {
    // timeout workaround because Drawer.tsx use effect on pageName is screwing things up
    setTimeout(() => dispatch(setSubPageActive(selectedAnAccount)), 200);
  }, [dispatch, selectedAnAccount]);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedAccountData());
      dispatch(setSubPageActive(false));
    };
  }, [dispatch]);

  return loading ? (
    <LoadSpinner size="90vh" />
  ) : (
    <Grid container className={classes.deviceContainer}>
      {addNewAccount ? (
        <AddNewAccount
          handleCancel={handleCancel}
          getaddNewAccount={getaddNewAccount}
        /> // Added the function as the modal is required for the redirection of the page. Github issue #26
      ) : selectedAnAccount ? (
        <AccountsOverview handleCancel={handleCancel} />
      ) : (
        <>
          <Grid item xs={12} className={classes.topGrid}>
            <div className={classes.search}>
              {/* // ! hid search functionality for later phase */}
              {/* <InputBase
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                placeholder="Search accounts by keyword(s)"
              />
              <div className={classes.searchbutton}>
                <img src={search} alt="" />
              </div> */}
            </div>
            {isTenantAdmin || isBasintrakSuperAdmin ? (
              <div className={classes.buttonContainer}>
                <Button
                  type="button"
                  color="white"
                // onClick={handleCsvDownload}
                >
                  <ExportIcon className={classes.exportIcon} />
                  Export to excel
                </Button>
                <Button
                  type="button"
                  color="defaultTheme"
                  onClick={() => {
                    handleAddNewAccount();
                  }}
                >
                  + Add new account
                </Button>
              </div>
            ) : null}
          </Grid>
          <Grid item xs={12} className={classes.bottomContainer}>
            <AccountsTable
              handleTenant={handleTenant}
              accounts={accounts}
              accessLevel={userInformation.access_level}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Accounts;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deviceContainer: {
      marginTop: "1rem"
    },
    topGrid: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    search: {
      display: "flex",
      flexDirection: "row"
    },
    searchIcon: {
      height: "100%",
      position: "absolute"
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row"
    },
    searchbutton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: Colors.White,
      cursor: "pointer",
      backgroundColor: theme.palette.primary.light,
      width: 40
    },
    bottomContainer: {
      margin: "1rem 0 0 0"
    },
    inputRoot: {
      border: "1px solid #DDDDDD",
      borderRadius: 0,
      width: 321,
      backgroundColor: "#FFFFFF",
      height: 30
    },
    inputInput: {
      paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
      width: 321,
      height: 30
    },
    exportButton: {
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: "#FFFFFF",
      width: 160,
      height: 30,
      color: theme.palette.primary.light,
      fontWeight: 600,
      marginRight: "1rem",
      cursor: "pointer"
    },
    addNewAccountButton: {
      border: `1px solid ${theme.palette.primary.light}`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      backgroundColor: theme.palette.primary.light,
      height: 30,
      // width: 175,
      color: "#fff",
      // fontWeight: 600,
      padding: ".5rem 1rem",
      cursor: "pointer",
      outline: "none"
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary
    },
    exportIcon: {
      stroke: theme.palette.primary.light,
      marginRight: ".5rem"
    }
  })
);
