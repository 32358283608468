import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import { AssetSettingsPopOverProps } from "./types";
import DeleteAssetCategory from "components/asset-modals/DeleteAssetCategory";
import { Colors } from "common/Colors";

export const AssetSettingsPopOver: React.FC<AssetSettingsPopOverProps> = ({
  id,
  open,
  anchorEl,
  handleClose,
  categoryId,
}) => {
  const classes = useStyles();
  const [showDeleteCategory, setShowDeleteCategory] = React.useState(false);

  const closeDeleteDeviceModal = () => {
    setShowDeleteCategory(false);
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        classes={{
          paper: classes.paper,
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        // transformOrigin={{
        //   vertical: "top",
        //   horizontal: "left",
        // }}
      >
        <div className={classes.selectionContainer}>
          <p
            className={classes.selection}
            onClick={() => {
              handleClose();
              setShowDeleteCategory(true);
            }}
          >
            Delete asset category
          </p>
        </div>
      </Popover>
      {showDeleteCategory && (
        <DeleteAssetCategory
          closeModal={closeDeleteDeviceModal}
          categoryId={categoryId}
        />
      )}
    </>
  );
};

export default AssetSettingsPopOver;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectionContainer: {
      padding: ".5rem 1rem",
    },
    paper: {
      border: "none",
      borderRadius: 0,
    },
    selection: {
      fontSize: "14px",
      color: theme.palette.primary.light,
      margin: 0,
      cursor: "pointer",
    },
  })
);
