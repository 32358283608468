/* Issue fixed for github issue #174*/
import { axiosCall, URL, Method } from "./config";

import {
  PostNewAssetObject,
  PutAssetObject,
  MovedAssetObject,
  DeviceProps,
  AssetGroupObject,
  AssetCategoryObject
} from "features/assets-page/types";

export default {
  // ! asset
  //#region
  postNewAsset: async (postNewAssetObject: PostNewAssetObject) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/asset`,
      postNewAssetObject
    );

    return response;
  },
  deleteAsset: async (assetId: string) => {
    const response = await axiosCall(Method.DELETE, `${URL()}/asset`, {
      asset_id: assetId
    });

    return response;
  },
  retrieveAssetProfile: async (assetId: string) => {
    const response = await axiosCall(Method.POST, `${URL()}/asset/profile`, {
      asset_id: assetId
    });

    return response.data.Item;
  },
  updateAsset: async (putAssetObject: PutAssetObject) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/asset`,
      putAssetObject
    );

    return response.data.Attributes;
  },
  deactivateAsset: async (assetId: string) => {
    const response = await axiosCall(Method.PUT, `${URL()}/asset/deactivate`, {
      asset_id: assetId
    });

    return response.data.Attributes.asset_status;
  },
  activateAsset: async (assetId: string) => {
    const response = await axiosCall(Method.PUT, `${URL()}/asset/activate`, {
      asset_id: assetId
    });

    return response.data.Attributes.asset_status;
  },
  moveAsset: async (movedAssetObject: MovedAssetObject) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/asset/move`,
      movedAssetObject
    );

    return response;
  },
  addDeviceToAsset: async (deviceProps: DeviceProps) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/asset/add_device`,
      deviceProps
    );

    return response;
  },
  //#endregion
  // ! asset group APIs
  //#region
  postAssetGroup: async (assetGroupObject: AssetGroupObject) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/asset/group`,
      assetGroupObject
    );

    return response;
  },
  deleteAssetGroup: async (assetGroupId: string) => {
    const response = await axiosCall(Method.DELETE, `${URL()}/asset/group`, {
      asset_group_id: assetGroupId
    });

    return response;
  },
  retrieveAssetGroupProfile: async (assetGroupId: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/asset/group_profile`,
      {
        asset_group_id: assetGroupId
      }
    );

    return response.data.Item;
  },
  updateAssetGroup: async (
    selectedGroupId: string,
    selectedGroupName: string | undefined,
    selectedGroupSharing: boolean | undefined
  ) => {
    const response = await axiosCall(Method.PUT, `${URL()}/asset/group`, {
      asset_group_id: selectedGroupId,
      group_name: selectedGroupName,
      group_sharing: selectedGroupSharing,
      shared_customers: [] // ! this will eventually have shared customers, it is not yet active
    });

    return response.data.Attributes.group_name;
  },
  deactivateAssetGroup: async (assetGroupId: string) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/asset/group_deactivate`,
      {
        asset_group_id: assetGroupId
      }
    );

    return response.data.Attributes.group_status;
  },
  activateAssetGroup: async (assetGroupId: string) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/asset/group_activate`,
      {
        asset_group_id: assetGroupId
      }
    );

    return response.data.Attributes.group_status;
  },
  moveAssetGroup: async (
    movingGroupId: string,
    targetGroupId: string,
    previousGroupId: string
  ) => {
    const response = await axiosCall(Method.PUT, `${URL()}/asset/group_move`, {
      moving_group_id: movingGroupId,
      target_group_id: targetGroupId,
      previous_group_id: previousGroupId
    });

    return response;
  },
  getGroupListForUserByTenant: async (userId: string, tenantId: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/asset/group_list/user/tenant`,
      {
        user_id: userId,
        tenant_id: tenantId
      }
    );
    return response.data;
  },
  getGroupListForTenant: async (tenantId: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/asset/group_list/tenant`,
      {
        tenant_id: tenantId
      }
    );

    return response.data.Items;
  },
  //#endregion
  // ! asset category
  //#region
  postNewAssetCategory: async (assetCategoryObject: AssetCategoryObject) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/asset/category`,
      assetCategoryObject
    );

    return response;
  },
  deleteAssetCategory: async (asset_category_id: string) => {
    const response = await axiosCall(Method.DELETE, `${URL()}/asset/category`, {
      asset_category_id
    });

    return response;
  },
  retrieveAssetCategoryProfile: async (asset_category_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/asset/category_profile`,
      { asset_category_id }
    );

    return response.data.Item;
  },
  updateAssetCategory: async (assetCategoryObject: AssetCategoryObject) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/asset/category`,
      assetCategoryObject
    );

    return response;
  },
  getAssetCategoryList: async () => {
    const response = await axiosCall(
      Method.GET,
      `${URL()}/asset/category_list`
    );
    return response.data.Items;
  },
  //Api to retrieve asset configuration for issue #174
  getAssetConfiguration: async (assetId: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/asset/retrieve_asset_config`,
      {
        asset_id: assetId
      }
    );
    return response.data.Item;
  },
  //Api to update configuration for issue #174
  updateAssetConfiguration: async (
    assetId: string,
    assetName: string,
    tankType: number,
    tankCapacity: number,
    tankLevelUnit: number,
    tankTypeParameter: any,
    daysToEmptyWarningNotify: boolean,
    daysToWarning: number,
    daysToEmptyCriticalNotify: boolean,
    daysToCritical: number
  ) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/asset/asset_config`,
      {
        asset_id: assetId,
        asset_name: assetName,
        tank_type: tankType,
        tank_capacity: tankCapacity,
        tank_level_unit: tankLevelUnit,
        tank_type_parameter: tankTypeParameter,
        days_to_empty_warning_notify: daysToEmptyWarningNotify,
        days_to_warning: daysToWarning,
        days_to_empty_critical_notify: daysToEmptyCriticalNotify,
        days_to_critical: daysToCritical
      }
    );
    return response.data;
  }
  //#endregion
};
