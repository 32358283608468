import React, { useState, useEffect, useCallback } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { formModalStyles } from "common/formModalStyles";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
} from "components/success-modal/successModalSlice";
import { useDispatch } from "react-redux";
import { Colors } from "common/Colors";
import UploadButton from "components/UploadButton";
import { InputLabel } from "components/input-label/InputLabel";
import Button from "components/common-buttons/Button";
import { Storage } from "aws-amplify";
import { S3_FIRMWARE_BUCKET } from "config";
import { otaAPI } from "services/otaAPI";
import LoadSpinner from "common/LoadSpinner";
import { useForm } from "react-hook-form";
import { RequiredFirmwareInputs } from "./types";
import { v4 as uuidv4 } from "uuid";

// import clsx from "clsx";

type CreateFirmwareModalProps = {
  open: boolean;
  handleClose: Function;
  recallList: Function;
};

export const CreateFirmwareModal: React.FC<CreateFirmwareModalProps> = ({
  open,
  handleClose,
  recallList,
}) => {
  const classes = formModalStyles();
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>();
  const [isFile, setIsFile] = useState(true);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm<RequiredFirmwareInputs>();

  const handleCreateFirmware = async ({
    version,
    applicationName,
    description,
    applicationPartNumber,
  }: RequiredFirmwareInputs) => {
    // ! don't change fileName - important *
    const uuid: string = uuidv4();
    const fileName: string = `firmwares/${uuid}_${file?.name}`;

    if (!isFile) {
      return;
    }

    try {
      setLoading(true);
      await Storage.put(fileName || "", file as File, {
        bucket: S3_FIRMWARE_BUCKET, // ! this is targeting a specific bucket here
      });
      await otaAPI.uploadFirmware(
        uuid,
        version,
        applicationName,
        description,
        applicationPartNumber,
        fileName
      );
      handleClose();
      recallList(true);
      setLoading(false);
      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription("File uploaded successfully!"));
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };

  const fileHandler = (file: File) => {
    setFile(file);
  };

  useEffect(() => {
    if (file) {
      setIsFile(true);
    }
  }, [file]);

  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalOverlay}>
        {loading ? (
          <LoadSpinner />
        ) : (
          <div className={classes.modalBody}>
            <div className={classes.header}>
              <p className={classes.headerText}>Add new firmware</p>
              <CloseIcon
                className={classes.icon}
                onClick={() => {
                  handleClose();
                  if (!file) {
                    setIsFile(true);
                  }
                }}
              />
            </div>
            <form onSubmit={handleSubmit(handleCreateFirmware)}>
              <div className={classes.inputBody}>
                <div className={classes.inputGroup}>
                  <div className={classes.labelContainer}>
                    <label>
                      Select a file to upload and create firmware{" "}
                      <span style={{ color: Colors.Pink }}>*</span>
                    </label>
                    {!isFile && (
                      <span className={classes.error}>
                        Upload file is required.
                      </span>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "3rem",
                    }}
                  >
                    <UploadButton onChange={fileHandler} isImageUpload={false}>
                      Choose a file
                    </UploadButton>
                    <span>{!file || file.name}</span>
                  </div>
                </div>
                <div className={classes.inputGroup}>
                  <InputLabel
                    label="Application name"
                    name="applicationName"
                    errors={errors}
                  />
                  <input
                    name="applicationName"
                    ref={register({
                      required: "This field is required.",
                    })}
                    type="text"
                    placeholder="Enter"
                  />
                </div>
                <div className={classes.inputGroup}>
                  <InputLabel
                    label="Application part number"
                    name="applicationPartNumber"
                    errors={errors}
                  />
                  <input
                    name="applicationPartNumber"
                    ref={register({
                      required: "This field is required.",
                    })}
                    type="text"
                    placeholder="Enter"
                  />
                </div>
                <div className={classes.inputGroup}>
                  <InputLabel
                    label="Application ID"
                    name="version"
                    errors={errors}
                  />
                  <input
                    name="version"
                    ref={register({
                      required: "This field is required.",
                    })}
                    type="text"
                    placeholder="Enter"
                  />
                </div>
                <div className={classes.inputGroup}>
                  <InputLabel
                    label="Description"
                    name="description"
                    errors={errors}
                  />
                  <input
                    name="description"
                    ref={register({
                      required: "This field is required.",
                    })}
                    type="text"
                    placeholder="Enter"
                  />
                </div>
              </div>
              <div className={classes.actionItems}>
                <Button
                  type="button"
                  color="white"
                  onClick={() => {
                    handleClose();
                    if (!file) {
                      setIsFile(true);
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="defaultTheme"
                  onClick={() => {
                    if (!file) {
                      setIsFile(false);
                    }
                  }}
                >
                  Create firmware
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CreateFirmwareModal;
