import React, { useState, useEffect, ChangeEvent } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// import { formModalStyles } from "common/formModalStyles";
import { Grid } from "@material-ui/core";
import { CategoryObject, AssetProfile } from "./types";
import { Colors } from "common/Colors";
import { InputLength } from "common/inputLength";
import AssetsAPI from "services/assetsAPI";
import { useDispatch } from "react-redux";
import { setNewAssetName } from "features/assets-page/assetsDashboardSlice";
import { InputLabel } from "components/input-label/InputLabel";
import { useForm } from "react-hook-form";
import { RequiredAssetProps } from "components/asset-modals/types";

// import clsx from "clsx";

type UpdateAssetPropertiesProps = {
  assetProfile: AssetProfile;
  setAssetUpdated: Function;
  handleAssetUpdateModal: Function;
};

const UpdateAssetProperties: React.FC<UpdateAssetPropertiesProps> = ({
  assetProfile,
  setAssetUpdated,
  handleAssetUpdateModal,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    asset_name,
    asset_id,
    asset_category_id,
    parent_asset_id,
    parent_asset_group_id,
  } = assetProfile;

  const {
    property_1,
    property_2,
    property_3,
    property_4,
    property_5,
    property_6,
    property_7,
    property_8,
    property_9,
    property_10,
  } = assetProfile.property_values;

  const [assetName, setAssetName] = useState("");
  const [assetProperties, setAssetProperties] = useState({
    property_1,
    property_2,
    property_3,
    property_4,
    property_5,
    property_6,
    property_7,
    property_8,
    property_9,
    property_10,
  });
  const [categoryList, setCategoryList] = useState<CategoryObject[]>([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const { register, handleSubmit, errors } = useForm<RequiredAssetProps>();
  // ! need assset category to be added to asset profile

  const onUpdateAsset = async ({ assetName }: RequiredAssetProps) => {
    const updatedAssetObject = {
      asset_name: assetName,
      asset_id: asset_id,
      asset_sharing: true,
      shared_customers: [],
      asset_category_id: categoryId, //! need to change this to have name and id
      parent_asset_id: parent_asset_id || parent_asset_group_id,
      property_values: assetProperties,
    };

    try {
      const newObject = await AssetsAPI.updateAsset(updatedAssetObject);

      dispatch(setNewAssetName(newObject.asset_name));
      setAssetUpdated(true);
      handleAssetUpdateModal();
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (asset_category_id) {
          const categoryList = await AssetsAPI.getAssetCategoryList();

          const categoryName = categoryList.filter(
            (categoryObject: CategoryObject) =>
              categoryObject.asset_category_id === asset_category_id
          )[0].category_name;

          setCategoryList(categoryList);
          setCategoryName(categoryName);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [asset_category_id]);

  useEffect(() => {
    setAssetName(asset_name);
    setCategoryId(asset_category_id);
    setAssetProperties({
      property_1,
      property_2,
      property_3,
      property_4,
      property_5,
      property_6,
      property_7,
      property_8,
      property_9,
      property_10,
    });
  }, [
    asset_name,
    asset_category_id,
    property_1,
    property_2,
    property_3,
    property_4,
    property_5,
    property_6,
    property_7,
    property_8,
    property_9,
    property_10,
  ]);

  return (
    <>
      <form onSubmit={handleSubmit(onUpdateAsset)}>
        <Grid container>
          <Grid className={classes.propertyColumn} item xs={6}>
            <div className={classes.inputGroup}>
              <div className={classes.inputName}>
                <InputLabel
                  label="Asset name"
                  name="assetName"
                  errors={errors}
                />
              </div>
              <input
                name="assetName"
                ref={register({
                  required: "This field is required.",
                })}
                maxLength={InputLength.AssetName}
                type="text"
                placeholder="Enter"
                value={assetName}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setAssetName(e.currentTarget.value)
                }
              />
            </div>
            <div
              className={classes.inputGroup}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <InputLabel
                label="Asset category type"
                name="assetCategoryId"
                errors={errors}
                isRequired={false}
              />
              :
              {/* <select
                name="assetCategoryId"
                ref={register({
                  required: "This field is required.",
                })}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setCategoryId(e.currentTarget.value)
                }
              >
                {categoryList.map((categoryObject: CategoryObject) => {
                  return (
                    <option
                      key={categoryObject.asset_category_id}
                      value={categoryObject.asset_category_id}
                      selected={
                        categoryObject.asset_category_id === asset_category_id
                      }
                    >
                      {categoryObject.category_name}
                    </option>
                  );
                })}
              </select>  */}
              <span style={{ fontWeight: 600, marginLeft: "1rem" }}>
                {categoryName}
              </span>
            </div>
            {property_1.name && (
              <div className={classes.inputGroup}>
                <div className={classes.inputName}>
                  <span>{property_1.name}</span>
                </div>
                <input
                  maxLength={InputLength.PropertyName}
                  type="text"
                  placeholder="Enter"
                  value={assetProperties.property_1.value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAssetProperties({
                      ...assetProperties,
                      property_1: {
                        name: property_1.name,
                        value: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            )}
            {property_2.name && (
              <div className={classes.inputGroup}>
                <div className={classes.inputName}>
                  <span>{property_2.name}</span>
                </div>
                <input
                  maxLength={InputLength.PropertyName}
                  type="text"
                  placeholder="Enter"
                  value={assetProperties.property_2.value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAssetProperties({
                      ...assetProperties,
                      property_2: {
                        name: property_2.name,
                        value: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            )}
            {property_3.name && (
              <div className={classes.inputGroup}>
                <div className={classes.inputName}>
                  <span>{assetProperties.property_3.name}</span>
                </div>
                <input
                  maxLength={InputLength.PropertyName}
                  type="text"
                  placeholder="Enter"
                  value={assetProperties.property_3.value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAssetProperties({
                      ...assetProperties,
                      property_3: {
                        name: property_3.name,
                        value: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            )}
            {property_4.name && (
              <div className={classes.inputGroup}>
                <div className={classes.inputName}>
                  <span>{property_4.name}</span>
                </div>
                <input
                  maxLength={InputLength.PropertyName}
                  type="text"
                  placeholder="Enter"
                  value={assetProperties.property_4.value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAssetProperties({
                      ...assetProperties,
                      property_4: {
                        name: property_4.name,
                        value: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            )}
          </Grid>
          <Grid className={classes.propertyColumn} item xs={6}>
            {property_5.name && (
              <div className={classes.inputGroup}>
                <div className={classes.inputName}>
                  <span>{property_5.name}</span>
                </div>
                <input
                  maxLength={InputLength.PropertyName}
                  type="text"
                  placeholder="Enter"
                  value={assetProperties.property_5.value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAssetProperties({
                      ...assetProperties,
                      property_5: {
                        name: property_5.name,
                        value: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            )}
            {property_6.name && (
              <div className={classes.inputGroup}>
                <div className={classes.inputName}>
                  <span>{property_6.name}</span>
                </div>
                <input
                  maxLength={InputLength.PropertyName}
                  type="text"
                  placeholder="Enter"
                  value={assetProperties.property_6.value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAssetProperties({
                      ...assetProperties,
                      property_6: {
                        name: property_6.name,
                        value: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            )}
            {property_7.name && (
              <div className={classes.inputGroup}>
                <div className={classes.inputName}>
                  <span>{property_7.name}</span>
                </div>
                <input
                  maxLength={InputLength.PropertyName}
                  type="text"
                  placeholder="Enter"
                  value={assetProperties.property_7.value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAssetProperties({
                      ...assetProperties,
                      property_7: {
                        name: property_7.name,
                        value: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            )}
            {property_8.name && (
              <div className={classes.inputGroup}>
                <div className={classes.inputName}>
                  <span>{property_8.name}</span>
                </div>
                <input
                  maxLength={InputLength.PropertyName}
                  type="text"
                  placeholder="Enter"
                  value={assetProperties.property_8.value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAssetProperties({
                      ...assetProperties,
                      property_8: {
                        name: property_8.name,
                        value: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            )}
            {property_9.name && (
              <div className={classes.inputGroup}>
                <div className={classes.inputName}>
                  <span>{property_9.name}</span>
                </div>
                <input
                  maxLength={InputLength.PropertyName}
                  type="text"
                  placeholder="Enter"
                  value={assetProperties.property_9.value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAssetProperties({
                      ...assetProperties,
                      property_9: {
                        name: property_9.name,
                        value: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            )}
            {property_10.name && (
              <div className={classes.inputGroup}>
                <div className={classes.inputName}>
                  <span>{property_10.name}</span>
                </div>
                <input
                  maxLength={InputLength.PropertyName}
                  type="text"
                  placeholder="Enter"
                  value={assetProperties.property_10.value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setAssetProperties({
                      ...assetProperties,
                      property_10: {
                        name: property_10.name,
                        value: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            )}
          </Grid>
          <Grid className={classes.buttonContainer} item xs={12}>
            <button
              className={classes.cancelButton}
              type="button"
              onClick={() => console.log("CANCEL")}
            >
              Cancel
            </button>
            <button
              className={classes.updateButton}
              type="submit"
              // onClick={onUpdateAsset} //! Update asset on react tree here using change nodeAtPath.
            >
              Save Updates
            </button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default UpdateAssetProperties;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    propertyColumn: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      // margin: "1rem 0",
    },
    propertyInformation: {
      display: "grid",
      gridTemplateColumns: "30% 70%",
      // margin: "1rem 0",
      fontSize: 16,
    },
    inputGroup: {
      padding: "1em 1em 1em 0",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem",
      },
      "& input": {
        width: "75%",
        padding: ".7rem 1rem",
        border: `1px solid ${Colors.LightGray}`,
      },
      "& select": {
        width: "75%",
        padding: ".7rem 1rem",
        border: `1px solid ${Colors.LightGray}`,
        cursor: "pointer",
      },
    },
    inputName: {
      width: "75%",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      margin: "1em 0 0 0",
    },
    cancelButton: {
      border: `1px solid ${theme.palette.primary.light}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      background: Colors.White,
      width: 150,
      height: 30,
      color: theme.palette.primary.light,
      cursor: "pointer",
      margin: "0 1em 0 0",
    },
    updateButton: {
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      background: theme.palette.primary.light,
      width: 150,
      height: 30,
      color: Colors.White,
      cursor: "pointer",
    },
  })
);
