/* Issue fixed for github issue #56 */
import { Colors } from "common/Colors";

export const grabCurrentColor = (singleAsset: boolean, asset: any) => {

  const displayColors = (deviceState: boolean | null) => {
    let color: string = "";

    switch (deviceState) {
      case true:
        color = Colors.DashboardYellow;
        break;
      case false:
        color = Colors.DashboardRed;
        break;
      case null:
        color = Colors.DashboardGreen;
        break;
      default:
        break;
    }
    return color;
  };

  //Added a ternary operator for sending the warning state of complex asset and other assets. Github issue #56
  var warningLevel = asset.hasOwnProperty("warning") ? asset.warning : asset.device_warning;
  return displayColors(warningLevel);
};
