import React, { useState, useEffect } from 'react';
import { Paper, IconButton } from '@material-ui/core';
import { Exception } from 'features/exceptions-page/types';
import { makeStyles } from '@material-ui/core/styles';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

type KanbanColumnProps = {
  title: string;
  exceptions: Exception[];
  children: React.ReactNode;
  headerColor: string;
  windowWidth: number; // Add windowWidth prop
};

const useStyles = makeStyles((theme) => ({
  column: {
    backgroundColor: "#adadad", // dark gray
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginTop: '10px',
    borderRadius: '8px',
    marginBottom: '10px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    backgroundColor: (props: { headerColor: string }) => props.headerColor,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '24px',
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer', // Add cursor pointer for clickable effect
  },
  titleText: {
    flexGrow: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '20px',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const KanbanColumn: React.FC<KanbanColumnProps> = ({ title, exceptions, children, headerColor, windowWidth }) => {
  const classes = useStyles({ headerColor });
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (windowWidth >= 1080) {
      setIsVisible(true);
    }
  }, [windowWidth]);

  const handleToggleVisibility = () => {
    if (windowWidth < 1080) {
      setIsVisible(!isVisible);
    }
  };

  return (
    <Paper className={classes.column}>
      <div className={classes.titleContainer} onClick={handleToggleVisibility}>
        <span className={classes.titleText}>
          {exceptions.length} {title}
        </span>
        {windowWidth < 1080 && (
          <IconButton>
            {isVisible ? <RemoveCircleOutlineIcon style={{ color: 'white' }} /> : <ControlPointIcon style={{ color: 'white' }} />}
          </IconButton>
        )}
      </div>
      <div className={classes.content}>
        {isVisible && children}
      </div>
    </Paper>
  );
};

export default KanbanColumn;
