//Added file for github issue #273
import React, { useState, useEffect, useCallback } from "react";
import { useStyles } from "./styles";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setRecallDeviceList } from "features/devices-page/devices-table/deviceTableSlice";
import { devicesAPI } from "services/devicesAPI";
import { RootState } from "app/rootReducer";
import ReportsTable from "features/reports-page/ReportsTable";
import Button from "components/common-buttons/Button";
import LoadSpinner from "common/LoadSpinner";
import { setRecallReportList } from "features/drawer/drawerSlice";
import ReportsDashboard from "./ReportsDashboard";

export interface ReportAssetProps { }

const ReportAsset: React.FC<ReportAssetProps> = props => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [deviceList, setDeviceList] = useState<any>([]);
  const [showAddReportModal, setShowAddReportModal] = useState(false);
  const [showUpdateReportModal, setShowUpdateReportModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [allReportsList, setAllReportsList] = useState<any>([]);

  const { accountId } = useSelector(
    (state: RootState) => state.AppSlice.selectedAccount
  );

  const userId = useSelector(
    (state: RootState) => state.AppSlice.userInformation.user_id
  );

  const { recallDeviceList } = useSelector(
    (state: RootState) => state.DeviceTableSlice
  );

  const { recallReportList } = useSelector(
    (state: RootState) => state.DrawerSlice
  );

  const grabTenantsDeviceList = useCallback(async (tenant_id: string) => {
    var sortedRes = [];
    try {
      if (!tenant_id) {
        return;
      }
      let response = await devicesAPI.retrieveListOfDevices(tenant_id);
      // sorting the parent nodes before mixing with gateway nodes so that gateway nodes don't show up under different parent nodes for issue #256
      //Added function to sort the device list for issue #218
      response.sort((a: any, b: any) => {
        if (a.device_id < b.device_id) return -1;
        return a.device_id > b.device_id ? 1 : 0;
      });
      //Added for loop for creating an array for parent and child data to be shown in the table. github issue #94
      for (let res of response) {
        if (res.node_connected == true) {
          sortedRes.push(res);
          for (let nodes of res.node) {
            sortedRes.push(nodes);
          }
        } else {
          sortedRes.push(res);
        }
      }
      //Added function to sort the device list for issue #218
      // sortedRes.sort((a: any, b: any) => {
      //   if (a.device_id < b.device_id) return -1;
      //   return a.device_id > b.device_id ? 1 : 0;
      // });
      setDeviceList(sortedRes);
      setLoading(false);
    } catch (error) {
      console.log(error);
      alert(error);
      setDeviceList([]);
    }
  }, []);

  useEffect(() => {
    if (accountId) {
      setLoading(true);
      grabTenantsDeviceList(accountId);
      // retrieveDeviceConfigurations(accountId); // !grabs all known device configurations
    }
  }, [
    accountId,
    grabTenantsDeviceList
    // ,retrieveDeviceConfigurations
  ]);

  // for getting list of reports for issue #273
  useEffect(() => {
    const getReportList = async () => {
      const reportList = await devicesAPI.listOfReports(accountId);
      setAllReportsList(reportList);
    };

    getReportList();

    if (recallReportList) {
      getReportList();
      dispatch(setRecallReportList(false));
    }
  }, [accountId, recallReportList]);

  useEffect(() => {
    if (recallDeviceList) {
      grabTenantsDeviceList(accountId);
      dispatch(setRecallDeviceList(false));
    }
  }, [recallDeviceList, grabTenantsDeviceList, dispatch, accountId]);

  // for closing the add or update report modal for issue #273
  const closeReportModal = () => {
    setShowAddReportModal(false);
    setShowUpdateReportModal(false);
    dispatch(setRecallReportList(true));
  };

  // for opening up update report modal when clicked on any report for issue #273
  const handleUpdateModal = (report: any) => {
    setSelectedReport(report);
    setShowUpdateReportModal(true);
  };

  if (loading) {
    return <LoadSpinner center={true} size={"90vh"} />;
  }

  return (
    <div className={styles.deviceContainer}>
      {!showAddReportModal && !showUpdateReportModal ? (
        <>
          <Grid
            item
            xs={12}
            className={styles.buttonContainer}
            style={{ justifyContent: "flex-end", marginBottom: "1rem" }}
          >
            <Button
              type="button"
              color="defaultTheme"
              onClick={() => setShowAddReportModal(true)}
            >
              + Add new report
            </Button>
          </Grid>
          <Grid item xs={12}>
            {/* table to show all reports for issue #273 */}
            <ReportsTable allReportsList={allReportsList} tenantId={accountId} handleUpdateModal={handleUpdateModal} />
          </Grid>
        </>
      ) : (
        <div style={{ display: "flex" }}>
          {/* for add report modal for issue #273 */}
          {showAddReportModal && (
            <ReportsDashboard
              parentTenantId={accountId}
              userId={userId}
              selectedReport={selectedReport}
              closeReportModal={closeReportModal}
              typeOfReportModal="Add"
            />
          )}
          {/* for update report modal for issue #237 */}
          {showUpdateReportModal && (
            <ReportsDashboard
              parentTenantId={accountId}
              userId={userId}
              selectedReport={selectedReport}
              closeReportModal={closeReportModal}
              typeOfReportModal="Update"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ReportAsset;
