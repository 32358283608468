export enum InputLength {
  AccountName = 50,
  FirstName = 35,
  LastName = 35,
  Email = 64,
  ZipCode = 9,
  City = 35,
  StateProvince = 35,
  Address = 50,
  PhoneNumber = 11,
  General = 35,
  AssetName = 35,
  PropertyName,
}
