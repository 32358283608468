//Added file for github issue #273
// styles for reports for issue #273
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowY: "auto",
    },
    lineBreak: {
      width: "100%",
      border: `1px solid ${Colors.LightGray}`,
    },
    table: {
      minWidth: "100%",
      marginTop: "1rem",
      borderTop: `2px solid ${Colors.LightGray}`,
    },
    tableCell: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 0",
      margin: 0,
      borderBottom: `2px dotted ${Colors.LightGray}`,
      fontSize: 14,
    },
    device: {
      color: theme.palette.primary.light,
      fontSize: 18,
      margin: 0,
    },
    header: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      // padding: 0,
      borderTop: "2px solid #DDD",
      borderBottom: "2px solid #DDD",
    },
    tableRow: {
      textDecoration: "none",
      "&:focus &:hover &:link &:visited &:active": {
        textDecoration: "none",
      },
    },
    serialNo: {
      display: "flex",
      alignItems: "center",
      padding: 0,
    },
    ellipsis: {
      cursor: "pointer",
      color: theme.palette.primary.light,
      height: "2rem",
    },
    flex: {
      display: "flex",
      alignItems: "center",
      padding: "0 0 0 1rem",
    },
    flex1: {
      display: "flex",
      alignItems: "center",
      padding: "0 0 0 2rem",
    },
    dot: {
      backgroundColor: "LightGreen",
      height: ".6rem",
      width: ".6rem",
      borderRadius: "50%",
      marginRight: "1rem",
    },
    pill: {
      color: "black",
      backgroundColor: Colors.LightGray,
      margin: 0,
      padding: ".3rem .8rem",
      borderRadius: "25px",
    },
    bluePill: {
      color: "skyblue",
      border: "1px solid skyblue",
      margin: 0,
      padding: ".3rem .8rem",
      borderRadius: "25px",
    },
    genericPill: {
      margin: 0,
      padding: ".3rem .8rem",
      borderRadius: "25px",
    },
    greenPill: {
      fontWeight: "bold",
      color: "green",
      border: `1px solid green`,
    },
    orangePill: {
      fontWeight: "bold",
      color: "white",
      backgroundColor: `orange`,
    },
    pinkPill: {
      fontWeight: "bold",
      color: "white",
      backgroundColor: Colors.Pink,
    },
    notPermitted: {
      opacity: 0.5,
      pointerEvents: "none",
    },
    disabled: {
      color: Colors.Gray
    },
    name: {
      color: theme.palette.primary.light,
      fontWeight: "bold",
      cursor: "pointer"
    },
    //adding css style classes for styling the delete account modal and popover for issue #33
    //css class for styling delete popover option
    selectionContainer: {
      padding: ".5rem 1rem",
    },

    //css class for styling delete option for issue #33
    selection: {
      fontSize: "14px",
      color: theme.palette.primary.light,
      margin: 0,
      cursor: "pointer",
    },
    columnText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      // padding: 0,
      borderTop: "2px solid #DDD",
      borderBottom: "2px solid #DDD",
    },
    deviceContainer: {
      marginTop: "1rem",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      // alignItems: "space-between",
      justifyContent: "space-between",
    },
    select: {
      border: `1px solid ${Colors.LightGray}`,
      padding: ".5rem .5rem .5rem 1rem",
      color: theme.palette.primary.light,
      fontWeight: "bold",
      "& option": {
        color: theme.palette.primary.light,
        fontWeight: "bold",
      },
      cursor: "pointer",
    },
    exportButton: {
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: "#fff",
      // width: 160,
      color: theme.palette.primary.light,
      // fontWeight: 600,
      padding: ".5rem 1rem",
      marginRight: "1rem",
      cursor: "pointer",
    },
    addButton: {
      color: "white",
      backgroundColor: theme.palette.primary.light,
      // width: 160,
      border: "none",
      // fontWeight: 600,
      padding: ".5rem 1rem",
      cursor: "pointer",
    },
    exportIcon: {
      stroke: theme.palette.primary.light,
      marginRight: ".5rem",
    },
    innerInput: {
      padding: "0 2.5rem",
      width: "100%"
      // maxHeight: 700, // causes double scrollbar overflow on certain window heights
      // overflowY: "auto",
    },
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)",
    },
    modalBody: {
      minHeight: "15rem",
      width: 500,
      backgroundColor: "white",
    },
    headerDiv: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "2rem 2.5rem",
      borderBottom: `1px solid ${Colors.LightGray}`,
    },
    headerText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      fontSize: 24,
      padding: 0,
      margin: 0,
    },
    icon: {
      color: Colors.LightGray,
      cursor: "pointer",
      fontSize: "3rem",
    },
    pathText: {
      backgroundColor: Colors.LightGray,
    },
    labelText: {
      display: "flex",
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        marginRight: "1rem",
        fontSize: "1rem",
      },
    },
    deleteDescription: {
      padding: "0 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem",
      },
    },
    actionItems: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "2rem 2.5rem",
    },
    role: {
      color: Colors.Black,
      fontSize: 14
    },
  })
);
