import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Colors } from "./Colors";

interface Props {
  center?: boolean;
  size?: number | string;
}

const LoadSpinner: React.FC<Props> = ({ center, size }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.loadSpinner}
      style={{
        margin: center ? "auto" : 0,
        textAlign: center ? "center" : "left",
        height: size,
      }}
    >
      <CircularProgress className={classes.circleProgress} />
    </div>
  );
};

export default LoadSpinner;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadSpinner: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    circleProgress: {
      color: theme.palette.primary.light,
    },
  })
);
