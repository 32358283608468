import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";

export const commonButtonStyle = makeStyles((theme: Theme) =>
  createStyles({
    defaultTheme: {
      border: `1px solid ${theme.palette.primary.light}`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.primary.light,
      height: 30,
      color: Colors.White,
      padding: ".5rem 1rem",
      cursor: "pointer",
      outline: "none",
      "&:hover": {
        opacity: ".8",
      },
    },
    white: {
      border: `1px solid ${theme.palette.primary.light}`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: Colors.White,
      height: 30,
      padding: ".5rem 1rem",
      color: theme.palette.primary.light,
      marginRight: "1rem",
      cursor: "pointer",
      "&:hover": {
        opacity: ".8",
      },
    },
    googleLink: {
      margin: "1rem 0",
      color: theme.palette.primary.light,
      textDecoration: "none",
      "&:hover": { opacity: 0.8 },
    },
  })
);
