/* Added this file for update token modal for github issue #143 */
import React, { useState, useEffect, ChangeEvent, useCallback } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import {
  setSuccessModalOpen,
  setSuccessModalDescription
} from "components/success-modal/successModalSlice";
import { setCallTokens } from "../accountSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "app/store";
import { RootState } from "app/rootReducer";
import { Grid } from "@material-ui/core";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { InputLabel } from "components/input-label/InputLabel";
import LoadSpinner from "common/LoadSpinner";
import Button from "components/common-buttons/Button";
import Select from "react-select";
import AccountsAPI from "services/accountsAPI";
import { adminRoles, powerUserRoles } from "./roles";
import { regex } from "utils/regexPatterns";
import { RequiredAPITokenProps, UsersGroupsProps } from "./types";
import Xsquare from "assets/bt-assets/x-square.svg";
import { LinearIndeterminate } from "common/LinearLoading";
import Checkbox from "@material-ui/core/Checkbox";
import { ActivateDeactivateTokenModal } from "./ActivateDeactivateTokenModal";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Calendar } from "react-date-range";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import { customDateFormatUnix } from "utils/helpers";
import { fromUnixTime, getUnixTime } from "date-fns";
import calendarWhite from "assets/bt-assets/calendar_white.svg";

type UpdateAPITokenModalProps = {
  handleClose: () => void;
  open: boolean;
  selectedToken: any;
  setCallToken: Function;
};

export const UpdateAPITokenModal: React.FC<UpdateAPITokenModalProps> = ({
  handleClose, // to close the create api token modal for issue #143
  open, //to open the create api token modal for issue #143
  selectedToken, //details of the token which was clicked for issue #143
  setCallToken //to recall token list after updation for issue #143
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [tokenActivity, setTokenActivity] = useState(false);
  const [linearLoading, setLinearLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [devicesAccessLevel, setDevicesAccessLevel] = useState(false);
  const [groupsAccessLevel, setGroupsAccessLevel] = useState(false);
  const [tokenDate, setTokenDate] = useState(selectedToken.token_expiry);

  // ! this will be UsersGroupsProps[][]
  const [usersGroupSelection, setUsersGroupSelection] = useState<
    UsersGroupsProps[][]
  >([]);
  const [
    activateDeactivateTokenModal,
    setActivateDeactivateTokenModal
  ] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    errors,
    control,
    reset,
    watch
  } = useForm<RequiredAPITokenProps>();

  const { userInformation } = useSelector((state: RootState) => state.AppSlice);

  const {
    role: selectedAccountRole,
    accountId: selectedAccountId,
    accountName: selectedAccountName
  } = useSelector((state: RootState) => state.AccountSlice.selectedAccount);

  const { tenant_id } = useSelector(
    (state: RootState) => state.AccountSlice.accountInformation
  );

  // to close the activate deactivate modal for issue #143
  const closeActivateDeactivateTokenModal = () => {
    setActivateDeactivateTokenModal(false);
  };

  // to set the calendar date timestamp for issue #143
  const calendarExpiryHandler = useCallback(
    (date: Date) => {
      const expiry = getUnixTime(date);
      setTokenDate(expiry);
    },
    [tokenDate]
  );

  // function to update the token for issue #143
  const updateCurrentToken = async ({
    token_name,
    token_access,
    token_expiry,
    access_groups,
    devices,
    groups
  }: RequiredAPITokenProps) => {
    try {
      token_access = [];
      // if devices checkbox is ticked then pushing that inside token_access array for issue #143
      if (devices && devices != undefined) token_access.push("devices");
      // if groups checkbox is ticked then pushing that inside token_access array for issue #143
      if (groups && groups != undefined) token_access.push("groups");
      token_expiry = String(tokenDate); //assigning the expiry date to token_expiry var for issue #143
      setLoading(true);
      // api to update token for issue #143
      await AccountsAPI.updateToken(
        tenant_id,
        selectedToken.token_id,
        token_name,
        token_expiry,
        token_access,
        access_groups
      );
      setLoading(false);
      dispatch(setCallTokens(true)); //calling token list after successful token creation for issue #143
      dispatch(setSuccessModalDescription("Token successfully updated!"));
      dispatch(setSuccessModalOpen(true));
      handleClose();
    } catch (error) {
      console.log(error);
      dispatch(setCallTokens(true));
      setLoading(false);
      handleClose();
    }
  };

  useEffect(() => {
    (async () => {
      // function to get all access groups in selected account for issue #143
      if (tenant_id) {
        try {
          //to get acccount list
          const allAccounts = await AccountsAPI.getAllTenants();
          const allTenantsGroups = allAccounts.filter(
            (account: any) => account.tenant_id === tenant_id
          )[0];

          // to get all access groups inside an account
          const getAllGroupsForTenantSuperAdmin = await AccountsAPI.retrieveGroupsInGroups(
            [allTenantsGroups.master_group_id]
          );
          // setting user groups for issue #143
          setUsersGroupSelection(getAllGroupsForTenantSuperAdmin);
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [tenant_id, userInformation.accounts]);

  // for group names in access groups input for issue #143
  const formatGroupLabel = (data: any) => (
    <div className={classes.group}>
      <span>{data.label}</span>
      <span className={classes.groupBadge}>{data.options.length}</span>
    </div>
  );

  useEffect(() => {
    // to map the already selected value for devices and groups checkbox when update modal is opened for issue #143
    selectedToken?.token_access.map((item: any) => {
      if (item == "devices") {
        setDevicesAccessLevel(true);
      }
      if (item == "groups") {
        setGroupsAccessLevel(true);
      }
    });
  }, [selectedToken]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalOverlay}>
          {loading ? (
            <LoadSpinner />
          ) : (
            <form onSubmit={handleSubmit(updateCurrentToken)}>
              <Grid className={classes.modalBody}>
                <Grid item xs={12} className={classes.header}>
                  <p className={classes.headerText}>Edit Token</p>
                  <CloseIcon
                    className={classes.closeIcon}
                    onClick={handleClose}
                  />
                </Grid>
                <div className={classes.innerInputs}>
                  <Grid item>
                    <div className={classes.inputGroup}>
                      {/* input for token name for issue #143 */}
                      <InputLabel
                        label="Token name"
                        name="token_name"
                        errors={errors}
                      />
                      <input
                        name="token_name"
                        defaultValue={selectedToken.token_name}
                        ref={register({
                          required: "This field is required."
                          // pattern: {
                          //   value: /^[a-zA-Z_ ]*$/,
                          //   message: "Letters only."
                          // }
                        })}
                        maxLength={35}
                        type="text"
                        placeholder="Enter"
                      />
                    </div>
                  </Grid>
                  <Grid item>
                    <div className={classes.inputGroup}>
                      {/* showing the token which is assigned for issue #143 (this field is not editable by user) */}
                      <InputLabel label="Token" name="token" errors={errors} />
                      {selectedToken.token}
                    </div>
                    <div className={classes.inputGroup}>
                      {/* showing the token which is assigned for issue #143 (this field is not editable by user) */}
                      <InputLabel label="API key" name="api_key" errors={errors} />
                      {selectedToken.api_key}
                    </div>
                  </Grid>
                  <div>
                    {/* input for access level for issue #143 */}
                    <InputLabel
                      label="Access Level"
                      name="token_access"
                      errors={errors}
                    />
                    <Grid item>
                      {/* checkbox for devices for issue #143 */}
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "inline-flex",
                          justifyContent: "flex-end",
                          alignItems: "center"
                        }}
                      >
                        <Controller
                          control={control}
                          name="devices"
                          defaultValue={devicesAccessLevel}
                          rules={{
                            required: false
                          }}
                          render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                          ) => (
                            <Checkbox
                              onBlur={onBlur}
                              onChange={e => onChange(e.target.checked)}
                              checked={value}
                              inputRef={ref}
                            />
                          )}
                        />
                        <InputLabel
                          label="Devices"
                          name="devices"
                          errors={errors}
                          isRequired={false}
                        />
                      </Grid>
                      {/* checkbox for groups for issue #143 */}
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "inline-flex",
                          justifyContent: "flex-end",
                          alignItems: "center"
                        }}
                      >
                        <Controller
                          control={control}
                          name="groups"
                          defaultValue={groupsAccessLevel}
                          rules={{
                            required: false
                          }}
                          render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                          ) => (
                            <Checkbox
                              onBlur={onBlur}
                              onChange={e => onChange(e.target.checked)}
                              checked={value}
                              inputRef={ref}
                            />
                          )}
                        />
                        <InputLabel
                          label="Groups"
                          name="groups"
                          errors={errors}
                          isRequired={false}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <Grid>
                    {/* input with calendar for token expiry date for issue #143 */}
                    {/* User cannot set any date which is before the date shown in update modal */}
                    <div className={classes.inputGroup}>
                      <div className={classes.inputName}>
                        Token expiry{" "}
                        <span className={classes.invalidText}>*</span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            overflow: "hidden",
                            display: "flex"
                          }}
                        >
                          <input
                            style={{
                              height: 30,
                              marginRight: 16,
                              cursor: "pointer"
                            }}
                            onClick={() => setCalendarOpen(true)}
                            value={
                              tokenDate
                                ? customDateFormatUnix(
                                  Number(tokenDate),
                                  "MMM dd yyyy"
                                )
                                : "N/A"
                            }
                          />
                          <Button
                            type="button"
                            color="defaultTheme"
                            onClick={() => setCalendarOpen(true)}
                          >
                            <img
                              src={calendarWhite}
                              alt="Subscription Expiry Calendar"
                            />
                          </Button>
                          {calendarOpen && (
                            <div className={classes.calendarDiv}>
                              <ClickAwayListener
                                onClickAway={() => setCalendarOpen(false)}
                              >
                                <Calendar
                                  minDate={
                                    fromUnixTime(Number(tokenDate || 0)) <
                                      new Date() // check if current device expiry date is before today
                                      ? new Date()
                                      : fromUnixTime(Number(tokenDate))
                                  }
                                  date={
                                    tokenDate
                                      ? fromUnixTime(Number(tokenDate))
                                      : new Date()
                                  }
                                  onChange={calendarExpiryHandler}
                                />
                              </ClickAwayListener>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item className={classes.inputGroup}>
                    {/* dropdown for access groups for issue #143 */}
                    <InputLabel
                      label="Access group(s)"
                      name="access_groups"
                      errors={errors}
                      isRequired={false}
                    />
                    <Controller
                      render={props => {
                        return (
                          <Select
                            {...props}
                            menuPosition={"fixed"}
                            // styles={reactSelectStyles}
                            defaultValue={null}
                            formatGroupLabel={formatGroupLabel}
                            options={usersGroupSelection}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isMulti
                          />
                        );
                      }}
                      name="access_groups"
                      defaultValue={selectedToken.access_groups}
                      type="select"
                      control={control}
                      rules={{
                        required: false
                      }}
                    />
                  </Grid>
                </div>
                <Grid
                  item
                  xs={12}
                  className={classes.actionItems}
                  style={{ justifyContent: "flex-end" }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      type="submit"
                      color="white"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" color="defaultTheme">
                      Save
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </div>
      </Modal>
      <ActivateDeactivateTokenModal
        open={activateDeactivateTokenModal}
        handleClose={closeActivateDeactivateTokenModal}
        tokenActivity={tokenActivity}
        tenantId={selectedAccountId}
        tokenId={selectedToken.tokenId}
      // setCallToken={setCallToken}
      />
    </div>
  );
};

export default UpdateAPITokenModal;

const inputStyle = {
  "& p": {
    padding: 0,
    margin: 0,
    fontSize: "1rem",
    marginBottom: ".5rem"
  },
  "& input": {
    width: "100%",
    padding: ".5rem 1rem",
    border: `1px solid ${Colors.LightGray}`
  },
  "& select": {
    width: "100%",
    padding: ".5rem 1rem",
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer"
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)"
    },
    modalBody: {
      maxHeight: "90vh",
      overflowY: "auto",
      // width: 830,
      // overflowY: "auto",
      backgroundColor: Colors.White,
      zIndex: 1
    },
    header: {
      // display: "flex",
      // alignItems: "center",
      // justifyContent: "space-between",
      // padding: "1rem 0",
      // borderBottom: `1px solid ${Colors.LightGray}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 2.5rem",
      margin: "0 0 1em 0",
      borderBottom: `1px solid ${Colors.LightGray}`
    },
    headerText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      fontSize: 24,
      padding: 0,
      margin: 0
    },
    closeIcon: {
      color: Colors.LightGray,
      cursor: "pointer",
      fontSize: "3rem"
    },
    innerInputs: {
      padding: "0 2.5rem",
      width: "100%"
      // maxHeight: 700, // causes double scrollbar overflow on certain window heights
      // overflowY: "auto",
    },
    heartIcon: {
      color: theme.palette.primary.light,
      cursor: "pointer"
    },
    inputGroup: {
      padding: "1rem 1rem 1rem 0",
      ...inputStyle
    },
    inputGroupRight: {
      padding: "1rem 0 1rem 1rem",
      ...inputStyle
    },
    labelContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    buttonContainer: {
      height: 30
    },
    button: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: Colors.White,
      cursor: "pointer",
      padding: "0 1rem",
      outline: "none",
      height: 30
    },
    disabledButton: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: Colors.LightGray,
      cursor: "none",
      padding: "0 1rem",
      outline: "none",
      height: 30
    },
    isActive: {
      backgroundColor: theme.palette.primary.light,
      color: Colors.White
    },
    active: {
      color: Colors.Green,
      fontWeight: 600,
      margin: "0 0 0 1rem"
    },
    notActive: {
      color: Colors.LightGray,
      fontWeight: 600,
      margin: "0 0 0 1rem"
    },
    accountInformation: {
      borderTop: `1px solid ${Colors.LightGray}`
    },
    accessAccount: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      "& p": {
        fontSize: 16,
        color: theme.palette.primary.light,
        margin: "0 0 0 .5rem",
        fontWeight: 600
      }
    },
    actionItems: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "1rem 2.5rem 0",
      borderTop: `1px solid ${Colors.LightGray}`,
      padding: "1rem 0"
    },
    inputName: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    group: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    groupBadge: {
      backgroundColor: "#EBECF0",
      borderRadius: "2em",
      color: "#172B4D",
      display: "inline-block",
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "1",
      minWidth: 1,
      padding: "0.16666666666667em 0.5em",
      textAlign: "center"
    },
    isBasinTrakEmployee: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    invalidText: {
      margin: "0 0 0 1px",
      color: "#ED254E"
    },
    calendarDiv: {
      position: "absolute",
      marginTop: 30,
      zIndex: 999,
      top: "30%",
      border: "1px solid",

      [`@media (min-height:800px)`]: {
        top: "auto"
      }
    }
  })
);
