import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  callGroupProfile: boolean;
  callAssetProfile: boolean;
} = {
  callGroupProfile: false,
  callAssetProfile: false,
};

const assetGroupModalSlice = createSlice({
  name: "AssetGroupModalSlice",
  initialState,
  reducers: {
    setCallGroupProfile: (state, action: PayloadAction<boolean>) => {
      state.callGroupProfile = action.payload;
    },
    setCallAssetProfile: (state, action: PayloadAction<boolean>) => {
      state.callAssetProfile = action.payload;
    },
  },
});

export const {
  setCallGroupProfile,
  setCallAssetProfile,
} = assetGroupModalSlice.actions;
export default assetGroupModalSlice.reducer;
