/* Issue fixed for github issue #24, issue #59, issue #36,issue #81,issue #133,issue #140,issue #261*/
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { NotificationProps } from "./_types";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Colors } from "common/Colors";
import { handleTimeStamp } from "utils/helpers"; //imported for issue #133

const NotificationCards: React.FC<NotificationProps> = ({
  amount,
  alertLevel,
  assetLevel, //Added asset level to show text according to asset level on the notifications tiles for issue #81
  tileNumber, //Added tileNumber to show different text on 1st and 2nd tile at asset level for issue #133
  timestamp //Added timestamp to show timestamp at asset level for issue #133
}: NotificationProps) => {
  const classes = useStyles();

  const displayText = () => {
    switch (alertLevel) {
      case "critical":
        return "Critical - Shutdown, events, faults";
      case "warning":
        return "Warning - Events, faults";
      case "notification":
        return "Notifications";
      //Added case nowarnings for grey tile for issue #133
      case "nowarnings":
        return "None";
      //Added case stopped for stopped assets for issue #133
      case "stopped":
        return "Stopped";
      // Added case running for running assets for issue #133
      case "running":
        return "Running";
      //Added case newOpen for new or open exceptions for issue #261
      case "newOpen":
        return "New/Open";
      // Added case onHold for on hold exceptions for issue #261
      case "onHold":
        return "On-hold";
      //Added case inProcess for in process exceptions for issue #261
      case "inProcess":
        return "In-process";
      //Added case closed for closed for exceptions for issue #261
      case "closed":
        return "Closed";
      default:
        break;
    }
  };

  return (
    <Grid
      item
      className={clsx({
        [classes.critical]: alertLevel === "critical",
        [classes.warning]: alertLevel === "warning",
        [classes.notification]: alertLevel === "notification",
        [classes.running]: alertLevel === "running", //Added class for running assets for issue #133
        [classes.stopped]: alertLevel === "stopped", //Added class for stopped assets for issue #133
        [classes.greytile]: alertLevel === "nowarnings", //Added class for assets with no warning or critical for issue #133
        [classes.newOpen]: alertLevel === "newOpen",  //Added class for new or open exceptions for issue #261
        [classes.onHold]: alertLevel === "onHold",  //Added class for on hold exceptions for issue #261
        [classes.inProcess]: alertLevel === "inProcess", //Added class for in process exceptions for issue #261
        [classes.closed]: alertLevel === "closed", //Added class for closed exceptions for issue #261
      })}
    >
      {/* Adding condition to show different tile color and text for 1st and 2nd tile for issue #133 */}
      {assetLevel && alertLevel !== "notification" ? (
        <>
          <Grid
            item
            //adding style class and removing "styles" tab for styling notification,warning and critical bars in responsive UI issue #24
            className={classes.gridtext1}
          >
            {/* Changed font size and margin for issue #140 */}
            <Typography style={{ fontSize: "12px", margin: ".2em 0" }}>
              {/* Added condition to show different text for 1st and 2nd tile for issue #133 */}
              {tileNumber == 1
                ? "Highest event level at"
                : tileNumber == 2
                  ? "Operation status at"
                  : ""}{" "}
              {handleTimeStamp(timestamp)}
            </Typography>
            {/* showing different text based on alert level for issue #133 */}
            {/* changed font size from 0.9 rem to 16px for issue #140 */}
            <Typography style={{ fontSize: "16px" }}>
              {displayText()}
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          {/* adding xs={4} for styling the item in responsive UI for issue #140 */}
          <Grid item lg={4} xs={4} className={classes.amountText}>
            <Typography variant="h3">{amount}</Typography>
          </Grid>
          <Grid
            item
            lg={8}
            //adding xs={10} for styling the item in responsive UI for issue #140
            xs={10}
            //adding style class and removing "styles" tab for styling notification,warning and critical bars in responsive UI issue #24
            className={classes.gridtext}
          >
            {/* Changed font size and margin for issue #140 */}
            <Typography style={{ fontSize: "12px", margin: ".2em 0" }}>
              {/* Added a ternary operator for showing the "Assets" for the critical and warning notification counts. Github issue #36 */}
              {
                alertLevel == "notification" ?
                  "In last 24 hours" :
                  alertLevel == "newOpen" ||
                    alertLevel == "onHold" ||
                    alertLevel == "inProcess" ||
                    alertLevel == "closed" ?
                    " " :
                    "Assets"
              }
            </Typography>
            {/* changed font size from 0.9 rem to 16px for issue #140 */}
            <Typography style={{ fontSize: "16px" }}>
              {displayText()}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default NotificationCards;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notification: {
      height: "2em",
      backgroundColor: Colors.DashboardBlue,
      color: "white",
      minHeight: "6em",
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.up("xs")]: {
        width: "100%"
      },

      //adding media query for notification bar responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginBottom: "0.625em",
        marginRight: "40px"
      },

      //Added media query for the notification bar for responsiveness. Github issue #59
      [`@media (min-width:801px) and (max-width: 1300px)`]: {
        marginBottom: "0.625em"
      }
    },
    critical: {
      height: "2em",
      backgroundColor: Colors.DashboardRed,
      color: "white",
      minHeight: "6em",
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.up("xs")]: {
        width: "100%"
      },

      //adding media query for critical bar responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginBottom: "0.625em",
        marginRight: "40px"
      },
      //Added media query for the notification bar for responsiveness. Github issue #59
      [`@media (min-width:801px) and (max-width: 1300px)`]: {
        marginBottom: "0.625em"
      }
    },
    warning: {
      height: "2em",
      backgroundColor: Colors.DashboardYellow,
      color: "white",
      minHeight: "6em",
      display: "flex",
      flexDirection: "row",

      [theme.breakpoints.up("xs")]: {
        width: "100%"
      },

      //adding media query for warning bar responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginBottom: "0.625em",
        marginRight: "40px"
      },
      //Added media query for the notification bar for responsiveness. Github issue #59
      [`@media (min-width:801px) and (max-width: 1300px)`]: {
        marginBottom: "0.625em"
      }
    },
    // Adding new css class for stopped assets for issue #133
    stopped: {
      height: "2em",
      backgroundColor: Colors.DashboardRed,
      color: "white",
      minHeight: "6em",
      display: "flex",
      flexDirection: "row",

      [theme.breakpoints.up("xs")]: {
        width: "100%"
      },

      //adding media query for warning bar responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginBottom: "0.625em",
        marginRight: "40px"
      },
      //Added media query for the notification bar for responsiveness. Github issue #59
      [`@media (min-width:801px) and (max-width: 1300px)`]: {
        marginBottom: "0.625em"
      }
    },
    // Adding css class for assets with no warnings or shutdowns for issue #133
    greytile: {
      height: "2em",
      backgroundColor: Colors.DashboardGreen,
      color: "white",
      minHeight: "6em",
      display: "flex",
      flexDirection: "row",

      [theme.breakpoints.up("xs")]: {
        width: "100%"
      },

      //adding media query for warning bar responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginBottom: "0.625em",
        marginRight: "40px"
      },
      //Added media query for the notification bar for responsiveness. Github issue #59
      [`@media (min-width:801px) and (max-width: 1300px)`]: {
        marginBottom: "0.625em"
      }
    },
    // Added css class for running assets for issue #133
    running: {
      height: "2em",
      backgroundColor: Colors.DashboardGreen,
      color: "white",
      minHeight: "6em",
      display: "flex",
      flexDirection: "row",

      [theme.breakpoints.up("xs")]: {
        width: "100%"
      },

      //adding media query for warning bar responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginBottom: "0.625em",
        marginRight: "40px"
      },
      //Added media query for the notification bar for responsiveness. Github issue #59
      [`@media (min-width:801px) and (max-width: 1300px)`]: {
        marginBottom: "0.625em"
      }
    },
    // Added css class for running assets for issue #133
    newOpen: {
      height: "2em",
      backgroundColor: Colors.BasinBlue,
      color: "white",
      minHeight: "6em",
      display: "flex",
      flexDirection: "row",

      [theme.breakpoints.up("xs")]: {
        width: "100%"
      },

      //adding media query for warning bar responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginBottom: "0.625em",
        marginRight: "40px"
      },
      //Added media query for the notification bar for responsiveness. Github issue #59
      [`@media (min-width:801px) and (max-width: 1300px)`]: {
        marginBottom: "0.625em"
      }
    },
    // Added css class for running assets for issue #133
    onHold: {
      height: "2em",
      backgroundColor: Colors.Orange,
      color: "white",
      minHeight: "6em",
      display: "flex",
      flexDirection: "row",

      [theme.breakpoints.up("xs")]: {
        width: "100%"
      },

      //adding media query for warning bar responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginBottom: "0.625em",
        marginRight: "40px"
      },
      //Added media query for the notification bar for responsiveness. Github issue #59
      [`@media (min-width:801px) and (max-width: 1300px)`]: {
        marginBottom: "0.625em"
      }
    },
    // Added css class for running assets for issue #133
    inProcess: {
      height: "2em",
      backgroundColor: Colors.DashboardGreen,
      color: "white",
      minHeight: "6em",
      display: "flex",
      flexDirection: "row",

      [theme.breakpoints.up("xs")]: {
        width: "100%"
      },

      //adding media query for warning bar responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginBottom: "0.625em",
        marginRight: "40px"
      },
      //Added media query for the notification bar for responsiveness. Github issue #59
      [`@media (min-width:801px) and (max-width: 1300px)`]: {
        marginBottom: "0.625em"
      }
    },
    // Added css class for running assets for issue #133
    closed: {
      height: "2em",
      backgroundColor: Colors.Gray,
      color: "white",
      minHeight: "6em",
      display: "flex",
      flexDirection: "row",

      [theme.breakpoints.up("xs")]: {
        width: "100%"
      },

      //adding media query for warning bar responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginBottom: "0.625em",
        marginRight: "40px"
      },
      //Added media query for the notification bar for responsiveness. Github issue #59
      [`@media (min-width:801px) and (max-width: 1300px)`]: {
        marginBottom: "0.625em"
      }
    },
    amountText: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      //adding media query for text in three bars for responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginLeft: "10px"
      }
    },

    //adding classes for styling three bars in mobile view for responsive UI issue #24
    gridtext: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      [`@media (max-width: 800px)`]: {
        marginLeft: "10px"
      }
    },
    gridtext1: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: "2em",
      [`@media (max-width: 800px)`]: {
        marginLeft: "50px"
      }
    }
  })
);
