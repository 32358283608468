import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { formModalStyles } from "common/formModalStyles";
// import ImageUploader from "react-images-upload";
import { AssetCategories, RequiredAssetCategoriesProps } from "./types";
import { InputLabel } from "components/input-label/InputLabel";
import Xsquare from "assets/bt-assets/x-square.svg";
import { useForm } from "react-hook-form";
import { assetCategoryStaticList } from "./assetsList";
import clsx from "clsx";
import Button from "components/common-buttons/Button";

type AssetCategoryModalProps = {
  open: boolean;
  handleClose: () => void;
  handleSave: Function;
};

export const AddNewAssetCategoryModal: React.FC<AssetCategoryModalProps> = ({
  open,
  handleClose,
  handleSave,
}) => {
  const classes = formModalStyles();
  const [assetSharing, setAssetSharing] = useState(true);
  // const [pictures, setPictures] = useState<any>([]);
  const [assetPropertyArray, setAssetPropertyArray] = useState<Array<number>>([
    1,
  ]);
  const [assetCategoryProperties, setAssetCategoryProperties] = useState<
    string[]
  >([]);
  let [assetPropertyCount, setAssetPropertyCount] = useState(1);
  const { register, handleSubmit, errors } =
    useForm<RequiredAssetCategoriesProps>();
  // const onDrop = (picture: any) => {
  //   setPictures([...pictures, picture]);
  // };

  const handleAssetPropertyCount = () => {
    if (assetPropertyArray.length !== 10) {
      setAssetPropertyCount((assetPropertyCount += 1));
      assetPropertyArray.push(assetPropertyCount);
    } else {
      return;
    }
  };

  const handleInputDeletes = (index: number) => {
    if (assetPropertyArray.length === 0) {
      setAssetPropertyArray([]);
      setAssetPropertyCount(0);
    }

    setAssetPropertyCount((assetPropertyCount -= 1));
    assetPropertyArray.splice(index, 1);
    const arrayToSubtract = assetPropertyArray
      .splice(index, assetPropertyArray.length)
      .map((item: number) => {
        return item - 1;
      });

    setAssetPropertyArray([...assetPropertyArray, ...arrayToSubtract]);
  };

  const handleAssetProperties = (index: number) => {
    const copyOfAssetCategoryPropertyArray = [...assetCategoryProperties];
    copyOfAssetCategoryPropertyArray.splice(index, 1);
    setAssetCategoryProperties(copyOfAssetCategoryPropertyArray);
  };

  const resetInputs = () => {
    // setAssetCategoryName("");
    // setAssetCategoryType("");
    setAssetCategoryProperties([]);
    setAssetPropertyCount(1);
    setAssetPropertyArray([1]);
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalOverlay}>
          <div className={classes.modalBody}>
            <div className={classes.header}>
              <p className={classes.headerText}>Add new asset category</p>
              <CloseIcon
                className={classes.icon}
                onClick={() => {
                  handleClose();
                  resetInputs();
                }}
              />
            </div>
            <form
              onSubmit={handleSubmit(
                ({ assetCategoryName, assetCategoryType }) => {
                  handleSave(
                    assetCategoryName,
                    assetCategoryType,
                    assetCategoryProperties
                  );
                  resetInputs();
                }
              )}
            >
              <div className={classes.inputBody}>
                <div
                  className={clsx({ [classes.inputsContainer]: !assetSharing })}
                >
                  <div className={classes.inputGroup}>
                    <InputLabel
                      label="Asset category name"
                      name="assetCategoryName"
                      errors={errors}
                    />
                    <input
                      name="assetCategoryName"
                      ref={register({
                        required: "This field is required.",
                      })}
                      type="text"
                      placeholder="Enter"
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <InputLabel
                      label="Asset Category type"
                      name="assetCategoryType"
                      errors={errors}
                    />
                    <select
                      name="assetCategoryType"
                      ref={register({
                        required: "Please select a category type.",
                      })}
                      placeholder="Select"
                    >
                      {assetCategoryStaticList.map(
                        (assetCategory: AssetCategories, index: number) => {
                          return (
                            <option
                              value={assetCategory.value}
                              key={assetCategory.value + index}
                            >
                              {assetCategory.name}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  {/* <div className={classes.inputGroup}>
                    <span>
                      Category icon
                      <span style={{ color: "#ED254E" }}>*</span>
                    </span>
                     <ImageUploader
                  // {...props}
                  withIcon={true}
                  onChange={onDrop}
                  imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                  maxFileSize={5242880}
                /> 
                  </div> */}
                </div>
                <div className={classes.inputsContainer}>
                  {assetPropertyArray.map((item: number, index: number) => {
                    return (
                      <div className={classes.inputGroup} key={index}>
                        <span>Asset property {item}</span>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="text"
                            placeholder="Enter"
                            value={assetCategoryProperties[index]}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const updatedAssetProperties = [
                                ...assetCategoryProperties,
                              ];
                              updatedAssetProperties[index] =
                                e.currentTarget.value;
                              setAssetCategoryProperties(
                                updatedAssetProperties
                              );
                            }}
                          />
                          <img
                            src={Xsquare}
                            alt="Delete asset property"
                            className={classes.faIcon}
                            style={{ marginLeft: "1em", cursor: "pointer" }}
                            onClick={() => {
                              handleInputDeletes(index);
                              handleAssetProperties(index);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={classes.addNewPropertyBtnContainer}>
                  {assetPropertyArray.length !== 10 ? (
                    <Button
                      type="button"
                      color="defaultTheme"
                      onClick={handleAssetPropertyCount}
                    >
                      <span style={{ margin: ".5em", fontSize: 14 }}>+</span>
                      Add new property
                    </Button>
                  ) : (
                    <div className={classes.maxPropertiesWarning}>
                      Only a maximum of 10 properties can be added.
                    </div>
                  )}
                </div>
              </div>

              <div className={classes.actionItems}>
                <Button
                  type="button"
                  color="white"
                  onClick={() => {
                    handleClose();
                    resetInputs();
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" color="defaultTheme">
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddNewAssetCategoryModal;
