/* Added this file for update connector modal for github issue #294 */
import React, { useState, useEffect, ChangeEvent, useCallback } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Modal, Grid, Checkbox, FormControlLabel } from "@material-ui/core";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
  setUnsuccessModalOpen,
  setUnsuccessModalDescription
} from "components/success-modal/successModalSlice";
import { setCallConnectors } from "../accountSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useForm, Controller } from "react-hook-form";
import { InputLabel } from "components/input-label/InputLabel";
import LoadSpinner from "common/LoadSpinner";
import Button from "components/common-buttons/Button";
import Select from "react-select";
import { Device, RequiredConnectorProps } from "./types";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Calendar } from "react-date-range";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import { customDateFormatUnix } from "utils/helpers";
import { fromUnixTime, format, getUnixTime } from "date-fns";
import calendarWhite from "assets/bt-assets/calendar_white.svg";
import { devicesAPI } from "services/devicesAPI";

const connectorTypeList = [{ name: "Salesforce", value: 0 }];

type ConnectorPushModalProps = {
  handleClose: () => void;
  open: boolean;
  selectedConnector: RequiredConnectorProps;
};

export const ConnectorPushModal: React.FC<ConnectorPushModalProps> = ({
  handleClose,
  open,
  selectedConnector
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [connectorActivity, setConnectorActivity] = useState(false);
  const [linearLoading, setLinearLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [devicesAccessLevel, setDevicesAccessLevel] = useState(false);
  const [groupsAccessLevel, setGroupsAccessLevel] = useState(false);
  const [connectorID, setConnectorID] = useState<string>("");
  const [deviceID, setDeviceID] = useState<string>("");
  const [connectorDate, setConnectorDate] = useState(selectedConnector.connector_expiry);
  const [deviceList, setDeviceList] = useState<any>([]);
  const [selectedDevice, setSelectedDevice] = useState<Device | undefined>(undefined);
  const [pushTime, setPushTime] = useState<string>("");
  const [
    activateDeactivateConnectorModal,
    setActivateDeactivateConnectorModal
  ] = useState(false);
  const [updatePushTime, setUpdatePushTime] = useState<string>("0");
  const updatePushTimeBoolean = updatePushTime === "1";

  const {
    register,
    handleSubmit,
    getValues,
    errors,
  } = useForm<RequiredConnectorProps>();

  const { userInformation } = useSelector((state: RootState) => state.AppSlice);

  const {
    role: selectedAccountRole,
    accountId: selectedAccountId,
    accountName: selectedAccountName
  } = useSelector((state: RootState) => state.AccountSlice.selectedAccount);

  const formatOptionLabel = ({ device_name, last_push_time }: Device) => {
    // Convert Unix epoch time to a Date object
    const date = fromUnixTime(last_push_time);
    console.log("formatOptionLabel");
    console.log(device_name)
    // Format the date and time
    const formattedDate = format(date, 'yyyy-MM-dd HH:mm:ss a');

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0px' }}>
        <span style={{ marginRight: '16px' }}>{device_name}</span>
        <span>{"("}{formattedDate}{")"}</span>
      </div>
    );
  };

  const { tenant_id } = useSelector(
    (state: RootState) => state.AccountSlice.accountInformation
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked ? "1" : "0";
    setUpdatePushTime(newValue);
  };


  useEffect(() => {
    if (Array.isArray(selectedConnector?.device_list) && selectedConnector.device_list.length > 0) {
      const initialSelectedDevices = selectedConnector.device_list.map((device: Device) => ({
        device_name: device.device_name,
        device_id: device.device_id,
        last_push_time: device.last_push_time
      }));
      // setSelectedDevice(initialSelectedDevices[0]);
      setDeviceList(initialSelectedDevices)
    }
    setConnectorID(selectedConnector.connector_id)
  }, [selectedConnector]);

  const closeActivateDeactivateConnectorModal = () => {
    setActivateDeactivateConnectorModal(false);
  };

  // to set the calendar date timestamp for issue #294
  const calendarExpiryHandler = useCallback(
    (date: Date) => {
      const pushTime = getUnixTime(date);
      setPushTime(String(pushTime));
      setCalendarOpen(false);
    },
    [pushTime]
  );

  const addDeviceToList = (device: Device) => {
    console.log("Device object:", device);
    const device_id = Array.isArray(device) ? device[0]?.device_id : device?.device_id;
    setSelectedDevice(device);
    setDeviceID(device_id);
  };

  const updatePushData = async ({
  }: RequiredConnectorProps) => {
    console.log("in update push data")
    try {
      const device_id = selectedDevice?.device_id;
      console.log(selectedDevice?.device_id)
      console.log(device_id)
      setLoading(true);
      await devicesAPI.connectorDataPush(tenant_id, connectorID, deviceID, pushTime, updatePushTime);
      // On success
      dispatch(setSuccessModalDescription("Data successfully pushed!"));
      dispatch(setSuccessModalOpen(true));
      handleClose();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      dispatch(setUnsuccessModalOpen(true));
      dispatch(setUnsuccessModalDescription("Error initiating data push"));
      handleClose();
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalOverlay}>
          {loading ? (
            <LoadSpinner />
          ) : (
            <form onSubmit={handleSubmit(updatePushData)}>
              <Grid className={classes.modalBody}>
                <Grid item xs={12} className={classes.header}>
                  <p className={classes.headerText}>Manual Data Push</p>
                  <CloseIcon
                    className={classes.closeIcon}
                    onClick={handleClose}
                  />
                </Grid>
                <div className={classes.innerInputs}>
                  <Grid item>
                    <div className={classes.inputGroup}>
                      {/* input for connector name for issue #294 */}
                      <InputLabel
                        label="Connector name"
                        name="connector_name"
                        errors={errors}
                        isRequired={false}
                      />
                      <input
                        name="connector_name"
                        defaultValue={selectedConnector.connector_name}
                        ref={register({
                          required: false,
                        })}
                        maxLength={35}
                        type="text"
                        placeholder="Enter"
                        readOnly
                        style={{
                          border: 'none',
                          backgroundColor: 'transparent',
                          outline: 'none',
                          color: 'inherit',
                          cursor: 'default', // Ensures the cursor shows as default when hovering
                        }}
                      />

                    </div>
                  </Grid>
                  <Grid item>
                    <div className={classes.inputGroup}>
                      <InputLabel
                        label="Select a device (last data pushed at)"
                        name="device_list"
                        errors={errors}
                      />
                      <div>
                        <Select
                          name="device_list"
                          styles={reactSelectStyles}
                          value={selectedDevice}
                          onChange={(device: Device) => addDeviceToList(device)}
                          options={deviceList}
                          isClearable={true}
                          maxMenuHeight={220}
                          classNamePrefix="select"
                          placeholder="Select devices"
                          isMulti={true}
                          isSearchable
                          formatOptionLabel={formatOptionLabel}
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid>
                    <div className={classes.inputGroup}>
                      <div className={classes.inputName}>
                        <InputLabel
                          label="Select push start date"
                          name="device_list"
                          errors={errors}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            overflow: "hidden",
                            display: "flex"
                          }}
                        >
                          <input
                            style={{
                              height: 30,
                              marginRight: 16,
                              cursor: "pointer"
                            }}
                            onClick={() => setCalendarOpen(true)}
                            value={
                              pushTime
                                ? customDateFormatUnix(
                                  Number(pushTime),
                                  "yyyy-MM-dd HH:mm:ss a"
                                )
                                : "N/A"
                            }
                          />
                          <Button
                            type="button"
                            color="defaultTheme"
                            onClick={() => setCalendarOpen(true)}
                          >
                            <img
                              src={calendarWhite}
                              alt="Subscription Expiry Calendar"
                            />
                          </Button>
                          {calendarOpen && (
                            <div className={classes.calendarDiv}>
                              <ClickAwayListener
                                onClickAway={() => setCalendarOpen(false)}
                              >
                                <Calendar
                                  minDate={fromUnixTime(Number(0))}
                                  date={
                                    pushTime
                                      ? fromUnixTime(Number(pushTime))
                                      : new Date()
                                  }
                                  onChange={calendarExpiryHandler}
                                />
                              </ClickAwayListener>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <Grid>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={updatePushTime === "1"}
                            onChange={handleCheckboxChange}
                            color="primary"
                          />
                        }
                        label="Update device last push time"
                      />
                    </Grid>
                    <Grid>
                      <p style={{ margin: 0, padding: '8px 0' }}>
                        Data will be pushed for up to 2 days including the selected day.
                      </p>
                    </Grid>
                  </Grid>
                </div>
                <Grid
                  item
                  xs={12}
                  className={classes.actionItems}
                  style={{ justifyContent: "flex-end" }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      type="submit"
                      color="white"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" color="defaultTheme"
                    >
                      Start
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ConnectorPushModal;

const inputStyle = {
  "& p": {
    padding: 0,
    margin: 0,
    fontSize: "1rem",
    marginBottom: ".5rem"
  },
  "& input": {
    width: "100%",
    padding: ".5rem 1rem",
    border: `1px solid ${Colors.LightGray}`
  },
  "& select": {
    width: "100%",
    padding: ".5rem 1rem",
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer"
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)"
    },
    modalBody: {
      maxHeight: "90vh",
      overflowY: "auto",
      // width: 830,
      // overflowY: "auto",
      backgroundColor: Colors.White,
      zIndex: 1
    },
    header: {
      // display: "flex",
      // alignItems: "center",
      // justifyContent: "space-between",
      // padding: "1rem 0",
      // borderBottom: `1px solid ${Colors.LightGray}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 2.5rem",
      margin: "0 0 1em 0",
      borderBottom: `1px solid ${Colors.LightGray}`
    },
    headerText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      fontSize: 24,
      padding: 0,
      margin: 0
    },
    closeIcon: {
      color: Colors.LightGray,
      cursor: "pointer",
      fontSize: "3rem"
    },
    innerInputs: {
      padding: "0 2.5rem",
      width: "100%"
      // maxHeight: 700, // causes double scrollbar overflow on certain window heights
      // overflowY: "auto",
    },
    heartIcon: {
      color: theme.palette.primary.light,
      cursor: "pointer"
    },
    inputGroup: {
      padding: "1rem 1rem 1rem 0",
      ...inputStyle
    },
    inputGroupRight: {
      padding: "1rem 0 1rem 1rem",
      ...inputStyle
    },
    labelContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    buttonContainer: {
      height: 30
    },
    button: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: Colors.White,
      cursor: "pointer",
      padding: "0 1rem",
      outline: "none",
      height: 30
    },
    disabledButton: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: Colors.LightGray,
      cursor: "none",
      padding: "0 1rem",
      outline: "none",
      height: 30
    },
    isActive: {
      backgroundColor: theme.palette.primary.light,
      color: Colors.White
    },
    active: {
      color: Colors.Green,
      fontWeight: 600,
      margin: "0 0 0 1rem"
    },
    notActive: {
      color: Colors.LightGray,
      fontWeight: 600,
      margin: "0 0 0 1rem"
    },
    accountInformation: {
      borderTop: `1px solid ${Colors.LightGray}`
    },
    accessAccount: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      "& p": {
        fontSize: 16,
        color: theme.palette.primary.light,
        margin: "0 0 0 .5rem",
        fontWeight: 600
      }
    },
    actionItems: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "1rem 2.5rem 0",
      borderTop: `1px solid ${Colors.LightGray}`,
      padding: "1rem 0"
    },
    inputName: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    group: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    groupBadge: {
      backgroundColor: "#EBECF0",
      borderRadius: "2em",
      color: "#172B4D",
      display: "inline-block",
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "1",
      minWidth: 1,
      padding: "0.16666666666667em 0.5em",
      textAlign: "center"
    },
    isBasinTrakEmployee: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    invalidText: {
      margin: "0 0 0 1px",
      color: "#ED254E"
    },
    calendarDiv: {
      position: "absolute",
      marginTop: 30,
      zIndex: 999,
      top: "30%",
      border: "1px solid",

      [`@media (min-height:800px)`]: {
        top: "auto"
      }
    }
  })
);

const reactSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer",
    width: "375px",
  }),
};
