/* Added this file for activating/deactivating/delete modal on three dots click for github issue #143 */
import React, { useCallback, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ActivateDeactivateTokenModal } from "./ActivateDeactivateTokenModal";
import Popover from "@material-ui/core/Popover";
import { DeleteAPITokenModal } from "./DeleteAPITokenModal";

type APITokenPopOverProps = {
  id: string | undefined;
  open: any;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  tenantId: string;
  tokenActive: boolean;
  tokenId: string;
  tokenName: string;
  setCallToken: React.Dispatch<React.SetStateAction<boolean>>;
};

export const APITokenPopOver: React.FC<APITokenPopOverProps> = ({
  id, //for checking which token is clicked for issue #143
  open, //to open modal for issue #143
  anchorEl, //for checking which token is clicked for showing the delete and activate modal below that token for issue #143
  handleClose, // to handle closing of modal for issue #143
  tenantId, // for sending to activate/deactivate/delete modal for issue #143
  tokenActive, // for showing activate or deactivate on UI based on if it is true or false for issue #143
  tokenId, // for sending to activate/deactivate/delete modal for issue #143
  tokenName, // for sending to activate/deactivate/delete modal for issue #143
  setCallToken // for sending to activate/deactivate/delete modal for issue #143
}) => {
  const classes = useStyles();
  const [tokenActivity, setTokenActivity] = useState(false);
  const [deleteTokenModal, setDeleteTokenModal] = useState(false); //var to open delete token modal for issue #143
  const [
    activateDeactivateTokenModal,
    setActivateDeactivateTokenModal
  ] = useState(false); //var to open activate/deactivate token modal for issue #143

  // function to handle activate/deactivate token button click for issue #143
  const handleToken = (tokenActivity: boolean) => {
    setTokenActivity(tokenActivity);
    setActivateDeactivateTokenModal(true);
    handleClose();
  };

  // function to close the activate/deactivate modal for issue #143
  const closeActivateDeactivateTokenModal = () => {
    setActivateDeactivateTokenModal(false);
  };

  // function to handle delete token button click for issue #143
  const deleteUserModalHandler = useCallback(() => {
    setDeleteTokenModal(true);
    handleClose();
  }, [handleClose]);

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        classes={{
          paper: classes.paper
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <div className={classes.selectionContainer}>
          {tokenActive == true ? (
            <p className={classes.selection} onClick={() => handleToken(true)}>
              Deactivate
            </p>
          ) : (
            <p className={classes.selection} onClick={() => handleToken(false)}>
              Activate
            </p>
          )}
          <p className={classes.selection} onClick={deleteUserModalHandler}>
            Delete
          </p>
        </div>
      </Popover>
      {/* modal for activate/deactivate token button click for issue #143 */}
      <ActivateDeactivateTokenModal
        open={activateDeactivateTokenModal}
        handleClose={closeActivateDeactivateTokenModal}
        tokenActivity={tokenActivity}
        tokenId={tokenId}
        tenantId={tenantId}
      // setCallToken={setCallToken}
      />
      {deleteTokenModal && (
        // modal for delete token button click for issue #143
        <DeleteAPITokenModal
          open
          handleClose={() => setDeleteTokenModal(false)}
          tokenId={tokenId}
          tenantId={tenantId}
          tokenName={tokenName}
        // setCallToken={setCallToken}
        />
      )}
    </>
  );
};

export default APITokenPopOver;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectionContainer: {
      padding: ".5rem 1rem"
    },
    paper: {
      border: "none",
      borderRadius: 0
    },
    selection: {
      fontSize: "14px",
      color: theme.palette.primary.light,
      margin: 0,
      cursor: "pointer"
    }
  })
);
