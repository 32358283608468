/* Issue fixed for github issue #27*/
import React from "react";
import { modalStyles } from "../../devices-page/device-settings/device-settings-tabs/modalStyles";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
} from "components/success-modal/successModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { devicesAPI } from "services/devicesAPI";
import { setAssetDeviceList, setAssetParameterList } from "../assetsDashboardSlice";  //importing setAssetParameterList for auto refresh page issue #27

type DeleteDeviceModalProps = {
  handleClose: Function;
  open: boolean;
};

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const DeleteDeviceModal: React.FC<DeleteDeviceModalProps> = ({
  handleClose,
  open,
}) => {
  const styles = modalStyles();
  const dispatch = useDispatch();

  //adding assetParameterList for keeping check of parameter list issue #27 
  const { selectedDeviceId, currentAssetId, assetParameterList } = useSelector(
    (state: RootState) => state.AssetDashboardSlice
  );

  const cancelDeleveDeviceModal = () => {
    handleClose();
  };

  const handleDeleteDevice = async () => {
    try {
      const newDeviceList = await devicesAPI.deleteAssetsDevice(
        currentAssetId,
        selectedDeviceId
      );

      //added logic for refreshing the screen when a device is deleted for issue #27
      let params = newDeviceList.asset_parameters;
      let newParams: any[] = [];
      for (let i = 0; i < params.length; i++) {
        for (let j = i; j < assetParameterList.length; j++) {
          if (params[i].device_parameter_id == assetParameterList[j].device_parameter_id) {
            newParams.push(assetParameterList[j]);
            break;
          }
        }
      }
      dispatch(setAssetParameterList(newParams));
      dispatch(setAssetDeviceList(newDeviceList.asset_devices));

      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription("Device successfully removed!"));
      handleClose();
    } catch (error) {
      alert(error);
      handleClose();
    }
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={styles.modalOverlay}>
          <div className={styles.modalBody}>
            <div className={styles.header}>
              <p className={styles.headerText}>Delete device from asset</p>
              <CloseIcon
                className={styles.icon}
                onClick={() => handleClose()}
              />
            </div>
            <div style={{ margin: "2rem 0 0 0" }}>
              <div className={styles.deleteDescription}>
                <p>
                  Are you sure you want to remove this device from this asset?
                </p>
                <p>
                  If yes, all the parameters from this device will also be
                  removed from this asset and will not be shown on the
                  dashboard.
                </p>
              </div>
            </div>
            <div className={styles.actionItems}>
              <button
                className={styles.cancelButton}
                onClick={cancelDeleveDeviceModal}
              >
                Cancel
              </button>
              <button
                className={styles.saveButton}
                onClick={handleDeleteDevice}
              >
                Remove device from asset
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteDeviceModal;
