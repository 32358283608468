import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as HexagonIcon } from "assets/bt-assets/Vectord.svg";
import AssetsDashboard from "./AssetsDashboard";
import AssetsSettings from "./AssetsSettings";
import { useDispatch, useSelector } from "react-redux";
import { setAssetSettingsName } from "features/assets-page/assetCategorySlice";
import { RootState } from "app/rootReducer";
import AddNewAssetCategoryModal from "components/asset-modals/AddNewAssetCategory";
import { setAssetDashboard } from "features/assets-page/assetCategorySlice";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
} from "components/success-modal/successModalSlice";
import { setCallCategoryList } from "features/assets-page/assetCategorySlice";
import AssetAPI from "services/assetsAPI";
import { setSubPageActive } from "features/accounts-page/accountSlice";

const Assets = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [assetSettingsPage, setAssetSettingsPage] = useState(false);
  const [openAddNewAssetCateogory, setOpenAddNewAssetCateogory] =
    useState(false);

  const { accountName, accountId } = useSelector(
    (state: RootState) => state.AppSlice.selectedAccount
  );
  const userId = useSelector(
    (state: RootState) => state.AppSlice.userInformation.user_id
  );

  const { isAssetDashboard } = useSelector(
    (state: RootState) => state.AssetCategorySlice
  );

  const returnToAssetsPage = () => {
    setAssetSettingsPage(false);
  };

  const handleSave = async (
    assetCategoryName: string,
    assetCategoryType: string,
    assetCategoryProperties: string[]
  ) => {
    const assetCategoryObject = {
      category_name: assetCategoryName,
      category_type: assetCategoryType,
      category_icon: assetCategoryType,
      property_names: {
        property_1: assetCategoryProperties[0] || "",
        property_2: assetCategoryProperties[1] || "",
        property_3: assetCategoryProperties[2] || "",
        property_4: assetCategoryProperties[3] || "",
        property_5: assetCategoryProperties[4] || "",
        property_6: assetCategoryProperties[5] || "",
        property_7: assetCategoryProperties[6] || "",
        property_8: assetCategoryProperties[7] || "",
        property_9: assetCategoryProperties[8] || "",
        property_10: assetCategoryProperties[9] || "",
      },
    };

    // console.log("assetCategoryObject", assetCategoryObject);

    try {
      await AssetAPI.postNewAssetCategory(assetCategoryObject);
      dispatch(setCallCategoryList(true));
      dispatch(
        setSuccessModalDescription("Asset category successfully added!")
      );
      dispatch(setSuccessModalOpen(true));
      setOpenAddNewAssetCateogory(false);
    } catch (error) {
      setOpenAddNewAssetCateogory(false);
    }
  };

  const handleClose = () => {
    setOpenAddNewAssetCateogory(false);
  };

  useEffect(() => {
    if (isAssetDashboard) {
      dispatch(setAssetDashboard(!isAssetDashboard));
      setAssetSettingsPage(!isAssetDashboard);
    }
    return () => {
      dispatch(setAssetSettingsName(""));
    };
  }, [isAssetDashboard, dispatch]);

  return (
    <>
      <div className={classes.deviceContainer}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            className={classes.topGrid}
            style={{ justifyContent: "flex-end" }}
          >
            <div>
              {!assetSettingsPage ? (
                <button
                  type="button"
                  className={classes.assetButton}
                  onClick={() => {
                    setAssetSettingsPage(true);
                    dispatch(setSubPageActive(true));
                    dispatch(setAssetSettingsName("Asset Settings"));
                  }}
                >
                  <HexagonIcon className={classes.hexagonIcon} />
                  {/* <img src={HexagonIcon} width="25" alt="" /> Asset type */}
                  Asset type settings
                </button>
              ) : (
                <button
                  type="button"
                  className={classes.addNewAssetButton}
                  onClick={() => {
                    // console.log("Add new asset category");
                    setOpenAddNewAssetCateogory(true);
                  }}
                >
                  <span style={{ margin: ".5em" }}>+</span>
                  Add new asset category
                </button>
              )}
            </div>
          </Grid>
          {!assetSettingsPage ? (
            <AssetsDashboard
              // accounts={sortedAccounts}
              parentTenantName={accountName}
              parentTenantId={accountId}
              userId={userId}
            />
          ) : (
            <AssetsSettings handleAssetPage={returnToAssetsPage} />
          )}
        </Grid>
      </div>
      <AddNewAssetCategoryModal
        open={openAddNewAssetCateogory}
        handleClose={handleClose}
        handleSave={handleSave}
      />
    </>
  );
};

export default Assets;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deviceContainer: {
      marginTop: "1rem",
    },
    topGrid: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    select: {
      border: `1px solid ${Colors.LightGray}`,
      padding: ".5rem .5rem .5rem 1rem",
      color: theme.palette.primary.light,
      cursor: "pointer",
      fontWeight: "bold",
      "& option": {
        color: theme.palette.primary.light,
        fontWeight: "bold",
      },
    },
    assetButton: {
      border: `1px solid ${theme.palette.primary.light}`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      backgroundColor: "#fff",
      height: 30,
      // width: 175,
      color: theme.palette.primary.light,
      // fontWeight: 500,
      padding: ".5rem",
      cursor: "pointer",
      outline: "none",
    },
    addNewAssetButton: {
      border: `1px solid ${theme.palette.primary.light}`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      backgroundColor: theme.palette.primary.light,
      height: 30,
      // width: 175,
      color: "#fff",
      // fontWeight: 600,
      padding: "0 .5rem 0 0",
      cursor: "pointer",
      outline: "none",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    hexagonIcon: {
      fill: theme.palette.primary.light,
      width: 25,
    },
  })
);
