/*Issue fixed for github issue #290 */
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useDispatch } from "react-redux";
import { setCallGroupProfile } from "components/asset-modals/assetGroupModalSlice";
import { useStyles } from "./assetStyles";
import { InputLabel } from "components/input-label/InputLabel";
import { useForm } from "react-hook-form";
import AssetsAPI from "services/assetsAPI";
import { RequiredGroupProps } from "./types";
import clsx from "clsx";
import Button from "components/common-buttons/Button";

type AssetModalProps = {
  open: boolean;
  handleClose: () => void;
  successDescription?: string;
  handleGroupModals: () => void;
  handleSuccessModal?: () => void;
  nodeObject: any;
};

export const AddNewGroupModal: React.FC<AssetModalProps> = ({
  open,
  handleClose,
  handleGroupModals,
  nodeObject,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [groupSharing, setGroupSharing] = useState(true);
  // const [groupName, setGroupName] = useState("");
  const { register, handleSubmit, errors } = useForm<RequiredGroupProps>();

  const handleGroupSave = async ({ groupName }: RequiredGroupProps) => {
    const assetGroupObject = {
      group_name: groupName,
      group_sharing: groupSharing,
      shared_customers: [],
      parent_group: {
        asset_group_id:
          nodeObject.tenantType === "parent"
            ? ""
            : nodeObject.assetGroupId || "",
        group_name:
          nodeObject.tenantType === "parent" ? "" : nodeObject.title || "",
      },
      tenant_id: nodeObject.tenantId || nodeObject.parentTenantId,
    };

    // console.log("assetGroupObject", assetGroupObject);
    try {
      // console.log("ASSET GROUP OBJECT: ", assetGroupObject);
      await AssetsAPI.postAssetGroup(assetGroupObject);
      dispatch(setCallGroupProfile(true));
      handleGroupModals();
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(error);
      }
      alert(error);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalOverlay}>
          <form onSubmit={handleSubmit(handleGroupSave)}>
            <div className={classes.modalBody}>
              <div className={classes.header}>
                <p className={classes.headerText}>Add new group</p>
                <CloseIcon className={classes.icon} onClick={handleClose} />
              </div>
              <div className={classes.inputBody}>
                <div className={classes.pathText}>
                  <p style={{ fontWeight: 600, color: "#A6A6A6" }}>Path: </p>
                  <p style={{ fontWeight: "bold" }}> {nodeObject.title} </p>
                </div>
                <div className={classes.labelText}>
                  {/* Changed text for issue #290 */}
                  <p>Selection: </p>
                  <p style={{ fontWeight: "bold" }}> {nodeObject.title} </p>
                </div>
                {/* // ! commented out code for future implementation */}
                {/* <div className={classes.inputGroup}>
                  <span>Group sharing</span>
                  <div className={classes.toggleButtonContainer}>
                    <button
                      className={clsx(classes.toggleButton, {
                        [classes.active]: groupSharing,
                      })}
                      type="button"
                      onClick={() => {
                        setGroupSharing(true);
                      }}
                    >
                      ON
                    </button>
                    <button
                      className={clsx(classes.toggleButton, {
                        [classes.active]: !groupSharing,
                      })}
                      type="button"
                      onClick={() => {
                        setGroupSharing(false);
                      }}
                    >
                      OFF
                    </button>
                  </div>
                </div> */}
                {/* <div
                  className={clsx(classes.inputGroup, {
                    [classes.hide]: !groupSharing,
                  })}
                >
                  <span>Shared with customer(s)</span>
                  <div>
                    <button
                      className={classes.selectButton}
                      type="button"
                      onClick={() => {
                        setGroupSharing(true);
                      }}
                    >
                      Select
                    </button>
                  </div>
                </div> */}
                <div
                  className={clsx({ [classes.inputsContainer]: !groupSharing })}
                >
                  <div className={classes.inputGroup}>
                    <InputLabel
                      label="Group name"
                      name="groupName"
                      errors={errors}
                    />
                    <input
                      name="groupName"
                      ref={register({
                        required: "This field is required",
                      })}
                      type="text"
                      placeholder="Enter"
                    // value={groupName}
                    // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    //   e.preventDefault();
                    //   setGroupName(e.currentTarget.value);
                    // }}
                    />
                  </div>
                  {!groupSharing && (
                    <>
                      <div className={classes.inputGroup}>
                        <span>
                          Group Property 1{" "}
                          <span style={{ color: "#ED254E" }}>*</span>
                        </span>
                        <input
                          type="text"
                          placeholder="Enter"
                        // value={serialNum}
                        // onChange={(e: ChangeEvent) => setSerialNum(e.target.value)}
                        />
                      </div>{" "}
                      <div className={classes.inputGroup}>
                        <span>
                          Group Property 2{" "}
                          <span style={{ color: "#ED254E" }}>*</span>
                        </span>
                        <input
                          type="text"
                          placeholder="Enter"
                        // value={serialNum}
                        // onChange={(e: ChangeEvent) => setSerialNum(e.target.value)}
                        />
                      </div>{" "}
                      <div className={classes.inputGroup}>
                        <span>
                          Group Property 3{" "}
                          <span style={{ color: "#ED254E" }}>*</span>
                        </span>
                        <input
                          type="text"
                          placeholder="Enter"
                        // value={serialNum}
                        // onChange={(e: ChangeEvent) => setSerialNum(e.target.value)}
                        />
                      </div>{" "}
                      <div className={classes.inputGroup}>
                        <span>
                          Group Property 4{" "}
                          <span style={{ color: "#ED254E" }}>*</span>
                        </span>
                        <input
                          type="text"
                          placeholder="Enter"
                        // value={serialNum}
                        // onChange={(e: ChangeEvent) => setSerialNum(e.target.value)}
                        />
                      </div>{" "}
                      <div className={classes.inputGroup}>
                        <span>
                          Group Property 5{" "}
                          <span style={{ color: "#ED254E" }}>*</span>
                        </span>
                        <input
                          type="text"
                          placeholder="Enter"
                        // value={serialNum}
                        // onChange={(e: ChangeEvent) => setSerialNum(e.target.value)}
                        />
                      </div>
                      <div className={classes.inputGroup}>
                        <span>
                          Group Property 6{" "}
                          <span style={{ color: "#ED254E" }}>*</span>
                        </span>
                        <input
                          type="text"
                          placeholder="Enter"
                        // value={serialNum}
                        // onChange={(e: ChangeEvent) => setSerialNum(e.target.value)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className={classes.actionItems}>
                <Button
                  type="button"
                  color="white"
                  onClick={() => {
                    handleClose();
                    // setGroupName("");
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" color="defaultTheme">
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AddNewGroupModal;
