/* Issue fixed for github issue #24*/
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      backgroundColor: "#fafafa",
      borderRadius: "30px 0 0 30px",
    },
    title: {
      // flexGrow: 1,
      fontSize: "24px",
      margin: ".5rem 0",
      textDecoration: "none",
      color: theme.palette.primary.light,
      fontWeight: 900,
      [theme.breakpoints.up("xs")]: {
        display: "block",
      },
      cursor: "default",

      //adding media query for color of heading in responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        fontSize: "20px",
        color: "cornflowerblue",
        margin: "1rem 0", //changed margin from 0.5rem to 1rem in responsive UI issue #24
      },
    },
    nestedTitle: {
      margin: ".5rem 0",
      textDecoration: "none",
      color: theme.palette.primary.light,
      fontWeight: 400,
      [theme.breakpoints.up("xs")]: {
        display: "block",
      },
      cursor: "pointer",
    },
  })
);
