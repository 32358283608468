/* Issue fixed for github issue #54,issue #82,issue #140,issue #219 */
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { BarChart, Bar, Cell, LabelList, ResponsiveContainer } from "recharts";
import { ParameterProps } from "features/real-time-status-page/types";
import { displayColors } from "utils/displayDashChartColors";
import { Colors } from "common/Colors";
import { binaryFromJsonList } from "utils/binaryToText";
import { numberToTextKey } from "utils/constants";
import { binaryToTextKey } from "utils/constants";
import { getDecodedVal } from "utils/conversions";
interface VeritcalBarChartProps {
  params: ParameterProps;
}

const VeritcalBarChart: React.FC<VeritcalBarChartProps> = ({
  params
}: VeritcalBarChartProps) => {
  const styles = useStyles();
  const max = params.max_value;
  const hihi = params.high_high_value;
  const hi = params.high_value;
  const lo = params.low_value;
  const lolo = params.low_low_value;
  const min = params.min_value;
  const param = params.parameter_value; // This is the actual data input

  const pcent_val = (100 * (param - min)) / (max - min);
  const pcent_val_bound = pcent_val > 100 ? 100 : pcent_val < 0 ? 0 : pcent_val;
  var status = "";
  if (pcent_val > 100) status = ">";
  if (pcent_val < 0) status = "<";

  // Convert data to percent
  const pcent_hi_max = (100 * (max - hihi)) / (max - min);
  const pcent_hi_hihi = (100 * (hihi - hi)) / (max - min);
  const pcent_lo_hi = (100 * (hi - lo)) / (max - min);
  const pcent_lolo_lo = (100 * (lo - lolo)) / (max - min);
  const pcent_min_lolo = (100 * (lolo - min)) / (max - min);

  const {
    low_low_to_low_color,
    min_to_low_low_color,
    low_to_high_color,
    high_high_to_max_color,
    high_to_high_high_color
  } = params;

  const data = [
    {
      name: status,
      // updated formulae for issue #219
      hi_max: Math.round(pcent_hi_max),
      hi_hihi: Math.round(pcent_hi_hihi),
      lo_hi: Math.round(pcent_lo_hi),
      lolo_lo: Math.round(pcent_lolo_lo),
      // min_lolo: pcent_min_lolo,
      //modified the formulae so that total doesn't exceed 100
      min_lolo:
        100 -
        (Math.round(pcent_hi_max) +
          Math.round(pcent_hi_hihi) +
          Math.round(pcent_lo_hi) +
          Math.round(pcent_lolo_lo)),
      val: Math.round(pcent_val_bound)
    }
  ];

  const COLORS = [
    min_to_low_low_color,
    low_low_to_low_color,
    low_to_high_color,
    high_to_high_high_color,
    high_high_to_max_color
  ];

  const percentLabel = (props: any) => {
    const { x, y, width, height, value } = props;

    // Adjust the x and y for centering within the CustomBackground
    const textX = x + width / 2;
    const textY = 50;

    return (
      <>
        <text
          x={textX}
          y={textY}
          textAnchor="middle"
          dominantBaseline="middle"
          fill={Colors.Black}
        >
          {/* Display the value of the parameter and its unit inside the chart */}
          {getDecodedVal(
            params,
            Number(params.parameter_value),
            numberToTextKey,
            binaryToTextKey,
            binaryFromJsonList
          )}
        </text>
        <text
          x={textX}
          y={textY + 15} // Adjust this value for spacing between the value and unit
          textAnchor="middle"
          dominantBaseline="middle"
          fill={Colors.Black}
        >
          {params.unit}
        </text>
      </>
    );
  };


  const CustomBackground = (props: any) => {
    const { x, y, width, height } = props;
    const roundedTopRadius = 3;

    return (
      <>
        {/* Top rounded rectangle */}
        <rect
          x={x}
          y={y}
          width={width}
          height={2 * roundedTopRadius}
          fill="#dfdfdf"
          rx={roundedTopRadius}
          ry={roundedTopRadius}
        />
        {/* Bottom rectangle */}
        <rect
          x={x}
          y={y + roundedTopRadius}
          width={width}
          height={height - roundedTopRadius}
          fill="#dfdfdf"
        />
      </>
    );
  };

  return (
    // Added div with height and width for chart so that it size itself according to screen resolution for issue #140
    <div
      style={{
        minHeight: "max(150px,14vh)",
        height: "max(100px,10vh)",
        // maxHeight: "min(100px,20vh)",
        minWidth: "min(100px,100vh)",
        // width: "100%",
        padding: "15px"
      }}
    >
      <ResponsiveContainer>
        <BarChart
          data={data}
          barGap={2}
          margin={{
            top: 10,
            bottom: 10
          }}
        >
          <Bar
            dataKey="min_lolo"
            stackId="a"
            barSize={10}
            fill={displayColors(COLORS[0])}
          />
          <Bar
            dataKey="lolo_lo"
            stackId="a"
            barSize={10}
            fill={displayColors(COLORS[1])}
          />
          <Bar
            dataKey="lo_hi"
            stackId="a"
            barSize={10}
            fill={displayColors(COLORS[2])}
          />
          <Bar
            dataKey="hi_hihi"
            stackId="a"
            barSize={10}
            fill={displayColors(COLORS[3])}
          />
          <Bar
            dataKey="hi_max"
            stackId="a"
            barSize={10}
            fill={displayColors(COLORS[4])}
          />

          <Bar
            dataKey="val"
            barSize={40}
            // fill="#f96262"
            // background={{ fill: "#dfdfdf" }}
            background={<CustomBackground />}
            // label={percentLabel}
            isAnimationActive={false}
            radius={[3, 3, 0, 0]}
          >
            <LabelList dataKey="val" content={percentLabel} />
            {data.map(entry => {
              let color = "";
              if (
                entry.val >
                entry.hi_max + entry.lo_hi + entry.lolo_lo + entry.min_lolo
              ) {
                color = COLORS[4];
              } else if (
                entry.val >=
                entry.lo_hi + entry.lolo_lo + entry.min_lolo
              ) {
                color = COLORS[3];
              } else if (entry.val > entry.lolo_lo + entry.min_lolo) {
                color = COLORS[2];
              } else if (entry.val > entry.min_lolo) {
                color = COLORS[1];
              } else if (entry.val >= 0) {
                color = COLORS[0];
              }
              return <Cell fill={displayColors(color)} />;
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const useStyles = makeStyles(theme => createStyles({}));

export default VeritcalBarChart;
