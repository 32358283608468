/* Issue fixed for github issue #24,issue #146*/
import React from "react";
import { useStyles } from "./styles";
import { ListItemIcon } from "@material-ui/core";
import {
  superAdminRealTimeStatusPages,
  superAdminPages,
  reportsPages
} from "../drawerPagesList";
import { PageProps } from "../types";
import { Link, useRouteMatch } from "react-router-dom";
import DashboardIcon from "assets/bt-assets/menu_dashboards.svg";
import AdminIcon from "assets/bt-assets/menu_admin.svg";
import ReportIcon from "assets/bt-assets/menu_reports.svg";
import clsx from "clsx";

export type SuperAdminMenuItems = {
  setPageName: Function;
  handleSignOut: Function; //for logout functionality for responsive UI issue #24
  accessLevel: string;
};

export const SuperAdminMenuItems: React.FC<SuperAdminMenuItems> = ({
  setPageName,
  handleSignOut, //for logout functionality for responsive UI issue #24
  accessLevel
}) => {
  const classes = useStyles();

  const isSuperAdmin = accessLevel === "admin";

  const PageName = ({ to, label, activeOnlyWhenExact }: PageProps) => {
    const routeSelected = useRouteMatch({
      path: to,
      exact: activeOnlyWhenExact
    });

    if (routeSelected) {
      setPageName(label);
    }

    return (
      <Link
        to={to}
        className={clsx(classes.routeNames, {
          [classes.active]: routeSelected
        })}
      >
        {label}
      </Link>
    );
  };

  return isSuperAdmin ? (
    <div>
      <ListItemIcon className={classes.listHeader}>
        <div style={{ marginTop: "2px" }}>
          <img
            className={classes.headerIcon}
            src={DashboardIcon}
            alt="Dashboard icon"
          />
        </div>
        <div className={classes.header}>Dashboards</div>
      </ListItemIcon>
      {superAdminRealTimeStatusPages.map((page: PageProps) => (
        <PageName
          key={page.label}
          to={page.to}
          label={page.label}
          activeOnlyWhenExact={page.activeOnlyWhenExact}
        />
      ))}

      {/*changes for responsive UI issue #24 adding css class to hide admin and reports section in mobile view */}
      <div className={classes.mobileView}>
        {/* // ! Admin secton */}
        <ListItemIcon className={classes.listHeader}>
          <div style={{ marginTop: "2px" }}>
            <img
              className={classes.headerIcon}
              src={AdminIcon}
              alt="Admin icon"
            />
          </div>
          <div className={classes.header}>Admin</div>
        </ListItemIcon>
        {superAdminPages.map((page: PageProps) => (
          <PageName
            key={page.label}
            to={page.to}
            label={page.label}
            activeOnlyWhenExact={page.activeOnlyWhenExact}
          />
        ))}
      </div>
      {/* Added reports to mobile view for issue #146 */}
      {/* // ! Reports section */}
      <ListItemIcon className={classes.listHeader}>
        <div style={{ marginTop: "2px" }}>
          <img
            className={classes.headerIcon}
            src={ReportIcon}
            alt="Report icon"
          />
        </div>
        <div className={classes.header}>Reports</div>
      </ListItemIcon>
      {reportsPages.map((page: PageProps) => (
        <PageName
          key={page.label}
          to={page.to}
          label={page.label}
          activeOnlyWhenExact={page.activeOnlyWhenExact}
        />
      ))}

      {/* adding logout in the sidenav bar for responsive UI issue #24 */}
      <div
        className={classes.logoutbutton}
        onClick={() => {
          handleSignOut();
        }}
      >
        Logout
      </div>
    </div>
  ) : null;
};

export default SuperAdminMenuItems;
