import React, { useState } from "react";
import { formModalStyles } from "common/formModalStyles";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  setSuccessDeviceOpen,
  setDeleteDeviceModalDescription,
} from "features/devices-page/device-components/device-modal/deviceModalSlice";
import { setRecallDeviceList } from "features/devices-page/devices-table/deviceTableSlice";
import { InputLabel } from "components/input-label/InputLabel";
import { Button } from "components/common-buttons/Button";
import { useForm } from "react-hook-form";
import { devicesAPI } from "services/devicesAPI";
import { RequiredDeviceProps } from "./types";

type AddDeviceModalProps = {
  // accountName: string;
  // accountId: string;
  showAddDeviceModal: boolean;
  closeAddDeviceModal: Function;
};

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const AddDeviceModal: React.FC<AddDeviceModalProps> = ({
  // accountName,
  // accountId,
  showAddDeviceModal,
  closeAddDeviceModal,
}) => {
  const styles = formModalStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, setError } =
    useForm<RequiredDeviceProps>();

  const { accountName, accountId } = useSelector(
    (state: RootState) => state.AppSlice.selectedAccount
  );

  const cancelAddDevice = () => {
    closeAddDeviceModal();
  };

  const createDevice = async ({
    deviceSerialNumber,
    hardwareId,
  }: RequiredDeviceProps) => {
    try {
      await devicesAPI.createDevice(deviceSerialNumber, hardwareId, accountId);
      dispatch(setRecallDeviceList(true));
      dispatch(setSuccessDeviceOpen(true));
      dispatch(setDeleteDeviceModalDescription("Device successfully added!"));
      cancelAddDevice();
    } catch (error) {
      let message = "Serial/hardware id does not match in the database.";
      if (error.response.data === "Error: device_id is claimed") {
        message = "Device already exists";
      }
      setError("deviceSerialNumber", {
        type: "noMatch",
        message: message,
      });
      setError("hardwareId", {
        type: "noMatch",
        message: message,
      });
    }
  };

  return showAddDeviceModal ? (
    <div className={styles.modalOverlay}>
      <div className={styles.modalBody}>
        <div className={styles.header}>
          <p className={styles.headerText}>Add new device</p>
          <CloseIcon
            className={styles.icon}
            onClick={() => closeAddDeviceModal()}
          />
        </div>
        <form onSubmit={handleSubmit(createDevice)}>
          <div className={styles.inputBody}>
            <div className={styles.labelText}>
              <p>Customer: </p>
              <p style={{ fontWeight: "bold" }}> {accountName} </p>
            </div>
            <div className={styles.inputGroup}>
              <InputLabel
                label="Device serial number"
                name="deviceSerialNumber"
                errors={errors}
              />
              <div style={{ width: "93%" }}>
                <input
                  name="deviceSerialNumber"
                  ref={register({
                    required: "This field is required.",
                  })}
                  type="text"
                  placeholder="Enter"
                />
              </div>
            </div>
            <div className={styles.inputGroup}>
              <InputLabel
                label="Hardware ID"
                name="hardwareId"
                errors={errors}
              />
              <div style={{ width: "93%" }}>
                <input
                  name="hardwareId"
                  ref={register({
                    required: "This field is required.",
                  })}
                  type="text"
                  placeholder="Enter"
                />
              </div>
            </div>
            <div className={styles.actionItems}>
              <Button type="button" color="white" onClick={cancelAddDevice}>
                Cancel
              </Button>
              <Button type="submit" color="defaultTheme">
                Add device
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  ) : null;
};

export default AddDeviceModal;
