//Added file for github issue #273
import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import Grid from "@material-ui/core/Grid";
import AssetAPI from "services/assetsAPI";
// import diamond from "assets/bt-assets/Group 137.svg";
import { useTheme } from "@material-ui/core/styles";
import InputBase from '@material-ui/core/InputBase';
import DefaultReportsDisplay from "./DefaultReportsDisplay";
import { useDispatch, useSelector } from "react-redux";
import {
  setCallGroupProfile,
  setCallAssetProfile,
} from "components/asset-modals/assetGroupModalSlice";
import {
  setGroupDelete,
  setAssetDelete,
} from "components/group-modal/groupModalSlice";
import { RootState } from "app/rootReducer";
import SortableTree, {
  getNodeAtPath,
  // getNodeKey,
  addNodeUnderParent,
  // removeNode,
  changeNodeAtPath,
  removeNodeAtPath,
} from "react-sortable-tree";
import FileExplorerTheme from "react-sortable-tree-theme-file-explorer";
import {
  TenantGroupObject,
  AssetOrganizerProps,
  ChildrenGroupProps,
} from "features/assets-page/types";
import "react-sortable-tree/style.css";
import SuccessModal from "components/success-modal/SuccessModal";
import { devicesAPI } from "services/devicesAPI";
import {
  setDeviceSelection,
  setParameterSelection,
  setAssetDeviceList,
  setCurrentAssetId,
  setAssetParameterList,
} from "features/assets-page/assetsDashboardSlice";
import { DeviceParametersProps } from "features/devices-page/device-settings/types";
import LoadSpinner from "common/LoadSpinner";
import AccountAPI from "services/accountsAPI";
import UnsuccessModal from "components/success-modal/UnsuccessfullModal";
import UpdateDefaultReportDisplay from "./UpdateDefaultReportsDisplay";
import SettingsIcon from '@material-ui/icons/Settings';
import GroupWorkIcon from '@material-ui/icons/GroupWork'

type ReportsDashboardProps = {
  parentTenantId: string;
  userId: string;
  selectedReport: any;
  closeReportModal: Function;
  typeOfReportModal: string;
};

interface TreeIndex {
  treeIndex: number;
}

interface TreeNode {
  title: string;
  // Add other properties of your node as needed
  [key: string]: any;  // Allows for other properties not explicitly defined
}

const placeholder = [
  {
    title: "Placeholder",
  },
];

const ReportsDashboard: React.FC<ReportsDashboardProps> = ({
  parentTenantId,
  userId,
  selectedReport,
  closeReportModal,
  typeOfReportModal
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [treeDepth, setTreeDepth] = useState<string>("parent");
  const [nodeObject, setNodeObject] = useState({});
  const [selectedNodeId, setSelectedNodeId] = useState("");
  const [path, setPath] = useState<number[]>([]);
  const [treeindex, setTreeindex] = useState(0);
  const [treeData, setTreeData] = useState<TreeNode[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredData, setFilteredData] = useState<TreeNode[]>([]);
  const [selectedParent, setSelectedParent] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [selectedNode, setSelectedNode] = useState<TreeNode | null>(null);


  const { callGroupProfile, callAssetProfile } = useSelector(
    (state: RootState) => state.AssetGroupModalSlice
  );
  const {
    groupName,
    newAssetName,
    assetDeviceList,
    assetParameterList,
    currentAssetId,
  } = useSelector((state: RootState) => state.AssetDashboardSlice);

  const { groupDelete, assetDelete } = useSelector(
    (state: RootState) => state.GroupModalSlice
  );

  const { access_level } = useSelector(
    (state: RootState) => state.AppSlice.userInformation
  );

  const getNodeKey = ({ treeIndex }: TreeIndex) => treeIndex;

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredData(treeData);
    } else {
      const filterTree = (nodes: TreeNode[]): TreeNode[] => {
        return nodes
          .map((node) => {
            if (node.title.toLowerCase().includes(searchQuery.toLowerCase())) {
              return { ...node };
            }
            if (node.children) {
              const filteredChildren = filterTree(node.children);
              if (filteredChildren.length > 0) {
                return { ...node, children: filteredChildren };
              }
            }
            return null;
          })
          .filter((node) => node !== null) as TreeNode[];
      };
      setFilteredData(filterTree(treeData));
    }
  }, [searchQuery, treeData]);


  const handleTenantTree = useCallback(
    (response: any) => {
      const tenantGroups = response.map(
        (groupObject: TenantGroupObject, index: number) => {
          const tenantChildGroups = (groupObject.children_groups || []).map(
            (groupAsset: ChildrenGroupProps) => {
              return {
                assetGroupId: groupAsset.asset_group_id,
                title: groupAsset.group_name,
                childType: "group",
                tenantId: groupObject.tenant_id,
                children: placeholder,
                isAssetGroup: groupObject.is_asset_group,
              };
            }
          );

          const tenantChildAssets = (groupObject.asset_organizer || []).map(
            (asset: AssetOrganizerProps) => {
              return {
                assetGroupId: asset.asset_id,
                title: asset.asset_name,
                childType: "asset",
                tenantId: groupObject.tenant_id,
                children: placeholder,
                isAssetGroup: false,
              };
            }
          );

          return {
            id: index,
            parentTenantId: parentTenantId,
            assetGroupId: groupObject.asset_group_id,
            title: groupObject.group_name,
            childType: "group",
            expanded: true,
            groupSharing: groupObject.group_sharing,
            groupStatus: groupObject.group_status,
            isAssetGroup: groupObject.is_asset_group,
            children: [...tenantChildGroups, ...tenantChildAssets],
          };
        }
      );

      const parentGroup = tenantGroups[0];
      if (parentGroup) {
        // initialize values for group display component
        setNodeObject(parentGroup);
        setTreeDepth("group");
        setSelectedNodeId(parentGroup.assetGroupId || "");
        setPath([0]);
        setTreeindex(0);
      }

      setTreeData(tenantGroups);
      setLoading(false);
    },
    [parentTenantId]
  );

  const getGroupListForTenant = useCallback(async () => {
    try {
      if (["admin", "engineer"].includes(access_level)) {
        const allTenants = await AccountAPI.getAllTenants();
        const masterGroupId = allTenants.filter(
          (tenant: any) => tenant.tenant_id === parentTenantId
        )[0].master_group_id;
        const response = await AssetAPI.retrieveAssetGroupProfile(
          masterGroupId
        );

        handleTenantTree([response]);
      } else {
        const response = await AssetAPI.getGroupListForUserByTenant(
          userId,
          parentTenantId
        );

        handleTenantTree(response);
      }
    } catch (error) {
      console.log(error);
    }
  }, [userId, parentTenantId, access_level, handleTenantTree]);

  const handleAssetsParameterList = useCallback(
    async (assetId: string) => {
      try {
        const response = await devicesAPI.retrieveReportDeviceParameters(assetId);
        const parametersList = response.map(
          (parameter: DeviceParametersProps) => {
            return {
              label: `${parameter.device_name} - ${parameter.parameter_name}`, // ! add device name to parameter name
              value: `["${parameter.device_id}","${parameter.device_parameter_id}"]`,
            };
          }
        );

        dispatch(setParameterSelection(parametersList));
      } catch (error) {
        alert(error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!currentAssetId) {
      return;
    }

    if (currentAssetId) {
      handleAssetsParameterList(currentAssetId);
    }
  }, [
    assetParameterList.length,
    assetDeviceList.length,
    handleAssetsParameterList,
    currentAssetId,
  ]);

  const handleAssetsDevicesAndParameters = async (assetId: string) => {
    try {
      const assetProfile = await AssetAPI.retrieveAssetProfile(assetId);
      const { asset_devices, asset_parameters } = assetProfile;

      dispatch(setAssetDeviceList(asset_devices));
      dispatch(setAssetParameterList(asset_parameters));
    } catch (error) {
      alert(error);
    }
  };

  const handleSelectedNode = (node: TreeNode, path: number[], treeIndex: number) => {
    setTreeDepth(node.tenantType || node.childType);
    setNodeObject(node);
    setSelectedNode(node);
    setSelectedNodeId(node.assetGroupId || "");
    setPath(path);
    setTreeindex(treeIndex);

    if (node.childType !== "asset") {
      return;
    } else {
      handleAssetsDevicesAndParameters(node.assetGroupId);
      dispatch(setCurrentAssetId(node.assetGroupId));
    }
  };

  useEffect(() => {
    if (userId && parentTenantId) {
      setLoading(true);
      getGroupListForTenant();
    }
  }, [getGroupListForTenant, userId, parentTenantId]);

  //! add some logic that will allow the user to click on a path and update the node objects to help with traversing the application

  useEffect(() => {
    if (treeDepth === "parent" && treeData) {
      const parentNode = treeData[0];
      setNodeObject(parentNode);
    }
  }, [treeDepth, treeData]);

  const handleMouseDown = (path: number[]) => {
    const parentNode = getNodeAtPath({
      treeData: treeData,
      path: path.slice(0, path.length - 1),
      getNodeKey: ({ treeIndex }: TreeIndex) => treeIndex,
      ignoreCollapsed: true,
    });
    setSelectedParent(parentNode);
  };

  const getPlaceholderPath = (path: any) => {
    const placeHolderPath = [...path];
    const placeHolderNumber = placeHolderPath[placeHolderPath.length - 1] + 1;
    placeHolderPath.push(placeHolderNumber);
    return placeHolderPath;
  };

  const populateGroupChildren = useCallback(
    async (assetGroupId: string, path: number[]) => {
      try {
        const groupProfile = await AssetAPI.retrieveAssetGroupProfile(
          assetGroupId
        );

        groupProfile.children_groups.length > 0 &&
          groupProfile.children_groups.forEach(
            (groupAsset: ChildrenGroupProps) => {
              setTreeData((treeData: any) => {
                return addNodeUnderParent({
                  treeData: treeData,
                  parentKey: path[path.length - 1],
                  expandParent: true,
                  getNodeKey: ({ treeIndex }: TreeIndex) => treeIndex,
                  newNode: {
                    assetGroupId: groupAsset.asset_group_id,
                    title: groupAsset.group_name,
                    childType: "group",
                    tenantId: groupProfile.tenant_id,
                    children: placeholder,
                    isAssetGroup: groupProfile.is_asset_group,
                  },
                }).treeData;
              });
            }
          );

        groupProfile.asset_organizer.length > 0 &&
          groupProfile.asset_organizer.forEach((asset: AssetOrganizerProps) => {
            setTreeData((treeData: any) => {
              return addNodeUnderParent({
                treeData: treeData,
                parentKey: path[path.length - 1],
                expandParent: true,
                getNodeKey: ({ treeIndex }: TreeIndex) => treeIndex,
                newNode: {
                  assetGroupId: asset.asset_id,
                  title: asset.asset_name,
                  childType: "asset",
                  tenantId: groupProfile.tenant_id,
                  children: placeholder,
                  isAssetGroup: false,
                },
              }).treeData;
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const populateAssetsChildren = async (assetId: any, path: number[]) => {
    try {
      const assetProfile = await AssetAPI.retrieveAssetProfile(assetId);

      assetProfile.children_asset.length > 0 &&
        assetProfile.children_asset.forEach((asset: AssetOrganizerProps) => {
          setTreeData((treeData: any) => {
            return addNodeUnderParent({
              treeData: treeData,
              parentKey: path[path.length - 1],
              expandParent: true,
              getNodeKey: ({ treeIndex }: TreeIndex) => treeIndex,
              newNode: {
                assetGroupId: asset.asset_id,
                title: asset.asset_name,
                childType: "asset",
                tenantId: assetProfile.tenant_id,
                children: placeholder,
                isAssetGroup: false,
              },
            }).treeData;
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const addSingleGroupToParent = useCallback(
    async (selectedNodeId: string, path: number[]) => {
      try {
        const groupProfile = await AssetAPI.retrieveAssetGroupProfile(
          selectedNodeId
        );
        const groupProfileChildren = groupProfile.children_groups;

        if (groupProfileChildren.length > 0) {
          const lastIndex = groupProfileChildren.length - 1;
          const newChild = groupProfileChildren[lastIndex];

          setTreeData((treeData: any) => {
            return addNodeUnderParent({
              treeData: treeData,
              parentKey: path[path.length - 1],
              expandParent: true,
              getNodeKey: ({ treeIndex }: TreeIndex) => treeIndex,
              newNode: {
                assetGroupId: newChild.asset_group_id,
                title: newChild.group_name,
                childType: "group",
                tenantId: groupProfile.tenant_id,
                children: placeholder,
                isAssetGroup: groupProfile.is_asset_group,
              },
            }).treeData;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const addSingleAssetToParent = useCallback(
    async (selectedNodeId: string, path: number[]) => {
      try {
        const groupProfile = await AssetAPI.retrieveAssetGroupProfile(
          selectedNodeId
        );
        const groupProfileChildren = groupProfile.asset_organizer;

        if (groupProfileChildren.length > 0) {
          const lastIndex = groupProfileChildren.length - 1;
          const newChild = groupProfileChildren[lastIndex];

          setTreeData((treeData: any) => {
            return addNodeUnderParent({
              treeData: treeData,
              parentKey: path[path.length - 1],
              expandParent: true,
              getNodeKey: ({ treeIndex }: TreeIndex) => treeIndex,
              newNode: {
                assetGroupId: newChild.asset_id,
                title: newChild.asset_name,
                childType: "asset",
                tenantId: groupProfile.tenant_id,
                children: placeholder,
                isAssetGroup: false,
              },
            }).treeData;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  useEffect(() => {
    if (callGroupProfile && treeDepth === "group") {
      addSingleGroupToParent(selectedNodeId, path);
      dispatch(setCallGroupProfile(false));
    }

    if (callAssetProfile && treeDepth === "group") {
      addSingleAssetToParent(selectedNodeId, path);
      dispatch(setCallAssetProfile(false));
    }
  }, [
    callGroupProfile,
    callAssetProfile,
    dispatch,
    selectedNodeId,
    path,
    addSingleGroupToParent,
    addSingleAssetToParent,
    treeDepth,
  ]);

  const addSingleAssetToComplexAsset = useCallback(
    async (selectedNodeId: string, path: number[]) => {
      try {
        const assetProfile = await AssetAPI.retrieveAssetProfile(
          selectedNodeId
        );
        const assetsChildren = assetProfile.children_asset;

        if (assetsChildren.length > 0) {
          const lastIndex = assetsChildren.length - 1;
          const newChild = assetsChildren[lastIndex];

          setTreeData((treeData: any) => {
            return addNodeUnderParent({
              treeData: treeData,
              parentKey: path[path.length - 1],
              expandParent: true,
              getNodeKey: ({ treeIndex }: TreeIndex) => treeIndex,
              newNode: {
                assetGroupId: newChild.asset_id,
                title: newChild.asset_name,
                childType: "asset",
                tenantId: assetProfile.tenant_id,
                children: placeholder,
                expanded: true,
                isAssetGroup: false,
              },
            }).treeData;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  useEffect(() => {
    if (callAssetProfile && treeDepth === "asset") {
      addSingleAssetToComplexAsset(selectedNodeId, path);
      dispatch(setCallAssetProfile(false));
    }
  }, [
    callAssetProfile,
    dispatch,
    selectedNodeId,
    path,
    addSingleAssetToComplexAsset,
    treeDepth,
  ]);

  const removePlaceholderChild = (path: number[]) => {
    setTreeData((treeData: any) => {
      return removeNodeAtPath({
        treeData: treeData,
        path: getPlaceholderPath(path),
        getNodeKey,
      });
    });
  };

  const callNodesChildren = async (
    node: any,
    expanded: boolean,
    path: number[]
  ) => {
    if (
      expanded &&
      node.childType === "group" &&
      node.children[0].title === "Placeholder"
    ) {
      removePlaceholderChild(path);
      // populateGroupChildren(groupProfile, path);
      populateGroupChildren(node.assetGroupId, path);
    } else if (
      expanded &&
      node.childType === "asset" &&
      node.children[0].title === "Placeholder"
    ) {
      removePlaceholderChild(path);
      // populateAssetsChildren(assetProfile, path);
      populateAssetsChildren(node.assetGroupId, path);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (groupDelete) {
      setTreeData((treeData: any) => {
        return removeNodeAtPath({
          treeData: treeData,
          path: path,
          getNodeKey,
        });
      });
      dispatch(setGroupDelete(false));
    }

    if (assetDelete) {
      setTreeData((treeData: any) => {
        return removeNodeAtPath({
          treeData: treeData,
          path: path,
          getNodeKey,
        });
      });
      dispatch(setAssetDelete(false));
    }
  }, [groupDelete, assetDelete, path, dispatch]);

  useEffect(() => {
    setTreeData((treeData: any) => {
      return changeNodeAtPath({
        treeData,
        path,
        getNodeKey,
        newNode: { ...nodeObject, title: groupName },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName]);

  useEffect(() => {
    setTreeData((treeData: any) => {
      return changeNodeAtPath({
        treeData,
        path,
        getNodeKey,
        newNode: { ...nodeObject, title: newAssetName },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAssetName]);

  return loading ? (
    <LoadSpinner center={true} size="90vh" />
  ) : (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.search}>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.fileContainer}>
            <div
              style={{
                // margin: "2rem 0", // ! uncomment this when search is implemented
                height: 600,
              }}
            >
              <SortableTree
                treeData={filteredData}
                theme={FileExplorerTheme}
                onChange={(treeData: TreeNode[]) => { setTreeData(treeData); }}
                // onMoveNode={(treeData: object[]) => handleMove(treeData)}
                // canDrop={(moveData: any) => handleCanDrop(moveData)}
                canDrag={false}
                canDrop={() => false}
                onVisibilityToggle={({
                  treeData,
                  node,
                  expanded,
                  path,
                }: {
                  treeData: object[];
                  node: any;
                  expanded: boolean;
                  path: number[];
                }) => {
                  callNodesChildren(node, expanded, path);
                }}
                generateNodeProps={({
                  node,
                  path,
                  treeIndex,
                }: {
                  node: TreeNode;
                  path: number[];
                  treeIndex: number;
                }) => {
                  const isSelected = selectedNode && selectedNode.title === node.title;
                  return {
                    style: {
                      cursor: "pointer",
                    },
                    title: (
                      <>
                        {node.childType === "group" ||
                          node.tenantType === "parent" ? (
                          <span style={{ display: 'flex', alignItems: 'center' }}>
                            {!node.expanded || node.children.length === 0 ? (
                              <GroupWorkIcon className={classes.groupiconDefault}
                              />
                            ) : (
                              <GroupWorkIcon className={classes.groupiconSelected}
                              />
                            )}
                            <span
                              className={`${classes.textWithBackground} ${isSelected ? classes.textSelected : classes.textDefault}`}
                            >
                              {"  "}
                              {node.title}
                            </span>
                          </span>
                        ) : (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <SettingsIcon
                              className={isSelected ? 'settingsiconSelected' : 'settingsiconDefault'}
                            />
                            <span
                              className={`${classes.textWithBackground} ${isSelected ? classes.textSelected : classes.textDefault}`}
                            >
                              {" "} {node.title}
                            </span>
                          </div>
                        )}
                      </>
                    ),
                    onClick: () => {
                      handleSelectedNode(node, path, treeIndex);
                    },
                    onMouseDown: () => {
                      handleMouseDown(path);
                    },
                  };
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={8}>
          <div className={classes.assetContainer}>
            {/* Added condition to show Add or Update report modal for issue #273 */}
            {typeOfReportModal == "Add" ? (
              <DefaultReportsDisplay
                nodeObject={nodeObject}
                treeDepth={treeDepth}
                closeReportModal={closeReportModal}
              />
            ) : (
              <UpdateDefaultReportDisplay
                nodeObject={nodeObject}
                treeDepth={treeDepth}
                reportObject={selectedReport}
                closeReportModal={closeReportModal}
              />
            )}
          </div>
          <SuccessModal />
          <UnsuccessModal />
        </Grid>
      </Grid>
    </>
  );
};

export default ReportsDashboard;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileContainer: {
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    faIcon: {
      color: theme.palette.primary.light,
    },
    assetContainer: {
      // height: "85vh",
      border: `2px solid ${Colors.LightGray}`,
      borderRight: "none",
      borderBottom: "none",
    },
    value: {
      fontSize: 24,
    },
    search: {
      display: "flex",
      alignItems: 'center',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.divider}`,
      fontsize: '18px',
      width: '300px',
    },
    searchIcon: {
      height: "100%",
      position: "absolute",
    },
    searchbutton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      cursor: "pointer",
      backgroundColor: `${theme.palette.primary.light}`,
      width: 45,
    },
    inputRoot: {
      border: "1px solid #DDD",
      borderRadius: 0,
      width: "100%",
      backgroundColor: "#fff",
    },
    inputInput: {
      paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
      width: "100%",
    },
    checkboxIcon: {
      color: theme.palette.primary.light,
    },
    settingsiconDefault: {
      stroke: theme.palette.primary.dark,
      fontSize: 24,
      verticalAlign: 'middle',
      lineHeight: 'normal',
    },
    settingsiconSelected: {
      color: 'green',
      stroke: theme.palette.primary.dark,
      fontSize: 24,
      verticalAlign: 'middle',
      lineHeight: 'normal',
    },
    groupiconDefault: {
      color: 'blue',
      stroke: theme.palette.primary.dark,
      fontSize: 24,
      verticalAlign: 'middle',
      lineHeight: 'normal',
    },
    groupiconSelected: {
      color: 'lightblue',
      stroke: theme.palette.primary.dark,
      fontSize: 24,
      verticalAlign: 'middle',
      lineHeight: 'normal',
    },
    textWithBackground: {
      padding: '0.2rem 0.5rem', // Add padding to extend background
      background: 'inherit',
      borderRadius: '4px', // Optional: Add border radius for better aesthetics
    },
    textSelected: {
      background: 'black',
      color: 'white',
      fontWeight: 'bold',
    },
    textDefault: {
      background: 'inherit',
      color: 'inherit',
    }
  })
);
