/* Issue fixed for github issue #90,issue #117,issue #274*/
import React, { useEffect, useMemo, useState, useRef } from "react";
// import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import Button from "components/common-buttons/Button";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceArea
} from "recharts";

interface GraphInterface {
  timestamp: string;
  [key: string]: number | string;
}

interface SyncLineChartProps {
  chartHeight: number;
  graphData: Array<GraphInterface>;
  minMaxData: any; //Added props to get the min and max values. Github issue #90
}

const SyncLineChart: React.FC<SyncLineChartProps> = ({
  chartHeight,
  graphData,
  minMaxData
}) => {
  // const styles = useStyles();
  const chartRef = useRef<HTMLDivElement>(null);
  const colors = [
    Colors.BasinBlue,
    Colors.BasinRed,
    Colors.BasinPurple,
    Colors.BasinDarkBlue
  ];

  const [refAreaLeft, setRefAreaLeft] = useState(0);
  const [refAreaRight, setRefAreaRight] = useState(0);
  const [refCoords, setRefCoords] = useState<any>([0, 0]);
  // const [selectedSeries, setSelectedSeries] = useState<Record<string, boolean>>(
  //   {}
  // );
  const [filteredData, setFilteredData] = useState<any>([]);
  const [ticks, setTicks] = useState<string[]>([]);

  useEffect(() => {
    if (refCoords !== [0, 0]) {
      let first = filteredData.filter(
        (d: any) => d.timestamp === refCoords[0]
      )[0];
      let firstIdx = filteredData.indexOf(first);
      let last = filteredData.filter(
        (d: any) => d.timestamp === refCoords[1]
      )[0];
      let lastIdx = filteredData.indexOf(last);
      if (firstIdx > -1 && lastIdx < filteredData.length) {
        let newData = filteredData.slice(firstIdx, lastIdx);
        setFilteredData(newData);
        setRefAreaLeft(0);
        setRefAreaRight(0);
        setRefCoords([0, 0]);
      }
    }
  }, [refCoords]);

  useEffect(() => {
    if (graphData.length > 0) {
      let cleanedData = graphData.map(d => {
        delete d.epoch;
        return d;
      });
      setFilteredData(cleanedData);
    }
  }, [graphData]);

  // use epoch here
  useEffect(() => {
    if (filteredData.length > 0) {
      let first = filteredData[0].timestamp;
      let last = filteredData[Math.floor(filteredData.length - 1)].timestamp;
      if (
        Math.floor(filteredData.length / 2) !== 0 &&
        filteredData.length - 1 > -1
      ) {
        setTicks([first, last]);
      } else {
        setTicks([first]);
      }
    }
  }, [filteredData]);

  const handleMouseDown = (event: any) => {
    if (event?.activeLabel) {
      setRefCoords([]);
      setRefAreaLeft(event.activeLabel);
      return;
    } else {
      return event;
    }
  };

  const handleMouseUp = (event: any) => {
    if (refAreaRight && refAreaLeft) {
      let epochLeft: any = new Date(refAreaLeft);
      epochLeft = epochLeft.getTime();
      let epochRight: any = new Date(refAreaRight);
      epochRight = epochRight.getTime();
      if (epochRight > epochLeft) {
        setRefCoords([refAreaLeft, refAreaRight]);
      } else {
        setRefCoords([refAreaRight, refAreaLeft]);
      }
    }
    setRefAreaLeft(0);
    setRefAreaRight(0);
    return event;
  };

  const handleMouseLeave = (event: any) => {
    setRefAreaLeft(0);
    setRefAreaRight(0);
  };

  const handleMouseMove = (event: any) => {
    if (refAreaLeft) {
      const start = event?.activePayload[0].payload.start;
      const end = event?.activePayload[0].payload.end;
      const refRight = refAreaLeft < end ? end : start ?? event.activeLabel;
      setRefAreaRight(refRight);
    }
    return event;
  };

  // const maxRef = useMemo(() => Math.max(...refCoords), [refCoords]);
  // const minRef = useMemo(() => Math.min(...refCoords), [refCoords]);

  const resetChart = () => {
    let cleanedData = graphData.map(d => {
      delete d.epoch;
      return d;
    });
    setFilteredData(cleanedData);
    setRefAreaLeft(0);
    setRefAreaRight(0);
    setRefCoords([0, 0]);
  };

  return (
    <div ref={chartRef} style={{ userSelect: "none" }}>
      <Button
        type="button"
        color="defaultTheme"
        onClick={() => resetChart()}
        style={{ margin: ".5rem 0" }}
      >
        Zoom Out
      </Button>
      {filteredData.length > 0 ? (
        <ResponsiveContainer width="100%" height={chartHeight}>
          <LineChart
            data={filteredData}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={() => {
              setRefAreaLeft(0);
              setRefAreaRight(0);
            }}
            onMouseUp={handleMouseUp}
            syncId="anyId"
            margin={{
              top: 20,
              right: 50,
              left: 20,
              bottom: 0
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            {ticks.length > 0 && (
              <XAxis dataKey="timestamp" interval={0} ticks={ticks} />
            )}
            {/* Updated the formula for getting the proper interval on Y axis according to min and max values. Github issue #90 */}
            {/* Updated the formula to limit values to 2 decimal places for issue #117 */}
            <YAxis
              type="number"
              tickFormatter={(value: number) => {
                return value.toFixed(2);
              }}
              domain={[
                // Changed domain min value formula for issue #117
                (min: number) => {
                  if (isFinite(min)) {
                    // Changed formula for issue #274
                    var decimals = 0;
                    const range = Math.abs(minMaxData.maxVal - minMaxData.minVal);
                    var maxsign = 1;
                    var minsign = 1;

                    if (minMaxData.maxVal !== 0) {
                      maxsign = Math.sign(minMaxData.maxVal);
                    } else {
                      maxsign = 1;
                    }
                    if (minMaxData.minVal !== 0) {
                      minsign = Math.sign(minMaxData.minVal);
                    } else {
                      minsign = 1;
                    }
                    if (range <= 1) {
                      decimals = 2;
                    } else if (range > 1 && range <= 5) {
                      decimals = 1;
                    } else if (range > 5) {
                      decimals = 0;
                    }
                    const newmin = minsign * (Math.abs(minMaxData.minVal) - 0.2 * minsign * range);
                    const truncmin = parseFloat(newmin.toFixed(decimals));
                    return truncmin;
                  } else {
                    return 0;
                  }
                },
                //Changed domain max value formula for issue #117
                (max: number) => {
                  if (isFinite(max)) {
                    var decimals = 0;
                    const range = Math.abs(minMaxData.maxVal - minMaxData.minVal);
                    var maxsign = 1;
                    var minsign = 1;

                    if (minMaxData.maxVal !== 0) {
                      maxsign = Math.sign(minMaxData.maxVal);
                    } else {
                      maxsign = 1;
                    }
                    if (minMaxData.minVal !== 0) {
                      minsign = Math.sign(minMaxData.minVal);
                    } else {
                      minsign = 1;
                    }
                    if (range <= 1) {
                      decimals = 2;
                    } else if (range > 1 && range <= 5) {
                      decimals = 1;
                    } else if (range > 5) {
                      decimals = 0;
                    }
                    const newmax = maxsign * (Math.abs(minMaxData.maxVal) + 0.2 * maxsign * range);
                    const truncmax = parseFloat(newmax.toFixed(decimals));
                    return truncmax;
                  } else {
                    return 1;
                  }
                }
              ]}
            />
            <Tooltip />
            <Legend />
            {filteredData?.length > 0 &&
              Object.keys(filteredData[0]).map(
                (data, i) =>
                  i > 0 && (
                    <Line
                      dot={false}
                      key={i}
                      type="monotone"
                      dataKey={data}
                      stroke={colors[i]}
                      fill={colors[i]}
                      activeDot={{ r: 8 }}
                    />
                  )
              )}
            {refAreaLeft && refAreaRight ? (
              <ReferenceArea
                x1={refAreaLeft}
                x2={refAreaRight}
                strokeOpacity={0.3}
              />
            ) : null}
            {refCoords.length > 0 && (
              <ReferenceArea
                x1={refCoords[0]}
                x2={refCoords[1]}
                strokeOpacity={0.3}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <h1>No Graph Data.</h1>
      )}
    </div>
  );
};

// const useStyles = makeStyles((theme) => createStyles({}));

export default SyncLineChart;
