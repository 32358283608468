/* Issue fixed for github issue #24*/
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Colors } from "common/Colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      zIndex: -1,
    },
    content: {
      flexGrow: 1,
      padding: "1.5rem 3rem",
    },
    headerIcon: {
      width: 18,
    },
    listHeader: {
      margin: "3rem 0 .5rem 3rem",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "baseline",
      fontWeight: 600,
      fontSize: 18,
      textTransform: "uppercase",
      alignItems: "center",
      margin: "0 0 0 .4rem",
      color: Colors.White,
    },
    routeNames: {
      display: "flex",
      alignItems: "center",
      fontSize: 18,
      color: Colors.White,
      cursor: "pointer",
      textDecoration: "none",
      flexGrow: 1,
      opacity: 1,
      marginLeft: "2em",
      padding: ".2em 0 .2em 1.7em",
    },
    active: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      backgroundColor: "#FAFAFA",
      borderRadius: "30px 0 0 30px",
      pointerEvents: "none",
    },

    //adding classes for issue #24
    //css class for hiding admin and reports section in responsive UI for issue #24
    mobileView: {
      [`@media (max-width: 800px)`]: {
        display: "none",
      },
    },
    //css class for displaying logout heading in responsive UI issue #24
    logoutbutton: {
      display: "none",
      [`@media (max-width: 800px)`]: {
        display: "flex",
        alignItems: "center",
        fontSize: 18,
        color: Colors.White,
        cursor: "pointer",
        textDecoration: "none",
        flexGrow: 1,
        opacity: 1,
        marginLeft: "1.2em",
        marginTop: "1em",
        padding: ".2em 0 .2em 1.7em",
      },
    },
  })
);
