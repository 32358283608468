import React, { useState, useEffect } from "react";
import { Colors } from "common/Colors";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import { tableStyle } from "../../devices-page/device-settings/device-settings-tabs/tableStyles";
import Xsquare from "assets/bt-assets/x-square.svg";
import Checkbox from "@material-ui/core/Checkbox";
import { setSelectedDeviceId } from "../assetsDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import clsx from "clsx";

type DeviceTableDisplayProps = {
  callDeleteDeviceModal: Function;
};

export const DeviceTableDisplay: React.FC<DeviceTableDisplayProps> = ({
  callDeleteDeviceModal,
}) => {
  const classes = useStyles();
  const table = tableStyle();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);

  const { assetDeviceList } = useSelector(
    (state: RootState) => state.AssetDashboardSlice
  );

  useEffect(() => {
    if (!assetDeviceList.length) {
      setExpanded(false);
    }
  }, [assetDeviceList.length]);

  return (
    <>
      <div style={{ width: "100%", padding: 0 }}>
        <Paper
          elevation={0}
          className={
            expanded
              ? classes.paperStylesExpanded
              : classes.paperStylesCollapsed
          }
          onClick={() => {
            setExpanded((expanded) => !expanded);
          }}
        >
          <div className={classes.collapseBarInfo}>
            <span
              className={clsx({
                [classes.tabbedHeaderCollapsed]: !expanded,
                [classes.tabbedHeaderExpanded]: expanded,
              })}
            >
              Devices ({assetDeviceList.length})
            </span>
          </div>
          {assetDeviceList.length ? (
            <>
              {expanded ? (
                <RemoveCircleOutlineOutlinedIcon
                  className={classes.expandIconOpen}
                />
              ) : (
                <ControlPointIcon className={classes.expandIconCollapsed} />
              )}
            </>
          ) : null}
        </Paper>
        {expanded && (
          <div className={classes.expansionContainer}>
            <TableContainer style={{ maxHeight: 500, margin: "2rem 0" }}>
              <Table
                style={{ width: "100%" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={table.header} align="left">
                      <span className={classes.headerName}>Device</span>
                    </TableCell>
                    <TableCell className={table.header} align="left">
                      <span className={classes.headerName}>
                        Show events and notifications
                      </span>
                    </TableCell>{" "}
                    <TableCell className={table.header} align="left">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assetDeviceList.map(
                    (
                      device: {
                        device_id: string;
                        device_name: string;
                        show_notifications: boolean;
                      },
                      index: number
                    ) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className={table.tableCell} align="left">
                            {device.device_name}
                          </TableCell>
                          <TableCell className={table.tableCell} align="left">
                            <Checkbox
                              checked={device.show_notifications}
                              className={classes.cb}
                            />
                          </TableCell>
                          <TableCell
                            className={table.tableCell}
                            style={{ padding: "0 2em" }}
                            align="left"
                          >
                            <img
                              src={Xsquare}
                              alt="Delete asset property"
                              className={classes.faIcon}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                callDeleteDeviceModal(device.device_id);
                                dispatch(setSelectedDeviceId(device.device_id));
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    cb: {
      color: theme.palette.primary.light,
      backgroundColor: "transparent",
      margin: 0,
      padding: 0,
      cursor: "default",
    },
    faIcon: {
      color: theme.palette.primary.light,
    },
    expansionContainer: { display: "flex", flexDirection: "column" },
    headerCollapsed: {
      color: theme.palette.primary.light,
      padding: 0,
      margin: 0,
    },
    headerExpanded: { color: "FFF", padding: 0, margin: 0 },
    paperStylesCollapsed: {
      marginTop: "1em",
      height: "2.5em",
      width: "100%",
      border: "solid 1px lightgrey",
      borderLeft: `solid 5px ${theme.palette.primary.light}`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "1em",
      paddingLeft: "1em",
      borderRadius: 0,
      cursor: "pointer",
    },
    paperStylesExpanded: {
      height: "2.5em",
      marginTop: "1em",
      width: "100%",
      border: "solid 1px lightgrey",
      borderLeft: `solid 0 ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.primary.light,
      color: "white",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "1em",
      paddingLeft: "1em",
      borderRadius: 0,
      cursor: "pointer",
    },
    tabbedHeaderCollapsed: {
      color: theme.palette.primary.light,
    },
    tabbedHeaderExpanded: {
      color: Colors.White,
    },
    headerName: {
      color: theme.palette.primary.light,
    },
    name: {
      cursor: "poiner",
      color: theme.palette.primary.light,
    },
    expandIconCollapsed: {
      color: theme.palette.primary.light,
      borderRadius: 0,
      "&:hover": { cursor: "pointer" },
    },
    expandIconOpen: {
      color: "white",
      padding: 0,
      borderRadius: "50%",
      "&:hover": { cursor: "pointer" },
    },
    collapseBarInfo: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      borderRadius: 0,
    },
    buttonStyles: {
      color: "#FFF",
      backgroundColor: theme.palette.primary.light,
      margin: ".5em 0 .5em 0",
      width: "40%",
      alignSelf: "flex-end",
      fontSize: ".7em",
      "&:hover": {
        color: "#FFF",
        backgroundColor: theme.palette.primary.light,
      },
    },
  })
);
