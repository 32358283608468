/* Issue fixed for github issue #24  */
import React, { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import LoadSpinner from "common/LoadSpinner";
import { batchAPI } from "services/batchAPI";
import { setCurrentBatchId } from "features/drawer/drawerSlice";
import { format } from "date-fns";
import Button from "components/common-buttons/Button";
import { RequestedBatchDevicesProps } from "./types";
import ExportIcon from "assets/bt-assets/Group 55.png";
import { useHistory } from "react-router-dom";
import { useStyles } from "./styles";
import fromUnixTime from "date-fns/esm/fp/fromUnixTime/index.js";

export type BatchProps = {};
export const Batch: React.FC<BatchProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [requestedDevices, setRequestedDevices] = useState<
    RequestedBatchDevicesProps[]
  >([]);
  const { batchId } = useSelector((state: RootState) => state.DrawerSlice);

  const handleExportToExcel = async () => {
    try {
      const csvKeys = Object.keys(requestedDevices[0]);
      const csvValues = requestedDevices.map((dataObject: any) => {
        return Object.values({
          ...dataObject,
          aws_root_ca: dataObject.aws_root_ca.replace(/\r?\n|\r/g, ""),
          PublicKey: dataObject.PublicKey.replace(/\r?\n|\r/g, ""),
          PrivateKey: dataObject.PrivateKey.replace(/\r?\n|\r/g, ""),
          certificatePem: dataObject.certificatePem.replace(/\r?\n|\r/g, ""),
          created: format(
            fromUnixTime(Number(dataObject.created)),
            // changed format from "MMMM dd,yyyy" to MM/dd/yyyy for web view issue #24
            "MM/dd/yyyy | h:mm a"
          ),
        });
      });

      const csvData = [csvKeys, ...csvValues]
        .map((indexArray: any) => indexArray.join(","))
        .join("\n");

      const csvContent = `data:text/csv;charset=utf-8, ${csvData}`;
      const encodedUri = encodeURI(csvContent);
      const linkedToDownload = document.createElement("a");

      linkedToDownload.setAttribute("href", encodedUri);
      linkedToDownload.setAttribute("download", `batch-${batchId}-devices.csv`);
      document.body.appendChild(linkedToDownload);
      linkedToDownload.click();
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  const handleExportToJson = async () => {
    try {
      const downloadJson = (content: any, fileName: any, contentType: any) => {
        const a = document.createElement("a");
        const file = new Blob([content], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
      };
      downloadJson(
        JSON.stringify(requestedDevices),
        `batch-${batchId}.json`,
        "text/plain"
      );
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const requestedDevicesFromBatchId =
          await batchAPI?.retrieveAllDevicesInBatch(batchId as string);
        setRequestedDevices(requestedDevicesFromBatchId);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [batchId]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentBatchId(null));
    };
  }, [dispatch]);

  return loading ? (
    <LoadSpinner size="90vh" />
  ) : (
    <>
      <Grid container>
        <Grid item xs={12} className={classes.root}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "1rem 0",
              gap: "1rem",
            }}
          >
            <Button
              type="button"
              color="defaultTheme"
              onClick={() => {
                history.push("/batch");
                dispatch(setCurrentBatchId(null));
              }}
            >
              Back to create batch
            </Button>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button type="button" color="white" onClick={handleExportToExcel}>
                <img
                  src={ExportIcon}
                  alt="Export icon"
                  style={{ marginRight: ".5em" }}
                />
                Export to excel
              </Button>
              <Button type="button" color="white" onClick={handleExportToJson}>
                <img
                  src={ExportIcon}
                  alt="Export icon"
                  style={{ marginRight: ".5em" }}
                />
                Export Json
              </Button>
            </div>
          </div>
        </Grid>
        {!requestedDevices.length ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              height: "80vh",
              width: "100%",
            }}
          >
            No batch device(s) to display
          </div>
        ) : (
          <Grid item xs={12} className={classes.root}>
            <TableContainer style={{ maxHeight: 800 }}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.columnText}>
                      Timestamp
                    </TableCell>
                    <TableCell className={classes.columnText}>
                      Device id
                    </TableCell>
                    <TableCell className={classes.columnText}>
                      Hardware id
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requestedDevices.map(
                    (device: RequestedBatchDevicesProps, index: number) => {
                      return (
                        <TableRow
                          key={device.device_id + device.hardware_id + index}
                          style={{
                            position: "relative",
                          }}
                        >
                          <TableCell className={classes.tableCell}>
                            {format(
                              fromUnixTime(Number(device.created)),
                              // changed format from "MMMM dd,yyyy" to MM/dd/yyyy for web view for issue #24
                              "MM/dd/yyyy | h:mm a"
                            )}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {device.device_id}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {device.hardware_id}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Batch;
