// Use this to customize the app.  This should be different for every prod app
export const footerText = "© 2024 Lutz-JESCO America Corp.";
export const companyName = "Lutz-JESCO America Corp.";
export const companyAddress = "55 Bermar Park";
export const companyCityStateZip = "Rochester, NY 14624";
export const companyPhone = "(585) 426-0990";
export const companyEmail = "mail@jescoamerica.com";
export const companyLogoWidth = "200px";
export const companyLogoHeight = "107px";
export const colorPowered = "#073038";
export const companyDomain = ".lutzjescoamerica.com";
export const ifSRC = "https://ap.lutzjescoamerica.com/";
export const companyDarkColor = "#0c5462";
export const custAppId = "2";