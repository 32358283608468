import React from "react";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from "@material-ui/core/Radio";

export const ThemedRadio = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.light,
      backgroundColor: "transparent",
      "&$checked": {
        color: theme.palette.primary.light,
      },
    },
    checked: {},
  })
)((props: RadioProps) => <Radio color="default" {...props} />);
