/* Issue fixed for github issue #94,issue #168,issue #97,issue #192,issue #221 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DeviceTabDetailsProps,
  DeviceConfigurationProps,
  WirelessNodesProps //Added WirelessNodesProps for initializing device_id and device_name for issue #94
} from "./types";
import { TimeZoneSelectionsProps } from "../device-settings/device-settings-tabs/system-settings/types";

export const deviceTabDetails: DeviceTabDetailsProps = {
  time_zone: "Select a timezone...",
  time_zone_gmt: 0,
  static_gps: true,
  gio_location: [],
  lpmri: 0,
  lpmwui: 0,
  operation_mode: 0, //Added new key for issue #168
  contract_hour: 0, //Added new key for issue #168
  notify_pause: 0, //Added new key for issue #168
  notify_later: 0, //Added new key for issue #168
  canbus_baud_rate: 1, //Added new key for issue #192
  ip_address: "192.168.1.10",
  serial_port: 0,
  client_port: 502,
  subnet_mask: "255.255.255.0",
  modbus_server_port: 502,
  modbus_tcp_timeout: 5,
  modbus_rtu_baud_rate: 0,
  modbus_rtu_port_settings: 0,
  modbus_rtu_timeout: 5,
  modbus_data_timeout: 0,
  tenant_id: "",
  device_id: "",
  system_settings_name: "",
  part_number: "",
  device_local_devices: [],
  device_alarms: [],
  device_automation: [], //initializing device_automation to empty array for issue #97
  device_snapshots: [], //initializing device_snapshots to empty array for issue #221
  device_parameters: [],
  device_histograms: [],
  subscription_info: {
    subscription_expiry: "",
    subscription_last_updated: "",
    subscription_level: 0
  }
};

const initialState: {
  defaultDeviceTabDetails: DeviceTabDetailsProps;
  modifiedDeviceTabDetails: DeviceTabDetailsProps;
  deviceConfigurations: DeviceConfigurationProps[];
  openAddLocalDeviceModal: boolean;
  openAddParameterModal: boolean;
  openAddAlarmModal: boolean;
  openAddAutomationModal: boolean; // for opening and closing of add rule modal for issue #97
  openAddSnapshotModal: boolean; // for opening and closing of add snapshot modal for issue #221 
  openAddHistogramModal: boolean;
  isResetting: boolean;
  timeZoneSelections: TimeZoneSelectionsProps[];
  systemSettingsPage: boolean;
  serial_port: number;
  static_gps: boolean;
  configurationNumber: string;
  isConfigurationUsed: boolean;
  wirelessNodeList: WirelessNodesProps[]; //Added wirelessNodeList to store data from API call in redux store for issue #94
} = {
  defaultDeviceTabDetails: deviceTabDetails,
  modifiedDeviceTabDetails: deviceTabDetails,
  deviceConfigurations: [],
  openAddLocalDeviceModal: false,
  openAddParameterModal: false,
  openAddAlarmModal: false,
  openAddAutomationModal: false, //initializing it to false to keep add rule modal closed for issue #97
  openAddSnapshotModal: false, //initializing it to false to keep add snapshot modal closed for issue #221
  openAddHistogramModal: false,
  isResetting: false,
  timeZoneSelections: [],
  systemSettingsPage: true,
  serial_port: 0,
  static_gps: false,
  configurationNumber: "000-0000-00",
  isConfigurationUsed: false,
  wirelessNodeList: [{ device_id: "", device_name: "" }] //initializing wirelessNodeList for issue #94
};

const deviceSettingsSlice = createSlice({
  name: "DeviceSettingsSlice",
  initialState,
  reducers: {
    setDefaultDeviceTabDetails: (
      state,
      action: PayloadAction<DeviceTabDetailsProps>
    ) => {
      state.defaultDeviceTabDetails = action.payload;
    },
    setModifiedDeviceTabDetails: (
      state,
      action: PayloadAction<DeviceTabDetailsProps>
    ) => {
      state.modifiedDeviceTabDetails = action.payload;
    },
    //Added state function to set wirelessNodeList so that it can be accessed from any page for issue #94
    setWirelessNodeList: (
      state,
      action: PayloadAction<WirelessNodesProps[]>
    ) => {
      state.wirelessNodeList = action.payload;
    },
    updatePartialModifiedDeviceTabDetails: (
      state,
      action: PayloadAction<Partial<DeviceTabDetailsProps>>
    ) => {
      state.modifiedDeviceTabDetails = {
        ...state.modifiedDeviceTabDetails,
        ...action.payload
      };
    },
    setDeviceConfigurations: (
      state,
      action: PayloadAction<DeviceConfigurationProps[]>
    ) => {
      state.deviceConfigurations = action.payload;
    },
    setOpenAddLocalDeviceModal: (state, action: PayloadAction<boolean>) => {
      state.openAddLocalDeviceModal = action.payload;
    },
    setOpenAddParameterModal: (state, action: PayloadAction<boolean>) => {
      state.openAddParameterModal = action.payload;
    },
    setOpenAddAlarmModal: (state, action: PayloadAction<boolean>) => {
      state.openAddAlarmModal = action.payload;
    },
    // Added for opening and closing of add rule modal for issue #97
    setOpenAddAutomationModal: (state, action: PayloadAction<boolean>) => {
      state.openAddAutomationModal = action.payload;
    },
    // Added for opening and closing of add snapshot modal for issue #221
    setOpenAddSnapshotModal: (state, action: PayloadAction<boolean>) => {
      state.openAddSnapshotModal = action.payload;
    },
    setOpenAddHistogramModal: (state, action: PayloadAction<boolean>) => {
      state.openAddHistogramModal = action.payload;
    },
    setResetDeviceConfigurations: (state, action: PayloadAction<boolean>) => {
      state.isResetting = action.payload;
    },
    setTimeZoneSelection: (
      state,
      action: PayloadAction<TimeZoneSelectionsProps[]>
    ) => {
      state.timeZoneSelections = action.payload;
    },
    setSystemSettingsPage: (state, action: PayloadAction<boolean>) => {
      state.systemSettingsPage = action.payload;
    },
    setSubscriptionLevel: (state, action: PayloadAction<string>) => {
      state.modifiedDeviceTabDetails.subscription_info.subscription_level = Number(
        action.payload
      );
    },
    setSubscriptionExpiry: (state, action: PayloadAction<number>) => {
      state.modifiedDeviceTabDetails.subscription_info.subscription_expiry = String(
        action.payload
      );
    },
    setConfigurationNumber: (state, action: PayloadAction<string>) => {
      state.configurationNumber = action.payload;
    },
    setIsConfigurationUsed: (state, action: PayloadAction<boolean>) => {
      state.isConfigurationUsed = action.payload;
    }
  }
});

export const {
  setDefaultDeviceTabDetails,
  setModifiedDeviceTabDetails,
  updatePartialModifiedDeviceTabDetails,
  setWirelessNodeList, //Exporting the state function so that it can be used anywhere with dispatch for issue #94
  setDeviceConfigurations,
  setOpenAddLocalDeviceModal,
  setOpenAddParameterModal,
  setOpenAddAlarmModal,
  setOpenAddAutomationModal, //added for issue #97
  setOpenAddSnapshotModal,  //added for issue #221
  setOpenAddHistogramModal,
  setResetDeviceConfigurations,
  setTimeZoneSelection,
  setSystemSettingsPage,
  setSubscriptionLevel,
  setSubscriptionExpiry,
  setConfigurationNumber,
  setIsConfigurationUsed
} = deviceSettingsSlice.actions;
export default deviceSettingsSlice.reducer;
