//Added new file for verification or editting the mobile number for issue #152
import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  setSuccessModalOpen,
  setSuccessModalDescription
} from "components/success-modal/successModalSlice";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { InputLabel } from "components/input-label/InputLabel";
import { Colors } from "common/Colors";
import { UserInformation } from "app/types";
import { Button } from "components/common-buttons/Button";
import { formModalStyles } from "common/formModalStyles";
import { VerifyMobileOtpProps } from "./types";
import { regex } from "utils/regexPatterns";
import AssetAPI from "services/assetsAPI";
import AccountsAPI from "services/accountsAPI";
import { setCallUsers } from "../accountSlice";

type VerifyMobileModalProps = {
  open: boolean; //to open modal for issue #152
  handleClose: Function; //to close modal for issue #152
  user: UserInformation; //to show information related to selected user for issue #152
  pageTitle: string; //for page title for issue #152
};

export const VerifyMobileModal: React.FC<VerifyMobileModalProps> = ({
  open,
  handleClose,
  user,
  pageTitle
}) => {
  const styles = formModalStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sendResendOTPButton, setSendResendOTPButton] = useState(true);
  const [mobileFieldDisabled, setMobileFieldDisabled] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [minutes, setMinutes] = useState(15);
  const [seconds, setSeconds] = useState(0);
  const { register, handleSubmit, control, errors, getValues } = useForm<
    VerifyMobileOtpProps
  >();

  // function to call api for sending otp to the phone number for issue #152
  const sendingOTP = async () => {
    try {
      setShowTimer(true);
      setMinutes(15);
      setSeconds(0);
      const mobNumber = getValues("mobile_number");
      const response = await AccountsAPI.sendOtp(user?.user_id, mobNumber);
      // dispatch(setSuccessModalOpen(true));
      // dispatch(setSuccessModalDescription("OTP sent"));
      setMobileFieldDisabled(true);
      setTimeout(() => {
        setSendResendOTPButton(false);
      }, 6000);
    } catch (error) {
      console.log(error);
    }
  };

  // function to check if the otp is correct or not for issue #152
  const submitOTP = async ({
    mobile_number,
    mobile_otp
  }: VerifyMobileOtpProps) => {
    const mobNumber = getValues("mobile_number");
    const response = await AccountsAPI.verifyOtp(
      user?.user_id,
      mobNumber,
      mobile_otp
    );
    dispatch(setCallUsers(true));
    dispatch(setSuccessModalOpen(true));
    dispatch(setSuccessModalDescription("Mobile number verified successfully"));
    handleClose();
  };

  // for timer for issue #152
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalBody}>
        <div className={styles.header}>
          <p className={styles.headerText}>{pageTitle}</p>
          <CloseIcon className={styles.icon} onClick={() => handleClose()} />
        </div>
        <form onSubmit={handleSubmit(submitOTP)}>
          <div className={styles.inputBody}>
            <div className={styles.labelText}>
              <p>User name: </p>
              <p style={{ fontWeight: "bold" }}> {user.username} </p>
            </div>
            <div>
              <div className={styles.inputGroup}>
                <InputLabel
                  label="Mobile number"
                  name="mobile_number"
                  errors={errors}
                />
                <div
                  style={{
                    width: "93%",
                    opacity: mobileFieldDisabled ? "0.55" : "1"
                  }}
                >
                  <input
                    name="mobile_number"
                    ref={register({
                      required: "This field is required.",
                      pattern: {
                        value: regex.validPhone,
                        message: "Please enter in format xxx-xxx-xxxx"
                      }
                    })}
                    defaultValue={user.phone_mobile}
                    disabled={mobileFieldDisabled}
                    type="text"
                    placeholder="Enter"
                  />
                </div>
              </div>
              <div
                style={{ border: "0", margin: "0 2.5rem " }}
                className={styles.actionItems}
              >
                {(seconds > 0 || minutes > 0) && showTimer ? (
                  <p style={{ paddingRight: "2rem" }}>
                    OTP valid for : {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <></>
                )}
                <Button
                  type="button"
                  style={{ padding: "0 1rem" }}
                  color="defaultTheme"
                  onClick={() => {
                    sendingOTP();
                  }}
                >
                  {sendResendOTPButton ? "Send OTP" : "Re-send OTP"}
                </Button>
              </div>
            </div>
            <div className={styles.inputGroup}>
              <InputLabel label="OTP" name="mobile_otp" errors={errors} />
              <div style={{ width: "93%" }}>
                <input
                  name="mobile_otp"
                  ref={register({
                    required: "This field is required."
                  })}
                  minLength={6}
                  maxLength={6}
                  type="text"
                  placeholder="Enter"
                />
              </div>
            </div>
            <div className={styles.actionItems}>
              <Button type="button" color="white" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" color="defaultTheme">
                Confirm
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerifyMobileModal;

const reactSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer"
  })
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)"
    },
    modalBody: {
      minHeight: "20rem",
      width: 500,
      backgroundColor: "white"
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "2rem 2.5rem",
      borderBottom: `1px solid ${Colors.LightGray}`
    },
    headerText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      fontSize: 24,
      padding: 0,
      margin: 0
    },
    icon: {
      color: Colors.LightGray,
      cursor: "pointer",
      fontSize: "3rem"
    },
    pathText: {
      backgroundColor: Colors.LightGray
    },
    labelText: {
      display: "flex",
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        marginRight: "1rem",
        fontSize: "1rem"
      }
    },
    inputGroup: {
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem"
      },
      "& input": {
        width: "100%",
        padding: ".7rem 1rem",
        border: `1px solid ${Colors.LightGray}`
      }
    },
    invalidText: {
      color: "#ED254E"
    },
    deleteDescription: {
      padding: "0 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem"
      }
    },
    actionItems: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "2rem 2.5rem"
    },
    cancelButton: {
      padding: ".7rem 1.5rem",
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      cursor: "pointer",
      marginRight: "1.5rem",
      fontWeight: "bold",
      backgroundColor: "white"
    },
    saveButton: {
      padding: ".7rem 1.5rem",
      color: "white",
      backgroundColor: theme.palette.primary.light,
      cursor: "pointer",
      fontWeight: "bold",
      border: "none"
    }
  })
);
