/* Issue fixed for github issue #35 */
import React, { useCallback, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ActivateDeactivateUserModal } from "./ActivateDeactivateUserModal";
import DeleteUserModal from "./DeleteUserModal";
import Popover from "@material-ui/core/Popover";
import { useAccessLevelAndRoles } from "utils/reactHooks";

// import { Colors } from "common/Colors";

type UserPopOverProps = {
  id: string | undefined;
  open: any;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  tenantId: string;
  userActive: boolean;
  userId: string;
  username: string;
  userAccessLevel: string;
  setCallUser: React.Dispatch<React.SetStateAction<boolean>>;
};

export const UserPopOver: React.FC<UserPopOverProps> = ({
  id,
  open,
  anchorEl,
  handleClose,
  tenantId,
  userActive,
  userId,
  username,
  userAccessLevel,
  setCallUser,
}) => {
  const classes = useStyles();
  const [userActivity, setUserActivity] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [activateDeactivateUserModal, setActivateDeactivateUserModal] =
    useState(false);

  const {
    isBasintrakSuperAdmin,
    isBasintrakEngineer,
    isTenant,
    isTenantAdmin,
    isTenantPowerUser,
  } = useAccessLevelAndRoles();

  const handleUser = (userActivity: boolean) => {
    setUserActivity(userActivity);
    setActivateDeactivateUserModal(true);
    handleClose();
  };

  const closeActivateDeactivateUserModal = () => {
    setActivateDeactivateUserModal(false);
  };

  const deleteUserModalHandler = useCallback(() => {
    setDeleteModal(true);
    handleClose();
  }, [handleClose]);

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        classes={{
          paper: classes.paper,
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.selectionContainer}>
          {userActive ? (
            <p className={classes.selection} onClick={() => handleUser(true)}>
              Deactivate
            </p>
          ) : (
            <p className={classes.selection} onClick={() => handleUser(false)}>
              Activate
            </p>
          )}
          {isBasintrakSuperAdmin ||
            (isTenant && userAccessLevel === "general") ? (
            <p className={classes.selection} onClick={deleteUserModalHandler}>
              Delete
            </p>
          ) : null}
        </div>
      </Popover>
      <ActivateDeactivateUserModal
        open={activateDeactivateUserModal}
        handleClose={closeActivateDeactivateUserModal}
        userActivity={userActivity}
        userId={userId}
        tenantId={tenantId}
        setCallUser={setCallUser}
      />
      {deleteModal && (
        <DeleteUserModal
          open
          handleClose={() => setDeleteModal(false)}
          userId={userId}
          tenantId={tenantId} //Added tenantid for sending it to the deleteUserModal. Github issue #35
          username={username}
          setCallUser={setCallUser}
        />
      )}
    </>
  );
};

export default Popover;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectionContainer: {
      padding: ".5rem 1rem",
    },
    paper: {
      border: "none",
      borderRadius: 0,
    },
    selection: {
      fontSize: "14px",
      color: theme.palette.primary.light,
      margin: 0,
      cursor: "pointer",
    },
  })
);
