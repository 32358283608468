import React, { useState, useEffect, useCallback } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { formModalStyles } from "common/formModalStyles";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
} from "components/success-modal/successModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { Colors } from "common/Colors";
import { InputLabel } from "components/input-label/InputLabel";
import Button from "components/common-buttons/Button";
import LoadSpinner from "common/LoadSpinner";
import { useForm } from "react-hook-form";
import ProfileInformation from "./ProfileInformation";

// import clsx from "clsx";

type ProfileModalProps = {
  open: boolean;
  handleClose: Function;
  handleOpenPasswordModal: Function;
};

export const ProfileModal: React.FC<ProfileModalProps> = ({
  open,
  handleClose,
  handleOpenPasswordModal,
}) => {
  const classes = formModalStyles();
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalOverlay}>
        {loading ? (
          <LoadSpinner />
        ) : (
          <div className={classes.modalBody}>
            <div className={classes.header}>
              <p className={classes.headerText}>My profile</p>
              <CloseIcon
                className={classes.icon}
                onClick={() => handleClose()}
              />
            </div>
            <ProfileInformation />
            <div
              className={classes.actionItems}
              style={{ justifyContent: "space-between" }}
            >
              <Button
                type="button"
                color="defaultTheme"
                onClick={() => {
                  handleOpenPasswordModal();
                }}
              >
                Change password
              </Button>
              <Button
                type="submit"
                color="defaultTheme"
                onClick={() => handleClose()}
              >
                OK
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ProfileModal;
