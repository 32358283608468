import React, { useEffect } from "react";
import { useStyles } from "./topBarStyles";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";

export type BatchTopBarProps = { pageName: string };

export const BatchTopBar: React.FC<BatchTopBarProps> = ({ pageName }) => {
  const classes = useStyles();
  const { batchId } = useSelector((state: RootState) => state.DrawerSlice);

  return pageName === "Create batch" ? (
    <div className={classes.title}>
      {batchId ? `Batch ${batchId}` : pageName}
    </div>
  ) : null;
};

export default BatchTopBar;
