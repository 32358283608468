import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  resetSelectedAccountData,
  setSubPageActive,
} from "features/accounts-page/accountSlice";
import { useStyles } from "./topBarStyles";
import clsx from "clsx";

export type AccountsTopBarProps = { pageName: string; subPageActive: boolean };

export const AccountsTopBar: React.FC<AccountsTopBarProps> = ({
  pageName,
  subPageActive,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { tenant_name } = useSelector(
    (state: RootState) => state.AccountSlice.accountInformation
  );

  const handleRouteToAccountTable = () => {
    dispatch(resetSelectedAccountData());
    dispatch(setSubPageActive(false));
  };

  return (
    <>
      {pageName === "Accounts" ? (
        <>
          <div
            className={clsx({
              [classes.title]: !subPageActive,
              [classes.nestedTitle]: subPageActive,
            })}
            onClick={handleRouteToAccountTable}
          >
            {pageName}
          </div>
          {tenant_name && (
            <>
              <div style={{ margin: "0 .2rem", fontSize: "1.7rem" }}>›</div>
              <div className={classes.title}>{tenant_name}</div>
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default AccountsTopBar;
