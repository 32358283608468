import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./accountStyles";
import Checkbox from "@material-ui/core/Checkbox";
import { Colors } from "common/Colors";
import clsx from "clsx";
import alertCircle from "assets/bt-assets/alert-circle.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { setUserInformation } from "app/appSlice";
import {
  setAccountLoading,
  setSelectedAccountInformation,
} from "../accountSlice";
import { RootState } from "app/rootReducer";
import Modal from "@material-ui/core/Modal";
import { useForm } from "react-hook-form";
import { InputLabel } from "components/input-label/InputLabel";
import AccountAPI from "services/accountsAPI";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
} from "components/success-modal/successModalSlice";
import AccountSuccessModal from "./AccountSuccessModal";
import { InputLength } from "common/inputLength";
import {
  TenantInformation,
  AdminInformation,
  BillingInformation,
  ShippingInformation,
} from "./types";
import { regex } from "utils/regexPatterns";
import { RequiredUpdatedObject } from "app/types";
import Button from "components/common-buttons/Button";

const StyledCheckbox = ({
  checked,
  handleChange,
}: {
  checked: boolean;
  handleChange: any;
}) => {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      checked={checked}
      onChange={handleChange}
    />
  );
};

type AccountDetailsProps = {
  handleCancel: Function;
};

const AccountDetails: React.FC<AccountDetailsProps> = ({ handleCancel }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);

  const selectedAccountInformation = useSelector(
    (state: RootState) => state.AccountSlice.accountInformation
  );
  const logginInUserInformation = useSelector(
    (state: RootState) => state.AppSlice.userInformation
  );
  const { role, accountName } = useSelector(
    (state: RootState) => state.AccountSlice.selectedAccount
  );
  const isLoading = useSelector(
    (state: RootState) => state.AccountSlice.accountLoading
  );

  const isPowerUser =
    logginInUserInformation.access_level === "general" &&
    ["power_user"].indexOf(role) > -1;

  const [accountInformation, setAccountInformation] =
    useState<TenantInformation>({
      tenant_id: "",
    });
  const [adminInformation, setAdminInformation] = useState<AdminInformation>({
    phone: "",
    given_name: "",
    family_name: "",
    email: "",
  });
  const [billingAddress, setBillingAddress] = useState<BillingInformation>({
    zip_code: "",
    city: "",
    state_province: "",
    address_line: "",
  });
  const [shippingAddress, setShippingAddress] = useState<ShippingInformation>({
    zip_code: "",
    city: "",
    state_province: "",
    address_line: "",
  });
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    clearErrors,
    errors,
  } = useForm<RequiredUpdatedObject>();
  const watchBilling = watch([
    "billing_address.zip_code",
    "billing_address.city",
    "billing_address.state_province",
    "billing_address.address_line",
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    clearErrors("shipping_address");
  };

  useEffect(() => {
    const zipCodeBilling = getValues("billing_address.zip_code");
    const cityBilling = getValues("billing_address.city");
    const stateProvinceBilling = getValues("billing_address.state_province");
    const addressBilling = getValues("billing_address.address_line");

    if (checked) {
      setValue("shipping_address.zip_code", zipCodeBilling, {
        shouldValidate: true,
      });
      setValue("shipping_address.city", cityBilling, { shouldValidate: true });
      setValue("shipping_address.state_province", stateProvinceBilling, {
        shouldValidate: true,
      });
      setValue("shipping_address.address_line", addressBilling, {
        shouldValidate: true,
      });
    }
  }, [checked, watchBilling, getValues, setValue]);

  useEffect(() => {
    if (!checked) {
      // console.log("firing");
      setValue("shipping_address.zip_code", shippingAddress.zip_code, {
        shouldValidate: true,
      });
      setValue("shipping_address.city", shippingAddress.city, {
        shouldValidate: true,
      });
      setValue(
        "shipping_address.state_province",
        shippingAddress.state_province,
        {
          shouldValidate: true,
        }
      );
      setValue("shipping_address.address_line", shippingAddress.address_line, {
        shouldValidate: true,
      });
    }
  }, [
    checked,
    shippingAddress.zip_code,
    shippingAddress.city,
    shippingAddress.state_province,
    shippingAddress.address_line,
    setValue,
  ]);

  const handleAccountDetailsUpdate = async ({
    billing_address,
    shipping_address,
    admin,
  }: RequiredUpdatedObject) => {
    try {
      dispatch(setAccountLoading(true));
      const updatedAccountInformation = await AccountAPI.putTenantProfile({
        ...accountInformation,
        billing_address,
        shipping_address,
        admin: { ...admin, email: adminInformation.email },
        is_same_as_billing: checked,
      });

      dispatch(
        setSelectedAccountInformation({
          ...accountInformation,
          ...updatedAccountInformation,
          tenant_name: accountName,
        })
      );

      const updatedUserInformation = await AccountAPI.getUserProfile(
        logginInUserInformation.user_id
      );

      dispatch(setAccountLoading(false));
      dispatch(setUserInformation(updatedUserInformation));
      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription("Account successfully updated!"));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedAccountInformation) {
      const {
        tenant_id,
        admin,
        billing_address,
        shipping_address,
        is_same_as_billing,
      } = selectedAccountInformation;

      setAccountInformation({
        tenant_id: tenant_id,
      });
      setAdminInformation({
        phone: admin?.phone,
        given_name: admin?.given_name,
        family_name: admin?.family_name,
        email: admin?.email,
      });
      setBillingAddress({
        zip_code: billing_address?.zip_code,
        city: billing_address?.city,
        state_province: billing_address?.state_province,
        address_line: billing_address?.address_line,
      });
      setShippingAddress({
        zip_code: is_same_as_billing
          ? billing_address?.zip_code
          : shipping_address?.zip_code,
        city: is_same_as_billing
          ? billing_address?.city
          : shipping_address?.city,
        state_province: is_same_as_billing
          ? billing_address?.state_province
          : shipping_address?.state_province,
        address_line: is_same_as_billing
          ? billing_address?.address_line
          : shipping_address?.address_line,
      });
      setChecked(is_same_as_billing);
    }
  }, [selectedAccountInformation]);

  return (
    <form onSubmit={handleSubmit(handleAccountDetailsUpdate)}>
      <Grid
        container
        item
        xs={12}
        className={clsx({ [classes.viewOnly]: isPowerUser })}
      >
        {!isLoading ? (
          <>
            <Grid item xs={12}>
              <div className={classes.inputGroup} style={{ width: "50%" }}>
                <InputLabel
                  label="Account name"
                  name="tenant_name"
                  errors={errors}
                />
                <input
                  maxLength={InputLength.AccountName}
                  type="text"
                  placeholder="Enter"
                  defaultValue={selectedAccountInformation?.tenant_name || ""}
                  readOnly
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.inputGroup}>
                <InputLabel
                  label="Admin first name"
                  name="admin.given_name"
                  errors={errors}
                />
                <input
                  name="admin.given_name"
                  ref={register({
                    required: "This field is required.",
                    maxLength: {
                      value: InputLength.FirstName,
                      message: `First name must be less than ${InputLength.FirstName}`,
                    },
                  })}
                  maxLength={InputLength.FirstName}
                  type="text"
                  placeholder="Enter"
                  defaultValue={adminInformation.given_name}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.inputGroup}>
                <InputLabel
                  label="Admin last name"
                  name="admin.family_name"
                  errors={errors}
                />
                <input
                  name="admin.family_name"
                  ref={register({
                    required: "This field is required.",
                    maxLength: {
                      value: InputLength.LastName,
                      message: `Last name must be less than ${InputLength.LastName}`,
                    },
                  })}
                  maxLength={InputLength.LastName}
                  type="text"
                  placeholder="Enter"
                  defaultValue={adminInformation.family_name}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.inputGroup}>
                <InputLabel
                  label=" Admin contact number"
                  name="admin.phone"
                  errors={errors}
                />
                <input
                  name="admin.phone"
                  ref={register({
                    required: "This field is required.",
                    pattern: {
                      value: regex.validPhone,
                      message:
                        "Invalid phone number - please enter in format xxx-xxx-xxxx",
                    },
                  })}
                  type="text"
                  placeholder="Enter valid contact number"
                  defaultValue={adminInformation.phone}
                />
              </div>
            </Grid>
            <div className={classes.emailContainer}>
              <div style={{ color: Colors.Black }}>
                Admin email ID:{" "}
                {selectedAccountInformation?.admin?.email !== undefined
                  ? selectedAccountInformation?.admin?.email
                  : "N/A"}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <span style={{ margin: "0 6px 0 0" }}>
                  <img src={alertCircle} alt="Alert circle" />
                </span>
                <span style={{ color: Colors.LightGray }}>
                  If you wish to change your email address,{" "}
                </span>
                <span
                  className={classes.adminContact}
                  onClick={() =>
                    console.log(
                      "CONTACT THE ADMIN - functionality is not yet active."
                    )
                  }
                >
                  contact your admin.
                </span>
              </div>
            </div>
            <hr className={classes.lineBreak} />
            <Grid item xs={12} className={classes.header}>
              <div> Billing address:</div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.inputGroup}>
                <InputLabel
                  label="Zip code"
                  name="billing_address.zip_code"
                  errors={errors}
                />
                <input
                  name="billing_address.zip_code"
                  ref={register({
                    required: "This field is required.",
                    pattern: {
                      value: regex.validNumbersOnly,
                      message: "Invalid zip code",
                    },
                  })}
                  maxLength={InputLength.ZipCode}
                  type="text"
                  placeholder="Enter"
                  defaultValue={billingAddress.zip_code}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.inputGroup}>
                <InputLabel
                  label="City"
                  name="billing_address.city"
                  errors={errors}
                />
                <input
                  name="billing_address.city"
                  ref={register({
                    required: "This field is required.",
                    minLength: {
                      value: 2,
                      message: "Please enter more than 1 character ",
                    },
                  })}
                  maxLength={InputLength.City}
                  type="text"
                  placeholder="Enter"
                  defaultValue={billingAddress.city}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.inputGroup}>
                <InputLabel
                  label="State/Province"
                  name="billing_address.state_province"
                  errors={errors}
                />
                <input
                  name="billing_address.state_province"
                  ref={register({
                    required: "This field is required.",
                    minLength: {
                      value: 2,
                      message: "Please enter more than 1 character ",
                    },
                  })}
                  maxLength={InputLength.StateProvince}
                  type="text"
                  placeholder="Enter"
                  defaultValue={billingAddress.state_province}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.inputGroup}>
                <InputLabel
                  label="Address line"
                  name="billing_address.address_line"
                  errors={errors}
                />
                <input
                  name="billing_address.address_line"
                  ref={register({
                    required: "This field is required.",
                    minLength: {
                      value: 2,
                      message: "Please enter more than 1 character ",
                    },
                    pattern: {
                      value: regex.validAddress,
                      message:
                        "Please enter a valid address - no special charaters",
                    },
                  })}
                  maxLength={InputLength.Address}
                  type="text"
                  placeholder="Enter"
                  defaultValue={billingAddress.address_line}
                />
              </div>
            </Grid>
            <hr className={classes.lineBreak} />
            <Grid item xs={12} className={classes.header}>
              <div style={{ margin: "0 1rem 0 0" }}>Shipping address:</div>{" "}
              <label style={{ fontWeight: 400, fontSize: 14 }}>
                <StyledCheckbox checked={checked} handleChange={handleChange} />
                Same as billing address
              </label>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.inputGroup}>
                <InputLabel
                  label="Zip code"
                  name="shipping_address.zip_code"
                  errors={errors}
                />
                <input
                  name="shipping_address.zip_code"
                  ref={register({
                    required: "This field is required.",
                    pattern: {
                      value: regex.validNumbersOnly,
                      message: "Invalid zip code",
                    },
                  })}
                  maxLength={InputLength.ZipCode}
                  type="text"
                  placeholder="Enter"
                  defaultValue={shippingAddress.zip_code}
                  readOnly={checked}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.inputGroup}>
                <InputLabel
                  label="City"
                  name="shipping_address.city"
                  errors={errors}
                />
                <input
                  name="shipping_address.city"
                  ref={register({
                    required: "This field is required.",
                    minLength: {
                      value: 2,
                      message: "Please enter more than 1 character ",
                    },
                  })}
                  maxLength={InputLength.City}
                  type="text"
                  placeholder="Enter"
                  defaultValue={shippingAddress.city}
                  readOnly={checked}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.inputGroup}>
                <InputLabel
                  label="State/Province"
                  name="shipping_address.state_province"
                  errors={errors}
                />
                <input
                  name="shipping_address.state_province"
                  ref={register({
                    required: "This field is required.",
                    minLength: {
                      value: 2,
                      message: "Please enter more than 1 character ",
                    },
                  })}
                  maxLength={InputLength.StateProvince}
                  type="text"
                  placeholder="Enter"
                  defaultValue={shippingAddress.state_province}
                  readOnly={checked}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.inputGroup}>
                <InputLabel
                  label="Address line"
                  name="shipping_address.address_line"
                  errors={errors}
                />
                <input
                  name="shipping_address.address_line"
                  ref={register({
                    required: "This field is required.",
                    minLength: {
                      value: 2,
                      message: "Please enter more than 1 character ",
                    },
                    pattern: {
                      value: regex.validAddress,
                      message:
                        "Please enter a valid address - no special charaters",
                    },
                  })}
                  maxLength={InputLength.Address}
                  type="text"
                  placeholder="Enter"
                  defaultValue={shippingAddress.address_line}
                  readOnly={checked}
                />
              </div>
            </Grid>
            {isPowerUser ? null : (
              <Grid item xs={12} className={classes.buttonContainer}>
                <Button
                  type="button"
                  color="white"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </Button>
                <Button type="submit" color="defaultTheme">
                  Update account
                </Button>
              </Grid>
            )}
          </>
        ) : (
          <Modal
            open={true}
            // onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.modalOverlay}>
              <CircularProgress style={{ color: Colors.White }} />
            </div>
          </Modal>
        )}
        <AccountSuccessModal handleCancel={handleCancel} />
      </Grid>
    </form>
  );
};

export default AccountDetails;
