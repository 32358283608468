import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";

export type InputLabelProps = {
  errors?: any;
  label: string;
  name: string;
  style?: Object;
  isRequired?: boolean;
};

export const InputLabel: React.FC<InputLabelProps> = ({
  errors,
  label,
  name,
  style,
  isRequired = true,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.labelContainer} style={style || {}}>
      <label>
        {label} {isRequired && <span style={{ color: Colors.Pink }}>*</span>}
      </label>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <span className={classes.error}>{message}</span>
        )}
      />
    </div>
  );
};

export default InputLabel;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    error: {
      color: Colors.Pink,
      fontSize: 12,
    },
  })
);
