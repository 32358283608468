/* Issue fixed for github issue #204*/
import React, { useState, useEffect, useRef, useMemo } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Colors } from "common/Colors";
import { useDispatch, useSelector } from "react-redux";
import alertCircle from "../../../assets/bt-assets/alert-circle.png";
import ButtonGroupSelector from "../../../components/ButtonGroupSelector";
import ColorButton from "../../../components/ColorButton";
import UploadButton from "../../../components/UploadButton";
import Button from "components/common-buttons/Button";
import Dialog from "@material-ui/core/Dialog";
import crossImg from "../../../assets/bt-assets/Group 53.svg";
import { ColorExtractor } from "react-color-extractor";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {
  setLogoUrl,
  setAccountDisplayName as setADN,
  setTheme,
  setNewTheme
} from "features/drawer/drawerSlice";
import { Storage } from "aws-amplify";
import AccountAPI from "services/accountsAPI";
import clsx from "clsx";
import { RootState } from "app/rootReducer";
import { v4 as uuidv4 } from "uuid";
import LoadSpinner from "common/LoadSpinner";
import AddIcon from "@material-ui/icons/Add";
import { useAccessLevelAndRoles } from "utils/reactHooks";

type DisplaySettingsProps = {};

const DisplaySettings: React.FC<DisplaySettingsProps> = ({ }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [accountDisplayName, setAccountDisplayName] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [isModalOpen, setModal] = useState(false);
  const [previewHex, setPreviewHex] = useState("0E1D3F");
  const [colors, setColors] = useState<never | Array<string>>([]);
  const [selectedColor, setSelected] = useState("");
  const hexRegExp = /^[0-9a-fA-F]+$/;
  const [isApplying, setApplying] = useState(false);
  const [isResetting, setResetting] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { tenant_id } = useSelector(
    (state: RootState) => state.AccountSlice.accountInformation
  );
  const { activeTenant } = useSelector((state: RootState) => state.DrawerSlice);
  const { isBasintrakSuperAdmin } = useAccessLevelAndRoles();

  const fileHandler = (file: File) => {
    setFile(file);
  };

  const colorChangeHandler = (id: any) => {
    setSelected(id);
    dispatch(setTheme(`#${id}`));
  };

  const hexColorHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPreviewHex(e.currentTarget.value);
  };

  const addCustomColor = (color: string) => {
    //Check if color already exists and if it's a valid hex number
    if (!colors.includes(color) && hexRegExp.test(color)) {
      setColors([...colors, color]);
    }
  };

  const resetThemeFromTenantInit = async (activeColor: string) => {
    if (activeTenant === tenant_id || isBasintrakSuperAdmin) {
      dispatch(setTheme(activeColor));
    }
  };

  const resetThemeFromTenantCleanup = async (activeColor: string) => {
    dispatch(setTheme(activeColor));
  };

  const themeReset = async () => {
    const resp = await AccountAPI.getActiveColor(tenant_id);
    await resetThemeFromTenantInit(resp.active_color);
    return resp.active_color;
  };

  const themeResetClean = async () => {
    const resp = await AccountAPI.getActiveColor(activeTenant);
    await resetThemeFromTenantCleanup(resp.active_color);
    return resp.active_color;
  };

  const revertToDefaultTheme = async () => {
    let defaultColor = Colors.DefaultTheme;
    let defaultName = "";

    try {
      setApplying(true);

      if (selectedColor !== "") {
        await AccountAPI.setActiveColor(tenant_id, defaultColor);
        await resetThemeFromTenantInit(defaultColor);
      }
      const hashedColors = colors.map(color => `#${color}`);
      await AccountAPI.setTenantColors(tenant_id, hashedColors);
      setAccountDisplayName("");
      const dnResp = await AccountAPI.setDisplayName(tenant_id, defaultName);
      if (activeTenant === tenant_id) {
        dispatch(setADN(dnResp.display_name));
      }
      if (activeTenant === tenant_id) {
        dispatch(setLogoUrl(""));
      }
      setApplying(false);
    } catch (error) {
      alert(error.message);
    }
  };

  //Reset display settings
  const reset = async () => {
    setResetting(true);
    setFile(null);
    await displaySettingsDataFetch();
    setResetting(false);
  };

  //Initial data fetch and set
  const displaySettingsDataFetch = async () => {
    const themeColor = await themeReset();
    setSelected(themeColor.split("#")[1]);
    const allAttributes = await AccountAPI.getAllAttributes(tenant_id);
    const removedHash = allAttributes.available_colors.map(
      (colorWHash: string) => colorWHash.split("#")[1]
    );
    setAccountDisplayName(
      allAttributes.display_name ? allAttributes.display_name : ""
    );
    setColors(removedHash);
  };

  //Apply display settings
  const apply = async () => {
    try {
      setApplying(true);
      //Send color data and update state
      if (selectedColor != "") {
        await AccountAPI.setActiveColor(tenant_id, `#${selectedColor}`);
        await resetThemeFromTenantInit(`#${selectedColor}`);
      }
      const hashedColors = colors.map(color => `#${color}`);
      await AccountAPI.setTenantColors(tenant_id, hashedColors);
      const dnResp = await AccountAPI.setDisplayName(
        tenant_id,
        accountDisplayName
      );
      if (activeTenant === tenant_id) {
        dispatch(setADN(dnResp.display_name));
      }
      //Upload logo file if it exists
      if (file) {
        const uuid: string = uuidv4();
        const fileName = `public/logos/${tenant_id}/${uuid}.${file?.name.split(".")[1]
          }`;
        const url: any = await Storage.put(fileName, file);
        const data = await AccountAPI.setLogoAndGetAllAttributes(
          tenant_id,
          url.key
        );
        if (activeTenant === tenant_id) {
          const getImageUrl = await Storage.get(data.logo_url);
          dispatch(setLogoUrl(getImageUrl.toString()));
        }
      }
      dispatch(setNewTheme(true));
      setApplying(false);
    } catch (error) {
      alert(error.message);
    }
  };

  /* Close modal on adding color */
  useEffect(() => {
    setModal(false);
  }, [colors]);

  const _init = () =>
    (async () => {
      setLoading(true);
      await displaySettingsDataFetch();
      setLoading(false);
    })();

  const _cleanup = () =>
    (async () => {
      await themeResetClean();
    })();

  useEffect(() => {
    _init();
    return () => {
      _cleanup();
    };
  }, []);

  const getColors = (extColors: any) => {
    const hashRemoved = extColors.map((c: string) => {
      return c.split("#")[1];
    });
    //check aspect ratio
    if (imgRef.current) {
      if (imgRef.current.naturalWidth / imgRef.current.naturalHeight !== 1) {
        setFile(null);
        alert("Invalid Image! The image should have aspect ratio 1:1");
      } else {
        const filtered = hashRemoved.filter((x: string) => !colors.includes(x));
        setColors([...colors, ...filtered]);
      }
    }
  };

  const ImageElem = () => (
    <ColorExtractor getColors={getColors}>
      <img
        ref={imgRef}
        style={{
          height: 120,
          width: 120,
          border: "1px solid #DDDDDD",
          borderRadius: "50%",
          objectFit: "contain",
          justifyContent: "center",
          alignItems: "center"
        }}
        src={URL.createObjectURL(file)}
        alt="Color extractor"
      />
    </ColorExtractor>
  );

  const imageExtractor = useMemo(() => <ImageElem />, [file]);

  return isLoading ? (
    <div style={{ width: "100%" }}>
      <LoadSpinner size="90vh" />
    </div>
  ) : (
    <Grid container xs={12} className={classes.container}>
      <Dialog
        open={isModalOpen}
        onClose={() => setModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.dialogContainer}>
          <div className={classes.titleContainer}>
            <div className={classes.dialogTitle}>Add new color</div>
            <div>
              <img src={crossImg} alt="" onClick={() => setModal(false)} />
            </div>
          </div>
          <hr className={classes.hr} />
          <div
            className={clsx([
              classes.dialogInnerContainer,
              classes.pt,
              classes.pb
            ])}
          >
            <div className={classes.colorInputContainer}>
              <div className={classes.textStyle}>Hex code *</div>
              <FormControl variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-amount"
                  style={{ borderRadius: 0, width: 130 }}
                  value={previewHex}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    hexColorHandler(e);
                  }}
                  startAdornment={
                    <InputAdornment position="start">#</InputAdornment>
                  }
                  labelWidth={0}
                />
              </FormControl>
            </div>
            <div className={classes.colorPreviewContainer}>
              <div>Preview</div>
              <div
                style={{ backgroundColor: `#${previewHex}` }}
                className={clsx([classes.colorBubble])}
              ></div>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              type="button"
              color="white"
              onClick={() => {
                setModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              color="defaultTheme"
              onClick={() => {
                addCustomColor(previewHex);
              }}
            >
              Add color
            </Button>
          </div>
        </div>
      </Dialog>
      <Grid item xs={12} className={clsx([classes.pt])}>
        Account display name (in menu) *
      </Grid>
      <Grid item xs={6}>
        <input
          type="text"
          placeholder="Account Display Name"
          value={accountDisplayName}
          className={clsx({
            [classes.inputAccountDisplayName]: true,
            [classes.error]: accountDisplayName === ""
          })}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setAccountDisplayName(e.target.value);
          }}
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        direction="row"
        justify="space-between"
        className={clsx([classes.pt])}
      >
        <Grid container item xs={6} direction={"column"} spacing={2}>
          <Grid item>Display logo</Grid>
          <Grid container item direction={"row"} justify={"space-between"}>
            <Grid item>
              {!file ? (
                <div
                  style={{ display: "flex" }}
                  className={classes.uploadedImageContainer}
                >
                  <span>Upload image</span>
                </div>
              ) : (
                imageExtractor
              )}
            </Grid>
            <Grid container item xs={9} direction="column" spacing={2}>
              <UploadButton onChange={fileHandler}>
                Browse logo file
              </UploadButton>
              <Grid item container direction="row">
                <Grid item xs={1}>
                  <img src={alertCircle} />
                </Grid>
                <Grid item xs={11} className={classes.warningText}>
                  Make sure you upload a high resolution JPEG/JPG file with
                  white background. The image should have the aspect ratio 1:1.
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={6} direction={"column"} spacing={2}>
          <Grid item className={clsx([classes.pt])}>
            Color settings
          </Grid>
          <Grid container item direction={"row"} spacing={1}>
            <Grid item>
              <img src={alertCircle} alt="Alert circle" />
            </Grid>
            <Grid item className={classes.warningText}>
              Colors are extracted from the logo file, but new colors can also
              be added.
            </Grid>
          </Grid>
          <Grid container item direction="row">
            <ButtonGroupSelector
              onChange={colorChangeHandler}
              activeId={selectedColor}
            >
              {colors.map(color => (
                <ColorButton
                  color={`#${color}`}
                  id={color}
                  spacing={1}
                  key={color}
                />
              ))}
              <ColorButton
                color={Colors.White}
                id={"add-button"}
                key={"add-button"}
                spacing={1}
              >
                <span
                  style={{
                    color: "lightgrey",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  onClick={() => setModal(true)}
                >
                  <AddIcon />
                </span>
              </ColorButton>
            </ButtonGroupSelector>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justify="flex-end">
        <Button type="button" color="white" onClick={revertToDefaultTheme}>
          Revert to default theme
        </Button>
        <Button
          type="button"
          color="white"
          disabled={isResetting}
          onClick={reset}
        >
          {isResetting ? "Resetting" : "Reset to current theme"}
        </Button>
        <Button
          type="button"
          color="defaultTheme"
          disabled={isApplying || accountDisplayName === ""}
          onClick={apply}
        >
          {isApplying ? "Applying" : "Apply changes"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default DisplaySettings;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    inputAccountDisplayName: {
      height: 30,
      width: "100%",
      border: "1px solid #DDDDDD",
      "&:focus": {
        outline: "none"
      }
    },
    error: {
      border: "1px solid #FF0000"
    },
    uploadedImageContainer: {
      height: 120,
      width: 120,
      border: "1px solid #DDDDDD",
      borderRadius: "50%",
      objectFit: "contain",
      justifyContent: "center",
      alignItems: "center"
    },
    uploadButton: {
      border: "1px solid #253746", //updated color from #6D1919 to #253746 for issue #204
      borderRadius: 0,
      width: 129,
      height: 30,
      textTransform: "none",
      fontSize: 14
    },
    warningText: {
      fontFamily: "Hind",
      color: "#A6A6A6",
      fontWeight: "normal",
      fontSize: 14
    },
    pt: {
      paddingTop: 24
    },
    pb: {
      paddingBottom: 24
    },
    pr: {
      paddingRight: 24
    },
    mr: {
      marginRight: 24
    },
    hr: {
      height: 1,
      width: "100%",
      backgroundColor: "#DDDDDD",
      opacity: 0.5
    },
    button: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: Colors.White,

      cursor: "pointer",
      padding: "0 1rem",
      outline: "none",
      height: 30
    },
    isActive: {
      backgroundColor: theme.palette.primary.light,
      color: Colors.White
    },
    dialogTitle: {
      display: "flex",
      fontFamily: "Rajdhani",
      color: Colors.MainColor, //updated color from #6D1919 to #253746 for issue #204
      fontWeight: "normal",
      fontSize: 24,
      padding: 15
    },
    dialogInnerContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-evenly",
      alignItems: "center"
    },
    dialogContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: 267,
      padding: "0 2em",
      borderRadius: 0,
      backgroundColor: Colors.White
    },
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingLeft: 5,
      paddingRight: 5,
      alignItems: "center"
    },
    textStyle: {
      fontFamily: "Hind",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      color: "#000000"
    },
    hexInputContainer: {
      border: "1px solid #DDDDDD"
    },
    colorInputContainer: {
      display: "flex",
      flexDirection: "column"
    },
    colorPreviewContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%"
    },
    colorBubble: {
      height: 40,
      width: 40,
      borderRadius: "50%"
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "100%"
    }
  })
);
