import React, { useState } from 'react';
import { Modal, TextField, Button, MenuItem, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { Exception } from './types';
import { dashboardAPI } from 'services/dashboardAPI';
import { RootState } from "app/rootReducer";
import {
  setSuccessModalOpen,
  setSuccessModalDescription
} from "components/success-modal/successModalSlice";

interface CreateExceptionModalProps {
  open: boolean;
  handleClose: () => void;
  rootCauseLists: { name: string, value: number }[];
  refreshExceptions: () => void; // Function to refresh the Kanban board after adding a new card
}

const CreateExceptionModal: React.FC<CreateExceptionModalProps> = ({
  open,
  handleClose,
  rootCauseLists,
  refreshExceptions,
}) => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [rootCause, setRootCause] = useState(0);
  const [priority, setPriority] = useState(0); // State for priority

  const dispatch = useDispatch();

  const { accountId } = useSelector(
    (state: RootState) => state.AppSlice.selectedAccount
  );
  const { userInformation } = useSelector(
    (state: RootState) => state.AppSlice
  );

  const handleSubmit = async () => {
    try {
      const newException: Partial<Exception> = {
        name,
        state_latest: 0,
        root_cause: rootCause,
        priority,
        assets: [], // add other required fields based on your needs
        exception_id: '', // placeholder, replace with actual data
        source: '', // placeholder, replace with actual data
        time_latest: Date.now(), // placeholder, replace with actual data
      };
      // API call to create a new exception
      const asset_list: string[] = ["asset_one", "asset_two"];
      const userName = userInformation?.given_name + " " + userInformation.family_name;
      const response = await dashboardAPI.createException(
        accountId,
        name,
        priority,
        userName,
        asset_list);
      // Refresh the exceptions list
      refreshExceptions();

      // Close the modal
      handleClose();
      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription("Comment added successfully!"));
    } catch (error) {
      console.error('Failed to create a new exception', error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <div className={classes.paper}>
        <h2>Create New Exception</h2>
        <form noValidate autoComplete="off" className={classes.form}>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            required
            className={classes.input}
          />
          <TextField
            select
            label="Root Cause"
            value={rootCause}
            onChange={(e) => setRootCause(Number(e.target.value))}
            fullWidth
            className={classes.input}
          >
            {rootCauseLists.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Priority"
            value={priority}
            onChange={(e) => setPriority(parseInt(e.target.value))}
            fullWidth
            className={classes.input}
          >
            <MenuItem value={0}>Warning</MenuItem>
            <MenuItem value={1}>Critical</MenuItem>
            <MenuItem value={3}>Note</MenuItem>
          </TextField>
          <div className={classes.actions}>
            <Button onClick={handleClose} variant="outlined" color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Create
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateExceptionModal;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '400px',
    height: 'auto',
    [theme.breakpoints.up(1080)]: {
      width: '440px', // Increase width by 40px when window is wider than 1080px
      padding: theme.spacing(3, 5, 4), // Increase padding to maintain proportion
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
