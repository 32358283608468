import React from "react";
import { useStyles } from "./styles";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import ReactHtmlParser from "react-html-parser";
import { useDispatch } from "react-redux";
import {
  setIsGroupActive,
  setIsAssetActive,
  setGroupDelete,
  setAssetDelete,
} from "components/group-modal/groupModalSlice";
import { setGroupName } from "features/assets-page/assetsDashboardSlice";
import AssetsAPI from "services/assetsAPI";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
  setUnsuccessModalOpen,
  setUnsuccessModalDescription
} from "components/success-modal/successModalSlice";

type DeviceModalProps = {
  handleClose: Function;
  open: boolean;
  groupTitle: string;
  groupDescription: string;
  groupButtonName: string;
  selectedGroupId: string;
  selectedGroupName: string;
  selectedGroupSharing: boolean;
};

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const GroupModalCard: React.FC<DeviceModalProps> = ({
  handleClose,
  open,
  groupTitle,
  groupDescription,
  groupButtonName,
  selectedGroupId,
  selectedGroupName,
  selectedGroupSharing,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const cancelAdd = () => {
    handleClose();
  };

  const handleAction = async () => {
    switch (groupTitle) {
      case "Delete group":
        try {
          await AssetsAPI.deleteAssetGroup(selectedGroupId);
          dispatch(setGroupDelete(true));
          dispatch(setSuccessModalDescription("Group successfully deleted!"));
          dispatch(setSuccessModalOpen(true));
        } catch (error) {
          if (error instanceof Error) {
            if ((error as any).response) {
              dispatch(setUnsuccessModalDescription(`${(error as any).response.data}`));
              dispatch(setUnsuccessModalOpen(true));
            } else {
              alert(`Error: ${error.message}`);
            }
          } else {
            alert('An unknown error occurred');
          }
        }
        break;
      case "Deactivate group":
        try {
          const groupActive = await AssetsAPI.deactivateAssetGroup(
            selectedGroupId
          );
          dispatch(
            setSuccessModalDescription("Group successfully deactivated!")
          );
          dispatch(setSuccessModalOpen(true));
          dispatch(setIsGroupActive(groupActive));
        } catch (error) {
          console.log(error);
          alert(error);
        }
        break;
      case "Activate group":
        try {
          const groupActive = await AssetsAPI.activateAssetGroup(
            selectedGroupId
          );
          dispatch(setSuccessModalDescription("Group successfully activated!"));
          dispatch(setSuccessModalOpen(true));
          dispatch(setIsGroupActive(groupActive));
        } catch (error) {
          console.log(error);
          alert(error);
        }
        break;
      case "Save group updates":
        try {
          // ! this will eventually have shared customers, it is not yet active
          // ! UPDATE THE TREE HERE by dispatching the new response
          const groupName = await AssetsAPI.updateAssetGroup(
            selectedGroupId,
            selectedGroupName,
            selectedGroupSharing
          );
          dispatch(setGroupName(groupName));
          dispatch(setSuccessModalDescription("Group successfully updated!"));
          dispatch(setSuccessModalOpen(true));
        } catch (error) {
          console.log(error);
          alert(error);
        }
        break;
      // ! ASSETS PORTION - NEED TO SEPERATE THIS LOGIC INTO ITS OWN COMPONENT. - Well aware that it can be seperated -
      case "Activate asset":
        try {
          const assetActive = await AssetsAPI.activateAsset(selectedGroupId);
          dispatch(setSuccessModalDescription("Asset successfully activated!"));
          dispatch(setSuccessModalOpen(true));
          dispatch(setIsAssetActive(assetActive));
        } catch (error) {
          console.log(error);
          alert(error);
        }
        break;
      case "Deactivate asset":
        try {
          const assetActive = await AssetsAPI.deactivateAsset(selectedGroupId);
          dispatch(
            setSuccessModalDescription("Asset successfully deactivated!")
          );
          dispatch(setSuccessModalOpen(true));
          dispatch(setIsAssetActive(assetActive));
        } catch (error) {
          console.log(error);
          alert(error);
        }
        break;
      case "Delete Asset":
        try {
          const response = await AssetsAPI.deleteAsset(selectedGroupId);
          dispatch(setAssetDelete(true));
          dispatch(setSuccessModalDescription("Asset successfully deleted!"));
          dispatch(setSuccessModalOpen(true));
        } catch (error) {
          if (error instanceof Error) {
            if ((error as any).response) {
              dispatch(setUnsuccessModalDescription(`${(error as any).response.data}`));
              dispatch(setUnsuccessModalOpen(true));
            } else {
              alert(`Error: ${error.message}`);
            }
          } else {
            alert('An unknown error occurred');
          }
        }
        break;
      default:
        break;
    }
    handleClose();
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={styles.modalOverlay}>
          <div className={styles.modalBody}>
            <div className={styles.header}>
              <p className={styles.headerText}>{groupTitle}</p>
              <CloseIcon
                className={styles.icon}
                onClick={() => handleClose()}
              />
            </div>
            <div style={{ margin: "2rem 0 0 0" }}>
              <div className={styles.deleteDescription}>
                {ReactHtmlParser(groupDescription)}
              </div>
            </div>
            <div className={styles.actionItems}>
              <button className={styles.cancelButton} onClick={cancelAdd}>
                Cancel
              </button>
              <button className={styles.saveButton} onClick={handleAction}>
                {groupButtonName}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GroupModalCard;
