/* Issue fixed for github issue #24,issue #213  */
import React, { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentBatchId } from "features/drawer/drawerSlice";
// import { RootState } from "app/rootReducer";
import LoadSpinner from "common/LoadSpinner";
import { batchAPI } from "services/batchAPI";
import { SuccessModal } from "components/success-modal/SuccessModal";
import { format, fromUnixTime } from "date-fns";
import Button from "components/common-buttons/Button";
import { useForm } from "react-hook-form";
import { RequestedBatchProps, PostBatchProps } from "./types";
import { InputLabel } from "components/input-label/InputLabel";
import { LinearIndeterminate } from "common/LinearLoading";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useStyles } from "./styles";
import { Colors } from "common/Colors";
import UnsuccessModal from "components/success-modal/UnsuccessfullModal"; //imported for issue #213

export type BatchProps = {};
export const Batch: React.FC<BatchProps> = ({ }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [batchLoading, setBatchLoading] = useState(false);
  const { register, handleSubmit, errors, setValue } = useForm<
    PostBatchProps
  >();
  // Added defualt selection device for deivce type drop down. Github issue 92
  const [selectedDeviceType, setSelectedDeviceType] = useState<{
    label: string;
    value: number;
  }>({
    label: "4S",
    value: 0
  });
  //Added the device type dropdown options. Github issue 92
  const [sourceSelections, setSourceSelections] = useState<
    { label: string; value: number }[]
  >([
    {
      label: "4S",
      value: 0
    },
    {
      label: "4N",
      value: 1
    },
    {
      label: "4M",
      value: 2
    },
    {
      label: "4L",
      value: 3
    }
  ]);
  const [requestedBatches, setRequestedBatches] = useState<
    RequestedBatchProps[]
  >([]);

  const handleCreateBatch = async ({ count, batch_code }: PostBatchProps) => {
    try {
      setBatchLoading(true);
      //Sending device type in the api call. Github issue 92
      await batchAPI?.createBatchDeviceIntoRegistry(
        count,
        batch_code,
        selectedDeviceType["value"]
      );
      const requestedBatches = await batchAPI?.retrieveBatchIds();
      setRequestedBatches(requestedBatches);
      setValue("batch_code", "");
      setValue("count", 1);
      setBatchLoading(false);
    } catch (error) {
      console.log(error);
      setBatchLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const requestedBatches = await batchAPI?.retrieveBatchIds();
        setRequestedBatches(requestedBatches);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, []);

  return loading ? (
    <LoadSpinner size="90vh" />
  ) : (
    <>
      <Grid container>
        <Grid item xs={12} className={classes.root1}>
          <form onSubmit={handleSubmit(handleCreateBatch)}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                margin: "1rem 0",
                gap: "1rem"
              }}
            >
              <div className={classes.inputGroup} style={{ width: 150 }}>
                <InputLabel
                  label="Batch code"
                  name="batch_code"
                  errors={errors}
                />
                <input
                  name="batch_code"
                  ref={register({
                    required: "Required.",
                    pattern: {
                      value: /^[a-zA-Z]+$/,
                      message: "Letters only."
                    }
                  })}
                  maxLength={3}
                  type="text"
                  placeholder="Enter"
                />
              </div>
              <div className={classes.inputGroup}>
                <InputLabel label="Device count" name="count" errors={errors} />
                <input
                  name="count"
                  ref={register({
                    required: "Required.",
                    pattern: {
                      value: /^\d*[1-9]\d*$/,
                      message: "No negative numbers."
                    },
                    validate: (value: number) =>
                      value <= 1000 || "Max limit: 1000"
                  })}
                  maxLength={4}
                  defaultValue={1}
                  type="number"
                  placeholder="Enter"
                />
              </div>
              {/* Added device type dropdown. Github issue 92 */}
              <div className={classes.inputGroup}>
                <InputLabel
                  label="Device Type"
                  name="device_type"
                  errors={errors}
                />
                <Select
                  styles={reactSelectStyles}
                  defaultValue={selectedDeviceType}
                  onChange={setSelectedDeviceType}
                  options={sourceSelections}
                  maxMenuHeight={220}
                  isSearchable
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: 20
                }}
              >
                <Button
                  type="submit"
                  color="defaultTheme"
                  disabled={batchLoading}
                >
                  Create
                </Button>
              </div>
            </div>
          </form>
        </Grid>
        {!requestedBatches.length ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
              width: "100%"
            }}
          >
            No batches to display
          </div>
        ) : (
          <Grid item xs={12} className={classes.root}>
            {batchLoading && <LinearIndeterminate />}
            <TableContainer style={{ maxHeight: 800 }}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.columnText}>
                      Date created
                    </TableCell>
                    {/* Added Device type in table. Github issue 92 */}
                    <TableCell className={classes.columnText}>
                      Device Type
                    </TableCell>
                    <TableCell className={classes.columnText}>
                      Batch code
                    </TableCell>
                    <TableCell className={classes.columnText}>
                      Device count
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requestedBatches.map(
                    (batch: RequestedBatchProps, index: number) => {
                      return (
                        <TableRow
                          key={batch.batch_id + index}
                          style={{
                            cursor: "pointer",
                            position: "relative"
                          }}
                          onClick={() => {
                            history.push(`/batch/${batch.batch_id}`);
                            dispatch(setCurrentBatchId(batch.batch_id));
                          }}
                        >
                          <TableCell className={classes.tableCell}>
                            {format(
                              fromUnixTime(Number(batch.created)),
                              // changed format from "MMMM dd,yyyy" to MM/dd/yyyy for web view issue #24
                              "MM/dd/yyyy | h:mm a"
                            )}
                          </TableCell>
                          {/* Added Device type in table. Github issue 92 */}
                          <TableCell className={classes.tableCell}>
                            {batch.device_type === 3
                              ? "4L"
                              : batch.device_type === 2
                                ? "4M"
                                : batch.device_type === 1
                                  ? "4N"
                                  : "4S"}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {batch.batch_id}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {batch.device_count}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
      <SuccessModal />
      {/* imported for issue #213 */}
      <UnsuccessModal />
    </>
  );
};

export default Batch;
/* Added styling for the device type dropdown */
const reactSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer",
    zIndex: 1
  })
};
