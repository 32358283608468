/* Issue fixed for github issue #17, issue #24, issue #46, issue #57, issue #90,issue #241*/
import React, { useState, useEffect } from "react";
import { Colors } from "common/Colors";
import { Typography, Paper, Tabs, Tab, Divider } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import chartIcon from "assets/bt-assets/histogram 1.svg";
import DateRangeSelector from "components/date-range-selector/DateRangeSelector";
import Button from "components/common-buttons/Button";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { LinearIndeterminate } from "common/LinearLoading";
import Xsquare from "assets/bt-assets/x-square.svg";
import {
  SingleAssetProp,
  ComplexAssetProp,
  AssetsProp,
  AssetParamProp
} from "features/asset-drilldown/Types";
import { devicesAPI } from "services/devicesAPI";
import { DataFrame } from "dataframe-js";
import SyncLineChart from "features/devices-page/device-details/device-details-tab/SyncLineChart";
import { format, fromUnixTime } from "date-fns";
import { BackupOutlined } from "@material-ui/icons";

interface IProps {
  expandPanel: (expand: boolean) => void;
  expanded: boolean;
  assetDisplay?: ComplexAssetProp;
  singleAsset?: SingleAssetProp;
}
interface SampleTextProps {
  listing: number;
  removeListing: (index: number) => void;
}

const TrendingGraphExpansionPanel: React.FC<IProps> = ({
  expandPanel,
  expanded,
  assetDisplay,
  singleAsset
}: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  // const [dropdownSelection, setDropdownSelection] = useState("critical");
  const [assetParametersObject, setAssetParametersObject] = useState<
    {
      asset_id: string;
      asset_name: string;
      asset_parameters: AssetParamProp[];
    }[]
  >();
  const [assetNames, setAssetNames] = useState<
    {
      asset_id: string;
      asset_name: string;
    }[]
  >();
  const [assetParameters, setAssetParameters] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<number | null>();
  const [endDate, setEndDate] = useState<number | null>();
  const [graphLoading, setgraphLoading] = useState(false);
  const [deviceParamterData, setDeviceParamterData] = useState<
    Array<{
      timestamp: string;
      [key: string]: number | string;
    }>
  >([]);
  //Added state variable for setting the min and max values for Y axis. Github issue #90
  const [deviceMinMaxValues, setdeviceMinMaxValues] = useState({
    minVal: 0,
    maxVal: 0
  });

  const {
    register,
    handleSubmit,
    setValue,
    errors,
    control,
    reset
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items"
  });

  // const handleChange = (
  //   event: React.ChangeEvent<{ name?: string; value: unknown }>
  // ) => {
  //   setDropdownSelection(event.target.value as string);
  // };

  const handleGenerateGraph = async (selectedParameters: any) => {
    try {
      // ! count selected Parameters array
      setgraphLoading(true);
      // Added the parsedIndex and tempArr and updated the parsedJsonParameter for updating the logic for sending the array for index and showing the graph in single api call. Github issue #17
      let parsedIndex: number[] = [];
      let parsedJsonParameter: any = null;
      // Added for adding device ids of respective selected parameters for issue #241
      const deviceIds: any[] = [];
      for (let i = 0; i < selectedParameters.items.length; i++) {
        parsedJsonParameter = JSON.parse(
          selectedParameters.items[i].asset_parameters
        );
        deviceIds.push(parsedJsonParameter.device_id);
        //Adding the indexes of the selected parameters in an array. Github issue #17
        parsedIndex.push(parsedJsonParameter.parameter_number - 1);
      }
      //Api call for generating the graph. Github issue #17
      //New api call for generating the graphs with accurate data for issue #241
      const response = await devicesAPI.generateAssetGraph(
        deviceIds,
        startDate as number,
        endDate as number,
        parsedIndex
      );
      /* Removed the logic for converting the array in proper format. Got the response from backend in the format required.
     The logic has been moved to Backend. Github issue #46 */
      if (response !== null) {
        setDeviceParamterData(response.trendVal);
        setdeviceMinMaxValues(response.limits); //Setting the min and max values from the api. Github issue #90
        setgraphLoading(false);
      } else {
        setDeviceParamterData([]);
        setdeviceMinMaxValues({ minVal: 0, maxVal: 0 });
        alert("No data found with the selection.");
        setgraphLoading(false);
      }
    } catch (error) {
      setgraphLoading(false);
      alert(error);
    }
  };
  useEffect(() => {
    const assets = assetDisplay?.assets;
    const asset = singleAsset?.asset;
    if (assets) {
      const assetsParameters = assets.map(
        ({ asset_id, asset_name, asset_parameters }: AssetsProp) => {
          return {
            asset_id,
            asset_name,
            asset_parameters
          };
        }
      );
      const _assetNames = assets.map(({ asset_id, asset_name }: AssetsProp) => {
        return {
          asset_id,
          asset_name
        };
      });

      setAssetNames(_assetNames);
      setAssetParametersObject(assetsParameters);

      setTimeout(() => {
        reset({
          items: assetsParameters.slice(0, 2)
        });
      }, 1.5);
    }

    if (asset) {
      const assetsParameters = [asset].map(
        ({ asset_id, asset_name, asset_parameters }: any) => {
          return {
            asset_id,
            asset_name,
            asset_parameters
          };
        }
      );
      const _assetNames = [asset].map(({ asset_id, asset_name }: any) => {
        return {
          asset_id,
          asset_name
        };
      });

      setAssetNames(_assetNames);
      setAssetParametersObject(assetsParameters);

      setTimeout(() => {
        reset({
          items: assetsParameters.slice(0, 2)
        });
      }, 1.5);
    }
  }, [assetDisplay, singleAsset, reset]);

  const handleAssetParameters = (
    e: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    if (e.target.value) {
      const prevParams = [...assetParameters];
      const parameters =
        assetParametersObject &&
        assetParametersObject.filter(
          (object: {
            asset_id: string;
            asset_name: string;
            asset_parameters: AssetParamProp[];
          }) => object.asset_id === e.target.value
        )[0].asset_parameters;

      prevParams[index] = parameters;

      setAssetParameters(prevParams);
    }
  };

  // useEffect(() => {
  //   console.log(fields);
  // }, [remove, fields]);

  return (
    <div style={{ width: "100%", padding: 0 }}>
      <Paper
        elevation={0}
        className={
          expanded ? classes.paperStylesExpanded : classes.paperStylesCollapsed
        }
        onClick={() => {
          expandPanel(!expanded);
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            className={
              expanded
                ? classes.warningIconExpanded
                : classes.warningIconCollapsed
            }
          >
            <svg
              style={{ height: "1.3rem" }}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.44 17.9123C18.7017 16.1861 18.1823 13.8138 17.6799 11.5195C17.1906 9.2847 16.6844 6.97375 15.9665 5.2457C15.5561 4.25754 15.1049 3.52054 14.5874 2.99258C13.9418 2.33401 13.1896 2 12.3516 2C11.5177 2 10.7706 2.33066 10.1313 2.98297C9.61871 3.50589 9.17361 4.23587 8.77048 5.21442C8.06628 6.92432 7.59647 9.1138 7.09918 11.4321C6.60342 13.7436 6.09073 16.1338 5.34442 17.8775C4.72736 19.3196 4.10464 19.9602 3.5625 20.237V2H2V22H22V20.4375C21.3646 20.4375 20.4081 20.1758 19.44 17.9123ZM8.62704 11.7597C9.10907 9.51221 9.56454 7.3894 10.2153 5.80951C11.045 3.7955 11.8318 3.5625 12.3516 3.5625C12.8768 3.5625 13.6736 3.79916 14.5237 5.84521C15.188 7.44434 15.6789 9.686 16.1536 11.8538C16.6715 14.2189 17.2071 16.6646 18.0034 18.5266C18.3348 19.3016 18.6919 19.9323 19.0857 20.4375H5.6705C6.07639 19.9243 6.44275 19.2824 6.78104 18.4922C7.5867 16.6095 8.11557 14.144 8.62704 11.7597Z"
                fill={expanded ? "white" : theme.palette.primary.light}
                stroke={expanded ? "white" : theme.palette.primary.light}
                stroke-width="0.5"
              />
            </svg>
          </div>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h6"
              className={
                expanded ? classes.headerExpanded : classes.headerCollapsed
              }
            >
              Custom trending graphs
            </Typography>
          </span>
        </div>

        {expanded ? (
          <RemoveCircleOutlineOutlinedIcon className={classes.expandIconOpen} />
        ) : (
          <ControlPointIcon className={classes.expandIconCollapsed} />
        )}
      </Paper>
      {expanded && (
        <div>
          <form onSubmit={handleSubmit(handleGenerateGraph)}>
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                margin: "1rem 0"
              }}
            >
              {/* //! Select option is commented out for now */}
              {/* <span style={{ marginTop: "1em" }}>
              <span className={classes.sortByText}>Graph type:</span>
              <select
                style={{ width: "auto" }}
                className={classes.sortSelect}
                onChange={handleChange}
                value={dropdownSelection}
              >
                <option value={"critical"}>Line</option>
                <option value={"connectivity"}>Line 2</option>
                <option value={"highestAssets"}>Line 3</option>
                <option value={"lowestAssets"}>Line 4</option>
              </select>
            </span> */}
              <DateRangeSelector
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </span>
            <Divider />
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start"
              }}
            >
              <div className={classes.tableColumn}>Asset</div>
              <div className={classes.tableColumn}>Parameter</div>
            </div>
            <Divider />
            <div style={{ height: 165 }}>
              {fields.map(({ asset_id }, fieldsIndex) => {
                return (
                  <div
                    key={fieldsIndex}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row"
                    }}
                  >
                    <div className={classes.tableColumn}>
                      <select
                        style={{ width: "75%", margin: 0 }}
                        name={`items[${fieldsIndex}].asset_name`}
                        ref={register({
                          required: true
                        })}
                        onChange={e => handleAssetParameters(e, fieldsIndex)}
                        defaultValue={""}
                      >
                        <option value={""}>Select an asset...</option>
                        {assetNames &&
                          assetNames.map(
                            (
                              item: {
                                asset_id: string;
                                asset_name: string;
                              },
                              index: number
                            ) => {
                              return (
                                <option
                                  key={item.asset_id + index}
                                  value={item.asset_id}
                                >
                                  {item.asset_name}
                                </option>
                              );
                            }
                          )}
                      </select>
                    </div>
                    <div className={classes.tableColumn}>
                      <select
                        style={{ width: "75%", margin: 0 }}
                        name={`items[${fieldsIndex}].asset_parameters`}
                        ref={register({
                          required: true
                        })}
                        defaultValue={""}
                      >
                        {(assetParameters[fieldsIndex] &&
                          assetParameters[fieldsIndex].map(
                            (parameter: AssetParamProp, index: number) => {
                              return (
                                <option
                                  key={parameter.device_id + index}
                                  value={`{"device_id": "${parameter.device_id}", "parameter_name": "${parameter.parameter_name}", "parameter_number": ${parameter.parameter_number}}`}
                                >
                                  {parameter.parameter_name}
                                </option>
                              );
                            }
                          )) ||
                          []}
                      </select>
                      {fieldsIndex ? (
                        <img
                          src={Xsquare}
                          alt="Delete Account"
                          style={{
                            color: Colors.BasinRed,
                            cursor: "pointer",
                            marginLeft: "1rem"
                          }}
                          onClick={() => remove(fieldsIndex)}
                        />
                      ) : null}
                    </div>{" "}
                  </div>
                );
              })}
            </div>
            <div className={classes.buttonContainer}>
              <Button
                type="button"
                color="defaultTheme"
                onClick={() => append({})}
                style={
                  fields.length >= 4
                    ? { opacity: 0.5, pointerEvents: "none" }
                    : {}
                }
              >
                {/* Updated the text add asset to add parameter for the text change issue. Github issue #57 */}
                + Add parameter
              </Button>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  type="button"
                  color="white"
                  onClick={() => {
                    setDeviceParamterData([]);
                    setdeviceMinMaxValues({ minVal: 0, maxVal: 0 });
                    remove();
                  }}
                >
                  Clear
                </Button>
                <Button type="submit" color="defaultTheme">
                  {/* Updated the text Apply filters to Apply for the text change issue. Github issue #57 */}
                  Apply
                </Button>
              </div>
            </div>
            <div style={{ margin: "1rem 0" }}>
              {deviceParamterData.length === 0 ? ( // ! this is an exclamation
                <>
                  <div>{graphLoading && <LinearIndeterminate />}</div>
                  <div className={classes.deviceParameterChartContainer}>
                    {/* Updated the text 'Press "Apply filters" to display data' to 'Press "Apply" to display data.' for the text change issue. Github issue #57 */}
                    Press "Apply" to display data.
                  </div>
                </>
              ) : (
                <>
                  <div>{graphLoading && <LinearIndeterminate />}</div>
                  <SyncLineChart
                    chartHeight={320}
                    graphData={deviceParamterData}
                    minMaxData={deviceMinMaxValues} //Added the props to get the min and max values. Github issue #90
                  />
                </>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
export default TrendingGraphExpansionPanel;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    tabsRoot: {
      display: "flex",
      float: "right",
      alignContent: "flex-end",
      alignItems: "center"
    },
    tabRoot: {
      fontSize: "1em",
      width: "3em",
      minHeight: "2em",
      height: "2em",
      color: theme.palette.primary.light,
      border: `solid 1px ${theme.palette.primary.light}`,
      minWidth: "3em",
      alignSelf: "center"
    },
    activeTab: {
      backgroundColor: theme.palette.primary.light,
      background: "#FFF",
      textDecoration: "none",
      color: "#FFF"
    },
    deviceParameterChartContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: 450,
      backgroundColor: Colors.Gray,
      color: Colors.White,
      fontSize: 16
    },
    tabDates: { alignSelf: "center", marginRight: "1em" },
    sortByText: {
      marginBottom: "1em",
      fontWeight: "bold",
      height: "2.5em",
      padding: ".5rem .5rem .5rem .5rem"
    },
    sortSelect: {
      marginBottom: "1em",
      height: "2.5em",
      border: `1px solid ${Colors.LightGray}`,
      padding: ".5rem .5rem .5rem 1rem",
      color: theme.palette.primary.light,
      fontWeight: "bold",
      "& option": {
        color: theme.palette.primary.light,
        fontWeight: "bold"
      }
    },
    trendingGraphSelect: {
      marginBottom: "1em",
      height: "2.5em",
      border: `1px solid ${Colors.LightGray}`,
      padding: ".5rem .5rem .5rem 1rem",
      fontWeight: "bold",
      "& option": {
        fontWeight: "bold"
      }
    },
    headerCollapsed: {
      color: theme.palette.primary.light,
      padding: 0,
      margin: 0
    },
    headerExpanded: { color: "FFF", padding: 0, margin: 0 },
    paperStylesCollapsed: {
      marginTop: "1em",
      height: "2.5em",
      width: "100%",
      border: "solid 1px lightgrey",
      borderLeft: `solid 5px ${theme.palette.primary.light}`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "1em",
      paddingLeft: "1em",
      cursor: "pointer"
    },
    paperStylesExpanded: {
      height: "2.5em",
      marginTop: "1em",
      width: "100%",
      border: "solid 1px lightgrey",
      borderLeft: `solid 5px ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.primary.light,
      color: "white",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "1em",
      paddingLeft: "1em",
      cursor: "pointer"
    },
    expandIconCollapsed: {
      color: theme.palette.primary.light,
      "&:hover": { cursor: "pointer" }
    },
    expandIconOpen: {
      color: "white",
      padding: 0,
      borderRadius: "50%",
      "&:hover": { cursor: "pointer" }
    },
    warningIconCollapsed: {
      alignSelf: "center",
      marginRight: ".5em",
      marginTop: ".2em",
      color: theme.palette.primary.light
    },
    warningIconExpanded: {
      alignSelf: "center",
      marginRight: ".5em",
      marginTop: ".2em",
      color: "FFF"
    },
    table: {
      minWidth: 450
    },
    tableColumn: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      width: "33%",
      margin: ".5em 0 .5em 0",

      //adding media query to style the asset and parameters column for responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        width: "60%",
        marginLeft: "15px"
      }
    },
    cancelIcon: {
      alignSelf: "center",
      marginLeft: ".5em",
      color: theme.palette.primary.light,
      "&:hover": {
        cursor: "pointer"
      }
    },
    buttonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      marginTop: "1em"
    },
    exportButton: {
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: "#fff",
      width: 160,
      color: theme.palette.primary.light,
      padding: ".5rem 1rem",
      marginRight: "1rem",
      cursor: "pointer"
    },
    addButton: {
      color: "white",
      backgroundColor: theme.palette.primary.light,
      width: 160,
      border: "none",
      padding: ".5rem 1rem",
      cursor: "pointer"
    }
  })
);
