/* Issue fixed for github issue #5, issue #24, issue #18, issue #59,issue #72 */
import React, { useState, useEffect } from "react";
import { Colors } from "common/Colors";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import { getUnixTime, subDays, fromUnixTime, format } from "date-fns";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import DateRangeSelector from "components/date-range-selector/DateRangeSelector";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import { tableStyle } from "features/devices-page/device-details/device-details-tab/tableStyles";
import Button from "components/common-buttons/Button";
import { dashboardAPI } from "services/dashboardAPI";
import { AssetEventProp } from "./types";
interface IProps {
  expandPanel: (expand: boolean) => void;
  expanded: boolean;
  assetId: string;
}

const EventsExpansionPanel: React.FC<IProps> = ({
  expandPanel,
  expanded,
  assetId
}) => {
  const classes = useStyles();
  const table = tableStyle();
  const [startDate, setStartDate] = useState<number | null>(
    getUnixTime(subDays(new Date(), 1))
  );
  const [endDate, setEndDate] = useState<number | null>(
    getUnixTime(new Date())
  );
  const [loading, setLoading] = useState(false);
  const [assetEvents, setAssetEvents] = useState<AssetEventProp[]>([]);

  // type UnionProp = AssetEventProp | AssetEventControlProp;

  const displayPriority = (alarmAction: number) => {
    switch (true) {
      //Have added cases to show text according to the alarmAction in logged events accordion
      case alarmAction === 0:
        return (
          <span style={{ color: Colors.Green, fontWeight: 600 }}>
            No action
          </span>
        );
      case alarmAction === 1:
        return (
          <span style={{ color: Colors.Green, fontWeight: 600 }}>
            enablement #1
          </span>
        );
      case alarmAction === 2:
        return (
          <span style={{ color: Colors.Green, fontWeight: 600 }}>
            enablement #2
          </span>
        );
      case alarmAction === 3:
        return (
          <span style={{ color: Colors.Green, fontWeight: 600 }}>
            Display only
          </span>
        );
      case alarmAction === 4:
        return (
          <span style={{ color: Colors.Green, fontWeight: 600 }}>Log</span>
        );
      case alarmAction === 5:
        return (
          <span style={{ color: Colors.Green, fontWeight: 600 }}>Notify</span>
        );
      case alarmAction === 6:
        return (
          <span style={{ color: Colors.Green, fontWeight: 600 }}>
            Enablement #1, Notify
          </span>
        );
      case alarmAction === 7:
        return (
          <span style={{ color: Colors.Green, fontWeight: 600 }}>
            Enablement #2, Notify
          </span>
        );
      case alarmAction === 8:
        return (
          <span style={{ color: Colors.Yellow, fontWeight: 600 }}>Warning</span>
        );
      case alarmAction === 9:
        return (
          <span style={{ color: Colors.Red, fontWeight: 600 }}>
            Critical, no shutdown
          </span>
        );
      case alarmAction === 10:
        return (
          <span style={{ color: Colors.Red, fontWeight: 600 }}>
            Critical, shutdown
          </span>
        );
      default:
        break;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (assetId && startDate && endDate) {
          setLoading(true);
          const events = await dashboardAPI.callAssetEvents(
            assetId,
            startDate,
            endDate
          );
          const sortedEvents = events.sort((a: any, b: any) => b.ts - a.ts);
          setAssetEvents(sortedEvents);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [assetId, startDate, endDate]);

  return (
    <div style={{ width: "100%", padding: 0 }}>
      <Paper
        elevation={0}
        className={
          expanded ? classes.paperStylesExpanded : classes.paperStylesCollapsed
        }
        onClick={() => {
          expandPanel(!expanded);
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            className={
              expanded
                ? classes.warningIconExpanded
                : classes.warningIconCollapsed
            }
          >
            <WarningRoundedIcon />
          </div>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h6"
              className={
                expanded ? classes.headerExpanded : classes.headerCollapsed
              }
            >
              {/* Changed text from events to logged events for issue #72 */}
              Logged events ({assetEvents.length})
            </Typography>
          </span>
        </div>
        {expanded ? (
          <RemoveCircleOutlineOutlinedIcon className={classes.expandIconOpen} />
        ) : (
          <ControlPointIcon className={classes.expandIconCollapsed} />
        )}
      </Paper>
      {expanded && (
        <div>
          {/* removed some css lines from "styles" tab and added a css class eventspanel for responsive UI issue #24 */}
          <div className={classes.eventspanel}>
            {/* ading css class buttonstyling for position of button for responsive UI issue #24 */}
            <div className={classes.buttonstyling}>
              {/* <Button
                color="defaultTheme"
                type="button"
                onClick={() => {
                  console.log(
                    "CLEAR ACTIVE ALARMS - this button is not active."
                  );
                }}
              >
                Clear active events
              </Button> */}
            </div>
            {/* ading css class datestyling for position of date button for responsive UI issue #24 */}
            <div className={classes.datestyling}>
              <DateRangeSelector
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </div>
          </div>
          <TableContainer className={classes.eventstable}>
            <Table
              style={{ width: "100%" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  {/* Updated the column name from Alarm to Event. Github issue #96 */}
                  <TableCell className={table.header}>Event</TableCell>
                  <TableCell className={table.header} align="left">
                    Priority
                  </TableCell>
                  <TableCell className={table.header} align="left">
                    Time
                  </TableCell>
                  <TableCell className={table.header} align="left">
                    Source
                  </TableCell>
                  <TableCell className={table.header} align="left">
                    Description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading &&
                  assetEvents.map((event: AssetEventProp, index: number) => {
                    return (
                      <TableRow key={event.event_id + index}>
                        <TableCell className={table.tableCell} align="left">
                          {event.alarm_name || event.control}
                        </TableCell>
                        <TableCell className={table.tableCell} align="left">
                          {displayPriority(event.alarm_action) ||
                            event.priority}
                        </TableCell>
                        <TableCell className={table.tableCell} align="left">
                          {format(
                            fromUnixTime(event.ts),
                            // changed format from "MMMM dd,yyyy" to MM/dd/yyyy for web view issue #24
                            "MM/dd/yyyy | h:mm:ss a"
                          )}
                        </TableCell>
                        <TableCell className={table.tableCell} align="left">
                          {event.source}
                        </TableCell>
                        <TableCell className={table.tableCell} align="left">
                          {event.description}{" "}
                          {/* Added the event.description as for the loss of communication description is mentioned in this key .Github issue #5 */}
                          {/* Commenting this as only description should be shown instead of alarm state. Github issue #18   
                        Number(event.alarm_state)
                            ? Number(event.alarm_state) === 1
                              ? "Active"
                              : "Clear"
                            :  */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {loading && (
            <div style={{ textAlign: "center", paddingTop: 6 }}>
              Loading events...
            </div>
          )}
          {/* {!loading && !deviceEvents.length && (
              <div style={{ textAlign: "center", paddingTop: 6 }}>
                No events found
              </div>
            )} */}
        </div>
      )}
    </div>
  );
};
export default EventsExpansionPanel;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    tabsRoot: {
      display: "flex",
      float: "right",
      alignContent: "flex-end",
      alignItems: "center"
    },
    tabRoot: {
      fontSize: "1em",
      width: "3em",
      minHeight: "2em",
      height: "2em",
      color: theme.palette.primary.light,
      border: `solid 1px ${theme.palette.primary.light}`,
      minWidth: "3em",
      alignSelf: "center"
    },
    activeTab: {
      backgroundColor: theme.palette.primary.light,
      background: "#FFF",
      textDecoration: "none",
      color: "#FFF"
    },
    tabDates: { alignSelf: "center", marginRight: "1em" },
    headerCollapsed: {
      color: theme.palette.primary.light,
      padding: 0,
      margin: 0
    },
    headerExpanded: { color: "FFF", padding: 0, margin: 0 },
    paperStylesCollapsed: {
      marginTop: "1em",
      height: "2.5em",

      width: "100%",
      border: "solid 1px lightgrey",
      borderLeft: `solid 5px ${theme.palette.primary.light}`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "1em",
      paddingLeft: "1em",
      cursor: "pointer"
    },
    paperStylesExpanded: {
      height: "2.5em",
      marginTop: "1em",
      width: "100%",
      border: "solid 1px lightgrey",
      borderLeft: `solid 5px ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.primary.light,
      color: "white",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "1em",
      paddingLeft: "1em",
      cursor: "pointer"
    },
    expandIconCollapsed: {
      color: theme.palette.primary.light,
      "&:hover": { cursor: "pointer" }
    },
    expandIconOpen: {
      color: "white",
      padding: 0,
      borderRadius: "50%",
      "&:hover": { cursor: "pointer" }
    },
    warningIconCollapsed: {
      alignSelf: "center",
      marginRight: ".5em",
      marginTop: ".2em",
      color: theme.palette.primary.light
    },
    warningIconExpanded: {
      alignSelf: "center",
      marginRight: ".5em",
      marginTop: ".2em",
      color: "FFF"
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    tankIcon: {
      fontSize: "3em",
      borderRadius: "50%",
      padding: ".2em",
      backgroundColor: "limegreen",
      color: "white"
    },
    table: {
      minWidth: 450
    },
    cellHeader: {
      fontWeight: "bold",
      color: theme.palette.primary.light
    },

    //adding styles with media queries for responsive UI issue #24
    //adding styles for eventspanel class for web view and mobile view for responsive UI issue #24
    eventspanel: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "1rem 0",
      [`@media (max-width: 800px)`]: {
        display: "block"
      }
    },
    //adding css class for styling 'clear active events' button in responsive UI issue #24
    buttonstyling: {
      [`@media (max-width: 800px)`]: {
        marginBottom: "20px"
      }
    },
    //adding css class for styling date shown under events tab in responsive UI issue #24
    datestyling: {
      [`@media (max-width: 700px)`]: {
        marginLeft: "28px"
      },
      // media query added for ipad issue #24
      [`@media (max-width: 800px)`]: {
        float: "right",
        marginBottom: "20px"
      }
    },

    //added css class for styling events table in responsive UI issue #24
    eventstable: {
      maxHeight: "500",
      //Updated the media query for Github issue #59
      [`@media (max-width: 584px)`]: {
        // maxWidth: "330px",
        overflowX: "auto"
      }
    }
  })
);
