import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)",
    },
    faIcon: {
      color: theme.palette.primary.light,
      cursor: "pointer",
      margin: "0 0 0 1rem",
    },
    modalBody: {
      maxHeight: "90vh",
      overflowY: "auto",
      width: 500,
      backgroundColor: "white",
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 2.5rem",
      margin: "0 0 2rem 0",
      borderBottom: `1px solid ${Colors.LightGray}`,
    },
    inputBody: {
      height: 500,
      overflowY: "auto",
    },
    headerText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      fontSize: 24,
      padding: 0,
      margin: 0,
    },
    icon: {
      color: Colors.LightGray,
      cursor: "pointer",
      fontSize: "3rem",
    },
    pathText: {
      backgroundColor: Colors.LightGray,
      display: "flex",
      padding: ".5rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        marginRight: "1rem",
        fontSize: "1rem",
      },
    },
    labelText: {
      display: "flex",
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        marginRight: "1rem",
        fontSize: "1rem",
      },
    },
    inputsContainer: {
      // maxHeight: "265px",
      // overflowY: "auto",
    },
    inputGroup: {
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem",
      },
      "& input": {
        width: "100%",
        padding: ".7rem 1rem",
        border: `1px solid ${Colors.LightGray}`,
      },
    },
    hide: {
      display: "none",
    },
    toggleButtonContainer: {
      display: "flex",
      flexDirection: "row",
      width: 100,
      height: 30,
    },
    toggleButton: {
      width: "50%",
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: "#fff",
      color: theme.palette.primary.light,
      fontWeight: 600,
      cursor: "pointer",
      outline: "none",
    },
    selectButton: {
      width: 66,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: "#fff",
      color: theme.palette.primary.light,
      fontWeight: 600,
      height: 30,
      cursor: "pointer",
      outline: "none",
    },
    active: {
      backgroundColor: `${theme.palette.primary.light}`,
      color: "#fff",
    },
    deleteDescription: {
      padding: "0 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem",
      },
    },
    addNewPropertyBtnContainer: {
      padding: "1rem 2.5rem",
    },
    addNewPropertyBtn: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      cursor: "pointer",
      marginRight: "1.5rem",
      fontWeight: "bold",
      backgroundColor: "white",
      outline: "none",
    },
    maxPropertiesWarning: {
      fontSize: "14px",
      fontWeight: 600,
      color: theme.palette.primary.light,
    },
    actionItems: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "1rem 2.5rem",
      borderTop: `1px solid ${Colors.LightGray}`,
      padding: "1rem 0",
    },
    cancelButton: {
      padding: ".7rem 1.5rem",
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      cursor: "pointer",
      marginRight: "1.5rem",
      fontWeight: "bold",
      backgroundColor: "white",
    },
    saveButton: {
      padding: ".7rem 1.5rem",
      color: "white",
      backgroundColor: theme.palette.primary.light,
      cursor: "pointer",
      fontWeight: "bold",
      border: "none",
    },
  })
);

export { useStyles };
