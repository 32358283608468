import React, { useRef, useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";

type UploadButton = {
  onChange: any;
  children: React.ReactNode;
  width?: number | string;
  height?: number | string;
  isImageUpload?: boolean;
};

const UploadButton: React.FC<UploadButton> = ({
  onChange,
  children,
  width,
  height,
  isImageUpload = true,
}) => {
  const classes = useStyles();
  const [file, setFile] = useState<File | null>();
  const fileRef = useRef<HTMLInputElement | null>(null);

  const openFileSelector = () => {
    fileRef.current && fileRef.current.click();
  };

  useEffect(() => {
    onChange && onChange(file);
  }, [file]);

  return (
    <>
      <Grid item>
        <Button
          style={{ width: width, height: height }}
          className={
            isImageUpload ? classes.uploadButton : classes.fileUploadButton
          }
          onClick={openFileSelector}
        >
          {children}
        </Button>
      </Grid>
      <input
        style={{ display: "none" }}
        ref={fileRef}
        type="file"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFile(e.currentTarget.files && e.currentTarget.files[0]);
        }}
        accept={
          isImageUpload
            ? "image/*"
            : "file_extension|audio/*|video/*|image/*|media_type"
        }
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadButton: {
      border: `1px solid ${theme.palette.primary.light}`,
      color: theme.palette.primary.light,
      borderRadius: 0,
      width: 129,
      height: 30,
      textTransform: "none",
      fontSize: 14,
    },
    fileUploadButton: {
      color: Colors.White,
      backgroundColor: theme.palette.primary.light,
      borderRadius: 0,
      width: 129,
      height: 30,
      textTransform: "none",
      fontSize: 14,
    },
  })
);

export default UploadButton;
