import React, { useEffect } from "react";
import { useStyles } from "./topBarStyles";

export type SoftwareTopBarProps = { pageName: string };

export const SoftwareTopBar: React.FC<SoftwareTopBarProps> = ({ pageName }) => {
  const classes = useStyles();

  return pageName === "Firmware" ? (
    <div className={classes.title}>{pageName}</div>
  ) : null;
};

export default SoftwareTopBar;
