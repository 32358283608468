import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const NoActiveAccountsView = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.noAccountsContainer}>
			<div className={classes.messageText}>You currently do not have access to any accounts - please contact your administrator.</div>
    </Grid>
  );
};

export default NoActiveAccountsView;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noAccountsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "1rem",
      height: "90vh",
    },
    topGrid: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    bottomContainer: {
      margin: "1rem 0 0 0",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
		messageText: {
			fontSize: 28,
		}
  })
);
