/* Issue fixed for github issue #24*/
import {
  TanksIcon,
  CompressorIcon,
  PumpsIcon,
  VariableSpeedDriveIcon,
  GeneratorsIcon,
  EnginesIcon,
  ProcessorIcon,
  RigLocksIcon,
  ChemicalInjectorsIcon,
  OtherEquipmentIcon,
} from "assets/bt-equipments/index";
import { fromUnixTime, format } from "date-fns";
import { LatLngBoundsLiteral } from "leaflet";
import { PathwayProps } from "features/real-time-status-page/types";

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const categoryIcon = (assetType: string) => {
  switch (assetType) {
    case "compressor":
      return CompressorIcon;
    case "pumps":
      return PumpsIcon;
    case "tanks":
      return TanksIcon;
    case "variable Speed Drive":
      return VariableSpeedDriveIcon;
    case "generators":
      return GeneratorsIcon;
    case "engines":
      return EnginesIcon;
    case "processors":
      return ProcessorIcon;
    case "rig Locks":
      return RigLocksIcon;
    case "chemical Injectors":
      return ChemicalInjectorsIcon;
    case "other Equipment":
      return OtherEquipmentIcon;
  }
  return TanksIcon;
};

export const handleTimeStamp = (timeStamp: number) => {
  if (!timeStamp) {
    return "N/A";
  }
  try {
    // changed format from "MMMM dd,yyyy" to MM/dd/yyyy for web view as well as responsive UI issue #24 
    return format(fromUnixTime(timeStamp), "MM/dd/yyyy | h:mm:ss a");
  } catch {
    return "Invalid date";
  }
};

export const customDateFormatUnix = (
  timeStamp: number,
  customFormat: string
) => {
  if (!timeStamp) {
    return "N/A";
  }
  try {
    return format(fromUnixTime(timeStamp), customFormat);
  } catch {
    return "Invalid date";
  }
};

export const isValidJson = (json: string) => {
  try {
    JSON.parse(json);
  } catch (e) {
    return false;
  }
  return true;
};

export const parseLocation = (coord: string[]) => {
  try {
    const [longStr, latStr] = coord;
    const _long = parseFloat(longStr);
    const _lat = parseFloat(latStr);
    if (isNaN(_long) || isNaN(_lat)) {
      return undefined;
    }
    return [_long, _lat] as [number, number];
  } catch {
    return undefined;
  }
};

export const getLeafletBbox = (latLongList: [number, number][]) => {
  const result = [Infinity, Infinity, -Infinity, -Infinity];
  for (let coord of latLongList) {
    if (result[0] > coord[0]) {
      result[0] = coord[0];
    }
    if (result[1] > coord[1]) {
      result[1] = coord[1];
    }
    if (result[2] < coord[0]) {
      result[2] = coord[0];
    }
    if (result[3] < coord[1]) {
      result[3] = coord[1];
    }
  }
  const [minLongitude, minLatitude, maxLongitude, maxLatitude] = result;

  return [
    [minLatitude, minLongitude],
    [maxLatitude, maxLongitude],
  ] as LatLngBoundsLiteral;
};

export const parsePathwayForGroup = (
  pathway: PathwayProps[]
): PathwayProps | null => {
  const lastPath = Array.isArray(pathway) ? pathway.slice(-1)[0] : null;
  const isGroup = lastPath && !lastPath.isAsset;
  return isGroup ? lastPath : null;
};

export const parsePathwayForAsset = (
  pathway: PathwayProps[]
): PathwayProps | null => {
  const lastPath = Array.isArray(pathway) ? pathway.slice(-1)[0] : null;
  const isAsset = lastPath ?.isAsset;
  return isAsset ? lastPath : null;
};
