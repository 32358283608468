/* Added this file for activating/deactivating connector modal for github issue #294 */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useDispatch } from "react-redux";
import {
  setSuccessModalOpen,
  setSuccessModalDescription
} from "components/success-modal/successModalSlice";
import { useStyles } from "components/asset-modals/assetStyles";
import AccountAPI from "services/accountsAPI";

type ActivateDeactivateConnectorModalProps = {
  open: boolean;
  handleClose: Function;
  tenantId: string;
  connectorActivity: boolean;
  connectorId: string;
  setCallConnector: Function;
};

export const ActivateDeactivateConnectorModal: React.FC<ActivateDeactivateConnectorModalProps> = ({
  open, //to open modal for issue #294
  handleClose, // to handle closing of modal for issue #294
  tenantId, //to send with api call for issue #294
  connectorActivity, //to add condition to activate/deactivate connector for issue #294
  connectorId, // to send with api call for issue #294
  setCallConnector //to call the connector list after connector operation for issue #294
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // function to handle connector activation/deactivation for issue #294
  const handleActivateDeactivateConnector = async () => {
    // var added to send with api call if activate or deactivate button is clicked for issue #294
    const connectorStatus = connectorActivity ? "deactivate" : "activate";
    try {
      //api call for activating/deactivating connector for issue #294
      const response = await AccountAPI.activateDeactivateToken(
        tenantId,
        connectorId,
        connectorStatus
      );
      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription(response.data.body));
      setCallConnector(true);
    } catch (error) {
      console.log(error);
    }

    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalOverlay}>
        <div className={classes.modalBody} style={{ minHeight: 0 }}>
          <div className={classes.header}>
            <p className={classes.headerText}>
              {/* condition to show Deactivate connector/Activate connector on UI for issue #294 */}
              {connectorActivity ? "Deactivate Connector" : "Activate Connector"}
            </p>
            <CloseIcon className={classes.icon} onClick={() => handleClose()} />
          </div>
          <p style={{ margin: "0 0 0 2rem" }}>
            {connectorActivity
              ? "Are you sure you want to deactivate this connector?"
              : "Are you sure you want to activate this connector?"}
          </p>
          <div className={classes.actionItems} style={{ border: "none" }}>
            <button
              className={classes.cancelButton}
              type="button"
              onClick={() => handleClose()}
            >
              Cancel
            </button>
            {
              <button
                className={classes.saveButton}
                type="button"
                onClick={handleActivateDeactivateConnector}
              >
                {connectorActivity ? "Deactivate connector" : "Activate connector"}
              </button>
            }
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ActivateDeactivateConnectorModal;
