/* Issue fixed for github issue #1,issue #24, issue #71, issue #66,issue #152 */
import React, { useState, useEffect, useCallback } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { LoginProps } from "./_types";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import usernameIcon from "assets/bt-logins/login_username.svg";
import usernameIconError from "assets/bt-logins/login_username_red.svg";
import passwordKey from "assets/bt-logins/login_password.svg";
import passwordKeyError from "assets/bt-logins/login_password_red.svg";
// import Button from "components/common-buttons/Button";
import { Auth } from "aws-amplify";
import clsx from "clsx";
import { Colors } from "common/Colors";
import { useIsMounted } from "utils/reactHooks";
//Added circularprogress bar and modal for showing the loader. Github issue #71
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import MFAMobileAuthModal from "components/MFAMobileAuth/MFAMobileAuthModal"; //Importing modal for MFA for issue #152

const Login: React.FC<LoginProps> = ({
  setLogType,
  onStateChange,
  setCognitoUserObj
}: LoginProps) => {
  const classes = useStyles();
  const isMounted = useIsMounted();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameValid, setUsernameValid] = useState<boolean>(true);
  const [passwordValid, setPasswordValid] = useState<boolean>(true);
  const [userMFA, setUserMFA] = useState(false); //for opening and closing of MFA modal for issue #152
  const [cognitoUser, setCognitoUser] = useState<any[]>([]); //for storing user object before MFA verified for issue #152
  //Added the loading state variable for setting the value of loader as true or false. Github issue #71
  const [loading, setLoading] = useState(false);
  // const history = useHistory();

  // function to handle closing of MFA modal for issue #152
  const handleClose = async () => {
    await Auth.signOut();
    localStorage.clear();
    setUserMFA(false);
  };

  const onLogin = useCallback(
    async (event: any) => {
      event.preventDefault();
      //Loader set to true. Github issue #71
      setLoading(true);
      try {
        const user = await Auth.signIn(username, password);
        localStorage.setItem("userid", user.username); //storing the userid in localstorage for fixing issue #1
        if (isMounted.current) {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            setCognitoUserObj(user);
            setLogType("newPasswordRequired");
            //Loader set to false. Github issue #71
            setLoading(false);
          } else if (
            //Adding condition to redirect user to MFA modal for issue #152
            user.challengeName === "SMS_MFA" ||
            user.challengeName === "SOFTWARE_TOKEN_MFA"
          ) {
            setCognitoUser(user);
            setUsername("");
            setPassword("");
            //Loader set to false. Github issue #71
            setLoading(false);
            setUserMFA(true); //opening up MFA modal for issue #152
          } else {
            setTimeout(() => {
              setUsername("");
              setPassword("");
              //Loader set to false. Github issue #71
              setLoading(false);
            }, 1000);
          }
        }
      } catch (e) {
        console.log(e);
        if (isMounted.current) {
          setUsernameValid(false);
          setPasswordValid(false);
        }
        //Loader set to false. Github issue #71
        setLoading(false);
      }
    },
    [username, password, setCognitoUserObj, setLogType, isMounted]
  );

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        // console.log("Enter key was pressed. Run your function.");
        onLogin(event);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [onLogin]);

  useEffect(() => {
    if (username.length === 0 || username.length) {
      setUsernameValid(true);
      setPasswordValid(true);
    }

    if (password.length === 0) {
      setPasswordValid(true);
    }
  }, [username, password]);

  return (
    <>
      {/* Added the modal and the loader, to be shown on top of the form and the form is disables once loader is true. Github issue #71 */}
      {loading && (
        <Modal
          open={loading}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.loader}
        >
          <CircularProgress />
        </Modal>
      )}
      <div>
        {/* Changed from img to sign in text for issue #66 */}
        <span className={classes.signintext}>Sign In</span>
        <form className={classes.formStyles} noValidate autoComplete="off">
          <span>
            <div className={classes.inputName}>
              <span>
                Username <span style={{ color: Colors.Pink }}>*</span>
              </span>
              <span className={classes.warnings}>
                {!usernameValid ? "Invalid username!" : null}
              </span>
            </div>
            <TextField
              className={clsx({
                [classes.valid]: usernameValid,
                [classes.invalid]: !usernameValid
              })}
              classes={{
                root: classes.input
              }}
              value={username}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setUsername(e.target.value)}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={usernameValid ? usernameIcon : usernameIconError}
                      alt=""
                      style={{
                        margin: "0 1rem 0 0"
                      }}
                    />
                  </InputAdornment>
                )
              }}
            />
          </span>
          <span>
            <div className={classes.inputName}>
              <span>
                Password <span style={{ color: Colors.Pink }}>*</span>
              </span>
              <span className={classes.warnings}>
                {!passwordValid ? "That does not seem right..." : null}
              </span>
            </div>
            <TextField
              type="password"
              className={clsx({
                [classes.valid]: passwordValid,
                [classes.invalid]: !passwordValid
              })}
              classes={{
                root: classes.input
              }}
              value={password}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setPassword(e.target.value)}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={passwordValid ? passwordKey : passwordKeyError}
                      alt=""
                      style={{
                        margin: "0 1rem 0 0",
                        width: "60%"
                      }}
                    />
                  </InputAdornment>
                )
              }}
            />
          </span>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <button
              className={classes.forgotPassword}
              type="button"
              onClick={() => setLogType("forgotPassword")}
            >
              Forgot password?
            </button>
            <button
              type="button"
              id="signInButton"
              className={classes.signIn}
              onClick={onLogin}
            >
              Sign In
            </button>
          </span>
          {/* // ! hidden ** for now - don't delete */}
          {/* <button
        type="button"
        className={classes.signUp}
        onClick={() => setLogType("signUp")}
        // disabled={true} // ! delete this line to allow
      >
        New to Basintrak? Sign up here
      </button> */}
        </form>
        {/* MFA modal to provide MFA for issue #152 */}
        {userMFA && (
          <MFAMobileAuthModal
            open={userMFA}
            handleClose={handleClose}
            cognitoUser={cognitoUser}
          />
        )}
      </div>
    </>
  );
};

export default Login;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStyles: {
      display: "flex",
      flexDirection: "column",
      width: "23em",
      minHeight: "25em",
      justifyContent: "space-evenly",
      marginLeft: "30%",

      //adding media query for login screen responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginLeft: "20%"
      }
    },
    //Added the class for aligning the loader in the center of the page. Github issue #71
    loader: {
      color: theme.palette.primary.light,
      justifyContent: "center",
      alignItems: "center",
      display: "flex"
    },
    inputName: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    warnings: {
      fontSize: "12px",
      color: Colors.Pink,
      position: "relative",
      top: "5px"
    },
    input: {
      display: "flex",
      justifyContent: "center",
      borderRadius: 0,
      color: "white",
      height: 40,
      width: "95%",
      padding: "0 0 0 5%"
    },
    valid: {
      border: "solid 1px #DDDDDD"
    },
    invalid: {
      border: "solid 1px #ED254D"
    },
    forgotPassword: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: Colors.MainColor, //color changed to slate for issue #66
      fontSize: "14px",
      cursor: "pointer",
      border: "none",
      background: "none",
      "&:hover": {
        opacity: ".8"
      },
      "&:focus": { outline: 0 }
    },
    signIn: {
      height: "40px",
      width: "106px",
      border: "none",
      color: "#fff",
      fontSize: "14px",
      backgroundColor: Colors.MainColor, //color changed to slate for issue #66
      cursor: "pointer",
      "&:hover": {
        opacity: ".8"
      }
    },
    signUp: {
      backgroundColor: "white",
      fontSize: "14px",
      fontWeight: 600,
      color: Colors.MainColor, //changed color to slate for issue #66
      border: "solid 1px #253746", //changed color to slate for issue #66
      borderRadius: 0,
      height: "39px",
      "&:hover": {
        cursor: "pointer",
        opacity: ".8",
        backgroundColor: "white",
        color: Colors.MainColor, //changed color to slate for issue #66
        border: "solid 1px #253746" //changed color to slate for issue #66
      }
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    //Added css class signin for styling signin text for issue #66
    signintext: {
      fontSize: "35px",
      color: Colors.MainColor,
      marginLeft: "14rem",
      [`@media (max-width: 800px)`]: {
        margin: "0 0 0 12rem"
      }
    },
    //adding css class for styling footer div for issue #66
    footerdiv: {
      marginLeft: "64rem",
      marginTop: "-55px",
      [`@media (max-width: 700px)`]: {
        marginLeft: "65px",
        marginTop: "165px"
      },
      [`@media (min-width:701px) and (max-width: 800px)`]: {
        marginLeft: "195px",
        marginTop: "400px"
      }
    },
    //adding css class for styling footer text for issue #66
    footertext: {
      fontSize: "14px"
    }
  })
);
