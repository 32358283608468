/* Added this file for create connector modal for github issue #294 */
import React, { useState, useEffect, useCallback } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAppSelector } from "app/store";
import { regex } from "utils/regexPatterns";
import {
  setSuccessModalOpen,
  setSuccessModalDescription
} from "components/success-modal/successModalSlice";
import { setCallConnectors } from "../accountSlice";
import { Grid } from "@material-ui/core";
import AccountsAPI from "services/accountsAPI";
import { useForm, Controller } from "react-hook-form";
import { InputLabel } from "components/input-label/InputLabel";
import Button from "components/common-buttons/Button";
import Select from "react-select";
import { adminRoles, powerUserRoles } from "./roles";
import { RequiredConnectorProps, UsersGroupsProps } from "./types";
import Checkbox from "@material-ui/core/Checkbox";
import LoadSpinner from "common/LoadSpinner";
import clsx from "clsx";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Calendar } from "react-date-range";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import { customDateFormatUnix } from "utils/helpers";
import { fromUnixTime, getUnixTime } from "date-fns";
import calendarWhite from "assets/bt-assets/calendar_white.svg";
import { devicesAPI } from "services/devicesAPI";

const connectorTypeList = [
  {
    name: "Salesforce",
    value: 0
  },
];

type CreateNewConnectorModalProps = {
  handleClose: () => void;
  open: boolean;
};

export const CreateNewConnectorModal: React.FC<CreateNewConnectorModalProps> = ({
  handleClose, // to close the create connector modal for issue #294
  open //to open the create connector modal for issue #294
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [usersGroupSelection, setUsersGroupSelection] = useState<
    UsersGroupsProps[]
  >([]);
  const [connectorDate, setConnectorDate] = useState(Number(""));
  const [deviceList, setDeviceList] = useState<any>([]);
  const [selectedDevices, setSelectedDevices] = useState<any>([]);

  const { register, handleSubmit, errors, control } = useForm<
    RequiredConnectorProps
  >();

  const { userInformation } = useSelector((state: RootState) => state.AppSlice);

  const { tenant_id } = useSelector(
    (state: RootState) => state.AccountSlice.accountInformation
  );

  const { access_level } = useSelector(
    (state: RootState) => state.AppSlice.userInformation
  );

  // function to get device list for issue #294
  useEffect(() => {
    const getDeviceList = async () => {
      try {
        var deviceListArr = [...deviceList];
        const deviceNameList = await devicesAPI.retrieveListOfDevices(tenant_id);

        deviceNameList.map((devicesObj: any) => {
          var temp = { "label": devicesObj?.device_name, "value": devicesObj?.device_id }
          deviceListArr.push(temp)
        })
        setDeviceList(deviceListArr)
      } catch (error) {
        alert(error);
        console.log(error);
      }
    };

    getDeviceList();
  }, [tenant_id]);

  // to set the calendar date timestamp for issue #294
  const calendarExpiryHandler = useCallback(
    (date: Date) => {
      const expiry = getUnixTime(date);
      setConnectorDate(expiry);
    },
    [connectorDate]
  );

  // for group names in access groups input for issue #294
  const formatGroupLabel = (data: any) => (
    <div className={classes.group}>
      <span>{data.label}</span>
      <span className={classes.groupBadge}>{data.options.length}</span>
    </div>
  );

  // function to create connector for issue #294
  const handleCreateConnector = async ({
    connector_name,
    connector_id,
    connector_type,
    connector_expiry,
    // last_post_time,
    creds: { consumer_key, consumer_pk, consumer_secret, end_point, user_name, user_pwd }
  }: RequiredConnectorProps) => {
    try {
      const selectedDeviceList = selectedDevices.map(
        (device: any) => {
          return {
            device_name: `${device.label}`,
            device_id: `${device.value}`,
          };
        }
      );
      connector_expiry = String(connectorDate); //assigning the expiry date to connector_expiry var for issue #294
      if (process.env.NODE_ENV === 'development') {
        console.log("connector_name:", connector_name);
        console.log("connector_expiry:", connector_expiry);
        console.log("selected devices:", selectedDeviceList);
        console.log("connector_type:", connector_type);
        console.log("consumer key:", consumer_key);
        console.log("consumer private key:", consumer_pk);
      }


      setLoading(true);
      // api to create token for issue #294
      await AccountsAPI.createConnector(
        tenant_id,
        connector_name,
        selectedDeviceList,
        consumer_key,
        consumer_pk,
        consumer_secret,
        end_point,
        user_name,
        user_pwd,
      );

      setLoading(false);
      dispatch(setCallConnectors(true)); //calling connector list after successful connector creation for issue #294
      dispatch(setSuccessModalDescription("Connector successfully added!"));
      dispatch(setSuccessModalOpen(true));
      setConnectorDate(Number("")); //resetting the connector date for issue #294
      handleClose();
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        alert(error.response.data);
      } else if (error.request) {
        // the request was made but no response was received
        alert("Error creating new connector: Access Denied");
      } else {
        // Something happened in setting up the request that triggered an Error
        alert("Error creating new connector. Please try again.");
      }
      console.log(error);
      // alert(error); // ! subscription error here on alert but creates user
      setLoading(false);
      handleClose();
    }
  };

  // function to handle adding of device for issue #294
  const addDeviceToList = (device: any) => {
    try {
      if (device.length > 0) {
        setSelectedDevices(device);
      } else {
        setSelectedDevices([]);
      }
    } catch (error) {
      console.log(error)
      alert(error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalOverlay}>
        {loading ? (
          <LoadSpinner />
        ) : (
          <form onSubmit={handleSubmit(handleCreateConnector)}>
            <Grid className={classes.modalBody}>
              <Grid item xs={12} className={classes.header}>
                <p className={classes.headerText}>Add New Connector</p>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={handleClose}
                />
              </Grid>
              <div className={classes.innerInputs}>
                <Grid item>
                  <div className={classes.inputGroup}>
                    {/* input for connector name for issue #294 */}
                    <InputLabel
                      label="Connector name"
                      name="connector_name"
                      errors={errors}
                    />
                    <input
                      name="connector_name"
                      ref={register({
                        required: "This field is required."
                        // pattern: {
                        //   value: /^[a-zA-Z_ ]*$/,
                        //   message: "Letters only."
                        // }
                      })}
                      maxLength={35}
                      type="text"
                      placeholder="Enter"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    <InputLabel
                      label="Select devices"
                      name="device_events"
                      errors={errors}
                    />
                    <div>
                      <Select
                        name="connector_device_events"
                        styles={reactSelectStyles}
                        value={selectedDevices?.value}
                        onChange={addDeviceToList}
                        options={deviceList}
                        maxMenuHeight={220}
                        classNamePrefix="select"
                        placeholder="Select devices"
                        isMulti
                        isSearchable
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    <InputLabel label="Connector type" name="connector_type" errors={errors} />
                    <select
                      name="connector_type"
                      ref={register({
                        required: "This field is required."
                      })}
                      defaultValue={0}

                    >
                      {connectorTypeList.map((connector: any) => {
                        return (
                          <option key={connector?.value} value={connector?.value}>
                            {connector?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Grid>
                <Grid>
                  {/* input with calendar for connector expiry date for issue #294 */}
                  <div className={classes.inputGroup}>
                    <div className={classes.inputName}>
                      Connector start date{" "}
                      <span className={classes.invalidText}>*</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          // overflow: "hidden",
                          display: "flex"
                        }}
                      >
                        <input
                          style={{
                            height: 30,
                            marginRight: 16,
                            cursor: "pointer"
                          }}
                          onClick={() => setCalendarOpen(true)}
                          value={
                            connectorDate
                              ? customDateFormatUnix(
                                Number(connectorDate),
                                "MMM dd yyyy"
                              )
                              : "N/A"
                          }
                        />
                        <Button
                          type="button"
                          color="defaultTheme"
                          onClick={() => setCalendarOpen(true)}
                        >
                          <img
                            src={calendarWhite}
                            alt="Subscription Expiry Calendar"
                          />
                        </Button>
                        {calendarOpen && (
                          <div className={classes.calendarDiv}>
                            <ClickAwayListener
                              onClickAway={() => setCalendarOpen(false)}
                            >
                              <Calendar
                                style={{ border: "1px solid" }}
                                minDate={fromUnixTime(Number(0))}
                                date={
                                  connectorDate
                                    ? fromUnixTime(Number(connectorDate))
                                    : new Date()
                                }
                                onChange={calendarExpiryHandler}
                              />
                            </ClickAwayListener>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    {/* input for connector name for issue #294 */}
                    <InputLabel
                      label="Consumer key"
                      name="creds.consumer_key"
                      errors={errors}
                    />
                    <input
                      name="creds.consumer_key"
                      ref={register({
                        required: "This field is required."
                        // pattern: {
                        //   value: /^[a-zA-Z_ ]*$/,
                        //   message: "Letters only."
                        // }
                      })}
                      maxLength={35}
                      type="text"
                      placeholder="Enter"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    {/* input for connector name for issue #294 */}
                    <InputLabel
                      label="Consumer private key"
                      name="creds.consumer_pk"
                      errors={errors}
                    />
                    <input
                      name="creds.consumer_pk"
                      ref={register({
                        required: "This field is required."
                        // pattern: {
                        //   value: /^[a-zA-Z_ ]*$/,
                        //   message: "Letters only."
                        // }
                      })}
                      maxLength={1000}
                      type="text"
                      placeholder="Enter"
                      className={classes.resizableInput}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    {/* input for connector name for issue #294 */}
                    <InputLabel
                      label="Consumer secret"
                      name="creds.consumer_secret"
                      errors={errors}
                    />
                    <input
                      name="creds.consumer_secret"
                      ref={register({
                        required: "This field is required."
                        // pattern: {
                        //   value: /^[a-zA-Z_ ]*$/,
                        //   message: "Letters only."
                        // }
                      })}
                      maxLength={35}
                      type="text"
                      placeholder="Enter"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    {/* input for connector name for issue #294 */}
                    <InputLabel
                      label="End point"
                      name="creds.end_point"
                      errors={errors}
                    />
                    <input
                      name="creds.end_point"
                      ref={register({
                        required: "This field is required."
                        // pattern: {
                        //   value: /^[a-zA-Z_ ]*$/,
                        //   message: "Letters only."
                        // }
                      })}
                      maxLength={35}
                      type="text"
                      placeholder="Enter"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    {/* input for connector name for issue #294 */}
                    <InputLabel
                      label="User name"
                      name="creds.user_name"
                      errors={errors}
                    />
                    <input
                      name="creds.user_name"
                      ref={register({
                        required: "This field is required."
                        // pattern: {
                        //   value: /^[a-zA-Z_ ]*$/,
                        //   message: "Letters only."
                        // }
                      })}
                      maxLength={35}
                      type="text"
                      placeholder="Enter"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    {/* input for connector name for issue #294 */}
                    <InputLabel
                      label="User password"
                      name="creds.user_pwd"
                      errors={errors}
                    />
                    <input
                      name="creds.user_pwd"
                      ref={register({
                        required: "This field is required."
                        // pattern: {
                        //   value: /^[a-zA-Z_ ]*$/,
                        //   message: "Letters only."
                        // }
                      })}
                      maxLength={35}
                      type="text"
                      placeholder="Enter"
                    />
                  </div>
                </Grid>
              </div>
              <Grid
                item
                xs={12}
                className={classes.actionItems}
                style={{ justifyContent: "flex-end" }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Button
                    type="submit"
                    color="white"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="defaultTheme">
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default CreateNewConnectorModal;

const inputStyle = {
  "& p": {
    padding: 0,
    margin: 0,
    fontSize: "1rem",
    marginBottom: ".5rem"
  },
  "& input": {
    width: "100%",
    padding: ".5rem 1rem",
    border: `1px solid ${Colors.LightGray}`
  },
  "& select": {
    width: "100%",
    padding: ".5rem 1rem",
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer"
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)"
    },
    modalBody: {
      maxHeight: "90vh",
      overflowY: "auto",
      // width: 830,
      // overflowY: "auto",
      backgroundColor: Colors.White,
      zIndex: 1
    },
    resizableInput: {
      width: "100%", // Adjust width as needed
      padding: "10px",
      fontSize: "16px",
      borderRadius: "4px",
      border: "1px solid #ccc",
      resize: "both", // Allows resizing both horizontally and vertically
      overflowY: "auto", // Ensures content is handled correctly during resizing
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 2.5rem",
      margin: "0 0 1em 0",
      borderBottom: `1px solid ${Colors.LightGray}`
    },
    headerText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      fontSize: 24,
      padding: 0,
      margin: 0
    },
    closeIcon: {
      color: Colors.LightGray,
      cursor: "pointer",
      fontSize: "3rem"
    },
    innerInputs: {
      padding: "0 2.5rem",
      width: "100%"
    },
    heartIcon: {
      color: theme.palette.primary.light,
      cursor: "pointer"
    },
    inputGroup: {
      padding: "1rem 1rem 1rem 0",
      ...inputStyle
    },
    inputGroupRight: {
      padding: "1rem 0 1rem 1rem",
      ...inputStyle
    },
    labelContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    buttonContainer: {
      height: 30
    },
    button: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: Colors.White,
      cursor: "pointer",
      padding: "0 1rem",
      outline: "none",
      height: 30
    },
    disabledButton: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: Colors.LightGray,
      cursor: "none",
      padding: "0 1rem",
      outline: "none",
      height: 30
    },
    isActive: {
      backgroundColor: theme.palette.primary.light,
      color: Colors.White
    },
    active: {
      color: Colors.Green,
      fontWeight: 600,
      margin: "0 0 0 1rem"
    },
    notActive: {
      color: Colors.LightGray,
      fontWeight: 600,
      margin: "0 0 0 1rem"
    },
    accountInformation: {
      borderTop: `1px solid ${Colors.LightGray}`
    },
    accessAccount: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      "& p": {
        fontSize: 16,
        color: theme.palette.primary.light,
        margin: "0 0 0 .5rem",
        fontWeight: 600
      }
    },
    actionItems: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "1rem 2.5rem 0",
      borderTop: `1px solid ${Colors.LightGray}`,
      padding: "1rem 0"
    },
    inputName: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    group: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    groupBadge: {
      backgroundColor: "#EBECF0",
      borderRadius: "2em",
      color: "#172B4D",
      display: "inline-block",
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "1",
      minWidth: 1,
      padding: "0.16666666666667em 0.5em",
      textAlign: "center"
    },
    isBasinTrakEmployee: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    invalidText: {
      margin: "0 0 0 1px",
      color: "#ED254E"
    },
    calendarDiv: {
      position: "absolute",
      zIndex: 999,
      top: "40%",
      left: "60%",

      [`@media (min-height:800px)`]: {
        top: "auto",
        left: "auto",
        marginTop: "30px"
      }
    }
  })
);

const reactSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer",
    width: "375px",
  }),
};
