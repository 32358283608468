import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ButtonProps } from "./types";
import checkMark from "../../assets/bt-assets/Vector.svg";
import { Colors } from "../../common/Colors";

const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  spacing,
  color,
  id,
  isActive,
  ...props
}) => {
  const styles = useStyles();
  const spacingPixels = spacing ? spacing * 8 : 0;

  return (
    <button
      className={styles.button}
      onClick={onClick}
      style={{
        backgroundColor: color,
        marginLeft: spacingPixels,
        marginRight: spacingPixels,
      }}
      {...props}
    >
      {children && children}
      {isActive && <img src={checkMark} alt="checkmark" />}
    </button>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      width: 60,
      height: 60,
      borderRadius: "50%",
      border: 0,
      color: Colors.White,
      marginBottom: 10,
      cursor: "pointer",
    },
  })
);

export default Button;
