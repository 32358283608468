/* Issue fixed for github issue #155,issue #152,issue #283 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInformation, SelectedAccountProps } from "./types";

const initialState: {
  userInformation: UserInformation;
  usersAssetGroupIds: string[];
  loading: boolean;
  currentForgottenEmail: string;
  selectedAccount: SelectedAccountProps;
  superAdminAccountId: string;
  fullscreen: boolean;
} = {
  userInformation: {
    phone_mobile: "",
    user_id: "",
    active: false,
    accounts: [],
    access_level: "",
    username: "",
    email: "",
    given_name: "",
    family_name: "",
    phone_office: "",
    notify_select: [],
    mobile_verified: false, //Added key for verification of mobile number for issue #152
    email_verified: false, //Added key for verification of email for issue #152
    mfa: "0", //Added key for multi-factor authentication for issue #152
    loss_of_comms_notify: true, //Added key for loss of comms for issue #155
    last_login_time: ""  //Added key to display last login time for issue #283
  },
  usersAssetGroupIds: [],
  loading: false,
  currentForgottenEmail: "",
  selectedAccount: {
    accountName: "",
    accountId: "",
    role: "",
    active: undefined
  },
  superAdminAccountId: "",
  fullscreen: false
};

const appSlice = createSlice({
  name: "AppSlice",
  initialState,
  reducers: {
    setUserInformation: (state, action: PayloadAction<UserInformation>) => {
      const { accounts, user_id } = action.payload;
      if (
        state.userInformation.user_id &&
        state.userInformation.user_id === user_id &&
        accounts.length === state.userInformation.accounts.length
      ) {
        // TODO: lodash_isEqual check if this payload is a duplicate of redux persist to avoid re-rendering from a "new" store update
        state.userInformation = { ...state.userInformation };
      } else {
        state.userInformation = action.payload;
      }
    },
    setUsersAssetGroupIds: (state, action: PayloadAction<string[]>) => {
      state.usersAssetGroupIds = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setForgottenEmail: (state, action: PayloadAction<string>) => {
      state.currentForgottenEmail = action.payload;
    },
    setSelectedAccount: (
      state,
      action: PayloadAction<{
        account: SelectedAccountProps;
        initializing?: boolean;
      }>
    ) => {
      const { initializing, account } = action.payload;
      if (
        initializing &&
        state.selectedAccount &&
        state.selectedAccount?.accountId !== "" &&
        state.selectedAccount?.accountId !== account.accountId
      ) {
        // from "did-mount" useEffect and app has selectedAccount from redux-persist, do not overwrite
        state.selectedAccount = { ...state.selectedAccount };
      } else {
        state.selectedAccount = account;
      }
    },
    setSuperAdminAccountId: (state, action: PayloadAction<string>) => {
      state.superAdminAccountId = action.payload;
    },
    setFullscreen: (state, action: PayloadAction<boolean>) => {
      state.fullscreen = action.payload;
    }
  }
});

export const {
  setUserInformation,
  setUsersAssetGroupIds,
  setLoading,
  setForgottenEmail,
  setSelectedAccount,
  setSuperAdminAccountId,
  setFullscreen
} = appSlice.actions;
export default appSlice.reducer;
