/* Issue fixed for github issue #33*/
import React, { useState } from "react"; //importing useState for using state variables for issue #33
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  // TableHead,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import ellipsis from "assets/bt-assets/more-vertical@2x.svg";
import { UsersAccounts, SuperAdminAccounts } from "./types";
import clsx from "clsx";
import Popover from "@material-ui/core/Popover";  //importing for delete popover when clicked on delete option issue #33
import DeleteAccountModal from "./DeleteAccountModal";  //importing for delete account modal for deleting account issue #33

type AccountsTableProps = {
  handleTenant: Function;
  accounts: any;
  accessLevel: string;
};

const AccountsTable: React.FC<AccountsTableProps> = ({
  handleTenant,
  accounts,
  accessLevel,
}) => {
  const classes = useStyles();

  const [threedots, setthreedots] = useState(false);  //state variable for showing popover with delete and disable option for issue #33
  const [anchorEl, setAnchorEl] = useState(null); //state variable for getting position of click on delete option issue #33
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);  //for calling delete account modal for issue #33
  const [selectedTenantId, setSelectedTenantId] = useState(""); //for getting tenant id of account to be deleted in issue #33
  const [selectedTenantName, setSelectedTenantName] = useState(""); //for getting tenant name of account to be deleted in issue #33

  //function for handling event on three dots clicked for issue #33
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setthreedots(true);
  };

  //function for closing delete modal on clicking cross for issue #33
  const handleClose = () => {
    setAnchorEl(null);
    setthreedots(false);
  };

  //for getting details of account on which cursor has clicked for issue #33
  const getTenantDetails = (tenantId: any, tenantName: any) => {
    setSelectedTenantId(tenantId);
    setSelectedTenantName(tenantName);
  }

  //for handling the delete event and calling delete modal for issue #33
  const handleDelete = () => {
    //handleTenant();
    setDeleteAccountModal(true);
    setthreedots(false);
  };


  return (
    <Grid item xs={12} className={classes.root}>
      <TableContainer>
        <Table className={classes.table}>
          {/* <hr className={classes.lineBreak} /> */}
          <TableBody>
            {accessLevel === "admin"
              ? accounts.map((account: SuperAdminAccounts, index: number) => {
                return (
                  <TableRow key={index}>
                    {/* this will have id or some sort of identifier attached to cell to know which company it is targetting */}
                    <TableCell className={classes.tableCell}>
                      {/* <div className={classes.logo}></div> */}
                      <div
                        className={classes.companyInformation}
                        onClick={() => {
                          handleTenant(account.tenant_id);
                        }}
                      >
                        <p className={classes.companyName}>
                          {account.tenant_name}
                        </p>
                        <div className={classes.address}>
                          {account ?.billing_address.address_line}{" "}
                          {account ?.billing_address.city}{" "}
                          {account ?.billing_address.state_province},{" "}
                          {account ?.billing_address.zip_code}
                        </div>
                      </div>
                      {/* Added handleclick function for handling click and getting tenant details for issue #33 */}
                      <div style={{ cursor: "pointer" }} onClick={handleClick}>
                        {/* added getTenantDetails function for getting details of account which has been clicked for issue #33 */}
                        <img src={ellipsis} alt="" onClick={() => {
                          getTenantDetails(account.tenant_id, account.tenant_name);
                        }} />
                      </div>
                      {/* Adding popover asking for confirmation from user to delete account for issue #33 */}
                      <Popover
                        open={threedots}  //opening popover when three dots are clicked for issue #33
                        anchorEl={anchorEl} //getting position of click for issue #33
                        onClose={handleClose} //for handling close button for issue #33
                        //for showing the small popover of delete option just below three dots for issue #33
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        {/* div for delete option for issue #33 */}
                        <div className={classes.selectionContainer}>
                          {threedots ? (
                            // Adding delete option and a function to handle delete option for issue #33
                            <p className={classes.selection} onClick={() => handleDelete()}>
                              Delete
                            </p>
                          ) : null}
                        </div>
                      </Popover>
                      {/* calling delete account modal when clicked for issue #33 and when deleteAccountModal variable is true */}
                      {deleteAccountModal && (
                        <DeleteAccountModal
                          open
                          handleClose={() => setDeleteAccountModal(false)}  //function for showing and hiding delete account modal for issue #33
                          tenantId={selectedTenantId} //for sending selected account id for displaying in modal for issue #33
                          tenantname={selectedTenantName} //for sending selected account name for displaying in modal for issue #33
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
              : accounts.map((account: UsersAccounts, index: number) => {
                const notAdminRole =
                  [
                    "office_user",
                    "field_operations",
                    "field_view_only",
                    "notifications",
                  ].indexOf(account.roles[0]) > -1;
                return (
                  <TableRow
                    key={index}
                    className={clsx({
                      [classes.notPermitted]:
                        notAdminRole || account ?.active !== true,
                    })}
                  >
                    {/* this will have id or some sort of identifier attached to cell to know which company it is targetting */}
                    <TableCell className={classes.tableCell}>
                      {/* <div className={classes.logo}></div> */}
                      <div
                        className={classes.companyInformation}
                        onClick={() => {
                          handleTenant(account.tenant_id);
                        }}
                      >
                        <p className={classes.companyName}>
                          {account.tenant_name}
                        </p>
                        <div className={classes.address}>
                          {account.address}
                        </div>
                      </div>
                      {/* Added handleclick function for handling click and getting tenant details for issue #33 */}
                      <div style={{ cursor: "pointer" }} onClick={handleClick}>
                        {/* added getTenantDetails function for getting details of account which has been clicked for issue #33 */}
                        <img src={ellipsis} alt="" onClick={() => {
                          getTenantDetails(account.tenant_id, account.tenant_name);
                        }} />
                      </div>
                      {/* Adding popover asking for confirmation from user to delete account for issue #33 */}
                      <Popover
                        open={threedots}  //opening popover when three dots are clicked for issue #33
                        anchorEl={anchorEl} //getting position of click for issue #33
                        onClose={handleClose} //for handling close button for issue #33
                        //for showing the small popover of delete option just below three dots for issue #33
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        {/* div for delete option for issue #33 */}
                        <div className={classes.selectionContainer}>
                          {threedots ? (
                            // Adding delete option and a function to handle delete option for issue #33
                            <p className={classes.selection} onClick={() => handleDelete()}>
                              Delete
                            </p>
                          ) : null}
                        </div>
                      </Popover>
                      {/* calling delete account modal when clicked for issue #33 and when deleteAccountModal variable is true */}
                      {deleteAccountModal && (
                        <DeleteAccountModal
                          open
                          handleClose={() => setDeleteAccountModal(false)}  //function for showing and hiding delete account modal for issue #33
                          tenantId={selectedTenantId} //for sending selected account id for displaying in modal for issue #33
                          tenantname={selectedTenantName} //for sending selected account name for displaying in modal for issue #33
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default AccountsTable;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowY: "auto",
    },
    lineBreak: {
      width: "100%",
      border: `1px solid ${Colors.LightGray}`,
    },
    table: {
      minWidth: "100%",
      marginTop: "1rem",
      borderTop: `2px solid ${Colors.LightGray}`,
    },
    tableCell: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 0",
      margin: 0,
      borderBottom: `2px dotted ${Colors.LightGray}`,
      fontSize: 14,
    },
    companyInformation: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "80%",
      cursor: "pointer",
    },
    companyName: {
      color: theme.palette.primary.light,
      fontSize: 24,
      margin: "0 2rem 0 0",
      fontWeight: 600,
    },
    subCompanyName: {
      color: theme.palette.primary.light,
      fontSize: 24,
      margin: "0 2rem 0 2rem",
    },
    subCompanies: {
      fontSize: 18,
      fontWeight: 600,
      color: Colors.LightGray,
      margin: "0 2rem 0 0",
    },
    address: {
      fontSize: 14,
      margin: "0 2rem 0 0",
      color: theme.palette.primary.light,
    },
    comment: {
      height: "1.8rem",
      color: Colors.Gray,
      cursor: "pointer",
    },
    ellipsis: {
      cursor: "pointer",
      color: theme.palette.primary.light,
      // height: "2rem",
      margin: "0 0 0 2rem",
    },
    notPermitted: {
      opacity: 0.5,
      pointerEvents: "none",
    },

    //adding css style classes for styling the delete account modal and popover for issue #33
    //css class for styling delete popover option
    selectionContainer: {
      padding: ".5rem 1rem",
    },

    //css class for styling delete option for issue #33
    selection: {
      fontSize: "14px",
      color: theme.palette.primary.light,
      margin: 0,
      cursor: "pointer",
    },
  })
);
