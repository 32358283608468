export const binaryFromJsonList = (num: number, json: any) => {
  if (num === 0) return json["-1"];

  const reversedBinaryArray = num
    .toString(2)
    .split("")
    .reverse();

  const convertedBinaryToText = reversedBinaryArray.reduce(
    (arr: string[], bit: string, index: number) => {
      if (parseInt(bit)) {
        if (json.hasOwnProperty(index)) {
          arr.push(json[index]);
        } else {
          arr.push("--");
        }
      }
      return arr;
    },
    []
  );

  return convertedBinaryToText.join(", ");
};
