/* Issue fixed for github issue #33*/
import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import CircularProgress from "@material-ui/core/CircularProgress";
//import { useStyles } from "components/asset-modals/assetStyles";
import { Colors } from "common/Colors";
import Button from "components/common-buttons/Button";
import { useDispatch } from "react-redux";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
} from "components/success-modal/successModalSlice";
import AccountAPI from "services/accountsAPI";
import {
  setAccountDashboard
} from "../accountSlice";

type Props = {
  open: boolean;
  handleClose: () => void;
  tenantId: string;
  tenantname: string;
};
export const DeleteAccountModal: React.FC<Props> = ({
  open,
  handleClose,
  tenantId,
  tenantname,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //Added confirm delete function for asking user to confirm whether the account name is correct for issue #33
  const confirmDeleteAccountHandler = async () => {
    try {
      await AccountAPI.deleteTenant(tenantId);
      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription("Account successfully deleted!"));
      dispatch(setAccountDashboard(true));
    } catch (error) {
      alert("Error deleting account");
    } finally {
      handleClose();
    }
  };


  return (
    // added the modal asking user for confirmation for account name for issue #33
    <Modal open={open} onClose={handleClose}>
      <div className={classes.modalOverlay}>
        <div className={classes.modalBody} style={{ minHeight: 0 }}>
          <div className={classes.header}>
            <p className={classes.headerText}>Delete Account</p>
            <CloseIcon className={classes.icon} onClick={handleClose} />
          </div>
          <p style={{ margin: "0 0 1rem 2rem" }}>
            Are you sure you want to delete {tenantname}?
          </p>
          <div className={classes.actionItems} style={{ border: "none" }}>
            <Button color="white" type="button" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="defaultTheme"
              type="button"
              onClick={confirmDeleteAccountHandler}
            >
              Delete Account
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectionContainer: {
      padding: ".5rem 1rem",
    },
    paper: {
      border: "none",
      borderRadius: 0,
    },
    selection: {
      fontSize: "14px",
      color: theme.palette.primary.light,
      margin: 0,
      cursor: "pointer",
    },
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)",
    },
    modalBody: {
      maxHeight: "90vh",
      overflowY: "auto",
      width: 500,
      backgroundColor: Colors.White,
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 2.5rem",
      margin: "0 0 1em 0",
      borderBottom: `1px solid ${Colors.LightGray}`,
    },
    inputBody: {
      maxHeight: 500,
      overflowY: "auto",
    },
    required: {
      color: Colors.Pink,
    },
    headerText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      fontSize: 24,
      padding: 0,
      margin: 0,
    },
    icon: {
      color: Colors.LightGray,
      cursor: "pointer",
      fontSize: "3rem",
    },
    pathText: {
      backgroundColor: Colors.LightGray,
      display: "flex",
      padding: ".5rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        marginRight: "1rem",
        fontSize: "1rem",
      },
    },
    actionItems: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "1rem 2.5rem 0",
      borderTop: `1px solid ${Colors.LightGray}`,
      padding: "1rem 0",
    },
    cancelButton: {
      padding: ".7rem 1.5rem",
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      cursor: "pointer",
      marginRight: "1.5rem",
      fontWeight: "bold",
      backgroundColor: Colors.White,
    },
    saveButton: {
      padding: ".7rem 1.5rem",
      color: Colors.White,
      backgroundColor: theme.palette.primary.light,
      cursor: "pointer",
      fontWeight: "bold",
      border: "none",
    },
    faIcon: {
      color: theme.palette.primary.light,
      backgroundColor: "transparent",
    },
  })
);
