import React, { useState, useEffect } from "react";
import { Colors } from "common/Colors";
import { Grid } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SequenceIcon from "assets/bt-assets/Group 95.png";
import {
  setParameterSequence,
  setAssetParameterList,
} from "../assetsDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { DeviceParametersProps } from "features/devices-page/device-settings/types";
import { devicesAPI } from "services/devicesAPI";
import ViewParameterModal from "./ViewParameterModal";
// import clsx from "clsx";

const reorder = (
  list: DeviceParametersProps[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const ParameterSequencePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [viewParameterModal, setViewParameterModal] = useState(false);
  const [parameterIndex, setParameterIndex] = useState(0);
  const [assetParameterListToDisplay, setAssetParameterListToDisplay] =
    useState<DeviceParametersProps[]>([]);

  const { assetParameterList, currentAssetId } = useSelector(
    (state: RootState) => state.AssetDashboardSlice
  );

  const onDragEnd = async (endDraggedItem: any) => {
    if (!endDraggedItem.destination) {
      return;
    }

    try {
      const displayedReorderdParameterList: any[] = reorder(
        assetParameterListToDisplay,
        endDraggedItem.source.index,
        endDraggedItem.destination.index
      );

      setAssetParameterListToDisplay(displayedReorderdParameterList);
      const updatedParameterList = await devicesAPI.moveParameter(
        currentAssetId,
        endDraggedItem.draggableId,
        endDraggedItem.destination.index
      );

      dispatch(setAssetParameterList(updatedParameterList));
    } catch (error) {
      setAssetParameterListToDisplay(assetParameterList);
      alert(error);
    }
  };

  const closeViewParameterModal = () => {
    setViewParameterModal(false);
  };

  useEffect(() => {
    setAssetParameterListToDisplay(assetParameterList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {assetParameterList && (
        <Grid className={classes.root} container>
          <Grid item xs={12} style={{ margin: "1rem" }}>
            <div className={classes.headers}>
              <div>Change parameter display sequence</div>
              <div className={classes.headerName}>Device</div>
              <div className={classes.headerName}>Parameter</div>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droped-id">
                {(provided: any) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ padding: 0 }}
                  >
                    {assetParameterListToDisplay.map(
                      (parameter: DeviceParametersProps, index: number) => (
                        <Draggable
                          key={parameter.device_parameter_id}
                          draggableId={parameter.device_parameter_id}
                          index={index}
                        >
                          {(provided: any, snapshot: any) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={rowStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div>
                                <img
                                  src={SequenceIcon}
                                  alt="move-icon"
                                  style={{
                                    cursor: "grab",
                                    zIndex: 20,
                                    pointerEvents: "auto",
                                  }}
                                />
                              </div>
                              <div style={{ fontWeight: 600 }}>
                                {parameter.device_name}
                              </div>
                              <div>
                                <span
                                  className={classes.parameter}
                                  onClick={(e: any) => {
                                    // e.preventDefault();
                                    setParameterIndex(index);
                                    setViewParameterModal(true);
                                  }}
                                >
                                  {parameter.parameter_name}
                                </span>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <button
              className={classes.redOutlineButton}
              type="button"
              onClick={() => dispatch(setParameterSequence(false))}
            >
              OK
            </button>
          </Grid>
        </Grid>
      )}
      {assetParameterList.length ? (
        <ViewParameterModal
          parameterIndex={parameterIndex}
          assetParameterList={assetParameterList}
          open={viewParameterModal}
          handleClose={closeViewParameterModal}
        />
      ) : null}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingLeft: "1rem",
    },
    headers: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      borderTop: `2px solid ${Colors.LightGray}`,
      padding: ".9rem",
      fontWeight: 600,
    },
    headerName: {
      color: theme.palette.primary.light,
    },
    parameter: {
      color: theme.palette.primary.light,
      pointerEvents: "auto",
      fontWeight: 600,
      cursor: "pointer",
      zIndex: 20,
    },
    row: {
      backgroundColor: Colors.White,
      color: Colors.Black,
      fontSize: 14,
      fontWeight: 600,
      cursor: "move",
      margin: "1rem",
      border: `1px solid ${Colors.Black}`,
    },
    redOutlineButton: {
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      background: theme.palette.primary.light,
      width: 150,
      height: 30,
      color: Colors.White,
      cursor: "pointer",
    },
  })
);

const rowStyle = (isDragging: any, draggableStyle: any) => ({
  userSelect: "none",
  padding: ".6rem 1rem",
  margin: 0,
  // ! this changes background color if dragging
  background: isDragging ? Colors.LightGray : null,
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  borderTop: `2px solid ${Colors.LightGray}`,
  fontWeight: 600,
  cursor: "default",
  pointerEvents: "none",
  zIndex: 1,
  ...draggableStyle,
});
