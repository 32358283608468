import { axiosCall, URL, Method } from "./config";

export const batchAPI = {
  //  ! admin specific for OTA
  //#region
  //Added device_type to update the api to accept device_type. Github issue 92
  createBatchDeviceIntoRegistry: async (count: number, batch_code: string, device_type: number) => {
    const response = await axiosCall(Method.POST, `${URL()}/registry`, {
      count,
      batch_code,
      device_type
    });
    return response.data.Items;
  },
  retrieveBatchIds: async () => {
    const response = await axiosCall(Method.GET, `${URL()}/registry/batch`);
    return response.data.Items;
  },
  retrieveAllDevicesInBatch: async (batch_id: string) => {
    const response = await axiosCall(Method.POST, `${URL()}/registry/batch`, {
      batch_id,
    });
    return response.data.Items;
  },
  //#endregion
};
