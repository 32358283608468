/* Issue fixed for github issue #84,issue #213,issue #290 */
import React, { useEffect, useState } from "react";
// import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// import { Colors } from "common/Colors";
import { useStyles } from "./displayStyles";
import SuccessModal from "components/success-modal/SuccessModal";
import { useSelector, useDispatch } from "react-redux";
import { setIsGroupActive } from "components/group-modal/groupModalSlice";
import { RootState } from "app/rootReducer";
import { Grid } from "@material-ui/core";
import AssetAPI from "services/assetsAPI";
import { AssetGroupProfile } from "./types";
import { InputLabel } from "components/input-label/InputLabel";
import { useForm } from "react-hook-form";
import { RequiredGroupProps } from "components/asset-modals/types";
import clsx from "clsx";
import Button from "components/common-buttons/Button";
import UnsuccessModal from "components/success-modal/UnsuccessfullModal"; //imported for issue #213

type GroupsDisplayProps = {
  openAddAssetModal: Function;
  openAddGroupModal: Function;
  openGroupModal: Function;
  returnToParent: Function;
  nodeObject: any;
};

const groupProfileObject = {
  parent_group: {
    group_name: "",
    asset_group_id: ""
  },
  tenant_id: "",
  group_sharing: false,
  group_status: false,
  asset_group_id: "",
  is_asset_group: false,
  asset_organizer: [],
  children_groups: [],
  shared_customers: [],
  group_name: "asset_group_A",
  count_groups: 0,
  count_assets: 0,
  count_devices: 0
};

const GroupsDisplay: React.FC<GroupsDisplayProps> = ({
  openAddAssetModal,
  openAddGroupModal,
  openGroupModal,
  returnToParent,
  nodeObject
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [groupSharing, setGroupSharing] = useState(true);
  // const [isActive, setIsActive] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupTitle, setGroupTitle] = useState("");
  const [groupId, setGroupId] = useState("");
  const [groupProfile, setGroupProfile] = useState<AssetGroupProfile>(
    groupProfileObject
  );
  const { register, handleSubmit, errors } = useForm<RequiredGroupProps>();

  const isGroupActive = useSelector(
    (state: RootState) => state.GroupModalSlice.isGroupActive
  );

  const newGroupName = useSelector(
    (state: RootState) => state.AssetDashboardSlice.groupName
  );

  useEffect(() => {
    if (nodeObject.assetGroupId) {
      const retrieveGroupProfile = async () => {
        try {
          const assetGroup = await AssetAPI.retrieveAssetGroupProfile(
            nodeObject.assetGroupId
          );
          // console.log("ASSET GROUP: ", assetGroup);
          setGroupProfile(assetGroup);
          setGroupSharing(assetGroup.group_sharing);
          setGroupName(assetGroup.group_name);
          setGroupTitle(assetGroup.group_name);
          dispatch(setIsGroupActive(assetGroup.group_status));
          setGroupId(assetGroup.asset_group_id);
        } catch (error) {
          console.log(error);
          alert(error);
        }
      };

      retrieveGroupProfile();
    }
  }, [nodeObject.assetGroupId, dispatch]);

  useEffect(() => {
    setGroupTitle(newGroupName);
  }, [newGroupName]);

  return (
    <>
      <div className={classes.pathHeader}>
        <span
          style={{
            fontWeight: 600,
            color: "#A6A6A6",
            margin: "0 2rem"
          }}
        >
          Path:
        </span>
        {/* // ! this will be a path eventually  */}
        <span>{groupTitle}</span>
      </div>
      <div className={classes.assetInformation}>
        <div className={classes.assetsGroup}>
          <div className={classes.value}>{groupProfile.count_groups}</div>
          <div>Groups</div>
        </div>
        <div className={classes.assetsGroup}>
          <div className={classes.value}>{groupProfile.count_assets}</div>
          <div>Assets</div>
        </div>
        <div className={classes.assetsGroup}>
          <div className={classes.value}>{groupProfile.count_devices}</div>
          <div>Devices</div>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <div className={classes.typeGroup}>Type Group:</div>
        <div className={classes.buttonGroup}>
          <Button
            type="button"
            color="white"
            style={{ margin: "1rem 1rem 0 0", width: "110px" }} //Added width for proper alignment of button text. Github issue #84
            onClick={() => {
              // ! on delete we need to recall the react tree list
              openGroupModal("delete", groupId);
            }}
          >
            Delete group
          </Button>{" "}
          {!isGroupActive ? (
            <Button
              type="button"
              color="white"
              style={{ margin: "1rem 1rem 0 0", width: "136px" }} //Added width for proper alignment of button text. Github issue #84
              onClick={() => {
                openGroupModal("activate", groupId);
              }}
            >
              Activate group
            </Button>
          ) : (
            <Button
              type="button"
              color="white"
              style={{ margin: "1rem 1rem 0 0", width: "136px" }} //Added width for proper alignment of button text. Github issue #84
              onClick={() => {
                openGroupModal("deactivate", groupId);
              }}
            >
              Deactivate group
            </Button>
          )}
          <Button
            type="button"
            color="defaultTheme"
            style={{ margin: "1rem 1rem 0 0", width: "132px" }} //Added width for proper alignment of button text. Github issue #84
            onClick={() => {
              openAddAssetModal();
            }}
          >
            + Add new asset
          </Button>
          <Button
            type="button"
            color="defaultTheme"
            style={{ width: "137px", margin: "1rem 1rem 0 0", }} //Added width for proper alignment of button text. Github issue #84
            onClick={() => {
              openAddGroupModal();
            }}
          >
            + Add new group
          </Button>
        </div>
      </div>
      <form
        onSubmit={handleSubmit(({ groupName }) => {
          openGroupModal("update group", groupId, groupName, groupSharing);
        })}
      >
        <Grid container className={classes.assetContainer}>
          <Grid item xs={6}>
            <div className={classes.labelText}>
              {/* Changed text for issue #290 */}
              <p>Selection: </p>
              <p style={{ fontWeight: "bold" }}> {groupTitle} </p>
            </div>
            {/* // ! commented out code for future phase */}
            {/* <div className={classes.inputGroup}>
              <span>Group sharing</span>
              <div className={classes.toggleButtonContainer}>
                <button
                  className={clsx(classes.toggleButton, {
                    [classes.active]: groupSharing,
                  })}
                  type="button"
                  onClick={() => {
                    setGroupSharing(true);
                  }}
                >
                  ON
                </button>
                <button
                  className={clsx(classes.toggleButton, {
                    [classes.active]: !groupSharing,
                  })}
                  type="button"
                  onClick={() => {
                    setGroupSharing(false);
                  }}
                >
                  OFF
                </button>
              </div>
            </div> */}
            <div className={classes.inputGroup}>
              <div style={{ width: "70%" }}>
                <InputLabel
                  label="Group name"
                  name="groupName"
                  errors={errors}
                />
              </div>
              <input
                name="groupName"
                ref={register({
                  required: "This field is required"
                })}
                type="text"
                placeholder="Enter"
                defaultValue={groupName}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.labelText}>
              {/* Changed text for issue #290 */}
              <p>Status: </p>
              <p
                className={clsx({
                  [classes.activeStatus]: isGroupActive,
                  [classes.deactivated]: !isGroupActive
                })}
              >
                {isGroupActive ? "ACTIVE" : "DEACTIVATED"}
              </p>
              {/* // ! commented out until we have the update time and date of deactivation */}
              {/* <p>{isGroupActive ? null : "10 Jul 2020 | 2:00 pm"}</p> */}
            </div>
            {/* <div className={classes.inputGroup}>
              <span>Shared with customer(s)</span>
              <button className={classes.selectButtons}>Select</button>
            </div> */}
          </Grid>
          <Grid item xs={12} className={classes.updateContainer}>
            <Button
              type="button"
              color="white"
              style={{ margin: "0 1rem 0 0" }}
              onClick={() => {
                returnToParent();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="defaultTheme"
              style={{ margin: "0 1rem 0 0" }}
            >
              Save updates
            </Button>
          </Grid>
        </Grid>
      </form>
      <SuccessModal />
      {/* imported for issue #213 */}
      <UnsuccessModal />
    </>
  );
};

export default GroupsDisplay;
