/*Issue fixed for github issue #66,issue #204 */
import React, { useState, useEffect } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { SignUpProps } from "./_types";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { validateEmail } from "utils/helpers";
import eraserIcon from "assets/bt-logins/Login_erase.svg";
import clsx from "clsx";
import { Colors } from "common/Colors";

const SignUpStepTwo: React.FC<SignUpProps> = ({
  setLogType,
  onStateChange
}: SignUpProps) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [device, setDevice] = useState(false);

  useEffect(() => {
    if (email.length === 0 || validateEmail(email)) {
      setEmailValid(true);
    }
  }, [email]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <form className={classes.formStyles} noValidate autoComplete="off">
        <div className={classes.accountVerifiedNotice}>
          Account verification completed!
        </div>
        {/* Updated color to slate for issue #66 */}
        <hr style={{ border: "1px solid  #253746", width: "100%" }} />
        <div className={classes.createDeviceLink}>
          If you are a member of an existing Basintrak customer, please enter
          any one device ID, to link your account with them.
        </div>
        <div className={classes.inputField}>
          <div className={classes.inputName}>
            <span>
              Device ID <span style={{ color: "#ED254E" }}>*</span>
            </span>
            <span className={classes.warnings}>
              {!emailValid ? "Not a valid email" : null}
            </span>
          </div>
          <div className={classes.deviceInputContainer}>
            <TextField
              className={clsx({
                [classes.valid]: emailValid,
                [classes.invalid]: !emailValid
              })}
              classes={{
                root: classes.input
              }}
              value={email}
              placeholder="Enter any device ID"
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setEmail(e.target.value)}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={eraserIcon}
                      alt=""
                      style={{
                        margin: "0 1rem 0 0",
                        cursor: "pointer",
                        display: !device ? "none" : ""
                      }}
                      onClick={() => {
                        setEmail("");
                        setDevice(false);
                      }}
                    />
                  </InputAdornment>
                )
              }}
            />
            <button
              type="button"
              className={classes.linkDeviceBtn}
              // ! here once validations check, proceed to next sign up
              onClick={() => {
                setDevice(true);
              }}
            >
              Link
            </button>
          </div>
        </div>
        {!device ? (
          <>
            <p className={classes.contactInformation}>
              Please contact your organisation’s Basintrak admin for the
              details. Your access rights will be managed by your organisation’s
              admin.
            </p>
            <p className={classes.contactInformation}>
              If you cannot find locate your admin, feel free to send us an
              email with your name, address, phone number, organisation name and
              your designation. We will help you locate the admin.
            </p>
            <p className={classes.createNewAccount}>
              If you are creating a new account, please click{" "}
              <span
                className={classes.signInReroute}
                onClick={() => setLogType("logIn")}
              >
                here
              </span>
              .
            </p>
          </>
        ) : (
          <>
            <div className={classes.deviceInformation}>
              ACME Corporation, Denver, CO
            </div>
            <p>If this is correct, please proceed!</p>
            <button
              type="button"
              className={classes.proceedBtn}
              onClick={() => {
                setLogType("logIn");
              }}
            >
              Proceed
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default SignUpStepTwo;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStyles: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      margin: "0 10% 0 10%"
    },
    accountVerifiedNotice: {
      fontSize: 24,
      fontWeight: 600,
      color: Colors.MainColor //changed color to slate for issue #66
    },
    createDeviceLink: {
      fontSize: "14px",
      width: "50%",
      margin: "2rem 0"
    },
    inputName: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    inputField: { margin: "2rem 0 1rem 0" },
    warnings: {
      fontSize: "12px",
      color: "#ED254E",
      position: "relative",
      top: "5px"
    },
    input: {
      display: "flex",
      justifyContent: "center",
      borderRadius: 0,
      color: "white",
      width: 345,
      height: 40,
      padding: "0 0 0 1rem",
      border: "solid 1px DD",
      margin: "1rem 0"
    },
    linkDeviceBtn: {
      backgroundColor: Colors.MainColor, //updated color from #6D1919 to #253746 for issue #204
      width: 90,
      height: 40,
      fontSize: "14px",
      fontWeight: 600,
      color: "#fff",
      border: "none",
      borderRadius: 0,
      margin: "1rem",
      "&:hover": {
        cursor: "pointer",
        opacity: ".8"
      }
    },
    valid: {
      border: "solid 1px #DDDDDD"
    },
    invalid: {
      border: "solid 1px #ED254D"
    },
    deviceInputContainer: {
      display: "flex",
      flexDirection: "row"
    },
    contactInformation: {
      fontSize: 14,
      width: "50%",
      margin: "1rem 0",
      color: "#A6A6A6"
    },
    createNewAccount: {
      fontSize: 14,
      fontWeight: 500,
      color: "#000"
    },
    signInReroute: {
      color: Colors.MainColor, //changed color to slate for issue #66
      cursor: "pointer"
    },
    deviceInformation: {
      fontSize: 18,
      fontWeight: 600
    },
    proceedBtn: {
      backgroundColor: Colors.MainColor, //changed color to slate for issue #66
      width: 90,
      height: 40,
      fontSize: "14px",
      fontWeight: 600,
      color: "#fff",
      border: "none",
      borderRadius: 0,
      "&:hover": {
        cursor: "pointer",
        opacity: ".8"
      }
    }
  })
);
