import React, { useState, useCallback, useEffect } from "react";
import App from "./App";
import LoginContainer from "../features/LoginContainer/LoginContainer";
import {
  AmplifyAuthenticator,
  AmplifyVerifyContact
} from "@aws-amplify/ui-react";
import {
  AuthState,
  UI_AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT
} from "@aws-amplify/ui-components";
import { Auth, Hub } from "aws-amplify";
import VerifyEmail from "components/verify-email/VerifyEmail";
import { useAppDispatch, resetReduxAction } from "./store";

const AppWithAuth: React.FC = () => {
  const dispatch = useAppDispatch();

  const [authState, setAuthState] = useState<AuthState>(AuthState.SignIn);
  const [emailVerified, setEmailVerified] = useState(false);

  const verificationCallback = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      Hub.dispatch(UI_AUTH_CHANNEL, {
        event: AUTH_STATE_CHANGE_EVENT,
        message: AuthState.SignedIn,
        data: user
      });
      setEmailVerified(true);
      // window.location.reload(); // fallback workaround: email now verified, but user's cognito session/JWT data is stale
    } catch (err) {
      await Auth.signOut();
      localStorage.clear();
      dispatch(resetReduxAction);
      alert("Verification success callback error."); // just in (edge) case
    }
  }, [dispatch]);

  const returnLoginApp = () => {
    switch (authState) {
      case AuthState.SignedIn:
        if (emailVerified) {
          return <App />;
        } else {
          return <VerifyEmail onSuccess={verificationCallback} />;
        }
      case AuthState.VerifyContact:
        // should not ever get to this state due to handler below
        break;
      case AuthState.SignIn:
        return (
          <div slot="sign-in" style={{ display: "flex", width: "100vw" }}>
            <LoginContainer />
          </div>
        );
      default:
        break;
    }
  };

  const authStateHandler = useCallback(async (authState: AuthState) => {
    if (authState === AuthState.SignedIn) {
      Auth.currentAuthenticatedUser({ bypassCache: true }).then(info => {
        const verified = (info.attributes.email_verified as boolean) || false;
        setEmailVerified(verified);
        setAuthState(authState);
      });
    } else if (authState === AuthState.VerifyContact) {
      setEmailVerified(false);
      Hub.dispatch(UI_AUTH_CHANNEL, {
        event: AUTH_STATE_CHANGE_EVENT,
        message: AuthState.SignedIn,
        data: await Auth.currentAuthenticatedUser()
      });
    } else {
      setAuthState(authState);
    }
  }, []);

  useEffect(() => {
    Hub.listen("auth", data => {
      if (data.payload.event === "signOut") {
        setEmailVerified(false);
      }
    });
  }, []);

  return (
    <AmplifyAuthenticator
      // style={{ display: "flex", width: "100%" }}
      handleAuthStateChange={authStateHandler}
    >
      {returnLoginApp()}
      <AmplifyVerifyContact
        slot="verify-contact"
        hidden // prevents a "flash render" before custom component loads
      />
    </AmplifyAuthenticator>
    // <App />
  );
};

export default AppWithAuth;
