/* Issue fixed for github issue #54, issue #82, issue #140,issue #219 */
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  BarChart,
  Bar,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";
import { ParameterProps } from "features/real-time-status-page/types";
import { displayColors } from "utils/displayDashChartColors";
import { Colors } from "common/Colors";
import { binaryFromJsonList } from "utils/binaryToText";
import { numberToTextKey } from "utils/constants";
import { binaryToTextKey } from "utils/constants";
import { getDecodedVal } from "utils/conversions";

interface VeritcalBarChartProps {
  params: ParameterProps;
}

const VeritcalBarChart: React.FC<VeritcalBarChartProps> = ({
  params
}: VeritcalBarChartProps) => {
  const styles = useStyles();
  const max = params.max_value;
  const hihi = params.high_high_value;
  const hi = params.high_value;
  const lo = params.low_value;
  const lolo = params.low_low_value;
  const min = params.min_value;
  const param = params.parameter_value;

  const valParam = param > max ? max : param < min ? min : param;

  // Convert data to percent
  const pcent_hi_max = (100 * (max - hihi)) / (max - min);
  const pcent_hi_hihi = (100 * (hihi - hi)) / (max - min);
  const pcent_lo_hi = (100 * (hi - lo)) / (max - min);
  const pcent_lolo_lo = (100 * (lo - lolo)) / (max - min);
  const pcent_min_lolo = (100 * (lolo - min)) / (max - min);

  const {
    low_low_to_low_color,
    min_to_low_low_color,
    low_to_high_color,
    high_high_to_max_color,
    high_to_high_high_color
  } = params;

  const COLORS = [
    min_to_low_low_color,
    low_low_to_low_color,
    low_to_high_color,
    high_to_high_high_color,
    high_high_to_max_color
  ];

  const data = [
    {
      name: "idx",
      // Changed denominator from max to max-min for all formulae for issue #82
      // updated formulae for issue #219
      // hi_max: Math.round(pcent_hi_max),
      hi_max: Math.min(
        100 -
        (Math.round(pcent_min_lolo) +
          Math.round(pcent_hi_hihi) +
          Math.round(pcent_lo_hi) +
          Math.round(pcent_lolo_lo)
        ),
        100
      ),
      hi_hihi: Math.round(pcent_hi_hihi),
      lo_hi: Math.round(pcent_lo_hi),
      lolo_lo: Math.round(pcent_lolo_lo),
      // min_lolo: (100 * (lolo - min)) / (max - min),
      //modified the formulae so that total doesn't exceed 100
      min_lolo: Math.max(
        100 -
        (Math.round(pcent_hi_max) +
          Math.round(pcent_hi_hihi) +
          Math.round(pcent_lo_hi) +
          Math.round(pcent_lolo_lo)),
        0
      ),
      val: Math.round((100 * (valParam - min)) / (max - min))
    }
  ];
  console.log("data: ", data);
  const percentLabel = (props: any) => {
    const { x, y, width, height, value, name } = props;
    const offset = height - 5;

    return (
      <>
        <text
          // Changed the x and y coordinates of text from offset to percent values for issue #140
          x={"50%"}
          y={"52%"}
          textAnchor="middle"
          dominantBaseline="middle"
          fill={Colors.Black}
        >
          {/* Added a function call to show the value of the parameter and its unit inside the chart. Github issue #54 */}
          {getDecodedVal(
            params,
            Number(params.parameter_value),
            numberToTextKey,
            binaryToTextKey,
            binaryFromJsonList
          )}
          {params.unit}
        </text>
      </>
    );
  };

  const CustomBackground = (props: any) => {
    const { x, y, width, height } = props;
    const roundedTopRadius = 3;
    const radius = 3;

    return (
      <>
        {/* Top rounded rectangle */}
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill="#dfdfdf"
          rx={roundedTopRadius}
          ry={roundedTopRadius}
        />
        {/* Bottom rectangle */}
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill="#dfdfdf"
          rx={roundedTopRadius}
          ry={roundedTopRadius}
        />
      </>
    );
  };

  return (
    // Added div with height and width for chart so that it size itself according to screen resolution for issue #140
    <div
      style={{
        height: "min(125px,20vh)",
        minHeight: "max(100px,10vh)",
        width: "min(150px, 80%)",
        // maxWidth: "min(100px, 100%)"
      }}
    >
      <ResponsiveContainer>
        <BarChart
          // width={220}
          // height={50}
          layout={"vertical"}
          barCategoryGap={1}
          data={data}
          barGap={2}
          margin={{
            top: 0,
            bottom: 5
          }}
        >
          <XAxis type="number" hide />
          <YAxis type="category" hide />
          <Bar
            dataKey="min_lolo"
            stackId="a"
            barSize={10}
            fill={displayColors(COLORS[0])}
          />
          <Bar
            dataKey="lolo_lo"
            stackId="a"
            barSize={10}
            fill={displayColors(COLORS[1])}
          />
          <Bar
            dataKey="lo_hi"
            stackId="a"
            barSize={10}
            fill={displayColors(COLORS[2])}
          />
          <Bar
            dataKey="hi_hihi"
            stackId="a"
            barSize={10}
            fill={displayColors(COLORS[3])}
          />
          <Bar
            dataKey="hi_max"
            stackId="a"
            barSize={10}
            fill={displayColors(COLORS[4])}
          />
          <Bar
            dataKey="val"
            barSize={30}
            // background={{ fill: "#dfdfdf" }}
            background={<CustomBackground />}
            isAnimationActive={false}
            label={percentLabel}
            radius={[0, 3, 3, 0]}
          >
            {data.map(entry => {
              let color = "";
              if (
                entry.val >
                entry.hi_max + entry.lo_hi + entry.lolo_lo + entry.min_lolo
              ) {
                color = COLORS[4];
              } else if (
                entry.val >
                entry.lo_hi + entry.lolo_lo + entry.min_lolo
              ) {
                color = COLORS[3];
              } else if (entry.val > entry.lolo_lo + entry.min_lolo) {
                color = COLORS[2];
              } else if (entry.val > entry.min_lolo) {
                color = COLORS[1];
              } else if (entry.val >= 0) {
                color = COLORS[0];
              }
              return <Cell fill={displayColors(color)} />;
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const useStyles = makeStyles(theme => createStyles({}));

export default VeritcalBarChart;
