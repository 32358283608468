import React, { useState, useEffect, useCallback } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { formModalStyles } from "common/formModalStyles";
import alertCircle from "assets/bt-assets/alert-circle.svg";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
} from "components/success-modal/successModalSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { Colors } from "common/Colors";
import { InputLabel } from "components/input-label/InputLabel";
import Button from "components/common-buttons/Button";
import LoadSpinner from "common/LoadSpinner";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import { NewPasswordProps } from "./types";

// import clsx from "clsx";

type ChangePasswordModalProps = {
  open: boolean;
  handleClose: Function;
};

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  open,
  handleClose,
}) => {
  const classes = formModalStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const { register, handleSubmit, errors, getValues, setError } =
    useForm<NewPasswordProps>();

  const handlePasswordChange = async ({
    oldPassword,
    newPassword,
  }: NewPasswordProps) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then((data) => {
        handleClose();
        dispatch(setSuccessModalOpen(true));
        dispatch(setSuccessModalDescription("Password successfully updated!"));
      })
      .catch((err) => {
        setError("oldPassword", {
          type: "noMatch",
          message: "Does not match current password.",
        });
      });
  };

  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalOverlay}>
        {loading ? (
          <LoadSpinner />
        ) : (
          <div className={classes.modalBody}>
            <div className={classes.header}>
              <p className={classes.headerText}>Change password</p>
              <CloseIcon
                className={classes.icon}
                onClick={() => handleClose()}
              />
            </div>
            <form onSubmit={handleSubmit(handlePasswordChange)}>
              <div className={classes.inputBody}>
                <div className={classes.inputGroup}>
                  <InputLabel
                    label="Old password"
                    name="oldPassword"
                    errors={errors}
                  />
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      marginBottom: 14,
                    }}
                  >
                    <input
                      name="oldPassword"
                      ref={register({
                        required: "This field is required.",
                      })}
                      type={showOldPassword ? "text" : "password"}
                      placeholder="Enter"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: 10,
                        right: "1rem",
                        opacity: 0.8,
                        cursor: "pointer",
                      }}
                      onClick={() => setShowOldPassword((prevVal) => !prevVal)}
                    >
                      {showOldPassword ? "Hide" : "Show"}
                    </div>
                  </div>
                </div>
                <div className={classes.inputGroup} style={{ display: "flex" }}>
                  <div>
                    <img
                      src={alertCircle}
                      alt="Exclamation point"
                      width={14}
                      height={14}
                      style={{ margin: "2px 10px 0 0" }}
                    />
                  </div>
                  <p style={{ fontWeight: 400, opacity: 0.5 }}>
                    Password should have 12 characters or more, must include a
                    capital letter, a number and a special character.
                  </p>
                </div>
                <div className={classes.inputGroup}>
                  <InputLabel
                    label="New password"
                    name="newPassword"
                    errors={errors}
                  />
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      marginBottom: 14,
                    }}
                  >
                    <input
                      name="newPassword"
                      ref={register({
                        required: "This field is required.",
                        minLength: {
                          value: 12,
                          message: "Must have 12 or more characters.",
                        },
                        validate: {
                          hasCapital: (value) =>
                            /[A-Z]/g.test(value) ||
                            "Needs atleast one capital.",
                          hasNumber: (value) =>
                            /[0-9]/.test(value) || "Needs atleast one number.",
                          hasCharacter: (value) =>
                            /[!@#$%^&*(),.?":{}|<>]/.test(value) ||
                            "Needs atleast one special character.",
                        },
                      })}
                      type={showNewPassword ? "text" : "password"}
                      placeholder="Enter"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: 10,
                        right: "1rem",
                        opacity: 0.8,
                        cursor: "pointer",
                      }}
                      onClick={() => setShowNewPassword((prevVal) => !prevVal)}
                    >
                      {showNewPassword ? "Hide" : "Show"}
                    </div>
                  </div>
                </div>
                <div className={classes.inputGroup}>
                  <InputLabel
                    label="Confirm new password"
                    name="confirmPassword"
                    errors={errors}
                  />
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      marginBottom: 14,
                    }}
                  >
                    <input
                      name="confirmPassword"
                      ref={register({
                        required: "This field is required.",
                        validate: {
                          matchesNewPassword: (value) =>
                            value === getValues("newPassword") ||
                            "Passwords do not match.",
                        },
                      })}
                      type={showConfirmNewPassword ? "text" : "password"}
                      placeholder="Enter"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: 10,
                        right: "1rem",
                        opacity: 0.8,
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setShowConfirmNewPassword((prevVal) => !prevVal)
                      }
                    >
                      {showConfirmNewPassword ? "Hide" : "Show"}
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.actionItems}>
                <Button
                  type="button"
                  color="white"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" color="defaultTheme">
                  Update password
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
