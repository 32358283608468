/* Issue fixed for github issue #56 */
import React from "react";
import { ReactComponent as TanksIcon } from "assets/bt-equipments/ic_tank_g_big.svg";
import { ReactComponent as CompressorIcon } from "assets/bt-equipments/ic_compressor_r_big.svg";
import { ReactComponent as PumpsIcon } from "assets/bt-equipments/ic_pump_r_big.svg";
import { ReactComponent as VariableSpeedDriveIcon } from "assets/bt-equipments/ic_vsd_r_big.svg";
import { ReactComponent as GeneratorsIcon } from "assets/bt-equipments/ic_generator_r_big.svg";
import { ReactComponent as EnginesIcon } from "assets/bt-equipments/ic_engine_r_big.svg";
import { ReactComponent as ProcessorIcon } from "assets/bt-equipments/ic_processor_r_big.svg";
import { ReactComponent as RigLocksIcon } from "assets/bt-equipments/ic_riglock_r_big.svg";
import { ReactComponent as ChemicalInjectorsIcon } from "assets/bt-equipments/ic_chemical_injector_r_big.svg";
import { ReactComponent as OtherEquipmentIcon } from "assets/bt-equipments/ic_others_r_big.svg";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { SingleAssetProp } from "components/SingleAssetCard/types";
import { grabCurrentColor } from "utils/iconColorHandler";
import { AssetsProp, AssetProp } from "features/asset-drilldown/Types";

export type AssetStatusCategoryIconsProps = {
  asset: any;
  singleAsset: boolean;
  page: any; //page attribute added to identify the complex asset page. Github issue #56
  parameter: any; //parameter attribute added for getting the warning state of the complex asset. Github issue #56
};

export const AssetStatusCategoryIcons: React.FC<AssetStatusCategoryIconsProps> =
  ({ asset, singleAsset, page, parameter }) => {
    const classes = useStyles();

    switch (asset.asset_category_type.toLowerCase()) {
      case "compressor":
        return (
          <CompressorIcon
            className={page == "complexAsset" ? classes.complexIcons : classes.icons} //Added condition for css changes for complex level asset. Github issue #56
            style={{
              fill: grabCurrentColor(singleAsset, page == "complexAsset" ? parameter : asset), //Added condition for sending the parameters for complex asset. Github issue #56
            }}
          />
        );
      case "pumps":
        return (
          <PumpsIcon
            className={page == "complexAsset" ? classes.complexIcons : classes.icons} //Added condition for css changes for complex level asset. Github issue #56
            style={{
              fill: grabCurrentColor(singleAsset, page == "complexAsset" ? parameter : asset), //Added condition for sending the parameters for complex asset. Github issue #56
            }}
          />
        );
      case "tanks":
        return (
          <TanksIcon
            className={page == "complexAsset" ? classes.complexIcons : classes.icons} //Added condition for css changes for complex level asset. Github issue #56
            style={{
              fill: grabCurrentColor(singleAsset, page == "complexAsset" ? parameter : asset), //Added condition for sending the parameters for complex asset. Github issue #56
            }}
          />
        );
      case "variable speed drive":
        return <VariableSpeedDriveIcon className={page == "complexAsset" ? classes.complexIcons : classes.icons} />; //Added condition for css changes for complex level asset. Github issue #56
      case "generators":
        return (
          <GeneratorsIcon
            className={page == "complexAsset" ? classes.complexIcons : classes.icons} //Added condition for css changes for complex level asset. Github issue #56
            style={{
              fill: grabCurrentColor(singleAsset, page == "complexAsset" ? parameter : asset), //Added condition for sending the parameters for complex asset. Github issue #56
            }}
          />
        );
      case "engines":
        return (
          <EnginesIcon
            className={page == "complexAsset" ? classes.complexIcons : classes.icons} //Added condition for css changes for complex level asset. Github issue #56
            style={{
              fill: grabCurrentColor(singleAsset, page == "complexAsset" ? parameter : asset), //Added condition for sending the parameters for complex asset. Github issue #56
            }}
          />
        );
      case "processors":
        return (
          <ProcessorIcon
            className={page == "complexAsset" ? classes.complexIcons : classes.icons} //Added condition for css changes for complex level asset. Github issue #56
            style={{
              fill: grabCurrentColor(singleAsset, page == "complexAsset" ? parameter : asset), //Added condition for sending the parameters for complex asset. Github issue #56
            }}
          />
        );
      case "rig locks":
        return (
          <RigLocksIcon
            className={page == "complexAsset" ? classes.complexIcons : classes.icons} //Added condition for css changes for complex level asset. Github issue #56
            style={{
              fill: grabCurrentColor(singleAsset, page == "complexAsset" ? parameter : asset), //Added condition for sending the parameters for complex asset. Github issue #56
            }}
          />
        );
      case "chemical injectors":
        return (
          <ChemicalInjectorsIcon
            className={page == "complexAsset" ? classes.complexIcons : classes.icons} //Added condition for css changes for complex level asset. Github issue #56
            style={{
              fill: grabCurrentColor(singleAsset, page == "complexAsset" ? parameter : asset), //Added condition for sending the parameters for complex asset. Github issue #56
            }}
          />
        );
      case "other equipment":
        return (
          <OtherEquipmentIcon
            className={page == "complexAsset" ? classes.complexIcons : classes.icons} //Added condition for css changes for complex level asset. Github issue #56
            style={{
              fill: grabCurrentColor(singleAsset, page == "complexAsset" ? parameter : asset), //Added condition for sending the parameters for complex asset. Github issue #56
            }}
          />
        );
    }

    return (
      <TanksIcon
        className={page == "complexAsset" ? classes.complexIcons : classes.icons} //Added condition for css changes for complex level asset. Github issue #56
        style={{
          fill: grabCurrentColor(singleAsset, page == "complexAsset" ? parameter : asset), //Added condition for sending the parameters for complex asset. Github issue #56
        }}
      />
    );
  };

export default AssetStatusCategoryIcons;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icons: {
      fill: theme.palette.primary.light,
    },
    //Added class for changing the height and width for complex level asset. Github issue #56
    complexIcons: {
      fill: theme.palette.primary.light,
      height: "28px",
      width: "28px",
    }
  })
);
