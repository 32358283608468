import { BarInformationProps } from "components/AssetStatusCard/types";

export const handleActiveHourBar = (barInformation: BarInformationProps) => {
  let radius: number[] = [10, 0, 0, 10];
  const { overAnHour, overADay, overAWeek } = barInformation;
  let otherBarsActive = overAnHour + overADay + overAWeek;

  if (!otherBarsActive) {
    radius = [10, 10, 10, 10];
  }

  return radius;
};

export const handleActiveOverHourBar = (
  barInformation: BarInformationProps
) => {
  let radius: number[] = [10, 10, 10, 10];
  const { lessThanHour, overADay, overAWeek } = barInformation;
  let otherBarsActive = overADay + overAWeek;

  if (lessThanHour && !otherBarsActive) {
    radius = [0, 10, 10, 0];
  }

  if (!lessThanHour && otherBarsActive) {
    radius = [10, 0, 0, 10];
  }

  if (lessThanHour && otherBarsActive) {
    radius = [0, 0, 0, 0];
  }

  return radius;
};

export const handleActiveOverADay = (barInformation: BarInformationProps) => {
  let radius: number[] = [10, 10, 10, 10];
  const { lessThanHour, overAnHour, overAWeek } = barInformation;
  let otherBarsActive = lessThanHour + overAnHour;

  if (overAWeek && !otherBarsActive) {
    radius = [10, 0, 0, 10];
  }

  if (!overAWeek && otherBarsActive) {
    radius = [0, 10, 10, 0];
  }

  if (overAWeek && otherBarsActive) {
    radius = [0, 0, 0, 0];
  }

  return radius;
};

export const handleActiveOverWeekBar = (
  barInformation: BarInformationProps
) => {
  let radius: number[] = [0, 10, 10, 0];
  const { lessThanHour, overAnHour, overADay } = barInformation;
  let otherBarsActive = lessThanHour + overAnHour + overADay;

  if (!otherBarsActive) {
    radius = [10, 10, 10, 10];
  }

  return radius;
};
