const subscriptionOptions = [
  { value: 1, label: "Demo" },
  { value: 2, label: "Basic" },
  { value: 3, label: "Enhanced" },
  { value: 4, label: "Complete" },
  { value: 0, label: "Disabled" },
];

export const getSubscriptionLabel = (level: number) => {
  const match = subscriptionOptions.find((op) => op.value === level);
  if (match) {
    return match.label;
  }
  return "N/A";
};

export default subscriptionOptions;
