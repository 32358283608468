import axios, { AxiosRequestConfig } from "axios";
import { Auth } from "aws-amplify";

// Add a request interceptor
const axiosReqAddConfig = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  try {
    const idToken = (await Auth.currentSession()).getIdToken();
    // console.log("ID TOKEN from Auth.currentSession: ", idToken);
    config.headers["Authorization"] = idToken ? idToken.getJwtToken() : "";
  } catch (error) {
    console.log("error attaching authorization token", error);
  }
  return config;
};

axios.interceptors.request.use(axiosReqAddConfig);
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";

export enum Method {
  POST = "POST",
  PUT = "PUT",
  GET = "GET",
  DELETE = "DELETE",
}

// ! set DEV or QA to return correct url
export const URL = () => {
  return process.env.REACT_APP_API_URL;
};

/**
 * Axios wrapper with full API support
 * @param {object}
 * Object for Axios configuration
 * - data = POST "body" section
 */

export const axiosCall = async (
  method: "GET" | "POST" | "PUT" | "DELETE",
  url: string,
  data?: string | object,
  headers?: object
) => {
  return axios({
    method,
    url,
    data,
    headers,
  });
};
