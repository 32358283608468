/*Issue fixed for github issue #213 */
import React, { useEffect, useState, useCallback } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import search from "assets/bt-assets/header_search_w.svg";
import { useTheme } from "@material-ui/core/styles";
import { AssetSettingsPopOver } from "./AssetSettingsPopOver";
import { categoryIcon } from "utils/helpers";
import { InputLabel } from "components/input-label/InputLabel";
import { useFieldArray, useForm } from "react-hook-form";
import SuccessModal from "components/success-modal/SuccessModal";
import { setAssetSettingsName } from "features/assets-page/assetCategorySlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
} from "components/success-modal/successModalSlice";
import { setCallCategoryList } from "features/assets-page/assetCategorySlice";
import DeleteAssetCategory from "components/asset-modals/DeleteAssetCategory";
import AssetAPI from "services/assetsAPI";
import { CategoryObject } from "./types";
import clsx from "clsx";
import { setSubPageActive } from "features/accounts-page/accountSlice";
import LoadSpinner from "common/LoadSpinner";
import { RequiredAssetCategoriesProps } from "components/asset-modals/types";
import AssetCategoryIcons from "components/category-icons/AssetCategoryIcons";
import Button from "components/common-buttons/Button";
import Xsquare from "assets/bt-assets/x-square.svg";
import UnsuccessModal from "components/success-modal/UnsuccessfullModal"; //imported for issue #213

type AssetSettingsProp = {
  handleAssetPage: () => void;
};

const defaultPropertyObject = {
  property_1: "",
  property_2: "",
  property_3: "",
  property_4: "",
  property_5: "",
  property_6: "",
  property_7: "",
  property_8: "",
  property_9: "",
  property_10: "",
};

const AssetsSettings = ({ handleAssetPage }: AssetSettingsProp) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<number>(0);
  const [showDeleteCategory, setShowDeleteCategory] = useState(false);
  const [assetCategoryList, setAssetCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [assetCategoryName, setAssetCategoryName] = useState("");
  const [assetCategoryHeader, setAssetCategoryHeader] = useState("");
  const [assetCategoryType, setAssetCategoryType] = useState("");
  const [assetCategoryId, setAssetCategoryId] = useState("");
  const [assetCategoryIcon, setAssetCategoryIcon] = useState("");
  const [assetCategoryProperties, setAssetCategoryProperties] = useState({});
  const [assetCount, setAssetCount] = useState(1);
  const [loading, setLoading] = useState(true);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const { register, handleSubmit, errors, control, reset } =
    useForm<RequiredAssetCategoriesProps>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const callCategoryList = useSelector(
    (state: RootState) => state.AssetCategorySlice.callCategoryList
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleCategory = (event: any, categoryId: string) => {
    event.preventDefault();
    setCategoryId(categoryId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const closeDeleteDeviceModal = () => {
    setShowDeleteCategory(false);
  };

  const handleAssetCategorySave = async ({
    assetCategoryName,
    items,
  }: RequiredAssetCategoriesProps) => {
    const updatedPropertyObject = items
      ? items
        .map((propertyObject: { name: string }, index: number) => ({
          [`property_${index + 1}`]: propertyObject.name,
        }))
        .reduce((accum: {}, propertyObject: any) => {
          accum = {
            ...accum,
            ...propertyObject,
          };

          return accum;
        }, {})
      : {};

    // console.log("updatedPropertyObject", updatedPropertyObject);

    const assetCategoryObject = {
      asset_category_id: assetCategoryId,
      category_name: assetCategoryName,
      category_type: assetCategoryType,
      category_icon: assetCategoryIcon,
      property_names: { ...defaultPropertyObject, ...updatedPropertyObject },
    };

    // console.log("assetCategoryObject", assetCategoryObject);
    try {
      await AssetAPI.updateAssetCategory(assetCategoryObject);
      handleAssetCategoryInformation(assetCategoryId);
      dispatch(
        setSuccessModalDescription("Asset category successfully saved!")
      );
      dispatch(setSuccessModalOpen(true));
      getAssetCategoryList();
      // setAssetCategoryHeader(assetCategoryName);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const handleAssetCategoryInformation = useCallback(
    async (selectedAssetCategoryId: string) => {
      setAssetCategoryId(selectedAssetCategoryId);
      try {
        setCategoryLoading(true);
        const assetCategoryObject = await AssetAPI.retrieveAssetCategoryProfile(
          selectedAssetCategoryId
        );
        setAssetCategoryHeader(assetCategoryObject.category_name);
        setAssetCategoryName(assetCategoryObject.category_name);
        setAssetCategoryType(assetCategoryObject.category_type);
        setAssetCategoryIcon(assetCategoryObject.category_icon);
        setAssetCount(assetCategoryObject.asset_count);
        setAssetCategoryProperties(assetCategoryObject.property_names);
        setCategoryLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const getAssetCategoryList = useCallback(async () => {
    try {
      const assetCategoryList = await AssetAPI.getAssetCategoryList();
      setAssetCategoryList(assetCategoryList);

      handleAssetCategoryInformation(assetCategoryList[0]?.asset_category_id);
      setLoading(false);
    } catch (error) {
      alert(error);
    }
  }, [handleAssetCategoryInformation]);

  useEffect(() => {
    const propertyKeys = Object.entries(assetCategoryProperties)
      .map((propertyObject: any) => ({
        name: `Asset property ${parseInt(propertyObject[0].slice(9))}`,
        value: propertyObject[1],
        id: parseInt(propertyObject[0].slice(9)),
      }))
      .sort((a: any, b: any) => a.id - b.id)
      .filter(
        (property: { name: string; value: string; id: number }) =>
          property.value !== ""
      );

    setTimeout(() => {
      reset({
        items: propertyKeys,
      });
    }, 1.5);
  }, [assetCategoryProperties, reset]);

  useEffect(() => {
    getAssetCategoryList();
  }, [getAssetCategoryList]);

  useEffect(() => {
    if (callCategoryList) {
      getAssetCategoryList();
      setAssetCategoryHeader("");
      dispatch(setCallCategoryList(false));
    }
  }, [callCategoryList, getAssetCategoryList, dispatch]);

  return loading ? (
    <LoadSpinner center={true} size="85vh" />
  ) : assetCategoryList.length ? (
    <>
      <Grid item xs={4}>
        <div className={classes.fileContainer}>
          {/* <div className={classes.search}>
            <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              placeholder="Search for group or asset"
            />
            <div className={classes.searchbutton}>
              <img src={search} alt="" />
            </div>
          </div> */}
          <div className={classes.categoryContainer}>
            {assetCategoryList.map(
              (categoryObject: CategoryObject, index: number) => (
                <div
                  key={index}
                  className={clsx(classes.category, {
                    [classes.selected]: selected === index,
                  })}
                  onClick={() => {
                    setSelected(index);
                    if (assetCategoryId === categoryObject.asset_category_id) {
                      return;
                    } else {
                      handleAssetCategoryInformation(
                        categoryObject.asset_category_id
                      );
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    id={`${index}`}
                  >
                    {/* <img
                      src={categoryIcon(categoryObject.category_icon)}
                      alt=""
                      style={{
                        margin: "0 .5rem 0 0",
                        position: "relative",
                        bottom: "1px",
                        color: "black",
                      }}
                    /> */}
                    <AssetCategoryIcons
                      assetType={categoryObject.category_icon}
                    />
                    {categoryObject.category_name}
                  </div>
                  <div
                    onClick={(event) =>
                      handleCategory(event, categoryObject.asset_category_id)
                    }
                    style={{ display: "flex" }}
                  >
                    {/*more-vertical@2x.svg*/}
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.5 3.75C7.5 2.92157 8.17157 2.25 9 2.25C9.82843 2.25 10.5 2.92157 10.5 3.75C10.5 4.57843 9.82843 5.25 9 5.25C8.17157 5.25 7.5 4.57843 7.5 3.75Z"
                        fill={theme.palette.primary.light}
                      />
                      <path
                        d="M7.5 9C7.5 8.17157 8.17157 7.5 9 7.5C9.82843 7.5 10.5 8.17157 10.5 9C10.5 9.82843 9.82843 10.5 9 10.5C8.17157 10.5 7.5 9.82843 7.5 9Z"
                        fill={theme.palette.primary.light}
                      />
                      <path
                        d="M9 12.75C8.17157 12.75 7.5 13.4216 7.5 14.25C7.5 15.0784 8.17157 15.75 9 15.75C9.82843 15.75 10.5 15.0784 10.5 14.25C10.5 13.4216 9.82843 12.75 9 12.75Z"
                        fill={theme.palette.primary.light}
                      />
                    </svg>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <AssetSettingsPopOver
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            categoryId={categoryId}
          />
          <SuccessModal />
          {/* imported for issue #213 */}
          <UnsuccessModal />
        </div>
      </Grid>
      <Grid item xs={8}>
        <div className={classes.assetContainer}>
          {categoryLoading ? (
            <LoadSpinner center={true} size="65vh" />
          ) : (
            <>
              <div className={classes.pathHeader}>
                {assetCategoryHeader && (
                  <span>
                    {assetCategoryHeader} - Used for{" "}
                    <span
                      className={classes.genericColor}
                      onClick={() => console.log(" ")}
                    >
                      {assetCount} asset(s)
                    </span>
                  </span>
                )}
              </div>
              <div className={classes.buttonContainer}>
                {assetCategoryHeader ? (
                  <button
                    className={clsx(classes.deleteAssetBtn, {
                      [classes.disabledAssetBtn]: assetCount !== 0,
                    })}
                    type="button"
                    disabled={assetCount !== 0}
                    onClick={() => {
                      setShowDeleteCategory(true);
                      setCategoryId(assetCategoryId);
                    }}
                  >
                    Delete asset category
                  </button>
                ) : null}
              </div>
              <form onSubmit={handleSubmit(handleAssetCategorySave)}>
                <Grid container className={classes.assetsInputContainer}>
                  {assetCategoryHeader ? (
                    <>
                      <Grid item xs={6} className={classes.inputsContainer}>
                        <div className={classes.inputGroup}>
                          <InputLabel
                            label="Asset category name"
                            name="assetCategoryName"
                            errors={errors}
                          />
                          <input
                            name="assetCategoryName"
                            ref={register({
                              required: "This field is required.",
                            })}
                            type="text"
                            placeholder="Enter"
                            defaultValue={assetCategoryName}
                          />
                        </div>
                        <div
                          className={classes.inputGroup}
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <InputLabel
                            label="Asset Category type"
                            name="assetCategoryType"
                            errors={errors}
                            isRequired={false}
                          />
                          :
                          <span style={{ marginLeft: "1rem", fontWeight: 600 }}>
                            {assetCategoryType}
                          </span>
                        </div>
                        <div className={classes.inputGroup}>
                          {/* <span>
                  Category icon
                  <span style={{ color: "#ED254E" }}>*</span>
                </span> */}
                          {/* <ImageUploader
                  // {...props}
                  withIcon={true}
                  onChange={onDrop}
                  imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                  maxFileSize={5242880}
                /> */}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className={classes.assetPropertyInputsContainer}
                      >
                        {fields.map(({ id, name, value }, index) => {
                          return (
                            <div key={id} className={classes.inputGroup}>
                              <InputLabel
                                label={`Asset property ${index + 1}`}
                                name={`items[${index}].name`}
                                errors={errors}
                                style={{ marginRight: "2.5rem" }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="text"
                                  placeholder="Enter"
                                  name={`items[${index}].name`}
                                  ref={register({
                                    required: "Enter a property name.",
                                  })}
                                  defaultValue={value}
                                />
                                <img
                                  src={Xsquare}
                                  alt="Delete asset property"
                                  className={classes.faIcon}
                                  style={{
                                    marginLeft: "1em",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => remove(index)}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                {assetCategoryHeader ? (
                  <div
                    className={classes.buttonContainer}
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>
                      <Button
                        type="button"
                        color="defaultTheme"
                        style={
                          fields.length === 10
                            ? { opacity: 0.8, pointerEvents: "none" }
                            : { cursor: "pointer" }
                        }
                        onClick={() => append({})}
                      // disabled={fields.length === 10}
                      >
                        + Add property
                      </Button>
                    </div>
                    <div className={classes.buttonContainer}>
                      <Button
                        type="button"
                        color="white"
                        style={{ margin: "0 1rem 0 0" }}
                        onClick={() => {
                          dispatch(setAssetSettingsName(""));
                          dispatch(setSubPageActive(false));
                          handleAssetPage();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button type="submit" color="defaultTheme">
                        Save changes
                      </Button>
                    </div>
                  </div>
                ) : null}
              </form>
            </>
          )}
        </div>
      </Grid>
      {showDeleteCategory && (
        <DeleteAssetCategory
          closeModal={closeDeleteDeviceModal}
          categoryId={categoryId || assetCategoryId}
        />
      )}
    </>
  ) : (
    <div className={classes.noAssetContainer}>
      No asset categories to display
    </div>
  );
};

export default AssetsSettings;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileContainer: {
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    genericColor: {
      color: theme.palette.primary.light,
    },
    categoryContainer: {
      // ! commented out until search component is implemented
      // margin: "2rem 0 0 0",
    },
    noAssetContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "85vh",
      width: "100%",
    },
    assetsInputContainer: {
      padding: "1rem 0 1rem 2rem",
      height: 585,
    },
    faIcon: {
      color: theme.palette.primary.light,
      backgroundColor: "transparent",
    },
    category: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: ".5rem",
      fontSize: "16px",
      color: "#000",
      cursor: "pointer",
    },
    selected: {
      backgroundColor: Colors.LightGray,
      borderRadius: "25px 0 0 25px",
    },
    ellipsis: {
      cursor: "pointer",
      color: theme.palette.primary.light,
      height: "2rem",
      zIndex: 10,
    },
    assetContainer: {
      border: `2px solid ${Colors.LightGray}`,
      borderRight: "none",
      borderBottom: "none",
    },
    pathHeader: {
      backgroundColor: Colors.LightGray,
      padding: ".5rem",
      width: "100%",
      height: 30,
    },
    assetInformation: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      fontWeight: 600,
      padding: "2rem 0",
      border: `2px solid ${Colors.LightGray}`,
      borderRight: "none",
      borderLeft: "none",
    },
    assetsGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    value: {
      fontSize: 24,
    },
    buttonContainer: {
      // width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      margin: "1rem 0 0 1rem",
    },
    cancelButtons: {
      border: `1px solid ${theme.palette.primary.light}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      backgroundColor: "#fff",
      width: 150,
      height: 30,
      color: theme.palette.primary.light,
      cursor: "pointer",
    },
    deleteAssetBtn: {
      border: `1px solid ${theme.palette.primary.light}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      backgroundColor: "#fff",
      width: 150,
      height: 30,
      color: theme.palette.primary.light,
      cursor: "pointer",
      margin: "0 1rem",
    },
    disabledAssetBtn: {
      border: `1px solid ${Colors.LightGray}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      backgroundColor: "#fff",
      width: 150,
      height: 30,
      color: Colors.LightGray,
      cursor: "pointer",
      margin: "0 1rem",
    },
    addNewPropBtn: {
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      backgroundColor: theme.palette.primary.light,
      width: 150,
      height: 30,
      color: "#fff",
      cursor: "pointer",
    },
    saveButtons: {
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      backgroundColor: theme.palette.primary.light,
      width: 150,
      height: 30,
      color: "#fff",
      cursor: "pointer",
    },
    search: {
      display: "flex",
      flexDirection: "row",
    },
    searchIcon: {
      height: "100%",
      position: "absolute",
    },
    searchbutton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      cursor: "pointer",
      backgroundColor: `${theme.palette.primary.light}`,
      width: 45,
    },
    inputRoot: {
      border: "1px solid #DDD",
      borderRadius: 0,
      width: "100%",
      backgroundColor: "#fff",
    },
    inputInput: {
      paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
      width: "100%",
    },
    inputsContainer: {
      maxHeight: "500px",
    },
    assetPropertyInputsContainer: {
      maxHeight: 550,
      overflowY: "auto",
    },
    inputGroup: {
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem",
      },
      "& input": {
        width: "100%",
        padding: ".7rem 1rem",
        border: `1px solid ${Colors.LightGray}`,
      },
      "& select": {
        width: "100%",
        padding: ".7rem 1rem",
        border: `1px solid ${Colors.LightGray}`,
        cursor: "pointer",
      },
    },
    maxPropertiesWarning: {
      fontSize: "14px",
      fontWeight: 600,
      color: theme.palette.primary.light,
    },
  })
);
