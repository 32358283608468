/* Issue fixed for github issue #94,issue #291 */
import React from "react";
import { useStyles } from "./styles";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import {
  setSuccessDeviceOpen,
  setDeleteDeviceModalDescription
} from "features/devices-page/device-components/device-modal/deviceModalSlice";
import { setRecallDeviceList } from "features/devices-page/devices-table/deviceTableSlice";
import {
  setDeviceSettingsActive,
  setCurrentDeviceId,
  setSavedConfigurationedActive
} from "features/drawer/drawerSlice";
import Button from "components/common-buttons/Button";
import { useHistory } from "react-router-dom";
import { devicesAPI } from "services/devicesAPI";

type DeleteDeviceModalProps = {
  deviceId: string | number;
  fromPopOver: boolean;
  showDeleteDeviceModal: boolean;
  closeModals: Function;
};

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const DeleteDeviceModal: React.FC<DeleteDeviceModalProps> = ({
  deviceId,
  fromPopOver,
  showDeleteDeviceModal,
  closeModals
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const cancelAdd = () => {
    closeModals();
  };

  const deleteDevice = async () => {
    try {
      if (fromPopOver) {
        //Receiving the response from delete device API for issue #94
        const msgResponse = await devicesAPI.deleteDevice(String(deviceId));
        //Dispatching the response for issue #94
        dispatch(setDeleteDeviceModalDescription(msgResponse.data));
        dispatch(setRecallDeviceList(true));
        dispatch(setSuccessDeviceOpen(true));
        closeModals();
      } else {
        await devicesAPI.deleteDevice(String(deviceId));
        dispatch(setCurrentDeviceId(null));
        dispatch(setDeviceSettingsActive(false));
        dispatch(setSavedConfigurationedActive(false));
        history.push("/admin-devices");
      }
    } catch (error) {
      alert(error);
      dispatch(setSuccessDeviceOpen(false));
      closeModals();
    }
  };

  return showDeleteDeviceModal ? (
    <div className={styles.modalOverlay}>
      <div className={styles.modalBody}>
        <div className={styles.header}>
          {/* Changed color for delete device button for issue #291 */}
          <p className={styles.headerText} style={{ color: "#ed254e" }}>Remove device from account</p>
          <CloseIcon className={styles.icon} onClick={() => closeModals()} />
        </div>
        <div style={{ margin: "2rem 0 0 0" }}>
          <p className={styles.deleteDescription}>
            Are you sure you want to delete this device?
          </p>
          <p className={styles.deleteDescription}>
            If yes, all parameters and settings for this device will also be
            deleted permanently.
          </p>
        </div>
        <div className={styles.actionItems}>
          <Button color="white" type="button" onClick={cancelAdd}>
            Cancel
          </Button>
          {/* Changed color for delete device button for issue #291 */}
          <Button color="defaultTheme" type="button" style={{ backgroundColor: "#ed254e", border: "1px solid rgb(237, 37, 78)" }} onClick={deleteDevice}>
            Delete device
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default DeleteDeviceModal;
