/* Issue fixed for github issue #84, issue #174,issue #213,issue #290 */
import React, { useState, useEffect, useCallback } from "react";
// import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useStyles } from "./displayStyles";
import { Colors } from "common/Colors";
import { useTheme } from "@material-ui/core/styles";
import SuccessModal from "components/success-modal/SuccessModal";
import { Grid } from "@material-ui/core";
import AssetPropertyList from "../asset-properties-display/AssetPropertyList";
import { DeviceTableDisplay } from "../asset-properties-display/DeviceTableDisplay";
import { useDispatch, useSelector } from "react-redux";
import { setIsAssetActive } from "components/group-modal/groupModalSlice";
import { RootState } from "app/rootReducer";
// import {
//   setSuccessModalOpen,
//   setSuccessModalDescription,
// } from "components/success-modal/successModalSlice";
import { setCurrentAssetId } from "../assetsDashboardSlice";
import { AssetProfile } from "./types";
import AssetAPI from "services/assetsAPI";
import clsx from "clsx";
import UpdateAssetProperties from "../asset-properties-display/UpdateAssetProperties";
import { ParameterTableDisplay } from "../asset-properties-display/ParameterTableDisplay";
import DeleteDeviceModal from "features/assets-page/asset-properties-display/DeleteDeviceModal";
import DeleteParameterModal from "../asset-properties-display/DeleteParameterModal";
import { ParameterSequencePage } from "../asset-properties-display/ParameterSequencePage";
import Button from "components/common-buttons/Button";
import ChemicalInjectorList from "../asset-properties-display/ChemicalInjectorList"; //imported for showing configure asset button for issue #174
import UnsuccessModal from "components/success-modal/UnsuccessfullModal"; //imported for issue #213

type AssetsDisplayProps = {
  openAddAssetModal: Function;
  openAddGroupModal: Function;
  openGroupModal: Function;
  returnToParent: Function;
  nodeObject: any;
  handleAssetUpdateModal: Function;
  openAddDeviceModal: Function;
};

const assetDefaultObject = {
  asset_sharing: false,
  parent_asset_group_id: "",
  parent_asset_id: "",
  asset_name: "",
  is_asset: false,
  shared_customers: [],
  asset_id: "",
  asset_status: false,
  tenant_id: "",
  asset_category_id: "",
  asset_category_type: "",
  property_values: {
    property_7: {
      name: "",
      value: ""
    },
    property_6: {
      name: "",
      value: ""
    },
    property_9: {
      name: "",
      value: ""
    },
    property_10: {
      name: "",
      value: ""
    },
    property_8: {
      name: "",
      value: ""
    },
    property_3: {
      name: "",
      value: ""
    },
    property_2: {
      name: "",
      value: ""
    },
    property_5: {
      name: "",
      value: ""
    },
    property_4: {
      name: "",
      value: ""
    },
    property_1: {
      name: "",
      value: ""
    }
  },
  children_asset: [],
  asset_devices: [],
  complex_asset: false,
  count_assets: 0,
  count_devices: 0
};

const AssetsDisplay: React.FC<AssetsDisplayProps> = ({
  openAddAssetModal,
  openAddGroupModal,
  openGroupModal,
  returnToParent,
  nodeObject,
  handleAssetUpdateModal,
  openAddDeviceModal
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [assetSharing, setAssetSharing] = useState(true);
  const [assetIsChild, setAssetIsChild] = useState(false);
  const [assetId, setAssetId] = useState("");
  const [assetProfile, setAssetProfile] = useState<AssetProfile>(
    assetDefaultObject
  );
  const [selectInformation, setSelectInformation] = useState(
    "Asset properties"
  );
  const [assetUpdated, setAssetUpdated] = useState(false);
  const [openDeleteDeviceModal, setOpenDeleteDeviceModal] = useState(false);
  const [openDeleteParameterModal, setOpenDeleteParameterModal] = useState(
    false
  );

  const isAssetActive = useSelector(
    (state: RootState) => state.GroupModalSlice.isAssetActive
  );
  const { assetDeviceList, isParameterSequenceActive } = useSelector(
    (state: RootState) => state.AssetDashboardSlice
  );

  const retrieveAssetProfile = useCallback(async () => {
    const assetProfile = await AssetAPI.retrieveAssetProfile(
      nodeObject.assetGroupId
    );
    setSelectInformation("Asset properties");
    setAssetIsChild(!!assetProfile.parent_asset_id);
    setAssetProfile(assetProfile);
    // setIsActive(assetProfile.asset_status);
    dispatch(setIsAssetActive(assetProfile.asset_status));
    setAssetId(assetProfile.asset_id);
  }, [nodeObject.assetGroupId, dispatch]);

  const callDeleteDeviceModal = () => {
    setOpenDeleteDeviceModal(true);
  };

  const callDeleteParameterModal = () => {
    setOpenDeleteParameterModal(true);
  };

  const closeModal = () => {
    setOpenDeleteDeviceModal(false);
    setOpenDeleteParameterModal(false);
  };

  useEffect(() => {
    retrieveAssetProfile();
  }, [nodeObject.assetGroupId, retrieveAssetProfile]); // !add isAssetActive here to update asset

  useEffect(() => {
    if (assetUpdated) {
      retrieveAssetProfile();
      setAssetUpdated(false);
    }
  }, [assetUpdated, nodeObject.assetGroupId, dispatch, retrieveAssetProfile]); // !add isAssetActive here to update asset

  useEffect(() => {
    retrieveAssetProfile();
  }, [isAssetActive, retrieveAssetProfile]);

  return (
    <>
      <div className={classes.pathHeader}>
        <span
          style={{
            fontWeight: 600,
            color: Colors.Gray,
            margin: "0 2rem"
          }}
        >
          Path:
        </span>
        <span>{assetProfile.asset_name}</span>
      </div>
      {!isParameterSequenceActive ? (
        <>
          <div className={classes.assetInformation}>
            <div className={classes.assetsGroup}>
              <div className={classes.notApplicable}>N/A</div>
              <div style={{ color: Colors.LightGray }}>Groups</div>
            </div>
            <div className={classes.assetsGroup}>
              <div className={classes.value}>{assetProfile.count_assets}</div>
              <div>Assets</div>
            </div>
            <div className={classes.assetsGroup}>
              <div className={classes.value}>{assetDeviceList.length}</div>
              <div>Devices</div>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <div className={classes.typeGroup}>Type Group:</div>
            <div className={classes.buttonGroup}>
              <Button
                type="button"
                color="white"
                style={{ margin: "0 1rem 0 0", width: "110px" }} //Added width for proper alignment of button text. Github issue #84
                onClick={() => {
                  openGroupModal("delete asset", assetId);
                }}
              >
                Delete asset
              </Button>
              {!isAssetActive ? (
                <Button
                  type="button"
                  color="white"
                  style={{ margin: "0 1rem 0 0", width: "131px" }} //Added width for proper alignment of button text. Github issue #84
                  onClick={() => {
                    openGroupModal("activate asset", assetId);
                  }}
                >
                  Activate asset
                </Button>
              ) : (
                <Button
                  type="button"
                  color="white"
                  style={{ margin: "0 1rem 0 0", width: "131px" }} //Added width for proper alignment of button text. Github issue #84
                  onClick={() => {
                    openGroupModal("deactivate asset", assetId);
                  }}
                >
                  Deactivate asset
                </Button>
              )}
              <button
                type="button"
                className={clsx({
                  [classes.addButtons]: isAssetActive,
                  [classes.disabled]: !isAssetActive
                })}
                style={{ margin: "0 1rem 0 0", width: "130px" }} //Added width for proper alignment of button text. Github issue #84
                onClick={() => {
                  openAddDeviceModal();
                  dispatch(setCurrentAssetId(assetId));
                }}
                disabled={!isAssetActive}
              >
                + Add new device
              </button>
              {!assetIsChild && (
                <button
                  type="button"
                  style={{ width: "130px" }} //Added width for proper alignment of button text. Github issue #84
                  className={clsx({
                    [classes.addButtons]: isAssetActive,
                    [classes.disabled]: !isAssetActive
                  })}
                  onClick={() => {
                    openAddAssetModal();
                  }}
                  disabled={!isAssetActive}
                >
                  + Add new asset
                </button>
              )}
            </div>
          </div>
          <Grid container className={classes.assetContainer}>
            <Grid item xs={6}>
              <div className={classes.labelText}>
                {/* Changed text for issue #290 */}
                <p>Selection: </p>
                <p style={{ fontWeight: "bold" }}>
                  {" "}
                  {assetProfile.asset_name}{" "}
                </p>
              </div>
              <div className={classes.inputGroup}>
                <span>Asset sharing</span>
                <div className={classes.toggleButtonContainer}>
                  <button
                    className={clsx(classes.toggleButton, {
                      [classes.active]: assetSharing && isAssetActive,
                      [classes.onOffDisabled]: assetSharing && !isAssetActive,
                      [classes.activeDisabled]: !assetSharing && !isAssetActive
                    })}
                    type="button"
                    onClick={() => {
                      setAssetSharing(true);
                    }}
                    disabled={!isAssetActive}
                  >
                    ON
                  </button>
                  <button
                    className={clsx(classes.toggleButton, {
                      [classes.active]: !assetSharing && isAssetActive,
                      [classes.onOffDisabled]: !assetSharing && !isAssetActive,
                      [classes.activeDisabled]: assetSharing && !isAssetActive
                    })}
                    type="button"
                    onClick={() => {
                      setAssetSharing(false);
                    }}
                    disabled={!isAssetActive}
                  >
                    OFF
                  </button>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.labelText}>
                {/* Changed text for issue #290 */}
                <p>Status: </p>
                <p
                  className={clsx({
                    [classes.activeStatus]: isAssetActive,
                    [classes.deactivated]: !isAssetActive
                  })}
                >
                  {isAssetActive ? "ACTIVE" : "DEACTIVATED"}
                </p>
                {/* // ! commented out until we have the update time and date of deactivation */}
                {/* <p>{isAssetActive ? null : "10 Jul 2020 | 2:00 pm"}</p> */}
              </div>
              {/* <div className={classes.inputGroup}>
                <span>Shared with customer(s)</span>
                <button
                  className={clsx(classes.selectButtons, {
                    [classes.selectedDisabled]: !isAssetActive,
                  })}
                  disabled={!isAssetActive}
                >
                  Select
                </button>
              </div> */}
            </Grid>
            <Grid item xs={12} className={classes.propertiesDisplayContainer}>
              <button
                type="button"
                className={clsx(classes.toggleSelectionButtons, {
                  [classes.active]: selectInformation === "Asset properties"
                })}
                onClick={() => {
                  setSelectInformation("Asset properties");
                }}
              >
                Asset properties
              </button>
              <button
                type="button"
                className={clsx(classes.toggleSelectionButtons, {
                  [classes.active]:
                    selectInformation === "Connected device(s) and parameters"
                })}
                onClick={() => {
                  setSelectInformation("Connected device(s) and parameters");
                }}
              >
                Connected device(s) and parameters
              </button>
              {/* adding button for configuring chemical injector assets for issue #174 */}
              {assetProfile?.asset_category_type == "chemical injectors" && (
                <button
                  type="button"
                  className={clsx(classes.toggleSelectionButtons, {
                    [classes.active]: selectInformation === "Configure asset"
                  })}
                  onClick={() => {
                    setSelectInformation("Configure asset");
                  }}
                >
                  Configure asset
                </button>
              )}
            </Grid>
            <Grid item xs={12}>
              {selectInformation === "Asset properties" &&
                (!isAssetActive ? (
                  <AssetPropertyList assetProfile={assetProfile} />
                ) : (
                  // ! have an object be returned here , also the save update function will live in assets display, cancel button should be bring you to tenant information
                  <UpdateAssetProperties
                    assetProfile={assetProfile}
                    setAssetUpdated={setAssetUpdated}
                    handleAssetUpdateModal={handleAssetUpdateModal}
                  />
                ))}{" "}
              {selectInformation === "Connected device(s) and parameters" && (
                <>
                  <DeviceTableDisplay
                    callDeleteDeviceModal={callDeleteDeviceModal}
                  />
                  <ParameterTableDisplay
                    callDeleteParameterModal={callDeleteParameterModal}
                  />
                </>
              )}{" "}
              {/* added for issue #174 */}
              {selectInformation === "Configure asset" && (
                <ChemicalInjectorList assetProfile={assetProfile} />
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <ParameterSequencePage />
      )}
      <SuccessModal />
      {/* imported for issue #213 */}
      <UnsuccessModal />
      <DeleteDeviceModal
        open={openDeleteDeviceModal}
        handleClose={closeModal}
      />
      <DeleteParameterModal
        open={openDeleteParameterModal}
        handleClose={closeModal}
      />
    </>
  );
};

export default AssetsDisplay;
