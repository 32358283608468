/*Issue fixed for github issue #199 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeviceDetailsProps } from "./types";
import { defaultDeviceDetails } from "./deviceDefaultValues";

const initialState: {
  deviceDetails: DeviceDetailsProps;
  accountName: string;
  accountId: string;
  burstAlarms: ({ alarm_name: string; alarm_value: number } | null)[];
  burstAutomation: ({ rule_name: string; rule_value: number } | null)[]; //added for automation rules status for issue #199
  burstDeviceActive: boolean;
  burstAlarmsTS: number;
  burstAutomationTS: number; //added for automation rules status for issue #199
  burstLocalDevices: string[];
  burstLocalDevicesTS: number;
  customAlarm: string;
  burstParameters: number[];
  burstParametersTS: number;
} = {
  deviceDetails: defaultDeviceDetails,
  accountName: "",
  accountId: "",
  burstAlarms: [],
  burstAutomation: [], //initializing to empty array for issue #199
  burstDeviceActive: false,
  burstAlarmsTS: 0,
  burstAutomationTS: 0, //initializing to 0 for issue #199
  burstLocalDevices: [],
  burstLocalDevicesTS: 0,
  customAlarm: "",
  burstParameters: [],
  burstParametersTS: 0
};

const deviceDetailsSlice = createSlice({
  name: "DeviceDetailsSlice",
  initialState,
  reducers: {
    setDeviceDetails: (state, action: PayloadAction<DeviceDetailsProps>) => {
      state.deviceDetails = action.payload;
    },
    updateDeviceInfo: (
      state,
      action: PayloadAction<{
        device_name: string;
        loss_of_comms_notify: boolean;
        device_notes: string;
      }>
    ) => {
      state.deviceDetails.device_name = action.payload.device_name;
      state.deviceDetails.loss_of_comms_notify =
        action.payload.loss_of_comms_notify;
      state.deviceDetails.device_notes =
        action.payload.device_notes;
    },
    setAccountName: (state, action: PayloadAction<string>) => {
      state.accountName = action.payload;
    },
    setAccountId: (state, action: PayloadAction<string>) => {
      state.accountId = action.payload;
    },
    setBurstAlarms: (
      state,
      action: PayloadAction<
        ({ alarm_name: string; alarm_value: number } | null)[]
      >
    ) => {
      state.burstAlarms = action.payload;
    },
    setBurstAlarmsTS: (state, action: PayloadAction<number>) => {
      state.burstAlarmsTS = action.payload;
    },
    //added for automation rules status for issue #199
    setBurstAutomation: (
      state,
      action: PayloadAction<
        ({ rule_name: string; rule_value: number } | null)[]
      >
    ) => {
      state.burstAutomation = action.payload;
    },
    //added for timestamp for issue #199
    setBurstAutomationTS: (state, action: PayloadAction<number>) => {
      state.burstAutomationTS = action.payload;
    },
    setBurstDeviceActive: (state, action: PayloadAction<boolean>) => {
      state.burstDeviceActive = action.payload;
    },
    setBurstLocalDevices: (state, action: PayloadAction<string[]>) => {
      state.burstLocalDevices = action.payload;
    },
    setBurstLocalDevicesTS: (state, action: PayloadAction<number>) => {
      state.burstLocalDevicesTS = action.payload;
    },
    setCustomAlarm: (state, action: PayloadAction<string>) => {
      state.customAlarm = action.payload;
    },
    setBurstParameters: (state, action: PayloadAction<number[]>) => {
      state.burstParameters = action.payload;
    },
    setBurstParametersTS: (state, action: PayloadAction<number>) => {
      state.burstParametersTS = action.payload;
    }
  }
});

export const {
  setDeviceDetails,
  updateDeviceInfo,
  setAccountName,
  setAccountId,
  setBurstAlarms,
  setBurstAlarmsTS,
  setBurstAutomation,
  setBurstAutomationTS,
  setBurstDeviceActive,
  setBurstLocalDevices,
  setBurstLocalDevicesTS,
  setCustomAlarm,
  setBurstParameters,
  setBurstParametersTS
} = deviceDetailsSlice.actions;
export default deviceDetailsSlice.reducer;
