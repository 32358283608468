import React from 'react';
import { Card, CardContent, Typography, Avatar, Divider, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CommentIcon from '@material-ui/icons/Comment';
import { Exception } from 'features/exceptions-page/types';

type KanbanCardProps = {
  exception: Exception;
  onClick: () => void;
};

const priorityLabels: { [key: number]: { label: string, color: string } } = {
  0: { label: 'Warning', color: 'orange' },
  1: { label: 'Critical', color: 'red' },
  3: { label: 'Note', color: 'green' },
};

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderRadius: '12px',
    margin: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    marginBottom: '8px',
    marginTop: '20px',
    cursor: 'pointer',
    padding: '5px',
    transition: 'transform 0.1s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  date: {
    color: theme.palette.text.secondary,
    marginBottom: '8px',
  },
  title: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  source: {
    marginBottom: '8px',
  },
  divider: {
    margin: '8px 0',
  },
  priorityLabel: {
    color: 'white',
    fontSize: '14px',
    marginBottom: '8px',
    fontWeight: 'bold',
    backgroundColor: (props: { priority: number }) => priorityLabels[props.priority]?.color || 'grey',
    padding: '4px 8px',
    borderRadius: '4px',
    display: 'inline-block',
  },
  assets: {
    marginBottom: '8px',
  },
  comment: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '4px',
    marginBottom: '0px',
  },
  assignedTo: {
    display: 'flex',
    gap: '4px',
    overflowX: 'auto', // Enable horizontal scrolling
    paddingBottom: '4px', // Optional: To prevent scrollbar overlap with content
  },
  assigneeAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    flexShrink: 0, // Prevent avatars from shrinking
  },
}));

const KanbanCard: React.FC<KanbanCardProps> = ({ exception, onClick }) => {
  const classes = useStyles({ priority: exception.priority });

  const formatDate = (timestamp: number) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    return new Date(timestamp * 1000).toLocaleString(undefined, options);
  };

  return (
    <Card onClick={onClick} className={classes.card}>
      <CardContent>
        <div className={classes.priorityLabel}>
          {priorityLabels[exception.priority]?.label}
        </div>
        <Typography variant="body2" className={classes.date}>
          {formatDate(exception.time_latest)}
        </Typography>
        <Typography variant="h6" className={classes.title}>
          {exception.name}
        </Typography>
        <Typography variant="body1" className={classes.source}>
          {exception.source}
        </Typography>
        <Divider className={classes.divider} />
        <div className={classes.comment}>
          <div className={classes.assignedTo}>
            {exception.assigned_to?.map(user => (
              <Avatar key={user.user_id} className={classes.assigneeAvatar}>
                {user.user_name.split(' ').map(n => n[0]).join('')}
              </Avatar>
            ))}
          </div>
          {exception.root_cause > 0 && (
            <IconButton>
              <CommentIcon />
            </IconButton>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default KanbanCard;
