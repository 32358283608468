/* Issue fixed for github issue #30 */
import React, { useState, useEffect, useCallback } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { formModalStyles } from "../devices-page/device-settings/device-settings-tabs/formModalStyles";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
} from "components/success-modal/successModalSlice";
import { useDispatch } from "react-redux";
import { Colors } from "common/Colors";
import { otaAPI } from "services/otaAPI";
import LoadSpinner from "common/LoadSpinner";
import { useForm } from "react-hook-form";
import { AccountSelection } from "./types";
import Button from "components/common-buttons/Button";
import Xsquare from "assets/bt-assets/x-square.svg";
import { LinearIndeterminate } from "common/LinearLoading";
import { FirmwareProps } from "features/firmware-page/types";
// import clsx from "clsx";

type AddFirmwareToTenantModalProps = {
  open: boolean;
  handleClose: Function;
  firmwareId: string;
  selectedFirmware: FirmwareProps;
  recallList: Function; //Added this boolean function for calling the list of firmwares. Github issue #30
};

export const AddFirmwareToTenantModal: React.FC<AddFirmwareToTenantModalProps> =
  ({ open, handleClose, firmwareId, selectedFirmware, recallList }) => {
    const classes = formModalStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const { register, handleSubmit, errors } = useForm<AccountSelection>();
    const [accounts, setAccounts] = useState([]);
    const [accountsAttachedToFirmware, setAccountsAttachedToFirmware] =
      useState([]);

    const handleAddFirmwareToTenant = async ({
      tenantId,
    }: AccountSelection) => {
      try {
        await otaAPI.addFirmwareToTenant(firmwareId, tenantId);
        handleClose();
        //Added this boolean function for calling the list of firmwares. Github issue #30
        recallList(true);
        dispatch(setSuccessModalOpen(true));
        dispatch(setSuccessModalDescription("Firmware added to account!"));
      } catch (error) {
        console.log(error);
        alert(error);
        setLoading(false);
      }
    };

    const callLists = useCallback(async () => {
      try {
        setDeleteLoading(true);
        const availableAccountsForFirmware =
          await otaAPI.retrieveAvailableTenantsToAddToFirmware(firmwareId);
        const retrieveTenantsAttachedToFirmware =
          await otaAPI.retrieveListOfTenantsAttachedToFirmware(firmwareId);

        setAccounts(availableAccountsForFirmware);
        setAccountsAttachedToFirmware(retrieveTenantsAttachedToFirmware);
        setDeleteLoading(false);
      } catch (error) {
        console.log(error);
      }
    }, [firmwareId]);

    const handleDeleteTenant = async (tenantId: string, firmwareId: string) => {
      try {
        await otaAPI.deleteTenantFromFirmware(firmwareId, tenantId);
        callLists();
        //Added this boolean function for calling the list of firmwares. Github issue #30
        recallList(true)
      } catch (error) {
        console.log("error", error);
      }
    };

    useEffect(() => {
      (async () => {
        if (open) {
          try {
            callLists();
          } catch (error) {
            console.log(error);
          }
        }
      })();
    }, [firmwareId, open, callLists]);

    useEffect(() => {
      if (!open) {
        setAccounts([]);
        setAccountsAttachedToFirmware([]);
      }
    }, [open]);

    return (
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalOverlay}>
          {loading ? (
            <LoadSpinner />
          ) : (
            <div className={classes.modalBody}>
              <div className={classes.header} style={{ marginBottom: 0 }}>
                <p className={classes.headerText}>Add firmware to account</p>
                <CloseIcon
                  className={classes.icon}
                  onClick={() => handleClose()}
                />
              </div>
              {deleteLoading && <LinearIndeterminate />}
              <form onSubmit={handleSubmit(handleAddFirmwareToTenant)}>
                <div className={classes.inputBody}>
                  <div
                    className={classes.inputGroup}
                    style={{ padding: "1rem 2.5rem 0 2.5rem" }}
                  >
                    <div className={classes.labelContainer}>
                      <span>Application name</span>
                    </div>
                    <p>{selectedFirmware.application_name}</p>
                  </div>
                </div>
                <div className={classes.inputBody}>
                  <div
                    className={classes.inputGroup}
                    style={{ padding: "1rem 2.5rem 0 2.5rem" }}
                  >
                    <div className={classes.labelContainer}>
                      <span>Application part number</span>
                    </div>
                    <p>{selectedFirmware.part_number}</p>
                  </div>
                </div>
                <div className={classes.inputBody}>
                  <div
                    className={classes.inputGroup}
                    style={{ padding: "1rem 2.5rem 0 2.5rem" }}
                  >
                    <div className={classes.labelContainer}>
                      <span>Application ID</span>
                    </div>
                    <p>{selectedFirmware.version}</p>
                  </div>
                </div>
                <div className={classes.inputBody}>
                  <div
                    className={classes.inputGroup}
                    style={{ padding: "1rem 2.5rem 0 2.5rem" }}
                  >
                    <div className={classes.labelContainer}>
                      <span>Description</span>
                    </div>
                    <p>{selectedFirmware.description}</p>
                  </div>
                </div>
                <div className={classes.inputBody}>
                  <div className={classes.inputGroup}>
                    <div className={classes.labelContainer}>
                      <span>
                        Add account{" "}
                        <span style={{ color: Colors.Pink }}>*</span>
                      </span>
                      {errors.tenantId && (
                        <span className={classes.error}>Select an account</span>
                      )}
                    </div>
                    <select
                      name="tenantId"
                      ref={register({ required: true, minLength: 1 })}
                    >
                      <option value={""}>Choose an account...</option>
                      {accounts.map((account: any, index) => (
                        <option
                          key={account.tenant_id + index}
                          value={account.tenant_id}
                        >
                          {account.tenant_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className={classes.inputBody}>
                  <div className={classes.inputGroup}>
                    <div className={classes.labelContainer}>
                      <span>
                        Account(s) <span style={{ color: Colors.Pink }}>*</span>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {accountsAttachedToFirmware.map(
                        (account: any, index: number) => (
                          <div
                            key={account.tenant_id + index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              margin: "0 5px 0 0",
                            }}
                          >
                            {account.tenant_name}{" "}
                            <img
                              src={Xsquare}
                              alt="Delete firmware"
                              style={{
                                color: Colors.BasinRed,
                                cursor: "pointer",
                                marginLeft: 5,
                              }}
                              onClick={() =>
                                handleDeleteTenant(
                                  account.tenant_id,
                                  firmwareId
                                )
                              }
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.actionItems}>
                  <Button
                    type="button"
                    color="white"
                    onClick={() => handleClose()}
                    disabled={deleteLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="defaultTheme"
                    disabled={deleteLoading}
                  >
                    Add firmware
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
      </Modal>
    );
  };

export default AddFirmwareToTenantModal;
