/* Issue fixed for github issue #23,issue #24, issue #58, issue #36,issue #72,issue #81,issue #133,issue #140,issue #199*/
import React, { useState, useEffect } from "react";
import NotificationCard from "../../components/notificationCards/NotificationCards";
import { Grid, InputBase } from "@material-ui/core";
import { Colors } from "common/Colors";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import AssetDisplayPanel from "../../components/asset-display-panel/AssetDisplayPanel";
import EventsExpansionPanel from "../../components/EventsExpansionPanel/EventsExpansionPanel";
import ActiveEvents from "../../components/EventsExpansionPanel/ActiveEvents"; //Imported Active Events for showing on asset status page for issue #72
import ActiveAutomationRules from "../../components/EventsExpansionPanel/ActiveAutomationRules"; //Imported Active Automation Rules for showing active rules for issue #199
import TrendingGraphExpansionPanel from "../../components/TrendingGraphExpansionPanel/TrendingGraphExpansionPanel";
// import HistogramExpansionPanel from "../../components/HistogramExpansionPanel/HistogramExpansionPanel"; // hide per BTRAK-545 client comments
import AssetMap from "../../components/AssetMap/AssetMap";
import search from "assets/bt-assets/header_search_w.svg";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { dashboardAPI } from "services/dashboardAPI";
import { SingleAssetProp, AssetsProp } from "./Types";
import LoadSpinner from "common/LoadSpinner";

import { useMediaQuery } from "react-responsive"; //for issue #24

type Props = {
  assetId: string;
  complexAsset?: boolean;
};

const AssetDrilldown = ({ assetId, complexAsset }: Props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  // const [dropdownSelection, setDropdownSelection] = useState("critical");
  // const [searchInput, setSearchInput] = useState("");
  const [collapseMap, setCollapseMap] = useState(false);
  const [expandEvents, setExpandEvents] = useState(false);
  const [expandActiveEvents, setExpandActiveEvents] = useState(false); //for expanding and contracting the Active Events accordion for issue #72
  const [
    expandActiveAutomationRules,
    setExpandActiveAutomationRules
  ] = useState(false); //for expanding and contracting the Automation Rules accordion for issue #199
  const [expandTrendingGraphs, setExpandTrendingGraphs] = useState(false);
  const [expandedHistogram, setExpandedHistogram] = useState(false);
  const [assetDisplay, setAssetDisplay] = useState<any>();
  const [singleAsset, setSingleAsset] = useState<SingleAssetProp>();
  const [assetLevel, setAssetLevel] = useState(false); //Added variable to know whether user is at asset level or group level for issue #81
  const [firstTileAlertLevel, setFirstTileAlertLevel] = useState(""); //Added for the alert level of first tile for issue #133
  const [secondTileAlertLevel, setSecondTileAlertLevel] = useState(""); //Added for the alert level of second tile for issue #133
  const [firstTileTimestamp, setFirstTileTimestamp] = useState(0); //Added for sending the time stamp for first tile for issue #133
  const [secondTileTimestamp, setSecondTileTimestamp] = useState(0); //Added for sending the time stamp for second tile for issue #133
  //for checking if mobile view or web view for responsive UI issue #24
  var ismobileview = useMediaQuery({ query: "(max-width: 800px)" });
  // const [listOfDevices, setListOfDevices] = useState<DeviceDetailsProps[]>();

  // ! commented out for until later phase
  // const handleChange = (
  //   event: React.ChangeEvent<{ name?: string; value: unknown }>
  // ) => {
  //   setDropdownSelection(event.target.value as string);
  // };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (complexAsset) {
          const response = await dashboardAPI.openComplexAsset(assetId);
          setAssetDisplay(response);
          setLoading(false);
          setAssetLevel(true); //Setting assetLevel to true since user will be at asset level for issue #81
          firstTileColorAndStatus(response); //calling function for color and timestamp to be shown on first tile for issue #133
          secondTileColorAndStatus(response); //calling function for color and timestamp to be shown on second tile for issue #133
        } else {
          const response = await dashboardAPI.openAsset(assetId);
          setSingleAsset(response);
          setLoading(false);
          setAssetLevel(true); //Setting assetLevel to true since user will be at asset level for issue #81
          firstTileColorAndStatus(response); //calling function for color and timestamp to be shown on first tile for issue #133
          secondTileColorAndStatus(response); //calling function for color and timestamp to be shown on second tile for issue #133
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        alert("API error: retrieve asset data");
      }
    })();
  }, [assetId, complexAsset]);

  // function for assigning the color of first tile based on different conditions for issue #133
  const firstTileColorAndStatus = (responseAssetDisplay: any) => {
    // condition to check if complex asset or single asset for issue #133
    if (complexAsset) {
      // initializing arrays for storing timestamps and event level of all assets under complex for issue #133
      var tsArr: any[] = [];
      var eventLevel: any[] = [];
      responseAssetDisplay?.assets?.map((element: any) => {
        tsArr.push(element.last_read); //pushing timestamp values into array for issue #133
        eventLevel.push(element.warning); //pushing event level values into arry for issue #133
      });

      let idxFalse = eventLevel.indexOf(false); //  checking if there is any false value in the array for issue #133
      var timestamps = 0;
      // checking if there are multiple false values and storing their indices in an array for issue #133
      if (idxFalse != -1) {
        var indicesFalse: any[] = [];
        setFirstTileAlertLevel("critical"); //setting alert level for first tile as critical for issue #133
        while (idxFalse !== -1) {
          indicesFalse.push(idxFalse);
          idxFalse = eventLevel.indexOf(false, idxFalse + 1);
        }
        // getting the latest timestamp from the array for issue #133
        indicesFalse.map((ind: any) => {
          if (tsArr[ind] > timestamps) timestamps = tsArr[ind];
        });
      } else {
        // checking if there are any true values after not having any false values for issue #133
        let idxTrue = eventLevel.indexOf(true);
        // checking if there are multiple true values and storing their indices in an array for issue #133
        if (idxTrue != -1) {
          var indicesTrue: any[] = [];
          setFirstTileAlertLevel("warning"); //setting alert level for first tile as warning for issue #133
          while (idxTrue !== -1) {
            indicesTrue.push(idxTrue);
            idxTrue = eventLevel.indexOf(true, idxTrue + 1);
          }
          // getting the latest timestamp from the array for issue #133
          indicesTrue.map((ind: any) => {
            if (tsArr[ind] > timestamps) timestamps = tsArr[ind];
          });
        } else {
          // checking for null values for issue #133
          let idxNull = eventLevel.indexOf(null);
          // checking if there are multiple false values and storing their indices in an array for issue #133
          if (idxNull != -1) {
            var indicesNull: any[] = [];
            setFirstTileAlertLevel("nowarnings"); //setting alert level for first tile as no warnings for issue #133
            while (idxNull !== -1) {
              indicesNull.push(idxNull);
              idxNull = eventLevel.indexOf(true, idxNull + 1);
            }
            // getting the latest timestamp from the array for issue #133
            indicesNull.map((ind: any) => {
              if (tsArr[ind] > timestamps) timestamps = tsArr[ind];
            });
          }
        }
      }
      setFirstTileTimestamp(timestamps);
    } else {
      // implementing the same thing for single asset for issue #133
      setFirstTileTimestamp(responseAssetDisplay?.asset?.last_read); //setting timestamp for issue #133
      // checking warning for event status and setting it accordingly for issue #133
      if (responseAssetDisplay?.asset?.warning != null) {
        if (responseAssetDisplay?.asset?.warning) {
          setFirstTileAlertLevel("warning");
        } else {
          setFirstTileAlertLevel("critical");
        }
      } else {
        setFirstTileAlertLevel("nowarnings");
      }
    }
  };

  // function for assigning the color of second tile based on different conditions for issue #133
  const secondTileColorAndStatus = (responseAssetDisplay: any) => {
    // condition for checking if it is complex asset or not for issue #133
    if (complexAsset) {
      var ts1 = 0; //initializing timestamp to 0 for stopped assets for issue #133
      var ts0 = 0; //initializing timestamp to 0 for running assets for issue #133
      responseAssetDisplay?.assets?.map((element: any) => {
        // checking if asset is stopped and has latest timestamp for issue #133
        if (
          element?.notification_count?.stopped_count > 0 &&
          element?.last_read > ts1
        ) {
          ts1 = element?.last_read;
        }
        //checking if asset is running and has latest timestamp for issue #133
        if (
          element?.notification_count?.stopped_count == 0 &&
          element?.last_read > ts1
        ) {
          ts0 = element?.last_read;
        }
      });
      // condition to check if there is atleast 1 stopped asset for issue #133
      if (ts1 == 0) {
        setSecondTileAlertLevel("running");
        setSecondTileTimestamp(ts0);
      } else {
        setSecondTileAlertLevel("stopped");
        setSecondTileTimestamp(ts1);
      }
    } else {
      //else part for single assets for issue #133
      //assigning last read value for issue #133
      setSecondTileTimestamp(responseAssetDisplay?.asset?.last_read);
      // condition to check if asset is stopped or running for issue #133
      if (responseAssetDisplay?.asset?.notification_count?.stopped_count > 0) {
        setSecondTileAlertLevel("stopped");
      } else {
        setSecondTileAlertLevel("running");
      }
    }
  };

  if (loading) {
    return <LoadSpinner center size="50vh" />;
  }

  return (
    <Grid container className={classes.root}>
      {/* Changing the grid layout and using simple div instead of grid for issue #140 */}
      <div className={classes.notificationsContainer}>
        {/* Adding div with styles for first notifications tile for issue #140 */}
        <div style={{ gridArea: "one" }}>
          {/* Updated the variable name for the critical count. Github issue #36 */}
          <NotificationCard
            amount={
              singleAsset?.all_notifications?.critical_total ||
              assetDisplay?.all_notifications?.critical_total ||
              0
            }
            alertLevel={firstTileAlertLevel} //sending alertlevel according to event status for issue #133
            assetLevel={assetLevel} //Adding assetLevel for showing if it is asset level or group level for issue #81
            tileNumber={1} //sending tile number to display text accordingly at asset level for issue #133
            timestamp={firstTileTimestamp} //sending timestamp for displaying it on tile at asset level for issue #133
          />
        </div>
        {/* Adding div with styles for second notifications tile for issue #140 */}
        <div style={{ gridArea: "two" }}>
          {/* Updated the variable name for the warning count. Github issue #36 */}
          <NotificationCard
            amount={
              singleAsset?.all_notifications?.warning_total ||
              assetDisplay?.all_notifications?.warning_total ||
              0
            }
            alertLevel={secondTileAlertLevel} //sending alertlevel according to running or stopped asset for issue #133
            assetLevel={assetLevel} //Adding assetLevel for showing if it is asset level or group level for issue #81
            tileNumber={2} //sending tile number to display text accordingly at asset level for issue #133
            timestamp={secondTileTimestamp} //sending timestamp for displaying it on tile at asset level for issue #133
          />
        </div>
        {/* Adding div with styles for third notifications tile for issue #140 */}
        <div style={{ gridArea: "three" }}>
          {/* Updated the variable name for the notification count. Github issue #36 */}
          <NotificationCard
            amount={
              singleAsset?.all_notifications?.notification_total ||
              assetDisplay?.all_notifications?.notification_total ||
              0
            }
            alertLevel={"notification"}
            assetLevel={assetLevel}
            tileNumber={3}
            timestamp={""}
          />
        </div>
      </div>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between"
        }}
      >
        <Grid
          className={
            // added ismobileview variable to check if it is mobile view or web view for responsive UI issue #24
            !collapseMap || ismobileview
              ? classes.notificationCardContainerNarrow
              : classes.notificationCardContainerWide
          }
        >
          {/* // ! removed sort and search for future phase */}
          {/* <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <span className={classes.sortByText}>Sort by:</span>
            <select
              style={{ width: "auto" }}
              className={classes.sortSelect}
              onChange={handleChange}
              value={dropdownSelection}
            >
              <option value={"critical"}>Critical First</option>
              <option value={"connectivity"}>Connectivity issues</option>
              <option value={"highestAssets"}>Highest # of assets first</option>
              <option value={"lowestAssets"}>Lowest # of assets first</option>
            </select>

            <div className={classes.search}>
              <InputBase
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={searchInput}
                onChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setSearchInput(e.target.value);
                }}
                inputProps={{ "aria-label": "search" }}
                placeholder="Search asset by keyword(s)"
              />
              <div className={classes.searchbutton}>
                <img src={search} alt="" />
              </div>
            </div>
          </div> */}
          <div
            className={classes.statusExpansion} // added the class for the expansion tabs for responsive UI issue #24
          >
            <TrendingGraphExpansionPanel
              expandPanel={(expand: boolean) => {
                if (
                  (!expand &&
                    collapseMap &&
                    !expandEvents &&
                    !expandActiveEvents &&
                    !expandActiveAutomationRules &&
                    !expandedHistogram) ||
                  ismobileview // added ismobileview variable to check if it is mobile view or web view for responsive UI issue #24
                ) {
                  setCollapseMap(false);
                } else {
                  setCollapseMap(true);
                }
                setExpandTrendingGraphs(expand);
              }}
              expanded={expandTrendingGraphs}
              assetDisplay={assetDisplay}
              singleAsset={singleAsset}
            />
            <EventsExpansionPanel
              expandPanel={(expand: boolean) => {
                if (
                  (!expand &&
                    collapseMap &&
                    !expandTrendingGraphs &&
                    !expandActiveEvents &&
                    !expandActiveAutomationRules &&
                    !expandedHistogram) ||
                  ismobileview // added ismobileview variable to check if it is mobile view or web view for responsive UI issue #24
                ) {
                  setCollapseMap(false);
                } else {
                  setCollapseMap(true);
                }
                setExpandEvents(expand);
              }}
              expanded={expandEvents}
              assetId={assetId}
            />
            {/* Added Active Events accordion for issue #72 */}
            <ActiveEvents
              expandPanel={(expand: boolean) => {
                if (
                  (!expand &&
                    collapseMap &&
                    !expandTrendingGraphs &&
                    !expandEvents &&
                    !expandActiveAutomationRules &&
                    !expandedHistogram) ||
                  ismobileview // added ismobileview variable to check if it is mobile view or web view for responsive UI issue #24
                ) {
                  setCollapseMap(false);
                } else {
                  setCollapseMap(true);
                }
                setExpandActiveEvents(expand);
              }}
              expanded={expandActiveEvents}
              assetDisplay={assetDisplay}
              singleAsset={singleAsset}
            />
            {/* Added active automation rules accordion for issue #199 */}
            {/* <ActiveAutomationRules
              expandPanel={(expand: boolean) => {
                if (
                  (!expand &&
                    collapseMap &&
                    !expandTrendingGraphs &&
                    !expandEvents &&
                    !expandActiveEvents &&
                    !expandedHistogram) ||
                  ismobileview // added ismobileview variable to check if it is mobile view or web view for responsive UI issue #24
                ) {
                  setCollapseMap(false);
                } else {
                  setCollapseMap(true);
                }
                setExpandActiveAutomationRules(expand);
              }}
              expanded={expandActiveAutomationRules}
              assetDisplay={assetDisplay}
              singleAsset={singleAsset}
            /> */}
            {/* <HistogramExpansionPanel
              expandPanel={(expand: boolean) => {
                if (
                  !expand &&
                  collapseMap &&
                  !expandEvents &&
                  !expandTrendingGraphs
                ) {
                  setCollapseMap(false);
                } else {
                  setCollapseMap(true);
                }
                setExpandedHistogram(expand);
              }}
              expanded={expandedHistogram}
            /> */}
            {!complexAsset && singleAsset && (
              <AssetDisplayPanel
                asset={singleAsset?.asset}
                singleAsset={true}
                index={1} // Added index propsissue #82
              />
            )}
            {complexAsset &&
              assetDisplay?.assets?.map((asset: AssetsProp, index: number) => {
                return (
                  <AssetDisplayPanel
                    key={asset.asset_id + index}
                    asset={asset}
                    singleAsset={false}
                    index={index} // Added index={index} for styling left hand side and right hand side tiles for issue #82
                  />
                );
              })}
          </div>
        </Grid>
        {collapseMap ? (
          <Grid className={classes.mapCardContainerHidden}>
            <LocationOnOutlinedIcon
              className={classes.locationIcon}
              onClick={() => {
                setExpandEvents(false);
                setExpandActiveEvents(false); // Added for collapsing map on active events accordion click for issue #72
                setExpandActiveAutomationRules(false); //Added for collapsing map on active rules accordion click for issue #199
                setExpandTrendingGraphs(false);
                setExpandedHistogram(false);
                setCollapseMap(!collapseMap);
              }}
            />
          </Grid>
        ) : (
          <Grid className={classes.mapCardContainerVisible}>
            <AssetMap
              assets={
                singleAsset?.asset ? [singleAsset.asset] : assetDisplay?.assets
              }
              //for checking whether single asset or multiple assets for sending to Assetmap for issue #23
              singleAsset={singleAsset?.asset ? true : false}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
export default AssetDrilldown;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column"
    },
    notificationsContainer: {
      // Changing the css to build a grid layout for issue #140
      display: "grid",
      gridTemplateAreas: '"one two three" "four four five"', //adding grid template areas for tiles and items for issue #140
      gridTemplateColumns: "repeat(3,1fr)",
      columnGap: "1.5rem",
      marginTop: "1rem",

      [`@media (max-width: 800px)`]: {
        marginLeft: "1.25rem",
        marginRight: "1.25rem"
      },

      // added media query for all notification tiles to stack on top of one another till 1300 resolution for issue #140
      [`@media (max-width: 1300px)`]: {
        gridTemplateColumns: "repeat(1,1fr)",
        gridTemplateAreas: '"one" "two" "three" "four" "five"'
      }
    },
    //Added new css class for groups and asset tiles to stack on top of one another till 1300 resolution for issue #140
    notificationCardContainer: {
      gridArea: "four",
      marginTop: "2em",
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      columnGap: "1.5rem",
      [`@media (max-width: 1300px)`]: {
        gridTemplateColumns: "repeat(1,1fr)"
      }
    },
    margin: {
      margin: theme.spacing(1)
    },
    sortByText: {
      marginBottom: "1em",
      fontWeight: "bold",
      height: "2.5em",
      padding: ".5rem .5rem .5rem .5rem"
    },

    sortSelect: {
      marginBottom: "1em",
      height: "2.5em",
      border: `1px solid ${Colors.LightGray}`,
      padding: ".5rem .5rem .5rem 1rem",
      color: theme.palette.primary.light,
      fontWeight: "bold",
      "& option": {
        color: theme.palette.primary.light,
        fontWeight: "bold"
      }
    },
    locationIcon: {
      marginLeft: "auto",
      fontSize: "2.5em",
      border: `solid 1px ${theme.palette.primary.light}`,
      color: theme.palette.primary.light,
      "&:hover": {
        cursor: "pointer"
      }
    },
    searchInput: {
      width: "30%",
      marginLeft: "39%",
      marginBottom: "1em",
      height: "2.5em",
      border: `1px solid ${Colors.LightGray}`,
      padding: ".5rem .5rem .5rem 1rem",
      color: theme.palette.primary.light,
      fontWeight: "bold",
      "& option": {
        color: theme.palette.primary.light,
        fontWeight: "bold"
      }
    },
    textField: {
      width: "25ch"
    },
    sortByDropdown: { marginLeft: "2em" },

    notificationCardContainerNarrow: {
      marginTop: "2em",
      minHeight: "35em",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("xs")]: {
        width: "100%"
      },
      [theme.breakpoints.up("lg")]: {
        width: "66%" // Updated the width for the alignment. Github issue #58
      },

      //adding media query for cutting out empty space and margins on right and left in responsive UI issue #24
      [`@media (max-width: 700px)`]: {
        minHeight: "0em",
        marginLeft: "20px",
        marginRight: "20px",
        width: "89%"
      },
      //media query for ipad issue #24
      [`@media (max-width: 800px)`]: {
        minHeight: "0em",
        marginLeft: "20px",
        marginRight: "20px"
      }
    },
    notificationCardContainerWide: {
      marginTop: "2em",
      minHeight: "35em",
      display: "flex",
      flexDirection: "column",
      width: "95%",

      //adding media query for styling access group and map in  responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        display: "grid"
        //minHeight: "35em",
      }
    },
    mapCardContainerVisible: {
      gridArea: "five", //adding grid area for map to align with 3rd notification tile for issue #140
      marginTop: "2em",
      minHeight: "35em",
      maxHeight: "35em",
      border: "solid 1px black",
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.up("xs")]: {
        width: "100%"
      },
      [theme.breakpoints.up("lg")]: {
        width: "32%" // Updated the width for the alignment. Github issue #58
      },

      //adding media query for styling map in responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginTop: "2em",
        // minHeight: "35em",
        // maxHeight: "35em",
        border: "solid 1px black",
        display: "flex",
        flexDirection: "row"
      }
    },
    mapCardContainerHidden: {
      marginTop: "2em",
      display: "flex",
      width: "5%"
    },
    search: {
      display: "flex",
      flexDirection: "row",
      height: "2.5em",
      marginLeft: "auto"
    },
    inputRoot: {
      border: "1px solid #DDD",
      borderRadius: 0,
      width: "100%",
      backgroundColor: "#fff"
    },
    inputInput: {
      paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
      width: "100%"
    },
    searchbutton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      cursor: "pointer",
      backgroundColor: `${theme.palette.primary.light}`,
      width: 45
    },
    // added the class for the expansion tabs for responsive UI issue #24
    statusExpansion: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap"
    }
  })
);
