/* Issue fixed for github issue #155,issue #292 */
import React, { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAppSelector } from "app/store";
import { regex } from "utils/regexPatterns";
import {
  setSuccessModalOpen,
  setSuccessModalDescription
} from "components/success-modal/successModalSlice";
import { setCallUsers } from "../accountSlice";
import { Grid } from "@material-ui/core";
import AccountsAPI from "services/accountsAPI";
import { InputLength } from "common/inputLength";
import { useForm, Controller } from "react-hook-form";
import { InputLabel } from "components/input-label/InputLabel";
import Button from "components/common-buttons/Button";
import Select from "react-select";
import { adminRoles, powerUserRoles } from "./roles";
import { RolesProp, RequiredUserProps, UsersGroupsProps } from "./types";
import { useStyles, reactSelectStyles } from "./userModalStyles";
import Checkbox from "@material-ui/core/Checkbox";
import LoadSpinner from "common/LoadSpinner";
import clsx from "clsx";

type CreateNewUserModalProps = {
  handleClose: () => void;
  open: boolean;
};

export const CreateNewUserModal: React.FC<CreateNewUserModalProps> = ({
  handleClose,
  open
}) => {
  const classes = { ...useStyles(), ...reactSelectStyles };
  const dispatch = useDispatch();
  // const [preferredContact, setPreferredContact] = useState("Email");
  const [loading, setLoading] = useState(false);
  const [usersGroupSelection, setUsersGroupSelection] = useState<
    UsersGroupsProps[]
  >([]);
  const { register, handleSubmit, errors, control, watch } = useForm<
    RequiredUserProps
  >();

  const watchBasinktrakSuperAdmin = watch("basintrak_super_admin");
  const watchBasinktrakEngineer = watch("basintrak_engineer");
  const { superAdminAccountId } = useAppSelector(state => state.AppSlice);

  const {
    // usersAssetGroupIds,
    userInformation
  } = useSelector((state: RootState) => state.AppSlice);

  // const { role: topNavBarSelectedAccountRole, accountName: topNavBarAccountName } = useSelector((state: RootState) => state.AppSlice.selectedAccount);
  const {
    role: selectedAccountRole,
    accountId: selectedAccountId,
    accountName: selectedAccountName
  } = useSelector((state: RootState) => state.AccountSlice.selectedAccount);

  const { tenant_name, tenant_id, billing_address } = useSelector(
    (state: RootState) => state.AccountSlice.accountInformation
  );

  const { access_level } = useSelector(
    (state: RootState) => state.AppSlice.userInformation
  );

  const createAccountArray = (
    role: string,
    access_groups: { tenantName: string; tenantId: string }[],
    loss_of_comms_notify: any //Adding key for issue #155
  ) => {
    const { address_line, state_province, city, zip_code } = billing_address;

    if (selectedAccountId === superAdminAccountId) {
      return [
        {
          address: `${address_line} ${city}, ${state_province} ${zip_code} `,
          groups: [
            {
              asset_group_name: usersGroupSelection[0].label,
              asset_group_id: usersGroupSelection[0].value
            }
          ],
          roles: [
            role
              ? role
              : watchBasinktrakSuperAdmin
                ? "admin"
                : watchBasinktrakEngineer
                  ? "engineer"
                  : ""
          ],
          notify_select: ["email"], //Added notify_select in the accounts array as it will be specific to accounts. Github issue #106
          // Adding loss of comms key associated with specific account for issue #155
          loss_of_comms_notify:
            loss_of_comms_notify == undefined ? true : loss_of_comms_notify,
          tenant_id,
          tenant_name
        }
      ];
    } else {
      return [
        {
          address: `${address_line} ${city}, ${state_province} ${zip_code} `,
          groups: access_groups
            ? access_groups.map((item: any) => {
              return {
                asset_group_name: item.label,
                asset_group_id: item.value
              };
            })
            : [],
          roles: [role],
          notify_select: ["email"], //Added notify_select in the accounts array as it will be specific to accounts. Github issue #106
          // Adding loss of comms key associated with specific account for issue #155
          loss_of_comms_notify:
            loss_of_comms_notify == undefined ? true : loss_of_comms_notify,
          tenant_id,
          tenant_name
        }
      ];
    }
  };

  // Added function to handle enter button press for issue #292
  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const formatGroupLabel = (data: any) => (
    <div className={classes.group}>
      <span>{data.label}</span>
      <span className={classes.groupBadge}>{data.options.length}</span>
    </div>
  );

  const handleCreateUser = async ({
    first_name,
    last_name,
    email_id,
    office_contact_number,
    mobile_number,
    role,
    basintrak_super_admin,
    basintrak_engineer,
    access_groups,
    loss_of_comms_notify // Adding loss of comms key for issue #155
  }: RequiredUserProps) => {
    try {
      const newUserObject = {
        active: true,
        email: email_id,
        accounts: createAccountArray(role, access_groups, loss_of_comms_notify), //sending loss of comms key to be associated with specific account for issue #155
        given_name: first_name,
        family_name: last_name,
        phone_mobile: mobile_number,
        phone_office: office_contact_number,
        access_level: basintrak_super_admin
          ? "admin"
          : basintrak_engineer
            ? "engineer"
            : "general",
        // access_level: "admin",
        username: email_id
        // notify_select: ["email", "text"],
        // sub_arn: "",
      };
      setLoading(true);
      await AccountsAPI.postNewUser(newUserObject);
      setLoading(false);
      dispatch(setCallUsers(true));
      dispatch(setSuccessModalDescription("User successfully added!"));
      dispatch(setSuccessModalOpen(true));
      handleClose();
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        alert(error.response.data);
      } else if (error.request) {
        // the request was made but no response was received
        alert("Error creating new user: Access Denied");
      } else {
        // Something happened in setting up the request that triggered an Error
        alert("Error creating new user. Please try again.");
      }
      console.log(error);
      // alert(error); // ! subscription error here on alert but creates user
      setLoading(false);
      handleClose();
    }
  };

  useEffect(() => {
    (async () => {
      // if (tenant_id && access_level === "general") {
      if (tenant_id) {
        if (access_level === "admin") {
          try {
            const allAccounts = await AccountsAPI.getAllTenants();
            const allTenantsGroups = allAccounts.filter(
              (account: any) => account.tenant_id === tenant_id
            )[0];

            const getAllGroupsForTenantSuperAdmin = await AccountsAPI.retrieveGroupsInGroups(
              [allTenantsGroups.master_group_id]
            );

            setUsersGroupSelection(getAllGroupsForTenantSuperAdmin);
          } catch (error) {
            console.log(error);
          }
        } else {
          const tenantsGroups = userInformation.accounts
            .filter(account => account.tenant_id === tenant_id)[0]
            .groups.map(group => group.asset_group_id);
          try {
            const retrievedUserGroups = await AccountsAPI.retrieveGroupsInGroups(
              tenantsGroups
            );
            setUsersGroupSelection(retrievedUserGroups);
          } catch (error) {
            console.log(error);
          }
        }
      }
    })();
  }, [tenant_id, userInformation.accounts, access_level]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalOverlay}>
        {loading ? (
          <LoadSpinner />
        ) : (
          <form onSubmit={handleSubmit(handleCreateUser)}>
            <Grid container className={classes.modalBody}>
              <Grid item xs={12} className={classes.header}>
                <p className={classes.headerText}>Add new user</p>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={handleClose}
                />
              </Grid>
              <div className={classes.innerInputs}>
                <Grid
                  item
                  xs={12}
                  className={clsx(classes.inputGroup, {
                    [classes.isBasinTrakEmployee]: true
                  })}
                >
                  <div className={classes.accessAccount}>
                    Will be attached to: <p>{tenant_name}</p>
                  </div>
                  {/* {access_level === "general" || superAdminAccountId !== selectedAccountId ? null : ( */}
                  {userInformation.access_level === "admin" &&
                    superAdminAccountId === selectedAccountId ? (
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end"
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "inline-flex",
                          justifyContent: "flex-end",
                          alignItems: "center"
                        }}
                      >
                        <InputLabel
                          label="Basintrak Super Admin?"
                          name="basintrak_super_admin"
                          errors={errors}
                          isRequired={false}
                        />
                        <Controller
                          control={control}
                          name="basintrak_super_admin"
                          defaultValue={false}
                          rules={{
                            required: false
                          }}
                          render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                          ) => (
                            <Checkbox
                              onBlur={onBlur}
                              onChange={e => onChange(e.target.checked)}
                              checked={value}
                              inputRef={ref}
                              disabled={watchBasinktrakEngineer}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "inline-flex",
                          justifyContent: "flex-end",
                          alignItems: "center"
                        }}
                      >
                        <InputLabel
                          label="Basintrak Engineer?"
                          name="basintrak_engineer"
                          errors={errors}
                          isRequired={false}
                        />
                        <Controller
                          control={control}
                          name="basintrak_engineer"
                          defaultValue={false}
                          rules={{
                            required: false
                          }}
                          render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                          ) => (
                            <Checkbox
                              onBlur={onBlur}
                              onChange={e => onChange(e.target.checked)}
                              checked={value}
                              inputRef={ref}
                              disabled={watchBasinktrakSuperAdmin}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <div className={classes.inputGroup}>
                      <InputLabel
                        label="First name"
                        name="first_name"
                        errors={errors}
                      />
                      <input
                        name="first_name"
                        ref={register({
                          required: "This field is required.",
                          pattern: {
                            value: /^[a-zA-Z_ ]*$/,
                            message: "Letters only."
                          }
                        })}
                        // Added function to handle enter button press for issue #292
                        onKeyPress={handleKeyPress}
                        maxLength={InputLength.FirstName}
                        type="text"
                        placeholder="Enter"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.inputGroupRight}>
                      <InputLabel
                        label="Last name"
                        name="last_name"
                        errors={errors}
                      />
                      <input
                        name="last_name"
                        ref={register({
                          required: "This field is required.",
                          pattern: {
                            value: /^[a-zA-Z_ ]*$/,
                            message: "Letters only."
                          }
                        })}
                        // Added function to handle enter button press for issue #292
                        onKeyPress={handleKeyPress}
                        maxLength={InputLength.LastName}
                        type="text"
                        placeholder="Enter"
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <div className={classes.inputGroup}>
                      <div className={classes.labelContainer}>
                        <InputLabel
                          label="Email"
                          name="email_id"
                          errors={errors}
                          style={{ width: "100%", paddingRight: 3 }}
                        />
                      </div>
                      <input
                        name="email_id"
                        ref={register({
                          required: "This field is required.",
                          pattern: {
                            value: /^\S+@\S+\.\S+$/,
                            message: "Not a valid email."
                          }
                        })}
                        // Added function to handle enter button press for issue #292
                        onKeyPress={handleKeyPress}
                        maxLength={InputLength.Email}
                        type="text"
                        placeholder="Enter"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.inputGroupRight}>
                      <div className={classes.labelContainer}>
                        <InputLabel
                          label="Office contact number"
                          name="office_contact_number"
                          errors={errors}
                          style={{ width: "100%", paddingRight: 3 }}
                          isRequired={false}
                        />
                      </div>
                      <input
                        name="office_contact_number"
                        ref={register({
                          required: false,
                          pattern: {
                            value: regex.validPhone,
                            message: "Please enter in format xxx-xxx-xxxx"
                          }
                        })}
                        // Added function to handle enter button press for issue #292
                        onKeyPress={handleKeyPress}
                        type="text"
                        placeholder="Enter"
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <div className={classes.inputGroup}>
                      <div className={classes.labelContainer}>
                        <InputLabel
                          label="Mobile number"
                          name="mobile_number"
                          errors={errors}
                          style={{ width: "100%", paddingRight: 3 }}
                          isRequired={true}
                        />
                      </div>
                      <input
                        name="mobile_number"
                        ref={register({
                          required: "This field is required.",
                          pattern: {
                            value: regex.validPhone,
                            message: "Please enter in format xxx-xxx-xxxx"
                          }
                        })}
                        // Added function to handle enter button press for issue #292
                        onKeyPress={handleKeyPress}
                        type="text"
                        placeholder="Enter"
                      />
                    </div>
                  </Grid>
                  {/* Added checkbox with text for enabling loss of comms for issue #155 */}
                  <Grid
                    item
                    xs={6}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className={classes.inputGroup}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "inline-flex",
                          justifyContent: "flex-end",
                          alignItems: "center"
                        }}
                      >
                        <Controller
                          control={control}
                          name="loss_of_comms_notify"
                          defaultValue={true}
                          rules={{
                            required: false
                          }}
                          render={(
                            { onChange, onBlur, value, name, ref },
                            { invalid, isTouched, isDirty }
                          ) => (
                            <Checkbox
                              onBlur={onBlur}
                              onChange={e => onChange(e.target.checked)}
                              checked={value}
                              inputRef={ref}
                            />
                          )}
                        />
                        <InputLabel
                          label="Enable loss of comms notifications"
                          name="loss_of_comms_notify"
                          errors={errors}
                          isRequired={false}
                        />
                      </Grid>
                    </div>
                  </Grid>
                  {watchBasinktrakSuperAdmin ||
                    watchBasinktrakEngineer ||
                    selectedAccountId === superAdminAccountId ? null : (
                    <Grid container className={classes.accountInformation}>
                      <Grid item xs={4} className={classes.inputGroup}>
                        <InputLabel label="Role" name="role" errors={errors} />
                        <select
                          name="role"
                          ref={register({
                            required: "Choose role."
                          })}
                          // Added function to handle enter button press for issue #292
                          onKeyPress={handleKeyPress}
                        >
                          <option value="">Select role...</option>
                          {selectedAccountRole === "admin" &&
                            adminRoles.map((role: RolesProp, index: number) => (
                              <option
                                key={role.name + index}
                                value={role.value}
                              >
                                {role.name}
                              </option>
                            ))}
                          {selectedAccountRole === "power_user" &&
                            powerUserRoles.map(
                              (role: RolesProp, index: number) => (
                                <option
                                  key={role.name + index}
                                  value={role.value}
                                >
                                  {role.name}
                                </option>
                              )
                            )}
                        </select>
                      </Grid>
                      <Grid item xs={8} className={classes.inputGroupRight}>
                        <InputLabel
                          label="Access group(s)"
                          // name={`items[${index}].access_groups`}
                          name="access_groups"
                          errors={errors}
                          isRequired={false}
                        />
                        <Controller
                          render={props => {
                            return (
                              <Select
                                {...props}
                                menuPosition={"fixed"}
                                styles={reactSelectStyles}
                                defaultValue={null}
                                formatGroupLabel={formatGroupLabel}
                                options={usersGroupSelection}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                isMulti
                              />
                            );
                          }}
                          name="access_groups"
                          defaultValue={null}
                          type="select"
                          control={control}
                          rules={{
                            required: false
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {selectedAccountId === superAdminAccountId &&
                    access_level === "admin" && (
                      <Grid container className={classes.accountInformation}>
                        <Grid item xs={4} className={classes.inputGroup}>
                          <div></div>
                        </Grid>
                        <Grid item xs={8} className={classes.inputGroupRight}>
                          <InputLabel
                            label="Access group(s)"
                            // name={`items[${index}].access_groups`}
                            name="access_groups"
                            errors={errors}
                            isRequired={false}
                          />
                          <Controller
                            render={props => {
                              return (
                                <Select
                                  {...props}
                                  menuPosition={"fixed"}
                                  styles={reactSelectStyles}
                                  defaultValue={usersGroupSelection[0]}
                                  // defaultValue={null}
                                  formatGroupLabel={formatGroupLabel}
                                  options={usersGroupSelection}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  isMulti
                                />
                              );
                            }}
                            name="access_groups"
                            defaultValue={usersGroupSelection[0]}
                            // defaultValue={null}
                            type="select"
                            control={control}
                            rules={{
                              required: false
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              </div>
              <Grid
                item
                xs={12}
                className={classes.actionItems}
                style={{ justifyContent: "flex-end" }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Button
                    type="submit"
                    color="white"
                    onClick={() => {
                      handleClose();
                      // const fieldsToRemove = fields.map((_, index) => index);
                      // remove(fieldsToRemove);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="defaultTheme">
                    Save
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default CreateNewUserModal;
