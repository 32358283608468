/*Issue fixed for github issue #204*/
import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import successIcon from "assets/bt-logins/success.svg";
import { SimpleModalProps } from "./types";
import { Colors } from "common/Colors";
import Button from "components/common-buttons/Button";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

export const SuccessSignUpModal: React.FC<SimpleModalProps> = ({
  open,
  handleClose
}) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <img src={successIcon} alt="" />
          <h2 id="simple-modal-title" className={classes.modalTitle}>
            Account created successfully!
          </h2>
          <p id="simple-modal-description" className={classes.modalDescription}>
            An email has been sent to Example@companyname.com, for account
            verification.
          </p>
          <p id="simple-modal-description" className={classes.modalDescription}>
            If you cannot find the mail in the inbox, please check your
            junk/spam folders as well.
          </p>
          <Button type="button" color="defaultTheme" onClick={handleClose}>
            OK
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default SuccessSignUpModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      color: "#fff",
      fontSize: "16px",
      width: 380,
      height: 322,
      backgroundColor: "#00D160",
      border: "none",
      outline: "none",
      padding: "0 2rem"
    },
    modalTitle: {
      fontSize: "24px",
      fontWeight: 600
    },
    modalDescription: {
      fontSize: "14px",
      textAlign: "center"
    },
    okButton: {
      backgroundColor: Colors.MainColor, //updated color from #6D1919 to #253746 for issue #204
      width: "47px",
      height: "30px",
      color: "#fff",
      border: "none",
      outline: "none",
      cursor: "pointer"
    }
  })
);
