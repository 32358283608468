import React from "react";
import { commonButtonStyle } from "./styles";

export type ButtonProps = {
  type: "button" | "submit";
  color: "defaultTheme" | "white";
  style?: Object;
  onClick?: Function;
  children: React.ReactNode;
  disabled?: boolean;
};
export const Button: React.FC<ButtonProps> = ({
  type,
  color,
  onClick,
  children,
  style,
  disabled,
}) => {
  const classes = commonButtonStyle();
  return (
    <button
      type={type}
      className={color === "defaultTheme" ? classes.defaultTheme : classes.white}
      style={style || {}}
      onClick={() => onClick && onClick()}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
