/*Issue fixed for github issue #213 */
import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import AssetAPI from "services/assetsAPI";
import search from "assets/bt-assets/header_search_w.svg";
// import diamond from "assets/bt-assets/Group 137.svg";
import { useTheme } from "@material-ui/core/styles";
import TenantDisplay from "features/assets-page/asset-file-display/TenantDisplay";
import GroupsDisplay from "features/assets-page/asset-file-display/GroupsDisplay";
import DefaultDisplay from "features/assets-page/asset-file-display/DefaultDisplay";
import GroupModalCard from "components/group-modal/GroupModalCard";
import AddNewAssetModal from "components/asset-modals/AddNewAssetModal";
import AssetSuccessModal from "components/success-modal/SuccessAssetModal";
import AssetsUpdatedSuccessModal from "components/success-modal/SuccessAssetUpdated";
import AddNewGroupModal from "components/asset-modals/AddNewGroupModal";
import GroupSuccessModal from "components/success-modal/SuccessGroupModal";
import AssetsDisplay from "./asset-file-display/AssetsDisplay";
import { useDispatch, useSelector } from "react-redux";
import {
  setCallGroupProfile,
  setCallAssetProfile,
} from "components/asset-modals/assetGroupModalSlice";
import {
  setGroupDelete,
  setAssetDelete,
} from "components/group-modal/groupModalSlice";
import { RootState } from "app/rootReducer";
import SortableTree, {
  getNodeAtPath,
  // getNodeKey,
  addNodeUnderParent,
  // removeNode,
  changeNodeAtPath,
  removeNodeAtPath,
} from "react-sortable-tree";
import FileExplorerTheme from "react-sortable-tree-theme-file-explorer";
import {
  TenantGroupObject,
  AssetOrganizerProps,
  ChildrenGroupProps,
} from "./types";
import "react-sortable-tree/style.css";
import AddDeviceModal from "features/assets-page/add-new-device/AddDeviceModal";
import SuccessModal from "components/success-modal/SuccessModal";
import { devicesAPI } from "services/devicesAPI";
import {
  setDeviceSelection,
  setParameterSelection,
  setAssetDeviceList,
  setCurrentAssetId,
  setAssetParameterList,
} from "./assetsDashboardSlice";
import { DeviceParametersProps } from "features/devices-page/device-settings/types";
import LoadSpinner from "common/LoadSpinner";
import AccountAPI from "services/accountsAPI";
import UnsuccessModal from "components/success-modal/UnsuccessfullModal"; //imported for issue #213

type AssetsDashboardProps = {
  // accounts: [];
  parentTenantName: string;
  parentTenantId: string;
  userId: string;
};

interface TreeIndex {
  treeIndex: number;
}
interface IDrag {
  draggedNode: object;
  isDragging: boolean;
}

const placeholder = [
  {
    title: "Placeholder",
  },
];

const AssetsDashboard: React.FC<AssetsDashboardProps> = ({
  // accounts,
  parentTenantName,
  parentTenantId,
  userId,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [addAssetModal, setAddAssetModal] = useState(false);
  const [assetSuccessOpen, setAssetSuccessOpen] = useState(false);
  const [addGroupModal, setAddGroupModal] = useState(false);
  const [groupSuccessOpen, setGroupSuccessOpen] = useState(false);
  const [openGroupModal, setOpenGroupModal] = useState(false);
  const [assetUpdateSuccessOpen, setAssetUpdateSuccessOpen] = useState(false);
  const [groupTitle, setGroupTitle] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [groupButtonName, setGroupButtonName] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [selectedGroupSharing, setSelectedGroupSharing] =
    useState<boolean>(false);
  const [treeDepth, setTreeDepth] = useState<string>("parent");
  const [nodeObject, setNodeObject] = useState({});
  const [selectedNodeId, setSelectedNodeId] = useState("");
  const [path, setPath] = useState<number[]>([]);
  const [treeindex, setTreeindex] = useState(0);
  const [treeData, setTreeData] = useState<any>([]);
  const [selectedParent, setSelectedParent] = useState<any>({});
  const [addDeviceModal, setAddDeviceModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const { callGroupProfile, callAssetProfile } = useSelector(
    (state: RootState) => state.AssetGroupModalSlice
  );
  const {
    groupName,
    newAssetName,
    assetDeviceList,
    assetParameterList,
    currentAssetId,
  } = useSelector((state: RootState) => state.AssetDashboardSlice);

  const { groupDelete, assetDelete } = useSelector(
    (state: RootState) => state.GroupModalSlice
  );

  const { access_level } = useSelector(
    (state: RootState) => state.AppSlice.userInformation
  );

  const getNodeKey = ({ treeIndex }: TreeIndex) => treeIndex;

  const openAddAssetModal = () => {
    setAddAssetModal(true);
  };

  const openAddDeviceModal = () => {
    setAddDeviceModal(true);
  };

  const closeAddDeviceModal = () => {
    setAddDeviceModal(false);
  };

  const openAddGroupModal = () => {
    setAddGroupModal(true);
  };

  const closeAssetModal = () => {
    setAddAssetModal(false);
  };

  const closeGroupModal = () => {
    setAddGroupModal(false);
  };

  const handleAssetSave = () => {
    setAssetSuccessOpen(true);
    setAddAssetModal(false);
  };

  const handleGroupModals = () => {
    setGroupSuccessOpen(true);
    setAddGroupModal(false);
  };

  const handleSuccessModal = () => {
    setAssetSuccessOpen(false);
    setGroupSuccessOpen(false);
    setAssetUpdateSuccessOpen(false);
  };

  const returnToParent = () => {
    setTreeDepth("parent");
  };

  const handleAssetUpdateModal = () => {
    setAssetUpdateSuccessOpen(true);
  };

  const openDeleteDeactivateGroupModal = (
    groupType: string,
    id: string,
    groupName: string,
    groupSharing: boolean
  ) => {
    // console.log(groupType, id, groupName, groupSharing);
    setSelectedGroupId(id);
    setSelectedGroupName(groupName || "");
    setSelectedGroupSharing(groupSharing || false);
    setOpenGroupModal(true);
    switch (groupType) {
      case "delete":
        setGroupTitle("Delete group");
        setGroupDescription(
          `<p>
       Are you sure you want to delete this group?
       </p>
       <p>
       If yes, all the sub-groups, assets and devices configured in this group will be removed from asset management.
       </p>
       `
        );
        setGroupButtonName("Delete Group");
        break;

      case "deactivate":
        setGroupTitle("Deactivate group");
        setGroupDescription(
          `<p>
            Are you sure you want to deactivate this group?
            </p>
            <p>
            If yes, all the sub-group, assets and devices of this group will be read only in asset management and will not be shown in dashboard.
            </p>
            `
        );
        setGroupButtonName("Deactivate Group");
        break;

      case "activate":
        setGroupTitle("Activate group");
        setGroupDescription(
          `<p>
            Are you sure you want to activate this group?
            </p>
            <p>
            If yes, all these updates will also start showing in the dashboard.
            </p>
            `
        );
        setGroupButtonName("Activate Group");
        break;

      case "update group":
        setGroupTitle("Save group updates");
        setGroupDescription(
          `<p>
              Are you sure you want to save these updates?
              </p>
              <p>
              If yes, all these updates will also start showing in the dashboard.
              </p>
              `
        );
        setGroupButtonName("Update group");
        break;

      case "delete asset":
        // console.log("Delete Asset");
        setGroupTitle("Delete Asset");
        setGroupDescription(
          `<p>
              Are you sure you want to delete this asset?
              </p>
              <p>
              If yes, all the sub-assets and devices configured in this assets will be removed from asset management.
              </p>
              `
        );
        setGroupButtonName("Delete Asset");
        break;

      case "activate asset":
        // console.log("activate");
        setGroupTitle("Activate asset");
        setGroupDescription(
          `<p>
        Are you sure you want to activate this asset?
        </p>
        <p>
        If yes, all the sub-assets and devices of this asset will be editable in asset management and will start showing in dashboard.
        </p>
        `
        );
        setGroupButtonName("Activate asset");
        break;
      case "deactivate asset":
        // console.log("Deactivate asset");
        setGroupTitle("Deactivate asset");
        setGroupDescription(
          `<p>
                  Are you sure you want to deactivate this asset?
                  </p>
                  <p>
                  If yes, all the sub-assets and devices of this asset will be deactivated in asset management and will not show in dashboard.
                  </p>
                  `
        );
        setGroupButtonName("Deactivate asset");
        break;
      default:
        break;
    }
  };

  const closeDeleteDeactivateGroupModal = () => {
    setOpenGroupModal(false);
    setGroupTitle("");
    setGroupDescription("");
    setGroupButtonName("");
  };

  const handleTenantTree = useCallback(
    (response: any) => {
      const tenantGroups = response.map(
        (groupObject: TenantGroupObject, index: number) => {
          const tenantChildGroups = (groupObject.children_groups || []).map(
            (groupAsset: ChildrenGroupProps) => {
              return {
                assetGroupId: groupAsset.asset_group_id,
                title: groupAsset.group_name,
                childType: "group",
                tenantId: groupObject.tenant_id,
                children: placeholder,
                isAssetGroup: groupObject.is_asset_group,
              };
            }
          );

          const tenantChildAssets = (groupObject.asset_organizer || []).map(
            (asset: AssetOrganizerProps) => {
              return {
                assetGroupId: asset.asset_id,
                title: asset.asset_name,
                childType: "asset",
                tenantId: groupObject.tenant_id,
                children: placeholder,
                isAssetGroup: false,
              };
            }
          );

          return {
            id: index,
            parentTenantId: parentTenantId,
            assetGroupId: groupObject.asset_group_id,
            title: groupObject.group_name,
            childType: "group",
            expanded: true,
            groupSharing: groupObject.group_sharing,
            groupStatus: groupObject.group_status,
            isAssetGroup: groupObject.is_asset_group,
            children: [...tenantChildGroups, ...tenantChildAssets],
          };
        }
      );

      const parentGroup = tenantGroups[0];
      if (parentGroup) {
        // initialize values for group display component
        setNodeObject(parentGroup);
        setTreeDepth("group");
        setSelectedNodeId(parentGroup.assetGroupId || "");
        setPath([0]);
        setTreeindex(0);
      }

      // const tenantTree: any = [ // creates an unnecessary top-level parent
      //   {
      //     tenantId: parentTenantId,
      //     tenantType: "parent",
      //     expanded: true,
      //     title: parentTenantName,
      //     children: tenantGroups,
      //   },
      // ];

      setTreeData(tenantGroups);
      setLoading(false);
    },
    [parentTenantId]
  );

  const getGroupListForTenant = useCallback(async () => {
    // let response: any = [];
    // console.log("USER ID: ", userId, "PARENT ID: ", parentTenantId);
    try {
      if (["admin", "engineer"].includes(access_level)) {
        // console.log("INSIDE ADMIN OR ENGINEER ROLE");
        const allTenants = await AccountAPI.getAllTenants();
        const masterGroupId = allTenants.filter(
          (tenant: any) => tenant.tenant_id === parentTenantId
        )[0].master_group_id;
        // console.log("MASTER GROUP ID", masterGroupId);
        const response = await AssetAPI.retrieveAssetGroupProfile(
          masterGroupId
        );

        handleTenantTree([response]);
      } else {
        // console.log("INSIDE ALL OTHER ROLE");
        const response = await AssetAPI.getGroupListForUserByTenant(
          userId,
          parentTenantId
        );

        handleTenantTree(response);
      }
    } catch (error) {
      console.log(error);
    }
  }, [userId, parentTenantId, access_level, handleTenantTree]);

  const handleDeviceList = useCallback(
    async (assetId: string) => {
      // console.log("handleDeviceList", assetId);
      try {
        const response = await devicesAPI.listDevicesForGivenAsset(assetId);
        const deviceList = response.map(
          (device: { device_name: string; device_id: string }) => {
            return {
              label: device.device_name,
              value: device.device_id,
            };
          }
        );

        dispatch(setDeviceSelection(deviceList));
      } catch (error) {
        alert(error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!currentAssetId) {
      return;
    }
    if (currentAssetId) {
      handleDeviceList(currentAssetId);
    }
  }, [assetDeviceList.length, handleDeviceList, currentAssetId]);

  const handleAssetsParameterList = useCallback(
    async (assetId: string) => {
      // console.log("handleAssetsParameterList", assetId);
      try {
        const response = await devicesAPI.retrieveDeviceParameters(assetId);
        // console.log("handleAssetsParameterList", response);
        const parametersList = response.map(
          (parameter: DeviceParametersProps) => {
            return {
              label: `${parameter.device_name} - ${parameter.parameter_name}`, // ! add device name to parameter name
              value: `["${parameter.device_id}","${parameter.device_parameter_id}"]`,
            };
          }
        );

        dispatch(setParameterSelection(parametersList));
      } catch (error) {
        alert(error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!currentAssetId) {
      return;
    }

    if (currentAssetId) {
      handleAssetsParameterList(currentAssetId);
    }
  }, [
    assetParameterList.length,
    assetDeviceList.length,
    handleAssetsParameterList,
    currentAssetId,
  ]);

  const handleAssetsDevicesAndParameters = async (assetId: string) => {
    try {
      const assetProfile = await AssetAPI.retrieveAssetProfile(assetId);
      const { asset_devices, asset_parameters } = assetProfile;

      dispatch(setAssetDeviceList(asset_devices));
      dispatch(setAssetParameterList(asset_parameters));
    } catch (error) {
      alert(error);
    }
  };

  const handleSelectedNode = (node: any, path: number[], treeIndex: number) => {
    // console.log(node, node.assetGroupId);
    // ! save node, path, treeIndex in variables to populate tree data when creating new group, asset or deleting.
    // console.log("SELECTED NODE:", node, "path:", path);
    setTreeDepth(node.tenantType || node.childType);
    setNodeObject(node);
    setSelectedNodeId(node.assetGroupId || "");
    setPath(path);
    setTreeindex(treeIndex);

    if (node.childType !== "asset") {
      return;
    } else {
      handleDeviceList(node.assetGroupId);
      handleAssetsDevicesAndParameters(node.assetGroupId);
      dispatch(setCurrentAssetId(node.assetGroupId));
    }
  };

  useEffect(() => {
    if (userId && parentTenantId) {
      setLoading(true);
      getGroupListForTenant();
    }
  }, [getGroupListForTenant, userId, parentTenantId]);

  //! add some logic that will allow the user to click on a path and update the node objects to help with traversing the application

  useEffect(() => {
    if (treeDepth === "parent" && treeData) {
      // console.log("TREE DATA", treeData);
      const parentNode = treeData[0];
      setNodeObject(parentNode);
    }
  }, [treeDepth, treeData]);

  const handleMouseDown = (path: number[]) => {
    const parentNode = getNodeAtPath({
      treeData: treeData,
      path: path.slice(0, path.length - 1),
      getNodeKey: ({ treeIndex }: TreeIndex) => treeIndex,
      ignoreCollapsed: true,
    });
    setSelectedParent(parentNode);
  };

  const handleMove = (moveData: any) => {
    if (!moveData.nextParentNode.childType || !selectedParent.node.childType) {
      console.log(
        "move async call prevented: no child type on previous or target group"
      );
      return;
    }
    const targetGroupId = moveData.nextParentNode.assetGroupId;
    const previousGroupId =
      selectedParent.node.assetGroupId || selectedParent.node.tenantId;
    if (targetGroupId === previousGroupId) {
      console.log(
        "move async call prevented: previous and target groups are the same"
      );
      return;
    }
    const { assetGroupId } = moveData.node;
    if (moveData.node.childType === "group") {
      AssetAPI.moveAssetGroup(assetGroupId, targetGroupId, previousGroupId);
    } else if (moveData.node.childType === "asset") {
      let body = {
        is_target_group: moveData.nextParentNode.childType === "group",
        is_previous_group: selectedParent.node.childType === "group",
        moving_asset_id: assetGroupId,
        previous_id: previousGroupId,
        target_id: targetGroupId,
      };
      AssetAPI.moveAsset(body);
    }
    setSelectedParent({});
  };

  const handleCanDrop = (moveData: any) => {
    if (!moveData || !moveData.nextParent) {
      if (process.env.NODE_ENV === 'development') {
        console.log("Forbidden: Cannot remove from tree");
      }
      return false;
    }

    const { children, childType } = moveData.node;
    const parentChildren = moveData.nextParent.children;
    const parentChildType = moveData.nextParent.childType;
    const prevParentChildType = moveData.prevParent.childType;
    const isComplexAsset =
      childType === "asset" && children && children.length > 0;
    const isParentComplexAsset =
      parentChildType === "asset" &&
      parentChildren &&
      parentChildren.length > 0;

    if (!childType || !parentChildType || !prevParentChildType) {
      console.log(
        "Forbidden: Cannot move to or from highest tier of hierarchy"
      );
      return false;
    }

    if (isComplexAsset && isParentComplexAsset) {
      console.log(
        "Forbidden: Cannot place complex asset inside a complex asset"
      );
      return false;
    }

    if (childType === "group" && parentChildType === "asset") {
      console.log(
        "Forbidden: Cannot place group inside of asset or complex asset"
      );
      return false;
    }

    return true;
  };

  const getPlaceholderPath = (path: any) => {
    const placeHolderPath = [...path];
    const placeHolderNumber = placeHolderPath[placeHolderPath.length - 1] + 1;
    placeHolderPath.push(placeHolderNumber);
    return placeHolderPath;
  };

  const populateGroupChildren = useCallback(
    async (assetGroupId: string, path: number[]) => {
      try {
        const groupProfile = await AssetAPI.retrieveAssetGroupProfile(
          assetGroupId
        );

        groupProfile.children_groups.length > 0 &&
          groupProfile.children_groups.forEach(
            (groupAsset: ChildrenGroupProps) => {
              setTreeData((treeData: any) => {
                return addNodeUnderParent({
                  treeData: treeData,
                  parentKey: path[path.length - 1],
                  expandParent: true,
                  getNodeKey: ({ treeIndex }: TreeIndex) => treeIndex,
                  newNode: {
                    assetGroupId: groupAsset.asset_group_id,
                    title: groupAsset.group_name,
                    childType: "group",
                    tenantId: groupProfile.tenant_id,
                    children: placeholder,
                    isAssetGroup: groupProfile.is_asset_group,
                  },
                }).treeData;
              });
            }
          );

        groupProfile.asset_organizer.length > 0 &&
          groupProfile.asset_organizer.forEach((asset: AssetOrganizerProps) => {
            setTreeData((treeData: any) => {
              return addNodeUnderParent({
                treeData: treeData,
                parentKey: path[path.length - 1],
                expandParent: true,
                getNodeKey: ({ treeIndex }: TreeIndex) => treeIndex,
                newNode: {
                  assetGroupId: asset.asset_id,
                  title: asset.asset_name,
                  childType: "asset",
                  tenantId: groupProfile.tenant_id,
                  children: placeholder,
                  isAssetGroup: false,
                },
              }).treeData;
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const populateAssetsChildren = async (assetId: any, path: number[]) => {
    try {
      const assetProfile = await AssetAPI.retrieveAssetProfile(assetId);

      assetProfile.children_asset.length > 0 &&
        assetProfile.children_asset.forEach((asset: AssetOrganizerProps) => {
          setTreeData((treeData: any) => {
            return addNodeUnderParent({
              treeData: treeData,
              parentKey: path[path.length - 1],
              expandParent: true,
              getNodeKey: ({ treeIndex }: TreeIndex) => treeIndex,
              newNode: {
                assetGroupId: asset.asset_id,
                title: asset.asset_name,
                childType: "asset",
                tenantId: assetProfile.tenant_id,
                children: placeholder,
                isAssetGroup: false,
              },
            }).treeData;
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const addSingleGroupToParent = useCallback(
    async (selectedNodeId: string, path: number[]) => {
      try {
        const groupProfile = await AssetAPI.retrieveAssetGroupProfile(
          selectedNodeId
        );
        const groupProfileChildren = groupProfile.children_groups;

        if (groupProfileChildren.length > 0) {
          const lastIndex = groupProfileChildren.length - 1;
          const newChild = groupProfileChildren[lastIndex];

          setTreeData((treeData: any) => {
            return addNodeUnderParent({
              treeData: treeData,
              parentKey: path[path.length - 1],
              expandParent: true,
              getNodeKey: ({ treeIndex }: TreeIndex) => treeIndex,
              newNode: {
                assetGroupId: newChild.asset_group_id,
                title: newChild.group_name,
                childType: "group",
                tenantId: groupProfile.tenant_id,
                children: placeholder,
                isAssetGroup: groupProfile.is_asset_group,
              },
            }).treeData;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const addSingleAssetToParent = useCallback(
    async (selectedNodeId: string, path: number[]) => {
      try {
        const groupProfile = await AssetAPI.retrieveAssetGroupProfile(
          selectedNodeId
        );
        const groupProfileChildren = groupProfile.asset_organizer;

        if (groupProfileChildren.length > 0) {
          const lastIndex = groupProfileChildren.length - 1;
          const newChild = groupProfileChildren[lastIndex];

          setTreeData((treeData: any) => {
            return addNodeUnderParent({
              treeData: treeData,
              parentKey: path[path.length - 1],
              expandParent: true,
              getNodeKey: ({ treeIndex }: TreeIndex) => treeIndex,
              newNode: {
                assetGroupId: newChild.asset_id,
                title: newChild.asset_name,
                childType: "asset",
                tenantId: groupProfile.tenant_id,
                children: placeholder,
                isAssetGroup: false,
              },
            }).treeData;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  useEffect(() => {
    if (callGroupProfile && treeDepth === "group") {
      addSingleGroupToParent(selectedNodeId, path);
      dispatch(setCallGroupProfile(false));
    }

    if (callAssetProfile && treeDepth === "group") {
      addSingleAssetToParent(selectedNodeId, path);
      dispatch(setCallAssetProfile(false));
    }
  }, [
    callGroupProfile,
    callAssetProfile,
    dispatch,
    selectedNodeId,
    path,
    addSingleGroupToParent,
    addSingleAssetToParent,
    treeDepth,
  ]);

  const addSingleAssetToComplexAsset = useCallback(
    async (selectedNodeId: string, path: number[]) => {
      try {
        const assetProfile = await AssetAPI.retrieveAssetProfile(
          selectedNodeId
        );
        const assetsChildren = assetProfile.children_asset;

        if (assetsChildren.length > 0) {
          const lastIndex = assetsChildren.length - 1;
          const newChild = assetsChildren[lastIndex];

          setTreeData((treeData: any) => {
            return addNodeUnderParent({
              treeData: treeData,
              parentKey: path[path.length - 1],
              expandParent: true,
              getNodeKey: ({ treeIndex }: TreeIndex) => treeIndex,
              newNode: {
                assetGroupId: newChild.asset_id,
                title: newChild.asset_name,
                childType: "asset",
                tenantId: assetProfile.tenant_id,
                children: placeholder,
                expanded: true,
                isAssetGroup: false,
              },
            }).treeData;
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  useEffect(() => {
    if (callAssetProfile && treeDepth === "asset") {
      addSingleAssetToComplexAsset(selectedNodeId, path);
      dispatch(setCallAssetProfile(false));
    }
  }, [
    callAssetProfile,
    dispatch,
    selectedNodeId,
    path,
    addSingleAssetToComplexAsset,
    treeDepth,
  ]);

  const removePlaceholderChild = (path: number[]) => {
    setTreeData((treeData: any) => {
      return removeNodeAtPath({
        treeData: treeData,
        path: getPlaceholderPath(path),
        getNodeKey,
      });
    });
  };

  const callNodesChildren = async (
    node: any,
    expanded: boolean,
    path: number[]
  ) => {
    if (
      expanded &&
      node.childType === "group" &&
      node.children[0].title === "Placeholder"
    ) {
      removePlaceholderChild(path);
      // populateGroupChildren(groupProfile, path);
      populateGroupChildren(node.assetGroupId, path);
    } else if (
      expanded &&
      node.childType === "asset" &&
      node.children[0].title === "Placeholder"
    ) {
      removePlaceholderChild(path);
      // populateAssetsChildren(assetProfile, path);
      populateAssetsChildren(node.assetGroupId, path);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (groupDelete) {
      // console.log("DELETING A GROUP", path);
      setTreeData((treeData: any) => {
        return removeNodeAtPath({
          treeData: treeData,
          path: path,
          getNodeKey,
        });
      });
      dispatch(setGroupDelete(false));
    }

    if (assetDelete) {
      // console.log("DELETING AN ASSET", path);
      setTreeData((treeData: any) => {
        return removeNodeAtPath({
          treeData: treeData,
          path: path,
          getNodeKey,
        });
      });
      dispatch(setAssetDelete(false));
    }
  }, [groupDelete, assetDelete, path, dispatch]);

  useEffect(() => {
    // console.log("GROUP NAME CHANGED: ", groupName, path, nodeObject);
    setTreeData((treeData: any) => {
      return changeNodeAtPath({
        treeData,
        path,
        getNodeKey,
        newNode: { ...nodeObject, title: groupName },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName]);

  useEffect(() => {
    // console.log("GROUP NAME CHANGED: ", newAssetName, path, nodeObject);
    setTreeData((treeData: any) => {
      return changeNodeAtPath({
        treeData,
        path,
        getNodeKey,
        newNode: { ...nodeObject, title: newAssetName },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAssetName]);

  return loading ? (
    <LoadSpinner center={true} size="90vh" />
  ) : (
    <>
      <Grid item xs={4}>
        <div className={classes.fileContainer}>
          {/* // ! commented code - search for later phase */}
          {/* <div className={classes.search}>
            <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              placeholder="Search for group or asset"
            />
            <div className={classes.searchbutton}>
              <img src={search} alt="" style={{ height: "1.5rem" }} />
            </div>
          </div> */}
          <div
            style={{
              // margin: "2rem 0", // ! uncomment this when search is implemented
              height: 600,
            }}
          >
            <SortableTree
              treeData={treeData}
              theme={FileExplorerTheme}
              onChange={(treeData: object[]) => {
                // console.log("ON CHANGE EVENT:", treeData);
                setTreeData(treeData);
              }}
              onMoveNode={(treeData: object[]) => handleMove(treeData)}
              canDrop={(moveData: any) => handleCanDrop(moveData)}
              onVisibilityToggle={({
                treeData,
                node,
                expanded,
                path,
              }: {
                treeData: object[];
                node: any;
                expanded: boolean;
                path: number[];
              }) => {
                callNodesChildren(node, expanded, path);
              }}
              generateNodeProps={({
                node,
                path,
                treeIndex,
              }: {
                node: any;
                path: number[];
                treeIndex: number;
              }) => {
                return {
                  style: {
                    cursor: "pointer",
                  },
                  title: (
                    <>
                      {node.childType === "group" ||
                        node.tenantType === "parent" ? (
                        <span style={{ fontSize: 18 }}>
                          {!node.expanded || node.children.length === 0 ? (
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3 3.75V14.25H15V5.25H8.6895L7.1895 3.75H3ZM9.3105 3.75H15.75C15.9489 3.75 16.1397 3.82902 16.2803 3.96967C16.421 4.11032 16.5 4.30109 16.5 4.5V15C16.5 15.1989 16.421 15.3897 16.2803 15.5303C16.1397 15.671 15.9489 15.75 15.75 15.75H2.25C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25H7.8105L9.3105 3.75Z"
                                fill={theme.palette.primary.light}
                              />
                            </svg>
                          ) : (
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.25 15.75C2.05109 15.75 1.86032 15.671 1.71967 15.5303C1.57902 15.3897 1.5 15.1989 1.5 15V3C1.5 2.80109 1.57902 2.61032 1.71967 2.46967C1.86032 2.32902 2.05109 2.25 2.25 2.25H7.8105L9.3105 3.75H15C15.1989 3.75 15.3897 3.82902 15.5303 3.96967C15.671 4.11032 15.75 4.30109 15.75 4.5V6.75H14.25V5.25H8.6895L7.1895 3.75H3V12.7485L4.125 8.25H16.875L15.1425 15.1823C15.1019 15.3444 15.0082 15.4884 14.8764 15.5913C14.7446 15.6941 14.5822 15.75 14.415 15.75H2.25ZM14.9535 9.75H5.2965L4.1715 14.25H13.8285L14.9535 9.75Z"
                                fill={theme.palette.primary.light}
                              />
                            </svg>
                          )}{" "}
                          {node.title}
                        </span>
                      ) : (
                        <div style={{ fontSize: 18, display: "flex" }}>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="29"
                              height="29"
                              fill="#fafafa"
                              stroke={theme.palette.primary.light}
                            />
                            <path
                              d="M15 5L19.243 9.243L17.828 10.657L15 7.828L12.172 10.657L10.757 9.243L15 5ZM5 15L9.243 10.757L10.657 12.172L7.828 15L10.657 17.828L9.243 19.243L5 15ZM25 15L20.757 19.243L19.343 17.828L22.172 15L19.343 12.172L20.757 10.757L25 15ZM15 17C14.4696 17 13.9609 16.7893 13.5858 16.4142C13.2107 16.0391 13 15.5304 13 15C13 14.4696 13.2107 13.9609 13.5858 13.5858C13.9609 13.2107 14.4696 13 15 13C15.5304 13 16.0391 13.2107 16.4142 13.5858C16.7893 13.9609 17 14.4696 17 15C17 15.5304 16.7893 16.0391 16.4142 16.4142C16.0391 16.7893 15.5304 17 15 17ZM15 25L10.757 20.757L12.172 19.343L15 22.172L17.828 19.343L19.243 20.757L15 25Z"
                              fill={theme.palette.primary.light}
                            />
                          </svg>
                          <span
                            style={{ paddingTop: ".2rem", marginLeft: ".5rem" }}
                          >
                            {node.title}
                          </span>
                        </div>
                      )}
                    </>
                  ),
                  onClick: () => {
                    handleSelectedNode(node, path, treeIndex);
                  },
                  onMouseDown: () => {
                    handleMouseDown(path);
                  },
                };
              }}
            />
          </div>
        </div>
      </Grid>
      <Grid item xs={8}>
        <div className={classes.assetContainer}>
          {treeDepth === "parent" ? (
            <TenantDisplay
              openAddAssetModal={openAddAssetModal}
              openAddGroupModal={openAddGroupModal}
              nodeObject={nodeObject}
            />
          ) : treeDepth === "group" ? (
            <GroupsDisplay
              openAddAssetModal={openAddAssetModal}
              openAddGroupModal={openAddGroupModal}
              openGroupModal={openDeleteDeactivateGroupModal}
              returnToParent={returnToParent}
              nodeObject={nodeObject}
            />
          ) : treeDepth === "asset" ? (
            <AssetsDisplay
              openAddAssetModal={openAddAssetModal}
              openAddDeviceModal={openAddDeviceModal}
              openAddGroupModal={openAddGroupModal}
              openGroupModal={openDeleteDeactivateGroupModal}
              returnToParent={returnToParent}
              nodeObject={nodeObject}
              handleAssetUpdateModal={handleAssetUpdateModal}
            />
          ) : (
            <DefaultDisplay />
          )}
        </div>
        <AddNewAssetModal
          open={addAssetModal}
          handleClose={closeAssetModal}
          handleSave={handleAssetSave}
          handleSuccessModal={handleSuccessModal}
          nodeObject={nodeObject}
        />
        <AddDeviceModal
          open={addDeviceModal}
          handleClose={closeAddDeviceModal}
        />
        <AssetSuccessModal
          open={assetSuccessOpen}
          handleClose={handleSuccessModal}
        />
        <AssetsUpdatedSuccessModal
          open={assetUpdateSuccessOpen}
          handleClose={handleSuccessModal}
        />
        <AddNewGroupModal
          open={addGroupModal}
          handleClose={closeGroupModal}
          handleGroupModals={handleGroupModals}
          handleSuccessModal={handleSuccessModal}
          nodeObject={nodeObject}
        />
        <GroupSuccessModal
          open={groupSuccessOpen}
          handleClose={handleSuccessModal}
        />
        <GroupModalCard
          open={openGroupModal}
          handleClose={closeDeleteDeactivateGroupModal}
          groupTitle={groupTitle}
          groupDescription={groupDescription}
          groupButtonName={groupButtonName}
          selectedGroupId={selectedGroupId}
          selectedGroupName={selectedGroupName}
          selectedGroupSharing={selectedGroupSharing}
        />
        <SuccessModal />
        {/* imported for issue #213 */}
        <UnsuccessModal />
      </Grid>
    </>
  );
};

export default AssetsDashboard;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileContainer: {
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    faIcon: {
      color: theme.palette.primary.light,
    },
    assetContainer: {
      // height: "85vh",
      border: `2px solid ${Colors.LightGray}`,
      borderRight: "none",
      borderBottom: "none",
    },
    value: {
      fontSize: 24,
    },
    search: {
      display: "flex",
      flexDirection: "row",
    },
    searchIcon: {
      height: "100%",
      position: "absolute",
    },
    searchbutton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      cursor: "pointer",
      backgroundColor: `${theme.palette.primary.light}`,
      width: 45,
    },
    inputRoot: {
      border: "1px solid #DDD",
      borderRadius: 0,
      width: "100%",
      backgroundColor: "#fff",
    },
    inputInput: {
      paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
      width: "100%",
    },
    checkboxIcon: {
      color: theme.palette.primary.light,
    },
  })
);
