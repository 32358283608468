/* Issue fixed for github issue #30,issue #213 */
import React, { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import { useSelector } from "react-redux";
// import { RootState } from "app/rootReducer";
import { otaAPI } from "services/otaAPI";
import LoadSpinner from "common/LoadSpinner";
import { Colors } from "common/Colors";
import { FirmwareProps } from "./types";
import CreateFirmwareModal from "./CreateFirmwareModal";
import AddFirmwareToTenantModal from "./AddFirmwareToTenantModal";
import DeleteFirmwareModal from "./DeleteFirmwareModal";
import { SuccessModal } from "components/success-modal/SuccessModal";
import Xsquare from "assets/bt-assets/x-square.svg";
import Button from "components/common-buttons/Button";
import { fromUnixTime, format, differenceInSeconds, addMonths } from "date-fns";
import UnsuccessModal from "components/success-modal/UnsuccessfullModal"; //imported for issue #213

type FirmwarePageProps = {};

const FirmwarePage: React.FC<FirmwarePageProps> = () => {
  const classes = useStyles();
  // const dispatch = useDispatch();

  const [superAdminFirmwareList, setSuperAdminFirmwareList] = useState<
    FirmwareProps[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [openCreateBundleModal, setOpenCreateBundleModal] = useState(false);
  const [
    openAddFirmwareToTenantModal,
    setOpenAddFirmwareToTenantModal
  ] = useState(false);
  const [firmwareId, setFirmwareId] = useState("");
  const [selectedFirmware, setSelectedFirmware] = useState<FirmwareProps>({
    application_name: "",
    version: "",
    part_number: "",
    description: "",
    firmware_id: "",
    //Added the created_time and tenant for showing on the list of firmware page. Github issue #30
    created_time: "",
    tenant_ids: []
  });
  const [recallList, setRecallList] = useState(true);
  const [openDeleteFirmwareModal, setOpenDeleteFirmwareModal] = useState(false);

  const handleCloseModals = () => {
    setOpenCreateBundleModal(false);
    setOpenAddFirmwareToTenantModal(false);
    setOpenDeleteFirmwareModal(false);
  };

  useEffect(() => {
    if (recallList) {
      (async () => {
        const firmwareList = await otaAPI.retrieveSuperAdminFirmwareList();
        //   console.log(firmwareList);
        setSuperAdminFirmwareList(firmwareList);
        setLoading(false);
        setRecallList(false);
      })();
    }
  }, [recallList]);

  return loading ? (
    <LoadSpinner size="90vh" />
  ) : (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.root}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            margin: "1rem 0"
          }}
        >
          <Button
            type="button"
            color="defaultTheme"
            onClick={() => setOpenCreateBundleModal(true)}
          >
            + Create firmware
          </Button>
        </Grid>
        {!superAdminFirmwareList.length ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
              width: "100%"
            }}
          >
            No firmware(s) to display
          </div>
        ) : (
          <Grid item xs={12} className={classes.root}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.columnText}>
                      Application name
                    </TableCell>
                    <TableCell className={classes.columnText}>
                      Application part number
                    </TableCell>
                    <TableCell className={classes.columnText}>
                      Application ID
                    </TableCell>
                    <TableCell className={classes.columnText}>
                      Description
                    </TableCell>
                    {/* Added the accounts column for showing in the table. Github issue #30 */}
                    <TableCell className={classes.columnText}>
                      Account(s)
                    </TableCell>
                    {/* Added the Created Date column for showing in the table. Github issue #30 */}
                    <TableCell className={classes.columnText}>
                      Created Date
                    </TableCell>
                    {/*<TableCell className={classes.columnText}>Date</TableCell> */}
                    <TableCell className={classes.columnText}>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {superAdminFirmwareList.map(
                    (firmware: FirmwareProps, index: number) => {
                      return (
                        <TableRow
                          key={firmware.firmware_id + index}
                          style={{
                            cursor: "pointer",
                            position: "relative"
                          }}
                          onClick={() => {
                            setOpenAddFirmwareToTenantModal(true);
                            setFirmwareId(firmware.firmware_id);
                            setSelectedFirmware(firmware);
                          }}
                        >
                          <TableCell className={classes.tableCell}>
                            {firmware.application_name}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {firmware.part_number}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {firmware.version}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {firmware.description}
                          </TableCell>
                          {/* Added the accounts data column for showing in the table. Github issue #30 */}
                          <TableCell className={classes.tableCell}>
                            {firmware.tenant_ids.join(", ")}
                          </TableCell>
                          {/* Added the created date and time data column for showing in the table. Github issue #30 */}
                          <TableCell className={classes.tableCell}>
                            {firmware.created_time == "NA"
                              ? "NA"
                              : format(
                                fromUnixTime(
                                  Number(firmware.created_time) / 1000
                                ),
                                "MM/dd/yyyy | h:mm a"
                              )}
                          </TableCell>
                          {/* <TableCell className={classes.tableCell}>
                            1 Aug 2020 | 4:30 PM
                          </TableCell> */}
                          <TableCell className={classes.tableCell}>
                            <img
                              src={Xsquare}
                              alt="Delete firmware"
                              style={{
                                color: Colors.BasinRed,
                                cursor: "pointer"
                              }}
                              onClick={e => {
                                e.stopPropagation();
                                setOpenDeleteFirmwareModal(true);
                                setFirmwareId(firmware.firmware_id);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
      <SuccessModal />
      {/* imported for issue #213 */}
      <UnsuccessModal />
      <CreateFirmwareModal
        open={openCreateBundleModal}
        handleClose={handleCloseModals}
        recallList={setRecallList}
      />
      <AddFirmwareToTenantModal
        open={openAddFirmwareToTenantModal}
        handleClose={handleCloseModals}
        firmwareId={firmwareId}
        selectedFirmware={selectedFirmware}
        // Added the recallList for the recalling the tenant values attached on the add firmware page. Github issue #30
        recallList={setRecallList}
      />
      <DeleteFirmwareModal
        open={openDeleteFirmwareModal}
        handleClose={handleCloseModals}
        firmwareId={firmwareId}
        recallList={setRecallList}
      />
    </>
  );
};

export default FirmwarePage;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowY: "auto"
    },
    lineBreak: {
      width: "100%",
      border: `1px solid ${Colors.LightGray}`
    },
    table: {
      minWidth: "100%"
    },
    columnText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      // padding: 0,
      borderTop: "2px solid #DDD",
      borderBottom: "2px solid #DDD"
    },
    tableCell: {
      padding: "1rem",
      margin: 0,
      borderBottom: "2px solid #DDD",
      fontSize: 14
    },
    companyInformation: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "80%",
      cursor: "pointer"
    }
  })
);
