/* Issue fixed for github issue #174*/
// Added the whole file for issue #174
import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ChemicalInjectorFormProps } from "./types"; //imported for form for issue #174
import AssetsAPI from "services/assetsAPI"; //imported for api call for issue #174
import { Colors } from "common/Colors";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from "@material-ui/core"; //imported for table view for issue #174
import { useForm, Controller } from "react-hook-form";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  setSuccessModalOpen,
  setSuccessModalDescription
} from "components/success-modal/successModalSlice";
import { InputLabel } from "components/input-label/InputLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { ThemedRadio } from "common/ThemedRadio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
// import clsx from "clsx";

type keyable = {
  [key: string]: any;
};

// tank type list for issue #174
export const chemicalTankTypeList = [
  {
    name: "Disabled",
    value: 0
  },
  {
    name: "Chem_Basic",
    value: 1
  }
];

// tank level list for issue #174
export const tankCapacityList = [
  {
    name: "500 gal vertical",
    value: 0
  },
  {
    name: "525 gal horizontal",
    value: 1
  },
  {
    name: "325 gal horizontal",
    value: 2
  }
];

type ChemicalInjectorListProps = {
  assetProfile: any;
  // assetObjectConfig: any;
};

const ChemicalInjectorList: React.FC<ChemicalInjectorListProps> = ({
  assetProfile
  // assetObjectConfig
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    errors,
    control,
    reset,
    watch
  } = useForm<ChemicalInjectorFormProps>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { asset_id, asset_name } = assetProfile;

  const [tankType, setTankType] = useState(0);
  const [tankCapacity, setTankCapacity] = useState(0);
  const [tankLevelUnit, setTankLevelUnit] = useState(0);
  const [daysToWarning, setDaysToWarning] = useState(0);
  const [daysToCritical, setDaysToCritical] = useState(0);
  const [tankTypeParameter, setTankTypeParameter] = useState<keyable>([]);
  const [tankTypeParameterList, setTankTypeParameterList] = useState<any[]>([]);
  const [daysToWarningNotify, setDaysToWarningNotify] = useState<boolean>(
    false
  );
  const [daysToCriticalNotify, setDaysToCriticalNotify] = useState<boolean>(
    false
  );
  const [loading, setLoading] = useState(false);

  // list for parameter dropdown for issue #174
  var paramListCopy = assetProfile?.asset_parameters;

  // function to handle parameter change for issue #174
  const selectedTankTypeParameterName = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    var selectParam = tankTypeParameterList.find((parameter: any) => {
      if (parseInt(e.target.value) === parameter.selectListNumber) {
        return parameter;
      }
    });
    setTankTypeParameter(selectParam);
  };

  // function to handle submit for issue #174
  const onConfigureAsset = async ({
    tank_capacity,
    tank_type,
    tank_type_parameter,
    tank_level_unit,
    days_to_critical,
    days_to_empty_critical_notify,
    days_to_warning,
    days_to_empty_warning_notify
  }: ChemicalInjectorFormProps) => {
    try {
      const assetConfigObject = {
        asset_id: asset_id,
        asset_name: asset_name,
        tank_type: tankType,
        tank_capacity: tankCapacity,
        tank_level_unit: tankLevelUnit,
        tank_type_parameter: tankTypeParameter,
        days_to_empty_warning_notify: daysToWarningNotify,
        days_to_warning: daysToWarning,
        days_to_empty_critical_notify: daysToCriticalNotify,
        days_to_critical: daysToCritical
      };
      setLoading(true);
      //api call to save configuration for issue #174
      const response = await AssetsAPI.updateAssetConfiguration(
        assetConfigObject.asset_id,
        assetConfigObject.asset_name,
        assetConfigObject.tank_type,
        assetConfigObject.tank_capacity,
        assetConfigObject.tank_level_unit,
        assetConfigObject.tank_type_parameter,
        assetConfigObject.days_to_empty_warning_notify,
        assetConfigObject.days_to_warning,
        assetConfigObject.days_to_empty_critical_notify,
        assetConfigObject.days_to_critical
      );
      setLoading(false);
      dispatch(
        setSuccessModalDescription("Asset configuration successfully updated!")
      );
      dispatch(setSuccessModalOpen(true));
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(error);
    }
  };

  useEffect(() => {
    //function to get saved configuration for issue #174
    const getAssetConfiguration = async () => {
      try {
        // to set parameter list to 1st option if there is no saved configuration for issue #174
        if (paramListCopy && paramListCopy.length > 0) {
          var num = 1;
          paramListCopy.map((param: any, index: number) => {
            param.selectListNumber = num;
            num++;
          });

          setTankTypeParameterList(paramListCopy);
        }
        const assetConfig = await AssetsAPI.getAssetConfiguration(asset_id);
        // if the user already has a saved configuration for issue #174
        if (
          assetConfig &&
          assetConfig.length != 0 &&
          assetConfig != "undefined"
        ) {
          setTankCapacity(assetConfig.tank_capacity);
          setTankType(assetConfig.tank_type);
          setTankLevelUnit(assetConfig.tank_level_unit);
          setDaysToCritical(assetConfig.days_to_critical);
          setDaysToWarning(assetConfig.days_to_warning);
          setDaysToCriticalNotify(assetConfig.days_to_empty_critical_notify);
          setDaysToWarningNotify(assetConfig.days_to_empty_warning_notify);
          if (Object.keys(assetConfig.tank_type_parameter).length !== 0) {
            setTankTypeParameter(assetConfig.tank_type_parameter);
          } else if (
            tankTypeParameterList &&
            tankTypeParameterList.length != 0
          ) {
            setTankTypeParameter(paramListCopy[0]);
          }
        } else {
          // if the user doesn't have a saved configuration for issue #174
          setTankCapacity(0);
          setTankType(0);
          setTankLevelUnit(0);
          setDaysToCritical(0);
          setDaysToWarning(0);
          setDaysToCriticalNotify(false);
          setDaysToWarningNotify(false);
          if (tankTypeParameterList && tankTypeParameterList.length > 0) {
            setTankTypeParameter(tankTypeParameterList[0]);
          } else {
            setTankTypeParameter({});
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (asset_id) {
      getAssetConfiguration();
    }
  }, [asset_id]);

  // useEffect(() => {
  //   // to set parameter list to 1st option if there is no saved configuration for issue #174
  //   if (paramListCopy && paramListCopy.length > 0) {
  //     var num = 1;
  //     paramListCopy.map((param: any, index: number) => {
  //       param.selectListNumber = num;
  //       num++;
  //     });

  //     setTankTypeParameterList(paramListCopy);
  //   }
  // }, [asset_id]);

  return (
    <>
      <form onSubmit={handleSubmit(onConfigureAsset)}>
        <Grid item xs={12} className={classes.root}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.columnText}>
                    Asset Parameter
                  </TableCell>
                  <TableCell className={classes.columnText}>
                    Enter selection
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <InputLabel
                      label="Chemical tank type select"
                      name="tank_type"
                      errors={errors}
                      isRequired={true}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <div className={classes.innerInput}>
                      <select
                        name="tank_type"
                        ref={register({
                          required: "This field is required."
                        })}
                        value={tankType}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          setTankType(parseInt(e.target.value));
                        }}
                      >
                        {chemicalTankTypeList.map(
                          (
                            tankTypeObject: { name: string; value: number },
                            index: number
                          ) => {
                            return (
                              <option key={index} value={tankTypeObject.value}>
                                {tankTypeObject.name}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </TableCell>
                </TableRow>
                {/* condition to show rest of the input fields only when type is not "disabled" for issue #174 */}
                {tankType != 0 && (
                  <>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        <InputLabel
                          label="Tank Level"
                          name="tankLevelUnit"
                          errors={errors}
                          isRequired={true}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="position"
                              name="position"
                              style={{ display: "flex", flexDirection: "row" }}
                              defaultValue={tankLevelUnit || 0}
                              value={tankLevelUnit}
                              onChange={(event: any) => {
                                if (event.target.value == "0") {
                                  setTankLevelUnit(0);
                                } else {
                                  setTankLevelUnit(1);
                                }
                              }}
                            >
                              <FormControlLabel
                                classes={{
                                  label: classes.label,
                                  root: classes.formRoot
                                }}
                                value={0}
                                control={<ThemedRadio size="small" />}
                                label="Inches"
                              />
                              <FormControlLabel
                                classes={{
                                  label: classes.label,
                                  root: classes.formRoot
                                }}
                                value={1}
                                control={<ThemedRadio size="small" />}
                                label="Gallons"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        <InputLabel
                          label="Tank level parameter"
                          name="tank_type_parameter"
                          errors={errors}
                          isRequired={false}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <div className={classes.innerInput}>
                          <select
                            name="tank_type_parameter"
                            value={tankTypeParameter?.selectListNumber}
                            // defaultValue={tankTypeParameter?.selectListNumber}
                            ref={register({
                              required: "This field is required."
                            })}
                            onChange={selectedTankTypeParameterName}
                          >
                            {tankTypeParameterList?.map(
                              (
                                params: {
                                  parameter_name: string;
                                  selectListNumber: number;
                                },
                                index: number
                              ) => {
                                return (
                                  <option
                                    key={params.selectListNumber + index}
                                    value={params.selectListNumber}
                                  >
                                    {params.parameter_name}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        <InputLabel
                          label="Tank capacity"
                          name="tank_capacity"
                          errors={errors}
                          isRequired={false}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <div className={classes.innerInput}>
                          <select
                            name="tank_capacity"
                            ref={register({
                              required: "This field is required."
                            })}
                            // defaultValue={tankCapacity}
                            value={tankCapacity}
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              setTankCapacity(parseInt(e.target.value));
                            }}
                          >
                            {tankCapacityList.map(
                              (
                                tankCapacityObject: {
                                  name: string;
                                  value: number;
                                },
                                index: number
                              ) => {
                                return (
                                  <option
                                    key={index}
                                    value={tankCapacityObject.value}
                                  >
                                    {tankCapacityObject.name}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        <InputLabel
                          label="Days to empty warning notify"
                          name="WarningNotify"
                          errors={errors}
                          isRequired={true}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="position"
                              name="position"
                              style={{ display: "flex", flexDirection: "row" }}
                              value={daysToWarningNotify}
                              onChange={(event: any) => {
                                if (event.target.value == "true") {
                                  setDaysToWarningNotify(true);
                                } else {
                                  setDaysToWarningNotify(false);
                                }
                              }}
                            >
                              <FormControlLabel
                                classes={{
                                  label: classes.label,
                                  root: classes.formRoot
                                }}
                                value={false}
                                control={<ThemedRadio size="small" />}
                                label="Disable"
                              />
                              <FormControlLabel
                                classes={{
                                  label: classes.label,
                                  root: classes.formRoot
                                }}
                                value={true}
                                control={<ThemedRadio size="small" />}
                                label="Enable"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        <InputLabel
                          label="Days"
                          name="days_to_warning"
                          errors={errors}
                          isRequired={true}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <div className={classes.inputGroup}>
                          <input
                            name="days_to_warning"
                            type="number"
                            ref={register({
                              required: "This field is required.",
                              valueAsNumber: true
                            })}
                            onChange={(event: any) => {
                              setDaysToWarning(event.target.value);
                            }}
                            value={daysToWarning}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        <InputLabel
                          label="Days to empty critical notify"
                          name="CriticalNotify"
                          errors={errors}
                          isRequired={true}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="position"
                              name="position"
                              style={{ display: "flex", flexDirection: "row" }}
                              value={daysToCriticalNotify}
                              onChange={(event: any) => {
                                if (event.target.value == "true") {
                                  setDaysToCriticalNotify(true);
                                } else {
                                  setDaysToCriticalNotify(false);
                                }
                              }}
                            >
                              <FormControlLabel
                                classes={{
                                  label: classes.label,
                                  root: classes.formRoot
                                }}
                                value={false}
                                control={<ThemedRadio size="small" />}
                                label="Disable"
                              />
                              <FormControlLabel
                                classes={{
                                  label: classes.label,
                                  root: classes.formRoot
                                }}
                                value={true}
                                control={<ThemedRadio size="small" />}
                                label="Enable"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        <InputLabel
                          label="Days"
                          name="days_to_critical"
                          errors={errors}
                          isRequired={true}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <div className={classes.inputGroup}>
                          <input
                            name="days_to_critical"
                            type="number"
                            ref={register({
                              required: "This field is required.",
                              valueAsNumber: true,
                              // Added condition so that critical days are always greater than days to warning for issue #174
                              min: {
                                value: daysToWarning,
                                message: "Must be greater than days to warning"
                              }
                            })}
                            onChange={(event: any) => {
                              setDaysToCritical(event.target.value);
                            }}
                            value={daysToCritical}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid className={classes.buttonContainer} item xs={12}>
          <button
            className={classes.cancelButton}
            type="button"
            onClick={() => console.log("CANCEL")}
          >
            Cancel
          </button>
          <button
            className={classes.updateButton}
            type="submit"
            // onClick={onUpdateAsset} //! Update asset on react tree here using change nodeAtPath.
          >
            Save Updates
          </button>
        </Grid>
      </form>
    </>
  );
};

export default ChemicalInjectorList;

const inputStyle = {
  "& p": {
    padding: 0,
    margin: 0,
    fontSize: "1rem",
    marginBottom: ".5rem"
  },
  "& input": {
    width: "100%",
    padding: ".5rem 1rem",
    border: `1px solid ${Colors.LightGray}`
  },
  "& select": {
    width: "100%",
    padding: ".5rem 1rem",
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer"
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowY: "auto"
    },
    lineBreak: {
      width: "100%",
      border: `1px solid ${Colors.LightGray}`
    },
    table: {
      minWidth: "100%",
      marginTop: "1rem",
      borderTop: `2px solid ${Colors.LightGray}`
    },
    tableCell: {
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 0",
      margin: 0,
      fontSize: 14
    },
    columnText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      borderTop: "2px solid #DDD",
      borderBottom: "2px solid #DDD"
    },
    propertyColumn: {
      width: "100%",
      display: "flex",
      flexDirection: "column"
      // margin: "1rem 0",
    },
    propertyInformation: {
      display: "grid",
      gridTemplateColumns: "30% 70%",
      // margin: "1rem 0",
      fontSize: 16
    },
    innerInput: {
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem"
      },
      "& input": {
        width: "100%",
        padding: ".5rem 1rem",
        border: `1px solid ${Colors.LightGray}`
      },
      "& select": {
        width: "100%",
        padding: "0 1rem",
        border: `1px solid ${Colors.LightGray}`,
        height: 38,
        cursor: "pointer"
      }
    },
    inputGroupRight: {
      padding: "1rem 0 1rem 1rem",
      ...inputStyle
    },
    inputGroupRightCheckbox: {
      padding: "1rem 0 1rem 0",
      ...inputStyle
    },
    inputGroup: {
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem"
      },
      "& input": {
        width: "100%",
        padding: ".5rem 1rem",
        border: `1px solid ${Colors.LightGray}`
        // height: 30,
      },
      "& select": {
        width: "100%",
        padding: ".5rem 1rem",
        border: `1px solid ${Colors.LightGray}`,
        // height: 30,
        cursor: "pointer"
      }
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      margin: "1em 0 0 0"
    },
    cancelButton: {
      border: `1px solid ${theme.palette.primary.light}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      background: Colors.White,
      width: 150,
      height: 30,
      color: theme.palette.primary.light,
      cursor: "pointer",
      margin: "0 1em 0 0"
    },
    updateButton: {
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      background: theme.palette.primary.light,
      width: 150,
      height: 30,
      color: Colors.White,
      cursor: "pointer"
    },
    label: {
      fontSize: "14px"
    },
    formRoot: {
      height: 25
    }
  })
);
