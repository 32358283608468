/* Issue fixed for github issue #1, issue #24, issue #66,issue #63,issue #140,issue #213,issue #273,issue #261 */
import React, { useEffect, useState } from "react";
import companyLogo from "custom/companyLogoPlain.png";
import AccountAPI from "services/accountsAPI";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withRouter, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { setSubPageActive } from "features/accounts-page/accountSlice";
import {
  setLogoUrl,
  setAccountDisplayName,
  setTheme,
  setNewTheme
} from "features/drawer/drawerSlice";
import { RootState } from "app/rootReducer";
// import { useTheme } from "@material-ui/core/styles";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { UserIconDropDown } from "./UserIconDropDown";
import NotificationsOnlyView from "features/drawer/NotificationsOnlyView";
import NoActiveAccountsView from "features/drawer/NoActiveAccountsView";
import MenuItems from "features/drawer/MenuItems";
import Routes from "Routes/Routes";
import { Drawer } from "@material-ui/core";
// import InputBase from "@material-ui/core/InputBase";
import LoginBG from "assets/bt-logins/login_BG.svg";
import DeviceTopBar from "./DeviceTopBar";
import AccountsTopBar from "./AccountsTopBar";
import AssetsTopBar from "./AssetsTopBar";
import BatchTopBar from "./BatchTopbar";
import SoftwareTopBar from "./SoftwareTopBar";
import AssetStatusTopBar from "./AssetStatusTopBar";
import ExceptionsTopBar from "./ExceptionsTopBar";  //imported for issue #261
import NotificationsTopBar from "./NotificationsTopBar";  //imported for issue #261
import ReportsTopBar from "./ReportsTopBar";  //imported for issue #273
import { Auth, Storage } from "aws-amplify";
import clsx from "clsx";
import ProfileModal from "./ProfileModal";
import ChangePasswordModal from "./ChangePasswordModal";
import SuccessModal from "components/success-modal/SuccessModal";
import LoadSpinner from "common/LoadSpinner";
import { resetReduxAction, useAppDispatch } from "app/store";
import { useAppSelector } from "app/store";
import { Colors } from "common/Colors";
import { useMediaQuery } from "react-responsive"; //for issue #24
import { GiHamburgerMenu } from "react-icons/gi"; //for issue #24
import { FaTimes } from "react-icons/fa"; //for issue #24
import UnsuccessModal from "components/success-modal/UnsuccessfullModal"; //imported for issue #213
import { colorPowered } from "custom/customizations";

const drawerWidth = 300;

const SideDrawer = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  // const theme = useTheme();

  const [pageName, setPageName] = React.useState("");
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const { subPageActive } = useSelector(
    (state: RootState) => state.AccountSlice
  );

  const { activeTenant, activeLogo, accountName, newTheme } = useSelector(
    (state: RootState) => state.DrawerSlice
  );

  //for checking if mobile view or web view for responsive UI issue #24
  var ismobileview = useMediaQuery({ query: "(max-width: 800px)" });

  const { fullscreen } = useAppSelector(state => state.AppSlice);
  // const { currentLoggedInUserId, currentLoggedInUserAccessLevel } = useAppSliceState();

  const { selectedAccount, userInformation } = useSelector(
    (state: RootState) => state.AppSlice
  );

  const currentLoggedInUserRole = selectedAccount?.role || "";
  // const currentLoggedInUserRole = useSelector(
  //   (state: RootState) => state.AppSlice?.selectedAccount?.role || ""
  // );
  const currentUserAccessLevel = useSelector(
    (state: RootState) =>
      state.AppSlice?.userInformation?.access_level || "general"
  );

  // const isMounted = useIsMounted();

  const signOut = async () => {
    try {
      await Auth.signOut();
      dispatch(resetReduxAction);
      localStorage.clear(); //clearing out the localstorage after storing variables for issue #1
      history.push("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  useEffect(() => {
    if (activeTenant !== "") {
      AccountAPI.getAllAttributes(activeTenant).then(async response => {
        dispatch(setTheme(response.active_color));
        dispatch(setAccountDisplayName(response.display_name));

        if (
          !response.display_name &&
          response.active_color === Colors.DefaultTheme
        ) {
          dispatch(setLogoUrl(""));
        }

        if (response.logo_url && response.display_name) {
          try {
            const preSignedUrl = await Storage.get(response.logo_url, {
              level: "public",
              download: false
            });
            dispatch(setLogoUrl(preSignedUrl.toString()));
          } catch {
            alert("Error retrieving logo URL");
          }
        }
      });
    }
    dispatch(setNewTheme(false));
  }, [activeTenant, newTheme, dispatch]);

  const handleOpenProfileModal = () => {
    setOpenProfileModal(true);
  };

  const handleOpenPasswordModal = () => {
    setOpenProfileModal(false);
    setOpenPasswordModal(true);
  };

  //for opening the navbar on hamburger icon click in responsive UI issue #24
  const [sidenavbaropen, setsidenavbaropen] = useState(false);

  //onclick function for hamburger icon in responsive UI issue #24
  const handlefullscreenclick = () => {
    setsidenavbaropen(true); //displaying sidenav bar on hamburger icon click in responsive UI issue #24
  };

  const handleCloseModals = () => {
    setOpenProfileModal(false);
    setOpenPasswordModal(false);
  };

  useEffect(() => {
    dispatch(setSubPageActive(false));
  }, [pageName, dispatch]);

  const TopBar = () => {
    return (
      // added css class for styling the header of page in responsive UI issue #24
      <div className={classes.fixedheader}>
        <div className={classes.root}>
          {/* // ! this portion will become more dynamic (seperated into functions maybe?) as we add, devices, asset status, etc */}

          {/* adding hamburger icon and css class for responsive UI issue #24 */}
          <div className={classes.hamburgericon}>
            <GiHamburgerMenu
              className={classes.iconproperty}
              onClick={handlefullscreenclick}
            ></GiHamburgerMenu>
          </div>

          <div className={classes.topBarContainer}>
            <AssetStatusTopBar pageName={pageName} />
            <BatchTopBar pageName={pageName} />
            <SoftwareTopBar pageName={pageName} />
            <AccountsTopBar pageName={pageName} subPageActive={subPageActive} />
            <DeviceTopBar pageName={pageName} />
            <AssetsTopBar pageName={pageName} subPageActive={subPageActive} />
            {/* Added to show Reports on top bar for issue #273 */}
            <ReportsTopBar pageName={pageName} subPageActive={subPageActive} />
            {/* Added to show Exceptions on top bar for issue #261 */}
            <ExceptionsTopBar pageName={pageName} subPageActive={subPageActive} />
            {/* Added to show Notifications on top bar for issue #261 */}
            <NotificationsTopBar pageName={pageName} subPageActive={subPageActive} />
          </div>
          <div className={classes.search}>
            {/* <div className={classes.searchIcon}></div> */}
            {/* <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            /> */}
            {/* <div style={{ display: "flex" }}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.23792 8.53446L11.3685 10.6646L10.6646 11.3684L8.53452 9.23786C7.74194 9.87321 6.75612 10.2188 5.74032 10.2173C3.26898 10.2173 1.26324 8.21161 1.26324 5.74026C1.26324 3.26891 3.26898 1.26318 5.74032 1.26318C8.21167 1.26318 10.2174 3.26891 10.2174 5.74026C10.2188 6.75606 9.87327 7.74188 9.23792 8.53446ZM8.24002 8.16535C8.87135 7.51611 9.22392 6.64584 9.2225 5.74026C9.2225 3.81611 7.66397 2.25809 5.74032 2.25809C3.81617 2.25809 2.25815 3.81611 2.25815 5.74026C2.25815 7.66391 3.81617 9.22243 5.74032 9.22243C6.6459 9.22386 7.51617 8.87129 8.16541 8.23996L8.24002 8.16535Z"
                  fill={theme.palette.primary.light}
                  stroke={theme.palette.primary.light}
                  strokeWidth="0.5"
                />
              </svg>
            </div> */}

            <UserIconDropDown
              handleSignOut={signOut}
              handleOpenProfileModal={handleOpenProfileModal}
            />
          </div>
        </div>
        <hr className={classes.topLine} />
      </div>
    );
  };

  return (
    // added css classes for displaying background behind nav bar when opened and closed in responsive UI issue #24
    <div
      className={clsx({
        [classes.root]: !sidenavbaropen, //for responsive UI issue #24 css class applied when nav bar isn't open
        [classes.root1]: sidenavbaropen //for issue #24 css class applied when in mobile view and hamburger icon clicked clicked
      })}
    >
      <CssBaseline />
      <nav
        className={clsx({
          [classes.drawer]: true, //for responsive UI issue #24 css class applied irrespective of any condition
          //Added fullscreen for expanding the screen for grafana reports for issue #63
          [classes.hide]: (ismobileview && !sidenavbaropen) || fullscreen //for issue #24 css class applied when in mobile view and cross button clicked
        })}
        aria-label="mailbox folders"
      >
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
          open
        >
          <div style={{ paddingBottom: 32, flex: 1 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "1rem 0 0 0",
                padding: "0 3rem",
                alignItems: "center"
              }}
            >
              {activeLogo ? (
                <>
                  <img
                    src={activeLogo}
                    alt=""
                    style={{ height: 52, width: 52, borderRadius: "50%" }}
                  />
                  <span className={classes.accountName}>{accountName}</span>
                </>
              ) : (
                <img src={companyLogo} alt="" style={{ width: 150 }} />
              )}
              <div>
                {/* close button added on side navigation bar in mobile view for responsive UI issue #24 */}
                <FaTimes
                  className={classes.closebutton}
                  onClick={() => setsidenavbaropen(false)}
                />
              </div>
            </div>
            {/* added handleSignOut={signOut} for sending data from Drawer to MenuItems for responsive UI issue #24 */}
            <MenuItems
              pageName={pageName}
              handleSignOut={signOut}
              setPageName={setPageName}
            />
          </div>
          <div
            style={{ flex: "0 0 2rem", textAlign: "center", paddingBottom: 12, color: colorPowered }}
          >
            Powered by Rulo
          </div>
          {/* <img
            className={classes.loginBG}
            src={LoginBG}
            alt=""
            style={{ maxWidth: "100%", height: "auto" }}
          /> */}
        </Drawer>
      </nav>
      <main className={clsx(classes.contentContainer, classes.toolbar)}>
        <div className={classes.overflowWrapper}>
          <div className={classes.content}>
            {!fullscreen && <TopBar />}
            {currentUserAccessLevel === "general" &&
              currentLoggedInUserRole === "notifications" ? (
              <NotificationsOnlyView />
            ) : (
              <>
                {selectedAccount.active === undefined && (
                  <LoadSpinner size="90vh" />
                )}
                {selectedAccount.active !== undefined &&
                  selectedAccount.active === false &&
                  currentUserAccessLevel === "general" &&
                  userInformation.user_id !== "" && <NoActiveAccountsView />}
                {selectedAccount.active && userInformation.user_id && (
                  <Routes />
                )}
                <ProfileModal
                  open={openProfileModal}
                  handleClose={handleCloseModals}
                  handleOpenPasswordModal={handleOpenPasswordModal}
                />
                <ChangePasswordModal
                  open={openPasswordModal}
                  handleClose={handleCloseModals}
                />
                <SuccessModal />
                {/* imported for issue #213 */}
                <UnsuccessModal />
              </>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default withRouter(SideDrawer);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      zIndex: -1,
      width: "100%"
    },
    accountName: {
      fontFamily: "Rajdhani",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 18,
      color: "#FFFFFF",
      paddingLeft: 10
    },
    topBarContainer: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",

      //adding media query for heading of page in responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginLeft: "10px"
      }
    },
    topLine: {
      border: `0.5px solid ${theme.palette.primary.light}`,
      margin: 0,

      //adding media query for line below heading of page in responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        border: `0.5px solid cornflowerblue`
      }
    },
    drawer: {
      width: drawerWidth,
      flex: `0 0 ${drawerWidth}px`
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: Colors.MainColor, //changed color to slate for issue #66
      padding: "1rem 0 0 0",
      color: "white",
      height: "100%",
      border: "none",
      display: "flex",
      flexDirection: "column",
      // justifyContent: "space-between",
      overflowX: "hidden",
      // below for basintrak logo background positioning
      backgroundImage: `url(${LoginBG})`,
      backgroundPosition: "right bottom",
      backgroundRepeat: "no-repeat",
      backgroundSize: "88%"
    },
    contentContainer: {
      flex: 1,
      height: "100vh",
      overflow: "hidden",
      position: "relative"
    },
    overflowWrapper: {
      position: "absolute",
      width: "100%",
      height: "100%",
      maxWidth: "100%",
      overflow: "auto",

      //added media query for hiding scroll bar at the bottom of screen in responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        overflowX: "hidden"
      }
    },
    content: {
      // minWidth: 1160 - drawerWidth,  //removed minWidth so that the asset status part doesn't go behind the side bar for issue #140
      padding: "1.5rem 3rem",

      //adding media query for responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        minWidth: "0px",
        padding: "0rem"
      }
    },
    loginBG: {
      width: 280,
      left: 20,
      position: "relative",
      zIndex: 10
    },
    search: {
      display: "flex",
      alignItems: "center"
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    inputRoot: {
      color: "inherit"
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("xs")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch"
        }
      }
    },
    hide: {
      display: "none"
    },

    //adding changes made for issue #24
    //css class for styling hamburger icon in responsive UI issue #24
    hamburgericon: {
      display: "none",
      [`@media (max-width: 800px)`]: {
        marginLeft: "10px",
        display: "flex"
      }
    },
    //css class for styling close icon in responsive UI issue #24
    closebutton: {
      display: "none",
      [`@media (max-width: 800px)`]: {
        marginLeft: "65px",
        display: "flex",
        fontSize: "30px",
        color: "grey"
      }
    },
    //css class for styling hamburger icon in responsive UI issue #24
    iconproperty: {
      [`@media (max-width: 800px)`]: {
        fontSize: "24px",
        color: "cornflowerblue",
        marginLeft: "9px",
        marginTop: "15px"
      }
    },
    // css class for styling behind nav bar in responsive UI issue #24
    root1: {
      display: "flex",
      zIndex: -1,
      width: "100%",
      [`@media (max-width: 800px)`]: {
        display: "block"
      }
    },

    //added css class for keeping the header fixed in mobile view responsive UI issue #24
    fixedheader: {
      [`@media (max-width: 800px)`]: {
        position: "sticky",
        zIndex: "999",
        top: "0",
        backgroundColor: "rgba(255, 255, 255,1)"
      }
    }
  })
);
