//Added file for adding reports for github issue #273
/* Issue fixed for github issue #305,issue #306,issue #307*/
import React, { useState, useEffect, useRef, useCallback } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import {
  setSuccessModalOpen,
  setSuccessModalDescription
} from "components/success-modal/successModalSlice";
import { InputLabel } from "components/input-label/InputLabel";
import { ReportsDashboardProps } from "./types";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from "@material-ui/core";
import AccountAPI from "services/accountsAPI";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import Select from "react-select";
import Button from "components/common-buttons/Button";
import Xsquare from "assets/bt-assets/x-square.svg";
import Checkbox from "@material-ui/core/Checkbox";
import { tableStyle } from "features/devices-page/device-settings/device-settings-tabs/tableStyles";
import { devicesAPI } from "services/devicesAPI";
import { DeviceParametersProps } from "features/devices-page/device-settings/types";
import { v4 as uuidv4 } from 'uuid';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Calendar } from "react-date-range";
import { customDateFormatUnix } from "utils/helpers";
import { fromUnixTime, getUnixTime } from "date-fns";
import calendarWhite from "assets/bt-assets/calendar_white.svg";
import { DateTime } from "luxon";

interface DefaultReportDisplayProps {
  nodeObject: any;
  treeDepth: string;
  closeReportModal: Function;
}

// frequency list for dropdown for issue #273
export const emailScheduleFrequencyList = [
  {
    name: "Daily",
    value: 0
  },
  {
    name: "Weekly",
    value: 1
  },
  {
    name: "Monthly",
    value: 2
  }
];

// time list for dropdown for issue #273
export const emailScheduleTimeList = [
  {
    name: "00:00",
    value: 0
  },
  {
    name: "01:00",
    value: 1
  },
  {
    name: "02:00",
    value: 2
  },
  {
    name: "03:00",
    value: 3
  },
  {
    name: "04:00",
    value: 4
  },
  {
    name: "05:00",
    value: 5
  },
  {
    name: "06:00",
    value: 6
  },
  {
    name: "07:00",
    value: 7
  },
  {
    name: "08:00",
    value: 8
  },
  {
    name: "09:00",
    value: 9
  },
  {
    name: "10:00",
    value: 10
  },
  {
    name: "11:00",
    value: 11
  },
  {
    name: "12:00",
    value: 12
  },
  {
    name: "13:00",
    value: 13
  },
  {
    name: "14:00",
    value: 14
  },
  {
    name: "15:00",
    value: 15
  },
  {
    name: "16:00",
    value: 16
  },
  {
    name: "17:00",
    value: 17
  },
  {
    name: "18:00",
    value: 18
  },
  {
    name: "19:00",
    value: 19
  },
  {
    name: "20:00",
    value: 20
  },
  {
    name: "21:00",
    value: 21
  },
  {
    name: "22:00",
    value: 22
  },
  {
    name: "23:00",
    value: 23
  },
];

// timezone list for dropdown for issue #273
export const emailScheduleTimezoneList = [
  {
    name: "EST",
    value: 0
  },
  {
    name: "CST",
    value: 1
  },
  {
    name: "MST",
    value: 2
  },
  {
    name: "PST",
    value: 3
  },
  {
    name: "AST",
    value: 4
  },
  {
    name: "HST",
    value: 5
  },
]

export const graphDurationList = [
  {
    name: "1 day",
    value: 1
  },
  {
    name: "7 days",
    value: 7
  },
  {
    name: "30 days",
    value: 30
  }
]

export const axisList = [
  {
    name: "Left Y",
    value: 0
  },
  {
    name: "Right Y",
    value: 1
  }
];

const DefaultReportsDisplay: React.FC<DefaultReportDisplayProps> = ({
  nodeObject,
  treeDepth,
  closeReportModal,
}) => {
  const classes = useStyles();
  const table = tableStyle();
  const dispatch = useDispatch();

  const { accountId } = useSelector(
    (state: RootState) => state.AppSlice.selectedAccount
  );

  const reCallUserList = useSelector(
    (state: RootState) => state.AccountSlice.callUsers
  );

  const [loading, setLoading] = useState(true);
  const [recipentUserList, setRecipentUserList] = useState<any>([]);
  const [assetList, setAssetList] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [selectedAssets, setSelectedAssets] = useState<any>([]);
  const [paramList, setParamList] = useState<any>([]);
  const [selectedParams, setSelectedParams] = useState<any[]>([]);
  const [disableParameterSaveButton, setDisableParameterSaveButton] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [reportDate, setReportDate] = useState(Number(""));

  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues
  } = useForm<ReportsDashboardProps>({});

  const { fields, append, remove } = useFieldArray({
    control,
    name: "graph"
  });

  // to set the calendar date timestamp for issue #307
  const calendarExpiryHandler = useCallback(
    (date: Date) => {
      const expiry = getUnixTime(date);
      setReportDate(expiry);
    },
    [reportDate]
  );

  // Function to convert the date string format to ISO format for issue #307
  const convertDateFormat = (inputDateString: string): string => {
    // Split the input string by space to separate date and time parts
    const [datePart, timePart] = inputDateString.split(" ");
    // Split the date part by '/' to extract month, day, and year
    const [month, day, year] = datePart.split("/");
    // Construct the new date string in yyyy-mm-dd format
    const newDateString = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;
    // Combine the new date string with the time part using 'T' as separator
    const newFormattedString = `${newDateString}T${timePart}`;
    return newFormattedString;
  };

  // function to handle time zone selected by the user for issue #307
  const handleTimezone = (timeZone: number) => {
    switch (String(timeZone)) {
      case "0":
        return 'America/New_York';  //EST
      case "1":
        return 'America/Chicago'; //CST
      case "2":
        return 'America/Denver'; //MST
      case "3":
        return 'America/Los_Angeles'; //PST
      case "4":
        return 'America/Anchorage'; //AST
      case "5":
        return 'Pacific/Honolulu'; //HST
      default:
        return 'America/New_York';
    }
  };

  // function to handle report form submission for issue #273
  const onReportSubmit = async ({
    report_name,
    users,
    report_type,
    report_schedule_days,
    report_schedule_hours,
    report_schedule_tz,
    graph
  }: ReportsDashboardProps) => {
    try {
      // combining the date,time and timezone and converting it to UTC timestamp for issue #307
      const dated = new Date(reportDate * 1000);
      const dateString = dated.toLocaleDateString();
      var timeHourString;
      if (report_schedule_hours < 10) {
        timeHourString = "0" + report_schedule_hours + ":00:00 z";
      } else {
        timeHourString = report_schedule_hours + ":00:00 z";
      }
      const mainTime = dateString + " " + timeHourString;
      const convertedDate = convertDateFormat(mainTime);
      const fromTimezone = handleTimezone(report_schedule_tz);
      const dateTime = DateTime.fromISO(convertedDate, {
        zone: fromTimezone,
      });
      const unixEpoch = dateTime.toSeconds();
      const conval = dateTime.toString();

      const params = [...selectedParams];
      params.map((parameter: any, index: number) => {
        parameter.map((obj: any) => {
          obj.y_axis = String(obj.y_axis);
        })
      })

      const graphObj1 = [...graph];
      if (graph && graph.length > 0) {
        graphObj1.map((obj: any, index: number) => {
          obj.parameters = selectedParams[index];
          delete obj.y_axis;
        })
      }

      const selectedUserList = selectedUsers.map(
        (user: any) => {
          return {
            user_name: `${user.label}`,
            user_id: `${user.value}`,
          };
        }
      );

      const selectedAssetList = selectedAssets.map(
        (asset: any) => {
          return {
            asset_name: `${asset.label}`,
            asset_id: `${asset.value}`,
          };
        }
      );

      const newReport = {
        report_id: uuidv4(),
        report_name: report_name,
        report_type: "PDF",
        report_schedule_days: report_schedule_days,
        report_schedule_hours: report_schedule_hours,
        report_schedule_tz: report_schedule_tz,
        report_start_date: String(unixEpoch),
        // report_next_delivery: ""
        users: selectedUserList,
        asset_events: selectedAssetList,
        graph: graphObj1,
      };

      // graying out the create report after it is been clicked for issue #306
      setDisableParameterSaveButton(true);
      const createReport = await devicesAPI.createReport(accountId, newReport);
      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription("Report created successfully"));
      closeReportModal();
      setDisableParameterSaveButton(false);
    } catch (error) {
      setDisableParameterSaveButton(false);
      alert(error);
      console.log(error);
    }
  };

  // function to get users list for issue #273
  useEffect(() => {
    const getUserList = async () => {
      try {
        var userListArr = [...recipentUserList];
        const userList = await AccountAPI.getUserList(accountId);
        setLoading(false);

        userList.map((userObj: any, index: number) => {
          var temp = userObj?.given_name + " " + userObj?.family_name;
          var temp1 = { "label": temp, "value": userObj.user_id }
          userListArr.push(temp1)
        })
        setRecipentUserList(userListArr)
      } catch (error) {
        alert(error);
        console.log(error);
        setLoading(false);
      }
    };

    if (reCallUserList) {
      getUserList();
    }

    getUserList();
  }, [accountId]);

  // added useeffect with condition checking to disable the create button for issue #305
  useEffect(() => {
    if (
      selectedAssets && selectedAssets.length > 0 &&
      selectedUsers && selectedUsers.length > 0 &&
      selectedParams && selectedParams.length > 0 &&
      reportDate > 0
    )
      setDisableParameterSaveButton(false);
    else
      setDisableParameterSaveButton(true);
    var paramFlag = false;
    selectedParams.map((paramObj: any) => {
      if (paramObj.length == 0)
        paramFlag = true;
    })
    if (paramFlag)
      setDisableParameterSaveButton(true)
  }, [selectedAssets, selectedUsers, selectedParams, reportDate]);

  // function to get asset list for issue #273
  useEffect(() => {
    const getAssetList = async () => {
      try {
        var assetListArr = [...assetList];
        const assetNameList = await devicesAPI.getAssetList(accountId);
        setLoading(false);

        assetNameList.map((assetObj: any) => {
          var temp = { "label": assetObj?.asset_name, "value": assetObj?.asset_id }
          assetListArr.push(temp)
        })

        setAssetList(assetListArr)
      } catch (error) {
        alert(error);
        console.log(error);
        setLoading(false);
      }
    };

    getAssetList();
  }, [accountId]);

  // function to get parameter list for selected asset for issue #273
  useEffect(() => {
    const getParamList = async () => {
      try {
        if (treeDepth == "asset") {
          const response = await devicesAPI.retrieveReportDeviceParameters(nodeObject?.assetGroupId);
          const parametersList = response.map(
            (parameter: DeviceParametersProps) => {
              return {
                label: `${parameter.device_name} - ${parameter.parameter_name}`, // ! add device name to parameter name
                value: `${parameter.device_parameter_id}`,
                param_name: `${parameter.parameter_name}`,
                device_name: `${parameter.device_name}`,
                asset_id: `${nodeObject?.assetGroupId}`,
                asset_name: `${nodeObject?.title}`,
                device_id: `${parameter.device_id}`,
                y_axis: 0
              };
            }
          );
          setParamList(parametersList);
        }
        else {
          setParamList([]);
        }
      } catch (error) {
        alert(error);
      }
    };

    getParamList();
  }, [nodeObject]);

  // function to handle adding of parameter to the graph list for issue #273
  const addParameterToAsset = (parameter: any, index: number) => {
    const [deviceLabel, parameter_id] = [parameter.label, parameter.value];
    const [device_name, deviceId, param_name] = [parameter.device_name, parameter.device_id, parameter.param_name];
    const [asset_id, asset_name, y_axis] = [parameter.asset_id, parameter.asset_name, parameter.y_axis];
    try {
      if (selectedParams[index].length < 4) {
        var temp = {
          "device_id": deviceId,
          "device_label": deviceLabel,
          "parameter_id": parameter_id,
          "param_name": param_name,
          "device_name": device_name,
          "asset_id": asset_id,
          "asset_name": asset_name,
          "y_axis": y_axis
        }
        var tempArr = [...selectedParams];
        if (tempArr[index] != null) {
          tempArr[index].push(temp);
          setSelectedParams(tempArr);
        }
      }
    } catch (error) {
      console.log(error)
      alert(error);
    }
  };

  // function to handle adding of user to recipent list for issue #273
  const addUserToList = (user: any) => {
    try {
      if (user.length > 0) {
        setSelectedUsers(user);
      } else {
        setSelectedUsers([])
      }
    } catch (error) {
      console.log(error)
      alert(error);
    }
  };

  // function to handle adding of asset for issue #273
  const addAssetToList = (asset: any) => {
    try {
      if (asset.length > 0) {
        setSelectedAssets(asset);
      } else {
        setSelectedAssets([]);
      }
    } catch (error) {
      console.log(error)
      alert(error);
    }
  };

  // function for modifying y axis values for issue #273
  const yAxisChange = (event: any, index: number, index1: number) => {
    var yAxisArr = [...selectedParams];
    if (Number(event.target.value) == 1) {
      yAxisArr[index][index1].y_axis = 1;
    } else {
      yAxisArr[index][index1].y_axis = 0;
    }
    setSelectedParams(yAxisArr);
  }

  //function to add empty array to selectedParams whenever a new graph is added for issue #273
  const handleAddElement = () => {
    try {
      var tempParam = [...selectedParams];
      tempParam.push([]);
      setSelectedParams(tempParam);
    } catch (error) {
      console.log(error)
      alert(error);
    }
  };

  //function to remove array from selectedParams whenever a parameter is deleted from graph is deleted for issue #273
  const handleRemoveRow = (index: number, index1: number) => {
    var tempParamArr = [...selectedParams];
    if (tempParamArr[index] != null) {
      tempParamArr[index].splice(index1, 1);
    }
    setSelectedParams(tempParamArr);
  };

  // function to remove graph from selected Params whenever a graph is deleted for issue #273
  const handleRemoveGraph = (index: number) => {
    var tempParamArr = [...selectedParams];
    if (tempParamArr[index] != null) {
      tempParamArr.splice(index, 1);
    }
    setSelectedParams(tempParamArr);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onReportSubmit)}>
        <Grid container className={classes.root}>
          <Grid item xs={6}>
            <div className={classes.inputGroup}>
              <InputLabel
                label="Report name"
                name="report_name"
                errors={errors}
              />
              <input
                name="report_name"
                ref={register({
                  required: "This field is required.",
                  maxLength: {
                    value: 50,
                    message: `First name must be less than ${50}`,
                  },
                })}
                maxLength={50}
                type="text"
                placeholder="Enter"
                defaultValue={""}
              />
            </div>
          </Grid>
          <hr className={classes.lineBreak} />
          <Grid item xs={6}>
            <div className={classes.inputGroup}>
              <InputLabel
                label="Recipents"
                name="users"
                errors={errors}
              />
              <div style={{ display: "flex" }}>
                <Select
                  name="users"
                  styles={reactSelectStyles}
                  value={selectedUsers?.value}
                  onChange={addUserToList}
                  options={recipentUserList}
                  maxMenuHeight={220}
                  classNamePrefix="select"
                  placeholder="Select users"
                  isMulti
                  isSearchable
                />
              </div>
            </div>
          </Grid>
          <Grid style={{ width: "100%" }}>
            <div className={classes.inputGroup}>
              <InputLabel
                label="Select email schedule"
                name="email_schedule"
                errors={errors}
              />
              <div style={{ display: "flex" }}>
                <select
                  name="report_schedule_days"
                  ref={register({
                    required: "This field is required."
                  })}
                  placeholder="Enter"
                  defaultValue={0}
                >
                  {emailScheduleFrequencyList.map((typeObj: any) => {
                    return (
                      <option key={typeObj?.value} value={typeObj?.value}>
                        {typeObj?.name}
                      </option>
                    );
                  })}
                </select>
                <span className={classes.scheduleSpan}> at time </span>
                <select
                  name="report_schedule_hours"
                  ref={register({
                    required: "This field is required."
                  })}
                  placeholder="Enter"
                  defaultValue={0}
                  className={classes.scheduleSelect}
                >
                  {emailScheduleTimeList.map((typeObj: any) => {
                    return (
                      <option key={typeObj?.value} value={typeObj?.value}>
                        {typeObj?.name}
                      </option>
                    );
                  })}
                </select>
                <select
                  name="report_schedule_tz"
                  ref={register({
                    required: "This field is required."
                  })}
                  placeholder="Enter"
                  defaultValue={0}
                  className={classes.scheduleSelect}
                >
                  {emailScheduleTimezoneList.map((typeObj: any) => {
                    return (
                      <option key={typeObj?.value} value={typeObj?.value}>
                        {typeObj?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </Grid>
          {/* Added calendar for issue #307 */}
          <Grid>
            {/* User cannot set any date which is before the date shown in update modal */}
            <div className={classes.inputGroup}>
              <div className={classes.inputName}>
                Starting at
                <span className={classes.invalidText}>*</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center"
                }}
              >
                <div
                  style={{
                    flex: 1,
                    overflow: "hidden",
                    display: "flex"
                  }}
                >
                  <input
                    style={{
                      height: 30,
                      marginRight: 16,
                      cursor: "pointer"
                    }}
                    onClick={() => setCalendarOpen(true)}
                    value={
                      reportDate
                        ? customDateFormatUnix(
                          Number(reportDate),
                          "MMM dd yyyy"
                        )
                        : "N/A"
                    }
                  />
                  <Button
                    type="button"
                    color="defaultTheme"
                    onClick={() => setCalendarOpen(true)}
                  >
                    <img
                      src={calendarWhite}
                      alt="Subscription Expiry Calendar"
                    />
                  </Button>
                  {calendarOpen && (
                    <div className={classes.calendarDiv}>
                      <ClickAwayListener
                        onClickAway={() => setCalendarOpen(false)}
                      >
                        <Calendar
                          minDate={
                            new Date()
                          }
                          date={
                            reportDate
                              ? fromUnixTime(Number(reportDate))
                              : new Date()
                          }
                          onChange={calendarExpiryHandler}
                        />
                      </ClickAwayListener>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.inputGroup}>
              <InputLabel
                label="Select assets for event logs"
                name="asset_events"
                errors={errors}
              />
              <div>
                <Select
                  name="asset_events"
                  styles={reactSelectStyles}
                  value={selectedAssets?.value}
                  onChange={addAssetToList}
                  options={assetList}
                  maxMenuHeight={220}
                  classNamePrefix="select"
                  placeholder="Select assets"
                  isMulti
                  isSearchable
                />
              </div>
            </div>
          </Grid>
          <Grid >
            {fields.map((graph, index: number) => {
              return (
                <>
                  {index < 10 && (
                    <>
                      <div
                        key={graph.id}
                        style={{
                          // display: "grid",
                          // columnGap: "1rem",
                          // gridTemplateColumns: "2fr 2fr 1fr",
                          // paddingBottom: "1rem"
                        }}
                      >
                        <hr className={classes.lineBreak} />
                        <div className={classes.inputGroup}>
                          <b>Graph {index + 1}</b>
                        </div>
                        <hr className={classes.lineBreak} />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div className={classes.inputGroup} style={{ marginRight: "1.5rem", display: "flex", alignItems: "center" }}>
                            <p>Selected Asset:{" "}
                              <span>
                                {nodeObject?.childType === "asset" ? (
                                  nodeObject?.title
                                ) : (
                                  <>
                                    Please select an asset <span style={{ color: "red" }}>*</span>
                                  </>
                                )}
                              </span>
                            </p>
                          </div>
                          <img
                            src={Xsquare}
                            alt="Delete operation"
                            style={{
                              color: Colors.BasinRed,
                              cursor: "pointer"
                            }}
                            onClick={() => {
                              handleRemoveGraph(index);
                              remove(index);
                            }}
                          />
                        </div>
                        <div>
                          <div className={classes.expansionContainer}>
                            <div className={classes.buttonActions}>
                              <div className={classes.buttonRow}>
                                <Select
                                  name={`graph[${index}].parameters`}
                                  styles={reactSelectStyles}
                                  value={!paramList.length && { selection: 0 }}
                                  onChange={(e: any) => { addParameterToAsset(e, index) }}
                                  options={paramList}
                                  maxMenuHeight={220}
                                  classNamePrefix="select"
                                  placeholder="Select parameters"
                                  isSearchable
                                />
                              </div>
                            </div>
                            <div className={classes.inputGroup}>
                              Selected Parameters:
                            </div>
                            <TableContainer style={{ maxHeight: 500, margin: "1rem 0" }}>
                              <Table
                                style={{ width: "100%" }}
                                size="small"
                                aria-label="a dense table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell className={table.header} align="left">
                                      <span className={classes.headerName}>Asset Name</span>
                                    </TableCell>
                                    <TableCell className={table.header} align="left">
                                      <span className={classes.headerName}>Device</span>
                                    </TableCell>
                                    <TableCell className={table.header} align="left">
                                      <span className={classes.headerName}>Parameter</span>
                                    </TableCell>
                                    <TableCell className={table.header} align="left">
                                      <span className={classes.headerName}>Axis</span>
                                    </TableCell>
                                    <TableCell className={table.header} align="left">
                                      <span className={classes.headerName}>Action</span>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {selectedParams.length > 0 && selectedParams[index]?.map(
                                    (parameter: any, index1: number) => {
                                      return (
                                        <TableRow key={index1}>
                                          <TableCell className={table.tableCell} align="left">
                                            {parameter?.asset_name}
                                          </TableCell>
                                          <TableCell className={table.tableCell} align="left">
                                            {parameter.device_name}
                                          </TableCell>
                                          <TableCell className={table.tableCell} align="left">
                                            <span className={classes.name}>
                                              {parameter?.param_name}
                                            </span>
                                          </TableCell>
                                          <TableCell className={table.tableCell} align="left">
                                            <div>
                                              <select
                                                name={`graph[${index}].y_axis[${index1}]`}
                                                ref={register({
                                                  required: "This field is required."
                                                })}
                                                onChange={(event: any) => {
                                                  yAxisChange(event, index, index1);
                                                }}
                                                value={Number(parameter?.y_axis)}
                                              >
                                                {axisList.map((axisObj: any) => {
                                                  return (
                                                    <option key={axisObj?.value} value={axisObj?.value}>
                                                      {axisObj?.name}
                                                    </option>
                                                  );
                                                })}
                                              </select>
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            className={table.tableCell}
                                            style={{ padding: "0 2em" }}
                                            align="left"
                                          >
                                            <img
                                              src={Xsquare}
                                              alt="Delete asset property"
                                              className={classes.faIcon}
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                // remove();
                                                handleRemoveRow(index, index1);
                                              }}
                                            />
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div className={classes.inputGroup} style={{ marginRight: "0.5rem" }}>
                            <InputLabel label="Y-left min" name="yl_min" errors={errors} />
                            <input
                              name={`graph[${index}].yl_min`}
                              ref={register({
                                required: "This field is required.",
                                min: {
                                  value: -1000000.0,
                                  message: "Enter a number between -1000000.00 to 1000000.00"
                                },
                                max: {
                                  value: 1000000.0,
                                  message: "Enter a number between -1000000.00 to 1000000.00"
                                },
                                validate: (value) =>
                                  parseFloat(value) < parseFloat(getValues(`graph[${index}].yl_max`)) ||
                                  "Y-left min must be less than Y-left max."
                              })}
                              type="number"
                              step="any"
                              placeholder="Enter"
                              defaultValue={-100}
                            />
                          </div>
                          <div className={classes.inputGroup} style={{ marginRight: "0.5rem" }}>
                            <InputLabel label="Y-left max" name="yl_max" errors={errors} />
                            <input
                              name={`graph[${index}].yl_max`}
                              ref={register({
                                required: "This field is required.",
                                min: {
                                  value: -1000000.0,
                                  message: "Enter a number between -1000000.00 to 1000000.00"
                                },
                                max: {
                                  value: 1000000.0,
                                  message: "Enter a number between -1000000.00 to 1000000.00"
                                },
                                validate: (value) =>
                                  parseFloat(value) > parseFloat(getValues(`graph[${index}].yl_min`)) ||
                                  "Y-left max must be greater than Y-left min."
                              })}
                              type="number"
                              step="any"
                              placeholder="Enter"
                              defaultValue={100}
                            />
                          </div>
                          <div className={classes.inputGroup} style={{ marginRight: "0.5rem" }}>
                            <InputLabel label="Y-right min" name="yr_min" errors={errors} />
                            <input
                              name={`graph[${index}].yr_min`}
                              ref={register({
                                required: "This field is required.",
                                min: {
                                  value: -1000000.0,
                                  message: "Enter a number between -1000000.00 to 1000000.00"
                                },
                                max: {
                                  value: 1000000.0,
                                  message: "Enter a number between -1000000.00 to 1000000.00"
                                },
                                validate: (value) =>
                                  parseFloat(value) < parseFloat(getValues(`graph[${index}].yr_max`)) ||
                                  "Y-right min must be less than Y-right max."
                              })}
                              type="number"
                              step="any"
                              placeholder="Enter"
                              defaultValue={-100}
                            />
                          </div>
                          <div className={classes.inputGroup}>
                            <InputLabel label="Y-right max" name="yr_max" errors={errors} />
                            <input
                              name={`graph[${index}].yr_max`}
                              ref={register({
                                required: "This field is required.",
                                min: {
                                  value: -1000000.0,
                                  message: "Enter a number between -1000000.00 to 1000000.00"
                                },
                                max: {
                                  value: 1000000.0,
                                  message: "Enter a number between -1000000.00 to 1000000.00"
                                },
                                validate: (value) =>
                                  parseFloat(value) > parseFloat(getValues(`graph[${index}].yr_min`)) ||
                                  "Y-right max must be greater than Y-right min."
                              })}
                              type="number"
                              step="any"
                              placeholder="Enter"
                              defaultValue={100}
                            />
                          </div>
                        </div>
                        <div className={classes.inputGroup} style={{ display: "flex" }}>
                          <InputLabel
                            label="Graph last"
                            name="graph_duration_days"
                            errors={errors}
                          />
                          <select
                            style={{ marginLeft: "1rem" }}
                            name={`graph[${index}].graph_duration_days`}
                            ref={register({
                              required: "This field is required."
                            })}
                            placeholder="Enter"
                            defaultValue={1}
                          >
                            {graphDurationList.map((graphObj: any) => {
                              return (
                                <option key={graphObj?.value} value={graphObj?.value}>
                                  {graphObj?.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <hr className={classes.lineBreak} />
                    </>
                  )}
                </>
              );
            })}
            <div>
              <Button
                type="button"
                color="defaultTheme"
                onClick={() => {
                  append({});
                  handleAddElement();
                }}
              >
                + Add graph
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid className={classes.actionItems}>
          <Button
            type="button"
            color="white"
            onClick={closeReportModal}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="defaultTheme"
            // Added style and condition to disable save button for issue #305
            style={{
              opacity: disableParameterSaveButton
                ? "0.6"
                : "unset"
            }}
            disabled={disableParameterSaveButton}>
            Create report
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default DefaultReportsDisplay;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      margin: "1em 0 0 1em"
    },
    faIcon: {
      color: theme.palette.primary.light,
      backgroundColor: "transparent"
    },
    inputGroup: {
      padding: "1em 0",
      // height: 92,
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem"
      },
      "& input": {
        width: "100%",
        padding: ".5rem 1rem",
        border: `1px solid ${Colors.LightGray}`
        // height: 30,
      },
    },
    label: {
      fontSize: "14px"
    },
    required: {
      color: Colors.Pink
    },
    lineBreak: {
      width: "100%",
      // border: "none",
      borderBottom: `2px ${Colors.LightGray}`,
    },
    scheduleSpan: {
      margin: "0 0 0 1rem",
      display: "flex",
      alignItems: "center"
    },
    scheduleSelect: {
      marginLeft: "1rem"
    },
    group: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    expansionContainer: { display: "flex", flexDirection: "column" },
    buttonActions: {
      margin: "1em",
    },
    headerName: {
      color: theme.palette.primary.light,
    },
    name: {
      cursor: "pointer",
      color: theme.palette.primary.light,
      fontWeight: 600,
    },
    buttonRow: {
      display: "flex",
      flexDirection: "row",
      width: "50%",
      height: 38,

      "& select": {
        width: "100%",
        padding: "0 1rem",
        border: `1px solid ${Colors.LightGray}`,
        height: 38,
        cursor: "pointer",
      },
    },
    actionItems: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "1rem 1.5rem",
      borderTop: `1px solid ${Colors.LightGray}`,
      padding: "1rem 0",
    },
    cancelButton: {
      padding: ".7rem 1.5rem",
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      cursor: "pointer",
      marginRight: "1.5rem",
      fontWeight: "bold",
      backgroundColor: "white",
    },
    // Added css classes for calendar for issue #307
    invalidText: {
      margin: "0 0 0 1px",
      color: "#ED254E"
    },
    calendarDiv: {
      position: "absolute",
      zIndex: 999,
      top: "40%",
      left: "60%",

      [`@media (min-height:800px)`]: {
        top: "auto",
        left: "auto",
        marginTop: "30px"
      }
    },
    inputName: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
  })
);

const reactSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer",
    width: "375px",
  }),
};