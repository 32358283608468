import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AWSIoTJobExecutionStatus } from "services/types";

type SliceState = {
  otaStatusTable: Record<
    string,
    | {
        status: AWSIoTJobExecutionStatus;
        jobId: string;
        lastUpdatedAt?: string;
      }
    | undefined
  >;
};

const initialState: SliceState = {
  otaStatusTable: {},
};

const appSlice = createSlice({
  name: "firmware",
  initialState,
  reducers: {
    updateOtaStatusTable: (
      state,
      action: PayloadAction<{
        deviceId: string;
        status: AWSIoTJobExecutionStatus;
        jobId: string;
        lastUpdatedAt?: string;
      }>
    ) => {
      const { deviceId, status, jobId, lastUpdatedAt } = action.payload;
      state.otaStatusTable[deviceId] = { status, jobId, lastUpdatedAt };
    },
  },
});

export const firmwareActions = appSlice.actions;

export default appSlice.reducer;
