/*Issue fixed for github issue #290*/
import React, { useEffect, useState, useCallback } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { AssetCategoryObject, PropertyValues } from "./types";
import { formModalStyles } from "common/formModalStyles";
import { useDispatch } from "react-redux";
import { setCallAssetProfile } from "components/asset-modals/assetGroupModalSlice";
import { InputLabel } from "components/input-label/InputLabel";
import { useForm } from "react-hook-form";
import { RequiredAssetProps } from "./types";
import AssetAPI from "services/assetsAPI";
import clsx from "clsx";
import { Colors } from "common/Colors";
import Button from "components/common-buttons/Button";

const propertyValuesArray = [
  {
    name: "",
    value: "",
  },
  {
    name: "",
    value: "",
  },
  {
    name: "",
    value: "",
  },
  {
    name: "",
    value: "",
  },
  {
    name: "",
    value: "",
  },
  {
    name: "",
    value: "",
  },
  {
    name: "",
    value: "",
  },
  {
    name: "",
    value: "",
  },
  {
    name: "",
    value: "",
  },
  {
    name: "",
    value: "",
  },
];

type AssetModalProps = {
  open: boolean;
  handleClose: () => void;
  successDescription?: string;
  handleSave: () => void;
  handleSuccessModal?: () => void;
  nodeObject: any;
};

// ! on close, reset all states to original
export const AddNewAssetModal: React.FC<AssetModalProps> = ({
  open,
  handleClose,
  handleSave,
  nodeObject,
}) => {
  const classes = formModalStyles();
  const dispatch = useDispatch();
  const [assetSharing, setAssetSharing] = useState(true);
  const [assetCategoryList, setAssetCategoryList] = useState([]);
  const [assetCategoryId, setAssetCategoryId] = useState("");
  const [assetCategoryProperties, setAssetCategoryProperties] = useState<
    Array<any>
  >([]);
  const [propertyValues, setPropertyValues] =
    useState<Array<PropertyValues>>(propertyValuesArray);
  const [resettedPropertyValues, setResettedPropertyValues] =
    useState<Array<PropertyValues>>(propertyValuesArray);
  // const [propertyValueObject, setPropertyValueObject] = useState<any>(
  //   propertyObjects
  // );
  const [assetName, setAssetName] = useState("");
  const { register, handleSubmit, errors } = useForm<RequiredAssetProps>();

  const handlePropertyValues = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    propertyName: string
  ) => {
    const copiedPropertyValues = [...propertyValues];

    copiedPropertyValues[index] = {
      name: propertyName,
      value: e.currentTarget.value,
    };

    setPropertyValues(copiedPropertyValues);
  };

  const transformPropertyValues = (propertyValueArray: PropertyValues[]) => {
    const propertyObject = propertyValueArray.reduce(
      (accum: any, object: PropertyValues, index: number) => {
        accum = {
          ...accum,
          ...{
            [`property_${index + 1}`]: {
              name: object.name,
              value: object.value,
            },
          },
        };

        return accum;
      },
      {}
    );

    return propertyObject;
  };

  const handleAssetClose = useCallback(() => {
    setPropertyValues(resettedPropertyValues);
    setAssetCategoryId("");
    setAssetName("");
    handleClose();
  }, [handleClose]);

  const handleAssetSave = async ({
    assetName,
    assetCategoryId,
  }: RequiredAssetProps) => {
    // console.log("NODE OBJECT: ", nodeObject);
    // console.log(propertyValues);
    const assetObject = {
      asset_name: assetName,
      asset_sharing: assetSharing,
      shared_customers: [],
      asset_category_id: assetCategoryId,
      tenant_id: nodeObject.parentTenantId || nodeObject.tenantId,
      parent_asset_id: !nodeObject.isAssetGroup ? nodeObject.assetGroupId : "",
      parent_asset_group_id: nodeObject.isAssetGroup
        ? nodeObject.assetGroupId
        : "",
      to_group: nodeObject.isAssetGroup,
      property_values: transformPropertyValues(propertyValues),
    };

    // console.log("ASSET OBJECT: ", assetObject);
    try {
      await AssetAPI.postNewAsset(assetObject);
      dispatch(setCallAssetProfile(true));
      handleSave();
      handleAssetClose();
    } catch (error) {
      alert(error);
      if (process.env.NODE_ENV === 'development') {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (open) {
      (async () => {
        try {
          const assetCategoryList = await AssetAPI.getAssetCategoryList();
          setAssetCategoryList(assetCategoryList);
        } catch (error) {
          alert(error);
          if (process.env.NODE_ENV === 'development') {
            console.log(error);
          }
        }
      })();
    }
  }, [open]);

  const handleInitialCategoryProperties = useCallback(
    (assetCategoryProperties) => {
      // console.log("CATEGORY PROPERTIES: ", assetCategoryProperties);

      const assetCategoryPropertiesArray = assetCategoryProperties.map(
        (object: any) => {
          return {
            name: object[`property_${object.id}`],
            value: "",
          };
        }
      );
      // ! add some setState to give
      setResettedPropertyValues(assetCategoryPropertiesArray);
      setPropertyValues(assetCategoryPropertiesArray);
    },
    []
  );

  useEffect(() => {
    const retrieveAssetCategoryProfile = async () => {
      try {
        const assetCategoryProfile =
          await AssetAPI.retrieveAssetCategoryProfile(assetCategoryId);

        // console.log(assetCategoryProfile.property_names);

        const assetCategoryProperties = Object.entries(
          assetCategoryProfile.property_names
        )
          .map((propertyObject: any) => ({
            [propertyObject[0]]: propertyObject[1],
            id: parseInt(propertyObject[0].slice(9)),
          }))
          .sort((a: any, b: any) => a.id - b.id);

        setAssetCategoryProperties(assetCategoryProperties);
        handleInitialCategoryProperties(assetCategoryProperties);
      } catch (error) {
        alert(error);
        if (process.env.NODE_ENV === 'development') {
          console.log(error);
        }
      }
    };

    if (assetCategoryId) {
      retrieveAssetCategoryProfile();
    }
  }, [assetCategoryId, handleInitialCategoryProperties]);

  return (
    <div style={{ display: "flex" }}>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalOverlay}>
          <form onSubmit={handleSubmit(handleAssetSave)}>
            <div className={classes.modalBody}>
              <div className={classes.header}>
                <p className={classes.headerText}>Add new asset</p>
                <CloseIcon className={classes.icon} onClick={handleClose} />
              </div>
              <div className={classes.inputBody}>
                <div className={classes.pathText}>
                  <p style={{ fontWeight: 600, color: "#A6A6A6" }}>Path: </p>
                  <p style={{ fontWeight: "bold" }}> {nodeObject.title} </p>
                </div>
                <div className={classes.labelText}>
                  {/* Changed text for issue #290 */}
                  <p>Selection: </p>
                  <p style={{ fontWeight: "bold" }}> {nodeObject.title} </p>
                </div>
                {/* // ! commented out code for future implementation */}
                {/* <div className={classes.inputGroup}>
                  <span>Asset sharing</span>
                  <div className={classes.toggleButtonContainer}>
                    <button
                      className={clsx(classes.toggleButton, {
                        [classes.active]: assetSharing,
                      })}
                      type="button"
                      onClick={() => {
                        setAssetSharing(true);
                      }}
                    >
                      ON
                    </button>
                    <button
                      className={clsx(classes.toggleButton, {
                        [classes.active]: !assetSharing,
                      })}
                      type="button"
                      onClick={() => {
                        setAssetSharing(false);
                      }}
                    >
                      OFF
                    </button>
                  </div>
                </div> */}
                {/* <div
                  className={clsx(classes.inputGroup, {
                    [classes.hide]: !assetSharing,
                  })}
                >
                  <span>Shared with customer(s)</span>
                  <div>
                    <button
                      className={classes.selectButton}
                      type="button"
                      onClick={() => {
                        setAssetSharing(true);
                      }}
                    >
                      Select
                    </button>
                  </div>
                </div> */}
                <div
                  className={clsx({ [classes.inputsContainer]: !assetSharing })}
                >
                  <div className={classes.inputGroup}>
                    <InputLabel
                      label="Asset name"
                      name="assetName"
                      errors={errors}
                    />
                    <input
                      name="assetName"
                      ref={register({
                        required: "This field is required",
                      })}
                      type="text"
                      placeholder="Enter"
                    // value={assetName}
                    // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    //   setAssetName(e.currentTarget.value)
                    // }
                    />
                  </div>
                  <div className={classes.inputGroup}>
                    <InputLabel
                      label="Asset category"
                      name="assetCategoryId"
                      errors={errors}
                    />
                    <select
                      name="assetCategoryId"
                      ref={register({
                        required: "Please select an asset category.",
                      })}
                      placeholder="Select"
                      // value={assetCategoryId}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        setAssetCategoryId(e.target.value)
                      }
                    >
                      <option value="">Select an asset category ...</option>
                      {assetCategoryList.map(
                        (assetCategory: AssetCategoryObject, index: number) => {
                          return (
                            <option
                              key={assetCategory.asset_category_id + index}
                              value={assetCategory.asset_category_id}
                            >
                              {assetCategory.category_name}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                  {assetSharing && assetCategoryId && (
                    <div>
                      {assetCategoryProperties.map(
                        (object: any, index: number) => {
                          if (object[`property_${object.id}`]) {
                            return (
                              <div key={index} className={classes.inputGroup}>
                                <span>
                                  {object[`property_${object.id}`] ||
                                    `Asset Property ${object.id}`}{" "}
                                  {/* <span style={{ color: Colors.Pink }}>*</span> */}
                                </span>
                                <input
                                  type="text"
                                  placeholder="Enter"
                                  value={propertyValues[index].value}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    handlePropertyValues(
                                      e,
                                      index,
                                      object[`property_${object.id}`] || ""
                                    );
                                  }}
                                />
                              </div>
                            );
                          }
                          return null;
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.actionItems}>
                <Button type="button" color="white" onClick={handleAssetClose}>
                  Cancel
                </Button>
                <Button type="submit" color="defaultTheme">
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AddNewAssetModal;
