/* Issue fixed for github issue #1, issue #17, issue #27, issue #28, issue #46,issue #94,issue #72,issue #89,issue #195,issue #215,issue #221,issue #27,issue #241,issue #243,issue #273 */
import { ReportsDashboardProps } from "features/reports-page/types";  //imported for issue #273
import { axiosCall, URL, Method } from "./config";

export const devicesAPI = {
  // ! located in devices page
  //#region
  retrieveDeviceProfile: async (device_id: string) => {
    const response = await axiosCall(Method.POST, `${URL()}/device/profile`, {
      device_id
    });
    return response.data.Item;
  },
  createDevice: async (
    device_id: string,
    hardware_id: string,
    parent_tenant_id: string
  ) => {
    const response = await axiosCall(Method.POST, `${URL()}/device`, {
      device_id,
      hardware_id,
      parent_tenant_id
    });
    return response;
  },
  deleteDevice: async (device_id: string) => {
    const response = await axiosCall(Method.DELETE, `${URL()}/device`, {
      device_id
    });
    return response;
  },
  updateDeviceInfo: async (
    device_id: string,
    device_name: string,
    loss_of_comms_notify: boolean,
    notify_after_4hrs: boolean, //Added for notify key for issue #195
    repeat_24hrs: boolean, //Added for notify key for issue #195
    device_notes: string
  ) => {
    const response = await axiosCall(Method.PUT, `${URL()}/device`, {
      device_id,
      device_name,
      loss_of_comms_notify,
      notify_after_4hrs, //Added for notify key for issue #195
      repeat_24hrs, //Added for notify key for issue #195
      device_notes
    });
    return response.data;
  },
  retrieveListOfDevices: async (tenant_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/device/list_of_devices`,
      { tenant_id }
    );
    return response.data.Items;
  },
  registerDeviceRequest: async (device_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/device/register_device_request`,
      { device_id }
    );
    return response;
  },
  //#endregion
  // ! located in assets page, used to add devices and parameters to assets
  //#region
  retrieveDeviceParameters: async (asset_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/asset/device_parameters`,
      {
        asset_id
      }
    );
    return response.data.Item;
  },
  addParameterToAsset: async (
    asset_id: string,
    device_id: string,
    device_parameter_id: string
  ) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/asset/add_parameter`,
      {
        asset_id,
        device_id,
        device_parameter_id
      }
    );
    return response.data.Attributes.asset_parameters;
  },
  deleteAssetsParameter: async (
    asset_id: string,
    device_parameter_id: string
  ) => {
    const response = await axiosCall(
      Method.DELETE,
      `${URL()}/asset/delete_parameter`,
      {
        asset_id,
        device_parameter_id
      }
    );
    return response.data.Attributes.asset_parameters;
  },
  deleteAssetsDevice: async (asset_id: string, device_id: string) => {
    const response = await axiosCall(
      Method.DELETE,
      `${URL()}/asset/delete_device`,
      {
        asset_id,
        device_id
      }
    );
    //returning just attributes instead of list of devices for issue #27
    return response.data.Attributes;
  },
  addDeviceToAsset: async (
    asset_id: string,
    device_id: string,
    show_notifications: boolean
  ) => {
    const response = await axiosCall(Method.POST, `${URL()}/asset/add_device`, {
      asset_id,
      device_id,
      show_notifications
    });
    return response.data.Attributes.asset_devices;
  },
  moveParameter: async (
    asset_id: string,
    device_parameter_id: string,
    index: number
  ) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/asset/move_parameter`,
      {
        asset_id,
        device_parameter_id,
        index
      }
    );
    return response.data.Attributes.asset_parameters;
  },
  listDevicesForGivenAsset: async (asset_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/asset/list_of_devices`,
      {
        asset_id
      }
    );
    return response.data.Items;
  },
  //API added for showing device ids of wireless rulo nodes for issue #94
  listOfWirelessNodes: async (tenant_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/device/list_of_wireless_nodes`,
      {
        tenant_id
      }
    );
    return response.data;
  },
  //API added for parameter list for CANJ1939 and Edgetrak 4N devices for issue #95
  parameterListForCANJandEdgetrak: async (parameterObject: any) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/device/device_params_by_source`,
      {
        source: parameterObject
      }
    );
    return response.data;
  },
  //API added for snapshot type list for snapshots for issue #221
  snapshotTypeList: async () => {
    const response = await axiosCall(
      Method.GET,
      `${URL()}/device/device_snapshot_type`
    );
    return response.data;
  },
  //#endregion
  // ! device Parsers
  connectorDataPush: async (
    tenant_id: string,
    connector_id: string,
    device_id: string,
    ts_start: string,
    updatePushTime: string
  ) => {
    console.log(tenant_id);
    console.log(connector_id);
    console.log(device_id);
    console.log(ts_start);

    const response = await axiosCall(
      Method.POST,
      `${URL()}/dataconnector/push_data`,
      {
        tenant_id: tenant_id,
        connector_id: connector_id,
        ts_start: ts_start,
        device_id: device_id,
        update_push_time: updatePushTime
      }
    );
    console.log("API call")
    return response;
  },

  prepareDeviceForBurst: async (device_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/device_parser/burst`,
      {
        device_id
      }
    );
    return response;
  },
  controlRequest: async (
    device_id: string,
    parameter_number: number,
    control_data: number,
    asset_id: string // Added the asset Id in the api call. Github issue #28
  ) => {
    //retriving the userid from localstorage for issue #1
    const user_id = localStorage.getItem("userid");
    const response = await axiosCall(
      Method.POST,
      `${URL()}/device_parser/control`,
      {
        device_id,
        parameter_number,
        control_data,
        user_id, //sending userid along with API call for fixing issue #1
        asset_id // Added the asset Id in the api call. Github issue #28
      }
    );
    return response;
  },
  //Added burst api call for test local device data for issue #243
  prepareLocalDeviceForBurst: async (
    device_id: string,
    source: number,
    start_reg: number,
    num_reg: number
  ) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/device_parser/burst_local_device`,
      {
        device_id,
        source,
        start_reg,
        num_reg
      }
    );
    return response;
  },
  // Added new API for clear active events button on asset status page for issue #72
  clearAlarm: async (device_ids: string[], user_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/device_parser/clear_alarm`,
      {
        device_ids,
        user_id
      }
    );
    return response;
  },
  // Added API for displaying selected Asset Parameter configurations for issue #89
  paramAssetMapView: async (device_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/device/param_asset_map_view`,
      {
        device_id
      }
    );
    return response;
  },
  // Added APi for sending the selected Asset Parameter configurations for issue #89
  paramAssetMapUpdate: async (device_id: string, device_asset_map: any[]) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/device/param_asset_map_update`,
      {
        device_id,
        device_asset_map
      }
    );
    return response;
  },
  // ! data
  requestLastDataForDeviceParameters: async (device_id: string) => {
    const response = await axiosCall(Method.POST, `${URL()}/data/latest`, {
      device_id
    });
    return response;
  },
  updateDeviceSubscription: async ({
    device_id,
    subscription_expiry,
    subscription_level
  }: UpdateSubscriptionPayload) => {
    // update_device_subscription
    await axiosCall(Method.POST, `${URL()}/device/update_device_subscription`, {
      device_id,
      subscription_expiry,
      subscription_level
    });
  },
  generateGraph: async (
    device_id: string,
    start_date: number,
    end_date: number,
    parameter_index: number[] //updated the parameter index to array of numbers. Github issue #17
  ) => {
    //Added the timezone for showing the local time in the graph. Github issue #46
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axiosCall(Method.POST, `${URL()}/data`, {
      device_id,
      start_date,
      end_date,
      parameter_index,
      timezone //sending the timezone in the api. Github issue #46
    });
    return response.data;
  },
  // Added api to generate graph for assets data for issue #241
  generateAssetGraph: async (
    device_ids: any[],
    start_date: number,
    end_date: number,
    parameter_index: number[]
  ) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axiosCall(
      Method.POST,
      `${URL()}/data/asset_status_data`,
      {
        device_ids,
        start_date,
        end_date,
        parameter_index,
        timezone
      }
    );
    return response.data;
  },
  // Added api to download data for device parameters for issue #215
  downloadCSVData: async (
    device_id: string,
    start_date: number,
    end_date: number,
    parameter_index: number[]
  ) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axiosCall(
      Method.POST,
      `${URL()}/data/download_data`,
      {
        device_id,
        start_date,
        end_date,
        parameter_index,
        timezone
      }
    );
    return response.data;
  },
  // Added new api to fetch timestamp cards data for issue #227
  getTimestampCards: async (device_id: string, start_date: number) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axiosCall(
      Method.POST,
      `${URL()}/data/timestamp_cards`,
      {
        device_id,
        start_date,
        timezone
      }
    );
    return response.data;
  },
  // Added new api to fetch snapshot data for graph for issue #227
  snapshotGraphData: async (
    device_id: string,
    timestamp: number,
    id: number
  ) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/data/snapshot_data`,
      {
        device_id,
        timestamp,
        id
      }
    );
    return response.data;
  },
  // Added new api to fetch all parameters connected to an asset for issue #273
  retrieveReportDeviceParameters: async (asset_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/report/device_parameters`,
      {
        asset_id
      }
    );
    return response.data.Item;
  },
  // Added new api to create a new report for issue #273
  createReport: async (
    tenant_id: string,
    reports: ReportsDashboardProps
  ) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/report/create_report`,
      {
        tenant_id,
        reports
      }
    );
    return response.data;
  },
  // Added new api to update report for issue #273
  updateReport: async (
    tenant_id: string,
    reports: ReportsDashboardProps
  ) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/report/update_report`,
      {
        tenant_id,
        reports
      }
    );
    return response.data;
  },
  // Added new api for list of reports for issue #273
  listOfReports: async (
    tenant_id: string,
  ) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/report/retrieve_report`,
      {
        "tenant_id": tenant_id,
      }
    );
    return response.data;
  },
  // Added new api to delete report for issue #273
  deleteReport: async (
    tenant_id: string,
    report_id: string
  ) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/report/delete_report`,
      {
        tenant_id,
        report_id
      }
    );
    return response.data;
  },
  // Added new api for list of assets in an account for issue #273
  getAssetList: async (
    tenant_id: string
  ) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/report/asset_list`,
      {
        tenant_id
      }
    );
    return response.data.Item;
  },
  // Added new api for testing reports in an account for issue #273
  testReport: async (
    tenant_id: string,
    report_id: string
  ) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/report/test`,
      {
        tenant_id,
        report_id
      }
    );
    return response.data;
  },
};

export type UpdateSubscriptionPayload = {
  device_id: string;
  subscription_level: number; // 0-disabled, 1-Demo, 2-Basic, 3-Enhanced, 4-Complete
  subscription_expiry: string; // UNIX time
};
