/* Issue fixed for github issue #2, issue #3, issue #16, issue #24, issue #59, issue #57, issue #56, issue #44, issue #28, issue #82,issue #103,issue #127,issue #132,issue #140,issue #163,issue #174,issue #210  */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
// import { Colors } from "common/Colors";
import {
  Typography,
  Paper,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
  // Typography,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import { LinearIndeterminate } from "common/LinearLoading";
// import { AssetDisplayPanelProps } from "./Types";
import { AssetsProp, AssetProp } from "features/asset-drilldown/Types";
import { SingleAssetProp } from "components/SingleAssetCard/types";
import { dashboardAPI } from "services/dashboardAPI";
// import { assetObject } from "./mockData";
// import { DeviceParametersProps } from "features/devices-page/device-details/types";
import Button from "components/common-buttons/Button";
import {
  DecodingDisabledInput,
  DecodingEnabledSelect
} from "features/devices-page/device-details/device-details-tab/DecodingFields";
import { devicesAPI } from "services/devicesAPI";
import BarChart from "components/asset-charts/BarChart";
import BarChart2 from "components/asset-charts/BarChart2";
import IndicatorChart from "components/asset-charts/IndicatorChart";
import { ParameterProps } from "features/real-time-status-page/types";
import GaugeChart from "components/asset-charts/GaugeChart";
import DonutChart from "components/asset-charts/DonutChart";
import AssetStatusCategoryIcons from "components/category-icons/AssetStatusCategoryIcons";
import { grabCurrentColor } from "utils/iconColorHandler";
// import { useIsMounted } from "utils/helpers";
import { WEBSOCKET_URL } from "config";
import { handleTimeStamp } from "utils/helpers";
import { Colors } from "common/Colors";
import { getUnixTime } from "date-fns";
import { binaryFromJsonList } from "utils/binaryToText";
import { numberToTextKey } from "utils/constants";
import { binaryToTextKey } from "utils/constants";
import { fieldView } from "utils/constants";
import { getDecodedVal } from "utils/conversions";

type SelectChangeEvent = React.ChangeEvent<HTMLSelectElement>;

type DeviceParamValueMap = Record<string, Record<number, number | undefined>>;

interface IProps {
  // asset: AssetsProp | AssetProp; // ! AssetProp
  asset: any;
  singleAsset?: boolean;
  index: any; //added index props for styling left and right hand tiles for issue #82
}
//added index props for styling left and right hand tiles for issue #82
const AssetDisplayPanel = ({ asset, singleAsset, index }: IProps) => {
  const classes = useStyles();
  // const isMounted = useIsMounted(); // TODO
  const wsClientRef = useRef<WebSocket | null>(null);

  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [lastReadTime, setLastReadTime] = useState<number>(0); // unix time
  const [control, setControl] = useState<Record<string, boolean | string>>({});
  const [assetProperties, setAssetProperties] = useState<
    { name: string; value: string }[]
  >([]);
  const [sensorParameters, setSensorParameters] = useState<ParameterProps[]>();
  const [setpointControlParameters, setSetpointControlParameters] = useState<
    ParameterProps[]
  >();
  const [selectVal, setSelectVal] = useState<string>("0");
  const [deviceParamsMap, setDeviceParamsMap] = useState<DeviceParamValueMap>(
    {}
  ); // {   }
  const [assetInformation, setAssetInformation] = useState();
  const [digitalOutputValue, setDigitalOutputValue] = useState("1");
  const [digitalOutput, setDigitalOutput] = useState<
    Record<string, boolean | string>
  >({});
  const [newDataArr, setNewDataArr] = useState<string[]>([]);
  const emptyString = "";
  // Added boolean array to disable any button for 3s when clicked for issue #103
  const [buttonDisable, setButtonDisable] = useState<boolean[]>([]);

  const { device_parameters } = useSelector(
    (state: RootState) => state.DeviceSettingsSlice.modifiedDeviceTabDetails
  );

  //Added role variable for checking the role. Github issue #16
  const selectedAccountUserRole = useSelector(
    (state: RootState) => state.AppSlice?.selectedAccount?.role || ""
  );

  const handleAssetProperties = (assetProperties: any) => {
    const filteredProperties: any = Object.values(assetProperties).filter(
      ({ name, value }: any) => name && value
    );
    setAssetProperties(filteredProperties);
  };

  const filterAssetParameters = (
    asset_parameters: ParameterProps[],
    devices_in_asset: string[]
  ) => {
    const sensorParams = asset_parameters.filter(
      (param: ParameterProps) => param.parameter_type === 1 && param.source != 3
    );
    const setPointControlParams = asset_parameters.filter(
      (param: ParameterProps) => param.parameter_type > 1 || param.source == 3
    );

    setSensorParameters(sensorParams);
    setSetpointControlParameters(setPointControlParams);

    const deviceParamsTable = devices_in_asset.reduce<DeviceParamValueMap>(
      (map, deviceId) => {
        map[deviceId] = {};
        return map;
      },
      {}
    );

    for (let param of asset_parameters) {
      // add initial values from this API for both sensor & setpoint parameters to be updated later by websocket
      deviceParamsTable[param.device_id] = {
        ...deviceParamsTable[param.device_id],
        [param.parameter_number - 1]: param.parameter_value // parameter_number = device param array index + 1
      };
    }

    setDeviceParamsMap(deviceParamsTable);
  };

  const applyDigitalOutputRequest = async (
    deviceId: string,
    parameter: any,
    isOpenClose?: string, //variable to check which button is clicked for issue #103
    isEncodingEnabled?: boolean //variable to check if text encoding is given by the user for issue #103
  ) => {
    // Added variable to save text json file for issue #103
    var valArr;
    if (isEncodingEnabled) {
      valArr = mappedJson(parameter.text_json);
    } else {
      valArr = [
        {
          name: "OFF",
          value: "0"
        },
        {
          name: "ON",
          value: "1"
        }
      ];
    }
    // Added variable to check which button is clicked and set dout value accordingly for issue #103
    var temp1;
    if (valArr[0].name == isOpenClose) {
      // sending the value given in text_json for issue #127
      temp1 = valArr[0].value;
      // setDigitalOutputValue("0");
    } else {
      // sending the value given in text_json for issue #127
      temp1 = valArr[1].value;
      // setDigitalOutputValue("1");
    }
    try {
      await devicesAPI.controlRequest(
        deviceId,
        parameter.parameter_number,
        Number(temp1),
        emptyString // Added the asset Id as emptyString as assetId is required for the api call. Github issue #28
      );
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(error);
      }
    }
  };

  // function to disable buttons when clicked for 3s for issue #103
  const disableButtonOnClick = (index: number) => {
    var temp = [...buttonDisable];
    var temp1 = [...buttonDisable];
    temp[index] = !temp[index];
    setButtonDisable(temp);
    setTimeout(() => {
      setButtonDisable(temp1);
    }, 3000);
  };

  const burstHandler = useCallback((deviceIds: string[]) => {
    const prepareDeviceHandler = async () => {
      try {
        for (let deviceId of deviceIds) {
          await devicesAPI.prepareDeviceForBurst(deviceId);
        }
      } catch (error) {
        // Handle error, e.g., alert("Error preparing device for burst");
      }
    };

    const handleWebSocketConnection = () => {
      if (wsClientRef.current !== null) {
        // Socket already open, but a new burst is being requested
        prepareDeviceHandler();
      } else {
        // No existing socket, so create a new one
        wsClientRef.current = new WebSocket(`${WEBSOCKET_URL}`);

        wsClientRef.current.onopen = () => {
          prepareDeviceHandler();
        };

        wsClientRef.current.onclose = () => {
          wsClientRef.current = null;
        };

        wsClientRef.current.onerror = () => {
          alert("Error opening websocket for burst data");
          wsClientRef.current?.close();
          wsClientRef.current = null;
        };

        wsClientRef.current.onmessage = (event: any) => {
          try {
            const burstData = JSON.parse(event.data);

            if (deviceIds.includes(burstData.did)) {
              const did = burstData.did as string;
              const paramValuesArray = burstData.parameters as number[];
              const paramIdxValueTable = paramValuesArray.reduce<
                Record<number, number>
              >((table, value, idx) => {
                table[idx] = value;
                return table;
              }, {});

              setDeviceParamsMap(deviceParamsTable => ({
                ...deviceParamsTable,
                [did]: {
                  ...deviceParamsTable[did],
                  ...paramIdxValueTable
                }
              }));
              setLastReadTime(getUnixTime(new Date()));
            }
          } catch (err) {
            if (process.env.NODE_ENV === 'development') {
              console.log("Error parsing burst data: ", err);
            }
            alert("Error parsing burst data");
          }
        };
      }
    };

    // Initiate the WebSocket connection
    handleWebSocketConnection();

  }, [setDeviceParamsMap, setLastReadTime]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Tab is inactive, close the WebSocket connection
        if (wsClientRef.current) {
          wsClientRef.current.close();
          wsClientRef.current = null;
        }
      } else {
        // Tab is active, reconnect the WebSocket connection
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      // Clean up WebSocket connection when the component unmounts
      if (wsClientRef.current) {
        wsClientRef.current.close();
        wsClientRef.current = null;
      }
    };
  }, []);

  const populateAssetDisplay = async () => {
    if (!expanded) {
      const deviceIds = Object.keys(deviceParamsMap);
      burstHandler(deviceIds);
    }
    setExpanded(expanded => !expanded);
  };

  const renderChartByDisplayType = useCallback(
    (
      parameter: ParameterProps,
      paramValue: number | undefined,
      hasValue: boolean
    ) => {
      if (!hasValue || paramValue === undefined) {
        return null;
      }
      const updatedParameter: ParameterProps = {
        ...parameter,
        parameter_value: paramValue
      };
      switch (parameter.display_type) {
        case "Horizontal bar":
          return <BarChart2 params={updatedParameter} />;
        // Updated the spelling for vertical, Github issue #57
        case "Vertical bar":
          return <BarChart params={updatedParameter} />;
        case "Doughnut":
          return <DonutChart params={updatedParameter} />;
        case "Gauge":
          return <GaugeChart params={updatedParameter} />;
        case "Indicator":
          return <IndicatorChart params={updatedParameter} />;
        case "None":
          return (
            <div
              style={{
                width: 220,
                height: "95%",
                display: "none", //display updated to none as when the display type of parameter is none, it should not show the empty NA box in asset-status page. Github issue #2
                justifyContent: "center",
                alignItems: "center",
                color: Colors.White,
                backgroundColor: Colors.LightGray,
                margin: 5
              }}
            >
              N/A
            </div>
          );
        default:
          break;
      }
    },
    []
  );

  const applyControlReq = async (
    deviceId: string,
    parameter: ParameterProps,
    selectVal: string,
    assetId: string // Added the asset Id as assetId is required for the api call. Github issue #28
  ) => {
    let newNum = Number(selectVal);
    if (parameter.parameter_number === 5) {
      let bin = "1";
      if (selectVal !== "0") {
        for (let i = 0; i < parseInt(selectVal); i++) {
          bin = bin + "0";
        }
      }
      let dec = parseInt(bin, 2);
      newNum = dec;
    }
    try {
      await devicesAPI.controlRequest(
        deviceId,
        parameter.parameter_number,
        newNum,
        assetId // Added the asset Id in the api call. Github issue #28
      );
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(error);
      }
    }
  };

  // handles new Row info to be updated later when get api is ready
  const updateRowInfo = async (
    index: number,
    value: string,
    isInput?: boolean,
    isButton?: boolean,
    disabled?: boolean,
    isOpenClose?: string, //variable to check which button is clicked for issue #103
    parameter?: ParameterProps
  ) => {
    let newDevices = [...newDataArr];

    if (isButton && !disabled) {
      const valArr = mappedJson(parameter?.text_json!);
      // Changed condition for issue #103
      if (newDevices[index] !== isOpenClose) {
        newDevices[index] = isOpenClose!;
      } else {
        newDevices[index] = isOpenClose!;
      }
      setNewDataArr(newDevices);
      return;
    }
    if (isButton && disabled) {
      // Changed condition for issue #103
      if (newDevices[index] !== isOpenClose) {
        newDevices[index] = isOpenClose!;
        setNewDataArr(newDevices);
      } else {
        newDevices[index] = isOpenClose!;
        setNewDataArr(newDevices);
      }
      return;
    }
    if (isInput) {
      newDevices[index] = value;
      setNewDataArr(newDevices);
    } else {
      const valArr = mappedJson(device_parameters[index].text_json);
      const foundName = valArr.filter(val => val.value === value);
      newDevices[index] = foundName[0].name;
      setNewDataArr(newDevices);
    }
  };

  const displayParameterAction = (parameter: ParameterProps, index: number) => {
    const isSetpointAndDecodingEnabled =
      parameter.parameter_type === 2 && parameter.text_decoding !== "0";
    const isSetpointEnabledAndDecodingDisabled =
      parameter.parameter_type === 2 && parameter.text_decoding === "0";
    const isControlAndDecodingEnabled =
      parameter.parameter_type === 3 && parameter.text_decoding !== "0";
    const isDigitalOutput =
      parameter.source === 3 && parameter.text_decoding !== "0";
    const isDigitalOutputDisabled =
      parameter.source === 3 && parameter.text_decoding === "0";
    const assetId = asset.asset_id;
    switch (true) {
      case isSetpointAndDecodingEnabled:
        return (
          <DecodingEnabledSelect
            deviceId={String(parameter.device_id)}
            jsonObject={mappedJson}
            parameter={parameter}
            index={index}
            assetId={assetId} // Added the asset Id as assetId is required for the api call. Github issue #28
            // updateRowInfo={updateRowInfo}
            disableButtonOnClick={disableButtonOnClick} //Added props to call the function on click for issue #132
            buttonDisable={buttonDisable} //Added props to disable button on click for issue #132
          />
        );
      case isSetpointEnabledAndDecodingDisabled:
        return (
          <DecodingDisabledInput
            deviceId={String(parameter.device_id)}
            parameter={parameter}
            index={index}
            assetId={assetId} // Added the asset Id as assetId is required for the api call. Github issue #28
            // updateRowInfo={updateRowInfo}
            disableButtonOnClick={disableButtonOnClick} //Added props to call the function on click for issue #132
            buttonDisable={buttonDisable} //Added props to disable button on click for issue #132
          />
        );
      case isControlAndDecodingEnabled:
        if (Object.keys(JSON.parse(parameter?.text_json)).length === 2) {
          if (!control[parameter.parameter_name]) {
            control[parameter.parameter_name] = false;
          }
          return (
            <Button
              type="button"
              color={
                control[parameter.parameter_name] ? "white" : "defaultTheme"
              }
              // Added style and condition to disable buttons for 3s when clicked for issue #103
              style={{
                backgroundColor: buttonDisable[index] ? Colors.Gray : ""
              }}
              disabled={buttonDisable[index]}
              onClick={(event: any) => {
                // updateRowInfo(index, "val", false, true);
                disableButtonOnClick(index);
                setControl(previousControl => ({
                  ...previousControl,
                  [parameter.parameter_name]: !previousControl[
                    parameter.parameter_name
                  ]
                }));
              }}
            >
              Apply
            </Button>
          );
        }
        return (
          <div className={classes.parameterButtonContainer}>
            <select
              value={selectVal}
              className={classes.parameterSelect}
              onChange={(e: SelectChangeEvent) => setSelectVal(e.target.value)}
            >
              {mappedJson(parameter.text_json).map(
                (item: any, index: number) => (
                  <option key={item.name + index} value={index}>
                    {item.name}
                  </option>
                )
              )}
            </select>
            <Button
              type="button"
              color={"defaultTheme"}
              // Added style and condition to disable buttons for 3s when clicked for issue #103
              style={{
                backgroundColor: buttonDisable[index] ? Colors.Gray : ""
              }}
              disabled={buttonDisable[index]}
              onClick={() => {
                disableButtonOnClick(index);
                applyControlReq(
                  String(parameter.device_id),
                  parameter,
                  selectVal,
                  assetId
                );
              }}
            >
              Apply
            </Button>
          </div>
        );
      case isDigitalOutput:
        if (Object.keys(JSON.parse(parameter?.text_json)).length === 2) {
          if (!digitalOutput[parameter.parameter_name]) {
            digitalOutput[parameter.parameter_name] = false;
          }
          return (
            <div className={classes.parameterButtonContainer}>
              <button
                name={parameter.parameter_name}
                // Added style and condition to disable buttons for 3s when clicked for issue #103
                disabled={buttonDisable[index]}
                style={{
                  backgroundColor: buttonDisable[index] ? Colors.Gray : "",
                  cursor: buttonDisable[index] ? "auto" : "pointer"
                }}
                className={classes.applyButton}
                onClick={(event: any) => {
                  // Added one more function to disable button for 3s for issue #103
                  disableButtonOnClick(index); // Sending one more variable to check which button is clicked for issue #103
                  updateRowInfo(
                    index,
                    "val",
                    false,
                    true,
                    undefined,
                    mappedJson(parameter.text_json)[0].name,
                    parameter
                  );
                  setDigitalOutput(previousDigitalOutput => ({
                    ...previousDigitalOutput,
                    [parameter.parameter_name]: !previousDigitalOutput[
                      parameter.parameter_name
                    ]
                  }));
                  // }
                  // Sending one more variable to check which button is clicked for issue #103
                  applyDigitalOutputRequest(
                    String(parameter.device_id),
                    parameter,
                    mappedJson(parameter.text_json)[0].name,
                    true
                  );
                }}
              >
                {mappedJson(parameter.text_json)[0].name}
              </button>

              <button
                name={parameter.parameter_name}
                // Added style and condition to disable buttons for 3s when clicked for issue #103
                disabled={buttonDisable[index]}
                style={{
                  backgroundColor: buttonDisable[index] ? Colors.Gray : "",
                  cursor: buttonDisable[index] ? "auto" : "pointer"
                }}
                className={classes.cancelButton}
                onClick={(event: any) => {
                  // setButtonClick(true);
                  // Added one more function to disable button for 3s for issue #103
                  disableButtonOnClick(index);
                  // Sending one more variable to check which button is clicked for issue #103
                  updateRowInfo(
                    index,
                    "val",
                    false,
                    true,
                    undefined,
                    mappedJson(parameter.text_json)[1].name,
                    parameter
                  );
                  setDigitalOutput(previousDigitalOutput => ({
                    ...previousDigitalOutput,
                    [parameter.parameter_name]: !previousDigitalOutput[
                      parameter.parameter_name
                    ]
                  }));
                  // Sending one more variable to check which button is clicked for issue #103
                  applyDigitalOutputRequest(
                    String(parameter.device_id),
                    parameter,
                    mappedJson(parameter.text_json)[1].name,
                    true
                  );
                }}
              >
                {mappedJson(parameter.text_json)[1].name}
              </button>
            </div>
          );
        }
        return (
          <div className={classes.parameterButtonContainer}>
            <select
              value={selectVal}
              className={classes.parameterSelect}
              onChange={(e: SelectChangeEvent) => setSelectVal(e.target.value)}
            >
              {mappedJson(parameter.text_json).map(
                (item: any, index: number) => (
                  <option key={item.name + index} value={index}>
                    {item.name}
                  </option>
                )
              )}
            </select>
            <button
              className={classes.applyButton}
              // Added style and condition to disable buttons for 3s when clicked for issue #103
              disabled={buttonDisable[index]}
              style={{
                backgroundColor: buttonDisable[index] ? Colors.Gray : "",
                cursor: buttonDisable[index] ? "auto" : "pointer"
              }}
              onClick={() => {
                // Added one more function to disable button for 3s for issue #103
                disableButtonOnClick(index);
                applyControlReq(
                  String(parameter.device_id),
                  parameter,
                  selectVal,
                  assetId
                );
              }}
            >
              Apply
            </button>
          </div>
        );
      case isDigitalOutputDisabled:
        const stringedJSON = '{"0":"OFF", "1":"ON"}';
        return (
          <div className={classes.parameterButtonContainer}>
            <button
              name={parameter.parameter_name}
              // Added style and condition to disable buttons for 3s when clicked for issue #103
              style={{
                backgroundColor: buttonDisable[index] ? Colors.Gray : ""
              }}
              disabled={buttonDisable[index]}
              className={classes.applyButton}
              onClick={(event: any) => {
                disableButtonOnClick(index);
                // Sending one more variable to check which button is clicked for issue #103
                updateRowInfo(
                  index,
                  "val",
                  false,
                  true,
                  true,
                  mappedJson(stringedJSON)[0].name,
                  parameter
                );
                setDigitalOutput(previousDigitalOutput => ({
                  ...previousDigitalOutput,
                  [parameter.parameter_name]: !previousDigitalOutput[
                    parameter.parameter_name
                  ]
                }));
                // Sending one more variable to check which button is clicked for issue #103
                applyDigitalOutputRequest(
                  String(parameter.device_id),
                  parameter,
                  mappedJson(stringedJSON)[0].name,
                  false
                );
              }}
            >
              {mappedJson(stringedJSON)[0].name}
            </button>
            <button
              name={parameter.parameter_name}
              // Added style and condition to disable buttons for 3s when clicked for issue #103
              disabled={buttonDisable[index]}
              style={{
                backgroundColor: buttonDisable[index] ? Colors.Gray : ""
              }}
              // Added style and condition to disable buttons for 3s when clicked for issue #103
              className={classes.cancelButton}
              onClick={(event: any) => {
                disableButtonOnClick(index);
                // Sending one more variable to check which button is clicked for issue #103
                updateRowInfo(
                  index,
                  "val",
                  false,
                  true,
                  true,
                  mappedJson(stringedJSON)[1].name,
                  parameter
                );
                setDigitalOutput(previousDigitalOutput => ({
                  ...previousDigitalOutput,
                  [parameter.parameter_name]: !previousDigitalOutput[
                    parameter.parameter_name
                  ]
                }));
                // Sending one more variable to check which button is clicked for issue #103
                applyDigitalOutputRequest(
                  String(parameter.device_id),
                  parameter,
                  mappedJson(stringedJSON)[1].name,
                  false
                );
              }}
            >
              {mappedJson(stringedJSON)[1].name}
            </button>
          </div>
        );
      default:
        break;
    }
  };

  const mappedJson = (jsonString: string) =>
    Object.entries(JSON.parse(jsonString)).map((item: any) => {
      return {
        name: item[1],
        value: item[0]
      };
    });

  useEffect(() => {
    (async () => {
      try {
        if (asset) {
          setLoading(true); // repurpose loader for the burst data or omit entirely??
          const assetInformation = singleAsset
            ? { asset } // if single asset, reuse the prop data instead of re-calling openAsset since it's the same exact API/data
            : await dashboardAPI.openAsset(asset.asset_id);
          setAssetInformation(assetInformation.asset);
          handleAssetProperties(assetInformation?.asset?.asset_properties);
          filterAssetParameters(
            assetInformation?.asset?.asset_parameters || [],
            assetInformation?.asset?.devices_in_asset || []
          );
          const assetLastRead = parseFloat(assetInformation.asset.last_read);
          if (!isNaN(assetLastRead)) {
            setLastReadTime(assetLastRead);
          }

          setLoading(false);
        }
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.log(error);
        }
        setLoading(false);
        alert("Error: API failed for open asset");
      }
    })();
  }, [asset, singleAsset]);

  useEffect(() => {
    // unmount cleanup: close websocket
    return () => {
      const wsClient = wsClientRef;
      wsClient.current?.close();
    };
  }, []);

  // console.log("ASSET_INFORMATION", assetInformation);
  return (
    <div style={{ width: "100%", padding: 0 }}>
      <Paper
        elevation={0}
        className={
          expanded ? classes.paperStylesExpanded : classes.paperStylesCollapsed
        }
        onClick={populateAssetDisplay}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={classes.expandIconContainer}>
            {/* Added the page and parameter attribute to be passed for the css change with respect to complex asset. Github issue #56 */}
            {assetInformation && (
              <AssetStatusCategoryIcons
                asset={assetInformation}
                parameter={""}
                singleAsset={false}
                page={"assetDisplay"}
              />
            )}
          </div>
          <span
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            {" "}
            {/* Added the width attribute for the plus sign issue. Github issue #59 */}
            <Typography
              variant="h6"
              className={
                expanded ? classes.headerExpanded : classes.headerCollapsed
              }
            >
              {asset?.asset_name}
            </Typography>
            <p className={classes.dateTextStyles}>
              Last read: {handleTimeStamp(lastReadTime)}
            </p>
          </span>
          {/* added div and css class for plus sign icon in mobile view responsive UI issue #24  */}
          <div className={classes.plusSignIconMobile}>
            {expanded ? (
              <RemoveCircleOutlineOutlinedIcon
                className={classes.expandIconOpen}
              />
            ) : (
              <ControlPointIcon className={classes.expandIconCollapsed} />
            )}
          </div>
        </div>
        {/* removed styles tab and added css class for styling in responsive UI issue #24 */}
        <span className={classes.chipSpan}>
          {sensorParameters &&
            sensorParameters
              .slice(0, 3)
              .map((param: ParameterProps, index: number) => {
                // Updated the type of ParamValue from const to var as to show the values in proper format. Github issue #2, issue #3
                var paramValue =
                  deviceParamsMap[param.device_id]?.[
                  param.parameter_number - 1
                  ];
                const hasValue =
                  paramValue !== undefined && paramValue !== null;

                //Called the new function for the decoding of value and converting it to proper format. Github issue #2, issue #3
                paramValue = getDecodedVal(
                  param,
                  Number(paramValue),
                  numberToTextKey,
                  binaryToTextKey,
                  binaryFromJsonList
                );

                return (
                  <Chip
                    key={param.device_parameter_id + index}
                    className={classes.chipStyles}
                    size="small"
                    label={hasValue ? `${paramValue} ${param.unit}` : "N/A"}
                  />
                );
              })}
        </span>
        {/* added span and css class for plus sign icon in web view responsive UI issue #24  */}
        <span className={classes.plusSignIconWeb}>
          {expanded ? (
            <RemoveCircleOutlineOutlinedIcon
              className={classes.expandIconOpen}
            />
          ) : (
            <ControlPointIcon className={classes.expandIconCollapsed} />
          )}
        </span>
      </Paper>
      {expanded && loading && <LinearIndeterminate />}
      {expanded && !loading && (
        <div className={classes.tankInfo}>
          <div className={classes.tankSpecs}>
            {assetProperties.map(
              (property: { name: string; value: string }, index: number) => {
                return (
                  <>
                    {/* Added div and css class tankSpecsContainer for aligning the parameters in responsive view for issue #82 */}
                    <div className={classes.tankSpecsContainer}>
                      <div className={classes.tankInfoColumn}>
                        <p className={classes.tankInfoText}>{property.name}</p>
                      </div>
                      <div className={classes.tankInfoColumnBold}>
                        <p className={classes.tankInfoText}>{property.value}</p>
                      </div>
                    </div>
                  </>
                );
              }
            )}
          </div>
          <div className={classes.paramBoxesContainer}>
            {/* condition to display chemical injector box for issue #174 */}
            {assetInformation?.asset_category_type == "chemical injectors" ? (
              assetInformation?.chemical_injector_parameter.map(
                (param: ParameterProps, index: number) => {
                  var originalValue =
                    deviceParamsMap[param.device_id]?.[
                    param.parameter_number - 1
                    ];

                  const hasValue =
                    originalValue !== undefined && originalValue !== null;

                  var paramValue = getDecodedVal(
                    param,
                    Number(originalValue),
                    numberToTextKey,
                    binaryToTextKey,
                    binaryFromJsonList
                  );
                  return (
                    <>
                      {/* 1st box to show the graph and text for issue #174 */}
                      <div
                        className={classes.verticleParamBox}
                        style={{ display: "flex", flexDirection: "row" }}
                        key={param.device_parameter_id + index}
                      >
                        {param.display_type.toLowerCase() == "gauge" ? (
                          <span className={classes.gaugeContainer}>
                            {renderChartByDisplayType(
                              param,
                              originalValue,
                              hasValue
                            )}
                          </span>
                        ) : param.display_type.toLowerCase() == "doughnut" ? (
                          <span className={classes.donutContainer}>
                            {renderChartByDisplayType(
                              param,
                              originalValue,
                              hasValue
                            )}
                          </span>
                        ) : (
                          renderChartByDisplayType(
                            param,
                            originalValue,
                            hasValue
                          )
                        )}
                        <div className={classes.verticleGraphInformation1}>
                          <p
                            className={
                              param.display_type.toLowerCase() != "none" ||
                                param.display_type.toLowerCase() !=
                                "horizontal bar"
                                ? classes.chemicalInjectorParamInfoOthers
                                : classes.chemicalInjectorParamInfo
                            }
                            style={{
                              display: "flex",
                              alignItems: "flex-start"
                            }}
                          >
                            Tank Fluid Volume
                          </p>
                          <p
                            className={classes.chemicalInjectorParamInfo}
                            style={{ fontWeight: 600 }}
                          >
                            {
                              assetInformation
                                ?.chemical_injector_asset_properties[0]
                                ?.tank_vol
                            }{" "}
                            gallons
                          </p>
                          {/* Added condition to show the tank level only if tank level unit is inches for issue #174 */}
                          {assetInformation
                            ?.chemical_injector_asset_properties[0]
                            ?.tank_level_unit == 0 && (
                              <>
                                <p
                                  className={
                                    param.display_type.toLowerCase() != "none" ||
                                      param.display_type.toLowerCase() !=
                                      "horizontal bar"
                                      ? classes.chemicalInjectorParamInfoOthers
                                      : classes.chemicalInjectorParamInfo
                                  }
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start"
                                  }}
                                >
                                  Tank Level
                                </p>
                                <p
                                  className={classes.chemicalInjectorParamInfo}
                                  style={{ fontWeight: 600 }}
                                >
                                  {
                                    assetInformation
                                      ?.chemical_injector_asset_properties[0]
                                      ?.tank_level
                                  }{" "}
                                  inches
                                </p>
                              </>
                            )}
                        </div>
                      </div>
                      {/* 2nd box to show the text related to number of days for warning and critical for issue #174 */}
                      <div
                        className={classes.verticleParamBox}
                        style={{ display: "flex", flexDirection: "row" }}
                        key={param.device_parameter_id + index}
                      >
                        <div className={classes.verticleGraphInformation2}>
                          <p
                            className={
                              param.display_type.toLowerCase() != "none" ||
                                param.display_type.toLowerCase() !=
                                "horizontal bar"
                                ? classes.chemicalInjectorParamInfoOthers
                                : classes.chemicalInjectorParamInfo
                            }
                            style={{
                              display: "flex",
                              alignItems: "flex-start"
                            }}
                          >
                            Current Status:
                            <span
                              className={classes.chemicalInjectorParamInfo}
                              style={{ fontWeight: 600 }}
                            >
                              {
                                assetInformation
                                  ?.chemical_injector_asset_properties[0]
                                  ?.current_status
                              }
                            </span>
                          </p>
                          <p
                            className={
                              param.display_type.toLowerCase() != "none" ||
                                param.display_type.toLowerCase() !=
                                "horizontal bar"
                                ? classes.chemicalInjectorParamInfoOthers
                                : classes.chemicalInjectorParamInfo
                            }
                            style={{
                              display: "flex",
                              alignItems: "flex-start"
                            }}
                          >
                            Days to warning:
                            <span
                              className={classes.chemicalInjectorParamInfo}
                              style={{ fontWeight: 600 }}
                            >
                              {
                                assetInformation
                                  ?.chemical_injector_asset_properties[0]
                                  ?.days_to_warning
                              }{" "}
                              days
                            </span>
                          </p>
                          <p
                            className={
                              param.display_type.toLowerCase() != "none" ||
                                param.display_type.toLowerCase() !=
                                "horizontal bar"
                                ? classes.chemicalInjectorParamInfoOthers
                                : classes.chemicalInjectorParamInfo
                            }
                            style={{
                              display: "flex",
                              alignItems: "flex-start"
                            }}
                          >
                            Days to critical:
                            <span
                              className={classes.chemicalInjectorParamInfo}
                              style={{ fontWeight: 600 }}
                            >
                              {
                                assetInformation
                                  ?.chemical_injector_asset_properties[0]
                                  ?.days_to_critical
                              }{" "}
                              days
                            </span>
                          </p>
                          <p
                            className={
                              param.display_type.toLowerCase() != "none" ||
                                param.display_type.toLowerCase() !=
                                "horizontal bar"
                                ? classes.chemicalInjectorParamInfoOthers
                                : classes.chemicalInjectorParamInfo
                            }
                            style={{
                              display: "flex",
                              alignItems: "flex-start"
                            }}
                          >
                            Days to empty:
                            <span
                              className={classes.chemicalInjectorParamInfo}
                              style={{ fontWeight: 600 }}
                            >
                              {
                                assetInformation
                                  ?.chemical_injector_asset_properties[0]
                                  ?.days_to_empty
                              }{" "}
                              days
                            </span>
                          </p>
                        </div>
                      </div>
                    </>
                  );
                }
              )
            ) : (
              <></>
            )}
          </div>
          <div className={classes.paramBoxesContainer}>
            {sensorParameters &&
              sensorParameters.map((param: ParameterProps, index: number) => {
                var originalValue =
                  deviceParamsMap[param.device_id]?.[
                  param.parameter_number - 1
                  ];

                const hasValue =
                  originalValue !== undefined && originalValue !== null;

                var paramValue = getDecodedVal(
                  param,
                  Number(originalValue),
                  numberToTextKey,
                  binaryToTextKey,
                  binaryFromJsonList
                );
                return (
                  <div
                    className={classes.verticleParamBox}
                    key={param.device_parameter_id + index}
                  >
                    <div className={classes.verticleGraphInformation}>
                      {/* Added a ternary operator for css classes for horizontal bar and other charts for aligning the text on top of the charts. Github issue #82 */}
                      <p
                        className={
                          param.display_type.toLowerCase() != "none" ||
                            param.display_type.toLowerCase() != "horizontal bar"
                            ? classes.paramInfoTextOthers
                            : classes.paramInfoText
                        }
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        {param.parameter_name}
                      </p>
                      <p
                        className={classes.paramInfoText}
                        style={{ fontWeight: 600 }}
                      >
                        {hasValue ? paramValue : "N/A"} {param.unit}
                      </p>
                    </div>
                    {/* Added condition for giving a css class to gauge for aligning it in the center. Github issue #82 */}
                    {param.display_type.toLowerCase() == "gauge" ? (
                      <span className={classes.gaugeContainer}>
                        {renderChartByDisplayType(
                          param,
                          originalValue, //Changed the paramValue to originalValue as in the graph, the value required is without the conversion. Github issue #2
                          hasValue
                        )}
                      </span>
                    ) : param.display_type.toLowerCase() == "doughnut" ? (
                      <span className={classes.donutContainer}>
                        {renderChartByDisplayType(
                          param,
                          originalValue, //Changed the paramValue to originalValue as in the graph, the value required is without the conversion. Github issue #2
                          hasValue
                        )}
                      </span>
                    ) : (
                      renderChartByDisplayType(
                        param,
                        originalValue, //Changed the paramValue to originalValue as in the graph, the value required is without the conversion. Github issue #2
                        hasValue
                      )
                    )}
                  </div>
                );
              })}
          </div>
          <Divider />
          {setpointControlParameters && setpointControlParameters.length > 0 ? (
            <TableContainer className={classes.controlsContainer}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.header}>
                      Control parameter
                    </TableCell>
                    <TableCell className={classes.header}>
                      Current value
                    </TableCell>
                    <TableCell className={classes.header}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {setpointControlParameters.map(
                    (assetParameter: ParameterProps, index: number) => {
                      var originalValue =
                        deviceParamsMap[assetParameter.device_id]?.[
                        assetParameter.parameter_number - 1
                        ];
                      const hasValue =
                        originalValue !== undefined && originalValue !== null;

                      return (
                        <TableRow key={index} className={classes.tableRow}>
                          <TableCell className={classes.tableCell}>
                            <div className={classes.flex}>
                              <p>{assetParameter.parameter_name}</p>
                            </div>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <div className={classes.flex}>
                              {/* Added the getDecodedVal function for showing the converted value for the number to text and bitwise to text conversions. Github issue #44 */}
                              <p>
                                {hasValue
                                  ? getDecodedVal(
                                    assetParameter,
                                    Number(originalValue),
                                    numberToTextKey,
                                    binaryToTextKey,
                                    binaryFromJsonList
                                  )
                                  : "N/A"} {assetParameter.unit}
                              </p>
                            </div>
                          </TableCell>
                          {/* Added the condition on the table cell to check if the user is field_view_only. If the user is field_view_only then to make the control parameters as not editable. Github issue #16 */}
                          <TableCell
                            className={
                              selectedAccountUserRole == fieldView
                                ? classes.disable
                                : classes.tableCell
                            }
                          >
                            <div className={classes.flex}>
                              <p>
                                {displayParameterAction(assetParameter, index)}
                              </p>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default AssetDisplayPanel;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    headerCollapsed: {
      color: theme.palette.primary.light,
      padding: 0,
      margin: 0
    },
    headerExpanded: {
      color: "FFF",
      padding: 0,
      margin: 0
    },
    paperStylesCollapsed: {
      marginTop: "1em",
      width: "100%",
      border: "solid 1px lightgrey",
      minHeight: "5em",
      borderLeft: `solid 5px ${theme.palette.primary.light}`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "1em",
      paddingLeft: "1em",
      cursor: "pointer",

      // added media query for displaying the text one below other in responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        display: "inline-block"
      }
    },
    disable: { pointerEvents: "none" },
    expandIconContainer: {
      alignSelf: "center",
      marginRight: ".5em",
      // added media query for centering the asset icon other in responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginTop: "45px"
      }
    },
    paperStylesExpanded: {
      marginTop: "1em",
      width: "100%",
      border: "solid 1px lightgrey",
      minHeight: "5em",
      borderLeft: `solid 5px ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.primary.light,
      color: "white",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "1em",
      paddingLeft: "1em",
      cursor: "pointer",

      // added media query for displaying the text one below other in responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        display: "inline-block"
      }
    },
    expandIconCollapsed: {
      color: theme.palette.primary.light,
      "&:hover": { cursor: "pointer" }
    },
    dateTextStyles: {
      padding: 0,
      margin: 0,
      fontSize: ".8em",
      //added media query for styling date on asset status bar for responsive UI issue #24
      [`@media (max-width: 700px)`]: {
        width: "150%"
      },
      // Added the media query for the ipad version for responsive UI issue #24
      [`@media (min-width:701px) and (max-width: 800px)`]: {
        width: "300%"
      }
    },
    expandIconOpen: {
      color: "white",
      padding: 0,
      borderRadius: "50%",
      "&:hover": { cursor: "pointer" }
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    tankIcon: {
      height: "4em"
    },
    chipStyles: {
      marginLeft: ".5em",
      //added media query for styling parameter values on asset status bar for responsive UI issue #24
      [`@media (max-width: 700px)`]: {
        marginTop: ".5em"
      }
    },
    tankSpecs: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: "10px",

      //adding media query for responsive UI issue #24 for displaying the tank specs with some space
      [`@media (max-width: 800px)`]: {
        marginTop: "15px",
        flexDirection: "column" //added flexDirection for aligning parameters in issue #82
      }
    },
    tankInfo: { display: "flex", flexDirection: "column" },
    tankInfoColumn: {
      marginTop: 1,
      fontSize: ".9em",
      width: "23%",
      display: "flex",
      flexDirection: "column",
      //added media query with width for aligning parameters for issue #82
      [`@media (max-width: 800px)`]: {
        width: "40%"
      }
    },
    tankInfoColumnBold: {
      paddingLeft: "2rem", //Added for issue #163
      marginTop: 1,
      fontWeight: "bold",
      fontSize: ".9em",
      width: "23%",
      display: "flex",
      flexDirection: "column",
      //added media query for keeping text in same line for issue #82
      [`@media (max-width: 800px)`]: {
        marginLeft: "20px"
      }
    },
    tankInfoText: {
      marginTop: 3,
      padding: 0
    },
    //Added a css class for aligning the test on top on the charts. Github issue #82
    paramInfoTextOthers: {
      margin: "0 0 0 1em",
      padding: 0,
      marginTop: "10px",
      fontSize: "1.2rem" //Added font size to increase font size for issue #210
    },
    paramInfoText: { margin: "0 0 0 1em", padding: 0, fontSize: "1.8rem" }, //Added font size to increase font size for issue #210
    // Added css class for chemical injector info to be normal size for issue #210
    chemicalInjectorParamInfoOthers: {
      margin: "0 0 0 1em",
      padding: 0,
      marginTop: "10px"
    },
    // Added css class for chemical injector info to be normal size for issue #210
    chemicalInjectorParamInfo: { margin: "0 0 0 1em", padding: 0 },
    controlsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      height: "350",
      color: theme.palette.primary.light,
    },
    controlHeader: {
      width: "33%",
      alignSelf: "flex-start",
      margin: 4,
      padding: 0
    },
    controlsRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    buttonRoot: {
      minWidth: "50%",
      paddingLeft: ".5em",
      paddingRight: ".5em",
      height: "1.5em",
      fontSize: ".9em",
      color: theme.palette.primary.light,
      border: `solid 1px ${theme.palette.primary.light}`
    },
    paramBoxesContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginBottom: "1em",
      justifyContent: "space-between"
    },
    paramBox: {
      display: "flex",
      // justifyContent: "space-evenly",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      minHeight: "8em",
      marginTop: "1em",
      border: `1px solid ${Colors.LightGray}`,
      [theme.breakpoints.up(960)]: {
        width: "45%"
      },
      [theme.breakpoints.up(1920)]: {
        width: "45%"
      },
      [theme.breakpoints.up(2560)]: {
        width: "30%"
      }
    },
    verticleParamBox: {
      display: "flex",
      justifyContent: "space-evenly",
      flexDirection: "column",
      background: 'whitesmoke',
      borderRadius: "5px",
      alignItems: "center",
      width: "100%",
      minHeight: 100,
      marginTop: "1em",
      border: `1px solid ${Colors.LightGray}`,
      [theme.breakpoints.up(960)]: {
        width: "45%"
      },
      [theme.breakpoints.up(1920)]: {
        width: "45%"
      },
      [theme.breakpoints.up(2560)]: {
        width: "30%"
      }
    },
    verticleGraphInformation: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      alignItems: "flex-start", //Aligned the items horizontally at the start. Github issue #82
      justifyContent: "center" //Aligned the items vertically at the center. Github issue #82
    },
    // css for first box for issue #174
    verticleGraphInformation1: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center"
    },
    // css for second box for issue #174
    verticleGraphInformation2: {
      width: "100%"
    },
    //Added a gauge css class for aligning it to the center. Github issue #82
    gaugeContainer: {
      minWidth: "100%" //added minWidth for gauge showing up for issue #140
    },
    //Added a donut css class for aligning it to the center. Github issue #82
    donutContainer: {
      minWidth: "100%" //added minWidth for donut showing up for issue #140
    },
    //added a new class with media query for styling parameters in responsive view for issue #82
    tankSpecsContainer: {
      [`@media (max-width: 800px)`]: {
        display: "flex",
        marginLeft: "5px"
      },
      [`@media (min-width: 801px)`]: {
        display: "contents"
      }
    },
    tankContainer: {
      display: "flex",
      flexDirection: "row",
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      [theme.breakpoints.up("xs")]: {
        width: "25%",
        height: "90%"
      },
      [theme.breakpoints.up("lg")]: {
        width: "30%",
        height: "90%"
      }
    },
    dotContainerVertical: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignSelf: "center",
      height: "90%"
    },
    dotContainerHorizontal: {
      display: "flex",
      flexDirection: "row",
      alignSelf: "center",
      justifyContent: "space-around",
      width: "100%",
      marginBottom: ".5em"
    },
    verticalTankRectangle: {
      height: "90%",
      width: "25%",
      display: "flex",
      backgroundColor: "lightgrey",
      alignSelf: "center",
      marginLeft: ".5em"
    },
    horizontalTankRectangle: {
      marginTop: "auto",
      height: "25%",
      width: "90%",
      display: "flex",
      backgroundColor: "lightgrey",
      alignSelf: "center",
      marginBottom: ".5em"
    },
    graphInformation: {
      display: "flex",
      flexDirection: "column",
      // height: "90%",
      // width: "90%",
      alignSelf: "center"
    },
    table: {
      minWidth: "100%",
      marginTop: "1rem"
    },
    header: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      // padding: 0,
      borderTop: "2px solid #DDD",
      borderBottom: "2px solid #DDD"
    },
    tableRow: {
      textDecoration: "none",
      "&:focus &:hover &:link &:visited &:active": {
        textDecoration: "none"
      }
    },
    tableCell: {
      padding: 0,
      margin: 0,
      borderBottom: "2px solid #DDD",
      fontSize: 14
    },
    serialNo: {
      display: "flex",
      alignItems: "center",
      padding: 0
    },
    flex: {
      display: "flex",
      alignItems: "center",
      padding: "0 0 0 1rem"
    },
    parameterButtonContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "1rem"
    },
    parameterSelect: {
      cursor: "pointer",
      width: 75.5
    },

    //added chipspan css class for chip sign icon styling in responsive UI issue #24
    chipSpan: {
      marginLeft: "auto",
      marginRight: ".5em",
      [`@media (max-width: 700px)`]: {
        marginBottom: "10px",
        display: "inline-block",
        marginLeft: "45px"
      },
      [`@media (min-width:701px) and (max-width: 800px)`]: {
        marginBottom: "10px",
        display: "flex",
        marginLeft: "45px",
        width: "auto"
      }
    },
    //added css class with media query for plus sign icon display in web view responsive UI issue #24
    plusSignIconWeb: {
      [`@media (max-width: 800px)`]: {
        display: "none"
      }
    },
    //added css class with media query for plus sign icon display in mobile view responsive UI issue #24
    plusSignIconMobile: {
      display: "none",
      [`@media (max-width: 700px)`]: {
        display: "flex",
        paddingTop: "65px" //Added padding for centering the icon. Github issue #59
      },
      // Added the media query for the ipad version for responsive UI  issue #24
      [`@media (min-width:701px) and (max-width: 800px)`]: {
        display: "flex",
        paddingTop: "63px" //Added padding for centering the icon. Github issue #59
      }
    },
    buttonStyles: {
      // color: Colors.White,
      // height: 30,
      // border: "none",
      // padding: ".5rem 1rem",
      border: "none",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.primary.light,
      height: 30,
      color: Colors.White,
      padding: ".5rem 1rem",
      cursor: "pointer",
      outline: "none",
      "&:hover": {
        opacity: ".8"
      }
    },
    /* Added this css class for the disabled of input and select Box. Github issue #16 */
    parameterSelectDisabled: {
      cursor: "pointer",
      width: 75.5,
      backgroundColor: "grey"
    },
    applyButton: {
      padding: "0 1rem",
      color: Colors.White,
      backgroundColor: theme.palette.primary.light,
      cursor: "pointer",
      border: "none",
      height: 30,
      width: 75.5
    },
    cancelButton: {
      padding: "0 1rem",
      color: theme.palette.primary.light,
      backgroundColor: Colors.White,
      cursor: "pointer",
      height: 30,
      width: 75.5,
      border: `1px solid ${theme.palette.primary.light}`
    },
    defaultTabButton: {
      padding: "0 1rem",
      color: Colors.White,
      backgroundColor: theme.palette.primary.light,
      cursor: "pointer",
      border: "none",
      height: 30
    }
  })
);
