/* Issue fixed for github issue #35 */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useDispatch } from "react-redux";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
  setUnsuccessModalOpen,
  setUnsuccessModalDescription
} from "components/success-modal/successModalSlice";
import { useStyles } from "components/asset-modals/assetStyles";
import AccountAPI from "services/accountsAPI";
import Button from "components/common-buttons/Button";

type Props = {
  open: boolean;
  handleClose: () => void;
  userId: string;
  tenantId: string; //tenantId added as it needs to be send to the api. Github issue #35
  username: string;
  setCallUser: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DeleteUserModal: React.FC<Props> = ({
  open,
  handleClose,
  userId,
  tenantId, //tenantId added as it needs to be send to the api. Github issue #35
  username,
  setCallUser
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const confirmDeleteUserHandler = async () => {
    try {
      //Updated the api call by sending the tenantId and showing the response from the api in the modal. Github issue #35
      let deleteResponse = await AccountAPI.deleteUser(userId, tenantId);
      const response = await deleteResponse.data;
      if (response == "User Deleted Succesfully") {
        dispatch(setSuccessModalOpen(true));
        dispatch(setSuccessModalDescription(response));
      }
      if (
        response ==
        "Delete user from non-primary accounts and try deleting primary account again"
      ) {
        dispatch(setUnsuccessModalOpen(true));
        dispatch(setUnsuccessModalDescription(response));
      }
      setCallUser(true);
    } catch (error) {
      alert("Error deleting user");
    } finally {
      handleClose();
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={classes.modalOverlay}>
        <div className={classes.modalBody} style={{ minHeight: 0 }}>
          <div className={classes.header}>
            <p className={classes.headerText}>Delete User</p>
            <CloseIcon className={classes.icon} onClick={handleClose} />
          </div>
          <p style={{ margin: "0 0 1rem 2rem" }}>
            Are you sure you want to delete {username}?
          </p>
          <p style={{ margin: "0 2rem" }}>
            If yes, this user and all its previous history will be removed from
            the system.
          </p>
          <div className={classes.actionItems} style={{ border: "none" }}>
            <Button color="white" type="button" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="defaultTheme"
              type="button"
              onClick={confirmDeleteUserHandler}
            >
              Delete User
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteUserModal;
