/*Issue fixed for issue #109 */
import { axiosCall, URL, Method } from "./config";
import { DeviceJobExecution } from "./types";

export const otaAPI = {
  //  ! admin specific for OTA
  //#region
  retrieveSuperAdminFirmwareList: async () => {
    const response = await axiosCall(Method.GET, `${URL()}/ota/firmwares`);
    return response.data.Items;
  },
  retrieveSuperAdminNext20FirmwareList: async (
    firmware_id: string,
    firmware_name: string
  ) => {
    const response = await axiosCall(Method.POST, `${URL()}/ota/firmwares`, {
      firmware_id,
      firmware_name
    });
    return response;
  },
  //#endregion

  // ! general OTA API
  //#region
  uploadFirmware: async (
    firmware_id: string,
    version: string,
    application_name: string,
    description: string,
    part_number: string,
    file_name: string
  ) => {
    const ts = Date.now();
    const response = await axiosCall(
      Method.POST,
      `${URL()}/ota/upload_firmware`,
      {
        firmware_id,
        version,
        application_name,
        description,
        part_number,
        file_name,
        ts
      }
    );
    return response;
  },
  updateFirmware: async (
    device_id: string | number | null,
    firmware_id: string
  ) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/ota/update_firmware`,
      {
        device_id,
        firmware_id
      }
    );
    return response;
  },
  addFirmwareToTenant: async (firmware_id: string, tenant_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/ota/firmwares/tenant/add`,
      {
        firmware_id,
        tenant_id
      }
    );
    return response;
  },
  retrieveFirmwareInTenant: async (tenant_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/ota/firmwares/tenant`,
      {
        tenant_id
      }
    );
    return response.data.Items;
  },
  // DEPRECATED by getOtaDataByDeviceId + new API uses entirely different iot job api
  // checkOtaStatus: async (job_id: string) => {
  //   const response = await axiosCall(Method.POST, `${URL()}/ota/status`, {
  //     job_id,
  //   });
  //   console.log("checkOtaStatus", response);
  //   // iot-describe-job status
  //   return response.data as
  //     | null
  //     | "IN_PROGRESS"
  //     | "CANCELED"
  //     | "DELETION_IN_PROGRESS"
  //     | "COMPLETED";
  // },
  getOtaDataByDeviceId: async (device_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/ota/device_id_status`,
      {
        device_id
      }
    );
    const data = (response.data as unknown) as DeviceJobExecution;
    try {
      if (data === null) throw Error;
      if (data.executionSummaries.length === 0) throw Error;
      const {
        jobId,
        jobExecutionSummary: { status, lastUpdatedAt } // "lastUpdatedAt": "2021-05-26 18:58:23.182000+00:00",
      } = data.executionSummaries[0];
      return { jobId, status, lastUpdatedAt };
    } catch {
      throw new Error("No OTA data found for device " + device_id);
    }
  },
  cancelOtaJob: async ({
    jobId,
    deviceId
  }: {
    jobId: string;
    deviceId: string;
  }) => {
    const response = await axiosCall(Method.POST, `${URL()}/ota/cancel_job`, {
      device_id: deviceId,
      job_id: jobId
    });
    return response;
  },
  deleteFirmware: async (firmware_id: string) => {
    const response = await axiosCall(Method.DELETE, `${URL()}/ota`, {
      firmware_id
    });
    return response;
  },
  retrieveListOfTenantsAttachedToFirmware: async (firmware_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/ota/all_tenants_in_firmware`,
      {
        firmware_id
      }
    );
    return response.data.Item;
  },
  deleteTenantFromFirmware: async (firmware_id: string, tenant_id: string) => {
    const response = await axiosCall(
      Method.PUT,
      `${URL()}/ota/delete_tenant_from_firmware`,
      {
        firmware_id,
        tenant_id
      }
    );
    return response;
  },
  retrieveAvailableTenantsToAddToFirmware: async (firmware_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/ota/retrieve_available_tenants_to_add_to_firmware`,
      {
        firmware_id
      }
    );
    return response.data.Items;
  },
  //Added new API for dropdown list items for issue #109
  getTenantDropdownList: async (tenant_id: string, device_id: any) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/ota/firmwares/device`,
      {
        tenant_id,
        device_id
      }
    );
    return response.data.Items;
  }
  //#endregion
};
