/*Issue fixed for github issue #273,issue #261 */
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import RealTimeStatusDashboard from "features/real-time-status-page/RealTimeStatus";
import ExceptionStatusDashboard from "features/exceptions-page/ExceptionStatus";  //imported for issue #261
import Accounts from "features/accounts-page/Accounts";
import Devices from "features/devices-page/Devices";
import Assets from "features/assets-page/Assets";
import AssetDrilldown from "features/asset-drilldown/AssetDrilldown";
import AdminDevicesContainer from "features/devices-page/AdminDevicesContainer";
import Firmware from "features/firmware-page/Firmware";
import ReportMaker from "features/report-maker/ReportMaker";
import Batch from "features/batch-page/Batch";
import BatchView from "features/batch-page/BatchView";
import ReportAsset from "features/reports-page/ReportsAssets";  //imported for issue #273
import PageNotFound from "common/PageNotFound";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import NotificationStatusDashboard from "features/notifications/NotificationStatus";  //imported for issue #261


export type RoutesProps = {};

export const Routes: React.FC<RoutesProps> = () => {
  const { batchId } = useSelector((state: RootState) => state.DrawerSlice);

  return (
    <Switch>
      <Route exact path="/" component={() => <Redirect to="/asset-status" />} />
      <Route exact path="/asset-status" component={RealTimeStatusDashboard} />
      <Route exact path="/exceptions" component={ExceptionStatusDashboard} />
      <Route exact path="/notifications" component={NotificationStatusDashboard} />
      <Route exact path="/accounts" component={Accounts} />
      {/* //! batch routes */}
      <Route exact path="/batch" component={Batch} />
      <Route exact path={`/batch/${batchId}`} component={BatchView} />

      {/* //! devices routes */}
      <Route exact path="/admin-devices" component={Devices} />
      <Route
        path="/admin-devices/:deviceId"
        component={AdminDevicesContainer}
      />
      <Route exact path={`/firmware`} component={Firmware} />
      {/* //! assets routes */}
      <Route exact path="/assets" component={Assets} />
      <Route exact path="/admin-reports" component={ReportAsset} /> {/* Added route for admin-reports */}
      <Route exact path="/asset-drilldown" component={AssetDrilldown} />
      <Route exact path="/report-maker" component={ReportMaker} />
      <Route component={PageNotFound} />
      {/* <Route component={RealTimeStatusDashboard} /> */}
    </Switch>
  );
};

export default Routes;
