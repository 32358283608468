/* Issue fixed for github issue #204,issue #273*/
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Colors } from "common/Colors";

const initialState: {
  deviceId: number | string | null;
  batchId: number | string | null;
  deviceSettingsActive: boolean;
  savedConfigurationActive: boolean;
  activeTenant: string;
  colors: Array<string>;
  activeLogo: string | null;
  accountName: string;
  theme: Object;
  newTheme: boolean;
  recallReportList: boolean;  //Added var for recalling report list after CRUD operations on reports for issue #273
} = {
  deviceId: null,
  deviceSettingsActive: false,
  savedConfigurationActive: false,
  activeTenant: "",
  activeLogo: null,
  accountName: "basinTrak",
  batchId: null,
  colors: ["0E1D3F", "6D1919", "EF4B25"],
  theme: {
    palette: {
      type: "light",
      primary: {
        light: Colors.MainColor, //updated color from #6D1919 to #253746 for issue #204
        main: Colors.MainColor //updated color from #6D1919 to #253746 for issue #204
      }
    }
  },
  newTheme: false,
  recallReportList: false // for issue #273
};

const drawerSlice = createSlice({
  name: "DrawerSlice",
  initialState,
  reducers: {
    setCurrentDeviceId: (
      state,
      action: PayloadAction<string | number | null>
    ) => {
      state.deviceId = action.payload;
    },
    setDeviceSettingsActive: (state, action: PayloadAction<boolean>) => {
      state.deviceSettingsActive = action.payload;
    },
    setSavedConfigurationedActive: (state, action: PayloadAction<boolean>) => {
      state.savedConfigurationActive = action.payload;
    },
    setTheme: (state, action: PayloadAction<string>) => {
      const obj = {
        palette: {
          type: "light",
          primary: {
            light: action.payload,
            main: action.payload
          }
        }
      };
      state.theme = obj;
    },
    setCurrentBatchId: (state, action: PayloadAction<string | null>) => {
      state.batchId = action.payload;
    },
    addColor: (state, action: PayloadAction<string>) => {
      state.colors = [...state.colors, action.payload];
    },
    addColors: (state, action: PayloadAction<Array<string>>) => {
      state.colors = [...state.colors, ...action.payload];
    },
    setActiveTenant: (state, action: PayloadAction<string>) => {
      state.activeTenant = action.payload;
    },
    setLogoUrl: (state, action: PayloadAction<string | null>) => {
      state.activeLogo = action.payload;
    },
    setAccountDisplayName: (state, action: PayloadAction<string>) => {
      state.accountName = action.payload;
    },
    setNewTheme: (state, action: PayloadAction<boolean>) => {
      state.newTheme = action.payload;
    },
    //Added var for recalling report list after CRUD operations on reports for issue #273
    setRecallReportList: (state, action: PayloadAction<boolean>) => {
      state.recallReportList = action.payload;
    }
  }
});

export const {
  setDeviceSettingsActive,
  setCurrentDeviceId,
  setSavedConfigurationedActive,
  setCurrentBatchId,
  setTheme,
  addColor,
  addColors,
  setActiveTenant,
  setLogoUrl,
  setNewTheme,
  setAccountDisplayName,
  setRecallReportList //Added for issue #273
} = drawerSlice.actions;
export default drawerSlice.reducer;
