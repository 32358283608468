/* Issue fixed for github issue #95,issue #89,issue #150,issue #187,issue #205,issue #221,issue #301 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DeviceParametersProps,
  SuspectParameterProps,
  SelectParameterProps
} from "../../types";
// Added suspect_parameter_name with same structure as received from API call for issue #95
const suspect_parameter_name = [
  {
    parameter_name: "",
    spn: 0,
    var_2: 0,
    var_3: 0,
    var_4: 0,
    var_5: 0,
    var_6: 0,
    var_7: 0,
    var_8: 0,
    var_9: 0,
    var_10: 0,
    var_11: "",
    var_12: ""
  }
];
// Added select_parameter with same structure as received from API call for issue #95
const select_parameter = [
  {
    high_value: 0,
    sensor_min: 0,
    analog_digital_range: 1,
    high_high_to_max_color: "red",
    low_value: 0,
    modbus_adress: 0,
    source: 0,
    display_in_view: false,
    parameter_number: 0,
    display_type: "None",
    low_low_to_low_color: "red",
    sensor_max: 1,
    text_json: "",
    low_to_high_color: "red",
    parameter_type: 1,
    device_parameter_id: "",
    min_to_low_low_color: "red",
    offset: 0, //changed default value from 1 to 0 for issue #150
    source_input: 0,
    text_decoding: "0",
    parameter_name: "",
    scalling: 1,
    modbus_adressing: 0,
    read_function_code: 3,
    min_value: 0,
    unit: "",
    high_to_high_high_color: "red",
    low_low_value: 0,
    decimals: 0,
    write_function_code: 6,
    high_high_value: 0,
    log_rate: 5,
    display_in_summary: false,
    max_value: 0
  }
];

const defaultParameters = {
  sensor_min: 0,
  high_value: 0,
  analog_digital_range: 1,
  low_value: 0,
  source: 0,
  parameter_number: 0, //! 0 index in FE -> sent 0 index + 1 to backend.
  display_type: "None",
  low_low_to_low_color: "red",
  sensor_max: 1,
  low_to_high_color: "red",
  device_parameter_id: "", // ! UUID
  min_to_low_low_color: "red",
  source_input: 0,
  text_decoding: "0",
  text_json: "",
  parameter_name: "",
  min_value: 0,
  unit: "",
  high_to_high_high_color: "red",
  low_low_value: 0,
  decimals: 0,
  high_high_value: 0,
  log_rate: 5,
  max_value: 0,
  display_in_summary: false,
  display_in_view: false,
  high_high_to_max_color: "red",
  read_function_code: 3,
  write_function_code: 6,
  parameter_type: 1,
  scalling: 1,
  offset: 0, //changed default value from 1 to 0 for issue #150
  modbus_adressing: 0,
  modbus_adress: 0,
  request_type: 0, //Initializing request type for issue #95
  modules: 0, //initializing for application setpoints for issue #187
  application_index: 0, //initializing for application setpoints for issue #187
  application_min: 0, //initializing for application setpoints for issue #187
  application_max: 0, //initializing for application setpoints for issue #187
  application_parameter_type: false, //initializing for application setpoints for issue #187
  perform_every: 0, //initializing for application setpoints for issue #187
  setpoints: [], //initializing for application setpoints for issue #187
  pid_parameter: 0, //initializing for application math A2 for issue #205
  select_pid_setpoint: 0, //initializing for application math A2 for issue #205
  p_gain: 0, //initializing for application math A2 for issue #205
  i_gain: 0, //initializing for application math A2 for issue #205
  d_gain: 0, //initializing for application math A2 for issue #205
  p_min: 0, //initializing for application math A2 for issue #205
  p_max: 0, //initializing for application math A2 for issue #205
  i_min: 0, //initializing for application math A2 for issue #205
  i_max: 0, //initializing for application math A2 for issue #205
  d_min: 0, //initializing for application math A2 for issue #205
  d_max: 0, //initializing for application math A2 for issue #205
  window_size: 0, //initializing for application analytics for issue #236
  select_analytics_operation: 0, //initializing for application analytics for issue #236
  p_max_rate: 0, //initializing for application analytics for issue #236
  p_min_rate: 0, //initializing for application analytics for issue #236
  parameter_tag: "", //initializing for optional tag for issue #301
  // Initializing suspect_parameter_name for issue #95
  suspect_parameter_name: {
    parameter_name: "",
    spn: 0,
    var_2: 0,
    var_3: 0,
    var_4: 0,
    var_5: 0,
    var_6: 0,
    var_7: 0,
    var_8: 0,
    var_9: 0,
    var_10: 0,
    var_11: "",
    var_12: ""
  },
  //intializing select_parameter for issue #95
  select_parameter: {
    high_value: 0,
    sensor_min: 0,
    analog_digital_range: 1,
    high_high_to_max_color: "red",
    low_value: 0,
    modbus_adress: 0,
    source: 0,
    display_in_view: false,
    parameter_number: 0,
    display_type: "None",
    low_low_to_low_color: "red",
    sensor_max: 1,
    text_json: "",
    low_to_high_color: "red",
    parameter_type: 1,
    device_parameter_id: "",
    min_to_low_low_color: "red",
    offset: 0, //changed default value from 1 to 0 for issue #150
    source_input: 0,
    text_decoding: "0",
    parameter_name: "",
    scalling: 1,
    modbus_adressing: 0,
    read_function_code: 3,
    min_value: 0,
    unit: "",
    high_to_high_high_color: "red",
    low_low_value: 0,
    decimals: 0,
    write_function_code: 6,
    high_high_value: 0,
    log_rate: 5,
    display_in_summary: false,
    max_value: 0
  }
};

const initialState: {
  parameterIndex: number;
  defaultParameters: DeviceParametersProps;
  modifiedParameters: DeviceParametersProps;
  source: number;
  suspectParameterNameList: SuspectParameterProps[]; //Setting stucture of the variable to SuspectParameterProps for issue #95
  selectParameterNameList: SelectParameterProps[]; //Setting stucture of the variable to SelectParameterProps for issue #95
  snapshotTypeList: { label: string; value: string }[]; //Setting list for snapshot type for issue #221
  parameterSelectionList: { label: string; value: number }[];
  automationSelectionList: { label: string; value: number }[]; //for active and clear parameter control for rules for issue #97
  ruleParameterSelectionList: { label: string; value: number }[]; //for active and clear parameter control for rules for issue #97
  paramAssetMap: any[]; //Adding new variable for storing asset parameter configurations for issue #89
  devParams: any[]; //Adding new variable for storing parameter data for issue #89
} = {
  parameterIndex: 0,
  defaultParameters: defaultParameters,
  modifiedParameters: defaultParameters,
  source: 0,
  parameterSelectionList: [],
  automationSelectionList: [], //for active and clear parameter control for rules for issue #97
  ruleParameterSelectionList: [], //for active and clear parameter control for rules for issue #97
  snapshotTypeList: [], //for snapshot type for issue #221
  suspectParameterNameList: suspect_parameter_name, //Intializing the SuspectParameterNameList to an empty list for issue #95
  selectParameterNameList: select_parameter, //Intializing the SelectParameterNameList to an empty list for issue #95
  paramAssetMap: [], //Initializing the variable to empty array for issue #89
  devParams: [] //Initializing the variable to empty array for issue #89
};

const parameterSettingsSlice = createSlice({
  name: "ParameterSettingsSlice",
  initialState,
  reducers: {
    setParameterIndex: (state, action: PayloadAction<number>) => {
      state.parameterIndex = action.payload;
    },
    setModifiedParameters: (
      state,
      action: PayloadAction<DeviceParametersProps>
    ) => {
      state.modifiedParameters = action.payload;
    },
    setPartialModifiedParameters: (
      state,
      action: PayloadAction<Partial<DeviceParametersProps>>
    ) => {
      state.modifiedParameters = {
        ...state.modifiedParameters,
        ...action.payload
      };
    },
    setToDefaultParameters: (
      state,
      action: PayloadAction<DeviceParametersProps>
    ) => {
      state.modifiedParameters = action.payload;
    },
    //Adding redux state function to store response received from API call in SuspectParameterNameList for issue #95
    setSuspectParameterNameList: (
      state,
      action: PayloadAction<SuspectParameterProps[]>
    ) => {
      state.suspectParameterNameList = action.payload;
    },
    //Adding redux state function to store response received from API call in SelectParameterNameList for issue #95
    setSelectParameterNameList: (
      state,
      action: PayloadAction<SelectParameterProps[]>
    ) => {
      state.selectParameterNameList = action.payload;
    },
    //Adding redux state function to stor response received from API call for snapshot type list for issue #221
    setSnapshotTypeList: (
      state,
      action: PayloadAction<{ label: string; value: string }[]>
    ) => {
      state.snapshotTypeList = action.payload;
    },
    setSource: (state, action: PayloadAction<number>) => {
      state.source = action.payload;
    },
    setParameterSelectionList: (
      state,
      action: PayloadAction<{ label: string; value: number }[]>
    ) => {
      state.parameterSelectionList = action.payload;
    },
    // state function to set automation list for issue #97
    setAutomationSelectionList: (
      state,
      action: PayloadAction<{ label: string; value: number }[]>
    ) => {
      state.automationSelectionList = action.payload;
    },
    // state function to set parameter control list for issue #97
    setRuleParameterSelectionList: (
      state,
      action: PayloadAction<{ label: string; value: number }[]>
    ) => {
      state.ruleParameterSelectionList = action.payload;
    },
    // State function to set the data received from backend for issue #89
    setParamAssetMap: (state, action: PayloadAction<any[]>) => {
      state.paramAssetMap = action.payload;
    },
    // State function to set the parameter data received from backend for issue #89
    setDevParams: (state, action: PayloadAction<any[]>) => {
      state.devParams = action.payload;
    }
  }
});

export const {
  setParameterIndex,
  setModifiedParameters,
  setPartialModifiedParameters,
  setToDefaultParameters,
  setSource,
  setParameterSelectionList,
  setAutomationSelectionList, //exporting the redux state function for issue #97
  setRuleParameterSelectionList, //exporting the redux state function for issue #97
  setSuspectParameterNameList, //exporting the redux state function for issue #95
  setSelectParameterNameList, //exporting the redux state function for issue #95
  setSnapshotTypeList, //exporting the redux state function for issue #221
  setParamAssetMap, //exporting the redux state function for issue #89
  setDevParams //exporting the redux state function for issue #89
} = parameterSettingsSlice.actions;
export default parameterSettingsSlice.reducer;
