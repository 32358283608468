import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import successIcon from "assets/bt-logins/success.svg";
import { setSuccessModalOpen } from "components/success-modal/successModalSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

type AccountSuccessModalProps = { handleCancel: Function };

export const AccountSuccessModal = ({
  handleCancel,
}: AccountSuccessModalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector(
    (state: RootState) => state.SuccessModalSlice.successModalOpen
  );
  const successDescription = useSelector(
    (state: RootState) => state.SuccessModalSlice.successModalDescription
  );

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const handleClose = () => {
    dispatch(setSuccessModalOpen(false));
    handleCancel();
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <img src={successIcon} alt="" />
          <h2 id="simple-modal-title" className={classes.modalTitle}>
            {successDescription}
          </h2>
          {/* <p></p> */}
          <button
            className={classes.okButton}
            type="button"
            onClick={handleClose}
          >
            OK
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AccountSuccessModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      color: "#fff",
      fontSize: "16px",
      // width: 433,
      height: 212,
      backgroundColor: "#00D160",
      border: "none",
      outline: "none",
      padding: "0 2rem",
    },
    modalTitle: {
      fontSize: "24px",
      fontWeight: 600,
    },
    modalDescription: {
      fontSize: "14px",
      textAlign: "center",
    },
    okButton: {
      backgroundColor: theme.palette.primary.light,
      width: "47px",
      height: "30px",
      color: "#fff",
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
  })
);
