/* Issue fixed for github issue #283 */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formModalStyles } from "common/formModalStyles";
import { RootState } from "app/rootReducer";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { AccountObject } from "app/types";

export type ProfileInformationProps = {};

export const ProfileInformation: React.FC<ProfileInformationProps> = ({ }) => {
  const classes = formModalStyles();
  const {
    given_name,
    family_name,
    phone_mobile,
    phone_office,
    email,
    active,
    user_id,
    accounts,
    username,
    last_login_time //imported for issue #283
  } = useSelector((state: RootState) => state.AppSlice.userInformation);

  const [, set] = useState();
  const [loginTime, setLoginTime] = useState(new Date()); //Added to store the date obj for issue #283

  useEffect(() => {
    const utcDate = new Date(last_login_time);
    const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);  //to convert to local time for issue #283
    setLoginTime(localDate);
  }, []);

  return (
    <Grid container>
      <Grid container style={{ margin: "0 2.5rem" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            width: "100%",
            fontWeight: 600,
          }}
        >
          <div>
            <p>First name:</p>
            <p>Last name:</p>
            <p>Email:</p>
            <p>Office contact number:</p>
            <p>Mobile number:</p>
            <p>Last login time:</p>
          </div>
          <div>
            <p>{given_name}</p>
            <p>{family_name}</p>
            <p>{email}</p>
            <p>{phone_office || "N/A"}</p>
            <p>{phone_mobile || "N/A"}</p>
            <p>{loginTime.toLocaleDateString()} {" "} {loginTime.toLocaleTimeString()}</p>
          </div>
        </div>
      </Grid>
      <Grid container>
        <Grid item xs={12} style={{ margin: "0 2.5rem" }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.columnText}>
                  Access account
                </TableCell>
                <TableCell className={classes.columnText}>Role</TableCell>
                <TableCell className={classes.columnText}>
                  Access group(s)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accounts.map((account: AccountObject, index: number) => {
                return (
                  <TableRow key={index}>
                    <TableCell className={classes.tableCell}>
                      {account.tenant_name}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {account.roles[0]}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {account.groups
                        .map((group: any) => group.asset_group_name)
                        .join(",")}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileInformation;
