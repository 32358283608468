/* Added this file for delete token modal for github issue #143 */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useDispatch } from "react-redux";
import {
  setSuccessModalOpen,
  setSuccessModalDescription
} from "components/success-modal/successModalSlice";
import { useStyles } from "components/asset-modals/assetStyles";
import AccountAPI from "services/accountsAPI";
import Button from "components/common-buttons/Button";
import { setCallTokens } from "../accountSlice";

type Props = {
  open: boolean;
  handleClose: () => void;
  tokenId: string;
  tenantId: string;
  tokenName: string;
  // setCallToken: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DeleteAPITokenModal: React.FC<Props> = ({
  open, //to open modal for issue #143
  handleClose, // to handle closing of modal for issue #143
  tokenId, //to send with api call for issue #143
  tenantId, //to send with api call for issue #143
  tokenName, //for showing in the modal for issue #143
  // setCallToken //to call the token list after token operation for issue #143
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //function to delete the token and call the delete api for issue #143
  const confirmDeleteTokenHandler = async () => {
    try {
      // calling the delete api with tenant id and token id for issue #143
      let deleteResponse = await AccountAPI.deleteToken(tenantId, tokenId);
      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription(deleteResponse.data.body));
      // setCallToken(true);
      dispatch(setCallTokens(true));
    } catch (error) {
      alert("Error deleting token");
      dispatch(setCallTokens(true));
    } finally {
      handleClose();
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={classes.modalOverlay}>
        <div className={classes.modalBody} style={{ minHeight: 0 }}>
          <div className={classes.header}>
            <p className={classes.headerText}>Delete API token</p>
            <CloseIcon className={classes.icon} onClick={handleClose} />
          </div>
          <p style={{ margin: "0 0 1rem 2rem" }}>
            Are you sure you want to delete this {tokenName}?
          </p>
          <p style={{ margin: "0 2rem" }}>
            If yes, this token and all its previous history will be removed from
            the system.
          </p>
          <div className={classes.actionItems} style={{ border: "none" }}>
            <Button color="white" type="button" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="defaultTheme"
              type="button"
              onClick={confirmDeleteTokenHandler}
            >
              Delete Token
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAPITokenModal;
