// Added file for showing exceptions on the top bar for issue #261
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  resetSelectedAccountData,
  setSubPageActive,
} from "features/accounts-page/accountSlice";
import { useStyles } from "./topBarStyles";
import clsx from "clsx";

export type ExceptionsTopBarProps = { pageName: string; subPageActive: boolean };

export const ExceptionsTopBar: React.FC<ExceptionsTopBarProps> = ({
  pageName,
  subPageActive,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleRouteToAccountTable = () => {
    dispatch(resetSelectedAccountData());
    dispatch(setSubPageActive(false));
  };

  return (
    <>
      {pageName === "Exceptions" ? (
        <>
          <div
            className={clsx({
              [classes.title]: !subPageActive,
              [classes.nestedTitle]: subPageActive,
            })}
            onClick={handleRouteToAccountTable}
          >
            {pageName}
          </div>
        </>
      ) : null}
    </>
  );
};

export default ExceptionsTopBar;