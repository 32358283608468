/* Issue fixed for github issue #204*/
import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { SignUpProps } from "./_types";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import arrowLeft from "assets/bt-logins/arrow-left-circle.svg";
import { validateEmail } from "utils/helpers";
import clsx from "clsx";
import SuccessSignUpModal from "components/success-modal/SuccessSignUpModal";
import { Colors } from "common/Colors";

const StyledCheckbox = (props: CheckboxProps) => {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      {...props}
    />
  );
};

const SignUpStepTwo: React.FC<SignUpProps> = ({
  setLogType,
  onStateChange
}: SignUpProps) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (email.length === 0 || validateEmail(email)) {
      setEmailValid(true);
    }
  }, [email]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLogType("linkDeviceId");
  };

  return (
    <div style={{ height: "100%" }}>
      {/* //! make validations for all inputs later on */}
      <form className={classes.formStyles} noValidate autoComplete="off">
        <button
          type="button"
          className={classes.backToLogin}
          onClick={() => setLogType("logIn")}
        >
          <img
            src={arrowLeft}
            alt=""
            style={{
              margin: "0 1rem 0 0"
            }}
          />
          Back to login
        </button>
        <div className={classes.createAccount}>Create account: Step 2</div>
        <div className={classes.inputField}>
          <div className={classes.inputName}>
            <span>
              Billing address <span style={{ color: "#ED254E" }}>*</span>
            </span>
            <span className={classes.warnings}>
              {!emailValid ? "Not a valid email" : null}
            </span>
          </div>
          <TextField
            className={clsx({
              [classes.valid]: emailValid,
              [classes.invalid]: !emailValid
            })}
            classes={{
              root: classes.input
            }}
            value={email}
            placeholder="Business name"
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setEmail(e.target.value)}
            InputProps={{
              disableUnderline: true
            }}
          />
          <TextField
            className={clsx({
              [classes.valid]: emailValid,
              [classes.invalid]: !emailValid
            })}
            classes={{
              root: classes.input
            }}
            // value={email}
            placeholder="Address line 1"
            // onChange={(
            //   e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            // ) => setEmail(e.target.value)}
            InputProps={{
              disableUnderline: true
            }}
          />
          <TextField
            className={clsx({
              [classes.valid]: emailValid,
              [classes.invalid]: !emailValid
            })}
            classes={{
              root: classes.input
            }}
            // value={email}
            placeholder="Address line 2"
            // onChange={(
            //   e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            // ) => setEmail(e.target.value)}
            InputProps={{
              disableUnderline: true
            }}
          />
        </div>
        <div className={classes.mailingAddressContainer}>
          <div>Mailing address</div>
          <div>
            <StyledCheckbox />
            Same as billing address
          </div>
        </div>
        <div className={classes.termsAndConditions}>
          <StyledCheckbox />
          <span style={{ whiteSpace: "nowrap" }}>
            I have read and understood all terms and conditions.
          </span>
        </div>
        <div className={classes.createAccountContainer}>
          <button
            type="button"
            className={classes.createAccountBtn}
            // ! here once validations check, proceed to next sign up
            onClick={() => {
              handleOpen();
            }}
          >
            Create account
          </button>
          <SuccessSignUpModal open={open} handleClose={handleClose} />
        </div>
      </form>
    </div>
  );
};

export default SignUpStepTwo;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStyles: {
      display: "flex",
      flexDirection: "column",
      width: "23em",
      height: "100%",
      justifyContent: "center",
      marginLeft: "30%"
    },
    createAccount: {
      fontSize: "24px",
      margin: "2rem 0"
    },
    inputName: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    inputField: { margin: "2rem 0 1rem 0" },
    warnings: {
      fontSize: "12px",
      color: "#ED254E",
      position: "relative",
      top: "5px"
    },
    input: {
      display: "flex",
      justifyContent: "center",
      borderRadius: 0,
      color: "white",
      height: 40,
      width: "95%",
      padding: "0 0 0 5%",
      border: "solid 1px DD",
      margin: "1rem 0"
    },
    valid: {
      border: "solid 1px #DDDDDD"
    },
    invalid: {
      border: "solid 1px #ED254D"
    },
    backToLogin: {
      display: "flex",
      flexDirection: "row",
      alignContent: "flex-start",
      alignItems: "center",
      margin: "1rem 0",
      padding: 0,
      border: "none",
      background: "none",
      color: Colors.MainColor, //updated color from #6D1919 to #253746 for issue #204
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": {
        opacity: ".8"
      },
      "&:focus": { outline: 0 }
    },
    mailingAddressContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "1rem 0"
    },
    termsAndConditions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      margin: "1rem 0"
    },
    createAccountContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end"
    },
    createAccountBtn: {
      backgroundColor: Colors.MainColor, //updated color from #6D1919 to #253746 for issue #204
      width: "50%",
      fontSize: "14px",
      fontWeight: 600,
      color: "#fff",
      border: "none",
      borderRadius: 0,
      margin: "1rem 0",
      height: "39px",
      "&:hover": {
        cursor: "pointer",
        opacity: ".8"
      }
    },
    // ! checkbox styles
    root: {
      padding: "5px 0",
      margin: "0 1rem 0 0",
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    icon: {
      width: 16,
      height: 16,
      border: ".5px solid #DDDDDD",
      backgroundColor: "#f5f8fa",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2
      },
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "input:hover ~ &": {
        backgroundColor: "#ebf1f5"
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)"
      }
    },
    checkedIcon: {
      backgroundColor: Colors.MainColor, //updated color from #6D1919 to #253746 for issue #204
      backgroundImage: Colors.MainColor, //updated color from #6D1919 to #253746 for issue #204
      "&:before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""'
      },
      "input:hover ~ &": {
        backgroundColor: Colors.MainColor //updated color from #6D1919 to #253746 for issue #204
      }
    }
  })
);
