export const adminRoles = [
  {
    name: "Admin",
    value: "admin",
  },
  {
    name: "Power User",
    value: "power_user",
  },
  {
    name: "Office User",
    value: "office_user",
  },
  {
    name: "Field Operations",
    value: "field_operations",
  },
  {
    name: "Field - View only",
    value: "field_view_only",
  },
  {
    name: "Notifications",
    value: "notifications",
  },
];

export const powerUserRoles = [
  {
    name: "Power User",
    value: "power_user",
  },
  {
    name: "Office User",
    value: "office_user",
  },
  {
    name: "Field Operations",
    value: "field_operations",
  },
  {
    name: "Field - View only",
    value: "field_view_only",
  },
  {
    name: "Notifications",
    value: "notifications",
  },
];
