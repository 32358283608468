/* Issue fixed for github issue #24*/
import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { setPathway } from "./RealTimeStatusPageSlice";
import { PathwayProps } from "./types";
import clsx from "clsx";

export type RealTimeNavBarProps = {
  returnToInitialDashboard?: Function;
  setAccountNotifications?: Function;
  setAccountGroups?: Function;
  returnToGroup?: Function;
  setInitialLoading?: Function;
  initialLoading?: boolean;
};

export const RealTimeNavBar: React.FC<RealTimeNavBarProps> = ({
  returnToInitialDashboard,
  setAccountNotifications,
  setAccountGroups,
  returnToGroup,
  setInitialLoading,
  initialLoading
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { pathway } = useSelector(
    (state: RootState) => state.RealTimeStatusPageSlice
  );
  const { accountName, accountId } = useSelector(
    (state: RootState) => state.AppSlice.selectedAccount
  );

  const cutPathway = (index: number) => {
    const pathwayCopy = [...pathway];
    pathwayCopy.splice(index + 1);
    dispatch(setPathway(pathwayCopy));
  };

  useEffect(() => {}, [dispatch, accountId]);

  useEffect(() => {
    return () => {
      dispatch(setPathway([]));
    };
  }, [dispatch]);

  return (
    // removing "styles" tab and added css class greybarstyling for styling the greybar in responsive UI issue #24
    <span className={classes.greybarstyling}>
      <div className={classes.locationTextContainer}>
        <p
          className={classes.accountText}
          onClick={() => {
            if (returnToInitialDashboard) {
              returnToInitialDashboard(accountId, () => {
                dispatch(setPathway([]));
              });
            }
          }}
        >
          {accountName}
        </p>{" "}
        {pathway.map((path: PathwayProps, index) => {
          return (
            <React.Fragment key={path.id + index}>
              <div className={classes.rightArrow}>{">"}</div>
              <div
                className={clsx(classes.locationText, {
                  [classes.notSelectable]: index === pathway.length - 1
                })}
                onClick={() => {
                  returnToGroup &&
                    returnToGroup(path.name, path.id, () => {
                      cutPathway(index);
                    });
                }}
              >
                <p>{path.name}</p>{" "}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </span>
  );
};

export default RealTimeNavBar;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column"
    },
    locationTextContainer: {
      backgroundColor: "lightgrey",
      width: "100%",
      //height: "2.5em",  //commenting out for issue #24
      marginTop: "1rem",
      display: "flex",
      flexDirection: "row",
      //justifyContent: "flex-start",    //commenting out for issue #24
      alignContent: "center",
      alignItems: "center",
      paddingLeft: ".5em",

      //adding media query for greybar below page heading for responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginLeft: "1.25rem",
        marginRight: "1.25rem"
      }
    },
    rightArrow: {
      fontSize: "8px",
      opacity: 0.8,
      margin: "0 3px"
    },
    accountText: {
      color: theme.palette.primary.light,
      cursor: "pointer",

      //adding media query for text on greybar for responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        color: "cornflowerblue"
      }
    },
    locationText: {
      color: theme.palette.primary.light,
      cursor: "pointer",

      //adding media query for text on greybar for responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        color: "cornflowerblue"
      }
    },
    notSelectable: {
      cursor: "default",
      pointerEvents: "none"
    },
    arrowIconStyles: {
      fontSize: ".5em",
      color: "grey",
      marginLeft: ".5em",
      marginRight: ".5em"
    },

    //adding css class for styling greybar in responsive UI issue #24
    greybarstyling: {
      display: "flex",
      flexDirection: "row",
      //adding media query for responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        width: "100%"
      }
    }
  })
);
