/* Issue fixed for github issue #24*/
import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";

import { Colors } from "common/Colors";

type UserIconDropDownProp = {
  handleSignOut: Function;
  handleOpenProfileModal: Function;
};

export const UserIconDropDown: React.FC<UserIconDropDownProp> = ({
  handleSignOut,
  handleOpenProfileModal,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const { given_name, family_name } = useSelector(
    (state: RootState) => state.AppSlice.userInformation
  );

  const userInitials = `${given_name.charAt(0).toUpperCase()}${family_name
    .charAt(0)
    .toUpperCase()}
  `;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.buttonIcon}
      >
        <span className={classes.label}>{userInitials}</span>
      </button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={"bottom-end"}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper style={{ borderRadius: 0, marginTop: ".4em" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  classes={{
                    root: classes.paper,
                  }}
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    onClick={() => {
                      handleOpenProfileModal();
                    }}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      handleClose(event);
                      handleSignOut();
                    }}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    buttonIcon: {
      border: "none",
      borderRadius: 25,
      width: 35,
      height: 35,
      background: theme.palette.primary.light,
      color: Colors.White,
      padding: 0,
      cursor: "pointer",
      marginLeft: "2rem",
      outline: "none !important",
      fontSize: 14,

      //adding media query for responsive UI issue #24 for hiding the button in mobile view
      [`@media (max-width: 800px)`]: {
        display: "none",
      },
    },
    label: {
      padding: 5,
    },
    paper: {
      color: theme.palette.primary.light,
      borderRadius: 0,
    },
  })
);
