import React, { useState, useEffect } from "react";
import { Colors } from "common/Colors";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import { tableStyle } from "../../devices-page/device-settings/device-settings-tabs/tableStyles";
import Xsquare from "assets/bt-assets/x-square.svg";
import {
  setSelectedParameterId,
  setAssetParameterList,
  setParameterSequence,
} from "../assetsDashboardSlice";
import { DeviceParametersProps } from "features/devices-page/device-settings/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import Select from "react-select";
import clsx from "clsx";
import { devicesAPI } from "services/devicesAPI";
import ViewParameterModal from "./ViewParameterModal";
interface ParameterTableDisplayProps {
  callDeleteParameterModal: Function;
}

export const ParameterTableDisplay: React.FC<ParameterTableDisplayProps> = ({
  callDeleteParameterModal,
}) => {
  const classes = useStyles();
  const table = tableStyle();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(false);
  const [viewParameterModal, setViewParameterModal] = useState(false);
  const [parameterIndex, setParameterIndex] = useState(0);

  const openViewParameterModal = (index: number) => {
    setParameterIndex(index);
    /* ViewParameter modal needs more information from backend to display. This
    is being commented out for now.  To see the pop-up, just uncomment the below line. */
    // setViewParameterModal(true);
  };

  const closeViewParameterModal = () => {
    setParameterIndex(0);
    setViewParameterModal(false);
  };

  const { assetParameterList, parameterSelections, currentAssetId } =
    useSelector((state: RootState) => state.AssetDashboardSlice);

  useEffect(() => {
    if (!assetParameterList.length) {
      setExpanded(false);
    }
  }, [assetParameterList.length]);

  const addParameterToAsset = async (parameter: any) => {
    const [deviceId, parameter_id] = JSON.parse(parameter.value);
    try {
      const updatedParameterList = await devicesAPI.addParameterToAsset(
        currentAssetId,
        deviceId,
        parameter_id
      );

      dispatch(setAssetParameterList(updatedParameterList));
    } catch (error) {
      alert(error);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <Paper
          elevation={0}
          className={
            expanded
              ? classes.paperStylesExpanded
              : classes.paperStylesCollapsed
          }
          onClick={() => {
            setExpanded((expanded) => !expanded);
          }}
        >
          <div className={classes.collapseBarInfo}>
            <span
              className={clsx({
                [classes.tabbedHeaderCollapsed]: !expanded,
                [classes.tabbedHeaderExpanded]: expanded,
              })}
            >
              Parameters ({assetParameterList.length})
            </span>
          </div>
          {expanded ? (
            <RemoveCircleOutlineOutlinedIcon
              className={classes.expandIconOpen}
            />
          ) : (
            <ControlPointIcon className={classes.expandIconCollapsed} />
          )}
        </Paper>
        {expanded && (
          <div className={classes.expansionContainer}>
            <div className={classes.buttonActions}>
              <button
                className={classes.whiteOutlineButton}
                type="button"
                onClick={() => {
                  dispatch(setParameterSequence(true));
                }}
              >
                Change Parameter sequence
              </button>
              <div className={classes.buttonRow}>
                <Select
                  styles={reactSelectStyles}
                  value={!parameterSelections.length && { selection: 0 }}
                  onChange={addParameterToAsset}
                  options={parameterSelections}
                  maxMenuHeight={220}
                  classNamePrefix="select"
                  placeholder="Select parameters"
                  isSearchable
                />
              </div>
            </div>
            <TableContainer style={{ maxHeight: 500, margin: "1rem 0" }}>
              <Table
                style={{ width: "100%" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={table.header} align="left">
                      <span className={classes.headerName}>Device</span>
                    </TableCell>
                    <TableCell className={table.header} align="left">
                      <span className={classes.headerName}>Parameter</span>
                    </TableCell>{" "}
                    <TableCell className={table.header} align="left">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assetParameterList.map(
                    (parameter: DeviceParametersProps, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className={table.tableCell} align="left">
                            {parameter.device_name}
                          </TableCell>
                          <TableCell className={table.tableCell} align="left">
                            <span
                              className={classes.name}
                              onClick={() => {
                                openViewParameterModal(index);
                              }}
                            >
                              {parameter?.parameter_name}
                            </span>
                          </TableCell>
                          <TableCell
                            className={table.tableCell}
                            style={{ padding: "0 2em" }}
                            align="left"
                          >
                            <img
                              src={Xsquare}
                              alt="Delete asset property"
                              className={classes.faIcon}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                callDeleteParameterModal();
                                dispatch(
                                  setSelectedParameterId(
                                    parameter.device_parameter_id
                                  )
                                );
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
      {assetParameterList.length ? (
        <ViewParameterModal
          parameterIndex={parameterIndex}
          assetParameterList={assetParameterList}
          open={viewParameterModal}
          handleClose={closeViewParameterModal}
        />
      ) : null}
    </>
  );
};

const reactSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer",
    width: "375px",
  }),
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      padding: 0,
    },
    faIcon: {
      color: theme.palette.primary.light,
    },
    expansionContainer: { display: "flex", flexDirection: "column" },
    headerCollapsed: {
      color: theme.palette.primary.light,
      padding: 0,
      margin: 0,
    },
    headerExpanded: { color: Colors.White, padding: 0, margin: 0 },
    paperStylesCollapsed: {
      marginTop: "1em",
      height: "2.5em",
      width: "100%",
      border: `solid 1px ${Colors.LightGray}`,
      borderLeft: `solid 5px ${theme.palette.primary.light}`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "1em",
      paddingLeft: "1em",
      borderRadius: 0,
      cursor: "pointer",
    },
    paperStylesExpanded: {
      height: "2.5em",
      marginTop: "1em",
      width: "100%",
      border: `solid 1px ${Colors.LightGray}`,
      borderLeft: `solid 0 ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.primary.light,
      color: Colors.White,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "1em",
      paddingLeft: "1em",
      borderRadius: 0,
      cursor: "pointer",
    },
    tabbedHeaderCollapsed: {
      color: theme.palette.primary.light,
    },
    tabbedHeaderExpanded: {
      color: Colors.White,
    },
    headerName: {
      color: theme.palette.primary.light,
    },
    name: {
      cursor: "pointer",
      color: theme.palette.primary.light,
      fontWeight: 600,
    },
    expandIconCollapsed: {
      color: theme.palette.primary.light,
      borderRadius: 0,
      "&:hover": { cursor: "pointer" },
    },
    expandIconOpen: {
      color: Colors.White,
      padding: 0,
      borderRadius: "50%",
      "&:hover": { cursor: "pointer" },
    },
    collapseBarInfo: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      borderRadius: 0,
    },
    buttonActions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "1em",
    },
    buttonRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      width: "50%",
      height: 38,

      "& select": {
        width: "100%",
        padding: "0 1rem",
        border: `1px solid ${Colors.LightGray}`,
        height: 38,
        cursor: "pointer",
      },
    },
    whiteOutlineButton: {
      padding: ".7rem 1.5rem",
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      cursor: "pointer",

      marginRight: "1.5rem",
      fontWeight: "bold",
      backgroundColor: Colors.White,
    },
    redOutlineButton: {
      padding: ".7rem 1.5rem",
      color: Colors.White,
      backgroundColor: theme.palette.primary.light,
      cursor: "pointer",
      fontWeight: "bold",
      border: "none",
    },
  })
);
