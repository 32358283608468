import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import CloseIcon from "@material-ui/icons/Close";
import AssetAPI from "services/assetsAPI";
import { useDispatch } from "react-redux";
import { setCallCategoryList } from "features/assets-page/assetCategorySlice";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
} from "components/success-modal/successModalSlice";

export interface DeleteAssetCategoryProps {
  closeModal: () => void;
  categoryId: string;
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const DeleteAssetCategory: React.FC<DeleteAssetCategoryProps> = ({
  closeModal,
  categoryId,
}: DeleteAssetCategoryProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const cancel = () => {
    closeModal();
  };

  const deleteAssetCategory = async () => {
    try {
      await AssetAPI.deleteAssetCategory(categoryId);
      dispatch(
        setSuccessModalDescription("Asset category successfully deleted!")
      );
      dispatch(setSuccessModalOpen(true));
      dispatch(setCallCategoryList(true));
    } catch (error) {
      alert("Unable to delete as there are assets using this asset category");
    }
    closeModal();
  };

  return (
    <div className={classes.modalOverlay}>
      <div className={classes.modalBody}>
        <div className={classes.header}>
          <p className={classes.headerText}>Delete asset category</p>
          <CloseIcon className={classes.icon} onClick={() => closeModal()} />
        </div>
        <div style={{ margin: "2rem 0 0 0" }}>
          <p className={classes.deleteDescription}>
            Are you sure you want to delete this asset category?
          </p>
          <p className={classes.deleteDescription}>
            If yes, it will be removed from this platform.
          </p>
        </div>
        <div className={classes.actionItems}>
          <button className={classes.cancelButton} onClick={cancel}>
            Cancel
          </button>
          <button className={classes.saveButton} onClick={deleteAssetCategory}>
            Delete asset category
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAssetCategory;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)",
    },
    modalBody: {
      minHeight: "20rem",
      width: 500,
      backgroundColor: "white",
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "2rem 2.5rem",
      borderBottom: `1px solid ${Colors.LightGray}`,
    },
    headerText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      fontSize: 24,
      padding: 0,
      margin: 0,
    },
    icon: {
      color: Colors.LightGray,
      cursor: "pointer",
      fontSize: "3rem",
    },
    pathText: {
      backgroundColor: Colors.LightGray,
    },
    labelText: {
      display: "flex",
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        marginRight: "1rem",
        fontSize: "1rem",
      },
    },
    inputGroup: {
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem",
      },
      "& input": {
        width: "100%",
        padding: ".7rem 1rem",
        border: `1px solid ${Colors.LightGray}`,
      },
    },
    deleteDescription: {
      padding: "0 2.5rem",
      display: "flex",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem",
      },
    },
    actionItems: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "2rem 2.5rem",
    },
    cancelButton: {
      padding: ".7rem 1.5rem",
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      cursor: "pointer",
      marginRight: "1.5rem",
      fontWeight: "bold",
      backgroundColor: "white",
    },
    saveButton: {
      padding: ".7rem 1.5rem",
      color: "white",
      backgroundColor: theme.palette.primary.light,
      cursor: "pointer",
      fontWeight: "bold",
      border: "none",
    },
  })
);
