/* Issue fixed for github issue #26*/
import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles } from "components/asset-modals/assetStyles";
import { Colors } from "common/Colors";

type AddNewAccountModalProps = {
  open: boolean;
  handleClose: Function;
  handleCreateNewAccount: Function;
};

export const AddNewAccountModal: React.FC<AddNewAccountModalProps> = ({
  open,
  handleClose,
  handleCreateNewAccount,
}) => {
  const classes = useStyles();
  const [disable, setDisable] = useState(false);
  const isLoading = useSelector(
    (state: RootState) => state.AccountSlice.accountLoading
  );

  useEffect(() => {
    return () => {
      setDisable(false);
    };
  }, []);

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalOverlay}>
        {!isLoading ? (
          <>
            <div className={classes.modalBody} style={{ minHeight: 0 }}>
              <div className={classes.header}>
                <p className={classes.headerText}>Create new account</p>
                <CloseIcon
                  className={classes.icon}
                  onClick={() => handleClose()}
                />
              </div>
              <p style={{ margin: "0 0 0 2rem" }}>
                Are you sure you want to create this account?
              </p>
              {/* removed the next line from th account creation modal for issue #26 */}
              <div className={classes.actionItems} style={{ border: "none" }}>
                <button
                  className={classes.cancelButton}
                  type="button"
                  onClick={() => {
                    setDisable(false);
                    handleClose();
                  }}
                >
                  Cancel
                </button>
                <button
                  disabled={disable}
                  className={classes.saveButton}
                  type="button"
                  onClick={() => {
                    setDisable(true);
                    handleCreateNewAccount();
                  }}
                >
                  {/* {!isLoading ? "Create account" : "Please wait..."} */}
                  Create account
                </button>
              </div>
            </div>
          </>
        ) : (
            <CircularProgress style={{ color: Colors.White }} />
          )}
      </div>
    </Modal>
  );
};

export default AddNewAccountModal;
