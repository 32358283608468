import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { ParameterProps } from "features/real-time-status-page/types";
import { displayColors } from "utils/displayDashChartColors";
import { Colors } from "common/Colors";
import { binaryFromJsonList } from "utils/binaryToText";
import { numberToTextKey, binaryToTextKey } from "utils/constants";
import { getDecodedVal } from "utils/conversions";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

type GaugeChartProps = {
  params: ParameterProps;
};

const GaugeChart = ({ params }: GaugeChartProps) => {
  const [needleRotation, setNeedleRotation] = useState(242); // initial rotation

  const max = params.max_value;
  const hihi = params.high_high_value;
  const hi = params.high_value;
  const lo = params.low_value;
  const lolo = params.low_low_value;
  const min = params.min_value;
  const param = params.parameter_value;

  const pcent_val = (100 * (param - min)) / (max - min);
  const pcent_val_bound = Math.max(0, Math.min(pcent_val, 100));

  useEffect(() => {
    const newRotation = 242 + (pcent_val_bound * 236) / 100;
    setNeedleRotation(newRotation);
  }, [pcent_val_bound]);

  const pcent_hi_max = (100 * (max - hihi)) / (max - min);
  const pcent_hi_hihi = (100 * (hihi - hi)) / (max - min);
  const pcent_lo_hi = (100 * (hi - lo)) / (max - min);
  const pcent_lolo_lo = (100 * (lo - lolo)) / (max - min);
  const pcent_min_lolo = (100 * (lolo - min)) / (max - min);

  const {
    min_to_low_low_color,
    low_low_to_low_color,
    low_to_high_color,
    high_to_high_high_color,
    high_high_to_max_color,
  } = params;

  const COLORS = [
    min_to_low_low_color,
    low_low_to_low_color,
    low_to_high_color,
    high_to_high_high_color,
    high_high_to_max_color,
  ];

  const data_2 = [
    { name: "A", value: pcent_min_lolo },
    { name: "B", value: pcent_lolo_lo },
    { name: "C", value: pcent_lo_hi },
    { name: "D", value: pcent_hi_hihi },
    { name: "E", value: pcent_hi_max },
    { name: "F", gauge_lo: pcent_val_bound },
  ];

  const percentLabel = (props: any) => {
    return (
      <>
        <text
          x={"50%"}
          y={"50%"}
          textAnchor="middle"
          dominantBaseline="middle"
          fill={Colors.Black}
        >
          {getDecodedVal(
            params,
            Number(params.parameter_value),
            numberToTextKey,
            binaryToTextKey,
            binaryFromJsonList
          )}
        </text>
        <text
          x={"50%"}
          y={"60%"}
          textAnchor="middle"
          dominantBaseline="middle"
          fill={Colors.Black}
        >
          {params.unit}
        </text>
      </>
    );
  };

  return (
    <div
      style={{
        height: "min(200px,20vh)",
        minHeight: "max(120px,10vh)",
        width: "100%",
        position: "relative"
      }}
    >
      <ResponsiveContainer>
        <PieChart>
          <Pie
            isAnimationActive={false}
            data={data_2}
            dataKey="value"
            cx={"50%"}
            cy={"50%"}
            startAngle={210}
            endAngle={-30}
            outerRadius={"85%"}
            innerRadius={"51%"}
            fill="#8884d8"
            labelLine={false}
            label={percentLabel}
            cornerRadius={0}
          >
            {data_2.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={displayColors(COLORS[index % COLORS.length])}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <svg
        viewBox="0 0 100 100"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          transform: `rotate(${needleRotation}deg)`,
          transformOrigin: "50% 50%",
          transition: "transform 1s ease-in-out",
        }}
      >
        <line
          x1="50"
          y1="35" // Start point close to the center
          x2="50"
          y2="12" // End point further out (pointy end)
          stroke={Colors.DashboardDarkBlue}
          strokeWidth="3"
        />
      </svg>
    </div>
  );
};

export const useStyles = makeStyles((theme: Theme) => createStyles({}));

export default GaugeChart;