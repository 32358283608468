// Added the whole file for MFA modal for issue #152
import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useForm, Controller } from "react-hook-form";
import { InputLabel } from "components/input-label/InputLabel";
import { Colors } from "common/Colors";
import { RootState } from "app/rootReducer";
import { Button } from "components/common-buttons/Button";
import { Auth, Hub } from "aws-amplify";
//Added circularprogress bar and modal for showing the loader for issue #152
import CircularProgress from "@material-ui/core/CircularProgress";

type MFAMobileProps = {
  open: boolean;
  handleClose: Function;
  cognitoUser: any;
};

// to store otp for issue #152
export interface VerifyMFAAuthProps {
  mfa_otp: string;
}

export const MFAMobileAuth: React.FC<MFAMobileProps> = ({
  open,
  handleClose,
  cognitoUser
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [sendResendOTPButton, setSendResendOTPButton] = useState(true);
  const [resendOTPButtonClicked, setResendOTPButtonClicked] = useState(false);
  const { register, handleSubmit, control, errors } = useForm<
    VerifyMFAAuthProps
  >();

  // function to close the modal for issue #152
  const handleModalClose = () => {
    handleClose();
  };

  // commented code for resend button
  // const resendOTP = () => {
  //   setResendOTPButtonClicked(true);
  //   console.log("OTP resent");
  //   setTimeout(() => {
  //     setResendOTPButtonClicked(false);
  //   }, 4000);
  // };

  // function to handle otp and verify it for issue #152
  const authMFA = async ({ mfa_otp }: VerifyMFAAuthProps) => {
    try {
      setLoading(true);
      // cognito api call to verify otp sent for MFA for issue #152
      const loggedUser = await Auth.confirmSignIn(
        cognitoUser,
        mfa_otp,
        cognitoUser.challengeName
      );
    } catch (error) {
      setLoading(false); //setting loader to false for issue #152
      console.log(error);
      alert(error.message);
    }
  };

  // commented code for resend button
  // useEffect(() => {
  //   setTimeout(() => {
  //     setSendResendOTPButton(false);
  //   }, 6000);
  // }, []);

  return (
    <div>
      {/* Added the modal and the loader, to be shown on top of the form and the form is disables once loader is true for issue #152 */}
      {loading && (
        <Modal
          open={loading}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.loader}
        >
          <CircularProgress />
        </Modal>
      )}
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalOverlay}>
          <form onSubmit={handleSubmit(authMFA)}>
            <div className={classes.modalBody}>
              <div className={classes.header}>
                <p className={classes.headerText}>
                  Multi-factor Authentication
                </p>
                <CloseIcon
                  className={classes.icon}
                  onClick={handleModalClose}
                />
              </div>
              <div>
                <div className={classes.inputGroup}>
                  An one time password has been sent to mobile number ending in{" "}
                  {cognitoUser?.challengeParam?.CODE_DELIVERY_DESTINATION}.
                </div>
                {resendOTPButtonClicked && (
                  <div style={{ color: "red" }} className={classes.inputGroup}>
                    OTP has been sent again.
                  </div>
                )}
                <div className={classes.inputGroup}>
                  <InputLabel
                    label="Enter OTP"
                    name="mfa_otp"
                    errors={errors}
                  />
                  <div style={{ width: "93%" }}>
                    <input
                      name="mfa_otp"
                      ref={register({
                        required: "This field is required."
                      })}
                      minLength={6}
                      maxLength={6}
                      type="text"
                      placeholder="Enter"
                    />
                  </div>
                </div>
              </div>
              <div className={classes.actionItems}>
                {/* commented code for resend button */}
                {/* <Button
                  type="button"
                  color="white"
                  onClick={resendOTP}
                  style={{ opacity: sendResendOTPButton ? "0.1" : "1" }}
                  disabled={sendResendOTPButton}
                >
                  Re-send OTP
                </Button> */}
                <Button type="button" color="white" onClick={handleModalClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="defaultTheme"
                  style={{ backgroundColor: Colors.MainColor }}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default MFAMobileAuth;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "0 1rem"
    },
    cb: {
      color: theme.palette.primary.light,
      backgroundColor: "transparent"
    },
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)"
    },
    modalBody: {
      maxHeight: "90vh",
      overflowY: "auto",
      width: 500,
      backgroundColor: Colors.White
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 2.5rem",
      margin: "0 0 1em 0",
      borderBottom: `1px solid ${Colors.LightGray}`
    },
    headerText: {
      // color: theme.palette.primary.light,
      fontWeight: 600,
      fontSize: 24,
      padding: 0,
      margin: 0
    },
    icon: {
      color: Colors.LightGray,
      cursor: "pointer",
      fontSize: "3rem"
    },
    reactSelect: {
      borderRadius: 0
    },
    pathText: {
      backgroundColor: Colors.LightGray,
      display: "flex",
      padding: ".5rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        marginRight: "1rem",
        fontSize: "1rem"
      }
    },
    labelText: {
      display: "flex",
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        marginRight: "1rem",
        fontSize: "1rem"
      }
    },
    label: {
      fontSize: "16px",
      color: theme.palette.primary.light
    },
    formRoot: {
      height: 25
    },
    inputGroup: {
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem"
      },
      "& input": {
        width: "100%",
        padding: ".7rem 1rem",
        border: `1px solid ${Colors.LightGray}`
      },
      "& select": {
        width: "100%",
        padding: "0 1rem",
        border: `1px solid ${Colors.LightGray}`,
        height: 38,
        cursor: "pointer"
      }
    },
    innerInput: {
      padding: "1rem 0",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem"
      },
      "& input": {
        width: "100%",
        padding: ".7rem 1rem",
        border: `1px solid ${Colors.LightGray}`
      },
      "& select": {
        width: "100%",
        padding: "0 1rem",
        border: `1px solid ${Colors.LightGray}`,
        height: 38,
        cursor: "pointer"
      }
    },
    actionItems: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "1rem 2.5rem",
      borderTop: `1px solid ${Colors.LightGray}`,
      padding: "1rem 0"
    },
    //Added the class for aligning the loader in the center of the page for issue #152
    loader: {
      color: theme.palette.primary.light,
      justifyContent: "center",
      alignItems: "center",
      display: "flex"
    }
  })
);
