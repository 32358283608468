import React from "react";
import { modalStyles } from "../../devices-page/device-settings/device-settings-tabs/modalStyles";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
} from "components/success-modal/successModalSlice";
import { setAssetParameterList } from "../assetsDashboardSlice";
import { devicesAPI } from "services/devicesAPI";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";

type DeleteParameterModalProps = {
  handleClose: Function;
  open: boolean;
};

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const DeleteParameterModal: React.FC<DeleteParameterModalProps> = ({
  handleClose,
  open,
}) => {
  const styles = modalStyles();
  const dispatch = useDispatch();

  const { selectedParameterId, currentAssetId } = useSelector(
    (state: RootState) => state.AssetDashboardSlice
  );

  const cancelDeleteParameterModal = () => {
    handleClose();
  };

  const handleDeleteParameter = async () => {
    try {
      const updatedParameterList = await devicesAPI.deleteAssetsParameter(
        currentAssetId,
        selectedParameterId
      );

      dispatch(setAssetParameterList(updatedParameterList));

      handleClose();
      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription("Parameter successfully removed!"));
    } catch (error) {
      alert(error);
      handleClose();
    }
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={styles.modalOverlay}>
          <div className={styles.modalBody}>
            <div className={styles.header}>
              <p className={styles.headerText}>Delete parameter from asset</p>
              <CloseIcon
                className={styles.icon}
                onClick={() => handleClose()}
              />
            </div>
            <div style={{ margin: "2rem 0 0 0" }}>
              <div className={styles.deleteDescription}>
                <p>
                  Are you sure you want to remove this parameter from this
                  asset?
                </p>
                <p>
                  If yes, this parameter will not be shown in asset management
                  as well as in the dashboard.
                </p>
              </div>
            </div>
            <div className={styles.actionItems}>
              <button
                className={styles.cancelButton}
                onClick={cancelDeleteParameterModal}
              >
                Cancel
              </button>
              <button
                className={styles.saveButton}
                onClick={handleDeleteParameter}
              >
                Remove parameter from asset
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteParameterModal;
