import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  recallDeviceList: boolean;
} = {
  recallDeviceList: false,
};

const deviceTableSlice = createSlice({
  name: "DeviceTableSlice",
  initialState,
  reducers: {
    setRecallDeviceList: (state, action: PayloadAction<boolean>) => {
      state.recallDeviceList = action.payload;
    },
  },
});

export const { setRecallDeviceList } = deviceTableSlice.actions;
export default deviceTableSlice.reducer;
