//Added file for github issue #261
// props for add and update exception form 
export interface UpdateExceptionProps {
  comment: string;
  change_state: number;
  root_cause: number;
  assigned_to: any[];
}

export interface CreateExceptionProps {
  comment: string;
  state: number;
  priority: number;
  root_cause: number;
  assigned_to: any[];
}

export interface AssignedTo {
  user_id: string;
  user_name: string;
}

export interface User {
  label: string;
  value: string;
}

export interface UserChange {
  added: User[];
  removed: User[];
}

export interface Exception {
  assets: string[];
  state_latest: number;
  exception_id: string;
  name: string;
  source: string;
  time_latest: number;
  priority: number;
  assigned_to?: AssignedTo[];
  root_cause: number;
  root_cause_desc: string | null;
}

export interface KanbanColumns {
  New: Exception[];
  Open: Exception[];
  InProcess: Exception[];
  OnHold: Exception[];
  Closed: Exception[];
}

export interface KanbanColumns {
  New: Exception[];
  Open: Exception[];
  InProcess: Exception[];
  OnHold: Exception[];
  Closed: Exception[];
}

export interface ColumnTitles {
  New: string;
  Open: string;
  InProcess: string;
  OnHold: string;
  Closed: string;
}

export interface ColumnColors {
  New: string;
  Open: string;
  InProcess: string;
  OnHold: string;
  Closed: string;
}

// Define columns, titles, and colors
export const columnsData: KanbanColumns = {
  New: [],
  Open: [],
  InProcess: [],
  OnHold: [],
  Closed: [],
};

export const columnTitles: ColumnTitles = {
  New: 'New',
  Open: 'Open',
  InProcess: 'In-process',
  OnHold: 'On-hold',
  Closed: 'Closed in time selected',
};

export const columnColors: ColumnColors = {
  New: '#56ccf2',
  Open: '#7030a0',
  InProcess: '#307fe2',
  OnHold: '#f39c12',
  Closed: '#32363a',
};
