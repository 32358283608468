import React from "react";
// import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// import { Colors } from "common/Colors";
import { useStyles } from "./displayStyles";

type TenantDisplayProps = {
  openAddAssetModal: Function;
  openAddGroupModal: Function;
  nodeObject: any;
};

const TenantDisplay: React.FC<TenantDisplayProps> = ({
  openAddAssetModal,
  openAddGroupModal,
  nodeObject,
}) => {
  const classes = useStyles();
  // console.log("INSIDE PARENT", nodeObject);
  return (
    <>
      <div className={classes.pathHeader}>
        <span
          style={{
            fontWeight: 600,
            color: "#A6A6A6",
            margin: "0 2rem",
          }}
        >
          Path:
        </span>
        <span>{nodeObject.title}</span>
      </div>
      <div className={classes.assetInformation}>
        <div className={classes.assetsGroup}>
          <div className={classes.value}>3</div>
          <div>Groups</div>
        </div>
        <div className={classes.assetsGroup}>
          <div className={classes.value}>11</div>
          <div>Assets</div>
        </div>
        <div className={classes.assetsGroup}>
          <div className={classes.value}>105</div>
          <div>Devices</div>
        </div>
      </div>
      <div className={classes.tenantButtonContainer}>
        <button
          type="button"
          className={classes.addButtons}
          onClick={() => {
            openAddGroupModal();
          }}
        >
          + Add new group
        </button>
      </div>
    </>
  );
};

export default TenantDisplay;
