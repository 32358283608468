import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, Grid, Container, TextField, InputAdornment, Button } from '@material-ui/core';
import KanbanColumn from 'components/Kanban/KanbanColumn';
import KanbanCard from 'components/Kanban/KanbanCard';
import ExceptionModal from './ExceptionModal';
import CreateExceptionModal from './CreateExceptionModal';
import { useSelector } from 'react-redux';
import { RootState } from 'app/rootReducer';
import { Exception, KanbanColumns, columnTitles, columnColors } from "./types";
import { makeStyles, Theme } from '@material-ui/core/styles';
import DateRangeSelector from "components/date-range-selector/DateRangeSelector";
import { getUnixTime, subDays } from "date-fns";
import { dashboardAPI } from "services/dashboardAPI";
import SearchIcon from '@material-ui/icons/Search';

import AddIcon from '@material-ui/icons/Add';
import ExceptionsTopBar from 'features/drawer/ExceptionsTopBar';

const ExceptionStatusDashboard = () => {
  const classes = useStyles();
  const [selectedException, setSelectedException] = useState<Exception | null>(null);
  const [exceptionModal, setExceptionModal] = useState(false);
  const [createExceptionModal, setCreateExceptionModal] = useState(false);
  const [exceptions, setExceptions] = useState<Exception[]>([]);
  const [filteredExceptions, setFilteredExceptions] = useState<Exception[]>([]);
  const [filterAssignedToMe, setFilterAssignedToMe] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<number | null>(getUnixTime(subDays(new Date(), 1)));
  const [endDate, setEndDate] = useState<number | null>(getUnixTime(new Date()));
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { userInformation } = useSelector((state: RootState) => state.AppSlice);
  const [rootCauseLists, setRootCauseLists] = useState<any>([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleCloseModal = () => {
    setExceptionModal(false);
    setCreateExceptionModal(false);
  };

  const callExceptionModal = (exceptionObj: Exception) => {
    setSelectedException(exceptionObj);
    setExceptionModal(true);
  };

  const callCreateExceptionModal = () => {
    setCreateExceptionModal(true);
  };

  const { accountId } = useSelector(
    (state: RootState) => state.AppSlice.selectedAccount
  );

  // api call to get comments and list of root causes for issue #261
  useEffect(() => {
    (async () => {
      try {
        if (accountId) {
          const rootCauseList = await dashboardAPI.getRootCauses(
            accountId
          );
          const tempList = [{ name: "Select root cause", value: 0 }, ...rootCauseList];
          setRootCauseLists(tempList);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [accountId]);

  const getExceptionList = async () => {
    try {
      if (accountId && startDate && endDate) {
        setLoading(true);
        const response = await dashboardAPI.getAllExceptions(accountId, startDate, endDate);
        if (response && Array.isArray(response.Item)) {
          setExceptions(response.Item);
          setFilteredExceptions(response.Item);
        } else {
          console.error("API response is not as expected", response);
          setExceptions([]);
          setFilteredExceptions([]);
        }
      }
    } catch (error) {
      console.error("Failed to fetch exceptions", error);
      setExceptions([]);
      setFilteredExceptions([]);
    } finally {
      setLoading(false);
    }
  };

  const refreshExceptions = () => {
    getExceptionList();
  };

  useEffect(() => {
    getExceptionList();
  }, [accountId, startDate, endDate]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let filteredList = exceptions;

    if (searchQuery !== '') {
      filteredList = filteredList.filter((exception) =>
        exception.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (filterAssignedToMe) {
      filteredList = filteredList.filter(
        (exception) =>
          exception.assigned_to?.some(
            (assigned) => assigned.user_id === userInformation.user_id
          )
      );
    }

    setFilteredExceptions(filteredList);
  }, [searchQuery, exceptions, filterAssignedToMe, userInformation.user_id]);

  const columns: KanbanColumns = {
    New: filteredExceptions.filter(ex => ex.state_latest === 0),
    Open: filteredExceptions.filter(ex => ex.state_latest === 1),
    InProcess: filteredExceptions.filter(ex => ex.state_latest === 2),
    OnHold: filteredExceptions.filter(ex => ex.state_latest === 3),
    Closed: filteredExceptions.filter(ex => ex.state_latest === 4),
  };

  useEffect(() => {
    if (exceptionModal || createExceptionModal) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [exceptionModal, createExceptionModal]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <TextField
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={callCreateExceptionModal}

          className={classes.addCardButton}
        >
          Add card
        </Button>
        <FormControlLabel
          control={
            <Checkbox
              checked={filterAssignedToMe}
              onChange={(e) => setFilterAssignedToMe(e.target.checked)}
              color="primary"
            />
          }
          label="Assigned to me"
        />

        <div className={classes.dateRangeSelector}>
          <DateRangeSelector
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </div>
      </div>

      <div className={classes.kanbanContainer}>
        {Object.keys(columns).map(key => {
          const columnKey = key as keyof KanbanColumns; // Type assertion
          return (
            <div key={columnKey} className={classes.column}>
              <KanbanColumn
                title={columnTitles[columnKey]} // Use imported columnTitles
                exceptions={columns[columnKey]}
                headerColor={columnColors[columnKey]} // Use imported columnColors
                windowWidth={windowWidth}
              >
                {columns[columnKey].map(exception => (
                  <KanbanCard
                    key={exception.exception_id}
                    exception={exception}
                    onClick={() => callExceptionModal(exception)}
                  />
                ))}
              </KanbanColumn>
            </div>
          );
        })}
      </div>

      {/* Create new card modal */}
      {createExceptionModal && (
        <CreateExceptionModal
          open={createExceptionModal}
          handleClose={handleCloseModal}
          rootCauseLists={rootCauseLists}
          refreshExceptions={refreshExceptions}
        />
      )}
      {exceptionModal && selectedException && (
        <ExceptionModal
          exceptionObject={selectedException}
          open={exceptionModal}
          handleClose={handleCloseModal}
          rootCauseLists={rootCauseLists}
          refreshExceptions={refreshExceptions}
        />
      )}
    </div>
  );
};

export default ExceptionStatusDashboard;

const useStyles = makeStyles((theme: Theme) => ({
  dateRangeSelector: {
    marginTop: '5px',
    marginBottom: '0px',
    display: 'flex',
    alignItems: 'flex-end', // Align to the bottom
    [theme.breakpoints.down(1080)]: {
      marginLeft: '-15px', // Align with the search box
    },
  },
  container: {
    fontFamily: `"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif`,
    [theme.breakpoints.down(1080)]: {
      overflowX: 'hidden',
      flexDirection: 'column',
      alignItems: 'stretch',
      width: 'calc(100% - 0px)',
      margin: '0px',
      padding: '0px',
    },
    [theme.breakpoints.down(800)]: {
      overflowX: 'hidden',
      flexDirection: 'column',
      alignItems: 'stretch',
      width: 'calc(100% - 10px)',
      margin: '0px',
      padding: '0px',
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end', // Align items to the bottom
    marginTop: '5px',
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid #ddd',
    [theme.breakpoints.down(1080)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: '20px',
    },
    [theme.breakpoints.down(800)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginLeft: '12px',
      marginBottom: theme.spacing(0, 2),
    },
  },
  addCardButton: {
    marginLeft: '20px',
    marginBottom: '5px',
    [theme.breakpoints.down(1080)]: {
      marginLeft: '0px', // Adjust for responsiveness if necessary
      marginTop: theme.spacing(2),
    },
  },
  kanbanContainer: {
    display: 'flex',
    overflowX: 'auto',
    width: 'calc(100% + 1px)',
    margin: '10',
    [theme.breakpoints.down(1080)]: {
      overflowX: 'hidden',
      flexDirection: 'column',
      alignItems: 'center',
      width: 'calc(100% + 0px)',
      margin: '0px',
      padding: '10px',
    },
    [theme.breakpoints.down(800)]: {
      overflowX: 'hidden',
      flexDirection: 'column',
      alignItems: 'center',
      width: 'calc(100% - 16px)',
      marginRight: '0px',
      marginLeft: '10px',
      margin: '0px',
      padding: '10px',
    },
  },
  column: {
    borderRadius: '8px',
    padding: '4px',
    minWidth: '300px',
    maxWidth: '401px',
    flexGrow: 1,
    flexBasis: 0,
    margin: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down(1080)]: {
      width: '100%',
      // margin: '0px',
      margin: '4px 0px',
      padding: '0px',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: '-20px',
      paddingRight: '0px',
      maxWidth: '100%',
    },
    [theme.breakpoints.down(800)]: {
      width: '100%',
      maxWidth: '100%',
      margin: '4px 0px',
      // margin: '0px',
      padding: '0px',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: '-14px',
      paddingRight: '0px',
    },
  },
  noScroll: {
    overflow: 'hidden',
  },
}));

