/* Issue fixed for github issue #93,issue #168,issue #97,issue #195,issue #192,issue #221  */
export const defaultDeviceDetails = {
  device_info: {
    hardware_part_number: "",
    imei: "",
    config_number: "",
    config_part_number: "",
    app_software_number: "",
    app_id: "",
    modem_firmware: "",
    external_power: "",
    battery_status: "",
    app_sw_part_number: ""
  },
  parent_asset_id: "",
  device_alarms: [],
  device_automation: [], //Added for automation rules for issue #97
  device_snapshots:[],  //Added for snapshots for issue #221
  parent_tenant_id: "",
  device_id: "",
  // loss_communications: false,
  loss_of_comms_notify: false,
  notify_after_4hrs: false, //Added for notify key for issue #195
  repeat_24hrs: false, //Added for notify key for issue #195
  device_system_settings_id: "",
  device_type: 0, //Added device type for issue #93
  device_name: "",
  system_settings: {
    modbus_rtu_baud_rate: 0,
    time_zone_gmt: 0,
    lpmri: 0,
    static_gps: false,
    modbus_data_timeout: 0,
    lpmwui: 0,
    operation_mode: 0, //Added new key for issue #168
    contract_hour: 0, //Added new key for issue #168
    notify_pause: 0, //Added new key for issue #168
    notify_later: 0, //Added new key for issue #168
    canbus_baud_rate: 1, //Added new key for issue #192
    ip_address: "",
    time_zone: "",
    serial_port: 0,
    client_port: 0,
    modbus_rtu_timeout: 0,
    gio_location: [], //! longitude, latitude
    modbus_server_port: 0,
    modbus_tcp_timeout: 0,
    subnet_mask: "255.255.255.0",
    modbus_rtu_port_settings: 0
  },
  service_info: {
    ui: [],
    fota_v1: ""
  },
  connectivity_info: {
    signal_strength: [],
    data_used: [],
    messages_used: "",
    connection_status: false,
    purchased_limit: "",
    power_info: [],
    last_communication: "",
    uptime: ""
  },
  hardware_id: "",
  device_events: [],
  sim_info: {
    iccid: "",
    uicc_mode: "",
    imsi: ""
  },
  network_info: {
    ue_mode: "",
    ip_address: "",
    mccmnc: "",
    current_band: "",
    cell_id: "",
    network_mode: ""
  },
  device_local_devices: [],
  device_histograms: [],
  device_parameters: [],
  config_info: {
    config_name: "",
    config_number: "",
    config_last_updated: "",
    application_id: ""
  },
  subscription_info: {
    subscription_last_updated: "",
    subscription_level: 0,
    subscription_expiry: ""
  }
};
