import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setDeviceSettingsActive,
  setCurrentDeviceId,
  setSavedConfigurationedActive,
} from "features/drawer/drawerSlice";
import { RootState } from "app/rootReducer";
import { useStyles } from "./topBarStyles";
import clsx from "clsx";

export type DeviceTopBarProps = { pageName: string };

export const DeviceTopBar: React.FC<DeviceTopBarProps> = ({ pageName }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { deviceSettingsActive, deviceId, savedConfigurationActive } =
    useSelector((state: RootState) => state.DrawerSlice);

  const handleRouteToDeviceTable = () => {
    dispatch(setCurrentDeviceId(null));
    dispatch(setDeviceSettingsActive(false));
    dispatch(setSavedConfigurationedActive(false));
    history.push("/admin-devices");
  };

  const handleRouteToDeviceName = () => {
    dispatch(setDeviceSettingsActive(false));
    dispatch(setSavedConfigurationedActive(false));
    history.push(`/admin-devices/${deviceId}`);
  };

  const handleRouteToDeviceSettings = () => {
    dispatch(setSavedConfigurationedActive(false));
    history.push(`/admin-devices/${deviceId}/device-settings`);
  };

  useEffect(() => {
    if (pageName !== "" && pageName !== "Devices") {
      // adding check for empty string because this fires off AFTER the device details page loads and resets the top bar
      dispatch(setCurrentDeviceId(null));
      dispatch(setDeviceSettingsActive(false));
      dispatch(setSavedConfigurationedActive(false));
    }
  }, [pageName, dispatch]);

  return (
    <>
      {pageName === "Devices" ? (
        <>
          <div
            className={clsx({
              [classes.title]: !deviceId,
              [classes.nestedTitle]: deviceId,
            })}
            onClick={handleRouteToDeviceTable}
          >
            {pageName}
          </div>
          {deviceId && (
            <>
              <div style={{margin: "0 .2rem", fontSize: "1.7rem"}}>
                ›
              </div>
              <div
                className={clsx({
                  [classes.title]: !deviceSettingsActive,
                  [classes.nestedTitle]: deviceSettingsActive,
                })}
                onClick={handleRouteToDeviceName}
              >
                {deviceId}
              </div>
            </>
          )}
          {deviceSettingsActive && (
            <>
             <div style={{margin: "0 .2rem", fontSize: "1.7rem"}}>
                ›
              </div>
              <div
                className={clsx({
                  [classes.title]: !savedConfigurationActive,
                  [classes.nestedTitle]: savedConfigurationActive,
                })}
                onClick={handleRouteToDeviceSettings}
              >
                Device settings
              </div>
            </>
          )}
          {savedConfigurationActive && (
            <>
             <div style={{margin: "0 .2rem", fontSize: "1.7rem"}}>
                ›
              </div>
              <div
                className={clsx({
                  [classes.title]: true,
                  [classes.nestedTitle]: false,
                })}
              >
                Saved configurations
              </div>
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default DeviceTopBar;
