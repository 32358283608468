import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowY: "auto",
    },
    root1: {
      width: "100%"
    },
    lineBreak: {
      width: "100%",
      border: `1px solid ${Colors.LightGray}`,
    },
    table: {
      minWidth: "100%",
    },
    columnText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      fontSize: 14,
      // padding: 0,
      borderTop: "2px solid #DDD",
      borderBottom: "2px solid #DDD",
    },
    tableCell: {
      padding: "1rem",
      margin: 0,
      borderBottom: "2px solid #DDD",
      fontSize: 14,
    },
    companyInformation: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "80%",
      cursor: "pointer",
    },
    inputGroup: {
      width: 250,
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem",
      },
      "& input": {
        width: "100%",
        padding: ".5rem 1rem",
        border: `1px solid ${Colors.LightGray}`,
      },
      "& select": {
        width: "100%",
        padding: ".5rem 1rem",
        border: `1px solid ${Colors.LightGray}`,
        cursor: "pointer",
      },
    },
  })
);
