import React from "react";
import { ReactComponent as TanksIcon } from "assets/bt-equipments/ic_tank.svg";
import { ReactComponent as CompressorIcon } from "assets/bt-equipments/ic_compressor.svg";
import { ReactComponent as PumpsIcon } from "assets/bt-equipments/ic_pump.svg";
import { ReactComponent as VariableSpeedDriveIcon } from "assets/bt-equipments/ic_vsd.svg";
import { ReactComponent as GeneratorsIcon } from "assets/bt-equipments/ic_generator.svg";
import { ReactComponent as EnginesIcon } from "assets/bt-equipments/ic_engine.svg";
import { ReactComponent as ProcessorIcon } from "assets/bt-equipments/ic_processor.svg";
import { ReactComponent as RigLocksIcon } from "assets/bt-equipments/ic_riglock.svg";
import { ReactComponent as ChemicalInjectorsIcon } from "assets/bt-equipments/ic_chemical_injector.svg";
import { ReactComponent as OtherEquipmentIcon } from "assets/bt-equipments/ic_others.svg";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export type AssetCategoryIconsProps = { assetType: string };

export const AssetCategoryIcons: React.FC<AssetCategoryIconsProps> = ({
  assetType,
}) => {
  const classes = useStyles();
  const categoryIcon = (assetType: string) => {
    switch (assetType) {
      case "compressor":
        return <CompressorIcon className={classes.icons} />;
      case "pumps":
        return <PumpsIcon className={classes.icons} />;
      case "tanks":
        return <TanksIcon className={classes.icons} />;
      case "variable speed drive":
        return <VariableSpeedDriveIcon className={classes.icons} />;
      case "generators":
        return <GeneratorsIcon className={classes.icons} />;
      case "engines":
        return <EnginesIcon className={classes.icons} />;
      case "processors":
        return <ProcessorIcon className={classes.icons} />;
      case "rig locks":
        return <RigLocksIcon className={classes.icons} />;
      case "chemical injectors":
        return <ChemicalInjectorsIcon className={classes.icons} />;
      case "other equipment":
        return <OtherEquipmentIcon className={classes.icons} />;
    }
    return <TanksIcon className={classes.icons} />;
  };

  return <>{categoryIcon(assetType.toLowerCase())}</>;
};

export default AssetCategoryIcons;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icons: {
      fill: theme.palette.primary.light,
    },
  })
);
