/* Issue fixed for github issue #155,issue #152,issue #213,issue #289,issue #283*/
import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import ellipsis from "assets/bt-assets/more-vertical@2x.svg";
import { SuccessModal } from "components/success-modal/SuccessModal";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { setCallUsers } from "../accountSlice";
import { UserInformation } from "app/types";
import { Group } from "./types";
import clsx from "clsx";
import AccountAPI from "services/accountsAPI";
import { useAccessLevelAndRoles } from "utils/reactHooks";
import { UserPopOver } from "./UserPopOver";
import LoadSpinner from "common/LoadSpinner";
import CreateNewUserModal from "./CreateUserModal";
// import UpdateUserModal from "./UpdateUserModal";
import SuperAdminUpdateUserModal from "./SuperAdminUpdateUserModal";
import GeneralUpdateUserModal from "./GeneralUpdateUserModal";
import VerifyMobileModal from "./VerifyMobileModal"; //imported for issue #152
import EditMobileIcon from "assets/bt-assets/EditMobileIcon.svg"; //imported for issue #152
import VerifiedMobileIcon from "assets/bt-assets/VerifiedMobileIcon.png"; //imported for issue #152
import UnsuccessModal from "components/success-modal/UnsuccessfullModal"; //imported for issue #213

type AccountUsersProps = {
  isBasintrakAdmin: boolean;
  openNewUserModal: boolean;
  setOpenNewUserModal: Function;
};

// ! retrieve users information via redux and populate table here.

const AccountUsers: React.FC<AccountUsersProps> = ({
  isBasintrakAdmin,
  openNewUserModal,
  setOpenNewUserModal
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tenantUsersList, setTenantUsersList] = useState<UserInformation[]>([]);
  const [userActive, setUserActive] = useState(false);
  const [callUser, setCallUser] = useState(false);
  const [userId, setUserId] = useState("");
  const [username, setUsername] = useState("");
  const [userAccessLevel, setUserAccessLevel] = useState("");
  const [selectedUser, setSelectedUser] = useState<UserInformation>({
    phone_mobile: "",
    active: false,
    user_id: "",
    accounts: [],
    access_level: "",
    given_name: "",
    family_name: "",
    username: "",
    email: "",
    phone_office: "",
    notify_select: [],
    mobile_verified: false, //Added key for verification of mobile number for issue #152
    email_verified: false, //Added key for verification of email for issue #152
    mfa: "0", //Added key for multi-factor authentication for issue #152
    loss_of_comms_notify: true, //Added key for loss of comms for issue #155
    last_login_time: ""  //Added key for last login time for issue #283
  });

  const [openUpdateUserModal, setOpenUpdateUserModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openVerifyMobileModal, setOpenVerifyMobileModal] = useState(false); //to open verify mobile modal for issue #152
  const [pageTitle, setPageTitle] = useState(""); //to show edit or verify mobile on page title for verify mobile modal for issue #152

  const tenantId = useSelector(
    (state: RootState) => state.AccountSlice.accountInformation?.tenant_id
  );
  const { email: userEmail } = useSelector((state: RootState) => state.AppSlice.userInformation);

  const reCallUserList = useSelector(
    (state: RootState) => state.AccountSlice.callUsers
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const {
    isBasintrakSuperAdmin,
    isBasintrakEngineer,
    isTenant,
    isTenantAdmin,
    isTenantPowerUser
  } = useAccessLevelAndRoles();

  const handleUpdateUser = (user: UserInformation) => {
    setSelectedUser(user);
    setUserActive(
      user.accounts.filter((account: any) => account.tenant_id === tenantId)[0]
        ?.active || false
    );
    // setUserActive(
    //   user.accounts.filter((account: any) => account.tenant_id === tenantId)[0]
    //     ?.active || (user?.active ? user.active : false)
    // );
    setOpenUpdateUserModal(true);
  };

  // function to handle verify or edit mobile number pop up for issue #152
  const handleVerifyMobilePopUp = (
    user: UserInformation,
    pageTitles: string
  ) => {
    setPageTitle(pageTitles);
    setSelectedUser(user);
    setOpenVerifyMobileModal(true);
  };

  const handleModalClose = () => {
    setOpenNewUserModal(false);
    setOpenUpdateUserModal(false);
    setOpenVerifyMobileModal(false); //for closing the verify mobile modal for issue #152
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userMenuClickHandler = useCallback(
    (
      userAccount: any,
      userId: string,
      username: string,
      accessLevel: string
    ) => (event: any) => {
      setAnchorEl(event.currentTarget);
      setUserActive(userAccount[0]?.active || userAccount.active);
      setUserId(userId);
      setUsername(username);
      setUserAccessLevel(accessLevel);
    },
    []
  );

  const displayRoles = (userAccounts: any) => {
    const selectedTenant = userAccounts.filter(
      (users: any) => users.tenant_id === tenantId
    )[0];

    return selectedTenant?.roles && selectedTenant?.roles !== 0 ? (
      <p className={classes.role}>{selectedTenant.roles.join("; ")}</p>
    ) : (
      <p className={classes.role}>No assigned role</p>
    );
  };

  const displayGroups = (userAccounts: any) => {
    const selectedTenant = userAccounts.filter(
      (users: any) => users.tenant_id === tenantId
    )[0];

    return selectedTenant?.groups && selectedTenant?.groups.length !== 0 ? (
      <p className={classes.role}>
        {selectedTenant.groups
          .map((group: Group) => group.asset_group_name)
          .join("; ")}
      </p>
    ) : (
      <p className={classes.role}>No assigned group(s)</p>
    );
  };

  useEffect(() => {
    const getUserList = async () => {
      try {
        const userList = await AccountAPI.getUserList(tenantId);
        setTenantUsersList(userList);
        setLoading(false);
      } catch (error) {
        alert(error);
        console.log(error);
        setLoading(false);
      }
    };

    if (reCallUserList) {
      getUserList();
      dispatch(setCallUsers(false));
    }

    getUserList();
  }, [tenantId, reCallUserList, dispatch]);

  useEffect(() => {
    const getUserList = async () => {
      const userList = await AccountAPI.getUserList(tenantId);
      setTenantUsersList(userList);
    };

    if (callUser) {
      getUserList();
      setCallUser(!callUser);
    }
  }, [tenantId, callUser]);

  return loading ? (
    <LoadSpinner size="90vh" center={true} />
  ) : (
    <Grid container>
      <Grid item xs={12} className={classes.root}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.columnText}>Name</TableCell>
                <TableCell className={classes.columnText}>Role</TableCell>
                <TableCell className={classes.columnText}>
                  Access group(s)
                </TableCell>
                <TableCell className={classes.columnText}>Email</TableCell>
                {/* aligning text centrally for issue #152 */}
                <TableCell
                  className={classes.columnText}
                  style={{ textAlign: "center" }}
                >
                  Contact number(s)
                </TableCell>
                <TableCell className={classes.columnText}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Added condition to filter out @basintrak.com user for issue #289 */}
              {tenantUsersList
                .filter((account: any) => {
                  const domainRegex = /@(basintrak\.com|rulotech\.com)$/;
                  return !domainRegex.test(account.email) || domainRegex.test(userEmail);
                })
                .map((user: UserInformation, index: number) => {
                  const notSuperAdmin =
                    user.access_level === "admin" && !isBasintrakSuperAdmin;
                  const notTenantAdmin =
                    isTenant &&
                    user.access_level !== "admin" &&
                    user.accounts.filter(
                      (account: any) => account.tenant_id === tenantId
                    )[0]?.roles[0] === "admin" &&
                    !isTenantAdmin;
                  const notTenantPowerUser =
                    isTenant &&
                    user.access_level !== "admin" &&
                    ["admin", "power_user"].includes(
                      user.accounts.filter(
                        (account: any) => account.tenant_id === tenantId
                      )[0]?.roles[0]
                    ) &&
                    !(isTenantPowerUser || isTenantAdmin);

                  return (
                    <TableRow
                      key={index}
                      style={{
                        cursor: "pointer",
                        position: "relative"
                      }}
                      className={clsx({
                        [classes.notPermitted]:
                          notSuperAdmin || notTenantAdmin || notTenantPowerUser
                      })}
                    >
                      {/* this will have id or some sort of identifier attached to cell to know which company it is targetting */}
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => {
                          handleUpdateUser(user);
                        }}
                      >
                        <div
                          // ! onClick here, pop modal that will update user via AccountAPI.
                          className={classes.companyInformation}
                        >
                          {/* Adding condition to show dot only if the user has verified his email for issue #152 */}
                          {user?.email_verified ? (
                            <div
                              className={clsx(classes.dot, {
                                [classes.activeDot]:
                                  user.accounts.filter(
                                    (account: any) =>
                                      account.tenant_id === tenantId
                                  )[0]?.active || false,
                                [classes.inactiveDot]:
                                  !user.accounts.filter(
                                    (account: any) =>
                                      account.tenant_id === tenantId
                                  )[0]?.active || false
                              })}
                            ></div>
                          ) : (
                            <div></div>
                          )}
                          <p className={classes.userName}>
                            {user.given_name} {user.family_name}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => {
                          handleUpdateUser(user);
                        }}
                      >
                        {displayRoles(user.accounts)}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => {
                          handleUpdateUser(user);
                        }}
                      >
                        {displayGroups(user.accounts)}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        onClick={() => {
                          handleUpdateUser(user);
                        }}
                      >
                        <p className={classes.userEmail}>{user.email}</p>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {!user.phone_mobile && !user.phone_office ? (
                          "No numbers on record"
                        ) : (
                          // Adding verified and edit icons for issue #152
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            {/* Adding condition to show the mobile verified and edit icons only when user has email verified for issue #152 */}
                            {user?.mobile_verified && user?.email_verified ? (
                              <img
                                src={VerifiedMobileIcon}
                                style={{ height: "20px" }}
                                alt=""
                              />
                            ) : (
                              <div></div>
                            )}
                            <p className={classes.userPhone}>
                              {user.phone_mobile
                                ? `${user.phone_mobile}`
                                : "Mobile not provided"}{" "}
                            </p>
                            {/* Adding condition to show the icon only when email is verified for the user for issue #152 */}
                            {user?.email_verified ? (
                              <img
                                src={EditMobileIcon}
                                style={{ height: "20px" }}
                                onClick={() => {
                                  handleVerifyMobilePopUp(
                                    user,
                                    "Edit mobile number"
                                  );
                                }}
                                alt=""
                              />
                            ) : (
                              <div></div>
                            )}
                          </div>
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <div
                          style={{
                            position: "relative",
                            zIndex: 1,
                            display: "flex",
                            justifyContent: "center"
                          }}
                          onClick={userMenuClickHandler(
                            user.accounts.filter(
                              (account: any) => account.tenant_id === tenantId
                            ),
                            user.user_id,
                            user.username,
                            user.access_level
                          )}
                        >
                          <img src={ellipsis} alt="hamburger menu" />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <UserPopOver
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            tenantId={tenantId}
            userActive={userActive}
            userId={userId}
            username={username}
            userAccessLevel={userAccessLevel}
            setCallUser={setCallUser}
          />
          <CreateNewUserModal
            open={openNewUserModal}
            handleClose={handleModalClose}
          />
          {/* to show verify mobile modal for issue #152 */}
          {openVerifyMobileModal && (
            <VerifyMobileModal
              open={openVerifyMobileModal}
              handleClose={handleModalClose}
              user={selectedUser}
              pageTitle={pageTitle}
            />
          )}
          {openUpdateUserModal && !isBasintrakAdmin && (
            <GeneralUpdateUserModal
              open={openUpdateUserModal}
              handleClose={handleModalClose}
              selectedUser={selectedUser}
              userActive={userActive}
              setCallUser={setCallUser}
            />
          )}
          {isBasintrakAdmin && openUpdateUserModal && (
            <SuperAdminUpdateUserModal
              open={openUpdateUserModal}
              handleClose={handleModalClose}
              selectedUser={selectedUser}
              userActive={userActive}
              setCallUser={setCallUser}
            />
          )}
          <SuccessModal />
          {/* imported for issue #213 */}
          <UnsuccessModal />
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default AccountUsers;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowY: "auto"
    },
    lineBreak: {
      width: "100%",
      border: `1px solid ${Colors.LightGray}`
    },
    buttonContainer: {
      margin: "2rem 0",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end"
    },
    createUserButton: {
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.primary.light,
      padding: "0 1rem",
      color: Colors.White,
      height: 30,
      cursor: "pointer",
      outline: "none"
    },
    table: {
      minWidth: "100%",
      marginTop: "1rem"
    },
    columnText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      // padding: 0,
      borderTop: "2px solid #DDD",
      borderBottom: "2px solid #DDD"
    },
    tableCell: {
      padding: "4px 16px",
      margin: 0,
      borderBottom: "2px solid #DDD",
      fontSize: 14
    },
    companyInformation: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "80%",
      cursor: "pointer"
    },
    userName: {
      position: "relative",
      right: "9px",
      color: theme.palette.primary.light,
      fontSize: 14
    },
    role: {
      color: Colors.Black,
      fontSize: 14
    },
    accessGroup: {
      color: Colors.Black,
      fontSize: 14
    },
    userEmail: {
      color: theme.palette.primary.light
    },
    userPhone: {
      color: Colors.Black,
      padding: "0 1rem"
    },
    comment: {
      height: "1.8rem",
      color: Colors.Gray,
      cursor: "pointer"
    },
    ellipsis: {
      cursor: "pointer",
      color: theme.palette.primary.light,
      height: "2rem",
      // margin: "0 0 0 2rem",
      zIndex: 100
    },
    dot: {
      backgroundColor: "LightGreen",
      height: ".6rem",
      width: ".6rem",
      borderRadius: "50%",
      position: "relative",
      right: "15px"
    },
    activeDot: {
      backgroundColor: Colors.Green
    },
    inactiveDot: {
      backgroundColor: Colors.LightGray
    },
    notPermitted: {
      opacity: 0.5,
      pointerEvents: "none"
    }
  })
);
