import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";

const DefaultDisplay = () => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.pathHeader}></div>
      <div className={classes.assetTemplate}></div>
      <div className={classes.buttonContainer}></div>
    </div>
  );
};

export default DefaultDisplay;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pathHeader: {
      backgroundColor: Colors.LightGray,
      padding: ".5rem",
      width: "100%",
      height: 30,
    },
    assetTemplate: {
      height: 122,
      border: `2px solid ${Colors.LightGray}`,
      borderRight: "none",
      borderLeft: "none",
    },
    buttonContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      margin: "1rem 0",
    },
  })
);
