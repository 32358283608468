/* Issue fixed for github issue #143,issue #294 */
import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Colors } from "common/Colors";
import clsx from "clsx";
import AccountDetails from "./AccountDetails";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import AccountUsers from "./AccountUsers";
import SoftwareUpdates from "./SoftwareUpdates";
import AccountHistory from "./AccountHistory";
import DisplaySettings from "./DisplaySettings";
import APITokens from "./APITokens"; //importing file for issue #143
import Button from "components/common-buttons/Button";
import Connector from "./Connector";  //importing file for issue #294

type AccountsOverviewProps = { handleCancel: Function };

const AccountsOverview: React.FC<AccountsOverviewProps> = ({
  handleCancel
}) => {
  const classes = useStyles();
  const [buttonState, setButtonState] = useState("Account details");
  const [openNewUserModal, setOpenNewUserModal] = useState(false);
  const [openNewAPITokenModal, setOpenNewAPITokenModal] = useState(false); //Added variable for opening and closing of modal for issue #143
  const [openNewConnectorModal, setOpenNewConnectorModal] = useState(false);  //Added variable for opening and closing of connector modal for issue #294

  const { access_level } = useSelector(
    (state: RootState) => state.AppSlice.userInformation
  );

  const { role } = useSelector(
    (state: RootState) => state.AccountSlice.selectedAccount
  );

  const isBasintrakAdmin = access_level === "admin";
  const isBasintrakEngineer = access_level === "engineer";
  const isTenant = access_level === "general";

  const isPowerUser = access_level === "general" && role === "power_user";

  const displaySelectedPage = (selectedPage: string) => {
    switch (selectedPage) {
      case "Account details":
        return <AccountDetails handleCancel={handleCancel} />;
      case "Display settings":
        return <DisplaySettings />;
      case "Users":
        return (
          <AccountUsers
            openNewUserModal={openNewUserModal}
            setOpenNewUserModal={setOpenNewUserModal}
            isBasintrakAdmin={isBasintrakAdmin}
          />
        );
      case "History":
        return <AccountHistory />;
      case "Software updates":
        return isBasintrakAdmin ? <SoftwareUpdates /> : null;
      // Added new case api tokens for issue #143
      case "API Tokens":
        return (
          <APITokens
            openNewAPITokenModal={openNewAPITokenModal}
            setOpenNewAPITokenModal={setOpenNewAPITokenModal}
            isBasintrakAdmin={isBasintrakAdmin}
          />
        );
      // Added new case  connector for issue #294
      case "Connector":
        return (
          <Connector
            openNewConnectorModal={openNewConnectorModal}
            setOpenNewConnectorModal={setOpenNewConnectorModal}
            isBasintrakAdmin={isBasintrakAdmin}
          />
        );
      default:
        return <AccountDetails handleCancel={handleCancel} />;
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.buttonContainer}>
        <div>
          <button
            className={clsx(classes.button, {
              [classes.isActive]: buttonState === "Account details"
            })}
            type="button"
            onClick={() => setButtonState("Account details")}
          >
            Account details
          </button>
          {isPowerUser ? null : (
            <button
              className={clsx(classes.button, {
                [classes.isActive]: buttonState === "Display settings"
              })}
              type="button"
              onClick={() => setButtonState("Display settings")}
            >
              Display settings
            </button>
          )}
          <button
            className={clsx(classes.button, {
              [classes.isActive]: buttonState === "Users"
            })}
            type="button"
            onClick={() => setButtonState("Users")}
          >
            Users
          </button>
          {/* <button
            className={clsx(classes.button, {
              [classes.isActive]: buttonState === "History",
            })}
            type="button"
            onClick={() => setButtonState("History")}
          >
            History
          </button> */}
          {isBasintrakAdmin ? (
            <button
              className={clsx(classes.button, {
                [classes.isActive]: buttonState === "Software updates"
              })}
              type="button"
              onClick={() => setButtonState("Software updates")}
            >
              Software updates
            </button>
          ) : null}
          {/* Added new buttonto redirect to API tokens file for issue #143 */}
          <button
            className={clsx(classes.button, {
              [classes.isActive]: buttonState === "API Tokens"
            })}
            type="button"
            onClick={() => setButtonState("API Tokens")}
          >
            API Tokens
          </button>
          {/* Added new buttonto redirect to  connector file for issue #294 */}
          <button
            className={clsx(classes.button, {
              [classes.isActive]: buttonState === "Connector"
            })}
            type="button"
            onClick={() => setButtonState("Connector")}
          >
            Connector
          </button>
        </div>
        {buttonState === "Users" && (
          <div className={classes.createButton}>
            <Button
              type="button"
              color="defaultTheme"
              onClick={() => {
                setOpenNewUserModal(true);
              }}
            >
              + User
            </Button>
          </div>

        )}
        {/* New button to add token for issue #143 */}
        {buttonState === "API Tokens" && (
          <div className={classes.createButton}>
            <Button
              type="button"
              color="defaultTheme"
              onClick={() => {
                setOpenNewAPITokenModal(true);
              }}
            >
              + Token
            </Button>
          </div>

        )}
        {/* New button to add  connector for issue #294 */}
        {buttonState === "Connector" && (
          <div className={classes.createButton}>

            <Button
              type="button"
              color="defaultTheme"
              onClick={() => {
                setOpenNewConnectorModal(true);
              }}
            >
              + Connector
            </Button>
          </div>

        )}
      </Grid>
      {displaySelectedPage(buttonState)}
    </Grid>
  );
};

export default AccountsOverview;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      height: 30,
      marginBottom: 20,
      [theme.breakpoints.down(800)]: {
        marginLeft: 10,
      }
    },
    createButton: {
      backgroundColor: Colors.White,
      color: theme.palette.primary.light,
      // border: `1px solid ${theme.palette.primary.light}`,
      cursor: "pointer",
      padding: "0 0",
      outline: "none",
      height: 30,
      whiteSpace: "nowrap",
      '&:hover': {
        opacity: 0.8,
      },
    },
    button: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: Colors.White,
      cursor: "pointer",
      padding: "0 1rem",
      outline: "none",
      height: 30,
      "&:hover": {
        opacity: 0.8
      }
    },
    isActive: {
      backgroundColor: theme.palette.primary.light,
      color: Colors.White,
      "&:hover": {
        opacity: 1
      },
      cursor: "default"
    }
  })
);
