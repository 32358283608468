/*Issue fixed for github issue #66,issue #204 */
import React, { useState, useEffect, useCallback } from "react";
import companyLogo from "custom/companyLogo.png"; //imported rulotech logo for issue #66
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { TextField, InputAdornment } from "@material-ui/core";
import PasswordValidation from "utils/validations/PasswordValidation";
import passwordKey from "assets/bt-logins/login_password.svg";
import passwordKeyError from "assets/bt-logins/login_password_red.svg";
import { Auth } from "aws-amplify";
import clsx from "clsx";
import { Colors } from "common/Colors";
import { companyLogoHeight, companyLogoWidth } from "custom/customizations";

// import "./../custom-sign-in/custom-sign-in.scss";

const PasswordRequired = ({ setLogType, userObj }: any) => {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  //   const [loggingIn, setLoggingIn] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const checkPasswordValid = (validPassword: boolean) => {
    setIsPasswordValid(validPassword);
  };

  const setNewPassword = useCallback(async () => {
    if (!isPasswordValid) return;

    try {
      await Auth.completeNewPassword(userObj, password);

      setTimeout(() => {
        setLogType("logIn");
      }, 1000);
    } catch (error) {
      console.log("error", error);
    }
  }, [userObj, password, isPasswordValid, setLogType]);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        // console.log("Enter key was pressed.");
        setNewPassword();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [setNewPassword]);

  return (
    <div>
      {/* Changed img src to companyLogo and added css class rulotechlog for styling for issue #66 */}
      <img src={companyLogo} alt="" className={classes.companylogo} />
      <form className={classes.formStyles}>
        <div>Please take a moment to create a new password.</div>
        <div>
          <span>
            <div className={classes.inputName}>
              <span>
                New password <span style={{ color: "#ED254E" }}>*</span>
              </span>
            </div>
            <TextField
              className={clsx({
                [classes.valid]: isPasswordValid,
                [classes.invalid]: !isPasswordValid
              })}
              type="password"
              classes={{
                root: classes.input
              }}
              value={password}
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setPassword(e.target.value)}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={!isPasswordValid ? passwordKeyError : passwordKey}
                      alt=""
                      style={{
                        margin: "0 1rem 0 0",
                        width: "60%"
                      }}
                    />
                  </InputAdornment>
                )
              }}
            />
          </span>
        </div>
        <div>
          <PasswordValidation
            password={password}
            setIsPasswordValid={checkPasswordValid}
          />
        </div>
        <div className={classes.changePasswordContainer}>
          <button
            type="button"
            className={classes.setNewPassword}
            onClick={setNewPassword}
          >
            Change password
          </button>
        </div>
      </form>
    </div>
  );
};

export default PasswordRequired;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStyles: {
      display: "flex",
      flexDirection: "column",
      width: "23em",
      minHeight: "25em",
      justifyContent: "space-evenly",
      marginLeft: "30%"
    },
    inputName: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    warnings: {
      fontSize: "12px",
      color: "#ED254E",
      position: "relative",
      top: "5px"
    },
    input: {
      display: "flex",
      justifyContent: "center",
      borderRadius: 0,
      color: "white",
      height: 40,
      width: "95%",
      padding: "0 0 0 5%",
      border: "solid 1px #DDDDDD"
    },
    valid: {
      border: "solid 1px #DDDDDD"
    },
    invalid: {
      border: "solid 1px #ED254D"
    },
    forgotPassword: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: Colors.MainColor, //updated color from #6D1919 to #253746 for issue #204
      fontSize: "14px",
      cursor: "pointer",
      border: "none",
      background: "none",
      "&:hover": {
        opacity: ".8"
      },
      "&:focus": { outline: 0 }
    },
    backToLogin: {
      display: "flex",
      flexDirection: "row",
      alignContent: "flex-start",
      alignItems: "center",
      padding: 0,
      border: "none",
      background: "none",
      color: Colors.MainColor, //changed color to slate for issue #66
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": {
        opacity: ".8"
      },
      "&:focus": { outline: 0 }
    },
    changePasswordContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end"
    },
    setNewPassword: {
      backgroundColor: Colors.MainColor, //changed color to slate for issue #66
      fontSize: "14px",
      width: "70%",
      fontWeight: 600,
      color: "#fff",
      border: "solid 1px #253746", //changed color to slate for issue #66
      borderRadius: 0,
      height: "39px",
      "&:hover": {
        cursor: "pointer",
        opacity: ".8"
      }
    },
    //Added css class companylogo for styling rulotech logo for issue #66
    companylogo: {
      margin: "0 0 0 8.3rem",
      width: companyLogoWidth,
      height: companyLogoHeight,
      [`@media (max-width: 800px)`]: {
        margin: "0 0 0 7rem",
        width: "50%"
      }
    }
  })
);
