/*Issue fixed for github issue #66,issue #204 */
import React, { useState, useEffect } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import { SignUpProps } from "./_types";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import envelopeIcon from "assets/bt-logins/login_email.svg";
import usernameIcon from "assets/bt-logins/login_username.svg";
import phoneIcon from "assets/bt-logins/Login_phone.svg";
import passwordIcon from "assets/bt-logins/Login_pwd.svg";
import { validateEmail } from "utils/helpers";
// import { Auth } from "aws-amplify";
import clsx from "clsx";
import arrowLeft from "assets/bt-logins/arrow-left-circle.svg";
import { Colors } from "common/Colors";

const SignUp: React.FC<SignUpProps> = ({ setLogType }: SignUpProps) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);

  useEffect(() => {
    if (email.length === 0 || validateEmail(email)) {
      setEmailValid(true);
    }
  }, [email]);

  return (
    <div style={{ height: "100%" }}>
      {/* //! make validations for all inputs later on */}
      <form className={classes.formStyles} noValidate autoComplete="off">
        <button
          type="button"
          className={classes.backToLogin}
          onClick={() => setLogType("logIn")}
        >
          <img
            src={arrowLeft}
            alt=""
            style={{
              margin: "0 1rem 0 0"
            }}
          />
          Back to login
        </button>
        <div className={classes.createAccount}>Create account: Step 1</div>
        <div className={classes.inputField}>
          <div className={classes.inputName}>
            <span>
              Full name <span style={{ color: "#ED254E" }}>*</span>
            </span>
            <span className={classes.warnings}>
              {!emailValid ? "Not a valid email" : null}
            </span>
          </div>
          <TextField
            className={clsx({
              [classes.valid]: emailValid,
              [classes.invalid]: !emailValid
            })}
            classes={{
              root: classes.input
            }}
            value={email}
            placeholder="First name, Last name"
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setEmail(e.target.value)}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={usernameIcon}
                    alt=""
                    style={{
                      margin: "0 1rem 0 0"
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className={classes.inputField}>
          <div className={classes.inputName}>
            <span>
              Phone number <span style={{ color: "#ED254E" }}>*</span>
            </span>
            <span className={classes.warnings}>
              {!emailValid ? "Not a valid email" : null}
            </span>
          </div>
          <TextField
            className={clsx({
              [classes.valid]: emailValid,
              [classes.invalid]: !emailValid
            })}
            classes={{
              root: classes.input
            }}
            value={email}
            placeholder="Enter"
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setEmail(e.target.value)}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={phoneIcon}
                    alt=""
                    style={{
                      margin: "0 1rem 0 0"
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className={classes.inputField}>
          <div className={classes.inputName}>
            <span>
              Email <span style={{ color: "#ED254E" }}>*</span>
            </span>
            <span className={classes.warnings}>
              {!emailValid ? "Not a valid email" : null}
            </span>
          </div>
          <TextField
            className={clsx({
              [classes.valid]: emailValid,
              [classes.invalid]: !emailValid
            })}
            classes={{
              root: classes.input
            }}
            value={email}
            placeholder="Enter"
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setEmail(e.target.value)}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={envelopeIcon}
                    alt=""
                    style={{
                      margin: "0 1rem 0 0"
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className={classes.inputField}>
          <div className={classes.inputName}>
            <span>
              Create password <span style={{ color: "#ED254E" }}>*</span>
            </span>
            <span className={classes.warnings}>
              {!emailValid ? "Not a valid email" : null}
            </span>
          </div>
          <TextField
            className={clsx({
              [classes.valid]: emailValid,
              [classes.invalid]: !emailValid
            })}
            classes={{
              root: classes.input
            }}
            value={email}
            placeholder="Enter"
            onChange={(
              e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setEmail(e.target.value)}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={passwordIcon}
                    alt=""
                    style={{
                      margin: "0 1rem 0 0"
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className={classes.proceedBtnContainer}>
          <button
            type="button"
            className={classes.proceedBtn}
            // ! here once validations check, proceed to next sign up
            onClick={() => {
              setLogType("createAccountStepTwo");
            }}
          >
            Proceed
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignUp;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStyles: {
      display: "flex",
      flexDirection: "column",
      width: "23em",
      height: "100%",
      justifyContent: "center",
      marginLeft: "30%"
    },
    createAccount: {
      fontSize: "24px",
      margin: "2rem 0"
    },
    inputName: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    inputField: { margin: "2rem 0" },
    warnings: {
      fontSize: "12px",
      color: "#ED254E",
      position: "relative",
      top: "5px"
    },
    input: {
      display: "flex",
      justifyContent: "center",
      borderRadius: 0,
      color: "white",
      height: 40,
      width: "95%",
      padding: "0 0 0 5%",
      border: "solid 1px #DDDDDD"
    },
    valid: {
      border: "solid 1px #DDDDDD"
    },
    invalid: {
      border: "solid 1px #ED254D"
    },
    forgotPassword: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: Colors.MainColor, //updated color from #6D1919 to #253746 for issue #204
      fontSize: "14px",
      cursor: "pointer",
      border: "none",
      background: "none",
      "&:hover": {
        opacity: ".8"
      },
      "&:focus": { outline: 0 }
    },
    backToLogin: {
      display: "flex",
      flexDirection: "row",
      alignContent: "flex-start",
      alignItems: "center",
      margin: "1rem 0",
      padding: 0,
      border: "none",
      background: "none",
      color: Colors.MainColor, //changed color to slate for issue #66
      fontSize: "14px",
      cursor: "pointer",
      "&:hover": {
        opacity: ".8"
      },
      "&:focus": { outline: 0 }
    },
    proceedBtnContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end"
    },
    proceedBtn: {
      backgroundColor: Colors.MainColor, //changed color to slate for issue #66
      width: "50%",
      fontSize: "14px",
      fontWeight: 600,
      color: "#fff",
      border: "none",
      borderRadius: 0,
      height: "39px",
      "&:hover": {
        cursor: "pointer",
        opacity: ".8"
      }
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    }
  })
);
