/* Issue fixed for github issue #24,issue #218,issue #256,issue #122  */
import React, { useState, useEffect, useCallback } from "react";
import { useStyles } from "./styles";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setRecallDeviceList } from "features/devices-page/devices-table/deviceTableSlice";
import { setDeviceConfigurations } from "./device-settings/deviceSettingsTabSlice";
import { devicesAPI } from "services/devicesAPI";
import { RootState } from "app/rootReducer";
import DevicesTable from "features/devices-page/devices-table/DevicesTable";
import AddDeviceModal from "features/devices-page/device-components/device-modal/AddDeviceModal";
import { systemSettingsAPI } from "services/systemSettingsAPI";
import { getSubscriptionLabel } from "assets/mappings/subscriptionOptions";
import { fromUnixTime, format } from "date-fns";
import { customDateFormatUnix } from "utils/helpers";
import Button from "components/common-buttons/Button";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as ExportIcon } from "assets/bt-assets/Group 55.svg";
import LoadSpinner from "common/LoadSpinner";
import InputBase from "@material-ui/core/InputBase";  //Added for search functionality for issue #122
import search from "assets/bt-assets/header_search_w.svg";  //Added for search functionality for issue #122

export interface DevicesProps { }

// type ChangeEvent = React.ChangeEvent<HTMLInputElement>;
// type PressEvent = React.KeyboardEvent<HTMLInputElement>;

const Devices: React.FC<DevicesProps> = props => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [deviceList, setDeviceList] = useState<any>([]);
  const [showAddDeviceModal, setShowAddDeviceModal] = useState(false);
  const [searchVal, setSearchVal] = useState(""); //Added for search functionality for issue #122
  const [mainDeviceList, setMainDeviceList] = useState<any>([]);  //Added for search functionality for issue #122
  // const [userAccounts, setUserAccounts] = useState<
  //   { name: string; id: string }[]
  // >([]);
  const [loading, setLoading] = useState(true);

  const { accountId, accountName } = useSelector(
    (state: RootState) => state.AppSlice.selectedAccount
  );
  const { recallDeviceList } = useSelector(
    (state: RootState) => state.DeviceTableSlice
  );
  // function to handle the search functionality for issue #122
  const handleSearchClick = () => {
    const productList = [...mainDeviceList];
    if (searchVal === "") { setDeviceList(mainDeviceList); return; }
    const filterBySearch = productList.filter((item: any) => {
      if (item?.device_id.toLowerCase()
        .includes(searchVal.toLowerCase())) { return item; }
      if (item?.device_name.toLowerCase()
        .includes(searchVal.toLowerCase())) { return item; }
    })
    setDeviceList(filterBySearch);
  };

  //to handle search functionality for issue #122
  useEffect(() => {
    const productList = [...mainDeviceList];
    if (searchVal === "") { setDeviceList(mainDeviceList); return; }
    const filterBySearch = productList.filter((item: any) => {
      if (item?.device_id.toLowerCase()
        .includes(searchVal.toLowerCase())) { return item; }
      if (item?.device_name.toLowerCase()
        .includes(searchVal.toLowerCase())) { return item; }
    })
    setDeviceList(filterBySearch);
  }, [searchVal])

  const grabTenantsDeviceList = useCallback(async (tenant_id: string) => {
    var sortedRes = [];
    try {
      if (!tenant_id) {
        return;
      }
      let response = await devicesAPI.retrieveListOfDevices(tenant_id);
      // sorting the parent nodes before mixing with gateway nodes so that gateway nodes don't show up under different parent nodes for issue #256
      //Added function to sort the device list for issue #218
      response.sort((a: any, b: any) => {
        if (a.device_id < b.device_id) return -1;
        return a.device_id > b.device_id ? 1 : 0;
      });
      //Added for loop for creating an array for parent and child data to be shown in the table. github issue #94
      for (let res of response) {
        if (res.node_connected == true) {
          sortedRes.push(res);
          for (let nodes of res.node) {
            sortedRes.push(nodes);
          }
        } else {
          sortedRes.push(res);
        }
      }
      setDeviceList(sortedRes);
      setMainDeviceList(sortedRes);
      setLoading(false);
    } catch (error) {
      console.log(error);
      alert(error);
      setDeviceList([]);
    }
  }, []);

  // const retrieveDeviceConfigurations = useCallback(
  //   async (tenant_id: string) => {
  //     const deviceConfigurationsList =
  //       await systemSettingsAPI.retrieveDeviceConfigurationsList(tenant_id);

  //     dispatch(setDeviceConfigurations(deviceConfigurationsList));
  //   },

  //   [dispatch]
  // );

  useEffect(() => {
    if (accountId) {
      setLoading(true);
      grabTenantsDeviceList(accountId);
      // retrieveDeviceConfigurations(accountId); // !grabs all known device configurations
    }
  }, [
    accountId,
    grabTenantsDeviceList
    // ,retrieveDeviceConfigurations
  ]);

  useEffect(() => {
    if (recallDeviceList) {
      grabTenantsDeviceList(accountId);
      dispatch(setRecallDeviceList(false));
    }
  }, [recallDeviceList, grabTenantsDeviceList, dispatch, accountId]);

  const closeAddDeviceModal = () => {
    setShowAddDeviceModal(false);
  };

  // ! this needs to be reworked to accomodate the new response
  const handleCsvDownload = async () => {
    try {
      const deviceTest = deviceList.map((device: any) => {
        return {
          serial_number: device.device_id,
          device_name: device.device_name,
          last_communication: `${format(
            fromUnixTime(device.connectivity_info.last_communication),
            "MM/dd/yyyy | h:mm a"
          )}`,
          signal: device?.connectivity_info?.signal_strength[0]
            ? `${device?.connectivity_info?.signal_strength[0]} dBm`
            : undefined,
          power: device?.connectivity_info?.power_info[1]
            ? `${device?.connectivity_info?.power_info[1]}%`
            : undefined,
          data_used: device?.connectivity_info?.data_used[1]
            ? `${device?.connectivity_info?.data_used[1]}%`
            : undefined,
          subscription_level: `${getSubscriptionLabel(
            device?.subscription_info?.subscription_level || 0
          )}`,
          valid_till: `${customDateFormatUnix(
            device?.subscription_info?.subscription_expiry,
            // changed format from "MMMM dd,yyyy" to MM/dd/yyyy for web view issue #24
            "MM/dd/yyyy"
          )}`
        };
      });
      const csvKeys = Object.keys(deviceTest[0]);
      const csvValues = deviceTest.map((dataObject: any) => {
        return Object.values(dataObject);
      });

      const csvData = [csvKeys, ...csvValues]
        .map((indexArray: any) => indexArray.join(","))
        .join("\n");

      const csvContent = `data:text/csv;charset=utf-8, ${csvData}`;
      const encodedUri = encodeURI(csvContent);
      const linkedToDownload = document.createElement("a");

      linkedToDownload.setAttribute("href", encodedUri);
      linkedToDownload.setAttribute(
        "download",
        `${accountName}_Device_Information.csv`
      );
      document.body.appendChild(linkedToDownload);
      linkedToDownload.click();
    } catch (error) {
      console.error(error);
      alert(error);
    }
  };

  if (loading) {
    return <LoadSpinner center={true} size={"90vh"} />;
  }

  return (
    <div className={styles.deviceContainer}>
      <div style={{ display: "flex" }}>
        {/* Search functionality for issue #122 */}
        <Grid style={{ display: "flex" }}>
          <InputBase
            classes={{
              root: styles.inputRoot,
              input: styles.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            placeholder="Search devices by keyword(s)"
            value={searchVal}
            onChange={e => setSearchVal(e.target.value)}
          />
          <div className={styles.searchbutton}>
            <img src={search} alt="" onClick={handleSearchClick} />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          className={styles.buttonContainer}
          style={{ justifyContent: "flex-end" }}
        >
          <Button type="button" color="white" onClick={handleCsvDownload}>
            {/* <SvgIcon className={styles.exportIcon}> */}
            <ExportIcon className={styles.exportIcon} />
            {/* </SvgIcon> */}
            {/* <img
            src={ExportIcon}
            alt="Export icon"
            style={{ marginRight: ".5rem" }}
          /> */}
            Export to excel
          </Button>
          <Button
            type="button"
            color="defaultTheme"
            onClick={() => setShowAddDeviceModal(true)}
          >
            + Add new device
          </Button>
          {/* </div> */}
        </Grid>
      </div>
      <Grid item xs={12}>
        <DevicesTable deviceList={deviceList} />
      </Grid>
      <AddDeviceModal
        // accountName={accountName}
        // accountId={accountId}
        showAddDeviceModal={showAddDeviceModal}
        closeAddDeviceModal={closeAddDeviceModal}
      />
    </div>
  );
};

export default Devices;
