import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deviceContainer: {
      marginTop: "1rem",
    },
    flex: {
      display: "flex",
      alignItems: "center",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      // alignItems: "space-between",
      justifyContent: "space-between",
    },
    select: {
      border: `1px solid ${Colors.LightGray}`,
      padding: ".5rem .5rem .5rem 1rem",
      color: theme.palette.primary.light,
      fontWeight: "bold",
      "& option": {
        color: theme.palette.primary.light,
        fontWeight: "bold",
      },
      cursor: "pointer",
    },
    // Added css class for search functionality for issue #122
    inputRoot: {
      border: "1px solid #DDDDDD",
      borderRadius: 0,
      width: 321,
      backgroundColor: "#FFFFFF",
      height: 30
    },
    // Added css class for search functionality for issue #122
    inputInput: {
      paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
      width: 321,
      height: 30
    },
    // Added css class for search functionality for issue #122
    searchbutton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: Colors.White,
      cursor: "pointer",
      backgroundColor: theme.palette.primary.light,
      width: 40
    },
    // Added css class for search functionality for issue #122
    faIcon: {
      color: theme.palette.primary.light,
      cursor: "pointer",
      margin: "0 0 0 1rem",
    },
    exportButton: {
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: "#fff",
      // width: 160,
      color: theme.palette.primary.light,
      // fontWeight: 600,
      padding: ".5rem 1rem",
      marginRight: "1rem",
      cursor: "pointer",
    },
    addButton: {
      color: "white",
      backgroundColor: theme.palette.primary.light,
      // width: 160,
      border: "none",
      // fontWeight: 600,
      padding: ".5rem 1rem",
      cursor: "pointer",
    },
    exportIcon: {
      stroke: theme.palette.primary.light,
      marginRight: ".5rem",
    },
  })
);

export { useStyles };
