export const assetCategoryStaticList = [
  {
    name: "Select category type...",
    value: "",
  },
  {
    name: "Compressor",
    value: "compressor",
  },
  {
    name: "Pumps",
    value: "pumps",
  },
  {
    name: "Tanks",
    value: "tanks",
  },
  {
    name: "Variable Speed Drive",
    value: "variable speed drive",
  },
  {
    name: "Generators",
    value: "generators",
  },
  {
    name: "Engines",
    value: "engines",
  },
  {
    name: "Processors",
    value: "processors",
  },
  {
    name: "Rig locks",
    value: "rig locks",
  },
  {
    name: "Chemical Injectors",
    value: "chemical injectors",
  },
  {
    name: "Other equipment",
    value: "other equipment",
  },
];
