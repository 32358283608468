/* Issue fixed for github issue #24, issue #59,issue #140 */
// Added the whole file for issue #72
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Colors } from "common/Colors";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import { tableStyle } from "features/devices-page/device-details/device-details-tab/tableStyles";
import Button from "components/common-buttons/Button";
import { WEBSOCKET_URL } from "config";
import { useDispatch, useSelector } from "react-redux";
import { devicesAPI } from "services/devicesAPI";
import { RootState } from "app/rootReducer";
import { handleTimeStamp } from "utils/helpers";
import {
  SingleAssetProp,
  ComplexAssetProp
} from "features/asset-drilldown/Types";
import {
  setSuccessModalOpen,
  setSuccessModalDescription
} from "components/success-modal/successModalSlice";

type DeviceAlarmValueMap = Record<string, Record<number, object | undefined>>;

interface IProps {
  expandPanel: (expand: boolean) => void;
  expanded: boolean;
  assetDisplay?: ComplexAssetProp;
  singleAsset?: SingleAssetProp;
}

const ActiveEvents: React.FC<IProps> = ({
  expandPanel,
  expanded,
  assetDisplay,
  singleAsset
}) => {
  const classes = useStyles();
  const table = tableStyle();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [timestamps, setTimestamps] = useState<number>(0);
  const [result1, setResult1] = useState<any[]>([]);
  const [deviceIds, setDeviceIds] = useState([""]);
  var list_of_devices: any[] = [];
  const [deviceAlarmsMap, setDeviceAlarmsMap] = useState<DeviceAlarmValueMap>(
    {}
  );

  const { userInformation } = useSelector((state: RootState) => state.AppSlice);

  const wsClientRef = useRef<WebSocket | null>(null);

  //function for burst call for issue #72
  const burstHandler = useCallback((deviceIds: string[]) => {
    const prepareDeviceHandler = async () => {
      try {
        //opening as many sockets as the number of devices attached to an asset for issue #72
        for (let deviceId of deviceIds) {
          devicesAPI.prepareDeviceForBurst(deviceId);
        }
      } catch (error) {
        alert(error);
      }
    };

    if (wsClientRef.current !== null) {
      // socket already open, but new burst being requested
      prepareDeviceHandler();
    } else {
      // no existing socket
      wsClientRef.current = new WebSocket(`${WEBSOCKET_URL}`);

      wsClientRef.current.onopen = () => {
        prepareDeviceHandler();
      };

      wsClientRef.current.onclose = () => {
        wsClientRef.current = null;
      };

      wsClientRef.current.onerror = () => {
        alert("Error opening websocket for burst data");
        wsClientRef.current?.close();
        wsClientRef.current = null;
      };

      wsClientRef.current.onmessage = (event: any) => {
        try {
          const burstData = JSON.parse(event.data);
          setTimestamps(burstData?.ts);

          if (deviceIds.includes(burstData.did)) {
            const did = burstData.did as string;
            const alarmValuesArray = burstData.alarms as object[];

            //reducing the alarms array to an object for issue #72
            const alarmIdxValueTable = alarmValuesArray.reduce<
              Record<number, object>
            >((table, value, idx) => {
              table[idx] = value;
              return table;
            }, {});

            //mapping latest data into a table with device id as key for issue #72
            setDeviceAlarmsMap(deviceAlarmsTable => ({
              ...deviceAlarmsTable,
              [did]: {
                ...deviceAlarmsTable[did],
                ...alarmIdxValueTable
              }
            }));
          }
        } catch (err) {
          console.log("Error parsing burst data: ", err);
          alert("Error parsing burst data");
        }
      };
    }
  }, []);

  useEffect(() => {
    //converting object list into array for showing on UI for issue #72
    var results = Object.entries(deviceAlarmsMap);
    setResult1(results);
  }, [deviceAlarmsMap]);

  const populateAssetDisplay = () => {
    if (!expanded) {
      const assets = assetDisplay?.assets;
      const asset = singleAsset?.asset;
      //removing duplicate device ids to prevent multiple burst calls with same device id for complex asset for issue #72
      if (assets) {
        assets?.map((device: any) => {
          device?.devices_in_asset.map((deviceList: any) => {
            if (!list_of_devices.includes(deviceList)) {
              list_of_devices.push(deviceList);
            }
          });
        });
      }
      //removing duplicate device ids to prevent multiple burst calls with same device id for simple asset for issue #72
      if (asset) {
        asset?.devices_in_asset.map((deviceList: any) => {
          if (!list_of_devices.includes(deviceList)) {
            list_of_devices.push(deviceList);
          }
        });
      }
      //initializing the list with device ids as keys so that latest data can be mapped for issue #72
      if (list_of_devices.length) {
        const deviceAlarmsTable = list_of_devices.reduce<DeviceAlarmValueMap>(
          (map, deviceId) => {
            map[deviceId] = {};
            return map;
          },
          {}
        );
        setDeviceAlarmsMap(deviceAlarmsTable);
        setDeviceIds(list_of_devices);
        burstHandler(list_of_devices);
      }
    }
    expandPanel(!expanded);
  };

  //function when clear active events button is pressed for issue #72
  const handleClearActiveEvents = async () => {
    try {
      const clear_alarms = await devicesAPI.clearAlarm(
        deviceIds,
        userInformation.user_id
      );
      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription(clear_alarms.data));
    } catch (error) {
      alert(error);
    }
  };

  //for closing the web socket for issue #72
  useEffect(() => {
    return () => {
      const wsClient = wsClientRef;
      wsClient.current?.close();
    };
  }, []);

  // function for showing alarm active or clear message based on alarm status for issue #72
  const handleAlarmColor = (alarmObject: any) => {
    switch (alarmObject.alarm_status) {
      case "Active":
        return (
          <span style={{ fontWeight: 600, color: Colors.Black }}>
            {alarmObject.alarm_active_msg}
          </span>
        );
      case "Inactive":
        return (
          <span style={{ fontWeight: 600, color: Colors.Black }}>
            {alarmObject.alarm_clear_msg}
          </span>
        );
      case "Pending":
        return (
          <span style={{ fontWeight: 600, color: Colors.Black }}>
            {alarmObject.alarm_active_msg}
          </span>
        );

      default:
        break;
    }
    return;
  };

  //function for showing the alarm status in different colour according to the status for issue #72
  const handleAlarmStatus = (alarmObject: any) => {
    switch (alarmObject.alarm_status) {
      case "Active":
        return (
          <span style={{ fontWeight: 600, color: Colors.Red }}>
            {alarmObject.alarm_status}
          </span>
        );
      case "Inactive":
        return (
          <span style={{ fontWeight: 600, color: Colors.Green }}>
            {alarmObject.alarm_status}
          </span>
        );
      case "Pending":
        return (
          <span style={{ fontWeight: 600, color: Colors.Yellow }}>
            {alarmObject.alarm_status}
          </span>
        );

      default:
        break;
    }
    return;
  };

  return (
    <div style={{ width: "100%", padding: 0 }}>
      <Paper
        elevation={0}
        className={
          expanded ? classes.paperStylesExpanded : classes.paperStylesCollapsed
        }
        onClick={populateAssetDisplay}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            className={
              expanded
                ? classes.warningIconExpanded
                : classes.warningIconCollapsed
            }
          >
            <WarningRoundedIcon />
          </div>
          <span style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h6"
              className={
                expanded ? classes.headerExpanded : classes.headerCollapsed
              }
            >
              Active events{" "}
              {/* Added classname for sttyling in mobile view and desktop view for issue #140 */}
              <span className={classes.lastReadSpan}>
                Last read: {handleTimeStamp(timestamps)}
              </span>
            </Typography>
          </span>
        </div>
        {expanded ? (
          <RemoveCircleOutlineOutlinedIcon className={classes.expandIconOpen} />
        ) : (
          <ControlPointIcon className={classes.expandIconCollapsed} />
        )}
      </Paper>
      {expanded && (
        <div>
          {/* removed some css lines from "styles" tab and added a css class eventspanel for responsive UI issue #24 */}
          <div className={classes.eventspanel}>
            {/* adding css class buttonstyling for position of button for responsive UI issue #24 */}
            <div className={classes.buttonstyling}>
              <Button
                color="defaultTheme"
                type="button"
                onClick={handleClearActiveEvents}
              >
                Clear active events
              </Button>
            </div>
          </div>
          <TableContainer className={classes.eventstable}>
            <Table
              style={{ width: "100%" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell className={table.header}>Event</TableCell>
                  <TableCell className={table.header} align="left">
                    Description
                  </TableCell>
                  <TableCell className={table.header} align="left">
                    Source
                  </TableCell>
                  <TableCell className={table.header} align="left">
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {result1?.map((data: any, index: number) => {
                  return Object.keys(data[1])?.map(
                    (eventData: any, index1: number) => {
                      return (
                        <TableRow>
                          <TableCell className={table.tableCell} align="left">
                            {data[1][eventData]["alarm_name"]}
                          </TableCell>
                          <TableCell className={table.tableCell} align="left">
                            {handleAlarmColor(data[1][eventData])}
                          </TableCell>
                          <TableCell
                            className={table.tableCell}
                            // style={{
                            //   width: "10ch",
                            //   whiteSpace: "nowrap",
                            //   overflow: "hidden",
                            //   textOverflow: "ellipsis"
                            // }}
                            align="left"
                          >
                            <Tooltip
                              title={
                                data[1][eventData]["device_name"] ? (
                                  <label style={{ fontSize: "1rem" }}>
                                    {data[1][eventData]["device_name"]}
                                  </label>
                                ) : (
                                  <label></label>
                                )
                              }
                              arrow
                            >
                              <span className={classes.deviceNameSpan}>
                                {data[1][eventData]["device_name"]}
                              </span>
                            </Tooltip>
                          </TableCell>
                          <TableCell className={table.tableCell} align="left">
                            {handleAlarmStatus(data[1][eventData])}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {loading && (
            <div style={{ textAlign: "center", paddingTop: 6 }}>
              Loading events...
            </div>
          )}
          {/* {!loading && !deviceEvents.length && (
              <div style={{ textAlign: "center", paddingTop: 6 }}>
                No events found
              </div>
            )} */}
        </div>
      )}
    </div>
  );
};
export default ActiveEvents;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    tabsRoot: {
      display: "flex",
      float: "right",
      alignContent: "flex-end",
      alignItems: "center"
    },
    tabRoot: {
      fontSize: "1em",
      width: "3em",
      minHeight: "2em",
      height: "2em",
      color: theme.palette.primary.light,
      border: `solid 1px ${theme.palette.primary.light}`,
      minWidth: "3em",
      alignSelf: "center"
    },
    activeTab: {
      backgroundColor: theme.palette.primary.light,
      background: "#FFF",
      textDecoration: "none",
      color: "#FFF"
    },
    tabDates: { alignSelf: "center", marginRight: "1em" },
    headerCollapsed: {
      color: theme.palette.primary.light,
      padding: 0,
      margin: 0,
      [`@media (max-width: 800px)`]: {
        display: "block"
      }
    },
    headerExpanded: {
      color: "FFF",
      padding: 0,
      margin: 0,
      [`@media (max-width: 800px)`]: {
        display: "block"
      }
    },
    paperStylesCollapsed: {
      marginTop: "1em",
      height: "2.5em",

      width: "100%",
      border: "solid 1px lightgrey",
      borderLeft: `solid 5px ${theme.palette.primary.light}`,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "1em",
      paddingLeft: "1em",
      cursor: "pointer",
      [`@media (max-width: 800px)`]: {
        height: "auto"
      }
    },
    paperStylesExpanded: {
      height: "2.5em",
      marginTop: "1em",
      width: "100%",
      border: "solid 1px lightgrey",
      borderLeft: `solid 5px ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.primary.light,
      color: "white",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "1em",
      paddingLeft: "1em",
      cursor: "pointer",
      [`@media (max-width: 800px)`]: {
        height: "auto"
      }
    },
    expandIconCollapsed: {
      color: theme.palette.primary.light,
      "&:hover": { cursor: "pointer" }
    },
    expandIconOpen: {
      color: "white",
      padding: 0,
      borderRadius: "50%",
      "&:hover": { cursor: "pointer" }
    },
    warningIconCollapsed: {
      alignSelf: "center",
      marginRight: ".5em",
      marginTop: ".2em",
      color: theme.palette.primary.light
    },
    warningIconExpanded: {
      alignSelf: "center",
      marginRight: ".5em",
      marginTop: ".2em",
      color: "FFF"
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    tankIcon: {
      fontSize: "3em",
      borderRadius: "50%",
      padding: ".2em",
      backgroundColor: "limegreen",
      color: "white"
    },
    table: {
      minWidth: 450
    },
    cellHeader: {
      fontWeight: "bold",
      color: theme.palette.primary.light
    },
    //Added css class for device name as source for issue #72
    deviceNameSpan: {
      cursor: "pointer",
      display: "block",
      width: "16ch",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    },

    //adding styles with media queries for responsive UI issue #24
    //adding styles for eventspanel class for web view and mobile view for responsive UI issue #24
    eventspanel: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "1rem 0",
      [`@media (max-width: 800px)`]: {
        display: "block"
      }
    },
    //adding css class for styling 'clear active events' button in responsive UI issue #24
    buttonstyling: {
      [`@media (max-width: 800px)`]: {
        marginBottom: "20px"
      }
    },
    //adding css class for styling date shown under events tab in responsive UI issue #24
    datestyling: {
      [`@media (max-width: 700px)`]: {
        marginLeft: "28px"
      },
      // media query added for ipad issue #24
      [`@media (max-width: 800px)`]: {
        float: "right",
        marginBottom: "20px"
      }
    },
    dateTextStyles: {
      fontSize: "0.8em"
    },
    //added css class for styling events table in responsive UI issue #24
    eventstable: {
      maxHeight: "500",
      //Updated the media query for Github issue #59
      [`@media (max-width: 584px)`]: {
        // maxWidth: "330px",
        overflowX: "auto"
      }
    },
    // Added css class to show last read according to resolution for issue #140
    lastReadSpan: {
      fontSize: "0.8rem",
      display: "inline",
      [`@media (max-width: 800px)`]: {
        display: "block"
      }
    }
  })
);
