import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  successDeviceOpen: boolean;
  deleteDeviceModalDescription: string;
  addDeviceModalDescription: string;
} = {
  successDeviceOpen: false,
  deleteDeviceModalDescription: "",
  addDeviceModalDescription: "",
};

const deviceModalSlice = createSlice({
  name: "DeviceModalSlice",
  initialState,
  reducers: {
    setSuccessDeviceOpen: (state, action: PayloadAction<boolean>) => {
      state.successDeviceOpen = action.payload;
    },
    setDeleteDeviceModalDescription: (state, action: PayloadAction<string>) => {
      state.deleteDeviceModalDescription = action.payload;
    },
    setAddDeviceModalDescription: (state, action: PayloadAction<string>) => {
      state.addDeviceModalDescription = action.payload;
    },
  },
});

export const {
  setSuccessDeviceOpen,
  setDeleteDeviceModalDescription,
  setAddDeviceModalDescription,
} = deviceModalSlice.actions;
export default deviceModalSlice.reducer;
