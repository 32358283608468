/*Issue fixed for github issue #23,issue #24, issue #38,issue #45,issue #70,issue #59,issue #82,issue #140 */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { divIcon, Icon } from "leaflet"; //importing Icon for showing the required icons on the map for issue #23
import { Map, Marker, TileLayer, ZoomControl, Popup } from "react-leaflet";
import AssetStatusCard from "../AssetStatusCard/AssetStatusCard";
import AssetReadinessCard from "components/AssetReadinessCard/AssetReadinessCard";
import SingleAssetCard from "components/SingleAssetCard/SingleAssetCard";
import { Group, Asset } from "features/real-time-status-page/types";
import { getLeafletBbox, parseLocation } from "utils/helpers";
import { DeviceDetailsProps } from "features/devices-page/device-details/types";
import { AssetStatusCategoryIcons } from "components/category-icons/AssetStatusCategoryIcons";
import { AssetsProp } from "features/asset-drilldown/Types";
import "./assetMap.css";
import { ReactComponent as OtherEquipmentIcon } from "assets/bt-equipments/ic_others_r_big.svg"; //importing icons for issue #23
import ReactDOMServer from "react-dom/server"; //for converting icons into html issue #23
import { BsCircleFill } from "react-icons/bs"; //for blue circle icon by default issue #23
import { footerText } from "custom/customizations";

type AssetMapProps = {
  accountGroups?: Group[];
  assets?: (Asset | AssetsProp)[];
  singleAsset: boolean; //adding singleAsset props to check if single asset or not for issue #23
  devices?: DeviceDetailsProps[];
  handleGroup?: Function;
  handleAsset?: Function;
};

const AssetMap: React.FC<AssetMapProps> = ({
  accountGroups,
  assets,
  singleAsset, //adding singleAsset props to check if single asset or not for issue #23
  devices,
  handleGroup,
  handleAsset
}) => {
  const classes = useStyles();
  const [viewport, setViewport] = useState({
    center: [40.86368, -96.869469] as [number, number],
    zoom: 4
  });

  const mapRef = useRef<Map>(null);
  var iconsPath; //for temporarily storing icon returned by assetswitchcase for issue #23
  const [mapImg, setMapImg] = useState<any[]>([]); //for storing all the icons according to assetswitchcase for issue #23
  var iconHTML = ReactDOMServer.renderToString(
    <OtherEquipmentIcon className={classes.icons} />
  );

  //function for changing marker and its colour on map based on asset data for issue #23
  const assetSwitchcase = (asset: any) => {
    //switch case for changing marker on the map as per folder icon for issue #23
    switch (asset.asset_category_type.toLowerCase()) {
      //for compressor icon in issue #23
      case "compressor":
        return require("assets/bt-equipments/ic_compressor_r_big.svg");
      //for pump icon in issue #23
      case "pumps":
        return require("assets/bt-equipments/ic_pump_r_big.svg");
      //for tanks icon in issue #23
      case "tanks":
        return require("assets/bt-equipments/ic_tank_g_big.svg");
      //for variable speed drive icon in issue #23
      case "variable speed drive":
        return require("assets/bt-equipments/ic_vsd_r_big.svg");
      //for generator icon in issue #23
      case "generators":
        return require("assets/bt-equipments/ic_generator_r_big.svg");
      //for engine icon in issue #23
      case "engines":
        return require("assets/bt-equipments/ic_engine_r_big.svg");
      //for processor icon in issue #23
      case "processors":
        return require("assets/bt-equipments/ic_processor_r_big.svg");
      //for rig lock icon in issue #23
      case "rig locks":
        return require("assets/bt-equipments/ic_riglock_r_big.svg");
      //for chemical injector icon in issue #23
      case "chemical injectors":
        return require("assets/bt-equipments/ic_chemical_injector_r_big.svg");
      //for other equipment icon in issue #23
      case "other equipment":
        return require("assets/bt-equipments/ic_others_r_big.svg");
      //for default blue circle icon in issue #23
      default:
        return require("react-icons/bs");
    }
  };

  //state variable to change icon on drilling down for Assetmarker issue #23
  const [changeicon, setchangeicon] = useState(iconHTML);
  //marker on the map issue #23
  var blueMarker = divIcon({
    // html:iconHTML,
    html: changeicon, //for changing map marker as per html for issue #23
    iconAnchor: [18, 18],
    popupAnchor: [0, -20],
    iconSize: [36, 36],
    className: classes.markerBlue
  });

  useEffect(() => {
    setTimeout(() => {
      const map = mapRef.current?.leafletElement;
      if (map) {
        map.invalidateSize(); // leaflet dimensions bug workaround
        map.getContainer().focus = () => { }; //added this line for issue #70
      }
    }, 200);
  }, []);

  const geoLocations = useMemo(() => {
    const locations: [number, number][] = [];
    if (Array.isArray(accountGroups)) {
      for (let group of accountGroups) {
        const longLat = parseLocation(group.gio_location);
        if (longLat) {
          locations.push(longLat);
        }
      }
    }
    if (Array.isArray(assets)) {
      if (assets.length == 0) {
        //default blue circle icon shown on map on loading of page issue #23
        iconHTML = ReactDOMServer.renderToString(
          <BsCircleFill
            className={classes.icons}
            style={{ fontSize: "36px", fill: "#027AC1" }}
          />
        );

        //changing icon when html changes for issue #23
        setchangeicon(iconHTML);
        const getchangeiconDefault = () => {
          setTimeout(() => {
            setchangeicon(iconHTML);
          }, 100);
        };
        getchangeiconDefault();
      } else {
        var iconsArr = []; //declaring an array to store icons or issue #23
        for (let asset of assets) {
          //Calling the switch case for getting the icon path. Github issue #23
          iconsPath = assetSwitchcase(asset);
          iconsArr.push(iconsPath);
          setMapImg(iconsArr);

          //setting timeout to re render changeicon every 100 millisecond for Asset marker issue #23
          const getchangeicon = () => {
            setTimeout(() => {
              setchangeicon(iconHTML);
            }, 100);
          };
          const longLat = parseLocation(asset.gio_location);
          if (longLat) {
            getchangeicon(); //added for issue #23
            locations.push(longLat);
          }
        }
      }
    }
    if (Array.isArray(devices)) {
      for (let d of devices) {
        const longLat = parseLocation(d.system_settings.gio_location);
        if (longLat) {
          locations.push(longLat);
        }
      }
    }
    return locations;
  }, [accountGroups, assets, devices]);

  const handleAssetObject = (asset: any) => {
    const firstParameter = asset.asset_parameters[0];
    return {
      ...asset,
      asset_parameter: firstParameter
    };
  };

  useEffect(() => {
    const map = mapRef.current?.leafletElement;
    if (geoLocations.length === 1) {
      // single point
      const [long, lat] = geoLocations[0];
      setViewport({ center: [lat, long], zoom: 8 });
    } else if (geoLocations.length && map) {
      // multiple points = boundary box
      const bounds = getLeafletBbox(geoLocations);
      setTimeout(() => {
        map.fitBounds(bounds, {
          padding: [50, 50]
        });
      }, 200);
    }
  }, [geoLocations]);

  return (
    <div className={classes.mapContainer}>
      <Map
        ref={mapRef}
        attributionControl={false}
        className={classes.mapStyles}
        center={viewport.center}
        zoom={viewport.zoom}
        // doubleClickZoom={false}
        // closePopupOnClick={false}
        dragging={true} //changes made for issue #45
        trackResize //changes made for issue #45
        touchZoom={true} //changes made for issue #45
        scrollWheelZoom={true}
        zoomControl={false}
      >
        {/* Updated the urls from openstreetmap to cartocdn link to show the information of map in english. Github issue #38 */}
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://carto.com/">carto.com</a> contributors'
        // noWrap={true}
        />
        <ZoomControl position="bottomleft" />
        {Array.isArray(accountGroups) &&
          accountGroups.map((group: any) => {
            const coor = parseLocation(group.gio_location);
            if (!coor) return null;

            return (
              <Marker
                position={[coor[1], coor[0]]}
                icon={blueMarker}
                title={`${group.group_name} (${group.groups_in_group.length})`}
                key={group.asset_group_id}
              >
                <Popup className="request-popup">
                  {/* Added div with style for showing the popup for group level for issue #140 */}
                  <div style={{ minWidth: "301px" }}>
                    <AssetStatusCard
                      wholeView={true}
                      group={group}
                      handleGroup={handleGroup}
                      isMarker={true}
                      index={1} //giving an index value for props for issue #82
                    />
                  </div>
                </Popup>
              </Marker>
            );
          })}
        {Array.isArray(assets) &&
          assets.map((asset: Asset | AssetsProp, index) => {
            const coor = parseLocation(asset.gio_location);
            if (!coor) return null;
            //Added the mapIcon for plotting the markers on the map, with respective color and icon. Github issue #23
            var mapIcon = new Icon({
              iconUrl: mapImg[index], //for changing map marker as per html
              iconAnchor: [18, 18],
              popupAnchor: [0, -20],
              iconSize: [36, 36],
              // Added the condition for the classname as to show the color of the icon corresponding to its warning level. Github issue #23
              className:
                asset.warning == null
                  ? classes.markerGreen
                  : asset.warning == true
                    ? classes.markerYellow
                    : classes.markerRed
            });
            if (asset.complex_asset) {
              return (
                <Marker
                  position={[coor[1], coor[0]]}
                  icon={mapIcon}
                  title={`${asset.asset_name}`}
                  key={asset.asset_id}
                >
                  <Popup className="request-popup">
                    {/* Added div with style for showing the popup for single asset level for issue #140 */}
                    <div style={{ minWidth: "301px" }}>
                      <AssetReadinessCard
                        asset={asset}
                        handleAsset={handleAsset}
                        isMarker={true}
                        index={1} //giving an index value for props for issue #82
                      />
                    </div>
                  </Popup>
                </Marker>
              );
            }
            return (
              <Marker
                position={[coor[1], coor[0]]}
                icon={mapIcon} //added mapIcon instead of blue marker for issue #23
                title={`${asset.asset_name}`}
                key={asset.asset_id}
              >
                <Popup className="request-popup">
                  {/* Added div with style for showing the popup for complex asset level for issue #140 */}
                  <div style={{ minWidth: "301px" }}>
                    <SingleAssetCard
                      singleAsset={
                        asset.hasOwnProperty("asset_parameter")
                          ? asset
                          : handleAssetObject(asset)
                      }
                      handleAsset={handleAsset}
                      isMarker={true}
                      index={1} //giving an index value for props for issue #82
                    />
                  </div>
                </Popup>
              </Marker>
            );
          })}
      </Map>
      {/* added div and css class for footer in responsive UI issue #24 */}
      <div className={classes.footerdiv}>
        <p className={classes.footerstyle}>
          {footerText}
        </p>
      </div>
    </div>
  );
};

export default AssetMap;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapContainer: {
      height: "100%",
      width: "100%",
      // display: "flex",
      // justifyContent: "center",
      margin: "0",
      padding: "0"
    },
    mapStyles: {
      width: "100%",
      height: "100%",
      margin: "0",
      padding: "0",
      backgroundColor: "#FFF",
      //added styling with media query for zoom in zoom out buttom for issue #59
      "& .leaflet-left": {
        [`@media (max-width: 800px)`]: {
          left: "15px"
        }
      }
    },
    markerBlue: {
      // backgroundColor: "#027AC1",
      opacity: ".8",
      fontWeight: "bold",
      textAlign: "center",
      borderRadius: "50%",
      width: "20em",
      height: "20em"
    },
    // Added markerGreen css class for showing the markers in running state. Github issue #23
    markerGreen: {
      backgroundColor: "#00D160",
      opacity: ".8",
      fontWeight: "bold",
      textAlign: "center",
      borderRadius: "50%",
      width: "20em",
      height: "20em"
    },
    // Added markerYellow css class for showing the markers in warning state. Github issue #23
    markerYellow: {
      backgroundColor: "#fcc42c",
      opacity: ".8",
      fontWeight: "bold",
      textAlign: "center",
      borderRadius: "50%",
      width: "20em",
      height: "20em"
    },
    // Added markerRed css class for showing the markers in critical state. Github issue #23
    markerRed: {
      backgroundColor: "#ed254e",
      opacity: ".8",
      fontWeight: "bold",
      textAlign: "center",
      borderRadius: "50%",
      width: "20em",
      height: "20em"
    },
    //adding css class for Asset map marker issue #23
    icons: {
      fill: theme.palette.primary.light,
      padding: "1px"
    },

    //adding css class for styling footer div in responsive UI issue #24
    footerdiv: {
      display: "none",
      //media query to style footer div in mobile view responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        marginBottom: "0",
        marginTop: "15px",
        backgroundColor: "#EEEDE7",
        display: "block"
      }
    },

    //adding css class for styling footer in responsive UI issue #24
    footerstyle: {
      [`@media (max-width: 800px)`]: {
        color: "steelblue",
        fontSize: "13px",
        paddingLeft: "8px",
        alignItems: "center"
      }
    }
  })
);
