/*Issue fixed for issue #66 */
import React, { useState, useEffect, useCallback, FormEvent } from "react";
import { TextField } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Auth } from "aws-amplify";
import clsx from "clsx";
import { Colors } from "common/Colors";

const VerifyEmail = ({ onSuccess }: { onSuccess: () => void }) => {
  const classes = useStyles();

  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [sentCode, setSentCode] = useState(false);

  const getCodeHandler = useCallback(async () => {
    try {
      await Auth.verifyCurrentUserAttribute("email");
      setSentCode(true);
    } catch (error) {
      alert("Amplify error sending verification email");
    }
  }, []);

  const verifyCodeHandler = useCallback(async () => {
    try {
      await Auth.verifyCurrentUserAttributeSubmit("email", code);
      onSuccess();
    } catch (err) {
      if (err.message) {
        setError(err.message);
      } else {
        alert("Verification error");
      }
    }
  }, [code, onSuccess]);

  const formHandler = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault(); // prevent page from reloading
      (sentCode ? verifyCodeHandler : getCodeHandler)();
    },
    [getCodeHandler, sentCode, verifyCodeHandler]
  );

  useEffect(() => {
    setError(""); // clear any error after input change
  }, [code]);

  return (
    <div className={classes.container}>
      <form
        className={classes.formStyles}
        noValidate
        autoComplete="off"
        onSubmit={formHandler}
      >
        <div>New accounts require email verification.</div>
        {sentCode && (
          <>
            <div className={classes.spacer} />
            {error && <span className={classes.errorText}>{error}</span>}
            <TextField
              className={clsx({
                [classes.valid]: !error,
                [classes.invalid]: !!error
              })}
              classes={{
                root: classes.input
              }}
              value={code}
              placeholder="Enter code"
              onChange={(
                e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                if (e.target.value.length < 10) {
                  setCode(e.target.value);
                }
              }}
              InputProps={{
                disableUnderline: true
              }}
            />
          </>
        )}
        <div className={classes.spacer} />
        <div className={classes.resetLinkContainer}>
          <button
            type="button"
            className={classes.resetLink}
            onClick={sentCode ? verifyCodeHandler : getCodeHandler}
          >
            {sentCode ? "Verify code" : "Send verification code"}
          </button>
        </div>
        <div className={classes.spacer} />
        <div
          className={classes.cancel}
          onClick={() => {
            Auth.signOut();
            localStorage.clear();
          }}
        >
          Cancel
        </div>
      </form>
    </div>
  );
};

export default VerifyEmail;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100vh",
      justifyContent: "center",
      alignItems: "center"
    },
    formStyles: {
      display: "flex",
      flexDirection: "column",
      width: "23em",
      minHeight: "20em",
      textAlign: "center"
    },
    inputName: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    warnings: {
      fontSize: "12px",
      color: "#ED254E",
      position: "relative",
      top: "5px"
    },
    input: {
      display: "flex",
      justifyContent: "center",
      borderRadius: 0,
      color: "white",
      height: 40,
      width: "95%",
      padding: "0 0 0 5%",
      border: "solid 1px #DDDDDD"
    },
    valid: {
      border: "solid 1px #DDDDDD"
    },
    invalid: {
      border: "solid 1px #ED254D"
    },
    errorText: {
      color: "#ED254D"
    },
    resetLinkContainer: {
      display: "flex",
      justifyContent: "center"
    },
    resetLink: {
      backgroundColor: Colors.MainColor, //changed color to slate for issue #66
      width: "70%",
      fontSize: "14px",
      fontWeight: 600,
      color: "#fff",
      border: "none",
      borderRadius: 0,
      height: "39px",
      "&:hover": {
        cursor: "pointer",
        opacity: ".8"
      }
    },
    cancel: {
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline"
      }
    },
    spacer: {
      height: 20
    }
  })
);
