import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputGroup: {
      padding: "1rem 1rem 1rem 0",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem",
      },
      "& input": {
        width: "100%",
        padding: ".7rem 1rem",
        border: `1px solid ${Colors.LightGray}`,
      },
    },
    lineBreak: {
      width: "100%",
      border: "none",
      borderBottom: `2px dotted ${Colors.LightGray}`,
    },
    header: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      fontSize: 18,
      fontWeight: 600,
      margin: "1rem 0",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      margin: "1rem 0 0 0",
      padding: "1rem 1rem 1rem 0",
    },
    // Checkbox styling
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      borderRadius: 3,
      width: 16,
      height: 16,
      boxShadow:
        "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
      backgroundColor: Colors.White,
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: Colors.White,
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        backgroundColor: theme.palette.primary.light,
      },
    },
    checkedIcon: {
      backgroundColor: theme.palette.primary.light,
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    invalidText: {
      color: "#ED254E",
    },
    warnings: {
      fontSize: ".8em",
      color: "#ED254E",
      position: "relative",
      // top: "5px",
    },
    inputName: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    labelContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    error: {
      color: Colors.Pink,
      fontSize: 12,
    },
    emailContainer: {
      fontSize: 14,
      margin: "2rem 0",
    },
    adminContact: {
      cursor: "pointer",
      color: theme.palette.primary.light,
    },
    textTitle: {
      color: theme.palette.primary.light,
    },
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)",
    },
    viewOnly: {
      color: Colors.Gray,
      pointerEvents: "none",
    },
  })
);
