import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";

export const tableStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowY: "auto",
      margin: "1rem 0",
    },
    table: {
      minWidth: "100%",
      marginTop: "1rem",
    },
    header: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      padding: "1em",
      borderTop: `2px solid ${Colors.LightGray}`,
      borderBottom: `2px solid ${Colors.LightGray}`,
    },
    tableRow: {
      textDecoration: "none",
      "&:focus &:hover &:link &:visited &:active": {
        textDecoration: "none",
      },
    },
    tableCell: {
      borderBottom: `2px solid ${Colors.LightGray}`,
      padding: "1em",
      fontSize: 14,
    },
    flex: {
      display: "flex",
      alignItems: "center",
      padding: "0 0 0 1rem",
    },
    //Added the class to show the trucated text for active/clear message. Github issue #105
    msgContent: {
      display: "block",
      width: "156px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  })
);
