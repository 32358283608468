import React, { useEffect } from "react";
import { useStyles } from "./topBarStyles";

export type AssetStatusTopBarProps = { pageName: string };

export const AssetStatusTopBar: React.FC<AssetStatusTopBarProps> = ({
  pageName,
}) => {
  const classes = useStyles();

  // console.log("ASSET STATUS", pageName);
  return pageName === "Asset status" ? (
    <div className={classes.title}>{pageName}</div>
  ) : null;
};

export default AssetStatusTopBar;
