/* Issue fixed for github issue #140 */
import React, { useEffect, useRef } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useAppSelector } from "app/store";
import { setFullscreen } from "app/appSlice";
import clsx from "clsx";
import { ifSRC } from "custom/customizations";

type ReportMakerProps = {};

const ReportMaker: React.FC<ReportMakerProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const iframeRef = useRef(null); // !iframe has CORS policies, not letting me access it

  const { fullscreen } = useAppSelector(state => state.AppSlice);

  useEffect(() => {
    const listener = (event: any) => {
      if (event.key === "Escape") {
        event.preventDefault();
        dispatch(setFullscreen(false));
      }
    };
    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  });

  return (
    <div
      className={clsx({
        [classes.fullscreen]: fullscreen,
        [classes.nonFullscreen]: !fullscreen
      })}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <span
          className={classes.fullscreenbutton}
          onClick={() => dispatch(setFullscreen(!fullscreen))}
        >
          {fullscreen ? "Collapse" : "Expand"}
        </span>
      </div>
      <iframe
        src={ifSRC}
        height="100%"
        width="100%"
        title="Advanced reports"
      />
    </div>
  );
};

export default ReportMaker;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullscreen: {
      position: "fixed",
      height: "93vh", //reduce height to keep some margin at the bottom for grafana for issue #140
      width: "100%",
      top: 0,
      left: 0
      // zIndex: 100,
    },
    nonFullscreen: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "90vh",
      width: "100%"
    },
    fullscreenbutton: {
      cursor: "pointer",
      opacity: 0.5,
      color: theme.palette.primary.light,
      fontSize: 12,
      margin: "5px 0",
      "&:hover": {
        opacity: 1
      }
    }
  })
);
