import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  groupModalOpen: boolean;
  deleteModalDescription: string;
  deactivateModalDescription: string;
  isGroupActive: boolean;
  isAssetActive: boolean;
  groupDelete: boolean;
  assetDelete: boolean;
} = {
  groupModalOpen: false,
  deleteModalDescription: "",
  deactivateModalDescription: "",
  isGroupActive: false,
  isAssetActive: false,
  groupDelete: false,
  assetDelete: false,
};

const groupModalSlice = createSlice({
  name: "GroupModalSlice",
  initialState,
  reducers: {
    setGroupModalOpen: (state, action: PayloadAction<boolean>) => {
      state.groupModalOpen = action.payload;
    },
    setDeleteModalDescription: (state, action: PayloadAction<string>) => {
      state.deleteModalDescription = action.payload;
    },
    setDeactivateModalDescription: (state, action: PayloadAction<string>) => {
      state.deactivateModalDescription = action.payload;
    },
    setIsGroupActive: (state, action: PayloadAction<boolean>) => {
      state.isGroupActive = action.payload;
    },
    // ! seperate asset logic into own slice - when time allows.
    setIsAssetActive: (state, action: PayloadAction<boolean>) => {
      state.isAssetActive = action.payload;
    },
    setGroupDelete: (state, action: PayloadAction<boolean>) => {
      state.groupDelete = action.payload;
    },
    setAssetDelete: (state, action: PayloadAction<boolean>) => {
      state.assetDelete = action.payload;
    },
  },
});

export const {
  setGroupModalOpen,
  setDeleteModalDescription,
  setDeactivateModalDescription,
  setIsGroupActive,
  setIsAssetActive,
  setGroupDelete,
  setAssetDelete,
} = groupModalSlice.actions;
export default groupModalSlice.reducer;
