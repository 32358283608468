import { Colors } from "common/Colors";

export const displayColors = (colorType: string) => {
  let color: string = "";

  switch (colorType) {
    case "red":
      color = Colors.DashboardRed;
      break;
    case "yellow":
      color = Colors.DashboardYellow;
      break;
    case "green":
      color = Colors.DashboardGreen;
      break;
    default:
      break;
  }

  return color;
};
