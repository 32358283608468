import React,{ useEffect, useState } from 'react';
import {
  createStyles,
  makeStyles
} from "@material-ui/core/styles";
import { ButtonGroupSelectorProps } from './types';

const ButtonGroupSelector: React.FC<ButtonGroupSelectorProps> = ({children, onChange, activeId, style, ...props}) => {
  const styles = useStyles(props);
  const [activeButton, setActiveButton] = useState<number | string>(activeId);

  useEffect(() => {
    setActiveButton(activeId);
  }, [activeId]);

  const clickHandler = (buttonId: number | string) => {
    setActiveButton(buttonId);
    onChange && onChange(buttonId);
  }

  return (
    <div className={styles.buttonsContainer} style={style}>
      {React.Children.map(children, (child) => {
        if(React.isValidElement(child)) {
          if(child.props.id === 'add-button') {
            return child;
          }
          else {
            return React.cloneElement(child, 
              { 
                isActive: child.props.id === activeButton,
                onClick: () => clickHandler(child.props.id)
              })
          }
        }
      })}
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonsContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: 'wrap'
    }
  })
);

export default ButtonGroupSelector;