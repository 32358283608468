import React, { useState, useEffect, useCallback } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAppSelector } from "app/store";
import { setSuccessModalOpen, setSuccessModalDescription } from "components/success-modal/successModalSlice";
import { setCallConnectors } from "../accountSlice";
import { Grid } from "@material-ui/core";
import AccountsAPI from "services/accountsAPI";
import { useForm, Controller } from "react-hook-form";
import { InputLabel } from "components/input-label/InputLabel";
import Button from "components/common-buttons/Button";
import Select from "react-select";
import { Device, RequiredConnectorProps, UsersGroupsProps } from "./types";
import Checkbox from "@material-ui/core/Checkbox";
import LoadSpinner from "common/LoadSpinner";
import clsx from "clsx";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Calendar } from "react-date-range";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import { customDateFormatUnix } from "utils/helpers";
import { fromUnixTime, getUnixTime } from "date-fns";
import calendarWhite from "assets/bt-assets/calendar_white.svg";
import { devicesAPI } from "services/devicesAPI";

const connectorTypeList = [
  {
    name: "Salesforce",
    value: 0
  },
];

type UpdateConnectorModalProps = {
  handleClose: () => void;
  open: boolean;
  selectedConnector: RequiredConnectorProps;
  setCallConnector: Function;
};

export const UpdateConnectorModal: React.FC<UpdateConnectorModalProps> = ({
  handleClose,
  open,
  selectedConnector,
  setCallConnector
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [usersGroupSelection, setUsersGroupSelection] = useState<UsersGroupsProps[]>([]);
  const [connectorDate, setConnectorDate] = useState(Number(""));
  const [deviceList, setDeviceList] = useState<{
    label: string;
    value: string;
    last_push_status: boolean,
    last_push_time: number,
    last_response: number,
  }[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [connectorID, setConnectorID] = useState<string>("");
  const { register, handleSubmit, errors } = useForm<RequiredConnectorProps>();

  const { tenant_id } = useSelector(
    (state: RootState) => state.AccountSlice.accountInformation
  );

  useEffect(() => {
    const getDeviceList = async () => {
      try {
        const deviceNameList = await devicesAPI.retrieveListOfDevices(tenant_id);

        // Create a map for quick lookup of last_push_status based on device_id
        const deviceStatusMap = new Map<string, {
          last_push_status: boolean;
          last_push_time: number;
          last_response: number;
        }>();
        if (Array.isArray(selectedConnector?.device_list)) {
          selectedConnector.device_list.forEach((device: Device) => {
            deviceStatusMap.set(device.device_id, {
              last_push_status: device.last_push_status,
              last_push_time: device.last_push_time,
              last_response: device.last_response,
            });
          });
        }

        const deviceListArr = deviceNameList.map((devicesObj: any) => {
          const status = deviceStatusMap.get(devicesObj.device_id);
          return {
            label: devicesObj.device_name,
            value: devicesObj.device_id,
            last_push_status: status?.last_push_status ?? null,
            last_push_time: status?.last_push_time ?? null,
            last_response: status?.last_response ?? null,
          };
        });

        setDeviceList(deviceListArr);
      } catch (error) {
        console.error("Error fetching device list:", error);
        alert("Failed to retrieve the device list.");
      }
    };

    getDeviceList();
  }, [tenant_id, selectedConnector]);

  useEffect(() => {
    if (selectedConnector) {
      if (selectedConnector.connector_id) {
        setConnectorID(selectedConnector.connector_id);
      }

      if (Array.isArray(selectedConnector.device_list) && selectedConnector.device_list.length > 0) {
        const devices = selectedConnector.device_list.map((device: Device) => ({
          ...device,
        }));
        setSelectedDevices(devices);
        console.log("initialSelectedDevices: ", devices);
      }
    }
  }, [selectedConnector]);

  const calendarExpiryHandler = useCallback(
    (date: Date) => {
      const expiry = getUnixTime(date);
      setConnectorDate(expiry);
    },
    [connectorDate]
  );

  const formatGroupLabel = (data: any) => (
    <div className={classes.group}>
      <span>{data.device_name}</span>
      <span className={classes.groupBadge}>{data.options.length}</span>
    </div>
  );

  const handleUpdateConnector = async ({
    connector_name,
    connector_id,
    connector_type,
    connector_expiry,
    creds: { consumer_key, consumer_pk, consumer_secret, end_point, user_name, user_pwd }
  }: RequiredConnectorProps) => {
    try {
      const selectedDeviceList = selectedDevices.map(
        // (device: Device) => ({
        (device: any) => ({
          device_name: device.device_name,
          device_id: device.device_id,
          // last_push_time: device.last_push_time,
          // last_push_status: device.last_push_status,
          // last_response: device.last_response
        })
      );
      connector_expiry = String(connectorDate);
      if (process.env.NODE_ENV === 'development') {
        console.log("connector_name:", connector_name);
        console.log("connector_ID:", connectorID);
        console.log("connector_expiry:", connector_expiry);
        console.log("selected devices:", selectedDeviceList);
        console.log("connector_type:", connector_type);
        console.log("consumer key:", consumer_key);
        console.log("consumer private key:", consumer_pk);
      }

      setLoading(true);
      await AccountsAPI.updateConnector(
        tenant_id,
        connector_name,
        connectorID,
        selectedDeviceList,
        consumer_key,
        consumer_pk,
        consumer_secret,
        end_point,
        user_name,
        user_pwd,
      );

      setLoading(false);
      dispatch(setCallConnectors(true));
      dispatch(setSuccessModalDescription("Connector updated successfully!"));
      dispatch(setSuccessModalOpen(true));
      setConnectorDate(Number(""));
      handleClose();
    } catch (error) {
      alert("Error updating connector. Please try again.");
      console.log(error);
      setLoading(false);
      handleClose();
    }
  };

  const handleDeviceChange = (selectedOptions: any) => {
    console.log("selectedOptions", selectedOptions);

    const updatedDevices = selectedOptions.map((option: any) => ({
      device_id: option.value,
      device_name: option.label,
      last_push_status: option?.last_push_status ?? null,
      last_push_time: option?.last_push_time ?? null,
      last_response: option?.last_response ?? null,
    }));
    console.log("updatedDevices", updatedDevices);
    setSelectedDevices(updatedDevices);
  };


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalOverlay}>
        {loading ? (
          <LoadSpinner />
        ) : (
          <form onSubmit={handleSubmit(handleUpdateConnector)}>
            <Grid className={classes.modalBody}>
              <Grid item xs={12} className={classes.header}>
                <p className={classes.headerText}>Edit Connector</p>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={handleClose}
                />
              </Grid>
              <div className={classes.innerInputs}>
                <Grid item>
                  <div className={classes.inputGroup}>
                    <InputLabel
                      label="Connector name"
                      name="connector_name"
                      errors={errors}
                    />
                    <input
                      name="connector_name"
                      defaultValue={selectedConnector.connector_name}
                      ref={register({ required: "This field is required." })}
                      maxLength={35}
                      type="text"
                      placeholder="Enter"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    <InputLabel
                      label="Select devices"
                      name="device_events"
                      errors={errors}
                    />
                    <div>
                      <Select
                        name="connector_device_events"
                        styles={reactSelectStyles}
                        value={deviceList.filter(option =>
                          selectedDevices.some(selected => selected.device_id === option.value)
                        )}
                        onChange={handleDeviceChange}
                        options={deviceList}
                        maxMenuHeight={220}
                        classNamePrefix="select"
                        placeholder="Select devices"
                        isMulti
                        isSearchable
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    <InputLabel label="Connector type" name="connector_type" errors={errors} />
                    <select
                      name="connector_type"
                      ref={register({
                        required: "This field is required."
                      })}
                      defaultValue={0}

                    >
                      {connectorTypeList.map((connector: any) => {
                        return (
                          <option key={connector?.value} value={connector?.value}>
                            {connector?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Grid>
                <Grid>
                  {/* input with calendar for connector expiry date for issue #294 */}
                  {/* User cannot set any date which is before the date shown in update modal */}
                  <div className={classes.inputGroup}>
                    <div className={classes.inputName}>
                      Connector start date{" "}
                      <span className={classes.invalidText}>*</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          overflow: "hidden",
                          display: "flex"
                        }}
                      >
                        <input
                          style={{
                            height: 30,
                            marginRight: 16,
                            cursor: "pointer"
                          }}
                          onClick={() => setCalendarOpen(true)}
                          value={
                            connectorDate
                              ? customDateFormatUnix(
                                Number(connectorDate),
                                "MMM dd yyyy"
                              )
                              : "N/A"
                          }
                        />
                        <Button
                          type="button"
                          color="defaultTheme"
                          onClick={() => setCalendarOpen(true)}
                        >
                          <img
                            src={calendarWhite}
                            alt="Subscription Expiry Calendar"
                          />
                        </Button>
                        {calendarOpen && (
                          <div className={classes.calendarDiv}>
                            <ClickAwayListener
                              onClickAway={() => setCalendarOpen(false)}
                            >
                              <Calendar
                                minDate={
                                  // minDate = { fromUnixTime(Number(0))}
                                  new Date() // check if current device expiry date is before today
                                    ? new Date()
                                    : fromUnixTime(Number(connectorDate))
                                }
                                date={
                                  connectorDate
                                    ? fromUnixTime(Number(connectorDate))
                                    : new Date()
                                }
                                onChange={calendarExpiryHandler}
                              />
                            </ClickAwayListener>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    {/* input for connector name for issue #294 */}
                    <InputLabel
                      label="Consumer key"
                      name="consumer_key"
                      errors={errors}
                    />
                    <input
                      name="creds.consumer_key"
                      ref={register({
                        required: "This field is required."
                        // pattern: {
                        //   value: /^[a-zA-Z_ ]*$/,
                        //   message: "Letters only."
                        // }
                      })}
                      defaultValue={selectedConnector?.creds.consumer_key}
                      maxLength={35}
                      type="text"
                      placeholder="Enter"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    {/* input for connector name for issue #294 */}
                    <InputLabel
                      label="Consumer private key"
                      name="consumer_pk"
                      errors={errors}
                    />
                    <input
                      name="creds.consumer_pk"
                      ref={register({
                        required: "This field is required."
                        // pattern: {
                        //   value: /^[a-zA-Z_ ]*$/,
                        //   message: "Letters only."
                        // }
                      })}
                      defaultValue={selectedConnector?.creds.consumer_pk}
                      maxLength={35}
                      type="text"
                      placeholder="Enter"

                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    {/* input for connector name for issue #294 */}
                    <InputLabel
                      label="Consumer secret"
                      name="consumer_secret"
                      errors={errors}
                    />
                    <input
                      name="creds.consumer_secret"
                      ref={register({
                        required: "This field is required."
                        // pattern: {
                        //   value: /^[a-zA-Z_ ]*$/,
                        //   message: "Letters only."
                        // }
                      })}
                      defaultValue={selectedConnector?.creds.consumer_secret}
                      maxLength={35}
                      type="text"
                      placeholder="Enter"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    {/* input for connector name for issue #294 */}
                    <InputLabel
                      label="End point"
                      name="end_point"
                      errors={errors}
                    />
                    <input
                      name="creds.end_point"
                      ref={register({
                        required: "This field is required."
                        // pattern: {
                        //   value: /^[a-zA-Z_ ]*$/,
                        //   message: "Letters only."
                        // }
                      })}
                      defaultValue={selectedConnector?.creds.end_point}
                      maxLength={35}
                      type="text"
                      placeholder="Enter"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    {/* input for connector name for issue #294 */}
                    <InputLabel
                      label="User name"
                      name="user_name"
                      errors={errors}
                    />
                    <input
                      name="creds.user_name"
                      ref={register({
                        required: "This field is required."
                        // pattern: {
                        //   value: /^[a-zA-Z_ ]*$/,
                        //   message: "Letters only."
                        // }
                      })}
                      defaultValue={selectedConnector?.creds.user_name}
                      maxLength={35}
                      type="text"
                      placeholder="Enter"
                    />
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.inputGroup}>
                    {/* input for connector name for issue #294 */}
                    <InputLabel
                      label="User password"
                      name="user_pwd"
                      errors={errors}
                    />
                    <input
                      name="creds.user_pwd"
                      ref={register({
                        required: "This field is required."
                        // pattern: {
                        //   value: /^[a-zA-Z_ ]*$/,
                        //   message: "Letters only."
                        // }
                      })}
                      defaultValue={selectedConnector?.creds.user_pwd}
                      maxLength={35}
                      type="text"
                      placeholder="Enter"
                    />
                  </div>
                </Grid>
              </div>
              <Grid
                item
                xs={12}
                className={classes.actionItems}
                style={{ justifyContent: "flex-end" }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Button
                    type="submit"
                    color="white"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="defaultTheme">
                    Save
                  </Button>
                </div>
              </Grid>

            </Grid>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default UpdateConnectorModal;

const inputStyle = {
  "& p": {
    padding: 0,
    margin: 0,
    fontSize: "1rem",
    marginBottom: ".5rem"
  },
  "& input": {
    width: "100%",
    padding: ".5rem 1rem",
    border: `1px solid ${Colors.LightGray}`
  },
  "& select": {
    width: "100%",
    padding: ".5rem 1rem",
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer"
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)"
    },
    modalBody: {
      maxHeight: "90vh",
      overflowY: "auto",
      // width: 830,
      // overflowY: "auto",
      backgroundColor: Colors.White,
      zIndex: 1
    },
    resizableInput: {
      width: "100%", // Adjust width as needed
      padding: "10px",
      fontSize: "16px",
      borderRadius: "4px",
      border: "1px solid #ccc",
      resize: "both", // Allows resizing both horizontally and vertically
      overflowY: "auto", // Ensures content is handled correctly during resizing
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 2.5rem",
      margin: "0 0 1em 0",
      borderBottom: `1px solid ${Colors.LightGray}`
    },
    headerText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      fontSize: 24,
      padding: 0,
      margin: 0
    },
    closeIcon: {
      color: Colors.LightGray,
      cursor: "pointer",
      fontSize: "3rem"
    },
    innerInputs: {
      padding: "0 2.5rem",
      width: "100%"
    },
    heartIcon: {
      color: theme.palette.primary.light,
      cursor: "pointer"
    },
    inputGroup: {
      padding: "1rem 1rem 1rem 0",
      ...inputStyle
    },
    inputGroupRight: {
      padding: "1rem 0 1rem 1rem",
      ...inputStyle
    },
    labelContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    buttonContainer: {
      height: 30
    },
    button: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: Colors.White,
      cursor: "pointer",
      padding: "0 1rem",
      outline: "none",
      height: 30
    },
    disabledButton: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: Colors.LightGray,
      cursor: "none",
      padding: "0 1rem",
      outline: "none",
      height: 30
    },
    isActive: {
      backgroundColor: theme.palette.primary.light,
      color: Colors.White
    },
    active: {
      color: Colors.Green,
      fontWeight: 600,
      margin: "0 0 0 1rem"
    },
    notActive: {
      color: Colors.LightGray,
      fontWeight: 600,
      margin: "0 0 0 1rem"
    },
    accountInformation: {
      borderTop: `1px solid ${Colors.LightGray}`
    },
    accessAccount: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      "& p": {
        fontSize: 16,
        color: theme.palette.primary.light,
        margin: "0 0 0 .5rem",
        fontWeight: 600
      }
    },
    actionItems: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "1rem 2.5rem 0",
      borderTop: `1px solid ${Colors.LightGray}`,
      padding: "1rem 0"
    },
    inputName: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    group: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    groupBadge: {
      backgroundColor: "#EBECF0",
      borderRadius: "2em",
      color: "#172B4D",
      display: "inline-block",
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "1",
      minWidth: 1,
      padding: "0.16666666666667em 0.5em",
      textAlign: "center"
    },
    isBasinTrakEmployee: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    invalidText: {
      margin: "0 0 0 1px",
      color: "#ED254E"
    },
    calendarDiv: {
      position: "absolute",
      zIndex: 999,
      top: "40%",
      left: "60%",

      [`@media (min-height:800px)`]: {
        top: "auto",
        left: "auto",
        marginTop: "30px"
      }
    }
  })
);

const reactSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer",
    width: "375px",
  }),
};
