import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pathHeader: {
      background: Colors.LightGray,
      padding: ".5rem",
      width: "100%",
      height: 30,
    },
    assetInformation: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      // fontWeight: 600,
      padding: "2rem 0",
      border: `2px solid ${Colors.LightGray}`,
      borderRight: "none",
      borderLeft: "none",
    },
    inputGroup: {
      margin: "2rem 0",
      display: "flex",
      flexDirection: "column",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem",
      },
      "& input": {
        width: "70%",
        padding: ".7rem 1rem",
        border: `1px solid ${Colors.LightGray}`,
      },
    },
    tenantButtonContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      margin: "1rem 0",
    },
    updateContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    toggleButtonContainer: {
      display: "flex",
      flexDirection: "row",
      width: 100,
      height: 30,
    },
    toggleButton: {
      width: 50,
      border: `1px solid ${theme.palette.primary.light}`,
      background: Colors.White,
      color: theme.palette.primary.light,
      // fontWeight: 600,
      cursor: "pointer",
      outline: "none",
    },
    toggleSelectionButtons: {
      padding: "0 1rem",
      border: `1px solid ${theme.palette.primary.light}`,
      background: Colors.White,
      color: theme.palette.primary.light,
      // fontWeight: 600,
      cursor: "pointer",
      outline: "none",
      height: 30,
      "&:hover": {
        opacity: ".8",
      },
    },
    labelText: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: "1rem 0",
      "& p": {
        padding: 0,
        marginRight: "1rem",
        fontSize: "1rem",
      },
    },
    active: {
      background: `${theme.palette.primary.light}`,
      color: Colors.White,
    },
    activeDisabled: {
      border: `1px solid ${Colors.LightGray}`,
      background: Colors.White,
      color: Colors.LightGray,
      width: 50,
      cursor: "default !important",
    },
    assetsGroup: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    value: {
      fontSize: 24,
    },
    notApplicable: {
      fontSize: 24,
      color: Colors.LightGray,
    },
    buttonContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "1rem 0",
      borderBottom: `2px solid ${Colors.LightGray}`,
      padding: "1rem",
    },
    assetContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "1rem 0",
      padding: "1rem",
    },
    propertiesDisplayContainer: {
      display: "flex",
      flexDirection: "row",
      margin: "0 0 1rem 0",
    },
    typeGroup: {
      fontWeight: 600,
    },
    buttonGroup: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-end",
    },
    genericPill: {
      padding: ".3rem .8rem !important",
      borderRadius: "25px",
    },
    activeStatus: {
      color: Colors.Green,
    },
    deactivated: {
      color: Colors.Gray,
    },
    deleteDeactiveBtns: {
      border: `1px solid ${theme.palette.primary.light}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      background: Colors.White,
      width: 150,
      height: 30,
      color: theme.palette.primary.light,
      cursor: "pointer",
    },
    selectButtons: {
      border: `1px solid ${theme.palette.primary.light}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      background: Colors.White,
      width: 66,
      height: 30,
      color: theme.palette.primary.light,
      cursor: "pointer",
      "&:hover": {
        opacity: ".8",
      },
    },
    addButtons: {
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      background: theme.palette.primary.light,
      width: 150,
      height: 30,
      color: Colors.White,
      cursor: "pointer",
      "&:hover": {
        opacity: ".8",
      },
    },
    disabled: {
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      background: `${Colors.LightGray} !important`,
      width: 150,
      height: 30,
      color: Colors.White,
      cursor: "default",
    },
    onOffDisabled: {
      border: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      background: `${Colors.LightGray} !important`,
      width: 50,
      height: 30,
      color: Colors.White,
      cursor: "default !important",
    },
    selectedDisabled: {
      border: `1px solid ${Colors.LightGray}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      background: Colors.White,
      width: 66,
      height: 30,
      color: Colors.LightGray,
      cursor: "default !important",
    },
  })
);
