import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { AssetProfile } from "./types";
import AssetsAPI from "services/assetsAPI";
import { CategoryObject } from "../types";
// import clsx from "clsx";

type AssetPropertyListProps = {
  assetProfile: AssetProfile;
};

const AssetPropertyList: React.FC<AssetPropertyListProps> = ({
  assetProfile,
}) => {
  const classes = useStyles();
  const { asset_name, asset_category_id } = assetProfile;
  const {
    property_1,
    property_2,
    property_3,
    property_4,
    property_5,
    property_6,
    property_7,
    property_8,
    property_9,
    property_10,
  } = assetProfile.property_values;
  const [assetCategory, setAssetCategory] = useState("");
  const [propertyValues, setPropertyValues] = useState<
    {
      id: number;
      name: string;
      value: string;
    }[]
  >([]);
  // ! need assset category to be added to asset profile

  useEffect(() => {
    const getAssetCategory = async () => {
      const categoryList = await AssetsAPI.getAssetCategoryList();
      const selectedCategory = categoryList.filter(
        (categoryObject: CategoryObject) =>
          asset_category_id === categoryObject.asset_category_id
      )[0].category_name;

      setAssetCategory(selectedCategory);
    };

    if (asset_category_id) {
      getAssetCategory();
    }
  }, [asset_category_id]);

  useEffect(() => {
    const _propertyValues = Object.entries(assetProfile.property_values)
      .map((propertyValue: any) => {
        return {
          id: parseInt(propertyValue[0].slice(9)),
          name: propertyValue[1].name,
          value: propertyValue[1].value,
        };
      })
      .sort((a: any, b: any) => a.id - b.id)
      .filter(
        (property: { name: string; value: string; id: number }) =>
          property.value !== ""
      );

    setPropertyValues(_propertyValues);
  }, [assetProfile.property_values]);

  return (
    <>
      <Grid container>
        <Grid className={classes.propertyColumn} item xs={6}>
          <div className={classes.propertyInformation}>
            <p>Asset name:</p>
            <p>{asset_name}</p>
          </div>
          <div className={classes.propertyInformation}>
            <p>Asset category:</p>
            <p>{assetCategory}</p>
          </div>
          {propertyValues
            .slice(0, 4)
            .map(
              (propertyValue: { id: number; name: string; value: string }) => {
                if (propertyValue.value) {
                  return (
                    <div className={classes.propertyInformation}>
                      <p>{propertyValue.name}:</p>
                      <p>{propertyValue.value}</p>
                    </div>
                  );
                }
                return null;
              }
            )}
        </Grid>
        <Grid className={classes.propertyColumn} item xs={6}>
          {propertyValues.slice(4, 10) &&
            propertyValues
              .slice(4, 10)
              .map(
                (propertyValue: {
                  id: number;
                  name: string;
                  value: string;
                }) => {
                  if (propertyValue.value) {
                    return (
                      <div className={classes.propertyInformation}>
                        <p>{propertyValue.name}:</p>
                        <p>{propertyValue.value}</p>
                      </div>
                    );
                  }
                  return null;
                }
              )}
        </Grid>
      </Grid>
    </>
  );
};

export default AssetPropertyList;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    propertyColumn: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      // margin: "1rem 0",
    },
    propertyInformation: {
      display: "grid",
      gridTemplateColumns: "30% 70%",
      // margin: "1rem 0",
      fontSize: 16,
    },
  })
);
