/* Added this file for activating/deactivating/delete modal on three dots click for github issue #294 */
import React, { useCallback, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import DeleteConnectorModal from "./DeleteConnectorModal";
import ActivateDeactivateConnectorModal from "./ActivateDeactivateConnectorModal";

type ConnectorPopOverProps = {
  id: string | undefined;
  open: any;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  tenantId: string;
  connectorActive: boolean;
  connectorId: string;
  connectorName: string;
  setCallConnector: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ConnectorPopOver: React.FC<ConnectorPopOverProps> = ({
  id, //for checking which connector is clicked for issue #294
  open, //to open modal for issue #294
  anchorEl, //for checking which connector is clicked for showing the delete and activate modal below that connector for issue #294
  handleClose, // to handle closing of modal for issue #294
  tenantId, // for sending to activate/deactivate/delete modal for issue #294
  connectorActive, // for showing activate or deactivate on UI based on if it is true or false for issue #294
  connectorId, // for sending to activate/deactivate/delete modal for issue #294
  connectorName, // for sending to activate/deactivate/delete modal for issue #294
  setCallConnector // for sending to activate/deactivate/delete modal for issue #294
}) => {
  const classes = useStyles();
  const [connectorActivity, setConnectorActivity] = useState(true);
  const [deleteConnectorModal, setDeleteConnectorModal] = useState(false); //var to open delete connector modal for issue #294
  const [
    activateDeactivateConnectorModal,
    setActivateDeactivateConnectorModal
  ] = useState(false); //var to open activate/deactivate connector modal for issue #294

  // function to handle activate/deactivate connector button click for issue #294
  const handleConnector = (connectorActivity: boolean) => {
    setConnectorActivity(connectorActivity);
    setActivateDeactivateConnectorModal(true);
    handleClose();
  };

  // function to close the activate/deactivate modal for issue #294
  const closeActivateDeactivateConnectorModal = () => {
    setActivateDeactivateConnectorModal(false);
  };

  // function to handle delete connector button click for issue #294
  const deleteConnectorModalHandler = useCallback(() => {
    setDeleteConnectorModal(true);
    handleClose();
  }, [handleClose]);

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        classes={{
          paper: classes.paper
        }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <div className={classes.selectionContainer}>
          {/* {connectorActive == true ? (
            <p className={classes.selection} onClick={() => handleConnector(true)}>
              Deactivate
            </p>
          ) : (
            <p className={classes.selection} onClick={() => handleConnector(false)}>
              Activate
            </p>
          )} */}
          <p className={classes.selection} onClick={deleteConnectorModalHandler}>
            Delete
          </p>
        </div>
      </Popover>
      {/* modal for activate/deactivate connector button click for issue #294 */}
      <ActivateDeactivateConnectorModal
        open={activateDeactivateConnectorModal}
        handleClose={closeActivateDeactivateConnectorModal}
        connectorActivity={connectorActivity}
        connectorId={connectorId}
        tenantId={tenantId}
        setCallConnector={setCallConnector}
      />
      {deleteConnectorModal && (
        // modal for delete connector button click for issue #294
        <DeleteConnectorModal
          open
          handleClose={() => setDeleteConnectorModal(false)}
          connectorId={connectorId}
          tenantId={tenantId}
          connectorName={connectorName}
          setCallConnector={setCallConnector}
        />
      )}
    </>
  );
};

export default ConnectorPopOver;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectionContainer: {
      padding: ".5rem 1rem"
    },
    paper: {
      border: "none",
      borderRadius: 0
    },
    selection: {
      fontSize: "14px",
      color: theme.palette.primary.light,
      margin: 0,
      cursor: "pointer"
    }
  })
);
