import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      // overflowY: "auto",
      margin: "1rem 0",
    },
    device: {
      color: theme.palette.primary.light,
      fontSize: 18,
      margin: 0,
    },
    table: {
      minWidth: "100%",
      marginTop: "1rem",
    },
    header: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      // padding: 0,
      borderTop: "2px solid #DDD",
      borderBottom: "2px solid #DDD",
    },
    tableRow: {
      textDecoration: "none",
      "&:focus &:hover &:link &:visited &:active": {
        textDecoration: "none",
      },
    },
    tableCell: {
      padding: 0,
      margin: 0,
      borderBottom: "2px solid #DDD",
      fontSize: 14,
    },
    serialNo: {
      display: "flex",
      alignItems: "center",
      padding: 0,
    },
    ellipsis: {
      cursor: "pointer",
      color: theme.palette.primary.light,
      height: "2rem",
    },
    flex: {
      display: "flex",
      alignItems: "center",
      padding: "0 0 0 1rem",
    },
    flex1: {
      display: "flex",
      alignItems: "center",
      padding: "0 0 0 2rem",
    },
    dot: {
      backgroundColor: "LightGreen",
      height: ".6rem",
      width: ".6rem",
      borderRadius: "50%",
      marginRight: "1rem",
    },
    pill: {
      color: "black",
      backgroundColor: Colors.LightGray,
      margin: 0,
      padding: ".3rem .8rem",
      borderRadius: "25px",
    },
    bluePill: {
      color: "skyblue",
      border: "1px solid skyblue",
      margin: 0,
      padding: ".3rem .8rem",
      borderRadius: "25px",
    },
    genericPill: {
      margin: 0,
      padding: ".3rem .8rem",
      borderRadius: "25px",
    },
    greenPill: {
      fontWeight: "bold",
      color: "green",
      border: `1px solid green`,
    },
    orangePill: {
      fontWeight: "bold",
      color: "white",
      backgroundColor: `orange`,
    },
    pinkPill: {
      fontWeight: "bold",
      color: "white",
      backgroundColor: Colors.Pink,
    },
  })
);

export { useStyles };
