import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useDispatch } from "react-redux";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
} from "components/success-modal/successModalSlice";
import { useStyles } from "components/asset-modals/assetStyles";
import AccountAPI from "services/accountsAPI";

type ActivateDeactivateUserModalProps = {
  open: boolean;
  handleClose: Function;
  tenantId: string;
  userActivity: boolean;
  userId: string;
  setCallUser: Function;
  // setUserActivity?: () => void;
};

export const ActivateDeactivateUserModal: React.FC<ActivateDeactivateUserModalProps> =
  ({
    open,
    handleClose,
    tenantId,
    userActivity,
    userId,
    setCallUser,
    // setUserActivity,
  }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleActivateDeactivateUser = async () => {
      if (userActivity) {
        try {
          await AccountAPI.disableUser(userId, tenantId);
          dispatch(setSuccessModalOpen(true));
          dispatch(
            setSuccessModalDescription("User successfully deactivated!")
          );
          setCallUser(true);
        } catch (error) {
          console.log(error);
        }
      }

      if (!userActivity) {
        await AccountAPI.enableUser(userId, tenantId);
        dispatch(setSuccessModalOpen(true));
        dispatch(setSuccessModalDescription("User successfully activated!"));
        setCallUser(true);
        try {
        } catch (error) {
          console.log(error);
        }
      }
      handleClose();
    };

    return (
      <Modal
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalOverlay}>
          <div className={classes.modalBody} style={{ minHeight: 0 }}>
            <div className={classes.header}>
              <p className={classes.headerText}>
                {userActivity ? "Deactivate User" : "Activate User"}
              </p>
              <CloseIcon
                className={classes.icon}
                onClick={() => handleClose()}
              />
            </div>
            <p style={{ margin: "0 0 0 2rem" }}>
              {userActivity
                ? "Are you sure you want to deactivate this user?"
                : "Are you sure you want to activate this user?"}
            </p>
            <p style={{ margin: "1rem 0 0 2rem" }}>
              {userActivity
                ? "If yes, this user will not be able to access account anymore."
                : "If yes, a link will be sent to the user with first time login link, for email verification."}
            </p>
            <div className={classes.actionItems} style={{ border: "none" }}>
              <button
                className={classes.cancelButton}
                type="button"
                onClick={() => handleClose()}
              >
                Cancel
              </button>
              {
                <button
                  className={classes.saveButton}
                  type="button"
                  onClick={handleActivateDeactivateUser}
                >
                  {userActivity ? "Deactivate user" : "Activate user"}
                </button>
              }
            </div>
          </div>
        </div>
      </Modal>
    );
  };

export default ActivateDeactivateUserModal;
