import React from "react";
import { commonButtonStyle } from "./styles";

export type GoogleLinkButtonProp = {
  latitude: string;
  longitude: string;
};
export const GoogleLinkButton: React.FC<GoogleLinkButtonProp> = ({
  latitude,
  longitude,
}) => {
  const classes = commonButtonStyle();
  return (
    <a
      className={classes.googleLink}
      href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Open in google maps
    </a>
  );
};

export default GoogleLinkButton;
