/*Issue fixed for github issue #273,issue #288,issue #261 */
export const realTimeStatusPages = [
  {
    to: "/asset-status",
    label: "Asset status",
    activeOnlyWhenExact: false,
  },
  // ! COMMENTED OUT FOR NOW, NOT A WORKING PAGE
  // {
  //   to: "/exceptions",
  //   label: "Exceptions",
  //   activeOnlyWhenExact: false,
  // },
  // Added for issue #261
  // {
  //   to: "/notifications",
  //   label: "System logs",
  //   activeOnlyWhenExact: false,
  // },
];

export const adminPages = [
  {
    to: "/accounts",
    label: "Accounts",
    activeOnlyWhenExact: false,
  },
  {
    to: "/admin-devices",
    label: "Devices",
    activeOnlyWhenExact: false,
  },
  {
    to: "/assets",
    label: "Assets",
    activeOnlyWhenExact: false,
  },
  // Added new route for admin-reports for issue #273
  {
    to: "/admin-reports",
    label: "Reports",
    activeOnlyWhenExact: false,
  },
  // ! COMMENTED OUT FOR NOW, NOT A WORKING PAGE
  // {
  //   to: "/sim-cards",
  //   label: "SIM cards",
  //   activeOnlyWhenExact: false,
  // },
];

export const reportsPages = [
  // Changed the name for grafana reports for issue #273
  {
    to: "/report-maker",
    label: "Advanced",  //changed text from basic to advanced for issue #288
    activeOnlyWhenExact: false,
  },
  // {
  //   to: "/report-maker",
  //   label: "Advanced",
  //   activeOnlyWhenExact: false,
  // },
  // {
  //   to: "/report-devices",
  //   label: "Devices",
  //   activeOnlyWhenExact: false,
  // },
];

// ! super admin
export const superAdminRealTimeStatusPages = [
  {
    to: "/asset-status",
    label: "Asset status",
    activeOnlyWhenExact: false,
  },
  // ! COMMENTED OUT FOR NOW, NOT A WORKING PAGE
  // {
  //   to: "/exceptions",
  //   label: "Exceptions",
  //   activeOnlyWhenExact: false,
  // },
  {
    to: "/notifications",
    label: "System logs",
    activeOnlyWhenExact: false,
  },
];

export const superAdminPages = [
  {
    to: "/firmware",
    label: "Firmware",
    activeOnlyWhenExact: false,
  },
  {
    to: "/batch",
    label: "Create batch",
    activeOnlyWhenExact: false,
  },
  {
    to: "/accounts",
    label: "Accounts",
    activeOnlyWhenExact: false,
  },
  {
    to: "/admin-devices",
    label: "Devices",
    activeOnlyWhenExact: false,
  },
  {
    to: "/assets",
    label: "Assets",
    activeOnlyWhenExact: false,
  },
  // Added new route for admin-reports for issue #273
  {
    to: "/admin-reports",
    label: "Reports",
    activeOnlyWhenExact: false,
  },
  // ! COMMENTED OUT FOR NOW, NOT A WORKING PAGE
  // {
  //   to: "/sim-cards",
  //   label: "SIM cards",
  //   activeOnlyWhenExact: false,
  // },
];

export const engineerPages = [
  {
    to: "/firmware",
    label: "Firmware",
    activeOnlyWhenExact: false,
  },
  {
    to: "/batch",
    label: "Create batch",
    activeOnlyWhenExact: false,
  },
  {
    to: "/admin-devices",
    label: "Devices",
    activeOnlyWhenExact: false,
  },
  {
    to: "/assets",
    label: "Assets",
    activeOnlyWhenExact: false,
  },
  // Added new route for admin-reports for issue #273
  {
    to: "/admin-reports",
    label: "Reports",
    activeOnlyWhenExact: false,
  },
  // ! COMMENTED OUT FOR NOW, NOT A WORKING PAGE
  // {
  //   to: "/sim-cards",
  //   label: "SIM cards",
  //   activeOnlyWhenExact: false,
  // },
];

// ! non admin
export const officeFieldReportsPages = [
  // {
  //   to: "/report-devices",
  //   label: "Devices",
  //   activeOnlyWhenExact: false,
  // },
  {
    to: "/report-maker",
    label: "Reports",
    activeOnlyWhenExact: false,
  },
];
