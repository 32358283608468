import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  setAssetDashboard,
  setAssetSettingsName,
} from "features/assets-page/assetCategorySlice";
import { setSubPageActive } from "features/accounts-page/accountSlice";
import { useStyles } from "./topBarStyles";
import clsx from "clsx";

export type AssetsTopBarProps = { pageName: string; subPageActive: boolean };

export const AssetsTopBar: React.FC<AssetsTopBarProps> = ({
  pageName,
  subPageActive,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { assetSettingsName } = useSelector(
    (state: RootState) => state.AssetCategorySlice
  );

  const handleRouteToAccountTable = () => {
    dispatch(setAssetDashboard(true));
    dispatch(setAssetSettingsName(""));
    dispatch(setSubPageActive(false));
  };

  return (
    <>
      {pageName === "Assets" ? (
        <>
          <div
            className={clsx({
              [classes.title]: !subPageActive,
              [classes.nestedTitle]: subPageActive,
            })}
            onClick={handleRouteToAccountTable}
          >
            {pageName}
          </div>
          {assetSettingsName && (
            <>
             <div style={{margin: "0 .2rem", fontSize: "1.7rem"}}>
                ›
              </div>
              <div className={classes.title}>{assetSettingsName}</div>
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default AssetsTopBar;
