/* Issue fixed for github issue #103,issue #44,issue #249 */
//Added the file for issue #44, issue #2, issue #3
//Added new function for the decoding of value and for converting values in proper format for ft, hours and minutes. Github issue #2, issue #3
export const getDecodedVal = (
  param: any,
  paramValue: number,
  numberToTextKey: any,
  binaryToTextKey: any,
  binaryFromJsonList: any
) => {
  // Condition added for the decoding of number to text. Github issue #2
  var parameterVal;
  var decodedValue;
  if (param.text_decoding == numberToTextKey) {
    decodedValue = JSON.parse(param.text_json);
    paramValue = decodedValue[Number(paramValue)] ?? "--";
  }
  // Added condition to show current value along with text decoding for DOUT buttons for issue #103
  if (param.source == 3 && param.text_decoding == 0) {
    decodedValue = JSON.parse('{"0":"OFF","1":"ON"}');
    paramValue = decodedValue[Number(paramValue)];
  }

  // Conditions added for the binary to text conversion.Github issue #2
  let decodedVal;
  if (param.text_decoding == binaryToTextKey) {
    decodedVal = binaryFromJsonList(
      Number(paramValue),
      JSON.parse(param.text_json)
    );
    if (decodedVal != undefined) {
      // Removed these lines for issue #249
      // if (decodedVal.lastIndexOf(",") > 0) {
      //   decodedVal = decodedVal.substring(0, decodedVal.lastIndexOf(","));
      // }
      paramValue = JSON.parse(JSON.stringify(decodedVal));
    }
  }

  //Cases for units are added for the units check for hours, minutes and feet. Github issue #3
  var parameter_units = {
    hours: ["hh:mm:ss"],
    minutes: ["mm:ss"],
    feet: ["ft-in"]
  };

  // Condition to display in proper format if unit is in feet. Github issue #3
  if (param.hasOwnProperty("unit")) {
    if (parameter_units.feet.includes(param.unit.toLowerCase())) {
      parameterVal = Number(paramValue);
      if (parameterVal != undefined || parameterVal != 0) {
        const feet = parseInt(parameterVal.toString());
        const fraction = parameterVal - feet;
        const inches = Math.round(12 * 10 * fraction) / 10; //change made in for decimals in inches
        const string1 = "" + feet.toString() + "'";
        const string2 = "" + inches.toString() + '"';
        // Added condition to check if inches and feet are numbers or not for issue #44
        if (Number.isNaN(feet) || Number.isNaN(inches)) {
          paramValue = JSON.parse(JSON.stringify("-" + "" + "-"));
        } else {
          paramValue = JSON.parse(JSON.stringify(string1 + " " + string2));
        }
      }
    }

    // Condition to display in proper format if unit is in hours. Github issue #3
    if (parameter_units.hours.includes(param.unit.toLowerCase())) {
      parameterVal = Number(paramValue);
      if (parameterVal != undefined || parameterVal != 0) {
        const hours = parseInt(parameterVal.toString());
        const fraction = parameterVal - hours;
        const mins_frac = 60 * fraction;
        const minutes = parseInt(mins_frac.toString());
        const fraction_2 = mins_frac - minutes;
        const seconds = parseInt((60 * fraction_2).toString());
        paramValue = JSON.parse(
          JSON.stringify(
            "" +
            (hours.toString().padStart(2, "0") +
              ":" +
              minutes.toString().padStart(2, "0") +
              ":" +
              seconds.toString().padStart(2, "0") +
              "")
          )
        );
      }
    }

    // Condition to display in proper format if unit is in minutes. Github issue #3
    if (parameter_units.minutes.includes(param.unit.toLowerCase())) {
      parameterVal = Number(paramValue);
      if (parameterVal != undefined || parameterVal != 0) {
        const minutes = parseInt(parameterVal.toString());
        const fraction = parameterVal - minutes;
        const seconds = parseInt((60 * fraction).toString());
        paramValue = JSON.parse(
          JSON.stringify(
            "" +
            (minutes.toString().padStart(2, "0") +
              ":" +
              seconds.toString().padStart(2, "0") +
              "")
          )
        );
      }
    }
  }
  return paramValue;
};
