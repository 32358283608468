import React, { ChangeEvent } from 'react';
import { usePagination } from '@material-ui/lab/Pagination';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

type PaginationProps = {
  pageCount: number,
  onChange: any
};

const Pagination: React.FC<PaginationProps> = ({pageCount, onChange}) => {
  const classes = useStyles();
  const { items } = usePagination({
    count: pageCount,
    onChange: onChange
  });

  return (<nav>
      <ul className={classes.ul}>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <button type="button" className={ selected ? classes.paginationSelected : classes.paginationUnselected } {...item}>
                {page}
              </button>
            );
          } else {
            children = (
              <button type="button" className={classes.paginationControl} {...item}>
                {`${(type.slice(0, 1)).toUpperCase()}${type.slice(1, type.length)}`}
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
    </ul>
  </nav>);
}

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    ul: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      display: 'flex',
    },
    paginationControl: {
      color: "#A6A6A6",
      margin: "0px 20px",
      fontFamily: "Hind",
      fontWeight: "normal",
      border: 0,
      background: "none"
    },
    paginationSelected: {
      height: '18px',
      background: theme.palette.primary.light,
      border: 0,
      justifyContent: 'center',
      alignItems: 'center',
      color: '#FFFFFF'
    },
    paginationUnselected: {
      height: '18px',
      color: theme.palette.primary.light,
      background: 'none',
      border: 0,
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
)

export default Pagination;