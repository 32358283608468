import React, { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { setCallUsers } from "../accountSlice";
import { UserInformation } from "app/types";
import AccountAPI from "services/accountsAPI";
import LoadSpinner from "common/LoadSpinner";
import Pagination from "../../../components/Pagination";
import clsx from "clsx";

type AccountHistoryProps = {};

const AccountHistory: React.FC<AccountHistoryProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const count = 100;

  const paginationHandler = (e: Object, pageNumber: number) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(pageNumber);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.root}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.columnText}>Date</TableCell>
                <TableCell className={classes.columnText}>Time</TableCell>
                <TableCell className={classes.columnText}>User</TableCell>
                <TableCell className={classes.columnText}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from("12345").map((item: string, index: number) => {
                return (
                  <TableRow
                    key={item + index}
                    style={{
                      cursor: "default",
                      position: "relative",
                      zIndex: -1,
                    }}
                  >
                    <TableCell className={classes.tableCell}>
                      22 Jul 2020
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      10:43 AM
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Jerome Bell
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      Device ID XXXOOXOOOXOX was added.
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid
        container
        xs={12}
        item
        justify={"flex-end"}
        className={clsx([classes.mt])}
      >
        <Pagination pageCount={count} onChange={paginationHandler} />
      </Grid>
    </Grid>
  );
};

export default AccountHistory;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowY: "auto",
    },
    lineBreak: {
      width: "100%",
      border: `1px solid ${Colors.LightGray}`,
    },
    mt: {
      marginTop: 30,
    },
    table: {
      minWidth: "100%",
      marginTop: "1rem",
    },
    columnText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      borderTop: "2px solid #DDD",
      borderBottom: "2px solid #DDD",
    },
    tableCell: {
      padding: "1rem",
      margin: 0,
      borderBottom: "2px solid #DDD",
      color: Colors.Black,
      fontSize: 14,
    },
  })
);
