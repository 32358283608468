/* Issue fixed for github issue #24*/
import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const NotificationsOnlyView = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.notificationsContainer}>
      <div className={classes.messageText}>You have a notification role only for this account.</div>
    </Grid>
  );
};

export default NotificationsOnlyView;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notificationsContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "1rem",
      height: "90vh",

      //adding media query for access groups and map in responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "1rem",
        marginLeft: "20px",
        marginRight: "20px",
      },
    },
    topGrid: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    bottomContainer: {
      margin: "1rem 0 0 0",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    messageText: {
      fontSize: 24,
    }
  })
);
