import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";

export const reactSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer",
    // width: 435,
    height: 41,
    overflowY: "auto"
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 100
  })
};

const inputStyle = {
  "& p": {
    padding: 0,
    margin: 0,
    fontSize: "1rem",
    marginBottom: ".5rem"
  },
  "& input": {
    width: "100%",
    padding: ".5rem 1rem",
    border: `1px solid ${Colors.LightGray}`
  },
  "& select": {
    width: "100%",
    padding: ".5rem 1rem",
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer"
  }
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)"
    },
    modalBody: {
      maxHeight: "90vh",
      overflowY: "auto",
      width: 830,
      // overflowY: "auto",
      backgroundColor: Colors.White,
      zIndex: 1
    },
    header: {
      // display: "flex",
      // alignItems: "center",
      // justifyContent: "space-between",
      // padding: "1rem 0",
      // borderBottom: `1px solid ${Colors.LightGray}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem 2.5rem",
      margin: "0 0 1em 0",
      borderBottom: `1px solid ${Colors.LightGray}`
    },
    headerText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      fontSize: 24,
      padding: 0,
      margin: 0
    },
    closeIcon: {
      color: Colors.LightGray,
      cursor: "pointer",
      fontSize: "3rem"
    },
    innerInputs: {
      padding: "0 2.5rem",
      width: "100%"
      // maxHeight: 700, // causes double scrollbar overflow on certain window heights
      // overflowY: "auto",
    },
    heartIcon: {
      color: theme.palette.primary.light,
      cursor: "pointer"
    },
    inputGroup: {
      padding: "1rem 1rem 1rem 0",
      ...inputStyle
    },
    inputGroupRight: {
      padding: "1rem 0 1rem 1rem",
      ...inputStyle
    },
    inputGroupRightCheckbox: {
      padding: "1rem 0 1rem 0",
      ...inputStyle
    },
    labelContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    buttonContainer: {
      height: 30
    },
    button: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: Colors.White,
      cursor: "pointer",
      padding: "0 1rem",
      outline: "none",
      height: 30
    },
    disabledButton: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: Colors.LightGray,
      cursor: "none",
      padding: "0 1rem",
      outline: "none",
      height: 30
    },
    isActive: {
      backgroundColor: theme.palette.primary.light,
      color: Colors.White
    },
    active: {
      color: Colors.Green,
      fontWeight: 600,
      margin: "0 0 0 1rem"
    },
    notActive: {
      color: Colors.LightGray,
      fontWeight: 600,
      margin: "0 0 0 1rem"
    },
    accountInformation: {
      borderTop: `1px solid ${Colors.LightGray}`
    },
    accessAccount: {
      display: "flex",
      flexDirection: "row",
      alignItems: "baseline",
      "& p": {
        fontSize: 16,
        color: theme.palette.primary.light,
        margin: "0 0 0 .5rem",
        fontWeight: 600
      }
    },
    actionItems: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "1rem 2.5rem 0",
      borderTop: `1px solid ${Colors.LightGray}`,
      padding: "1rem 0"
    },
    inputName: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    group: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    groupBadge: {
      backgroundColor: "#EBECF0",
      borderRadius: "2em",
      color: "#172B4D",
      display: "inline-block",
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: "1",
      minWidth: 1,
      padding: "0.16666666666667em 0.5em",
      textAlign: "center"
    },
    isBasinTrakEmployee: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between"
    },
    invalidText: {
      margin: "0 0 0 1px",
      color: "#ED254E"
    },
    label: {
      fontSize: "14px"
    },
    formRoot: {
      height: 25
    }
  })
);
