// Added file for unsuccessful modal for issue #213
import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import successIcon from "assets/bt-logins/success.svg";
import { setUnsuccessModalOpen } from "components/success-modal/successModalSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import Button from "components/common-buttons/Button";
import { IoMdCloseCircle } from "react-icons/io";
import { Colors } from "common/Colors";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

export const UnsuccessModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector(
    (state: RootState) => state.SuccessModalSlice.unsuccessModalOpen
  );
  const unsuccessDescription = useSelector(
    (state: RootState) => state.SuccessModalSlice.unsuccessModalDescription
  );

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const handleClose = () => {
    dispatch(setUnsuccessModalOpen(false));
  };

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper1}>
          <IoMdCloseCircle style={{ fontSize: "40" }} />
          <h2 id="simple-modal-title" className={classes.modalTitle}>
            {unsuccessDescription}
          </h2>
          <Button type="button" color="defaultTheme" onClick={handleClose}>
            OK
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default UnsuccessModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper1: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      color: "#fff",
      fontSize: "16px",
      // width: 433,
      height: 212,
      backgroundColor: "#F62217",
      border: "none",
      outline: "none",
      padding: "0 2rem"
    },
    modalTitle: {
      fontSize: "24px",
      fontWeight: 600
    },
    modalDescription: {
      fontSize: "14px",
      textAlign: "center"
    },
    okButton: {
      backgroundColor: Colors.MainColor, //updated color from #6D1919 to #253746 for issue #204
      width: "47px",
      height: "30px",
      color: "#fff",
      border: "none",
      outline: "none",
      cursor: "pointer"
    }
  })
);
