/* Added this file for API tokens UI for github issue #143,issue #213 */
import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Colors } from "common/Colors";
import ellipsis from "assets/bt-assets/more-vertical@2x.svg";
import { SuccessModal } from "components/success-modal/SuccessModal";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { setCallTokens } from "../accountSlice";
import clsx from "clsx";
import AccountAPI from "services/accountsAPI";
import LoadSpinner from "common/LoadSpinner";
import UpdateAPITokenModal from "./UpdateAPITokenModal";
import { CreateNewAPITokenModal } from "./CreateNewAPITokenModal";
import APITokenPopOver from "./APITokenPopOver";
import { fromUnixTime, format } from "date-fns";
import UnsuccessModal from "components/success-modal/UnsuccessfullModal"; //imported for issue #213
import { useAccessLevelAndRoles } from "utils/reactHooks";
import Button from "components/common-buttons/Button";
import ExpirySelector from 'components/date-range-selector/ExpirySelector';
import AccountsAPI from "services/accountsAPI";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
  setUnsuccessModalOpen,
  setUnsuccessModalDescription
} from "components/success-modal/successModalSlice";

type Token = {
  token_active: boolean;
  token: string;
  tenant_id: string;
  soft_delete: boolean;
  api_key: string;
  token_id: number;
  token_name: string;
  token_access: string[];
  access_groups: { value: string; label: string }[];
  token_expiry: string;
};

type apiSubscription = {
  level: number,
  last_updated: string,
  expiry: string
};

type AllTokensList = {
  api_token: Token[];
  api_subscription: apiSubscription;
  remaining_requests: number;
};

type APITokensProps = {
  isBasintrakAdmin: boolean;
  openNewAPITokenModal: boolean;
  setOpenNewAPITokenModal: Function;
};

const APITokens: React.FC<APITokensProps> = ({
  isBasintrakAdmin,
  openNewAPITokenModal,
  setOpenNewAPITokenModal
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [allTokensList, setAllTokensList] = useState<AllTokensList>({
    api_token: [],
    api_subscription: {
      level: 0,
      last_updated: '',
      expiry: ''
    },
    remaining_requests: 0,
  });

  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const [remaingReqs, setRemainReqs] = useState<number>(0);
  const [currentExpiryDate, setCurrentExpiryDate] = useState<number>(0);
  const [newExpiryDate, setNewExpiryDate] = useState<number>(0);

  const [tokenActive, setTokenActive] = useState(false);
  const [callToken, setCallToken] = useState(false);
  const [tokenId, setTokenId] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [selectedToken, setSelectedToken] = useState<any>([]);
  const [newSubscriptionLevel, setNewSubscriptionLevel] = useState<number>(0);
  const formattedExpiryDate = currentExpiryDate ? format(fromUnixTime(currentExpiryDate), 'yyyy-MM-dd HH:mm:ss') : 'N/A';
  const formattedLastUpdated = lastUpdate ? format(fromUnixTime(lastUpdate), 'yyyy-MM-dd HH:mm:ss') : 'N/A';

  const [openUpdateAPITokenModal, setOpenUpdateAPITokenModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const tenantId = useSelector(
    (state: RootState) => state.AccountSlice.accountInformation?.tenant_id
  );
  const reCallTokenList = useSelector(
    (state: RootState) => state.AccountSlice.callTokens
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const subscriptionLevelMap: { [key: number]: string } = {
    0: 'Disabled',
    1: 'Basic',
    2: 'Enhanced',
    3: 'Complete',
    4: 'Demo'
  };

  const { isBasintrakSuperAdmin, isTenantAdmin } = useAccessLevelAndRoles();

  const handleExpiryDateChange = (date: number) => {
    setNewExpiryDate(date);
  };

  const handleSubscriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedLevel = Number(event.target.value);
    setNewSubscriptionLevel(selectedLevel);
  };

  // function to open update token modal when any token is clicked for issue #143
  const handleUpdateToken = (token: any) => {
    setSelectedToken(token);
    setTokenActive(token?.token_active);
    setOpenUpdateAPITokenModal(true);
  };

  // function to close create or update token modal for issue #143
  const handleModalClose = () => {
    setOpenNewAPITokenModal(false);
    setOpenUpdateAPITokenModal(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // function to handle three dots click for issue #143
  const APITokenMenuClickHandler = useCallback(
    (APItoken: any, tokenId: string, tokenName: string) => (event: any) => {
      setAnchorEl(event.currentTarget);
      setTokenActive(APItoken?.token_active);
      setTokenId(tokenId);
      setTokenName(tokenName);
    },
    []
  );

  // function to display access to groups or devices or both for issue #143
  const displayAccess = (tokenAccess: any) => {
    return tokenAccess && tokenAccess.length !== 0 ? (
      <p className={classes.role}>
        {tokenAccess.map((accessItem: any) => accessItem).join("; ")}
      </p>
    ) : (
      <p className={classes.role}>No access levels</p>
    );
  };

  // function to display access groups for issue #143
  const displayGroups = (accessGroups: any) => {
    return accessGroups && accessGroups.length !== 0 ? (
      <p className={classes.role}>
        {accessGroups.map((group: any) => group.label).join("; ")}
      </p>
    ) : (
      <p className={classes.role}>No assigned group(s)</p>
    );
  };

  const getTokenList = async () => {
    try {
      // api call to retrieve token list for issue #143
      const tokenList = await AccountAPI.getAllTokens(tenantId);
      // const tokenList = response.data;
      setAllTokensList(tokenList);

      const level = Number(tokenList.api_subscription.level || 0);
      const lastUpdated = Number(parseInt(tokenList.api_subscription.last_updated, 10) || 0);
      const expiry = Number(parseInt(tokenList.api_subscription.expiry, 10) || 0);
      const remainingrequests = Number(parseInt(tokenList.remaining_requests, 10) || 0);
      setNewSubscriptionLevel(level);
      setLastUpdate(lastUpdated);
      setCurrentExpiryDate(expiry);
      setNewExpiryDate(expiry);
      setRemainReqs(remainingrequests);

      setLoading(false);
    } catch (error) {
      alert(error);
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // function to call token list for issue #
    getTokenList();
    //for calling token list after CRUD operation on token for issue #143
    if (reCallTokenList) {
      getTokenList();
      dispatch(setCallTokens(false));
    }

    getTokenList();
  }, [tenantId, reCallTokenList, dispatch]);

  const handleUpdateSubscription = async () => {
    const api_subscription = {
      expiry: newExpiryDate.toString(),
      level: newSubscriptionLevel,
      last_updated: Math.floor(Date.now() / 1000).toString(),
    };

    try {
      // Call the API function
      await AccountsAPI.updateAPISubscription({
        tenantId,
        api_subscription,
      });
      dispatch(
        setSuccessModalDescription("API subscription updated.")
      );
      dispatch(setSuccessModalOpen(true));
      getTokenList();
    } catch (error) {
      dispatch(setUnsuccessModalOpen(true));
      dispatch(setUnsuccessModalDescription("Unable to update subscription."));
      getTokenList();
      console.error('Error updating subscription:', error);
    }
  };

  return loading ? (
    <LoadSpinner size="90vh" center={true} />
  ) : (
    <Grid container>
      <Grid item xs={12} className={classes.root}>
        <div
          className={clsx({
            [classes.viewOnly]:
              (!isTenantAdmin && !isBasintrakSuperAdmin) ||
              (!isBasintrakSuperAdmin && !isTenantAdmin)
          })}
          style={{ margin: "1em 0 0 0" }}
        >
          <div className={classes.subscriptionRow}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Subscription Level</FormLabel>
              <RadioGroup
                aria-label="subscription-level"
                name="subscription-level"
                value={newSubscriptionLevel.toString()}
                onChange={handleSubscriptionChange}
                className={classes.radioGroup}
              >
                {Object.entries(subscriptionLevelMap).map(([key, label]) => (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio color="primary" />}
                    label={label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <div className={classes.expiryContainer}>
              <p className={classes.expiryLabel}>Expiry date: </p>
              <p className={classes.expiryLabel}>{formattedExpiryDate}</p>
            </div>
            <div className={classes.expiryContainer}>
              <p className={classes.expiryLabel}>Subscription last updated: </p>
              <p className={classes.expiryLabel}>{formattedLastUpdated}</p>
            </div>
            <div className={classes.expiryContainer}>
              <p className={classes.expiryLabel}>API requests remaining today: </p>
              <p className={classes.expiryLabel}>{remaingReqs}</p>
            </div>
            {/* Conditionally render the button */}
            {(isTenantAdmin || isBasintrakSuperAdmin) && (
              <div className={classes.buttonContainer}>
                <ExpirySelector
                  setExpiryDate={handleExpiryDateChange}
                  currentExpiry={currentExpiryDate}
                />
              </div>
            )}
          </div>
          {(isTenantAdmin || isBasintrakSuperAdmin) && (
            <div className={classes.buttonContainer}>
              <Button
                type="button"
                color="defaultTheme"
                onClick={handleUpdateSubscription}
              >
                Update subscription
              </Button>
            </div>
          )}
        </div>

        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.columnText}>Token name</TableCell>
                <TableCell className={classes.columnText}>
                  Access level
                </TableCell>
                <TableCell className={classes.columnText}>
                  Access group(s)
                </TableCell>
                <TableCell className={classes.columnText}>
                  Token expiry
                </TableCell>
                <TableCell className={classes.columnText}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allTokensList.api_token?.map((token, index) => {
                return (
                  <TableRow
                    key={index}
                    style={{
                      cursor: "pointer",
                      position: "relative"
                    }}
                  >
                    {/* this will have id or some sort of identifier attached to cell to know which token it is targetting */}
                    <TableCell
                      className={classes.tableCell}
                      onClick={() => {
                        handleUpdateToken(token);
                      }}
                    >
                      <div className={classes.companyInformation}>
                        <div
                          className={clsx(classes.dot, {
                            [classes.activeDot]: token?.token_active,
                            [classes.inactiveDot]: !token?.token_active
                          })}
                        ></div>
                        <p className={classes.tokenName}>{token.token_name}</p>
                      </div>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={() => {
                        handleUpdateToken(token);
                      }}
                    >
                      {displayAccess(token.token_access)}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={() => {
                        handleUpdateToken(token);
                      }}
                    >
                      {displayGroups(token.access_groups)}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      onClick={() => {
                        handleUpdateToken(token);
                      }}
                    >
                      {format(fromUnixTime(Number(token?.token_expiry)), "MM/dd/yyyy")}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <div
                        style={{
                          position: "relative",
                          zIndex: 1,
                          display: "flex",
                          justifyContent: "center"
                        }}
                        onClick={APITokenMenuClickHandler(
                          token,
                          `${token.token_id}`,
                          token.token_name
                        )}
                      >
                        <img src={ellipsis} alt="hamburger menu" />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <APITokenPopOver
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            tenantId={tenantId}
            tokenActive={tokenActive}
            tokenId={tokenId}
            tokenName={tokenName}
            setCallToken={setCallToken}
          />
          <CreateNewAPITokenModal
            open={openNewAPITokenModal}
            handleClose={handleModalClose}
            subscription_expiry={currentExpiryDate}
          />
          {openUpdateAPITokenModal && (
            <UpdateAPITokenModal
              open={openUpdateAPITokenModal}
              handleClose={handleModalClose}
              selectedToken={selectedToken}
              setCallToken={setCallToken}
            />
          )}
          <SuccessModal />
          {/* imported for issue #213 */}
          <UnsuccessModal />
        </TableContainer>

      </Grid>
    </Grid >
  );
};

export default APITokens;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowY: "auto"
    },
    subscriptionRow: {
      display: "flex",
      flexDirection: "column",
      marginTop: "10px",
      paddingTop: "1rem",
    },
    // Optional: Add styles for radio buttons if needed
    radioGroup: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    viewOnly: {
      color: Colors.Gray,
      pointerEvents: "none"
    },
    expiryContainer: {
      display: "flex",
      marginTop: "10px",
      // gap: "16px",
    },
    expiryTextBox: {
      marginRight: "1rem",
      flex: 1,
    },
    expirySelector: {
      flex: 1,
    },
    expiryLabel: {
      display: "flex",
      flexDirection: "row",
      marginTop: "10px",
      paddingRight: "0.5rem"
    },
    lineBreak: {
      width: "100%",
      border: `1px solid ${Colors.LightGray}`
    },
    buttonContainer: {
      margin: "1rem 0",
      display: "flex",
      flexDirection: "row",
    },
    createUserButton: {
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.primary.light,
      padding: "0 1rem",
      color: Colors.White,
      height: 30,
      cursor: "pointer",
      outline: "none"
    },
    table: {
      minWidth: "100%",
      marginTop: "1rem"
    },
    columnText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      borderTop: "2px solid #DDD",
      borderBottom: "2px solid #DDD"
    },
    tableCell: {
      padding: "4px 16px",
      margin: 0,
      borderBottom: "2px solid #DDD",
      fontSize: 14
    },
    companyInformation: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "80%",
      cursor: "pointer"
    },
    tokenName: {
      position: "relative",
      right: "9px",
      color: theme.palette.primary.light,
      fontSize: 14
    },
    role: {
      color: Colors.Black,
      fontSize: 14
    },
    accessGroup: {
      color: Colors.Black,
      fontSize: 14
    },
    userEmail: {
      color: theme.palette.primary.light
    },
    userPhone: {
      color: Colors.Black
    },
    comment: {
      height: "1.8rem",
      color: Colors.Gray,
      cursor: "pointer"
    },
    ellipsis: {
      cursor: "pointer",
      color: theme.palette.primary.light,
      height: "2rem",
      zIndex: 100
    },
    dot: {
      backgroundColor: "LightGreen",
      height: ".6rem",
      width: ".6rem",
      borderRadius: "50%",
      position: "relative",
      right: "15px"
    },
    activeDot: {
      backgroundColor: Colors.Green
    },
    inactiveDot: {
      backgroundColor: Colors.LightGray
    },
    notPermitted: {
      opacity: 0.5,
      pointerEvents: "none"
    }
  })
);