// Added file for issue #261
import React, {
  useState,
  useEffect,
} from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Colors } from "common/Colors";
import { dashboardAPI } from "services/dashboardAPI";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { getUnixTime, subDays, fromUnixTime, format } from "date-fns";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import DateRangeSelector from "components/date-range-selector/DateRangeSelector";
import { tableStyle } from "features/devices-page/device-details/device-details-tab/tableStyles";
import "react-sortable-tree/style.css";
import InputBase from "@material-ui/core/InputBase";  //Added for search functionality for issue #122
import search from "assets/bt-assets/header_search_w.svg";  //Added for search functionality for issue #122
import { footerText } from "custom/customizations";

const NotificationStatusDashboard = () => {
  const classes = useStyles();
  const table = tableStyle();

  const [searchVal, setSearchVal] = useState(""); //Added for search functionality for issue #122
  const [mainAssetEvents, setMainAssetEvents] = useState<any>([]);  //Added for search functionality for issue #122
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState<number | null>(
    getUnixTime(subDays(new Date(), 1))
  );
  const [endDate, setEndDate] = useState<number | null>(
    getUnixTime(new Date())
  );
  const [assetEvents, setAssetEvents] = useState<any>([]);

  const { accountId } = useSelector(
    (state: RootState) => state.AppSlice.selectedAccount
  );

  // function to handle the search functionality for issue #261
  const handleSearchClick = () => {
    const productList = [...assetEvents];
    if (searchVal === "") { setAssetEvents(mainAssetEvents); return; }
    const filterBySearch = productList.filter((item: any) => {
      if (item?.notification_type.toLowerCase()
        .includes(searchVal.toLowerCase())) { return item; }
      if (item?.notification_affect.toLowerCase()
        .includes(searchVal.toLowerCase())) { return item; }
      if (item?.notification_desc.toLowerCase()
        .includes(searchVal.toLowerCase())) { return item; }
    })
    setAssetEvents(filterBySearch);
  };

  //to handle search functionality for issue #261
  useEffect(() => {
    const productList = [...mainAssetEvents];
    if (searchVal === "") { setAssetEvents(mainAssetEvents); return; }
    const filterBySearch = productList.filter((item: any) => {
      if (item?.notification_type.toLowerCase()
        .includes(searchVal.toLowerCase())) { return item; }
      if (item?.notification_affect.toLowerCase()
        .includes(searchVal.toLowerCase())) { return item; }
      if (item?.notification_desc.toLowerCase()
        .includes(searchVal.toLowerCase())) { return item; }
    })
    setAssetEvents(filterBySearch);
  }, [searchVal]);

  // to make the api call to retrieve notifications data for issue #261
  useEffect(() => {
    (async () => {
      try {
        if (accountId && startDate && endDate) {
          setLoading(true);
          const events = await dashboardAPI.callAssetNotifications(
            accountId,
            startDate,
            endDate
          );
          const sortedEvents = events.sort((a: any, b: any) => b.notification_time - a.notification_time);
          setAssetEvents(sortedEvents);
          setMainAssetEvents(sortedEvents);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [accountId, startDate, endDate]);

  return (
    <Grid
      container
      className={classes.root}
      style={{
        pointerEvents: loading ? "none" : "all"
      }}
    >
      <div>
        {/* removed some css lines from "styles" tab and added a css class eventsPanel for responsive UI issue #24 */}
        <div className={classes.eventsPanel}>
          {/* Search functionality for issue #122 */}
          <Grid style={{ display: "flex" }}>
            <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              placeholder="Search notifications"
              value={searchVal}
              onChange={e => setSearchVal(e.target.value)}
            />
            <div className={classes.searchButton}>
              <img src={search} alt="" onClick={handleSearchClick} />
            </div>
          </Grid>
          {/* ading css class dateStyling for position of date button for responsive UI issue #24 */}
          <Grid className={classes.dateStyling}>
            <DateRangeSelector
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </Grid>
        </div>
        <TableContainer className={classes.eventsTable}>
          <Table
            style={{ width: "100%" }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                {/* Updated the column name from Alarm to Event. Github issue #96 */}
                <TableCell className={table.header}>Type</TableCell>
                <TableCell className={table.header} align="left">
                  Time
                </TableCell>
                <TableCell className={table.header} align="left">
                  Affects
                </TableCell>
                <TableCell className={table.header} align="left">
                  Description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                assetEvents?.map((event: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className={table.tableCell} align="left">
                        {event?.notification_type}
                      </TableCell>
                      <TableCell className={table.tableCell} align="left">
                        {format(
                          fromUnixTime(event?.notification_time),
                          // changed format from "MMMM dd,yyyy" to MM/dd/yyyy for web view issue #24
                          "MM/dd/yyyy | h:mm:ss a"
                        )}
                      </TableCell>
                      <TableCell className={table.tableCell} align="left">
                        {event?.notification_affect}
                      </TableCell>
                      <TableCell className={table.tableCell} align="left">
                        {event?.notification_desc}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {loading && (
          <div style={{ textAlign: "center", paddingTop: 6 }}>
            Loading events...
          </div>
        )}
      </div>
      {/* added div and css class for footer in responsive UI issue #24 */}
      <div className={classes.footerDiv}>
        <p className={classes.footerStyle}>
          {footerText}
        </p>
      </div>
    </Grid>
  );
};
export default NotificationStatusDashboard;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column"
    },
    margin: {
      margin: theme.spacing(1)
    },
    select: {
      width: "25%",
      marginBottom: "1em",
      height: "2.5em",
      border: `1px solid ${Colors.LightGray}`,
      padding: ".5rem .5rem .5rem 1rem",
      color: theme.palette.primary.light,
      fontWeight: "bold",
      "& option": {
        color: theme.palette.primary.light,
        fontWeight: "bold"
      },

      //added media query for responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        color: "lightblue"
      }
    },
    sortSelect: {
      marginBottom: "1em",
      height: "2.5em",
      border: `1px solid ${Colors.LightGray}`,
      padding: ".5rem .5rem .5rem 1rem",
      color: theme.palette.primary.light,
      fontWeight: "bold",
      "& option": {
        color: theme.palette.primary.light,
        fontWeight: "bold"
      }
    },
    searchInput: {
      width: "30%",
      marginLeft: "39%",
      marginBottom: "1em",
      height: "2.5em",
      border: `1px solid ${Colors.LightGray}`,
      padding: ".5rem .5rem .5rem 1rem",
      color: theme.palette.primary.light,
      fontWeight: "bold",
      "& option": {
        color: theme.palette.primary.light,
        fontWeight: "bold"
      }
    },
    textField: {
      width: "25ch"
    },
    sortByDropdown: { marginLeft: "2em" },
    notificationsContainer: {
      // Changing the css to build a grid layout for issue #140
      display: "grid",
      gridTemplateAreas: '"one two" "three three"', //adding grid template areas for tiles and items for issue #140
      gridTemplateColumns: "repeat(2,1fr)",
      columnGap: "1.5rem",
      marginTop: "1rem",

      [`@media (max-width: 800px)`]: {
        marginLeft: "1.25rem",
        marginRight: "1.25rem"
      },

      // added media query for all notification tiles to stack on top of one another till 1300 resolution for issue #140
      [`@media (max-width: 1300px)`]: {
        gridTemplateColumns: "repeat(1,1fr)",
        gridTemplateAreas: '"one" "two" "three" "four" "five"'
      }
    },
    search: {
      display: "flex",
      flexDirection: "row",
      height: "2.5em",
      marginLeft: "auto"
    },
    inputRoot: {
      border: "1px solid #DDD",
      borderRadius: 0,
      width: "100%",
      backgroundColor: "#fff"
    },
    inputInput: {
      paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
      width: "100%"
    },
    searchButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      cursor: "pointer",
      backgroundColor: `${theme.palette.primary.light}`,
      width: 45
    },
    //adding css class for styling footer div in responsive UI issue #24
    footerDiv: {
      display: "none",
      [`@media (max-width: 800px)`]: {
        marginBottom: "0",
        marginTop: "15px",
        backgroundColor: "#EEEDE7",
        display: "block"
      }
    },
    //adding css class for styling footer in responsive UI issue #24
    footerStyle: {
      [`@media (max-width: 800px)`]: {
        color: "steelblue",
        fontSize: "13px",
        paddingLeft: "8px",
        alignItems: "center"
      }
    },
    eventsPanel: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: "1rem 0",
      [`@media (max-width: 800px)`]: {
        display: "block"
      }
    },
    //adding css class for styling date shown under events tab in responsive UI issue #24
    dateStyling: {
      [`@media (max-width: 700px)`]: {
        marginLeft: "28px"
      },
      // media query added for ipad issue #24
      [`@media (max-width: 800px)`]: {
        float: "right",
        marginBottom: "20px"
      }
    },
    //added css class for styling events table in responsive UI issue #24
    eventsTable: {
      maxHeight: "500",
      //Updated the media query for Github issue #59
      [`@media (max-width: 584px)`]: {
        // maxWidth: "330px",
        overflowX: "auto"
      }
    }
  })
);
