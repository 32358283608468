/* Issue fixed for github issue #16, issue #28,issue #132,issue #253,issue #263 */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import Button from "components/common-buttons/Button";
import { devicesAPI } from "services/devicesAPI";
import { useStyles } from "./tabStyles";
import { fieldView } from "utils/constants";
import { Colors } from "common/Colors"; //importing for styling the button when disabled for issue #132

type ParametersProps = {
  deviceId: string;
  parameter: any;
  index: number;
  updateRowInfo?: Function;
  jsonObject?: any;
  assetId: any;
  disableButtonOnClick?: Function; //Adding onClick function to disable button for issue #132
  buttonDisable?: any; //Adding variable to check if button is clicked for issue #132
};
type SelectChangeEvent = React.ChangeEvent<HTMLSelectElement>;
type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

export const DecodingEnabledSelect: React.FC<ParametersProps> = ({
  deviceId,
  jsonObject,
  parameter,
  assetId,
  index,
  updateRowInfo,
  disableButtonOnClick, //Adding onClick function to disable button for issue #132
  buttonDisable //Adding variable to check if button is clicked for issue #132
}) => {
  const [selectVal, setSelectVal] = useState("0");
  const classes = useStyles();
  //Added role variable for checking the role. Github issue #16
  const selectedAccountUserRole = useSelector(
    (state: RootState) => state.AppSlice?.selectedAccount?.role || ""
  );
  const applyControlReq = async () => {
    try {
      // Added to send convert the value to the power of 2 for issue #253
      let newNumb = Number(selectVal);
      // to check if text decoding is bitwise to number for issue #253
      if (Number(parameter.text_decoding) == 2) {
        // condition to convert only if selected val is non negative for issue #253
        if (newNumb >= 0) {
          let bin = "1";
          if (selectVal !== "0") {
            for (let i = 0; i < parseInt(selectVal); i++) {
              bin = bin + "0";
            }
          }
          let dec = parseInt(bin, 2);
          newNumb = dec;
        } else {
          newNumb = 0;
        }
        // to check if text decoding is disabled for issue #253
      } else if (Number(parameter.text_decoding) == 0) {
        // removed the conditions so that negative values can be sent for issue #263
        // if (newNumb >= 0) newNumb = Number(selectVal);
        // else newNumb = 0;
        newNumb = Number(selectVal);
      }
      await devicesAPI.controlRequest(
        deviceId,
        parameter.parameter_number,
        newNumb,
        assetId // Added the asset Id in the api call. Github issue #28
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.parameterButtonContainer}>
      {/* Added the condition for select box to be greyed out if the user role is field view only. Github issue #16 */}
      <select
        className={
          selectedAccountUserRole == fieldView
            ? classes.parameterSelectDisabled
            : classes.parameterSelect
        }
        value={selectVal}
        onChange={(event: SelectChangeEvent) =>
          setSelectVal(event.target.value)
        }
      >
        {jsonObject(parameter.text_json).map((item: any, index: number) => (
          <option key={item.name + index} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>
      <Button
        type="button"
        color="defaultTheme"
        // Added style and condition to disable buttons for 3s when clicked for issue #132
        disabled={buttonDisable[index]}
        style={{
          backgroundColor: buttonDisable[index] ? Colors.Gray : "",
          cursor: buttonDisable[index] ? "auto" : "pointer"
        }}
        onClick={() => {
          //Function to disable button for 3s for issue #132
          if (disableButtonOnClick) disableButtonOnClick(index);
          applyControlReq();
        }}
      >
        Apply
      </Button>
    </div>
  );
};

export const DecodingDisabledInput: React.FC<ParametersProps> = ({
  deviceId,
  parameter,
  assetId,
  index,
  updateRowInfo,
  disableButtonOnClick, //Adding onClick function to disable button for issue #132
  buttonDisable //Adding variable to check if button is clicked for issue #132
}) => {
  const [inputVal, setInputVal] = useState("");
  const classes = useStyles();
  //Added role variable for checking the role. Github issue #16
  const selectedAccountUserRole = useSelector(
    (state: RootState) => state.AppSlice?.selectedAccount?.role || ""
  );

  const applyControlReq = async () => {
    try {
      // Added to send convert the value to the power of 2 for issue #253
      let newNum = Number(inputVal);
      // to check if text decoding is bitwise to number for issue #253
      if (Number(parameter.text_decoding) == 2) {
        // condition to convert only if selected val is non negative for issue #253
        if (newNum >= 0) {
          let bin = "1";
          if (inputVal !== "0") {
            for (let i = 0; i < parseInt(inputVal); i++) {
              bin = bin + "0";
            }
          }
          let dec = parseInt(bin, 2);
          newNum = dec;
        } else {
          newNum = 0;
        }
        // to check if text decoding is disabled for issue #253
      } else if (Number(parameter.text_decoding) == 0) {
        // removed the conditions so that negative values can be sent for issue #263
        // if (newNum >= 0) newNum = Number(inputVal);
        // else newNum = 0;
        newNum = Number(inputVal);
      }
      await devicesAPI.controlRequest(
        deviceId,
        parameter.parameter_number,
        newNum,
        assetId // Added the asset Id in the api call. Github issue #28
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.parameterButtonContainer}>
      {/* Added the condition for input box to be greyed out and placeholder to be blank if the user role is field view only. Github issue #16 */}
      <input
        type="text"
        inputMode="decimal"
        className={
          selectedAccountUserRole == fieldView
            ? classes.parameterSelectDisabled
            : classes.parameterSelect
        }
        style={{ cursor: "default" }}
        onChange={(event: ChangeEvent) => setInputVal(event.target.value)}
        placeholder={selectedAccountUserRole == fieldView ? "" : "Enter"}
      />
      <Button
        type="button"
        color="defaultTheme"
        // Added style and condition to disable buttons for 3s when clicked for issue #132
        disabled={buttonDisable[index]}
        style={{
          backgroundColor: buttonDisable[index] ? Colors.Gray : "",
          cursor: buttonDisable[index] ? "auto" : "pointer"
        }}
        onClick={() => {
          //Function to disable button for 3s for issue #132
          if (disableButtonOnClick) disableButtonOnClick(index);
          applyControlReq();
        }}
      >
        Apply
      </Button>
    </div>
  );
};
