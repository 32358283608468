import React, { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { otaAPI } from "services/otaAPI";
import LoadSpinner from "common/LoadSpinner";
import { Colors } from "common/Colors";
import { TenantFirmwareProps } from "./types";

type SoftwareUpdatesProps = {};

const SoftwareUpdates: React.FC<SoftwareUpdatesProps> = () => {
  const classes = useStyles();
  // const dispatch = useDispatch();

  const [tenantFirmwareList, setTenantFirmwareList] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const { tenant_id } = useSelector(
    (state: RootState) => state.AccountSlice.accountInformation
  );

  useEffect(() => {
    (async () => {
      const firmwareList = await otaAPI.retrieveFirmwareInTenant(tenant_id);
      setTenantFirmwareList(firmwareList);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <div style={{ width: "100%" }}>
      <LoadSpinner size="90vh" />
    </div>
  ) : !tenantFirmwareList.length ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
        width: "100%",
      }}
    >
      No firmware for current account to display
    </div>
  ) : (
    <Grid container>
      <Grid item xs={12} className={classes.root}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.columnText}>
                  Application name
                </TableCell>
                <TableCell className={classes.columnText}>
                  Application part number
                </TableCell>
                {/* <TableCell className={classes.columnText}>Version</TableCell> */}
                {/* <TableCell className={classes.columnText}>
                  Firmware ID
                </TableCell> */}
                <TableCell className={classes.columnText}>
                  Description
                </TableCell>
                {/* <TableCell className={classes.columnText}>Account(s)</TableCell>
                <TableCell className={classes.columnText}>Created by</TableCell>
                <TableCell className={classes.columnText}>Date</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {tenantFirmwareList.map(
                (firmware: TenantFirmwareProps, index: number) => {
                  return (
                    <TableRow
                      key={firmware.firmware_id + index}
                      style={{
                        cursor: "default",
                        position: "relative",
                        zIndex: -1,
                      }}
                    >
                      <TableCell className={classes.tableCell}>
                        {firmware.application_name}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {firmware.part_number}
                      </TableCell>
                      {/* <TableCell className={classes.tableCell}>
                        {firmware.version}
                      </TableCell> */}
                      {/* <TableCell className={classes.tableCell}>
                        {firmware.firmware_id}
                      </TableCell> */}
                      <TableCell className={classes.tableCell}>
                        {firmware.description}
                      </TableCell>
                      {/* <TableCell className={classes.tableCell}>
                        BasinTrak
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        V Janardhan
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        1 Aug 2020 | 4:30 PM
                      </TableCell> */}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default SoftwareUpdates;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowY: "auto",
    },
    lineBreak: {
      width: "100%",
      border: `1px solid ${Colors.LightGray}`,
    },
    table: {
      minWidth: "100%",
      marginTop: "1rem",
    },
    columnText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      // padding: 0,
      borderTop: "2px solid #DDD",
      borderBottom: "2px solid #DDD",
    },
    tableCell: {
      padding: "1rem",
      margin: 0,
      borderBottom: "2px solid #DDD",
      fontSize: 14,
    },
  })
);
