/* Issue fixed for github issue #16,issue #163,issue #215,issue #227,issue #243 */
import { Box } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Colors } from "common/Colors";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    expandedContainer: {
      display: "flex",
      flexDirection: "column"
    },
    expandedItems: {
      display: "grid",
      gridTemplateColumns: "30% 1fr"
    },
    rowedItems: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      flexWrap: "wrap"
    },
    lightGrayPill: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 25,
      height: 25,
      width: "fit-content",
      background: Colors.LightGray,
      textTransform: "uppercase",
      fontWeight: 600,
      padding: "0 1em",
      margin: "0 1em 0 0"
    },
    redPill: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 25,
      height: 25,
      width: "fit-content",
      color: "white",
      background: Colors.Red,
      textTransform: "uppercase",
      fontWeight: 600,
      padding: "0 1em",
      margin: "0 1em 0 0"
    },
    greenPill: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 25,
      height: 25,
      width: "fit-content",
      color: "black",
      background: Colors.Green,
      textTransform: "uppercase",
      fontWeight: 600,
      padding: "0 1em",
      margin: "0 1em 0 0"
    },
    yellowPill: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 25,
      height: 25,
      width: "fit-content",
      color: "black",
      background: Colors.Yellow,
      textTransform: "uppercase",
      fontWeight: 600,
      padding: "0 1em",
      margin: "0 1em 0 0"
    },
    wrappedExpandedItems: {
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap"
      // removed maxHeight to display all events in single column for issue #163
    },
    paramName: {
      "&:hover": {
        cursor: "pointer"
      }
    },
    headerCollapsed: {
      color: theme.palette.primary.light,
      padding: 0,
      margin: 0,
      fontSize: 20
    },
    headerExpanded: {
      color: Colors.White,
      padding: 0,
      margin: 0,
      fontSize: 20
    },
    flex: {
      display: "flex",
      alignItems: "center"
    },
    paperStylesCollapsed: {
      margin: "1em 0",
      border: `solid 1px ${Colors.LightGray}`,
      borderLeft: `solid 5px ${theme.palette.primary.light}`,
      borderRadius: 0,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "1em",
      paddingLeft: "1em",
      cursor: "pointer"
    },
    paperStylesExpanded: {
      margin: "1em 0",
      border: "solid 1px lightgrey",
      backgroundColor: theme.palette.primary.light,
      borderRadius: 0,
      color: Colors.White,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "1em",
      paddingLeft: "1em",
      cursor: "pointer"
    },
    expandIconCollapsed: {
      color: theme.palette.primary.light,
      "&:hover": { cursor: "pointer" }
    },
    expandIconOpen: {
      color: Colors.White,
      padding: 0,
      borderRadius: "50%",
      "&:hover": { cursor: "pointer" }
    },
    collapseBarInfo: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      fontSize: 20
    },
    online: {
      color: Colors.BasinBlue
    },
    offline: {
      color: Colors.Pink
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end"
    },
    parameterButtonContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "1rem"
    },
    buttonStyles: {
      // color: Colors.White,
      // height: 30,
      // border: "none",
      // padding: ".5rem 1rem",
      border: "none",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.primary.light,
      height: 30,
      color: Colors.White,
      padding: ".5rem 1rem",
      cursor: "pointer",
      outline: "none",
      "&:hover": {
        opacity: ".8"
      }
    },
    parameterSelect: {
      cursor: "pointer",
      width: 75.5,
      [theme.breakpoints.down("sm")]: { // For screens smaller than 800px
        fontSize: "16px",
      },
    },
    /* Added this css class for the disabled of input and select Box. Github issue #16 */
    parameterSelectDisabled: {
      cursor: "pointer",
      width: 75.5,
      backgroundColor: "grey",
      [theme.breakpoints.down("sm")]: { // For screens smaller than 800px
        fontSize: "16px",
      },
    },
    applyButton: {
      padding: "0 1rem",
      color: Colors.White,
      backgroundColor: theme.palette.primary.light,
      cursor: "pointer",
      border: "none",
      height: 30,
      width: 75.5
    },
    cancelButton: {
      padding: "0 1rem",
      color: theme.palette.primary.light,
      backgroundColor: Colors.White,
      cursor: "pointer",
      height: 30,
      width: 75.5,
      border: `1px solid ${theme.palette.primary.light}`
    },
    defaultTabButton: {
      padding: "0 1rem",
      color: Colors.White,
      backgroundColor: theme.palette.primary.light,
      cursor: "pointer",
      border: "none",
      height: 30
    },
    deviceParameterChartContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: 450,
      backgroundColor: Colors.Gray,
      color: Colors.White,
      fontSize: 16
    },
    // Added new css class for issue #215
    exportIcon: {
      stroke: theme.palette.primary.light,
      marginRight: ".5rem"
    },
    // Added new css class for issue #227
    chipSelected: {
      opacity: 1
    },
    // Added new css class for issue #227
    chipNotSelected: {
      backgroundColor: "cornflowerblue",
      opacity: 0.3
    },
    // Added new css class for issue #243
    inputGroup: {
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem"
      },
      "& input": {
        width: "100%",
        padding: ".5rem 1rem",
        border: `1px solid ${Colors.LightGray}`
      }
    },
    // Added new css class for issue #243
    label: {
      fontSize: "14px"
    },
    // Added new css class for issue #243
    formRoot: {
      height: 25
    },
    // Added new css class for issue #243
    keyName: {
      display: "inline-block",
      width: 180,
      margin: "0 16px 0 0"
    },
    // Added new css class for issue #243
    testDeviceKeyName: {
      display: "inline-block",
      width: 180
    },
    lineBreak: {
      width: "100%",
      border: "none",
      borderBottom: `1px dotted ${Colors.LightGray}`
    },
    // Added new css class for issue #243
    parameterInfo: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  })
);
