/*Issue fixed for github issue #74,issue #261*/
import { DashboardData, GroupsList } from "features/real-time-status-page/types";
import { axiosCall, URL, Method } from "./config";

// let req = 0;

export const dashboardAPI = {
  //#region
  // API BELOW DEPRECATED
  callInitialDashboard: async (dashboard_id: string) => {
    const response = await axiosCall(Method.POST, `${URL()}/dashboard`, {
      dashboard_id
    });
    return response.data;
  },
  getDashboardId: async (
    tenant_id: string,
    groups: { asset_group_id: string }[]
  ) => {
    const response = await axiosCall(Method.POST, `${URL()}/dashboard`, {
      tenant_id,
      groups
    });
    //Changed the response type as done in the backend for issue #74
    return response.data as DashboardData;
  },
  //Not using this API as it is removed from backend for issue #74
  getDashboardDataFromId: async (dashboard_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/dashboard/get_dashboard`,
      {
        dashboard_id
      }
    );
    return response.data as DashboardData;
  },
  openGroup: async (asset_group_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/dashboard/open_group`,
      {
        asset_group_id
      }
    );
    //Changed the response type as done in the backend for issue #74
    return response.data as DashboardData;
  },
  openGroupAll: async (asset_group_ids: GroupsList[]) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/dashboard/open_group_all`,
      {
        groups: asset_group_ids
      }
    );
    // Changed the response type as done in the backend for issue #74
    return response.data as DashboardData;
  },
  openComplexAsset: async (asset_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/dashboard/open_complex_asset`,
      {
        asset_id
      }
    );
    return response.data;
  },
  openAsset: async (asset_id: string) => {
    const response = await axiosCall(
      Method.POST,
      `${URL()}/dashboard/open_asset`,
      {
        asset_id
      }
    );
    return response.data;
  },
  callAssetEvents: async (
    asset_id: string,
    ts_start: number,
    ts_end: number
  ) => {
    const response = await axiosCall(Method.POST, `${URL()}/events/asset`, {
      asset_id,
      ts_start,
      ts_end
    });
    return response.data.Items;
  },
  // Added API for notifications for issue #261
  callAssetNotifications: async (
    tenant_id: string,
    ts_start: number,
    ts_end: number
  ) => {
    const response = await axiosCall(Method.POST, `${URL()}/tenant/notifications`, {
      tenant_id,
      ts_start,
      ts_end
    });
    return response.data;
  },
  // Added api for exceptions for issue #261
  getAllExceptions: async (
    tenant_id: string,
    ts_start: number,
    ts_end: number
  ) => {
    const response = await axiosCall(Method.POST, `${URL()}/tenant/exceptions`, {
      tenant_id,
      ts_start,
      ts_end
    });
    return response.data;
  },
  // Added api for exceptions for issue #328
  createException: async (
    tenant_id: string,
    event_name: string,
    priority: number,
    user_name: string,
    asset_list: string[],
  ) => {
    const response = await axiosCall(Method.POST, `${URL()}/tenant/create_exception `, {
      tenant_id,
      event_name,
      priority,
      user_name,
      asset_list
    });
    return response.data;
  },
  // Added api for exceptions for issue #261
  getException: async (
    tenant_id: string,
    exception_id: string,
  ) => {
    const response = await axiosCall(Method.POST, `${URL()}/tenant/get_exception `, {
      tenant_id,
      exception_id
    });
    return response.data;
  },
  // Added api for updating exceptions for issue #261
  updateException: async (
    tenant_id: string,
    exception_id: string,
    root_cause: string,
    type: number,
    user: string,
    desc: string,
    assigned_to: any[]
  ) => {
    const response = await axiosCall(Method.POST, `${URL()}/tenant/update_exception`, {
      tenant_id,
      exception_id,
      root_cause,
      type,
      user,
      desc,
      assigned_to
    });
    return response.data;
  },
  // Added api for getting root cause list for issue #261
  getRootCauses: async (
    tenant_id: string,
  ) => {
    const response = await axiosCall(Method.POST, `${URL()}/tenant/exception/root_causes `, {
      tenant_id
    });
    return response.data;
  },
};
