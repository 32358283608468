import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  callCategoryList: boolean;
  assetSettingsName: string;
  isAssetDashboard: boolean;
} = {
  callCategoryList: false,
  assetSettingsName: "",
  isAssetDashboard: false,
};

const assetCategorySlice = createSlice({
  name: "AssetCategorySlice",
  initialState,
  reducers: {
    setCallCategoryList: (state, action: PayloadAction<boolean>) => {
      state.callCategoryList = action.payload;
    },
    setAssetSettingsName: (state, action: PayloadAction<string>) => {
      state.assetSettingsName = action.payload;
    },
    setAssetDashboard: (state, action: PayloadAction<boolean>) => {
      state.isAssetDashboard = action.payload;
    },
  },
});

export const {
  setCallCategoryList,
  setAssetSettingsName,
  setAssetDashboard,
} = assetCategorySlice.actions;
export default assetCategorySlice.reducer;
