/* Issue fixed for github issue #24, issue #57, issue #36,issue #82,issue #140*/
import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import Chip from "@material-ui/core/Chip";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as Folder } from "assets/bt-assets/group.svg";
import SettingsInputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";
import {
  handleActiveHourBar,
  handleActiveOverHourBar,
  handleActiveOverADay,
  handleActiveOverWeekBar
} from "utils/connectivityBarHandler";
import { Colors } from "common/Colors";
import { BarInformationProps } from "./types";
import GoogleLinkButton from "components/common-buttons/GoogleLinkButton";
import { useMediaQuery } from "react-responsive"; //for issue #82
// import { icon } from "leaflet";
// import id from "date-fns/esm/locale/id/index.js";
// import { Colors } from "common/Colors";

type AssetStatusCardProp = {
  wholeView?: boolean;
  group?: any;
  handleGroup?: Function;
  isMarker?: boolean;
  index: any; //Added index props for issue #82
};
const AssetStatusCard: React.FC<AssetStatusCardProp> = ({
  wholeView = true,
  group,
  handleGroup,
  isMarker = false
}) => {
  const classes = useStyles();
  const [pieCells, setPieCells] = useState<number[] | null>([1, 4]);
  const [markerLocation, setMarkerLocation] = useState([]);
  const [assetInformation, setAssetInformation] = useState([
    {
      name: "okAssets",
      value: 0,
      fill: Colors.Green
    },
    {
      name: "warningAssets",
      value: 0,
      fill: Colors.Green
    },
    {
      name: "errorAssets",
      value: 0,
      fill: Colors.Green
    }
  ]);
  const [deviceInformation, setDeviceInformation] = useState<
    BarInformationProps[]
  >([
    {
      name: "",
      lessThanHour: 0,
      overAnHour: 0,
      overADay: 0,
      overAWeek: 0
    }
  ]);

  let tooltip: string;

  //added variable to check if it is mobile view or web view for issue #82
  var ismobileview = useMediaQuery({ query: "(max-width: 800px)" });

  const handleAssetInformation = useCallback(() => {
    const {
      critical_count,
      running_count,
      stopped_count,
      warning_count
    } = group.notification_count;
    let totalAssets = running_count + stopped_count;
    let errorAssets = critical_count / totalAssets;
    let warningAssets = warning_count / totalAssets;
    //Updated the calculations part for showing proper running assets. Github issue #36
    let okAssets =
      (totalAssets - (critical_count + warning_count)) / totalAssets;
    setAssetInformation([
      {
        name: "okAssets",
        value: okAssets,
        fill: Colors.Green
      },
      {
        name: "warningAssets",
        value: warningAssets,
        fill: Colors.Yellow
      },
      {
        name: "errorAssets",
        value: errorAssets,
        fill: Colors.Red
      }
    ]);
  }, [group.notification_count]);

  const handleDeviceInformation = useCallback(() => {
    const {
      devices_active,
      devices_active_over_day,
      devices_active_over_hour,
      devices_active_over_week
    } = group.active_counts;

    setDeviceInformation([
      {
        name: "Device information",
        lessThanHour: devices_active,
        overAnHour: devices_active_over_hour,
        overADay: devices_active_over_day,
        overAWeek: devices_active_over_week
      }
    ]);
  }, [group.active_counts]);

  const CustomTooltip = ({ active, payload }: any) => {
    let displayDiv;
    let color;

    if (!active || !tooltip) return null;
    for (const bar of payload) {
      if (bar.dataKey === tooltip) {
        switch (bar.name) {
          case "lessThanHour":
            displayDiv = `${bar.value} asset(s) communicated within the hour.`;
            color = bar.color;
            break;
          case "overAnHour":
            displayDiv = `${bar.value} asset(s) did not communicate within the last hour.`;
            color = bar.color;
            break;
          case "overADay":
            displayDiv = `${bar.value} asset(s) did not communicate in over 24 hours.`;
            color = bar.color;
            break;
          case "overAWeek":
            displayDiv = `${bar.value} asset(s) did not communicate over the last week.`;
            color = bar.color;
            break;
          default:
            break;
        }
      }
    }

    return (
      <div className={classes.tooltip} style={{ background: color }}>
        {displayDiv}
      </div>
    );
  };

  const CustomTooltipTwo = ({ active, payload }: any) => {
    let text;
    let color;
    if (payload.length > 0 && payload[0].name === "okAssets") {
      text = "Assets Ok.";
      color = Colors.Green;
    }
    if (payload.length > 0 && payload[0].name === "warningAssets") {
      // console.log(payload);
      text = "Assets in warning.";
      color = Colors.Yellow;
    }
    if (payload.length > 0 && payload[0].name === "errorAssets") {
      // console.log(payload);
      text = "Assets Critical.";
      color = Colors.BasinRed;
    }
    return (
      <div className={classes.tooltiptwo} style={{ background: color }}>
        {text}
      </div>
    );
  };

  useEffect(() => {
    if (group) {
      // console.log({ group });
      handleAssetInformation();
      handleDeviceInformation();
    }
  }, [group, handleAssetInformation, handleDeviceInformation]);

  useEffect(() => {
    if (group) {
      setMarkerLocation(group.gio_location);
    }
  }, [group]);

  return (
    <div className={wholeView ? classes.rootWholeView : classes.rootHalfView}>
      {/* Added css class outerLayout for styling the grid layout for groups for issue #140 */}
      <div className={classes.outerLayout}>
        {/* div for group icon to be aligned with 1st notification tile start for issue #140 */}
        <div className={classes.folderOutline}>
          <SvgIcon>
            <Folder />
          </SvgIcon>
        </div>
        {/* Added div with style for group name to be aligned with chart and text for issue #140 */}
        <div style={{ gridArea: "two", display: "flex", alignItems: "center" }}>
          <p
            className={classes.groupName}
            onClick={() => {
              handleGroup &&
                handleGroup(group.group_name, group.asset_group_id);
            }}
          >
            {group.group_name}
          </p>
          {/* Added css class chipSize for issue #82 */}
          <Chip size="small" label="Group" className={classes.chipSize} />
        </div>
        {/* Added div with style for chart and text for issue #140 */}
        <div style={{ gridArea: "four" }}>
          {/* Added grid layout to divide structure into chart space and text space for issue #140 */}
          <div className={classes.pieChartGrid}>
            <div>
              {pieCells && (
                // Added div with height and width for chart so that it size itself according to screen resolution for issue #140
                <div style={{ height: "max(125px,20vh)", width: "100%" }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        isAnimationActive={false}
                        outerRadius={"80%"} //Changed radius to percent value for issue #140
                        // Changed origin of piechart for alignment for issue #140
                        cx={"50%"}
                        cy={"50%"}
                        innerRadius={"40%"} //Changed radius to percent value for issue #140
                        dataKey="value"
                        data={assetInformation}
                        fill="#fff"
                      />
                      <Tooltip cursor={false} content={<CustomTooltipTwo />} />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              )}
            </div>
            <div className={classes.groupLevelAlignment}>
              <p className={classes.errorText}>
                {group.notification_count.critical_count} Critical{" "}
                {/* Critical  Updated the text Error to Critical. Github issue #57 */}
              </p>
              <p className={classes.warningText}>
                {group.notification_count.warning_count} Warnings
              </p>
              <p className={classes.regularText}>
                {group.groups_in_group.length} Groups |{" "}
                {group.notification_count.total_count} Assets{" "}
                {/* Critical  Updated the text as Assets(Capital A). Github issue #57 */}
              </p>
              <p className={classes.regularText}>
                {group.notification_count.running_count} Running |{" "}
                {/* Critical  Updated the text Running(Capital R). Github issue #57 */}
                <span className={classes.errorText}>
                  {group.notification_count.stopped_count} Stopped
                </span>
              </p>
            </div>
          </div>
          {/* Added div for connectivitybar alignment for issue #140 */}
          <div>
            <div className={classes.connectivitybar}>
              {/* Added css class settingsIcon for icon styling for issue #82 */}
              <SettingsInputAntennaIcon className={classes.settingsIcon} />
              {/* Changed width to 50% and added condition for css classes for issue #82 */}
              <ResponsiveContainer
                width={"100%"}
                height={15}
                className={classes.barCharts}
              >
                <BarChart
                  layout="vertical"
                  data={deviceInformation}
                  margin={{ left: 6 }}
                  stackOffset="expand"
                >
                  <XAxis hide type="number" />
                  <YAxis hide type="category" dataKey="name" stroke="#FFFFFF" />
                  <Tooltip cursor={false} content={<CustomTooltip />} />
                  <Bar
                    dataKey="lessThanHour"
                    fill={Colors.BasinBlue}
                    stackId="a"
                    radius={handleActiveHourBar(deviceInformation[0])}
                    name="lessThanHour"
                    onMouseOver={() => (tooltip = "lessThanHour")}
                  />
                  <Bar
                    dataKey="overAnHour"
                    fill={Colors.BasinDarkBlue}
                    stackId="a"
                    radius={handleActiveOverHourBar(deviceInformation[0])}
                    name="overAnHour"
                    onMouseOver={() => (tooltip = "overAnHour")}
                  />
                  <Bar
                    dataKey="overADay"
                    fill={Colors.BasinPurple}
                    stackId="a"
                    radius={handleActiveOverADay(deviceInformation[0])}
                    name="overADay"
                    onMouseOver={() => (tooltip = "overADay")}
                  />
                  <Bar
                    dataKey="overAWeek"
                    fill={Colors.Gray}
                    stackId="a"
                    radius={handleActiveOverWeekBar(deviceInformation[0])}
                    name="overAWeek"
                    onMouseOver={() => (tooltip = "overAWeek")}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>

            {/* //! lat,lng https://www.google.com/maps/search/?api=1&query=<lat>,<lng> */}
            {isMarker && (
              <GoogleLinkButton
                latitude={markerLocation[1]}
                longitude={markerLocation[0]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AssetStatusCard;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootHalfView: {
      display: "flex",
      flexDirection: "column",
      height: "auto",
      width: 450
    },
    rootWholeView: {
      display: "flex",
      flexDirection: "column",
      height: "auto",
      width: "auto"
      //Added media query for applying the width to the complex asset box. Github issue #82
      /* [`@media (min-width:801px)`]: {
        maxWidth: "290px",
      } */
    },
    folderOutline: {
      gridArea: "one",
      fontSize: "2em",
      marginRight: "0.5rem", //reduced margin for folder icon and text for issue #82
      color: `${theme.palette.primary.light}`
    },
    groupName: {
      display: "inline", //added to keep group name in line with icon for issue #140
      color: `${theme.palette.primary.light}`,
      fontWeight: 600,
      cursor: "pointer",
      zIndex: 100,
      margin: "0" //added margin 0 to overwrite default margin given by p tag for issue #140
    },
    locationInfo: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: "2em",
      fontWeight: 600
    },
    chartDetails: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)"
    },
    errorText: {
      color: "#d61834",
      marginBottom: ".1em",
      marginTop: 0,
      // Added media queries to make the text box approx same height as graph for issue #140
      [`@media (min-width: 1900px) and (max-width: 2500px)`]: {
        fontSize: "20px"
      },
      [`@media (min-width: 2501px)`]: {
        fontSize: "24px"
      }
    },
    warningText: {
      color: "#ccaa2d",
      marginBottom: ".1em",
      marginTop: 0,
      // Added media queries to make the text box approx same height as graph for issue #140
      [`@media (min-width: 1900px) and (max-width: 2500px)`]: {
        fontSize: "20px"
      },
      [`@media (min-width: 2501px)`]: {
        fontSize: "24px"
      }
    },
    regularText: {
      marginBottom: ".1em",
      marginTop: 0,
      // Added media queries to make the text box approx same height as graph for issue #140
      [`@media (min-width: 1900px) and (max-width: 2500px)`]: {
        fontSize: "20px"
      },
      [`@media (min-width: 2501px)`]: {
        fontSize: "24px"
      }
    },
    //added css class groupLevelAlignment to align text in single line for issue #82
    groupLevelAlignment: {
      marginLeft: "1rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    tooltip: {
      width: 150,
      height: 60,
      padding: 10,
      color: Colors.White,
      fontSize: 11
    },
    tooltiptwo: {
      width: 100,
      height: 30,
      padding: 10,
      color: Colors.White,
      fontSize: 11
    },
    //Added css class for left hand side styling of comms bar in mobile view for issue #82
    barCharts: {
      height: "15px"
    },
    //removed styles tab and added css class for issue #82
    chipSize: {
      marginLeft: "1em"
    },
    //removed styles tab and added css class for issue #82
    settingsIcon: {
      fontSize: "1em"
    },
    //adding css class with media query in place of "styles" for styling connectivity bar in responsive UI issue #24
    connectivitybar: {
      display: "flex",
      flexDirection: "row",
      marginBottom: "7% !important",
      [`@media (max-width: 800px)`]: {
        marginBottom: "5%"
      },
      // Added media queries for larger screens for issue #82
      [`@media (min-width: 1920px) and (max-width: 2400px)`]: {
        marginBottom: "4% !important"
      },
      [`@media (min-width: 2560px) and (max-width: 3000px)`]: {
        marginBottom: "4% !important"
      }
    },
    // Added class to style the layout at group level into a grid for issue #140
    outerLayout: {
      display: "grid",
      gridTemplateAreas: '"one two" "three four"'
    },
    // Added class to fix the alignment of the piechart and the text for issue #140
    pieChartGrid: {
      display: "grid",
      gridTemplateColumns: "1fr 2fr",
      margin: "1rem 0"
    }
  })
);
