/* Added file for github issue #261*/
import React, { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  setSuccessModalOpen,
  setSuccessModalDescription
} from "components/success-modal/successModalSlice";
import { InputLabel } from "components/input-label/InputLabel";
import Button from "components/common-buttons/Button";
import { useForm, Controller } from "react-hook-form";
import alertCircle from "assets/bt-assets/alert-circle.svg";
import { Colors } from "common/Colors";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { dashboardAPI } from "services/dashboardAPI";
import { getUnixTime, subDays, fromUnixTime, format } from "date-fns";
import { UpdateExceptionProps, UserChange } from "./types";
import Select from "react-select";
import AccountAPI from "services/accountsAPI";

export const exceptionStateList = [
  {
    name: "New",
    value: 0,
  },
  {
    name: "Open",
    value: 1,
  },
  {
    name: "In process",
    value: 2,
  },
  {
    name: "On hold",
    value: 3,
  },
  {
    name: "Closed",
    value: 4,
  },
];

interface ExceptionModalProps {
  exceptionObject: any;
  open: boolean;
  handleClose: Function;
  rootCauseLists: any;
  refreshExceptions: () => void;
}

interface LabelProps {
  type: 'state' | 'priority';
  value: number;
}
interface StyleProps {
  priority: number;
  state_latest: number;
}

const labels: { [key in LabelProps['type']]: { [key: number]: { label: string, color: string } } } = {
  state: {
    0: { label: 'New', color: '#56ccf2' },
    1: { label: 'Open', color: '#7030a0' },
    2: { label: 'In-process', color: '#307fe2' },
    3: { label: 'On-hold', color: '#f39c12' },
    4: { label: 'Closed', color: '#32363a' },
  },
  priority: {
    0: { label: 'Warning', color: 'orange' },
    1: { label: 'Critical', color: 'red' },
    3: { label: 'Note', color: 'green' },
  }
};
const getLabelProps = (type: LabelProps['type'], value: number) => {
  return labels[type][value] || { label: 'Unknown', color: 'grey' };
};

export const ExceptionModal = ({
  exceptionObject,
  open,
  handleClose,
  rootCauseLists,
  refreshExceptions,
}: ExceptionModalProps) => {
  const classes = useStyles({
    priority: exceptionObject.priority,
    state_latest: exceptionObject.state_latest,
  });
  const dispatch = useDispatch();

  const [rootCauses, setRootCauses] = useState<any>([]);
  const [disableUpdateButton, setDisableUpdateButton] = useState(false);
  const [formComment, setFormComment] = useState("");
  const [formState, setFormState] = useState(exceptionObject?.state_latest);
  const [formCause, setFormCause] = useState(0);
  const [recipentUserList, setRecipentUserList] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);

  const { accountId } = useSelector(
    (state: RootState) => state.AppSlice.selectedAccount
  );

  const { userInformation } = useSelector(
    (state: RootState) => state.AppSlice
  );

  const reCallUserList = useSelector(
    (state: RootState) => state.AccountSlice.callUsers
  );

  const getUserChange = (
    selectedUsers: { label: string; value: string }[] = [],
    assignedTo: { user_name: string; user_id: string }[] = []
  ): UserChange => {
    const selectedUserIds = new Set(selectedUsers.map(user => user.value));
    const assignedToUserIds = new Set(assignedTo.map(user => user.user_id));

    const added = selectedUsers
      .filter(user => !assignedToUserIds.has(user.value));
    const removed = assignedTo
      .filter(user => !selectedUserIds.has(user.user_id))
      .map(user => ({
        label: user.user_name,
        value: user.user_id
      }));

    return { added, removed };
  };

  const { register, handleSubmit, errors, getValues } = useForm<
    UpdateExceptionProps
  >();

  // function to get users list for issue #261
  useEffect(() => {
    const getUserList = async () => {
      try {
        var userListArr = [...recipentUserList];
        const userList = await AccountAPI.getUserList(accountId);

        userList.map((userObj: any, index: number) => {
          var temp = userObj?.given_name + " " + userObj?.family_name;
          var temp1 = { "label": temp, "value": userObj.user_id }
          userListArr.push(temp1)
        })
        setRecipentUserList(userListArr)
      } catch (error) {
        alert(error);
        console.log(error);
      }
    };

    if (reCallUserList) {
      getUserList();
    }

    getUserList();
  }, [accountId]);

  // function to initialize already selected values for issue #261 
  useEffect(() => {
    if (exceptionObject?.assigned_to && exceptionObject?.assigned_to.length > 0) {
      const tempUserArr: any[] = [];
      exceptionObject?.assigned_to.map((userObj: any) => {
        var temp = { "label": userObj?.user_name, "value": userObj?.user_id };
        tempUserArr.push(temp);
      });
      setSelectedUsers(tempUserArr);
      if (process.env.NODE_ENV === 'development') {
        console.log(tempUserArr);
      }
    }
  }, []);

  // function to handle adding of user to recipent list for issue #261
  const addUserToList = (user: any) => {
    try {
      if (user.length > 0) {
        setSelectedUsers(user);
      } else {
        setSelectedUsers([])
      }
    } catch (error) {
      console.log(error)
      alert(error);
    }
  };

  // function to handle the save exception button for issue #261
  const handleSaveException = async ({
    comment,
    change_state,
    root_cause,
    assigned_to
  }: UpdateExceptionProps) => {
    try {
      const selectedUserList = selectedUsers.map(
        (user: any) => {
          return {
            user_name: `${user.label}`,
            user_id: `${user.value}`,
          };
        }
      );
      const userName = userInformation?.given_name + " " + userInformation.family_name;

      const userChange = getUserChange(selectedUsers, exceptionObject.assigned_to);
      const addedLabels = userChange.added.map(user => user.label).join(', ');
      const removedLabels = userChange.removed.map(user => user.label).join(', ');

      let tempComment = "";
      if (addedLabels) {
        tempComment += `Added: ${addedLabels} | `;
      }

      if (removedLabels) {
        tempComment += `Removed: ${removedLabels} | `;
      }
      tempComment += comment;

      const updateException = await dashboardAPI.updateException(
        accountId,
        exceptionObject?.exception_id,
        String(root_cause),
        change_state,
        userName,
        tempComment,
        selectedUserList
      );

      refreshExceptions();
      handleClose();
      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription("Comment added successfully!"));
    } catch (error) {
      console.log(error)
      alert(error);
    }
  };

  // added useeffect with condition checking to disable the update button for issue #261
  useEffect(() => {
    if (
      formCause != 0 || formComment != "" || formState != exceptionObject.state_latest
    )
      setDisableUpdateButton(false);
    else
      setDisableUpdateButton(true);
  }, [formComment, formState, formCause]);

  // api call to get comments and list of root causes for issue #261
  useEffect(() => {
    (async () => {
      try {
        if (accountId) {
          const exception = await dashboardAPI.getException(
            accountId,
            exceptionObject?.exception_id
          );
          setRootCauses(exception);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [accountId]);

  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalOverlay}>
        <form onSubmit={handleSubmit(handleSaveException)}>
          <div className={classes.modalBody}>
            <div className={classes.header}>
              <p className={classes.headerText}>{exceptionObject.name}</p>
              <CloseIcon
                className={classes.icon}
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
            <div className={classes.statePriority}>
              <span className={classes.stateText}>
                {getLabelProps('state', exceptionObject.state_latest).label}
              </span>
              <span className={classes.priorityText}>
                {getLabelProps('priority', exceptionObject.priority).label}
              </span>
            </div>
            <div>
              <p className={classes.inputGroup}>
                <span className={classes.keyName}>Created by :</span>
                {exceptionObject.source}
              </p>
              <p className={classes.inputGroup}>
                <span className={classes.keyName}>Assets :</span>
                {exceptionObject.assets &&
                  exceptionObject.assets.map((exception: any) =>
                    exception
                  ).join(", ")
                }
              </p>
              <p className={classes.inputGroup}>
                <span className={classes.keyName}>Root cause :</span>
                {exceptionObject.root_cause_desc}
              </p>
              <div className={classes.inputGroup}>
                <InputLabel
                  label="Assigned to"
                  name="assigned_to"
                  errors={errors}
                />
                <Select
                  name="users"
                  styles={reactSelectStyles}
                  value={selectedUsers}
                  onChange={addUserToList}
                  options={recipentUserList}
                  maxMenuHeight={220}
                  classNamePrefix="select"
                  placeholder="Select users"
                  isMulti
                  isSearchable
                />
              </div>
              {rootCauses?.map((cause: any, index: number) => {
                return (
                  <p className={classes.inputGroup}>{format(
                    fromUnixTime(cause.time),
                    "MM/dd/yyyy | h:mm:ss a"
                  )}:{" "}
                    <span>
                      {cause?.desc}{" "}-{" "}{cause?.user}
                    </span>
                  </p>
                );
              })}
              <div className={classes.inputGroup}>
                <textarea
                  name="comment"
                  ref={register({
                    maxLength: {
                      value: 150,
                      message: `Comment must be less than ${150} characters`,
                    },
                  })}
                  defaultValue={""}
                  onChange={(e: any) => {
                    setFormComment(e.target.value);
                  }}
                  rows={2}
                  cols={55}
                />
              </div>
              <div className={classes.inputGroup}>
                <InputLabel
                  label="Change state"
                  name="change_state"
                  errors={errors}
                />
                <select
                  name="change_state"
                  ref={register({
                    valueAsNumber: true,
                    required: "This field is required."
                  })}
                  placeholder="Enter"
                  defaultValue={exceptionObject?.state_latest || 0}
                  onChange={(e: any) => {
                    setFormState(e.target.value);
                  }}
                >
                  {exceptionStateList.map(
                    (exceptionState: any) => {
                      return (
                        <option key={exceptionState.value} value={exceptionState.value}>
                          {exceptionState.name}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
              <div className={classes.inputGroup}>
                <InputLabel
                  label="Select root cause"
                  name="root_cause"
                  errors={errors}
                />
                <select
                  name="root_cause"
                  ref={register({
                    valueAsNumber: true,
                    required: "This field is required."
                  })}
                  placeholder="Enter"
                  defaultValue={Number(exceptionObject?.root_cause)}
                  onChange={(e: any) => {
                    setFormCause(e.target.value);
                  }}
                >
                  {rootCauseLists.map(
                    (exceptionState: any) => {
                      return (
                        <option key={exceptionState.value} value={exceptionState.value}>
                          {exceptionState.name}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
            </div>
            <div className={classes.actionItems}>
              <Button
                type="button"
                color="white"
                style={{ margin: "0 1em 0 0" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="defaultTheme"
                style={{
                  opacity: disableUpdateButton
                    ? "0.6"
                    : "unset"
                }}
                disabled={disableUpdateButton}
              >
                Update
              </Button>
            </div>
          </div>
        </form>
      </div >
    </Modal >
  );
};

export default ExceptionModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statePriority: {
      display: "flex",
      flexDirection: "row",
      alignItems: 'left',
      justifyContent: 'left',
      width: '100%',
      padding: "0 1rem",
    },
    priorityText: {
      color: 'white',
      backgroundColor: (props: StyleProps) => getLabelProps('priority', props.priority).color,
      fontSize: '14px',
      padding: "0.5rem 0.5rem",
      marginRight: "1.5rem",
      marginBottom: '8px',
      fontWeight: 'bold',
      borderRadius: '4px',
      display: 'inline-block',
    },
    stateText: {
      color: 'white',
      backgroundColor: (props: StyleProps) => getLabelProps('state', props.state_latest).color,
      fontSize: '14px',
      padding: "0.5rem 0.5rem",
      marginRight: "1.5rem",
      marginBottom: '8px',
      fontWeight: 'bold',
      borderRadius: '4px',
      display: 'inline-block',
    },
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)",
      overflowX: "hidden",
    },
    modalBody: {
      maxHeight: "90vh",
      overflowY: "auto",
      // width: "100%", // Make it use full width of the container
      maxWidth: "600px", // Maximum width for larger screens
      minWidth: "350px", // Maximum width for larger screens
      margin: "20px", // Center it horizontally
      backgroundColor: Colors.White,
      boxSizing: "border-box", // Ensure padding and borders are included in the width
      padding: "20px", // Adjust padding as needed
      [`@media (max-width: 800px)`]: { // For screens narrower than 800px
        maxWidth: "500px",
        padding: "15px",
      },
      [`@media (max-width: 500px)`]: { // For screens narrower than 500px
        maxWidth: "370px",
        padding: "40px",
      },
      [`@media (max-width: 350px)`]: { // For screens narrower than 350px
        maxWidth: "325px",
        padding: "20px",
      }
    },

    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0rem 1rem",
      margin: "0 0 1em 0",
      borderBottom: `1px solid ${Colors.LightGray}`
    },
    stateHeader: {
      display: "flex",
      padding: "0rem 2.5rem",
    },
    headerText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      fontSize: 24,
      padding: 0,
      margin: "1rem 0" // Add more space above and below the title
    },
    icon: {
      color: Colors.LightGray,
      cursor: "pointer",
      fontSize: "3rem"
    },
    inputGroup: {
      padding: "0.5rem 1rem", /* Adjusted to reduce side padding */
      width: "100%", /* Ensure it takes full width of the container */
      boxSizing: "border-box", /* Include padding and border in the element's total width and height */
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem"
      },
      "& textarea": {
        width: "100%", /* Ensure textarea takes full width of the container */
        padding: ".5rem 1rem",
        border: `1px solid ${Colors.LightGray}`,
        resize: "vertical", /* Allow resizing vertically if needed */
        boxSizing: "border-box" /* Include padding and border in the element's total width and height */
      },
      "& input": {
        width: "100%",
        padding: ".5rem 1rem",
        border: `1px solid ${Colors.LightGray}`
      },
      "& select": {
        width: "100%",
        border: `1px solid ${Colors.LightGray}`,
        padding: ".5rem 1rem",
        cursor: "pointer"
      }
    },
    actionItems: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "1rem 2.5rem",
      borderTop: `1px solid ${Colors.LightGray}`,
      padding: "1rem 0"
    },
    keyName: {
      display: "row",
      width: 180,
      margin: "0 16px 0 0"
    },
  })
);
const reactSelectStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: 0,
    border: `1px solid ${Colors.LightGray}`,
    cursor: "pointer"
  })
};
