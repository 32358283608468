import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeviceParametersProps } from "features/devices-page/device-settings/types";

const initialState: {
  groupName: string;
  newAssetName: string;
  deviceList: { label: string; value: string }[];
  currentAssetId: string;
  selectedParameterId: string;
  assetDeviceList: {
    device_id: string;
    device_name: string;
    show_notifications: boolean;
  }[];
  assetParameterList: DeviceParametersProps[];
  selectedDeviceId: string;
  parameterSelections: { label: string; value: string }[];
  isParameterSequenceActive: boolean;
} = {
  groupName: "",
  newAssetName: "",
  deviceList: [],
  currentAssetId: "",
  selectedParameterId: "",
  assetDeviceList: [],
  assetParameterList: [],
  selectedDeviceId: "",
  parameterSelections: [],
  isParameterSequenceActive: false,
};

const assetDashboardSlice = createSlice({
  name: "AssetDashboardSlice",
  initialState,
  reducers: {
    setGroupName: (state, action: PayloadAction<string>) => {
      state.groupName = action.payload;
    },
    setNewAssetName: (state, action: PayloadAction<string>) => {
      state.newAssetName = action.payload;
    },
    setDeviceSelection: (
      state,
      action: PayloadAction<{ label: string; value: string }[]>
    ) => {
      state.deviceList = action.payload;
    },
    setParameterSelection: (
      state,
      action: PayloadAction<{ label: string; value: string }[]>
    ) => {
      state.parameterSelections = action.payload;
    },
    setCurrentAssetId: (state, action: PayloadAction<string>) => {
      state.currentAssetId = action.payload;
    },
    setAssetDeviceList: (
      state,
      action: PayloadAction<
        {
          device_id: string;
          device_name: string;
          show_notifications: boolean;
        }[]
      >
    ) => {
      state.assetDeviceList = action.payload;
    },
    setAssetParameterList: (
      state,
      action: PayloadAction<DeviceParametersProps[]>
    ) => {
      state.assetParameterList = action.payload;
    },
    setSelectedDeviceId: (state, action: PayloadAction<string>) => {
      state.selectedDeviceId = action.payload;
    },
    setSelectedParameterId: (state, action: PayloadAction<string>) => {
      state.selectedParameterId = action.payload;
    },
    setParameterSequence: (state, action: PayloadAction<boolean>) => {
      state.isParameterSequenceActive = action.payload;
    },
  },
});

export const {
  setGroupName,
  setNewAssetName,
  setDeviceSelection,
  setParameterSelection,
  setCurrentAssetId,
  setSelectedParameterId,
  setAssetDeviceList,
  setAssetParameterList,
  setSelectedDeviceId,
  setParameterSequence,
} = assetDashboardSlice.actions;
export default assetDashboardSlice.reducer;
