//Added file for github issue #273
import React from "react";
import { useStyles } from "./styles";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import {
  setSuccessModalOpen,
  setSuccessModalDescription
} from "components/success-modal/successModalSlice";
import Button from "components/common-buttons/Button";
import { devicesAPI } from "services/devicesAPI";

type DeleteReportModalProps = {
  tenantId: string;
  reportId: string;
  reportName: string;
  closeModals: Function;
};

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const DeleteReportModal: React.FC<DeleteReportModalProps> = ({
  tenantId,
  reportId,
  reportName,
  closeModals
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const cancelAdd = () => {
    closeModals();
  };

  //function handling delete report for issue #273
  const deleteReport = async () => {
    try {
      const msgResponse = await devicesAPI.deleteReport(tenantId, String(reportId));
      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription("Report deleted successfully!"));
      closeModals();
    } catch (error) {
      alert(error);
      closeModals();
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalBody}>
        <div className={styles.headerDiv}>
          <p className={styles.headerText}>Remove report from account</p>
          <CloseIcon className={styles.icon} onClick={() => closeModals()} />
        </div>
        <div style={{ margin: "2rem 0 0 0" }}>
          <p className={styles.deleteDescription}>
            Are you sure you want to delete {reportName} report?
          </p>
        </div>
        <div className={styles.actionItems}>
          <Button color="white" type="button" onClick={cancelAdd}>
            Cancel
          </Button>
          <Button color="defaultTheme" type="button" onClick={deleteReport}>
            Delete report
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteReportModal;
