/* Issue fixed for github issue #54,issue #82,issue #140 */
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { ParameterProps } from "features/real-time-status-page/types";
import { displayColors } from "utils/displayDashChartColors";
import { Colors } from "common/Colors";
import { binaryFromJsonList } from "utils/binaryToText";
import { numberToTextKey } from "utils/constants";
import { binaryToTextKey } from "utils/constants";
import { getDecodedVal } from "utils/conversions";

type DonutChartProps = {
  params: ParameterProps;
};
//added index props for styling right and left tiles for issue #82
const DonutChart = ({ params }: DonutChartProps) => {
  const classes = useStyles();

  const ndl_width = 3;
  var status = "";
  // These are the inputs from our db and data
  const max = params.max_value;
  const hihi = params.high_high_value;
  const hi = params.high_value;
  const lo = params.low_value;
  const lolo = params.low_low_value;
  const min = params.min_value;
  const param = params.parameter_value; // This is the actual data input

  const pcent_val = (100 * (param - min)) / (max - min);
  const pcent_val_bound = pcent_val > 100 ? 100 : pcent_val < 0 ? 0 : pcent_val;
  if (pcent_val > 100) status = ">";
  if (pcent_val < 0) status = "<";

  // Convert data to percent
  const pcent_hi_max = (100 * (max - hihi)) / (max - min);
  const pcent_hi_hihi = (100 * (hihi - hi)) / (max - min);
  const pcent_lo_hi = (100 * (hi - lo)) / (max - min);
  const pcent_lolo_lo = (100 * (lo - lolo)) / (max - min);
  const pcent_min_lolo = (100 * (lolo - min)) / (max - min);
  const pcent_val_rem = 100 - pcent_val_bound;

  const {
    low_low_to_low_color,
    min_to_low_low_color,
    low_to_high_color,
    high_high_to_max_color,
    high_to_high_high_color
  } = params;

  const COLORS = [
    min_to_low_low_color,
    low_low_to_low_color,
    low_to_high_color,
    high_to_high_high_color,
    high_high_to_max_color
  ];

  const data_1 = [
    {
      name: "idx",
      hi_max: pcent_hi_max,
      hi_hihi: pcent_hi_hihi,
      lo_hi: pcent_lo_hi,
      lolo_lo: pcent_lolo_lo,
      min_lolo: pcent_min_lolo,
      val: pcent_val_bound
    }
  ];

  // data creation for red, yellow and green marking
  const data_2 = [
    { name: "A", value: pcent_hi_max },
    { name: "B", value: pcent_hi_hihi },
    { name: "C", value: pcent_lo_hi },
    { name: "D", value: pcent_lolo_lo },
    { name: "E", value: pcent_min_lolo },
    { name: "F", gauge_lo: pcent_val_bound },
    { name: "G", gauge_lo: pcent_val_rem }
  ];

  // Data creation for needle
  const data_3 = [
    { name: status, gauge_lo: pcent_val_bound },
    { name: "B", gauge_lo: ndl_width },
    { name: "C", gauge_lo: pcent_val_rem }
  ];

  const getFillColor = () => {
    const p = params;
    if (
      p.parameter_value >= p.min_value &&
      p.parameter_value <= p.low_low_value
    ) {
      return COLORS[0];
    }
    if (
      p.parameter_value > p.low_low_value &&
      p.parameter_value <= p.low_value
    ) {
      return COLORS[1];
    }
    if (p.parameter_value > p.low_value && p.parameter_value <= p.high_value) {
      return COLORS[2];
    }
    if (
      p.parameter_value > p.high_value &&
      p.parameter_value <= p.high_high_value
    ) {
      return COLORS[3];
    }
    if (
      p.parameter_value > p.high_high_value &&
      p.parameter_value <= p.max_value
    ) {
      return COLORS[4];
    }
    return COLORS[4];
  };

  const percentLabel = (props: any) => {
    return (
      <>
        <text
          // Changed the x and y coordinates of text from offset to percent values for issue #140
          x={"50%"}
          y={"50%"}
          textAnchor="middle"
          dominantBaseline="middle"
          fill={Colors.Black}
        >
          {/* Added a function call to show the value of the parameter and its unit inside the chart. Github issue #54 */}
          {getDecodedVal(
            params,
            Number(params.parameter_value),
            numberToTextKey,
            binaryToTextKey,
            binaryFromJsonList
          )}
          {/* {`${pcent_val.toFixed(0)} %`} */}
        </text>
        {/* Added another text tag for showing the units in next line. Github issue #54 */}
        <text
          // Changed the x and y coordinates of text from offset to percent values for issue #140
          x={"50%"}
          y={"60%"}
          textAnchor="middle"
          dominantBaseline="middle"
          fill={Colors.Black}
        >
          {params.unit}
        </text>
      </>
    );
  };

  return (
    // Added div with height and width for chart so that it size itself according to screen resolution for issue #140
    <div style={{ height: "max(125px,20vh)", width: "100%" }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data_3}
            dataKey="gauge_lo"
            // Pass nameKey to show "<"" or ">" by the percent
            nameKey="name"
            // Changed origin values to percent instead of 125 and 100 for issue #140
            cx={"50%"}
            cy={"50%"}
            startAngle={180}
            endAngle={-180}
            outerRadius={"80%"}
            innerRadius={"40%"}
            labelLine={false}
            label={percentLabel}
            isAnimationActive={false}
            cornerRadius={2}
            // paddingAngle={2}
          >
            <Cell fill={displayColors(getFillColor())} />

            {// Needle color
            data_1.map(entry => {
              let color = "";
              if (
                entry.val >
                entry.hi_hihi + entry.lo_hi + entry.lolo_lo + entry.min_lolo
              ) {
                color = COLORS[4];
              } else if (
                entry.val >
                entry.lo_hi + entry.lolo_lo + entry.min_lolo
              ) {
                color = COLORS[3];
              } else if (entry.val > entry.lolo_lo + entry.min_lolo) {
                color = COLORS[2];
              } else if (entry.val > entry.min_lolo) {
                color = COLORS[1];
              } else if (entry.val >= 0) {
                color = COLORS[0];
              }
              return <Cell fill={displayColors(color)} />;
            })}
            <Cell fill="#dbdbdb" />
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

const useStyles = makeStyles(theme => createStyles({}));

export default DonutChart;
