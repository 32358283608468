/* Issue fixed for github issue #19, issue #24,issue #44, issue #55, issue #45, issue #56, issue #57, issue #54, issue #68, issue #83, issue #82,issue #140,issue #145,issue #217 */
import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import {
  BarChart as SingleBarChart,
  Bar,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import SettingsInputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";
import AssetStatusCategoryIcons from "components/category-icons/AssetStatusCategoryIcons";
import { format, fromUnixTime } from "date-fns";
import BarChart from "components/asset-charts/BarChart";
import BarChart2 from "components/asset-charts/BarChart2";
import IndicatorChart from "components/asset-charts/IndicatorChart";
import { ParameterProps } from "features/real-time-status-page/types";
import GaugeChart from "components/asset-charts/GaugeChart";
import DonutChart from "components/asset-charts/DonutChart";
import { Colors } from "common/Colors";
import GoogleLinkButton from "components/common-buttons/GoogleLinkButton";
import { binaryFromJsonList } from "utils/binaryToText";
import { numberToTextKey } from "utils/constants";
import { binaryToTextKey } from "utils/constants";
import { getDecodedVal } from "utils/conversions";
//Added the imports for chechking the connectivity of the device on the asset level. Gihutb issue #68
import {
  handleActiveHourBar,
  handleActiveOverHourBar,
  handleActiveOverADay,
  handleActiveOverWeekBar
} from "utils/connectivityBarHandler";
import { BarInformationProps } from "components/AssetStatusCard/types";
import { useMediaQuery } from "react-responsive"; //for issue #82

type TankCardProp = {
  singleAsset: any;
  handleAsset?: Function;
  isMarker?: boolean;
  index: any; //Added index props for issue #82
  // handleAsset?: (
  //   assetName: string,
  //   assetId: string,
  //   complex_asset: boolean
  // ) => void;
};

const SingleAssetCard: React.FC<TankCardProp> = ({
  singleAsset,
  handleAsset,
  isMarker
}) => {
  const classes = useStyles();
  const [markerLocation, setMarkerLocation] = useState([]);
  //Updated the state variable for adding the overAnHour, overADay and overAWeek in the object. Github issue #68
  const [assetInformation, setAssetInformation] = useState<
    BarInformationProps[]
  >([
    {
      name: "",
      lessThanHour: 0,
      overAnHour: 0,
      overADay: 0,
      overAWeek: 0
    }
  ]);
  //const param = singleAsset.asset_parameter.parameter_value; // This is the actual data input
  /* const max = singleAsset.asset_parameter.max_value;
  const min = singleAsset.asset_parameter.min_value; */

  /*  const pcent_val = (100 * (param - min)) / (max - min);
   const pcent_val_bound = pcent_val > 100 ? 100 : pcent_val < 0 ? 0 : pcent_val; */
  let tooltip: string;
  // const pcent_val_rem = 100 - pcent_val_bound;

  //added variable to check if it is mobile view or web view for issue #82
  var ismobileview = useMediaQuery({ query: "(max-width: 800px)" });

  const renderChartByDisplayType = (parameter: ParameterProps) => {
    switch (parameter.display_type) {
      case "Horizontal bar":
        return <BarChart2 params={parameter} />;
      // Updated the spelling for vertical, Github issue #57
      case "Vertical bar":
        return <BarChart params={parameter} />;
      case "Doughnut":
        return <DonutChart params={parameter} />;
      case "Gauge":
        return <GaugeChart params={parameter} />;
      case "Indicator":
        return <IndicatorChart params={parameter} />;
      default:
        break;
    }
  };

  const CustomTooltip = ({ active, payload }: any) => {
    let displayDiv;
    let color;

    if (!active || !tooltip) return null;
    for (const bar of payload) {
      if (bar.dataKey === tooltip) {
        switch (bar.name) {
          case "lessThanHour":
            displayDiv = `This asset communicated within the hour.`;
            color = bar.color;
            break;
          //Added the case for overAnHour. Github issue #68
          case "overAnHour":
            displayDiv = `${bar.value} asset(s) did not communicate within the last hour.`;
            color = bar.color;
            break;
          //Added the case for overADay. Github issue #68
          case "overADay":
            displayDiv = `${bar.value} asset(s) did not communicate in over 24 hours.`;
            color = bar.color;
            break;
          //Added the case for overAWeek. Github issue #68
          case "overAWeek":
            displayDiv = `${bar.value} asset(s) did not communicate over the last week.`;
            color = bar.color;
            break;
          default:
            break;
        }
      }
    }

    return (
      <div className={classes.tooltip} style={{ background: color }}>
        {displayDiv}
      </div>
    );
  };

  const handleAssetInformation = useCallback(() => {
    if (singleAsset.active_counts) {
      //Added the overAnHour, overADay and overAWeek constants for setting the count of these in the assetInformation. Github issue #68
      const {
        devices_active,
        devices_active_over_day,
        devices_active_over_hour,
        devices_active_over_week
      } = singleAsset.active_counts;

      //if (devices_active) {
      setAssetInformation([
        {
          name: "Asset information",
          lessThanHour: devices_active,
          overAnHour: devices_active_over_hour,
          overADay: devices_active_over_day,
          overAWeek: devices_active_over_week
        }
      ]);
      /* } else {
        setAssetInformation([]);
      } */
    }
  }, [singleAsset.active_counts]);

  useEffect(() => {
    if (singleAsset) {
      handleAssetInformation();
    }
  }, [singleAsset, handleAssetInformation]);

  useEffect(() => {
    if (singleAsset) {
      setMarkerLocation(singleAsset.gio_location);
    }
  }, [singleAsset]);

  return (
    //Added a ternary operater for taking a different css class for the horizontal bar. Github issue #82
    <div
      className={
        // Added ? to check if it is null for issue #145
        singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
          "horizontal bar"
          ? classes.rootHorizontal
          : classes.root
      }
    >
      {/* Added css class outerLayout for styling the grid layout for simple asset for issue #140 */}
      <div className={classes.outerLayout}>
        {/* div to align asset icon with 1st notification tile for issue #140 */}
        <div className={classes.headerInfo}>
          {/* Added the page and parameter attribute to be passed for the css change with respect to complex asset. Github issue #56 */}
          <AssetStatusCategoryIcons
            asset={singleAsset}
            parameter={""}
            singleAsset={true}
            page={"singleAsset"}
          />
        </div>
        {/* Added div with css style to align asset name with chart or text for issue #140 */}
        <div style={{ gridArea: "two", display: "flex", alignItems: "center" }}>
          <p
            className={classes.assetName}
            style={handleAsset ? {} : { cursor: "default" }}
            onClick={() => {
              const { asset_id = "", asset_name = "", complex_asset = false } =
                singleAsset || {};
              handleAsset && handleAsset(asset_name, asset_id, complex_asset);
            }}
          >
            {singleAsset?.asset_name || ""}
          </p>
          <Chip
            size="small"
            label={singleAsset.asset_category_name}
            style={{ marginLeft: "1em" }}
          />
        </div>
        {/* Added div with style to align chart starting with the asset name for issue #140 */}
        <div style={{ gridArea: "four" }}>
          {/* Adding css class based on parameter display type for issue #140 */}
          <div
            className={
              // Added ? to check if it is null for issue #145
              singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                "none"
                ? classes.pieChartGridNone
                : singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                  "horizontal bar"
                  ? classes.pieChartGridHorizontal
                  : singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                    "gauge"
                    ? classes.pieChartGridGauge
                    : singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                      "vertical bar"
                      ? classes.pieChartGridVertical
                      : singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                        "indicator"
                        ? classes.pieChartGridIndicator
                        : singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                          "doughnut"
                          ? classes.pieChartGridDonut
                          : classes.pieChartGrid
            }
          >
            {/* Added the condition for the className to use the None css class if display type is none. Github issue #55 */}
            <div
              className={
                // Added ? to check if it is null for issue #145
                singleAsset?.asset_parameter === undefined ||
                  singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                  "none"
                  ? classes.chartDetailsNone
                  : singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                    "horizontal bar"
                    ? classes.chartDetailsHorizontal
                    : singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                      "vertical bar"
                      ? classes.chartDetailsVertical
                      : singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                        "doughnut"
                        ? classes.chartDetailsDonut
                        : classes.chartDetails
              }
            >
              {/* Added condition for checking the asset_parameter for the map broken issue. Github issue #45 */}
              {singleAsset.asset_parameter != undefined ? (
                /* Added the condition for the className to use the None css class if display type is none. Github issue #55 */
                /* Added the condition for the className to use the vertical bar css class if display type is vertical bar. Github issue #82 */
                <div
                  className={
                    // Added ? to check if it is null for issue #145
                    singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                      "none"
                      ? classes.innerContainerNone
                      : singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                        "vertical bar" ||
                        singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                        "horizontal bar"
                        ? classes.innerContainerVertical
                        : singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                          "indicator"
                          ? classes.innerContainerIndicator
                          : classes.innerContainer
                  }
                >
                  {renderChartByDisplayType(singleAsset?.asset_parameter)}
                </div>
              ) : (
                ""
              )}
            </div>
            {/* Added a separate css class for the horizontal text alginment and the none type test alignment. Github issue #82 */}
            <div
              className={
                // Added ? to check if it is null for issue #145
                singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                  "doughnut" ||
                  singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                  "gauge"
                  ? classes.textContainer
                  : singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                    "vertical bar"
                    ? classes.textContainerVertical
                    : singleAsset?.asset_parameter?.display_type?.toLowerCase() ==
                      "none"
                      ? classes.textContainerNone
                      : classes.textContainerHorizontal
              }
            >
              <div>
                <p className={classes.regularText} style={{ fontWeight: 600 }}>
                  {singleAsset?.asset_parameter?.parameter_name}
                </p>
                {/* Added condition for checking the asset_parameter for the map broken issue. Github issue #45 */}
                {singleAsset?.asset_parameter != undefined ? (
                  <p className={classes.regularText}>
                    {/* Added the function for the conversion of feet, hours , minutes, binary to text and number to text. Github issue #44 */}
                    {getDecodedVal(
                      singleAsset.asset_parameter,
                      Number(singleAsset?.asset_parameter?.parameter_value),
                      numberToTextKey,
                      binaryToTextKey,
                      binaryFromJsonList
                    )}{" "}
                    {singleAsset?.asset_parameter?.unit}
                  </p>
                ) : (
                  "NaN"
                )}
                {/* Commented the % part, as it is not required. Github issue #54 */}
                {/*  <p className={classes.regularText} style={{ fontSize: ".8em" }}>
            {pcent_val_bound >= 0
              ? `${pcent_val_bound.toFixed(
                singleAsset?.asset_parameter?.decimal
              )}%`
              : ""}
          </p> */}

                {/* Added the condition and the span tag for handling the map and checking if notification_count exists or not. Github issue #45 */}
                {singleAsset.hasOwnProperty("notification_count") ? (
                  <span>
                    {/* changed condition to show only single device status for issue #217 */}
                    {/* Added the condition for showing if the asset is in stopped state. Github issue #19 */}
                    {singleAsset.notification_count.stopped_count > 0 ? (
                      <p className={classes.errorText}>
                        Stopped
                        {/* Added the condition for showing the error and warning if any. Github issue #19 */}
                        {singleAsset.warning != null ? (
                          singleAsset.warning ? (
                            /* Added the condition for showing the warning if any. Github issue #19 */
                            <span className={classes.warningText}>
                              {/* changes made for color of | symbol for issue #19  */}{" "}
                              <span style={{ color: "black" }}>|</span> Warning
                            </span>
                          ) : (
                            /* Added the condition for showing the error if any. Github issue #19 */
                            <span className={classes.errorText}>
                              {/* changes made for color of | symbol for issue #19  */}{" "}
                              <span style={{ color: "black" }}>|</span> Critical
                            </span>
                          )
                        ) : (
                          ""
                        )}
                      </p>
                    ) : singleAsset.notification_count.running_count > 0 ? (
                      <p className={classes.regularText}>
                        Running
                        {/* Added the condition for showing the error and warning if any. Github issue #19 */}
                        {singleAsset.warning != null ? (
                          singleAsset.warning ? (
                            /* Added the condition for showing the warning count if any. Github issue #19 */
                            <span className={classes.warningText}>
                              {/* changes made for color of | symbol for issue #19  */}{" "}
                              <span style={{ color: "black" }}>|</span> Warning
                            </span>
                          ) : (
                            /* Added the condition for showing the error if any. Github issue #19 */
                            <span className={classes.errorText}>
                              {/* changes made for color of | symbol for issue #19  */}{" "}
                              <span style={{ color: "black" }}>|</span> Critical
                            </span>
                          )
                        ) : (
                          ""
                        )}
                      </p>
                    ) : (
                      ""
                    )}
                  </span>
                ) : (
                  ""
                )}
                {/* Added new css class for last read text for issue #140 */}
                <p className={classes.lastReadText}>
                  {singleAsset?.last_read
                    ? format(
                      fromUnixTime(singleAsset?.last_read),
                      //changed from mmmm dd,yyyy to MM/dd/yyyy for issue #24
                      "MM/dd/yyyy | h:mm a"
                    )
                    : ""}
                </p>
              </div>
            </div>
          </div>
          {/* Added div for aligning the connectivity bar with notification tile as well as the chart or text starting for issue #140 */}
          <div>
            {assetInformation.length > 0 && (
              // Changed from span to div for issue #82
              <div
                // removing "styles" and adding connectivitybar class for styling connectivity bar in responsive UI issue #24
                className={classes.connectivitybar}
              >
                {/* Added css class settingsIcon for icon styling for issue #82 */}
                <SettingsInputAntennaIcon className={classes.settingsIcon} />
                {/* Added condition to decide comms bar length in mobile view and web view for issue #82 */}
                <ResponsiveContainer
                  height={15}
                  width={"100%"}
                  className={classes.singleBarChart}
                >
                  <SingleBarChart
                    layout="vertical"
                    data={assetInformation}
                    margin={{ left: 6 }}
                    stackOffset="expand"
                  >
                    <XAxis hide type="number" />
                    <YAxis
                      hide
                      type="category"
                      dataKey="name"
                      stroke="#FFFFFF"
                    />
                    <Tooltip cursor={false} content={<CustomTooltip />} />
                    <Bar
                      dataKey="lessThanHour"
                      fill={Colors.BasinBlue}
                      stackId="a"
                      radius={handleActiveHourBar(assetInformation[0])}
                      name="lessThanHour"
                      onMouseOver={() => (tooltip = "lessThanHour")}
                    />
                    {/* Added the case for overAnHour. Github issue #68 */}
                    <Bar
                      dataKey="overAnHour"
                      fill={Colors.BasinDarkBlue}
                      stackId="a"
                      radius={handleActiveOverHourBar(assetInformation[0])}
                      name="overAnHour"
                      onMouseOver={() => (tooltip = "overAnHour")}
                    />
                    {/* Added the case for overADay. Github issue #68 */}
                    <Bar
                      dataKey="overADay"
                      fill={Colors.BasinPurple}
                      stackId="a"
                      radius={handleActiveOverADay(assetInformation[0])}
                      name="overADay"
                      onMouseOver={() => (tooltip = "overADay")}
                    />
                    {/* Added the case for overAWeek. Github issue #68 */}
                    <Bar
                      dataKey="overAWeek"
                      fill={Colors.Gray}
                      stackId="a"
                      radius={handleActiveOverWeekBar(assetInformation[0])}
                      name="overAWeek"
                      onMouseOver={() => (tooltip = "overAWeek")}
                    />
                  </SingleBarChart>
                </ResponsiveContainer>
              </div>
            )}
            {isMarker && (
              <GoogleLinkButton
                latitude={markerLocation[1]}
                longitude={markerLocation[0]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SingleAssetCard;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column"
    },
    //Added a separate root css class for the horizontal bar, for proper alignment. Github issue #82
    rootHorizontal: {
      display: "flex",
      flexDirection: "column"
    },
    assetName: {
      // removed margin left and added css for aligning asset name with chart and text for issue #140
      height: "3em",
      display: "flex",
      alignItems: "center",
      margin: "0",
      color: `${theme.palette.primary.light}`,
      cursor: "pointer",
      zIndex: 100
    },
    iconHeader: {
      fontSize: "1.5em",
      border: "solid 1px #ccaa2d",
      borderRadius: "50%",
      padding: ".2em",
      backgroundColor: "#ccaa2d",
      color: "white"
    },
    dotMarkers: {
      display: "flex",
      flexDirection: "column",
      marginRight: ".5em",
      justifyContent: "space-between",
      height: 75
    },
    headerInfo: {
      gridArea: "one", //added grid area for asset icon to properly align with 1st notification tile for issue #140
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      fontWeight: 600,
      marginRight: "1em"
    },
    //Added css class for gauge styling for issue #82
    chartDetails: {},
    //Added this css class to show the horizontal bar. Github issue #55
    chartDetailsHorizontal: {
      marginRight: "1rem"
    },
    //Added this css class to show the vertical bar for issue #88
    chartDetailsVertical: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    //Added this css class to show the donut for issue #82
    chartDetailsDonut: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    //Added this css class to show the text properly if display type is none. Github issue #55
    chartDetailsNone: {},
    errorText: {
      color: "#d61834",
      marginBottom: ".1em",
      marginTop: 0,
      // Added media queries to make the text box approx same height as graph for issue #140
      [`@media (min-width: 1800px) and (max-width: 2100px)`]: {
        fontSize: "16px"
      },
      [`@media (min-width: 2101px) and (max-width: 2500px)`]: {
        fontSize: "20px"
      },
      [`@media (min-width: 2501px)`]: {
        fontSize: "24px"
      }
    },
    warningText: {
      color: "#fcc42c",
      marginBottom: ".1em",
      marginTop: 0,
      // Added media queries to make the text box approx same height as graph for issue #140
      [`@media (min-width: 1800px) and (max-width: 2100px)`]: {
        fontSize: "16px"
      },
      [`@media (min-width: 2101px) and (max-width: 2500px)`]: {
        fontSize: "20px"
      },
      [`@media (min-width: 2501px)`]: {
        fontSize: "24px"
      }
    },
    regularText: {
      marginBottom: ".1em",
      marginTop: 0,
      // Added media queries to make the text box approx same height as graph for issue #140
      [`@media (min-width: 1800px) and (max-width: 2100px)`]: {
        fontSize: "16px"
      },
      [`@media (min-width: 2101px) and (max-width: 2500px)`]: {
        fontSize: "20px"
      },
      [`@media (min-width: 2501px)`]: {
        fontSize: "24px"
      }
    },
    // Added new css class with media queries to keep font size of same height as graph for issue #140
    lastReadText: {
      marginBottom: ".1em",
      marginTop: 0,
      fontSize: "9.8px",
      [`@media (min-width: 1800px) and (max-width: 2100px)`]: {
        fontSize: "11.2px"
      },
      [`@media (min-width: 2101px) and (max-width: 2500px)`]: {
        fontSize: "14px"
      },
      [`@media (min-width: 2501px)`]: {
        fontSize: "16.8px"
      }
    },
    //Added media queries for word wrap. Github issue #82
    textContainer: {
      margin: "auto 0" //Added margin for text to align itself with space remaining to right side of chart for issue #140
    },
    //Added media queries for word wrap. Github issue #82
    //Added a separate class for vertical display type for word wrap. Github issue #82
    textContainerVertical: {
      margin: "auto 0"
    },
    //Added a separate class for horizontal and none display type for word wrap. Github issue #82
    textContainerHorizontal: {
      margin: "auto 0" //Added margin for text to align in the right space of chart for issue #140
    },
    textContainerNone: {
      // Added height to align tile with no chart with other asset tiles and notification tiles for issue #140
      height: "max(125px,15vh)",
      display: "flex",
      alignItems: "center"
    },
    innerContainer: {
      minWidth: "100%" //Added min width to align the start of chart with asset name for issue #140
    },
    // Added class for aligning indicator chart for issue #140
    innerContainerIndicator: {
      display: "flex",
      justifyContent: "center"
    },
    //Added this css class to show the text properly if display type is none. Github issue #55
    innerContainerNone: {},
    //Added this css class to show the Chart algined properly if display type is vertical bar. Github issue #82
    innerContainerVertical: {
      minWidth: "100%" //Added min width to align the start of chart with asset name for issue #140
    },
    tankRectangle: {
      height: 70,
      width: "60%",
      display: "flex",
      backgroundColor: "lightgrey"
    },
    tooltip: {
      width: 150,
      height: 60,
      padding: 10,
      color: Colors.White,
      fontSize: 11
    },
    singleBarChart: {
      height: "15px"
    },

    //removed styles tab and added css class for issue #82
    settingsIcon: {
      fontSize: "1em"
    },

    //adding css class with media query in place of "styles" for styling connectivity bar in responsive UI issue #24
    connectivitybar: {
      display: "flex",
      flexDirection: "row",
      paddingRight: "0.5rem",
    },
    // Added css class for styling and allocating grid layout for issue #140
    outerLayout: {
      display: "grid",
      gridTemplateAreas: '"one two" "three four"',
      gridTemplateColumns: "auto minmax(0,1fr)",
      background: 'whitesmoke',
      borderRadius: "5px",
      padding: "0.5rem",
    },
    //Added piechart grid to align charts and text for issue #140
    pieChartGrid: {
      display: "grid",
      gridTemplateColumns: "1fr 2fr",
      margin: "1rem 0"
    },
    //Added piechart grid to align text with no chart for issue #140
    pieChartGridNone: {
      display: "grid",
      gridTemplateColumns: "1fr",
      margin: "1rem 0"
    },
    //Added piechart grid to align horizontal chart and text for issue #140
    pieChartGridHorizontal: {
      display: "grid",
      gridTemplateColumns: "minmax(0,1.8fr) minmax(0,1.2fr)",
      margin: "1rem 0"
    },
    //Added piechart grid to align vertical chart and text for issue #140
    pieChartGridVertical: {
      display: "grid",
      gridTemplateColumns: "minmax(0,1.8fr) minmax(0,1.2fr)",
      margin: "1rem 0"
    },
    //Added piechart grid to align gauge chart and text for issue #140
    pieChartGridGauge: {
      display: "grid",
      gridTemplateColumns: "minmax(0,1.8fr) minmax(0,1.2fr)",
      margin: "1rem 0"
    },
    //Added piechart grid to align indicator chart and text for issue #140
    pieChartGridIndicator: {
      display: "grid",
      gridTemplateColumns: "minmax(0,1.8fr) minmax(0,1.2fr)",
      margin: "1rem 0"
    },
    //Added piechart grid to align donut chart and text for issue #140
    pieChartGridDonut: {
      display: "grid",
      gridTemplateColumns: "minmax(0,1.8fr) minmax(0,1.2fr)",
      margin: "1rem 0"
    }
  })
);
