/* Issue fixed for github issue #24*/
import React, { useEffect, useMemo } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import SideDrawer from "../features/drawer/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { setUserInformation, setSuperAdminAccountId } from "./appSlice";
import { setActiveTenant } from "../features/drawer/drawerSlice";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import AccountAPI from "services/accountsAPI";
// import { AccountObject } from "./types";
import { Auth } from "aws-amplify";
import Cookies from "universal-cookie";
import "./app.css";
import { RootState } from "./rootReducer";
import { companyDomain } from "custom/customizations";

const App: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const themeState = useSelector((state: RootState) => state.DrawerSlice.theme);

  // const grabAssetGroupIds = (usersAccounts: AccountObject[]) => {
  //   return usersAccounts
  //     .map((account) => account.groups)
  //     .flat()
  //     .map((group) => group.asset_group_id);
  // };

  useEffect(() => {
    (async () => {
      try {
        const cookies = new Cookies();
        const currentUser = await Auth?.currentAuthenticatedUser();
        cookies.set(
          "MyAccessToken",
          currentUser.signInUserSession.idToken.jwtToken,
          { path: "/", domain: companyDomain, secure: true }
        );
        const userId = currentUser?.attributes?.sub;
        const userInformation = await AccountAPI.getUserProfile(userId);

        userInformation.accounts[0] &&
          dispatch(setActiveTenant(userInformation.accounts[0].tenant_id));
        dispatch(
          setUserInformation({
            ...userInformation,
            access_level: userInformation.access_level
          })
        );
        // Change made for calling super tenant after profile
        const response = await AccountAPI.getSuperAdminAccountId();
        dispatch(setSuperAdminAccountId(response.data.Items[0].tenant_id));
      } catch (error) {
        // alert(error);
        if (process.env.NODE_ENV === 'development') {
          console.log(error);
        }
      }
    })();
  }, [dispatch]);

  //removed useeffect for calling supertenant after profile
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const response = await AccountAPI.getSuperAdminAccountId();
  //       dispatch(setSuperAdminAccountId(response.data.Items[0].tenant_id));
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, [dispatch]);

  const theme = useMemo(() => createMuiTheme(themeState), [themeState]);

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <SideDrawer />
      </ThemeProvider>
    </div>
  );
};

export default App;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },

    //adding style classes for stying full screen in responsive UI issue #24
    content: {
      //adding media query for styling full mobile view in responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        padding: "1.5rem 3rem",
        minwidth: "0px"
      }
    }
  })
);
