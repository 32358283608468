import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Colors } from "common/Colors";
import CloseIcon from "@material-ui/icons/Close";
import { otaAPI } from "services/otaAPI";
import { useDispatch } from "react-redux";
import {
  setSuccessModalOpen,
  setSuccessModalDescription,
} from "components/success-modal/successModalSlice";
import LoadSpinner from "common/LoadSpinner";
import Button from "components/common-buttons/Button";

export interface DeleteFirmwareModal {
  open: boolean;
  handleClose: Function;
  firmwareId: string;
  recallList: Function;
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

const DeleteFirmwareModal: React.FC<DeleteFirmwareModal> = ({
  open,
  handleClose,
  firmwareId,
  recallList,
}: DeleteFirmwareModal) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const cancel = () => {
    handleClose();
  };

  const deleteFirmware = async () => {
    try {
      setLoading(true);
      await otaAPI.deleteFirmware(firmwareId);
      setLoading(false);
      recallList(true);
      dispatch(setSuccessModalDescription("Firmware successfully deleted!"));
      dispatch(setSuccessModalOpen(true));
    } catch (error) {
      alert("Unable to delete firmware");
    }
    handleClose();
  };

  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalOverlay}>
        {loading ? (
          <LoadSpinner />
        ) : (
          <div className={classes.modalBody}>
            <div className={classes.header}>
              <p className={classes.headerText}>Delete firmware</p>
              <CloseIcon
                className={classes.icon}
                onClick={() => handleClose()}
              />
            </div>
            <div style={{ margin: "2rem 0 0 0" }}>
              <p className={classes.deleteDescription}>
                Are you sure you want to delete this firmware?
              </p>
              <p className={classes.deleteDescription}>
                If yes, it will be removed from this platform.
              </p>
            </div>
            <div className={classes.actionItems}>
              <Button type="button" color="white" onClick={cancel}>
                Cancel
              </Button>
              <Button
                type="button"
                color="defaultTheme"
                onClick={deleteFirmware}
              >
                Delete firmware
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DeleteFirmwareModal;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalOverlay: {
      zIndex: 9000,
      position: "fixed",
      top: 0,
      left: 0,
      minHeight: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, .7)",
    },
    modalBody: {
      minHeight: "20rem",
      width: 500,
      backgroundColor: "white",
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "2rem 2.5rem",
      borderBottom: `1px solid ${Colors.LightGray}`,
    },
    headerText: {
      color: theme.palette.primary.light,
      fontWeight: 600,
      fontSize: 24,
      padding: 0,
      margin: 0,
    },
    icon: {
      color: Colors.LightGray,
      cursor: "pointer",
      fontSize: "3rem",
    },
    pathText: {
      backgroundColor: Colors.LightGray,
    },
    labelText: {
      display: "flex",
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        marginRight: "1rem",
        fontSize: "1rem",
      },
    },
    inputGroup: {
      padding: "1rem 2.5rem",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem",
      },
      "& input": {
        width: "100%",
        padding: ".7rem 1rem",
        border: `1px solid ${Colors.LightGray}`,
      },
    },
    deleteDescription: {
      padding: "0 2.5rem",
      display: "flex",
      "& p": {
        padding: 0,
        margin: 0,
        fontSize: "1rem",
        marginBottom: ".5rem",
      },
    },
    actionItems: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "2rem 2.5rem",
    },
    cancelButton: {
      padding: ".7rem 1.5rem",
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      cursor: "pointer",
      marginRight: "1.5rem",
      fontWeight: "bold",
      backgroundColor: "white",
    },
    saveButton: {
      padding: ".7rem 1.5rem",
      color: "white",
      backgroundColor: theme.palette.primary.light,
      cursor: "pointer",
      fontWeight: "bold",
      border: "none",
    },
  })
);
