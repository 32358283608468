/* Issue fixed for github issue #16, issue #24,issue #146*/
import React from "react";
import { useStyles } from "./styles";
import { ListItemIcon } from "@material-ui/core";
import {
  realTimeStatusPages,
  officeFieldReportsPages
} from "../drawerPagesList";
import { PageProps } from "../types";
import { Link, useRouteMatch } from "react-router-dom";
import DashboardIcon from "assets/bt-assets/menu_dashboards.svg";
import ReportIcon from "assets/bt-assets/menu_reports.svg";
import clsx from "clsx";
import { fieldOperator } from "utils/constants";
import { ofcUser } from "utils/constants";

export type NonAdminMenuItemsProps = {
  setPageName: Function;
  handleSignOut: Function; //for logout functionality for responsive UI issue #24
  accessLevel: string;
  role: string;
};

export const NonAdminMenuItems: React.FC<NonAdminMenuItemsProps> = ({
  setPageName,
  handleSignOut, //for logout functionality for responsive UI issue #24
  accessLevel,
  role
}) => {
  const classes = useStyles();

  const isNonAdminUser =
    accessLevel === "general" &&
    [
      "office_user",
      "field_operations",
      "field_view_only",
      "notifications"
    ].indexOf(role) > -1;

  const PageName = ({ to, label, activeOnlyWhenExact }: PageProps) => {
    const routeSelected = useRouteMatch({
      path: to,
      exact: activeOnlyWhenExact
    });

    if (routeSelected) {
      setPageName(label);
    }

    return (
      <Link
        to={to}
        className={clsx(classes.routeNames, {
          [classes.active]: routeSelected
        })}
      >
        {label}
      </Link>
    );
  };

  return isNonAdminUser ? (
    <div>
      <ListItemIcon className={classes.listHeader}>
        <div style={{ marginTop: "2px" }}>
          <img
            className={classes.headerIcon}
            src={DashboardIcon}
            alt="Dashboard icon"
          />
        </div>
        <div className={classes.header}>Dashboards</div>
      </ListItemIcon>
      {realTimeStatusPages.map((page: PageProps) => (
        <PageName
          key={page.label}
          to={page.to}
          label={page.label}
          activeOnlyWhenExact={page.activeOnlyWhenExact}
        />
      ))}
      {/* // ! Reports section */}
      {/* Added reports to mobile view for issue #146 */}
      {/* Added the condition to hide the reports section for field_view_only user. Github issue #16 */}
      {role === fieldOperator || role === ofcUser ? (
        <>
          <ListItemIcon className={classes.listHeader}>
            <div style={{ marginTop: "2px" }}>
              <img
                className={classes.headerIcon}
                src={ReportIcon}
                alt="Report icon"
              />
            </div>
            <div className={classes.header}>Reports</div>
          </ListItemIcon>
          {officeFieldReportsPages.map((page: PageProps) => (
            <PageName
              key={page.label}
              to={page.to}
              label={page.label}
              activeOnlyWhenExact={page.activeOnlyWhenExact}
            />
          ))}
        </>
      ) : null}
      {/* adding logout in the sidenav bar for responsive UI issue #24 */}
      <div
        className={classes.logoutbutton}
        onClick={() => {
          handleSignOut();
        }}
      >
        Logout
      </div>
    </div>
  ) : null;
};

export default NonAdminMenuItems;
