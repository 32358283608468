/*Issue fixed for github issue #213 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  successModalOpen: boolean;
  successModalDescription: string;
  unsuccessModalOpen: boolean; //Added for unsuccessful modal for issue #213
  unsuccessModalDescription: string; //Added for unsuccessful modal for issue #213
} = {
  successModalOpen: false,
  successModalDescription: "",
  unsuccessModalOpen: false, //Added for unsuccessful modal for issue #213
  unsuccessModalDescription: "" //Added for unsuccessful modal for issue #213
};

const successModalSLice = createSlice({
  name: "SuccessModalSlice",
  initialState,
  reducers: {
    setSuccessModalOpen: (state, action: PayloadAction<boolean>) => {
      state.successModalOpen = action.payload;
    },
    setSuccessModalDescription: (state, action: PayloadAction<string>) => {
      state.successModalDescription = action.payload;
    },
    //Added for unsuccessful modal for issue #213
    setUnsuccessModalOpen: (state, action: PayloadAction<boolean>) => {
      state.unsuccessModalOpen = action.payload;
    },
    //Added for unsuccessful modal for issue #213
    setUnsuccessModalDescription: (state, action: PayloadAction<string>) => {
      state.unsuccessModalDescription = action.payload;
    }
  }
});

export const {
  setSuccessModalOpen,
  setSuccessModalDescription,
  setUnsuccessModalOpen, //Added for unsuccessful modal for issue #213
  setUnsuccessModalDescription //Added for unsuccessful modal for issue #213
} = successModalSLice.actions;
export default successModalSLice.reducer;
