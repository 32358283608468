import { AnyAction, combineReducers, Reducer } from "@reduxjs/toolkit";
import DeviceModalSlice from "features/devices-page/device-components/device-modal/deviceModalSlice";
import DeviceTableSlice from "features/devices-page/devices-table/deviceTableSlice";
import SuccessModalSlice from "components/success-modal/successModalSlice";
import GroupModalSlice from "components/group-modal/groupModalSlice";
import AccountSlice from "features/accounts-page/accountSlice";
import AssetCategorySlice from "features/assets-page/assetCategorySlice";
import AssetGroupModalSlice from "components/asset-modals/assetGroupModalSlice";
import AssetDashboardSlice from "features/assets-page/assetsDashboardSlice";
import DeviceDetailsSlice from "features/devices-page/device-details/deviceDetailsSlice";
import DeviceSettingsSlice from "features/devices-page/device-settings/deviceSettingsTabSlice";
import ParameterSettingsSlice from "features/devices-page/device-settings/device-settings-tabs/parameter-settings/parameterSettingSlice";
import DrawerSlice from "features/drawer/drawerSlice";
import RealTimeStatusPageSlice from "features/real-time-status-page/RealTimeStatusPageSlice";
import FirmwareSlice from "features/firmware-page/firmwareSlice";
import AppSlice from "./appSlice";
import { LOGOUT_RESET_REDUX } from "./enums";

const reducersConfig = {
  FirmwareSlice,
  RealTimeStatusPageSlice,
  DeviceModalSlice,
  DeviceTableSlice,
  SuccessModalSlice,
  GroupModalSlice,
  AccountSlice,
  AssetCategorySlice,
  AssetGroupModalSlice,
  AssetDashboardSlice,
  DeviceDetailsSlice,
  DeviceSettingsSlice,
  ParameterSettingsSlice,
  DrawerSlice,
  AppSlice,
};

const rootReducer = combineReducers(reducersConfig);

export type ReducerKeys = keyof typeof reducersConfig;
export type RootState = ReturnType<typeof rootReducer>;

const rootReducerWithReset: Reducer<RootState, AnyAction> = (state, action) => {
  if (action.type === LOGOUT_RESET_REDUX) {
    state = {} as RootState;
  }
  return rootReducer(state, action);
};

export default rootReducerWithReset;
