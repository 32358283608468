/* Added this file for delete connector modal for github issue #294 */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import { useDispatch } from "react-redux";
import {
  setSuccessModalOpen,
  setSuccessModalDescription
} from "components/success-modal/successModalSlice";
import { useStyles } from "components/asset-modals/assetStyles";
import AccountAPI from "services/accountsAPI";
import Button from "components/common-buttons/Button";

type Props = {
  open: boolean;
  handleClose: () => void;
  connectorId: string;
  tenantId: string;
  connectorName: string;
  setCallConnector: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DeleteConnectorModal: React.FC<Props> = ({
  open, //to open modal for issue #294
  handleClose, // to handle closing of modal for issue #294
  connectorId, //to send with api call for issue #294
  tenantId, //to send with api call for issue #294
  connectorName, //for showing in the modal for issue #294
  setCallConnector //to call the connector list after connector operation for issue #294
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //function to delete the connector and call the delete api for issue #294
  const confirmDeleteConnectorHandler = async () => {
    try {
      // calling the delete api with tenant id and connector id for issue #294
      let deleteResponse = await AccountAPI.deleteConnector(tenantId, connectorId);
      dispatch(setSuccessModalOpen(true));
      dispatch(setSuccessModalDescription("Connector successfully deleted"));
      setCallConnector(true);
    } catch (error) {
      alert("Error deleting connector");
    } finally {
      handleClose();
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={classes.modalOverlay}>
        <div className={classes.modalBody} style={{ minHeight: 0 }}>
          <div className={classes.header}>
            <p className={classes.headerText}>Delete connector</p>
            <CloseIcon className={classes.icon} onClick={handleClose} />
          </div>
          <p style={{ margin: "0 0 1rem 2rem" }}>
            Are you sure you want to delete {connectorName}?
          </p>
          <p style={{ margin: "0 2rem" }}>
            If yes, this connector and all its previous history will be removed from
            the system.
          </p>
          <div className={classes.actionItems} style={{ border: "none" }}>
            <Button color="white" type="button" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="defaultTheme"
              type="button"
              onClick={confirmDeleteConnectorHandler}
            >
              Delete Connector
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConnectorModal;
