import React, { useState, useEffect, useRef } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { getUnixTime, fromUnixTime, format, subHours, subDays } from "date-fns";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateProp, Selection, CalendarProp } from "./types";
import { Colors } from "common/Colors";
import clsx from "clsx";
import { Button } from "@material-ui/core";

export type DateRangeSelectorProps = {
  setStartDate: Function;
  setEndDate: Function;
  defaultRange?: "hour" | "day" | "week";
  style?: Object;
};

const useAlert = (
  ref: any,
  setToggleCalendar: Function,
  setIsDateRangeVisible: Function, // Include the setIsDateRangeVisible function
  buttonState: string
) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setToggleCalendar(false);
        setIsDateRangeVisible(true); // Set isDateRangeVisible to true when clicking outside
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setToggleCalendar, setIsDateRangeVisible, buttonState]);
};

export const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  setStartDate,
  setEndDate,
  defaultRange,
  style,
}) => {
  const classes = useStyles();
  const [buttonState, setButtonState] = useState("day");
  const [startUnix, setStartUnix] = useState<number | null>(
    getUnixTime(subDays(new Date(), 1))
  );
  const [endUnix, setEndUnix] = useState<number | null>(
    getUnixTime(new Date())
  );
  const [state, setState] = useState<CalendarProp[]>([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const [toggleCalendar, setToggleCalendar] = useState(false);
  const [tempState, setTempState] = useState(state);
  const [isDateRangeVisible, setIsDateRangeVisible] = useState(true); // Track date range visibility
  const buttonContainer = useRef(null);

  useAlert(buttonContainer, setToggleCalendar, setIsDateRangeVisible, buttonState);

  const dates = [
    {
      name: "H",
      value: "hour",
      returnDates: () => {
        setStartUnix(getUnixTime(subHours(new Date(), 1)));
        setEndUnix(getUnixTime(new Date()));
      },
    },
    {
      name: "D",
      value: "day",
      returnDates: () => {
        setStartUnix(getUnixTime(subDays(new Date(), 1)));
        setEndUnix(getUnixTime(new Date()));
      },
    },
    {
      name: "W",
      value: "week",
      returnDates: () => {
        setStartUnix(getUnixTime(subDays(new Date(), 7)));
        setEndUnix(getUnixTime(new Date()));
      },
    },
    {
      name: "M",
      value: "month",
      returnDates: () => {
        setStartUnix(getUnixTime(subDays(new Date(), 30)));
        setEndUnix(getUnixTime(new Date()));
      },
    },
    {
      name: "C",
      value: "calendar",
      component: (
        <CalendarTodayOutlinedIcon style={{ height: 14, width: 14 }} />
      ),
    },
  ];

  useEffect(() => {
    setStartDate(startUnix);
    setEndDate(endUnix);
  }, [startUnix, endUnix, setStartDate, setEndDate]);

  const handleApply = () => {
    setState(tempState);
    setStartUnix(getUnixTime(tempState[0].startDate));
    setEndUnix(getUnixTime(tempState[0].endDate as Date));
    setToggleCalendar(false);
    setIsDateRangeVisible(true); // Ensure the date range information is visible after applying
  };

  const handleCancel = () => {
    setTempState(state);
    setToggleCalendar(false);
    setIsDateRangeVisible(true); // Ensure the date range information is visible after canceling
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          left: buttonState !== "calendar" || !toggleCalendar ? 0 : 179,
        }}
        className={classes.dateselector}
      >
        {isDateRangeVisible && startUnix && endUnix
          ? buttonState === "hour"
            ? `${format(fromUnixTime(startUnix), "K:mm a")} - ${format(
              fromUnixTime(endUnix),
              "K:mm a"
            )}`
            : `${format(fromUnixTime(startUnix), "dd MMM yyyy")} - ${format(
              fromUnixTime(endUnix),
              "dd MMM yyyy"
            )}`
          : null}
      </div>
      <div className={classes.buttonContainer} ref={buttonContainer}>
        <div>
          {dates.map((date: DateProp, index: number) => (
            <button
              key={date.value + index}
              className={clsx(classes.button, {
                [classes.isActive]: buttonState === date.value,
              })}
              type="button"
              onClick={() => {
                if (date.returnDates) {
                  setButtonState(date.value);
                  date.returnDates();
                } else {
                  setButtonState(date.value);
                  setToggleCalendar(true);
                  setIsDateRangeVisible(false); // Hide the date range information when calendar is open
                }
              }}
            >
              {date.component || date.name}
            </button>
          ))}
        </div>
        <div
          className={clsx(classes.calendarContainer, {
            [classes.hidden]: buttonState !== "calendar" || !toggleCalendar,
          })}
        >
          <div className={classes.calendarWrapper}>
            <DateRange
              editableDateInputs={true}
              onChange={(item: Record<Selection, CalendarProp>) => {
                setTempState([item.selection]);
              }}
              moveRangeOnFirstSelection={false}
              ranges={tempState}
            />
            <div className={classes.buttonGroup}>
              <Button
                variant="contained"
                onClick={handleCancel}
                className={classes.cancelButton}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApply}
                className={classes.applyButton}
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangeSelector;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    dateDisplay: {
      width: 175,
      fontSize: 15,
    },
    buttonContainer: {
      height: 30,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      marginLeft: theme.spacing(2), // Add margin to avoid overlap
      [`@media (max-width: 800px)`]: {
        flexDirection: "row", // Stack buttons horizontally
      },
    },
    button: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: Colors.White,
      whiteSpace: "nowrap",
      cursor: "pointer",
      padding: "0 1rem",
      outline: "none",
      height: 30,
    },
    isActive: {
      backgroundColor: theme.palette.primary.light,
      color: Colors.White,
    },
    calendarContainer: {
      top: 8,
      zIndex: 15,
      position: "relative",
      [`@media (max-width: 1080px)`]: {
        position: "absolute",
        left: 0,
        right: 0,
        margin: "0 auto",
        width: "80%",
        maxWidth: "500px",
        display: "flex",
        justifyContent: "center",
        top: "50%",
        transform: "translateY(-50%)",
      },
    },
    hidden: {
      display: "none",
    },
    calendarColor: {
      color: theme.palette.primary.light,
    },
    dateselector: {
      textAlign: "center",
      width: "10rem",
      position: "relative",
      padding: theme.spacing(0, 2), // Add padding to provide space
      marginTop: theme.spacing(1), // Adjust margin to lower the text
      whiteSpace: "nowrap", // Prevent text wrapping
    },
    calendarWrapper: {
      backgroundColor: Colors.White,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[5],
    },
    buttonGroup: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(2),
    },
    cancelButton: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    applyButton: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    dateRangeInfo: {
      display: "block",
      marginBottom: theme.spacing(0),
      overflow: "hidden", // Hide overflow to avoid text spilling out
      [`@media (max-width: 1080px)`]: {
        display: "block",
      },
    },
  })
);
