import { Action, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { useDispatch, createSelectorHook } from "react-redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
// import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import { LOGOUT_RESET_REDUX } from "./enums";
import rootReducer, { ReducerKeys } from "./rootReducer";

const whitelist: ReducerKeys[] = [
  "AccountSlice",
  "AppSlice",
  "RealTimeStatusPageSlice",
  "DrawerSlice",
]; // workaround for PersistConfig type blocker

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist, // removing this will persist entire store. blacklist is also an option
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export let persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof persistedReducer>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = createSelectorHook<AppState>();

export const resetReduxAction = { type: LOGOUT_RESET_REDUX } as Action;

export default store;
