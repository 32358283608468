/* Issue fixed for github issue #143,issue #294*/
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountInformation, SelectedAccountProps } from "./types";

const initialState: {
  accountInformation: AccountInformation;
  callUsers: boolean;
  callTokens: boolean; //Added a new var for calling the tokenlist after CRUD operation for issue #143
  callConnectors: boolean; //Added a new var for calling the connectorlist after CRUD operation for issue #294
  accountLoading: boolean;
  isAccountDashboard: boolean;
  subPageActive: boolean;
  selectedAccount: SelectedAccountProps;
} = {
  accountInformation: {
    billing_address: {
      state_province: "",
      address_line: "",
      city: "",
      zip_code: ""
    },
    admin: {
      family_name: "",
      given_name: "",
      phone: "",
      email: ""
    },
    tenant_id: "",
    shipping_address: {
      state_province: "",
      address_line: "",
      city: "",
      zip_code: ""
    },
    is_same_as_billing: false,
    tenant_name: ""
    // display_settings: {
    //   active_color: "",
    //   available_colors: [],
    //   logo_url: "",
    // }
  },
  callUsers: false,
  callTokens: false, //Initializing the var to false for issue #143
  callConnectors: false, //Initializing the var to false for issue #294
  accountLoading: false,
  isAccountDashboard: false,
  subPageActive: false,
  selectedAccount: {
    accountName: "",
    accountId: "",
    role: "",
    active: false
  }
};

const accountSlice = createSlice({
  name: "AccountSlice",
  initialState,
  reducers: {
    setSelectedAccountInformation: (
      state,
      action: PayloadAction<AccountInformation>
    ) => {
      state.accountInformation = action.payload;
    },
    setCallUsers: (state, action: PayloadAction<boolean>) => {
      state.callUsers = action.payload;
    },
    //state function to set the var to true after any operation for issue #143
    setCallTokens: (state, action: PayloadAction<boolean>) => {
      state.callTokens = action.payload;
    },
    //state function to set the var to true after any operation for issue #294
    setCallConnectors: (state, action: PayloadAction<boolean>) => {
      state.callConnectors = action.payload;
    },
    setAccountLoading: (state, action: PayloadAction<boolean>) => {
      state.accountLoading = action.payload;
    },
    setAccountDashboard: (state, action: PayloadAction<boolean>) => {
      state.isAccountDashboard = action.payload;
    },
    setSubPageActive: (state, action: PayloadAction<boolean>) => {
      state.subPageActive = action.payload;
    },
    setSelectedAccount: (
      state,
      action: PayloadAction<SelectedAccountProps>
    ) => {
      state.selectedAccount = action.payload;
    },
    resetSelectedAccountData: state => {
      state.accountInformation = initialState.accountInformation;
      state.selectedAccount = initialState.selectedAccount;
    }
  }
});

export const {
  setSelectedAccountInformation,
  setCallUsers,
  setCallTokens, //exporting the var for issue #143
  setCallConnectors, //exporting the var for issue #294
  setAccountLoading,
  setAccountDashboard,
  setSubPageActive,
  setSelectedAccount,
  resetSelectedAccountData
} = accountSlice.actions;
export default accountSlice.reducer;
